import React, { useCallback, useMemo, useState } from 'react';
import s from './s.module.less';
import { Tabs, message, type TabsProps } from 'antd';
import { getUrlParam, insertUrlParam } from 'utils/common';
import HelloKlarityTable from './components/HelloKlarityTable';
import AirTableTable from './components/AirTableTable';
import GlobalLayerStore from 'store/GlobalLayer';
import PorivderMappingModal from 'components/PorivderMappingModals/MappingModal';
import ProviderSlugModal from 'components/PorivderMappingModals/SlugUpdateModal';
import ProviderTitleModal from 'components/PorivderMappingModals/TitleUpdateModal';
import PatientRatingModal from 'components/PorivderMappingModals/PatientRatingUpdateModal';
import { EType } from 'types/helloKlarity';
import { EProviderListMappingStatusValue, IBPMappingItem } from 'types/operation';
import { updateHelloKlarityListingStatus, getHelloKlarityProviderMappingData } from 'api/operation';
import AccessStore from 'store/Access/permission';
import { PermissionModuleKeys } from 'constants/access';
import AccessDenied from 'components/AccessDenied';
import MappingModalConfirm from 'components/PorivderMappingModals/MappingModalConfirm';

/**
 *
 * showProviderMappingUpdateTitleModal: false,
        showProviderMappingPatientRatingModal: false,
        showProviderMappingUpdateSlugModal: false,
 */
const HelloKlarityProviders = () => {
    const [, setGlobalLayer] = GlobalLayerStore.useStore();
    const activeType = getUrlParam('type') as EType || EType.HELLOW_KLARITY;
    const [type, setType] = useState(activeType || EType.HELLOW_KLARITY);
    const [currentRow, setCurrentRow] = useState<IBPMappingItem | null>(null);
    const [forceUpdateBPTable, setForceUpdateBPTable] = useState({});
    const [forceUpdateATTable, setForceUpdateATTable] = useState({});
    const [getAccessStore] = AccessStore.useStore();
    const access = getAccessStore('data');

    const handleSetCurrentRow = useCallback((row: IBPMappingItem | null) => {
        setCurrentRow(row);
    }, []);

    // 当冲突的时候，比如BP这边relist，要确认AIRTABLE那边是否已经map了，如果是的话，需要弹确认提示框
    const findProviderByEmail = useCallback((email: string, tableType: number) => {
        const params = {
            // bookLink: bookingLinkList as number[],
            current: 1,
            // ehrProviderId,
            // intakeqProviderId: '',
            // listingStatus: listingStatusList as string[],
            // mappingStatus: mappingStatusList as string[],
            name: email,
            type: tableType,
        };

        return getHelloKlarityProviderMappingData(params);
    }, []);

    const handleReloadTableData = useCallback(() => {
        if (activeType === EType.HELLOW_KLARITY) {
            console.info('re hk');
            setForceUpdateBPTable({});
        }
        if (activeType === EType.AIRTABLE) {
            console.info('re at');
            setForceUpdateATTable({});
        }
    }, [activeType]);

    const handleReloadAnotherTableData = useCallback(() => {
        if (activeType === EType.HELLOW_KLARITY) {
            console.info('another hk');
            setForceUpdateATTable({});
        }
        if (activeType === EType.AIRTABLE) {
            console.info('another at');
            setForceUpdateBPTable({});
        }
    }, [activeType]);

    // mapping modal
    const hideMappingModal = useCallback(() => {
        setGlobalLayer('showProviderMappingModal', false);
    }, []);

    const showMappingModal = useCallback(() => {
        setGlobalLayer('showProviderMappingModal', true);
    }, []);

    // mapping Confirm modal
    const hideMappingConfirmModal = useCallback(() => {
        setGlobalLayer('showProviderMappingConfirmModal', false);
    }, []);

    const showMappingConfirmModal = useCallback(() => {
        setGlobalLayer('showProviderMappingConfirmModal', true);
    }, []);

    const handleMappingModalOk = useCallback(() => {
        handleSetCurrentRow(null);
        handleReloadTableData();
        handleReloadAnotherTableData();
        hideMappingModal();
    }, [handleReloadAnotherTableData, handleReloadTableData, handleSetCurrentRow]);

    // slug modal
    const hideSlugModal = useCallback(() => {
        setGlobalLayer('showProviderMappingUpdateSlugModal', false);
    }, []);

    const showSlugModal = useCallback(() => {
        setGlobalLayer('showProviderMappingUpdateSlugModal', true);
    }, []);

    const handleSlugModalOk = useCallback(() => {
        handleSetCurrentRow(null);
        handleReloadTableData();
        hideSlugModal();
    }, [handleReloadTableData, handleSetCurrentRow]);

    // title modal
    const hideTitleModal = useCallback(() => {
        setGlobalLayer('showProviderMappingUpdateTitleModal', false);
    }, []);

    const showTitleModal = useCallback(() => {
        setGlobalLayer('showProviderMappingUpdateTitleModal', true);
    }, []);

    const handleTitleModalOk = useCallback(() => {
        handleSetCurrentRow(null);
        handleReloadTableData();
        hideTitleModal();
    }, [handleReloadTableData, handleSetCurrentRow]);

    // patientRating modal
    const hidePatientRatingModal = useCallback(() => {
        setGlobalLayer('showProviderMappingPatientRatingModal', false);
    }, []);

    const showPatientRatingModal = useCallback(() => {
        setGlobalLayer('showProviderMappingPatientRatingModal', true);
    }, []);

    const handlePatientRatingModalOk = useCallback(() => {
        handleSetCurrentRow(null);
        handleReloadTableData();
        hidePatientRatingModal();
    }, [handleReloadTableData, handleSetCurrentRow]);

    const handleListingStatusChange = useCallback(async (id: number, status: EProviderListMappingStatusValue) => {
        if (id) {
            try {
                const res = await updateHelloKlarityListingStatus({
                    id,
                    listingStatus: status,
                });

                if (res && !res.error) {
                    handleReloadTableData();
                    hideMappingConfirmModal();
                    handleReloadAnotherTableData();
                } else {
                    message.error(res.error);
                }
            } catch (e) {
                console.error(e);
            }
        }
    }, [handleReloadTableData]);

    const commomProps = useMemo(() => {
        return {
            showMappingModal,
            showSlugModal,
            showTitleModal,
            showMappingConfirmModal,
            hideMappingConfirmModal,
            showPatientRatingModal,
            handleSetCurrentRow,
            handleListingStatusChange,
            findProviderByEmail,
        };
    }, [findProviderByEmail, handleListingStatusChange, handleSetCurrentRow, hideMappingConfirmModal, showMappingConfirmModal, showMappingModal, showPatientRatingModal, showSlugModal, showTitleModal]);

    const items = useMemo<TabsProps['items']>(() => [
        {
            key: EType.HELLOW_KLARITY,
            label: <p className={s.tabTitle}>Kiwi Health providers</p>,
            children: access?.[PermissionModuleKeys.KLARITY_PROVIDER_KIWI] ? <HelloKlarityTable {...commomProps} forceReload={forceUpdateBPTable} /> : <AccessDenied />,
            forceRender: true,
        },
        {
            key: EType.AIRTABLE,
            label: <p className={s.tabTitle}>Airtable providers</p>,
            children: access?.[PermissionModuleKeys.KLARITY_PROVIDER_AIRTABLE] ? <AirTableTable {...commomProps} forceReload={forceUpdateATTable} /> : <AccessDenied />,
            forceRender: true,
        },
    ], [access, commomProps, forceUpdateATTable, forceUpdateBPTable]);

    return (
        <div className={s.wrap}>
            <MappingModalConfirm
                type={type}
                onOk={() => {
                    if (currentRow) {
                        handleListingStatusChange(currentRow.id, EProviderListMappingStatusValue.LISTED);
                    }
                }}
                onCancel={hideMappingConfirmModal}
            />
            <PorivderMappingModal
                row={currentRow}
                type={type}
                onOk={handleMappingModalOk}
                onCancel={hideMappingModal}
            />
            <ProviderSlugModal
                row={currentRow}
                type={type}
                onOk={handleSlugModalOk}
                onCancel={hideSlugModal}
            />
            <ProviderTitleModal
                row={currentRow}
                type={type}
                onOk={handleTitleModalOk}
                onCancel={hideTitleModal}
            />
            <PatientRatingModal
                row={currentRow}
                type={type}
                onOk={handlePatientRatingModalOk}
                onCancel={hidePatientRatingModal}
            />
            <div className={s.title}>Hello Klarity.com providers</div>
            <Tabs
                activeKey={type}
                items={items}
                onChange={(key) => {
                    setType(key as EType);
                    insertUrlParam('type', key);
                }}
            />
        </div>
    );
};

export default HelloKlarityProviders;
