import { Button, message, Modal, Spin } from 'antd';
import React, { } from 'react';
import { ServerMeta } from 'types/common';
import s from './s.module.less';
import { TFaqInfo } from 'types/operation';
import EditFaqModal from './components/EditFaqModal';
import { DndContext, useSensor, useSensors, KeyboardSensor, PointerSensor } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { getHelloKlairtyKlarityPageFaq, deleteHelloKlairtyKlarityPageFaq, updateHelloKlairtyKlarityPageFaqSeq } from 'api/operation';

type Props = {
    pageId?: string;
    state?: ServerMeta;
    condition?: ServerMeta;
    onPreview: (items:TFaqInfo[]) => void;
};

const SortableItem = (
    {
        id,
        item,
        index,
        setCurrentEdit,
        setShowEdit,
        handleRemove,
        setCurrentEditInx,
    }:
    {
        id:number,
        item: TFaqInfo,
        index: number,
        setCurrentEdit: (item:TFaqInfo)=>void,
        setShowEdit:(show:boolean)=>void,
        handleRemove: (item:TFaqInfo)=>void
        setCurrentEditInx: (inx:number)=>void
    }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
    const style = {
        transform: CSS.Transform.toString({ ...transform, scaleX: 1, scaleY: 1 } as any),
        transition,
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners} className={s.item} key={item.id}>
            <div className={s.header}>
                <div className={s.left}>
                    <div className={s.subTitle}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M7.91536 4.16667C7.91536 5.08712 7.16916 5.83333 6.2487 5.83333C5.32824 5.83333 4.58203 5.08712 4.58203 4.16667C4.58203 3.24619 5.32824 2.5 6.2487 2.5C7.16916 2.5 7.91536 3.24619 7.91536 4.16667ZM6.2487 11.6667C7.16916 11.6667 7.91536 10.9205 7.91536 10C7.91536 9.07954 7.16916 8.33333 6.2487 8.33333C5.32824 8.33333 4.58203 9.07954 4.58203 10C4.58203 10.9205 5.32824 11.6667 6.2487 11.6667ZM6.2487 17.5C7.16916 17.5 7.91536 16.7538 7.91536 15.8333C7.91536 14.9129 7.16916 14.1667 6.2487 14.1667C5.32824 14.1667 4.58203 14.9129 4.58203 15.8333C4.58203 16.7538 5.32824 17.5 6.2487 17.5Z" fill="#9CA3AF" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M15.4154 4.16667C15.4154 5.08712 14.6692 5.83333 13.7487 5.83333C12.8282 5.83333 12.082 5.08712 12.082 4.16667C12.082 3.24619 12.8282 2.5 13.7487 2.5C14.6692 2.5 15.4154 3.24619 15.4154 4.16667ZM13.7487 11.6667C14.6692 11.6667 15.4154 10.9205 15.4154 10C15.4154 9.07954 14.6692 8.33333 13.7487 8.33333C12.8282 8.33333 12.082 9.07954 12.082 10C12.082 10.9205 12.8282 11.6667 13.7487 11.6667ZM13.7487 17.5C14.6692 17.5 15.4154 16.7538 15.4154 15.8333C15.4154 14.9129 14.6692 14.1667 13.7487 14.1667C12.8282 14.1667 12.082 14.9129 12.082 15.8333C12.082 16.7538 12.8282 17.5 13.7487 17.5Z" fill="#9CA3AF" />
                        </svg>
                        FAQ {index + 1}
                    </div>
                </div>
                <div className={s.right}>
                    <Button
                        type="text"
                        danger
                        style={{
                            color: '#C45407',
                        }}
                        onClick={() => {
                            // remove confirm
                            Modal.confirm({
                                className: s.removeModal,
                                title: 'Remove FAQ',
                                content: `Do you want to remove this FAQ - ${item.question} ?`,
                                okText: 'Confirm',
                                onOk: () => {
                                    handleRemove(item);
                                },
                            });
                        }}
                    >
                        Remove
                    </Button>
                    <Button
                        className={s.btn}
                        onClick={() => {
                            setCurrentEdit(item);
                            setShowEdit(true);
                            setCurrentEditInx(index);
                        }}
                    >
                        Edit
                    </Button>
                </div>
            </div>
            <div className={s.content}>
                <div className={s.label}>Question</div>
                <div>
                    {item.question}
                </div>
                <div className={s.label} style={{ marginTop: '12px' }}>Answer</div>
                <div
                    className={s.answer}
                    dangerouslySetInnerHTML={{
                        __html: item.answer || '',
                    }}
                />
            </div>
        </div>
    );
};

const FaqManagement = (props: Props) => {
    const { state, condition, onPreview, pageId } = props;
    const [data, setData] = React.useState<TFaqInfo[]>([]);
    const [showEdit, setShowEdit] = React.useState(false);
    const [currentEdit, setCurrentEdit] = React.useState<TFaqInfo>();
    const [currentEditInx, setCurrentEditInx] = React.useState<number>();
    const [loading, setLoading] = React.useState(false);
    const [targetIndex, setTargetIndex] = React.useState<number>(0);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    const handleDragEnd = async (event:any) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            const oldIndex = data.findIndex((item) => item.id === active.id);
            const newIndex = data.findIndex((item) => item.id === over.id);
            const _items = arrayMove(data, oldIndex, newIndex);
            setData(_items);

            const allSeq:{ id:number, seq:number }[] = [];
            _items.forEach((item, inx) => {
                allSeq.push({
                    id: item.id!,
                    seq: inx,
                });
            });
            const result = await updateHelloKlairtyKlarityPageFaqSeq(allSeq);
            if (!result.error) {
                //message.success('Update success');
            } else {
                console.error(result);
            }
        }
    };

    React.useEffect(() => {
        onPreview?.(data);
    }, [data]);

    const fetchData = async (id:string) => {
        setLoading(true);
        const result = await getHelloKlairtyKlarityPageFaq(parseInt(`${id}`, 10));
        if (!result.error) {
            const _data:TFaqInfo[] = result.data?.data || [];
            _data.sort((a, b) => {
                return a.seq - b.seq;
            });
            setData(_data);
        }
        setLoading(false);
    };

    React.useEffect(() => {
        if (pageId) {
            fetchData(pageId);
        }
    }, [pageId]);

    const handleRemove = async (item:TFaqInfo) => {
        setLoading(true);
        const result = await deleteHelloKlairtyKlarityPageFaq(item.id);
        if (!result.error) {
            message.success('Remove success');
            fetchData(pageId!);
        }
        setLoading(false);
    };

    return (
        <div className={s.wrap}>
            <div className={s.title}>
                <span>FAQ management</span>
                <Button
                    onClick={() => {
                        setCurrentEdit(undefined);
                        setTargetIndex(data.length + 1);
                        setShowEdit(true);
                    }}
                    type="primary"
                >Add new FAQ
                </Button>
            </div>
            <Spin spinning={loading}>
                <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
                    <SortableContext items={data.map((item) => item.id!)} strategy={verticalListSortingStrategy}>
                        <div className={s.itemWrap}>
                            {
                                data?.map((item:TFaqInfo, inx) => {
                                    return (
                                        <SortableItem
                                            handleRemove={handleRemove}
                                            key={item.id}
                                            id={item.id!}
                                            item={item}
                                            index={inx}
                                            setCurrentEdit={setCurrentEdit}
                                            setCurrentEditInx={setCurrentEditInx}
                                            setShowEdit={setShowEdit}
                                        />
                                    );
                                })
                            }
                        </div>
                    </SortableContext>
                </DndContext>
            </Spin>
            <EditFaqModal
                onCancel={() => {
                    setShowEdit(false);
                    setCurrentEdit(undefined);
                }}
                onSuccess={() => {
                    setShowEdit(false);
                    fetchData(pageId!);
                    setCurrentEdit(undefined);
                }}
                targetIndex={targetIndex}
                pageId={pageId ? parseInt(pageId, 10) : 0}
                open={showEdit}
                item={currentEdit}
                index={currentEditInx}
            />
        </div>
    );
};

export default FaqManagement;
