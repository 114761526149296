import React from 'react';
import s from './s.module.less';
import { ECarouselsStatus } from 'types/helloKlarity';

type IProps = {
    status: ECarouselsStatus
};

const CarouselsEmpty: React.FC<IProps> = (props) => {
    const { status } = props;
    return (
        <div className={s.wrap}>
            <h4 className={s.title}>
                {status === ECarouselsStatus.ACTIVE
                    ? 'No active carousels'
                    : 'No inactive carousels'}
            </h4>
            <p className={s.desc}>Start by adding new carousels</p>
            <div className={s.empty} />
        </div>
    );
};

export default CarouselsEmpty;
