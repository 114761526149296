import TinyStore from 'lib/tinyStore';
import { MyChannelsData } from 'types/channel';

const initData:MyChannelsData = {
    freeList: [
        {
            id: -1,
            name: 'WebMD',
        },
        {
            id: -2,
            name: 'NextDoor',
        },
    ],
    premiumList: [],
    standardList: [],
    remainChannelList: [],
};

type ProviderChannelsModal = {
    data: MyChannelsData;
    loading: boolean;
};

const Store = TinyStore.createStore<ProviderChannelsModal>({
    nameSpace: 'klarity',
    key: 'providerChannels',
    crossBundle: false,
    runTime: true,
    initValue: { data: initData, loading: false },
});

export default Store;
