// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--ZGbqa .s-module__toolbar--ZOwlI {
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.s-module__wrap--ZGbqa .s-module__toolbar--ZOwlI .s-module__title--Z5j8d {
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  color: var(--gray-800);
}
.s-module__wrap--ZGbqa .s-module__tableUl--DVHey li {
  list-style: disc;
  margin: 16px 0;
}
.s-module__wrap--ZGbqa .s-module__tableUl--DVHey li .s-module__liText--_j_WQ {
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.s-module__wrap--ZGbqa .s-module__nameWrap--MjM51 {
  margin: 16px 24px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 5;
  text-overflow: ellipsis;
  color: var(--gray-500);
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
.s-module__wrap--ZGbqa .s-module__edit--zmO7o {
  color: var(--main-green);
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/pages/operation/ServiceTemplateManagement/components/KlarityServices/components/KlaritySelectPrograms/s.module.less"],"names":[],"mappings":"AAAA;EAEQ,eAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AAAR;AALA;EAQY,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,sBAAA;AAAZ;AAbA;EAmBY,gBAAA;EACA,cAAA;AAHZ;AAjBA;EAuBgB,gBAAA;EACA,WAAA;EACA,mBAAA;EACA,uBAAA;AAHhB;AAvBA;EAgCQ,iBAAA;EACA,oBAAA;EACA,4BAAA;EACA,gBAAA;EACA,qBAAA;EACA,uBAAA;EACA,sBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AANR;AArCA;EA+CQ,wBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,eAAA;AAPR","sourcesContent":[".wrap {\n    .toolbar {\n        padding: 10px 0;\n        display: flex;\n        align-items: center;\n        justify-content: space-between;\n\n        .title {\n            font-family: Inter;\n            font-size: 20px;\n            font-weight: 500;\n            line-height: 28px;\n            text-align: left;\n            color: var(--gray-800);\n        }\n    }\n\n    .tableUl {\n        li {\n            list-style: disc;\n            margin: 16px 0;\n\n            .liText {\n                overflow: hidden;\n                width: 100%;\n                white-space: nowrap;\n                text-overflow: ellipsis;\n            }\n        }\n    }\n\n    .nameWrap {\n        margin: 16px 24px;\n        display: -webkit-box;\n        -webkit-box-orient: vertical;\n        overflow: hidden;\n        -webkit-line-clamp: 5;\n        text-overflow: ellipsis;\n        color: var(--gray-500);\n        font-family: Inter;\n        font-size: 14px;\n        font-weight: 400;\n        line-height: 20px;\n        text-align: left;\n    }\n\n    .edit {\n        color: var(--main-green);\n        font-family: Inter;\n        font-size: 14px;\n        font-weight: 400;\n        line-height: 20px;\n        text-align: left;\n        cursor: pointer;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--ZGbqa`,
	"toolbar": `s-module__toolbar--ZOwlI`,
	"title": `s-module__title--Z5j8d`,
	"tableUl": `s-module__tableUl--DVHey`,
	"liText": `s-module__liText--_j_WQ`,
	"nameWrap": `s-module__nameWrap--MjM51`,
	"edit": `s-module__edit--zmO7o`
};
export default ___CSS_LOADER_EXPORT___;
