import React, { useCallback, useMemo } from 'react';
import s from './s.module.less';
import { Alert, Table, Tag, Popover, message } from 'antd';
import CommonTable from 'components/CommonTable';
import { TPreviousListing, UniprofileFormData } from 'types/provider';
import ChannelStatus from 'pages/dashboard/ChannelMainPage/components/ActiveChannelStatus';
import { EnumFields, SettingEnum } from 'types/enumerationData';
import useSettings from 'hooks/useSettings';
import copy from 'copy-to-clipboard';
import { Channel, ChannelPayPlanTypeEnum, EKlarityPlanLevel, EListStatus, Meta, TProfileLinkInfo } from 'types/common';
import dayjs from 'dayjs';
import { DEFAULT_CHANNEL_LISTING_LIMITATION, NOT_SET } from 'constants/common';
import { isTrialUser } from 'constants/subscription';
import { EStripeStatus, TSubscriptionItemFromServer, TSubscriptionType } from 'types/subscription';
import ChannelSelectModal from 'components/ChannelSelectModal';
import useLiveChannelsWithCategory from 'hooks/useLiveChannelsWithCategory';
import { MyChannelsData } from 'types/channel';
import { getPlanTypeByInfo } from 'utils/provider';
import useGetSettingData from 'hooks/useGetSettingData';

interface IProps {
    payplan?: TSubscriptionItemFromServer;
    listedChannels?: MyChannelsData;
    auditDetailData?: UniprofileFormData
    refetch?: () => void;
}

export interface FormInterface {
    gmail: string;
    gmailPassword: string;
}

type TTableItem = {
    state: string,
    link: string,
    vfdLink: string,
};

const AccountInfo = ({
    payplan,
    listedChannels = {},
    auditDetailData,
    refetch,
}: IProps) => {
    const { status, subType, type, freeChannelLimit, paidChannelLimit, payFailDate, originStatus } = payplan || {};
    const { freeList, premiumList, standardList, remainChannelList } = listedChannels || {};
    const { profile, profileLinkInfo, previousListings, contractList, practice } = auditDetailData || {};
    const appointmentIntegrationUrl = auditDetailData?.appointmentIntegrationUrl;
    const vfdPhoneLine = auditDetailData?.practice?.vfdPhoneLine;
    const hasPPPLink = profileLinkInfo && profileLinkInfo.length > 0;
    const isFreeTril = isTrialUser(payplan);
    const showEditBpAccount = !!(auditDetailData?.listChannelCount && auditDetailData?.listChannelCount > 0 && (!profile?.gmail));
    const [setting] = useSettings();
    // const auditStatusOptions = setting[EnumFields.CHANNEL_AUDIT_STATUS];
    const paymentStatus = setting[EnumFields.PAYMENT_STATUS];
    const listStatusOptions = setting[EnumFields.LIST_CHANNEL_STATUS];
    // const auditStatusOptions = auditStatusData?.map((item) => ({ label: item.content, value: item.dictKey }));

    const [allStates] = useGetSettingData(EnumFields.STATE);
    const getStatusText = useCallback((listStatus: string) => {
        const statusItem = listStatusOptions?.find((option) => listStatus === option.value);

        return statusItem?.label;
    }, [listStatusOptions]);

    const [showChannelSelectModal, setShowChannelSelectModal] = React.useState(false);
    const [allLiveChannelWithCategory, loadingAllLiveChannelWithCategory] = useLiveChannelsWithCategory(true);

    const [modalSelectType, setModalSelectType] = React.useState(ChannelPayPlanTypeEnum.FREE);

    // ops 状态
    // const [aduitStatusIndeterminate, setAduitStatusIndeterminate] = useState(false);
    // const [aduitCheckAll, setAduitCheckAll] = useState(false);
    // const [aduitList, setAduitList] = useState([]);

    // const handleAduitStatusFilterCheckAll = useCallback((e: CheckboxChangeEvent) => {
    //     const checkedListData = e.target.checked && auditStatusOptions ? auditStatusOptions.map((option) => option.value) : [];

    //     setAduitList(checkedListData);
    //     setAduitStatusIndeterminate(false);
    //     setAduitCheckAll(e.target.checked);

    //     // handleTableChange(null, {
    //     //     name: pendingNameFilter,
    //     //     channelName: pendingChannelNameFilter,
    //     //     assignee: pendingAssigneeFilter,
    //     //     auditStatusList: checkedListData,
    //     //     completeStatusList: listingStatusList,
    //     // });
    // }, [auditStatusOptions]);

    // const handleAduitStatusFilterChange = useCallback((statusList: CheckboxValueType[]) => {
    //     setAduitList(statusList);
    //     setAduitStatusIndeterminate(!!statusList.length && !!auditStatusOptions && statusList.length < auditStatusOptions.length);
    //     setAduitCheckAll(statusList.length === auditStatusOptions?.length);

    //     // handleTableChange(null, {
    //     //     name: pendingNameFilter,
    //     //     channelName: pendingChannelNameFilter,
    //     //     assignee: pendingAssigneeFilter,
    //     //     auditStatusList: statusList,
    //     //     completeStatusList: listingStatusList,
    //     // });
    // }, [auditStatusOptions]);

    const premiumChannelColumn = useMemo(() => {
        return (
            [
                {
                    title: 'CHANNEL',
                    dataIndex: 'name',
                    key: 'name',
                    render: (name: string) => {
                        return (
                            <div className={s.channelNameColumn}>
                                {name}
                            </div>
                        );
                    },
                },
                {
                    title: 'PAYMENT',
                    dataIndex: 'payStatus',
                    key: 'payStatus',
                    render: (payStatus: string) => {
                        return paymentStatus ? paymentStatus.find((e: Meta) => e.value === payStatus)?.label : '';
                    },
                },
                {
                    title: 'LISTING STATUS',
                    dataIndex: 'listStatus',
                    width: '280px',
                    key: 'listStatus',
                    className: s.tableColumn,
                    // filterDropdown: () => {
                    //     return (
                    //         <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    //             <Space>
                    //                 <Checkbox indeterminate={aduitStatusIndeterminate} onChange={handleAduitStatusFilterCheckAll} checked={aduitCheckAll}>
                    //                     Check all
                    //                 </Checkbox>
                    //                 <Checkbox.Group options={auditStatusOptions} value={aduitList} onChange={handleAduitStatusFilterChange} />
                    //             </Space>
                    //         </div>
                    //     );
                    // },
                    render: (listStatus: string, row: Channel) => {
                        const { removeStatus } = row;
                        return removeStatus === 'Pending' ? <div className={s.pendingRemoved}>Pending removed</div> : <ChannelStatus showDot status={listStatus} statusText={getStatusText(listStatus)} />;
                    },
                },
            ]
        );
    }, [getStatusText, paymentStatus]);

    let providerPaymentStatus = 'Not created yet';
    if (paymentStatus && status) {
        const paymentSetting = paymentStatus.find((e: Meta) => e.value === status);

        if (paymentSetting) {
            providerPaymentStatus = paymentSetting.label;
        }
    }

    const allFreeChannelAmount = allLiveChannelWithCategory[ChannelPayPlanTypeEnum.FREE].length || 0;
    const allStandardChannelAmount = allLiveChannelWithCategory[ChannelPayPlanTypeEnum.PAID].length || 0;

    const allFreeChannels = allLiveChannelWithCategory[ChannelPayPlanTypeEnum.FREE];
    const allStandardChannels = allLiveChannelWithCategory[ChannelPayPlanTypeEnum.PAID];
    const allPremiumChannels = allLiveChannelWithCategory[ChannelPayPlanTypeEnum.PREMIUM];

    const freeListWithListedAndUnderReview = freeList?.filter((e: Channel) => e.listStatus !== EListStatus.REJECTED)?.sort((a, b) => {
        if (a.listStatus === EListStatus.LISTED) {
            return -1;
        }
        if (b.listStatus === EListStatus.LISTED) {
            return 1;
        }
        return 0;
    });
    const standardListWithListedAndUnderReview = standardList?.filter((e: Channel) => e.listStatus !== EListStatus.REJECTED).sort((a, b) => {
        if (a.listStatus === EListStatus.LISTED) {
            return -1;
        }
        if (b.listStatus === EListStatus.LISTED) {
            return 1;
        }
        return 0;
    });

    //include under review channels
    const listedFreeChanneIds = freeListWithListedAndUnderReview?.map((e: Channel) => e.id);
    const listedStandardChanneIds = standardListWithListedAndUnderReview?.map((e: Channel) => e.id);

    const unListedFreeChannels = allFreeChannels.filter((e: Channel) => { return !listedFreeChanneIds?.includes(e.id); });
    const unListedStandardChannels = allStandardChannels.filter((e: Channel) => !listedStandardChanneIds?.includes(e.id));

    let maxSelectedAmount: number | null = null;
    if (modalSelectType === ChannelPayPlanTypeEnum.FREE) {
        if (freeChannelLimit === null) {
            maxSelectedAmount = allFreeChannelAmount - (listedStandardChanneIds?.length || 0);
        } else {
            maxSelectedAmount = (freeChannelLimit || 9) - (listedStandardChanneIds?.length || 0);
            if (maxSelectedAmount < 0) {
                maxSelectedAmount = 0;
            }
        }
    }
    if (modalSelectType === ChannelPayPlanTypeEnum.PAID) {
        //null mean unlimited
        if (paidChannelLimit === null) {
            maxSelectedAmount = null;
        } else {
            maxSelectedAmount = (paidChannelLimit || 0) - (listedStandardChanneIds?.length || 0);
            if (maxSelectedAmount < 0) {
                maxSelectedAmount = 0;
            }
        }
    }

    const currentPlanType = getPlanTypeByInfo(payplan);

    //diff with unListedFreeChannels and unListedStandardChannels
    const showAddFreeChannel = (freeListWithListedAndUnderReview?.length || 0) < (freeChannelLimit || allFreeChannelAmount || DEFAULT_CHANNEL_LISTING_LIMITATION.free);
    let showAddStandardChannel = false;
    if (paidChannelLimit === null) {
        showAddStandardChannel = (standardListWithListedAndUnderReview?.length || 0) < (allStandardChannelAmount || DEFAULT_CHANNEL_LISTING_LIMITATION.standard);
    } else {
        showAddStandardChannel = (standardListWithListedAndUnderReview?.length || 0) < (paidChannelLimit || 0);
    }

    const isKlarityUser = currentPlanType === TSubscriptionType.KLARITY
        || currentPlanType === TSubscriptionType.KLARITY_STARTER
        || currentPlanType === TSubscriptionType.KLARITY_PLUS
        || currentPlanType === TSubscriptionType.KLARITY_PRO;

    const isStarter = currentPlanType === TSubscriptionType.KLARITY
        || currentPlanType === TSubscriptionType.KLARITY_STARTER;
    const isPlus = currentPlanType === TSubscriptionType.KLARITY_PLUS;
    const isPro = currentPlanType === TSubscriptionType.KLARITY_PRO;

    const klarityUnListedStandardChannels = allStandardChannels.filter((e: Channel) => {
        if (isStarter) {
            if (e.klarityPlanLevel === EKlarityPlanLevel.PLUS || e.klarityPlanLevel === EKlarityPlanLevel.PRO) {
                return false;
            }
        }
        return !listedStandardChanneIds?.includes(e.id);
    });

    // console.log({
    //     allStandardChannels,
    //     isStarter,
    //     payplan,
    //     listedStandardChanneIds,
    // })

    const allPremiumListCanDirectListByKlarity = allPremiumChannels?.filter((e: Channel) => {
        if (!e.klarityPlanLevel) {
            return false;
        }
        if (isStarter) {
            return e.klarityPlanLevel <= EKlarityPlanLevel.STARTER;
        }
        if (isPlus) {
            return e.klarityPlanLevel <= EKlarityPlanLevel.PLUS;
        }
        if (isPro) {
            return e.klarityPlanLevel <= EKlarityPlanLevel.PRO;
        }
        return false;
    });

    const premiumListWithListedAndUnderReview = premiumList?.filter(
        (e: Channel) => {
            if (!e.klarityPlanLevel) {
                return false;
            }
            if (isStarter) {
                return e.klarityPlanLevel > EKlarityPlanLevel.STARTER;
            }
            if (isPlus) {
                return e.klarityPlanLevel > EKlarityPlanLevel.PLUS;
            }
            return e.listStatus !== EListStatus.REJECTED;
        }).sort((a, b) => {
        if (a.listStatus === EListStatus.LISTED) {
            return -1;
        }
        if (b.listStatus === EListStatus.LISTED) {
            return 1;
        }
        return 0;
    });
    const listedPremiumChanneIds = premiumListWithListedAndUnderReview?.map((e: Channel) => e.id);
    const unListedCanDirectPremiumChannels = allPremiumListCanDirectListByKlarity.filter((e: Channel) => !listedPremiumChanneIds?.includes(e.id));

    const freeAndStandarAndPremiumListWithListedAndUnderReview: Channel[] = [...freeListWithListedAndUnderReview || [], ...standardListWithListedAndUnderReview || [], ...premiumListWithListedAndUnderReview || []];
    const showKlarityAdd = showAddFreeChannel || showAddStandardChannel;

    // console.log({
    //     maxSelectedAmount,
    //     listedFreeChanneIds,
    //     listedStandardChanneIds,
    //     paidChannelLimit,
    //     freeChannelLimit,
    //     allFreeChannelAmount,
    //     allStandardChannelAmount,
    //     freeListWithListedAndUnderReview,
    //     standardListWithListedAndUnderReview,
    // });

    const tableData: TTableItem[] = [];
    if (hasPPPLink) {
        const addLink: string[] = [];
        profileLinkInfo?.forEach((info: TProfileLinkInfo) => {
            try {
                const url = new URL(info.profileLink!);
                const search = url.searchParams;
                const state: string = (search && search.get('state')) || 'General';
                const linkUrl = url.origin + url.pathname;

                const targetState = (allStates as SettingEnum[])?.find((e: SettingEnum) => {
                    return state?.replace(/-/g, ' ').toLowerCase() === e.dictKey?.toLowerCase()
                        || state?.replace(/-/g, ' ').toLowerCase() === (e.content as string)?.toLowerCase();
                });
                if (!addLink.includes(url.href)) {
                    tableData.push({
                        state: targetState ? targetState.content : state,
                        link: url.href,
                        vfdLink: info.vfdLink!,
                    });
                    addLink.push(url.href);
                }
                if (!addLink.includes(linkUrl)) {
                    tableData.push({
                        state: 'General',
                        link: linkUrl,
                        vfdLink: info.vfdLink!,
                    });
                    addLink.push(linkUrl);
                }
            } catch (ex) {

            }
        });
    }
    const columns = [
        {
            title: 'STATE',
            dataIndex: 'state',
            width: '200px',
        },
        {
            title: 'PPP Link',
            dataIndex: 'link',
            key: 'link',
            render: (link?: string) => {
                return (
                    <a className={s.pppLink} href={link} target="_blank" rel="noreferrer">{link}</a>
                );
            },
        },
        {
            title: 'ACTION',
            width: '120px',
            render: (data?: TTableItem) => {
                return (
                    <span
                        className={s.copyText}
                        onClick={() => {
                            copy(data?.link || '', { format: 'text/plain' });
                            message.success('Copied to the clipboard');
                        }}
                    >Copy
                    </span>
                );
            },
        },
        {
            title: 'VFD LINK',
            width: '120px',
            dataIndex: 'vfdLink',
            key: 'vfdLink',
            render: (link?: string) => {
                return (
                    <span
                        onClick={() => {
                            if (link) {
                                window.open(link, '_blank');
                            } else {
                                message.error('Vfd link not found');
                            }
                        }}
                        className={s.copyText}
                    >Open
                    </span>
                );
            },
        },
    ];

    const contractNoticeAddressColumns = [
        {
            title: 'ADDRESS',
            dataIndex: 'contractSignAddress',
            key: 'contractSignAddress',
            width: '200px',
        },
        {
            title: 'CONTRACT',
            dataIndex: 'contractVersion',
            key: 'contractVersion',
            width: '200px',
        },
    ];

    const previousListingsColumns = [
        {
            title: 'CHANNEL',
            dataIndex: 'channelName',
            key: 'channelName',
            width: '200px',
            render: (str?: string) => {
                return <span style={{ fontSize: '14px', color: '#111827', fontWeight: '400' }}>{str}</span>;
            },
        },
        {
            title: 'EMAIL/USER NAME',
            dataIndex: 'account',
            key: 'account',
            width: '200px',
            render: (str?: string) => {
                return <span style={{ fontSize: '14px', color: '#6B7280' }}>{str}</span>;
            },
        },
        {
            title: 'PASSWORD',
            width: '200px',
            render: (data?: TPreviousListing) => {
                return (
                    <span
                        className={s.copyText}
                        onClick={() => {
                            copy(data?.password || '', { format: 'text/plain' });
                            message.success('Copied to the clipboard');
                        }}
                    >Copy
                    </span>
                );
            },
        },
    ];

    const hasPreviousListings = previousListings && previousListings.length > 0;
    const hasContractLinks = contractList && contractList.length > 0;
    const contractListTableData = useMemo(() => {
        if (!contractList) {
            return [];
        }

        return Object.values(
            contractList.reduce((acc, item) => {
                const { contractSignAddress, contractVersion } = item;

                if (!acc[contractSignAddress]) {
                    acc[contractSignAddress] = { contractSignAddress, contractVersion };
                } else {
                    acc[contractSignAddress].contractVersion += `, ${contractVersion}`;
                }

                return acc;
            }, {}),
        );
    }, [contractList]);

    let channelRefText = NOT_SET;
    if (`${auditDetailData?.profile?.channelPreference}` === '1') {
        channelRefText = 'I\'m not interested in listing outside of Klarity.';
    } else if (`${auditDetailData?.profile?.channelPreference}` === '2') {
        channelRefText = 'I\'ll select channels later.';
    }

    return (
        <div className={s.wrap}>
            <div className={s.addAccount}>
                <div className={s.module}>
                    <h3><span>Account information</span></h3>
                    {showEditBpAccount && <Alert style={{ marginBottom: 24 }} message="The auto creation process failed. Please add it manually." type="error" showIcon />}
                    <div className={s.row}>
                        <div
                            style={{
                                width: '30%',
                            }}
                            className={s.displayItem}
                        >
                            <div className={s.label}>Product</div>
                            <div className={s.value}>
                                {auditDetailData?.profile?.product}
                            </div>
                        </div>
                        <div
                            style={{
                                width: '30%',
                            }}
                            className={s.displayItem}
                        >
                            <div className={s.label}>Plan</div>
                            <div className={s.value}>
                                <span>{type || 'Not created yet'}</span>
                                {
                                    isFreeTril && <span className={s.freeTrial}>Free Trial</span>
                                }
                                {
                                    originStatus && (![EStripeStatus.ACTIVE, EStripeStatus.TRIALING].includes(originStatus)) &&
                                    <Tag color="#f50" style={{ fontWeight: 'bold', marginLeft: '8px' }}>{originStatus.replace('_', ' ').toLocaleUpperCase()}</Tag>
                                }
                            </div>
                        </div>
                        <div
                            style={{
                                width: '30%',
                            }}
                            className={s.displayItem}
                        >
                            <div className={s.label}>Contract version</div>
                            <div className={s.value}>
                                {hasContractLinks ? contractList?.map((e) => e.contractVersion).join(', ') : 'None'}
                            </div>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div
                            style={{
                                width: '30%',
                            }}
                            className={s.displayItem}
                        >
                            <div className={s.label}>Billing cycle</div>
                            <div className={s.value}>
                                {subType || 'Not created yet'}
                            </div>
                        </div>
                        <div
                            style={{
                                width: '300px',
                            }}
                            className={s.displayItem}
                        >
                            <div className={s.label}>Payment status</div>
                            <div className={s.value}>
                                <p>{providerPaymentStatus}</p>
                                {
                                    status === 'failed' &&
                                    (
                                        <div
                                            style={{
                                                width: '300px',
                                            }}
                                            className={s.displayItem}
                                        >
                                            <div className={s.label}>Back to free in</div>
                                            <div className={s.value}>{payFailDate ? dayjs(payFailDate).add(7, 'day').format('MM/DD/YYYY') : ''}</div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    {hasPPPLink &&
                        <div className={s.row}>
                            {/* <div className={s.col}>
                                <div className={s.label}>Assigned VFD</div>
                                <div className={s.value}>Assigned VFD</div>
                            </div> */}
                            <div
                                style={{
                                    width: '80%',
                                }}
                                className={s.displayItem}
                            >
                                <div className={s.label}>Klarity PPP</div>
                                <div className={s.value}>
                                    <CommonTable
                                        bordered
                                        rowKey="link"
                                        columns={columns}
                                        data={tableData || []}
                                        pagination={false}
                                    />
                                </div>
                                {/* {
                                    profileLinks?.map((link: string) => {
                                        return (
                                            <div className={s.linkValue} key={link}>
                                                <span className={s.link}>{link}</span>
                                                <span
                                                    onClick={() => {
                                                        copy(link || '', { format: 'text/plain' });
                                                        message.success('Copied to the clipboard');
                                                    }}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                        <path d="M6.66634 5.83333V12.5C6.66634 13.4205 7.41253 14.1667 8.33301 14.1667H13.333M6.66634 5.83333V4.16667C6.66634 3.24619 7.41253 2.5 8.33301 2.5H12.1545C12.3755 2.5 12.5875 2.5878 12.7438 2.74408L16.4223 6.42259C16.5785 6.57887 16.6663 6.79083 16.6663 7.01184V12.5C16.6663 13.4205 15.9201 14.1667 14.9997 14.1667H13.333M6.66634 5.83333H5.33301C4.22844 5.83333 3.33301 6.72876 3.33301 7.83333V15.8333C3.33301 16.7538 4.0792 17.5 4.99967 17.5H11.333C12.4376 17.5 13.333 16.6046 13.333 15.5V14.1667" stroke="#00816B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </span>
                                            </div>
                                        );
                                    })
                                } */}
                            </div>
                        </div>
                    }
                    {
                        !hasPPPLink &&
                        <div className={s.row}>
                            <div
                                style={{
                                    width: '600px',
                                }}
                                className={s.displayItem}
                            >
                                <div className={s.label}>Klarity PPP</div>
                                <div className={s.value}>Not activated yet</div>
                            </div>
                        </div>
                    }
                    <div className={s.row}>
                        <div
                            style={{
                                width: '700px',
                            }}
                            className={s.displayItem}
                        >
                            <div className={s.label}>Contract notice address</div>
                            <div className={s.value}>
                                { !hasContractLinks && 'No contract yet'}
                                {
                                    hasContractLinks &&
                                    <CommonTable
                                        bordered
                                        rowKey="contractId"
                                        columns={contractNoticeAddressColumns}
                                        data={contractListTableData || []}
                                        pagination={false}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div
                            style={{
                                width: '80%',
                            }}
                            className={s.displayItem}
                        >
                            <div className={s.label}>Channel preference</div>
                            <div className={s.value}>
                                {channelRefText || NOT_SET}
                            </div>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div
                            style={{
                                width: '700px',
                            }}
                            className={s.displayItem}
                        >
                            <div className={s.label}>Previous listings</div>
                            <div className={s.value}>
                                {!hasPreviousListings && 'No previous listing channels'}
                                {
                                    hasPreviousListings &&
                                    <CommonTable
                                        bordered
                                        rowKey="channelName"
                                        columns={previousListingsColumns}
                                        data={previousListings || []}
                                        pagination={false}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    {
                        !isKlarityUser &&
                        <>
                            <div className={s.row}>
                                <div
                                    style={{
                                        width: '800px',
                                    }}
                                    className={s.displayItem}
                                >
                                    <div className={s.label}>{`Listed basic channels (${freeListWithListedAndUnderReview ? freeListWithListedAndUnderReview.length : 0}/${freeChannelLimit === null ? allFreeChannelAmount : freeChannelLimit})`}</div>
                                    <div className={`${s.value} ${s.channelLogos}`}>
                                        {
                                            freeListWithListedAndUnderReview?.map((channel: Channel) => {
                                                const isListed = channel.listStatus === EListStatus.LISTED;
                                                if (isListed) {
                                                    return (
                                                        <Popover content={<span>Live</span>} trigger="hover">
                                                            <img className={isListed ? s.channelLogo : `${s.channelLogo} ${s.channelLogoPending}`} src={channel.logo} />
                                                        </Popover>);
                                                }
                                                return (
                                                    <Popover content={<span>Under review</span>} trigger="hover">
                                                        <img className={`${s.channelLogo} ${s.channelLogoPending}`} src={channel.logo} />
                                                    </Popover>);
                                            })
                                        }
                                        {
                                            showAddFreeChannel &&
                                            <span
                                                onClick={() => {
                                                    setModalSelectType(ChannelPayPlanTypeEnum.FREE);
                                                    setShowChannelSelectModal(true);
                                                }}
                                                className={s.addIcon}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="51" height="50" viewBox="0 0 51 50" fill="none">
                                                    <rect x="1" y="0.5" width="49" height="49" rx="24.5" stroke="#9CA3AF" strokeDasharray="4 4" />
                                                    <path d="M25.5 12.5V25M25.5 25V37.5M25.5 25H38M25.5 25L13 25" stroke="#6B7280" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div
                                    style={{
                                        width: '800px',
                                    }}
                                    className={s.displayItem}
                                >
                                    <div className={s.label}>Listed standard channels {paidChannelLimit !== null ? ` (${standardListWithListedAndUnderReview ? standardListWithListedAndUnderReview.length : 0}/${paidChannelLimit})` : ''}</div>
                                    <div className={`${s.value} ${s.channelLogos}`}>
                                        {
                                            standardListWithListedAndUnderReview?.map((channel: Channel) => {
                                                const isListed = channel.listStatus === EListStatus.LISTED;
                                                if (isListed) {
                                                    return (
                                                        <Popover content={<span>Live</span>} trigger="hover">
                                                            <img className={isListed ? s.channelLogo : `${s.channelLogo} ${s.channelLogoPending}`} src={channel.logo} />
                                                        </Popover>);
                                                }
                                                return (
                                                    <Popover content={<span>Under review</span>} trigger="hover">
                                                        <img className={`${s.channelLogo} ${s.channelLogoPending}`} src={channel.logo} />
                                                    </Popover>);
                                            })
                                        }
                                        {
                                            showAddStandardChannel &&
                                            <span
                                                onClick={() => {
                                                    setModalSelectType(ChannelPayPlanTypeEnum.PAID);
                                                    setShowChannelSelectModal(true);
                                                }}
                                                className={s.addIcon}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="51" height="50" viewBox="0 0 51 50" fill="none">
                                                    <rect x="1" y="0.5" width="49" height="49" rx="24.5" stroke="#9CA3AF" strokeDasharray="4 4" />
                                                    <path d="M25.5 12.5V25M25.5 25V37.5M25.5 25H38M25.5 25L13 25" stroke="#6B7280" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {
                        isKlarityUser && (
                            <div className={s.row}>
                                <div
                                    style={{
                                        width: '800px',
                                    }}
                                    className={s.displayItem}
                                >
                                    <div className={s.label}>Listed channels included the plan {paidChannelLimit !== null ? ` (${standardListWithListedAndUnderReview ? standardListWithListedAndUnderReview.length : 0}/${paidChannelLimit})` : ''}</div>
                                    <div className={`${s.value} ${s.channelLogos}`}>
                                        {
                                            freeAndStandarAndPremiumListWithListedAndUnderReview?.map((channel: Channel) => {
                                                const isListed = channel.listStatus === EListStatus.LISTED;
                                                if (isListed) {
                                                    return (
                                                        <Popover content={<span>Live</span>} trigger="hover">
                                                            <img className={isListed ? s.channelLogo : `${s.channelLogo} ${s.channelLogoPending}`} src={channel.logo} />
                                                        </Popover>);
                                                }
                                                return (
                                                    <Popover content={<span>Under review</span>} trigger="hover">
                                                        <img className={`${s.channelLogo} ${s.channelLogoPending}`} src={channel.logo} />
                                                    </Popover>);
                                            })
                                        }
                                        {
                                            showKlarityAdd &&
                                            <span
                                                onClick={() => {
                                                    setModalSelectType(ChannelPayPlanTypeEnum.PAID);
                                                    setShowChannelSelectModal(true);
                                                }}
                                                className={s.addIcon}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="51" height="50" viewBox="0 0 51 50" fill="none">
                                                    <rect x="1" y="0.5" width="49" height="49" rx="24.5" stroke="#9CA3AF" strokeDasharray="4 4" />
                                                    <path d="M25.5 12.5V25M25.5 25V37.5M25.5 25H38M25.5 25L13 25" stroke="#6B7280" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </span>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    <div className={s.row}>
                        <div
                            style={{
                                width: '800px',
                            }}
                            className={s.displayItem}
                        >
                            <div className={s.label}>{`Listed Premium channels (${premiumList ? premiumList.length : 0})`}</div>
                            <div className={`${s.value} ${s.copyWrap}`}>
                                <Table
                                    columns={premiumChannelColumn}
                                    className={s.channelTable}
                                    dataSource={premiumList || []}
                                    pagination={
                                        { hideOnSinglePage: true }
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <ChannelSelectModal
                    isOps
                    isKlarityUser={isKlarityUser}
                    title={isKlarityUser ? 'List on all channels in the plan' : (modalSelectType === ChannelPayPlanTypeEnum.PAID ? 'List on all standard channels' : 'List on all basic channels')}
                    show={showChannelSelectModal}
                    channelList={modalSelectType === ChannelPayPlanTypeEnum.PAID ? (isKlarityUser ? klarityUnListedStandardChannels : unListedStandardChannels) : unListedFreeChannels}
                    channelListWithCategory={isKlarityUser ? { 'Basic channels': unListedFreeChannels, 'Standard channels': isKlarityUser ? klarityUnListedStandardChannels : unListedStandardChannels, 'Premium channels': isKlarityUser ? unListedCanDirectPremiumChannels : [] } : undefined}
                    onClose={() => {
                        setShowChannelSelectModal(false);
                    }}
                    onSubmit={() => {
                        setShowChannelSelectModal(false);
                        refetch?.();
                    }}
                    showTopNav={modalSelectType === ChannelPayPlanTypeEnum.PAID && !isKlarityUser}
                    providerId={profile?.id}
                    maxSelectedAmount={maxSelectedAmount}
                />
            </div>
        </div>
    );
};

export default AccountInfo;
