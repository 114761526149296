import { Permission as PermissionType } from 'types/access';

import { PermissionModuleKeys } from 'constants/access';

const transfromPermission = (privList: PermissionType[] = []) => {
    const permissions = {} as Record<PermissionModuleKeys, boolean>;
    const loop = (permissionsList: PermissionType[]) => {
        permissionsList.forEach((permission) => {
            const { children, name } = permission;

            permissions[name as PermissionModuleKeys] = true;

            if (children && children.length > 0) {
                loop(children);
            }
        });
    };

    loop(privList || []);

    return permissions;
};

export default transfromPermission;
