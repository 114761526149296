import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import cx from 'classnames';
import ZocdocIcon from 'assets/channel/zocdoc.png';
import ZocdocLogo from 'assets/channel/zoc_logo.png';
import { Button, Select, Checkbox } from 'antd';
import { StarFilled, EnvironmentFilled, VideoCameraFilled, ClockCircleOutlined, CalendarOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import useProviderFullInfo from 'hooks/useProviderFullInfo';
import { UniProfile, Provider } from 'types/provider';
import dayjs from 'dayjs';
import s from './s.module.less';
import PreviewWrapper from 'components/PreviewWrapper';

export interface FormInterface {
    email: string;
}

const Page = () => {
    const { id } = useParams();
    const [params] = useSearchParams();
    const channelId = params.getAll('channelId')[0];
    const uid = id ? parseInt(id, 10) : 0;
    const cid = channelId ? parseInt(channelId, 10) : 0;
    const [providerFullInfo] = useProviderFullInfo(uid);
    const uniProfile: UniProfile | undefined = providerFullInfo?.uniProfile;
    const basicInfo: Provider | undefined = providerFullInfo?.basicInfo;

    return (
        <PreviewWrapper channelId={cid} logo={ZocdocLogo} name="Zocdoc">
            <>
                <div className={s.description}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M3 0C3.55228 0 4 0.447715 4 1V2H5C5.55228 2 6 2.44772 6 3C6 3.55228 5.55228 4 5 4H4V5C4 5.55228 3.55228 6 3 6C2.44772 6 2 5.55228 2 5V4H1C0.447715 4 0 3.55228 0 3C0 2.44772 0.447715 2 1 2H2V1C2 0.447715 2.44772 0 3 0ZM3 10C3.55228 10 4 10.4477 4 11V12H5C5.55228 12 6 12.4477 6 13C6 13.5523 5.55228 14 5 14H4V15C4 15.5523 3.55228 16 3 16C2.44772 16 2 15.5523 2 15V14H1C0.447715 14 0 13.5523 0 13C0 12.4477 0.447715 12 1 12H2V11C2 10.4477 2.44772 10 3 10Z" fill="#FBBF24" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.99995 0C10.4537 0 10.8505 0.305483 10.9667 0.744107L12.1459 5.19893L15.4997 7.13381C15.8092 7.31241 15.9999 7.64262 15.9999 8C15.9999 8.35738 15.8092 8.6876 15.4997 8.86619L12.1459 10.8011L10.9667 15.2559C10.8505 15.6945 10.4537 16 9.99995 16C9.54622 16 9.14935 15.6945 9.03324 15.2559L7.85402 10.8011L4.50027 8.86618C4.19072 8.68759 4 8.35738 4 8C4 7.64262 4.19072 7.31241 4.50027 7.13382L7.85402 5.19893L9.03324 0.744107C9.14935 0.305483 9.54622 0 9.99995 0Z" fill="#FBBF24" />
                    </svg>
                    We create this preview based on your UniProfile.
                </div>

                <div className={s.content}>
                    <div className={s.main}>
                        <img src={ZocdocIcon} height={31} className={s.logo} />
                        <div className={s.doctorInfoWrapper}>
                            <img
                                width="155px"
                                height="155px"
                                src={uniProfile?.photoList?.[0]}
                                alt="Practice avatar"
                            />
                            <div className={s.doctorInfo}>
                                <div className={s.doctorName}>{uniProfile?.firstName} {uniProfile?.lastName}</div>
                                <div>{uniProfile?.title}</div>
                                <div><StarFilled style={{ color: '#FC660A' }} /><span>5.00</span><span>(33)</span></div>
                                <div><EnvironmentFilled /> <span>{`${uniProfile?.address}, ${uniProfile?.city}, ${uniProfile?.state}, ${uniProfile?.zip}`}</span></div>
                                <div>
                                    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.31012 2.47275L8.5 0.875L15.6899 2.47275C15.8842 2.51594 16.058 2.62409 16.1825 2.77935C16.3071 2.93461 16.375 3.1277 16.375 3.32675V12.0654C16.3749 12.9296 16.1615 13.7805 15.7537 14.5425C15.3458 15.3045 14.7561 15.954 14.037 16.4334L8.5 20.125L2.963 16.4334C2.24399 15.9541 1.6544 15.3047 1.24655 14.5429C0.838706 13.7811 0.625207 12.9304 0.625 12.0662V3.32675C0.625034 3.1277 0.692933 2.93461 0.817491 2.77935C0.942049 2.62409 1.11582 2.51594 1.31012 2.47275ZM7.625 8.75H5V10.5H7.625V13.125H9.375V10.5H12V8.75H9.375V6.125H7.625V8.75Z" fill="#418AED" />
                                    </svg>
                                    <span>See if they’re in network</span>
                                </div>

                            </div>
                        </div>
                        <div className={s.apptWrapper}>
                            <div className={s.apptTitle}>Book an appointment for free</div>
                            <div className={s.apptContent}>
                                <div className={s.apptLeft}>
                                    <div className={s.apptDesc}>{uniProfile?.firstName} {uniProfile?.lastName} partners with Zocdoc to schedule patient appointments</div>
                                    <div className={s.vedio}><VideoCameraFilled style={{ color: '#418AED', marginRight: 5 }} />External video visit</div>
                                    <Select
                                        defaultValue="Initial Visit"
                                        style={{ width: '100% ', marginBottom: 20 }}
                                    />
                                    <Checkbox>I’m a new patient to this practice</Checkbox>
                                </div>
                                <div className={s.apptRight}>
                                    <div className={s.apptTime}>{dayjs().format('ddd, MMM DD')} - {dayjs().add(7, 'day').format('ddd, MMM DD')}</div>
                                    <div className={s.apptTable}>
                                        <span>{dayjs().format('ddd')} <br /> {dayjs().format('MMM DD')} <br /> 11 <br /> appts </span>
                                        <span>{dayjs().add(1, 'day').format('ddd')} <br /> {dayjs().add(1, 'day').format('MMM DD')} <br /> 11 <br /> appts </span>
                                        <span>{dayjs().add(2, 'day').format('ddd')} <br /> {dayjs().add(2, 'day').format('MMM DD')} <br /> 11 <br /> appts </span>
                                        <span>{dayjs().add(3, 'day').format('ddd')} <br /> {dayjs().add(3, 'day').format('MMM DD')} <br /> 11 <br /> appts </span>
                                        <span>{dayjs().add(4, 'day').format('ddd')} <br /> {dayjs().add(4, 'day').format('MMM DD')} <br /> 11 <br /> appts </span>
                                        <span>{dayjs().add(5, 'day').format('ddd')} <br /> {dayjs().add(5, 'day').format('MMM DD')} <br /> 11 <br /> appts </span>
                                        <span>{dayjs().add(6, 'day').format('ddd')} <br /> {dayjs().add(6, 'day').format('MMM DD')} <br /> 11 <br /> appts </span>
                                        <span>{dayjs().add(7, 'day').format('ddd')} <br /> {dayjs().add(7, 'day').format('MMM DD')} <br /> 11 <br /> appts </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={s.related}>
                        <div className={cx(s.tabs, s.text1)}><span>About</span><span>Reviews</span><span>FAQs</span></div>
                        <div className={s.features}>
                            <div className={s.feature}>
                                <StarFilled style={{ color: '#FC660A', fontSize: 20 }} />
                                <div>
                                    <p className={s.text2}>Highly recommended</p>
                                    <p className={s.text3}>100% of patients gave this doctor 5 stars</p>
                                </div>
                            </div>
                            <div className={s.feature}>
                                <ClockCircleOutlined style={{ color: '#FC660A', fontSize: 20 }} />
                                <div>
                                    <p className={s.text2}>Excellent wait time
                                    </p>
                                    <p className={s.text3}>100% of patients waited less than 30 minutes</p>
                                </div>
                            </div>
                            <div className={s.feature}>
                                <CalendarOutlined style={{ color: '#FC660A', fontSize: 20 }} />
                                <div>
                                    <p className={s.text2}>New patient appointments
                                    </p>
                                    <p className={s.text3}>Appointments available for new patients on Zocdoc</p>
                                </div>
                            </div>
                        </div>
                        <div className={cx(s.sectionTitle, s.text1, s.aboutTitle)}>
                            About {uniProfile?.firstName} {uniProfile?.lastName}
                        </div>
                        <div className={cx(s.listTitle, s.text2)}>Focus areas</div>
                        <ul className={cx(s.list, s.text3)}>
                            {uniProfile?.specialityInHealth?.split(',').map((item) => {
                                return <li key={item}>{item}</li>;
                            })}
                        </ul>
                        <Button type="link" className={cx(s.viewTreated, s.text2)}>View all issues treated</Button>
                        <div className={cx(s.listTitle, s.text2)}>Clientele seen</div>
                        <ul className={cx(s.list, s.text3)}>
                            <li key="over18">Over 18 years old</li>
                            <li key="under65">Under 65 years old</li>
                        </ul>
                        <div className={cx(s.listTitle, s.text2)}>Treatment approaches</div>
                        <ul className={cx(s.list, s.text3)}>
                            {uniProfile?.providerPractice?.treatmentApproach?.split('•').filter((item) => !!item).map((item) => {
                                return <li key={item}>{item}</li>;
                            })}
                        </ul>
                        <div className={cx(s.listTitle, s.text2)}>Languages spoken</div>
                        <ul className={cx(s.list, s.text3)}>
                            <li key="primaryLanguage">{uniProfile?.primaryLanguage ?? '-'}</li>
                            <li key="foreignLanguage">{uniProfile?.foreignLanguage ?? '-'}</li>
                        </ul>
                        <div className={cx(s.sectionTitle, s.text1)}>Getting to know {uniProfile?.firstName} {uniProfile?.lastName}</div>
                        <div
                            className={cx(s.setctionContent, s.text3)}
                            dangerouslySetInnerHTML={{
                                __html: uniProfile?.intro || '',
                            }}
                        />
                        <div className={cx(s.sectionTitle, s.text1)}>What is your typical process for working with clients?</div>
                        <div className={cx(s.setctionContent, s.text3)}>Tell me your story, let patient do the talking</div>
                        <div className={cx(s.sectionTitle, s.text1)}>In-network insurances</div>
                        {uniProfile?.insuranceList &&
                        <ul className={cx(s.list, s.text3)}>
                            {uniProfile?.providerPractice?.treatmentApproach?.split('•').filter((item) => !!item).map((item) => {
                                return <li key={item}>{item}</li>;
                            })}
                        </ul>}
                        {!uniProfile?.insuranceList &&
                        <div className={cx(s.setctionContent, s.insuranceWrapper)}>
                            <img src={ZocdocLogo} width={68} />
                            <div>
                                <p className={cx(s.insuranceTitle, s.text3)}>{uniProfile?.firstName} {uniProfile?.lastName},{uniProfile?.title}  is not in-network with any insurances</p>
                                <p className={s.text3}>It’s common for mental health providers to be out-of-network. Many insurance plans offer out-of-network coverage, so you may get partially reimbursed. <Button type="link">Learn more</Button></p>
                            </div>
                        </div>}
                        <div className={cx(s.sectionTitle, s.text1)}>Out-of-pocket fees and assistance<ExclamationCircleOutlined style={{ fontSize: 13, color: '#667385', marginRight: 8 }} /></div>
                        <ul className={cx(s.setctionContent, s.text3, s.providerServiceList)}>
                            {
                                uniProfile?.providerServiceList?.map((item) => { return <li key={item?.name}>{item?.name}</li>; })
                            }
                        </ul>
                        <div className={cx(s.sectionTitle, s.text1)}>Education and background</div>
                        <div className={cx(s.text3, s.subTitle)}>Education and training</div>
                        <ul className={cx(s.setctionContent, s.text3, s.eduList)}>
                            {
                                uniProfile?.providerEducationList?.map((item) => { return <li key={`${item?.schoolName},${item?.major}`}>{item?.schoolName},{item?.major}</li>; })
                            }
                        </ul>
                        <div className={cx(s.text3, s.subTitle)}>NPI number <ExclamationCircleOutlined style={{ fontSize: 13, color: '#667385', marginRight: 8 }} /></div>
                        <div className={cx(s.setctionContent, s.text3)}>{uniProfile?.npi}</div>
                    </div>
                </div>
            </>
        </PreviewWrapper>
    );
};

export default Page;
