// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__addLinkBtn--kUMIU {
  color: var(--primary-color);
  cursor: pointer;
  font-weight: 500;
}
.s-module__module--kqZIg {
  background: #fff;
  height: auto;
  color: #6B7280;
}
.s-module__module--kqZIg .s-module__rowHeader--fh3NI {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--gray-500, var(--Placeholder, #6B7280));
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.s-module__module--kqZIg .s-module__content--UAX48 {
  padding: 24px;
  border-radius: 10px;
  border: 1px solid var(--gray);
  background: #FFF;
  margin-top: 12px;
  margin-bottom: 24px;
}
.s-module__module--kqZIg .s-module__row--BelMf {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.s-module__module--kqZIg .s-module__row--BelMf .s-module__displayItem--Z6gH1 {
  margin-bottom: 12px;
}
.s-module__module--kqZIg .s-module__row--BelMf .s-module__displayItem--Z6gH1 .s-module__label--er9fo {
  color: #6B7280;
  font-size: 14px;
  line-height: 20px;
}
.s-module__module--kqZIg .s-module__rowItem--dkLGs {
  margin-right: 36px;
}
.s-module__module--kqZIg .s-module__colItem--hKrBh {
  display: flex;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/pages/operation/HelloKlarityPageManagement/components/InternalLinkManagement/components/EditOnlineServiceModal/s.module.less"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,eAAA;EACA,gBAAA;AACF;AAEA;EACE,gBAAA;EACA,YAAA;EACA,cAAA;AAAF;AAHA;EAMI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;EACA,mDAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAAJ;AAfA;EAmBI,aAAA;EACA,mBAAA;EACA,6BAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;AADJ;AAvBA;EA4BI,aAAA;EACA,mBAAA;EACA,uBAAA;AAFJ;AA5BA;EAuCM,mBAAA;AARN;AA/BA;EAkCQ,cAAA;EACA,eAAA;EACA,iBAAA;AAAR;AApCA;EA4CI,kBAAA;AALJ;AAvCA;EAgDI,aAAA;EACA,sBAAA;AANJ","sourcesContent":[".addLinkBtn {\n  color: var(--primary-color);\n  cursor: pointer;\n  font-weight: 500;\n}\n\n.module {\n  background: #fff;\n  height: auto;\n  color: #6B7280;\n\n  .rowHeader {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    color: var(--gray-500, var(--Placeholder, #6B7280));\n    font-family: Inter;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 20px;\n  }\n\n  .content {\n    padding: 24px;\n    border-radius: 10px;\n    border: 1px solid var(--gray);\n    background: #FFF;\n    margin-top: 12px;\n    margin-bottom: 24px;\n  }\n\n  .row {\n    display: flex;\n    flex-direction: row;\n    align-items: flex-start;\n\n    .displayItem {\n      .label {\n        color: #6B7280;\n        font-size: 14px;\n        line-height: 20px;\n      }\n\n      margin-bottom: 12px;\n    }\n  }\n\n  .rowItem {\n    margin-right: 36px;\n  }\n\n  .colItem {\n    display: flex;\n    flex-direction: column;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addLinkBtn": `s-module__addLinkBtn--kUMIU`,
	"module": `s-module__module--kqZIg`,
	"rowHeader": `s-module__rowHeader--fh3NI`,
	"content": `s-module__content--UAX48`,
	"row": `s-module__row--BelMf`,
	"displayItem": `s-module__displayItem--Z6gH1`,
	"label": `s-module__label--er9fo`,
	"rowItem": `s-module__rowItem--dkLGs`,
	"colItem": `s-module__colItem--hKrBh`
};
export default ___CSS_LOADER_EXPORT___;
