import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import s from './s.module.less';
import { useParams, useNavigate } from 'react-router-dom';
import commonS from 'styles/common.module.less';
import { Spin, Button, message, Modal, Alert, Space } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import {
    updateProviderChannelInfo,
    // providerChannelAuditGoLive,
    channelWaitingApproval,
    updateUniprofileAuditStatus,
    syncSnapshot,
    syncEhr,
    getEhrInitStatus,
    // exportProviderByPorivderId,
} from 'api/operation';
import OnHoldImg from 'assets/operation/sendFeedback.png';
import ApproveImg from 'assets/operation/approve.png';
import Motivation from 'assets/operation/motivation.svg';
import { PATH } from 'constants/path';
// import ReviewModal from './components/ReviewModal';
import useFetchAuditDetailData from './hooks/useFetchAuditDetailData';
import { transferFormValue } from 'utils/channel';
import { StepValue } from 'pages/CreateUniProfilePage/types';
import Detail from './components/Detail';
// import useFetchReviewsData from './hooks/useFetchReviewsData';
// import StatusTag from 'components/StatusTag';
import ChannelAuditStatusTag from 'components/ChannelAuditStatusTag';
import UniprofileAuditStatusTag from 'components/UniprofileAuditStatusTag';
import { NoticeType } from 'antd/es/message/interface';
import globalFormsEditStatusStore from 'store/ChannelApplicationProvider/FormsEditStatus';
import { IProfileFormSubmit } from 'types/channelApplicationProvider';
import { EType } from '../ProviderPage';
import { baseURL } from 'api';
import { getUrlParam } from 'utils/common';
import { EAuditStatus, EChanelAuditStatus } from 'types/operation';
import useFetchAuditDetailSnapshotData from './hooks/useFetchAuditDetailSnapshotData';
import { getShadowDataFromTwoData } from 'data/provider';
// import { useRequest } from 'ahooks';
// import dayjs from 'dayjs';
// import { getProviderNameDisplay } from 'utils/provider';
// import { downloadFileWithBlob } from 'utils/file';
// import { getUserToken } from 'utils/localstore';
import ProfileStore from 'store/Profile';

type ISubmitData = Partial<IProfileFormSubmit>;
export interface FormInterface {
    gmail: string;
    gmailPassword: string;
}

const getAllKeys = (obj: any): string[] => {
    let keys: string[] = [];

    try {
        for (const key in obj) {
            //@ts-nocheck
            // eslint-disable-next-line no-prototype-builtins
            if (obj.hasOwnProperty(key)) { // 添加条件语句以过滤掉原型链上的属性
                keys.push(key);
                if (typeof obj[key] === 'object') {
                    keys = keys.concat(getAllKeys(obj[key]));
                }
            }
        }
    } catch (e) {
        console.error(e);
    }

    return keys;
};

const Page = () => {
    const { id, pid } = useParams();
    const updated: boolean = !!getUrlParam('updated');
    const channelName = decodeURIComponent(getUrlParam('channelName') || '');
    const channelId = id ? parseInt(id, 10) : 0;
    const providerId = pid ? parseInt(pid, 10) : 0;
    const navigate = useNavigate();
    const [messageApi, messageContextHolder] = message.useMessage();
    const [saveLoading, setSaveLoading] = useState<boolean>(false);
    // const [showReviewModal, setShowReviewModal] = useState(false);
    const [showOnHold, setShowOnHold] = useState(false);
    const [showApprove, setShowApprove] = useState(false);
    const [showListed, setShowListed] = useState(false);
    const [showWaitApproveModal, setShowWaitApproveModal] = useState(false);
    const [, setGloalEditStatus] = globalFormsEditStatusStore.useStore();
    const isUniprofileDetail = channelId === -1;

    // const [form] = Form.useForm<FormInterface>();
    // const [getUser] = ProviderStore.useStore();
    // const user = getUser('data');

    const [hasSettleAll, setHasSettleAll] = useState(false);
    const [settling, setSettling] = useState(false);
    const [syncingEhr, setSyncingEhr] = useState(false);

    const { data: auditDetailData, loading, refetch } = useFetchAuditDetailData({
        channelId,
        providerId,
    });
    const { data: auditDetailSnapshotData, snapshotLoading, refetchSnapshot } = useFetchAuditDetailSnapshotData(updated ? providerId : undefined);
    const dataAfterChange = (updated && !hasSettleAll) ? getShadowDataFromTwoData(auditDetailData, auditDetailSnapshotData) : undefined;
    const useData = (updated && auditDetailSnapshotData && dataAfterChange && auditDetailData && !hasSettleAll) ? dataAfterChange : auditDetailData;

    const [finishEhrInit, setFinishEhrInit] = useState(false);
    const [getProfileStore, setProfileStore] = ProfileStore.useStore();

    const allKeys: string[] = getAllKeys(useData);
    const hasNew = !!(allKeys.find((item) => item.includes('New')));

    const showNewBtn = hasNew && updated && auditDetailSnapshotData && !hasSettleAll;
    const showAlert = updated && auditDetailSnapshotData && !hasSettleAll;

    const detailRef = useRef<any>();
    // const { reviews, refetchReviews } = useFetchReviewsData(channelId, providerId);
    const { profile } = useData;
    const { auditStatus, updateStatus } = profile;
    useEffect(() => {
        return () => setGloalEditStatus('globalEditStatus', false);
    }, []);

    useEffect(() => {
        const check = async () => {
            const result = await getEhrInitStatus(providerId);
            if (!result.error) {
                const status = result.data?.data;
                if (status?.ehrSetUpCompleted) {
                    setFinishEhrInit(true);
                }
            }
        };
        if (providerId) {
            check();
        }
    }, [providerId]);

    const handleMessageContent = useCallback((type: NoticeType, content: string) => {
        messageApi.open({
            type,
            content,
        });
    }, [messageApi]);

    // const handleAddReviewsSuccess = useCallback(() => {
    //     refetchReviews();
    //     setShowReviewModal(false);
    // }, [refetchReviews]);

    // const handleShowReviewModal = useCallback(async () => {
    //     setShowReviewModal(true);
    // }, []);

    const handleChangeConfirm = async () => {
        setHasSettleAll(true);
        setSettling(true);
        await refetch();
        await syncSnapshot(providerId);
        setSettling(false);
    };

    // const base64ToBlob = useCallback((base64: string, contentType: string) => {
    //     const byteCharacters = atob(base64);
    //     const byteNumbers = new Array(byteCharacters.length);
    //     for (let i = 0; i < byteCharacters.length; i++) {
    //         byteNumbers[i] = byteCharacters.charCodeAt(i);
    //     }
    //     const byteArray = new Uint8Array(byteNumbers);
    //     return new Blob([byteArray], { type: contentType });
    // }, []);

    // const downloadFile = useCallback(async () => {
    //     if (auditDetailData && auditDetailData.profile) {
    //         try {
    //             const response = await exportProviderByPorivderId(providerId);
    //             if (response && !response.error && response.data) {
    //                 const binaryString = JSON.parse(JSON.stringify(response.data));
    //                 const byteArray = new Uint8Array(binaryString.length);
    //                 for (let i = 0; i < binaryString.length; i++) {
    //                     byteArray[i] = binaryString.charCodeAt(i);
    //                 }
    //                 // const blob = base64ToBlob(response.data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    //                 const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //                 const fileName = `${getProviderNameDisplay({
    //                     firstName: auditDetailData.profile?.firstName,
    //                     lastName: auditDetailData.profile?.lastName,
    //                     middleName: auditDetailData.profile?.middleName,
    //                 })}_${dayjs().format('MM/DD/YYYY')}.xlsx`;
    //                 const downloadUrl = window.URL.createObjectURL(blob);

    //                 // 创建一个隐藏的 <a> 元素
    //                 const a = document.createElement('a');
    //                 a.style.display = 'none';
    //                 a.href = downloadUrl;
    //                 a.download = fileName;

    //                 // 将 <a> 元素添加到文档中并触发点击事件以启动下载
    //                 document.body.appendChild(a);
    //                 a.click();

    //                 // 清理
    //                 window.URL.revokeObjectURL(downloadUrl);
    //                 document.body.removeChild(a);
    //                 // const csvData = response.data;
    //                 // const fileName = `${getProviderNameDisplay({
    //                 //     firstName: auditDetailData.profile?.firstName,
    //                 //     lastName: auditDetailData.profile?.lastName,
    //                 //     middleName: auditDetailData.profile?.middleName,
    //                 // })}_${dayjs().format('MM/DD/YYYY')}.xlsx`;

    //                 // downloadFileWithBlob(csvData, fileName);
    //             } else {
    //                 message.error(response?.error);
    //             }
    //         } catch (e) {
    //             console.error(e);
    //         }
    //     }
    // }, [providerId, auditDetailData, base64ToBlob]);

    // const { loading: exportLoading, run: handleHoldOn } = useRequest(downloadFile, {
    //     manual: true,
    // });

    const handleHoldOn = useCallback(async () => {
        const url = `${baseURL}admin-server/provider/profile/export?providerId=${providerId}`;

        // const newUrl = [url, `token=${getUserToken()}`].join('?');

        window.location.href = url;
    }, [providerId]);

    const handleApprove = useCallback(async () => {
        setSaveLoading(true);

        const updateRes = await channelWaitingApproval(channelId, providerId);

        await refetch();
        if (!updateRes?.error) {
            setShowWaitApproveModal(false);
        } else {
            handleMessageContent('error', 'Application approved failed!');
            console.error(updateRes);
        }

        setSaveLoading(false);
    }, [channelId, handleMessageContent, providerId, refetch]);

    // const handleGoLive = useCallback(async () => {
    //     setSaveLoading(true);

    //     const goliveRes = await providerChannelAuditGoLive({
    //         channelId,
    //         providerId,
    //     });

    //     await refetch();

    //     if (!goliveRes.error) {
    //         setShowListed(true);
    //     } else {
    //         handleMessageContent('error', 'Application go-live failed!');
    //         console.error(goliveRes);
    //     }
    //     setSaveLoading(false);
    // }, [channelId, handleMessageContent, providerId, refetch]);

    const onSubmit = useCallback(async (formValue: ISubmitData, step: StepValue) => {
        setSaveLoading(true);

        const submitData = {
            providerId: profile?.id,
            channelId,
            ...transferFormValue(formValue, step),
            step: step.toLocaleUpperCase(),
        };

        const submitRes = await updateProviderChannelInfo(submitData);

        await refetch();

        if (!submitRes?.error) {
            detailRef.current?.setStepEditStatus?.(false, step);
        } else {
            handleMessageContent('error', submitRes.error);
            console.error(submitRes);
        }

        setSaveLoading(false);
    }, [channelId, handleMessageContent, profile, refetch]);

    const handleUniprofileApprove = useCallback(async (pass: boolean) => {
        const res = await updateUniprofileAuditStatus({
            providerId,
            passed: pass,
        });

        if (!res?.error) {
            await refetch();
        } else {
            handleMessageContent('error', res.error);
        }
    }, [handleMessageContent, providerId, refetch]);

    // export enum EChanelAuditStatus {
    //     UNIPROFILE_NEEDED = 'uniprofile-needed',
    //     UPLOAD_DATA = 'upload-data',
    //     WAITING_APPROVE = 'waiting-approve',
    //     FAILED = 'failed',
    //     APPROVED = 'approved',
    // }

    // export enum EAuditStatus {
    //     FOLLOW_UP = 'follow-up',
    //     PENDING = 'pending',
    //     APPROVED = 'approved',
    //     REJECTED = 'rejected',
    // }

    const handleSync = async () => {
        setProfileStore('isSyncingEhr', true);
        setSyncingEhr(true);
        const result = await syncEhr(providerId);
        if (!result.error) {
            message.success('Sync with EHR successfully');
        }
        setSyncingEhr(false);
        setProfileStore('isSyncingEhr', false);
    };

    const renderButtons = useMemo(() => {
        if (isUniprofileDetail) {
            const showDownload = auditStatus === EAuditStatus.APPROVED || auditStatus === EAuditStatus.REJECTED || auditStatus === EAuditStatus.PENDING;
            const showDisqualify = auditStatus === EAuditStatus.PENDING;
            const showApproveBtn = auditStatus === EAuditStatus.PENDING;
            const showContact = auditStatus === EAuditStatus.FOLLOW_UP;
            const showSync = auditStatus === EAuditStatus.APPROVED;
            return (
                <div className={s.right}>
                    {
                        showSync && finishEhrInit &&
                        <Button
                            type="primary"
                            ghost
                            loading={syncingEhr}
                            onClick={handleSync}
                            className={s.btnWithLink}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M2.66699 2.66797V6.0013H3.05467M13.2924 7.33464C12.9643 4.70376 10.7201 2.66797 8.00033 2.66797C5.76207 2.66797 3.84585 4.04675 3.05467 6.0013M3.05467 6.0013H6.00033M13.3337 13.3346V10.0013H12.946M12.946 10.0013C12.1548 11.9559 10.2386 13.3346 8.00033 13.3346C5.28058 13.3346 3.03632 11.2988 2.70825 8.66797M12.946 10.0013H10.0003" stroke="#00816B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            Sync with EHR
                        </Button>
                    }
                    {
                        showDownload &&
                        <Button
                            type="primary"
                            ghost
                            onClick={handleHoldOn}
                            // loading={exportLoading}
                        >
                            Download UniProfile in Excel
                        </Button>
                    }
                    {
                        showDisqualify &&
                        <Button
                            type="primary"
                            danger
                            onClick={() => handleUniprofileApprove(false)}
                        >
                            Disqualify
                        </Button>
                    }

                    {
                        showApproveBtn &&
                        <Button
                            type="primary"
                            onClick={() => handleUniprofileApprove(true)}
                        >
                            Approve
                        </Button>
                    }
                    {
                        showContact &&
                        <Button
                            type="primary"
                            className={s.btnWithLink}
                        >
                            <a href={`mailto:${profile.email}`}>Contact provider</a>
                        </Button>
                    }
                </div>
            );
        } else {
            //channel
            if (auditStatus === EChanelAuditStatus.APPROVED) {
                return (
                    <div className={s.right}>
                        <Button
                            type="primary"
                            ghost
                            onClick={handleHoldOn}
                            // loading={exportLoading}
                        >
                            Download UniProfile in Excel
                        </Button>
                    </div>
                );
            }
            if (auditStatus === EChanelAuditStatus.UPLOAD_DATA) {
                return (
                    <div className={s.right}>
                        <Button
                            type="primary"
                            ghost
                            onClick={handleHoldOn}
                            // loading={exportLoading}
                        >
                            Download UniProfile in Excel
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => setShowWaitApproveModal(true)}
                        >
                            Update status to Awaiting approval
                        </Button>

                    </div>
                );
            }
        }

        return null;
    }, [isUniprofileDetail, auditStatus, handleHoldOn, handleUniprofileApprove, profile.email, syncingEhr, finishEhrInit]);

    const platformUrl = `https://dev.d3n71p7v2xu531.amplifyapp.com/provider/${profile.firstName}-${profile.lastName}/${profile.id}`;
    const channelNameTail = channelName ? ` - ${decodeURIComponent(channelName)}` : '';
    const providerNameDisplay = (profile.firstName || profile.lastName) ? `${profile?.firstName} ${profile?.lastName}` : profile?.email;
    const showSync = isUniprofileDetail && finishEhrInit && auditStatus === EAuditStatus.APPROVED;
    const [changeInx, setChangeInx] = React.useState(0);

    const checkChange = () => {
        //@ts-ignore
        const changeElements: any[] = document.getElementsByClassName('changed') as any[];
        if (!changeElements || changeElements.length === 0) {
            return;
        }
        let goInx = changeInx;
        if (changeInx >= changeElements.length) {
            goInx = 0;
        }
        for (let i = 0; i < changeElements.length; i++) {
            if (i === goInx) {
                changeElements[i].style.borderWidth = '2px';
                changeElements[i].style.fontWeight = 'bolder';
                changeElements[i].scrollIntoView({
                    behavior: 'smooth', // 滚动行为可以是 "auto", "instant", 或 "smooth"
                    block: 'center', // 在视口的顶部滚动元素到视口中
                });
            } else {
                changeElements[i].style.borderWidth = '1px';
                changeElements[i].style.fontWeight = 'normal';
            }
        }
        setChangeInx(goInx + 1);
    };

    return (
        <div className={s.wrap}>
            {messageContextHolder}
            <Spin spinning={loading || saveLoading}>
                <div className={s.header}>
                    <div className={s.headerInner}>
                        <div className={s.left}>
                            <div
                                onClick={() => {
                                    !isUniprofileDetail ? navigate(`${PATH.OPERATION}/${PATH.CHANNEL}?type=application`) : navigate(`${PATH.OPERATION}/${PATH.PROVIDER}?type=${EType.UNI_PROFILE}`);
                                }}
                                className={s.leftTop}
                            >
                                <span className={s.leftTopHome}><ArrowLeftOutlined /></span>
                                <span
                                    className={s.link}
                                >{isUniprofileDetail ? 'Back to Uniprofile' : 'Back to Channel listing'}
                                </span>
                            </div>
                            <h1 className={s.leftBtm}>
                                <span className={s.title}>{`${providerNameDisplay}${channelNameTail}`}</span>
                                <span className={s.tag}>
                                    {isUniprofileDetail ? <UniprofileAuditStatusTag status={auditStatus} /> : <ChannelAuditStatusTag status={auditStatus} />}
                                </span>

                            </h1>
                        </div>
                        {renderButtons}
                    </div>
                </div>
                {
                    showAlert &&
                    <div className={s.alertWrap}>
                        <Alert
                            message={<div style={{ fontSize: '24', fontWeight: 'bold' }}>Infomation update</div>}
                            description={
                                <div className={s.info}>
                                    {/* <p>Provider has updated some information of uniprofile, we will highlight some key info for you with red color.</p>
                                    <p>For example: Credentials: <b>DNP, A-GNP-C</b> <span style={{ color: 'red', fontWeight: 'bold' }}>A-GNP-C</span> </p>
                                    <p>This means the provider has changed <span style={{ color: 'red', fontWeight: 'bold' }}>A-GNP-C</span> to <b>DNP, A-GNP-C</b></p>
                                    <p><b>Other changes still need you to check and sync to channels.</b></p> */}
                                    We&apos;ve highlighted the provider&apos;s <b>previous</b> UniProfile information in blue. Please review and confirm all changes and sync to channels. After you&apos;ve moved all updates into channels, click &quot;Mark as resolved&quot; to clear all highlights.
                                </div>
                            }
                            type="info"
                            action={
                                <Space direction="vertical">
                                    <Button
                                        loading={settling}
                                        onClick={handleChangeConfirm}
                                        type="primary"
                                    >
                                        Mark as resolved
                                    </Button>
                                </Space>
                            }
                        />
                    </div>
                }
                <Detail
                    providerId={providerId}
                    ref={detailRef}
                    channelId={channelId}
                    formData={useData}
                    onSubmit={onSubmit}
                    // showSyncButton={showSync}
                    // onClickSync={handleSync}
                    refetch={refetch}
                    // onShowReviewModal={handleShowReviewModal}
                    // reviews={reviews}
                    onShowMessage={handleMessageContent}
                    onApprove={handleApprove}
                />
                <Modal
                    title="Application on-hold with feedback"
                    closable
                    className={commonS.modalFixHeightWrap}
                    width="660px"
                    destroyOnClose
                    onCancel={() => {
                        setShowOnHold(false);
                    }}
                    footer={
                        <div className={s.footerWrap}>
                            <Button onClick={() => {
                                setShowOnHold(false);
                                navigate(`${PATH.OPERATION}/${PATH.CHANNEL}`);
                            }}
                            >
                                Back to Channels
                            </Button>
                            <Button
                                type="primary"
                                onClick={() => {
                                    setShowOnHold(false);
                                    window.open(`mailto:${profile?.email}`);
                                }}
                            >
                                Open Gmail
                            </Button>
                        </div>
                    }
                    open={showOnHold}
                >
                    <div className={s.onHoldWrap}>
                        <div className={s.imgWrap}>
                            <img src={OnHoldImg} />
                        </div>
                        <div className={s.title}>Send feedback to provider {profile?.firstName}, {profile?.lastName}</div>
                        <div className={s.subTitle}>Please email the provider to explain application on-hold reasons in detail.</div>
                    </div>
                </Modal>
                <Modal
                    title="Application approve"
                    closable
                    className={commonS.modalFixHeightWrap}
                    width="660px"
                    destroyOnClose
                    footer={
                        <div className={s.footerWrap}>
                            <Button onClick={() => {
                                setShowApprove(false);
                                navigate(`${PATH.OPERATION}/${PATH.CHANNEL}`);
                            }}
                            >
                                Back to Channels
                            </Button>
                        </div>
                    }
                    onCancel={() => {
                        setShowApprove(false);
                    }}
                    open={showApprove}
                >
                    <div className={s.onHoldWrap}>
                        <div className={s.imgWrap}>
                            <img src={ApproveImg} />
                        </div>
                        <div className={s.title}>
                            Provider {profile?.firstName}, {profile?.lastName}’s channel integration application has been approved
                        </div>
                        <div className={s.subTitle}>Please ensure the provider’s EHR is configured properly and the Provider Profile Pages in Marketplace are alive. After the configuration, please update the channel integration status to Go-live.</div>
                    </div>
                </Modal>
                <Modal
                    title="Update status to Awaiting approval"
                    closable
                    className={commonS.modalFixHeightWrap}
                    width="660px"
                    destroyOnClose
                    okText="Yes, I confirm"
                    cancelText="No, still in progress"
                    onOk={handleApprove}
                    onCancel={() => setShowWaitApproveModal(false)}
                    open={showWaitApproveModal}
                >
                    <p>Have you added the provider&apos;s information to the Channel correctly? </p>
                </Modal>
                <Modal
                    title="Application go live"
                    closable
                    className={commonS.modalFixHeightWrap}
                    width="660px"
                    destroyOnClose
                    footer={
                        <div className={s.footerWrap}>
                            <Button onClick={() => {
                                setShowApprove(false);
                                navigate(`${PATH.OPERATION}/${PATH.CHANNEL}`);
                            }}
                            >
                                Back to Channels
                            </Button>
                        </div>
                    }
                    onCancel={() => {
                        setShowApprove(false);
                    }}
                    open={showListed}
                >
                    <div className={s.onHoldWrap}>
                        <div className={s.imgWrap}>
                            <img src={Motivation} />
                        </div>
                        <div className={s.title}>
                            Provider {profile?.firstName}, {profile?.lastName} has been successfully listed
                        </div>
                        <div className={s.subTitle}>Provider is ready to be booked. <a href={platformUrl}>View provider profile</a></div>
                    </div>
                </Modal>
                {
                    // showReviewModal &&
                    // <ReviewModal
                    //     channelId={channelId}
                    //     providerId={providerId}
                    //     initValue={reviews}
                    //     onSuccess={handleAddReviewsSuccess}
                    //     onCancel={() => { setShowReviewModal(false); }}
                    // />
                }
            </Spin>
            {
                showNewBtn &&
                <div className={s.change}>
                    <Button onClick={checkChange} id="checkChange" type="primary" className={s.change}>
                        <span>Next</span>
                        <span>Changed</span>
                    </Button>
                </div>
            }
        </div>
    );
};

export default Page;
