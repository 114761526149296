import React, { useState } from 'react';
import { Modal, Form, Input, Button, message } from 'antd';
import commonS from 'styles/common.module.less';
import s from './s.module.less';
import { homeConfigCreate, homeConfigUpdate } from 'api/operation';

import { HomeConfig } from 'types/common';

type Props = {
    isOps?: boolean
    item?: HomeConfig
    onCancel?:()=>void
    onSuccess?: ()=>void
};

const CreateAndEditHomeConfigModal = ({ item, onCancel, onSuccess }:Props) => {
    const [submiting, setSubmiting] = useState(false);
    const [form] = Form.useForm<HomeConfig>();
    const isEdit = !!item?.id;

    const onSave = async () => {
        setSubmiting(true);
        try {
            const formValues = await form.validateFields();
            const id = item?.id;

            const params :HomeConfig = {
                title: formValues.title,
                content: formValues.content,
                headLine: formValues.headLine,
            };
            if (isEdit) {
                params.id = id;
            }
            const apiFunc = isEdit ? homeConfigUpdate(params, id!) : homeConfigCreate(params);
            const { data } = await apiFunc;
            if (data.code === 200) {
                onSuccess?.();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setSubmiting(false);
        }
    };

    React.useEffect(() => {
        if (item) {
            form.setFieldsValue({
                title: item.title,
                content: item.content,
                headLine: item.headLine,
            });
        } else {
            form.resetFields();
        }
    }, [item]);

    return (
        <Modal
            title={isEdit ? 'Edit config' : 'Add an new config'}
            closable
            className={commonS.modalFixHeightWrap}
            width="500px"
            destroyOnClose
            onCancel={onCancel}
            okText="Save"
            open
            footer={
                <div>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button loading={submiting} type="primary" onClick={onSave}>Save</Button>
                </div>
            }
        >
            <div className={s.wrap}>
                <Form
                    form={form}
                    className={commonS.formStyle1}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    layout="vertical"
                    scrollToFirstError
                >
                    <>
                        <Form.Item
                            className={s.rowItem}
                            label="Card title"
                            name="title"
                            rules={[{ required: true, message: 'Title is required' }]}
                        >
                            <Input showCount maxLength={40} style={{ width: '100%' }} />
                        </Form.Item>

                        <Form.Item
                            className={s.rowItem}
                            label="Headline"
                            name="headLine"
                            rules={[{ required: true, message: 'Title is required' }]}
                        >
                            <Input showCount maxLength={40} style={{ width: '100%' }} />
                        </Form.Item>

                        <Form.Item
                            className={s.rowItem}
                            label="Content"
                            name="content"
                        >
                            <Input.TextArea style={{ width: '100%' }} maxLength={200} rows={4} showCount />
                        </Form.Item>
                    </>
                </Form>
            </div>
        </Modal>
    );
};

export default CreateAndEditHomeConfigModal;
