import { Dayjs } from 'dayjs';
import { AddressPublicType, EditLocalSEOInfoServer, LocalSEOInfoServer } from './common';
import { ProviderPractice, TKlarityServiceServerItemForPF } from './provider';

export enum EditModuleEnum {
    BASICINFO = 'basicInfo',
    INSURANCE = 'Insurance',
    ABOUTME = 'aboutMe',
    MYPHILOSOPHY = 'myPhilosophy',
    SEPCIALITIESAPPROACH = 'specialitiesApproach',
    WORKEXPERIENCE = 'workExperience',
    EDUCATION = 'education',
    CONTACT = 'contact',
    SERVICE = 'service',
    PRACTICE_ADDRESS = 'practiceAddress',
    LOCAL_SEO = 'localSEO',
    APPOINTMENT_LINK = 'appointmentIntegrationUrl',
}

export enum FormFields {
    ID = 'id',
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    // ADDRESS = 'address',
    CITY = 'city',
    ZIP_CODE = 'zip',
    STATE = 'state',
    LICENSES = 'licenses',
    INSURANCE_LIST = 'insuranceList',
    OTHER_INSURANCE_LIST = 'otherInsuranceList',
    CHANNELS = 'channels',
    INTRO = 'intro',
    YEAR_EXP = 'yearExp',
    TITLE = 'title',
    PHOTO = 'photo',
    TREATMENT_PHILOSOPHY = 'treatmentPhilosophy',
    SPECIALTIES = 'specialties', // ...
    TREATMENT_APPROACH = 'treatmentApproach',
    SPECIALITYINHEALTH = 'specialityInHealth',
    SPECIALITYINFAMILY = 'specialityInFamily',
    ORGANIZATION_NAME = 'organizationName',
    IS_CURRENTLY_WORK = 'currently',
    START_DATE = 'startDate',
    END_DATE = 'endDate',
    EMPLOYMENT_TYPE = 'employmentType',
    HOSPITAL_LOCATION = 'hospitalLocation',
    DESCRIPTION = 'description',
    LOGO = 'experienceLogo',
    SCHOOL_NAME = 'schoolName',
    MAJOR = 'major',
    DEGREE = 'degree',
    CERTIFICATES = 'certificates',
    IS_PURSUING_DEGREE = 'currently',
    SCHOOL_LOGO = 'schoolLogo',
    PHONE = 'tel',
    EMAIL = 'email',
    COLOR_ID = 'colorId',
    DURATION = 'duration',
    NAME = 'name',
    PERSON_ADDRESS = 'personAddress',
    PERSON_CITY = 'personCity',
    PERSON_STATE = 'personState',
    PERSON_TYPE = 'personType',
    PERSON_ZIP = 'personZip',
    PERSON_ID = 'providerId',
    SEND_FORM = 'sendForm',
    TELEHEALTH_TYPE = 'telehealthType',
    TYPE = 'type',
    PRICE = 'price',
    INTAKE_FORM = 'intakeForm',
    PRACTICE_ADDRESS = 'practiceAddress',
    PRACTICE_STATE = 'practiceState',
    PRACTICE_CITY = 'practiceCity',
    PRACTICE_ZIP = 'practiceZip',
    PRACTICE_PHONE = 'practicePhone',
    PRACTICE_LOGO = 'practiceLogo',
    CREDENTIAL = 'credential',
    PROVIDER_PRACTICE = 'providerPractice',
    HEADLINE = 'headLine',
    SPECIALTY_LIST = 'specialtyList',
    CONDITION_TREATED_LIST = 'conditionTreatedList',
    IS_DEFAULT = 'isDefault',
    AS_MAILING = 'asMailing',
    PRACTICE_NAME = 'practiceName',
    PRACTICE_EMAIL = 'practiceEmail',
    PRACTICE_WEBSITE = 'practiceWebsite',
    SUPPORT_PAY_TYPE = 'supportPayType',
    ADDRESS_ID = 'addressId',
    SERVICE_TYPE = 'type',
    ADDRESS_PUBLIC_TYPE = 'publicType',
    APPOINTMENT_LINK = 'appointmentIntegrationUrl',
    USE_INTEGRATION_URL = 'useIntegrationUrl',
    ENABLE_SLIDING_SCALE = 'enableSlidingScale',
    LOWEST_PRICE = 'lowestPrice',
    HIGHEST_PRICE = 'highestPrice',
}

export interface IService {
    [FormFields.ID]?: number;
    [FormFields.COLOR_ID]?: string;
    [FormFields.DESCRIPTION]?: string;
    [FormFields.DURATION]?: number;
    [FormFields.NAME]: string;
    [FormFields.PERSON_ADDRESS]?: string;
    [FormFields.PERSON_CITY]?: string;
    [FormFields.PERSON_STATE]?: string;
    [FormFields.PERSON_TYPE]?: Array<string>;
    [FormFields.PERSON_ZIP]?: string;
    [FormFields.PERSON_ID]?: number;
    [FormFields.SEND_FORM]?: string;
    [FormFields.TELEHEALTH_TYPE]?: Array<string>;
    [FormFields.TYPE]?: string;
    [FormFields.PRICE]?: number;
    [FormFields.INTAKE_FORM]: string;
    [FormFields.ADDRESS_ID]: number;
    [FormFields.ENABLE_SLIDING_SCALE]?: boolean;
    [FormFields.LOWEST_PRICE]?: number;
    [FormFields.HIGHEST_PRICE]?: number;
}

export interface IBasicInfo {
    [FormFields.ID]: number;
    [FormFields.FIRST_NAME]: string;
    [FormFields.LAST_NAME]: string;
    // [FormFields.CITY]: string;
    // [FormFields.ZIP_CODE]: string;
    // [FormFields.STATE]: string;
    // [FormFields.LICENSES]: Array<License>;
    [FormFields.TITLE]: string;
    [FormFields.CREDENTIAL]: string[];
    [FormFields.PHOTO]: string;
    [FormFields.PROVIDER_PRACTICE]: ProviderPractice,
}

export interface IInsurance {
    [FormFields.INSURANCE_LIST]: Array<string>;
    [FormFields.OTHER_INSURANCE_LIST]: Array<string>;
    [FormFields.SUPPORT_PAY_TYPE]: string;
}

export interface IInsuranceInput {
    [FormFields.INSURANCE_LIST]: Array<string> | null;
    [FormFields.OTHER_INSURANCE_LIST]: Array<string> | null;
    [FormFields.SUPPORT_PAY_TYPE]: string;
}

export interface IInsuranceForm {
    [FormFields.INSURANCE_LIST]: Array<string>;
    [FormFields.OTHER_INSURANCE_LIST]: string;
    [FormFields.SUPPORT_PAY_TYPE]: string;
}

export interface IAboutMe {
    [FormFields.INTRO]: string;
    [FormFields.YEAR_EXP]: number;
    [FormFields.HEADLINE]: string;
    [FormFields.PHOTO]: string;
}

export interface IMyPhilosophy {
    [FormFields.TREATMENT_PHILOSOPHY]: string;
    [FormFields.TREATMENT_APPROACH]: string;
}

export interface ISpecialtiesApproaches {
    [FormFields.SPECIALITYINHEALTH]: Array<string>;
    [FormFields.SPECIALITYINFAMILY]: Array<string>;
    [FormFields.TREATMENT_APPROACH]: string;
}

export interface IWorkExperienceOrigin {
    [FormFields.ORGANIZATION_NAME]: string,
    [FormFields.TITLE]: string,
    [FormFields.IS_CURRENTLY_WORK]: boolean,
    [FormFields.START_DATE]: string,
    [FormFields.END_DATE]: string,
    [FormFields.EMPLOYMENT_TYPE]: string,
    [FormFields.HOSPITAL_LOCATION]: string,
    [FormFields.DESCRIPTION]: string,
    [FormFields.LOGO]: string,
    [FormFields.ID]?: number,
}

export interface IWorkExperience extends Omit<IWorkExperienceOrigin, FormFields.START_DATE | FormFields.END_DATE> {
    [FormFields.START_DATE]?: Dayjs,
    [FormFields.END_DATE]?: Dayjs,
}

export interface IEducation {
    [FormFields.ID]?: number,
    [FormFields.SCHOOL_NAME]: string,
    [FormFields.MAJOR]: string,
    [FormFields.IS_PURSUING_DEGREE]: boolean,
    [FormFields.DEGREE]: string,
    [FormFields.SPECIALTIES]: string,
    [FormFields.CERTIFICATES]: string,
    [FormFields.START_DATE]?: Dayjs,
    [FormFields.END_DATE]?: Dayjs,
    [FormFields.SCHOOL_LOGO]: string,
}

export interface IEmailAddress {
    [FormFields.EMAIL]: string;
}

export interface IChannelListChannel {
    id: number;
    name: string;
}

export interface IProviderStore {
    email: string;
    basicInfo: IBasicInfo;
    insurance: IInsurance;
    channels: Array<IChannelListChannel>;
    aboutMe: IAboutMe;
    appointmentIntegrationUrl: string;
    useIntegrationUrl: boolean | null;
    appointmentNewFlag: boolean | null;
    emailNotify: string | null;
    myPhilosophy: {
        [FormFields.TREATMENT_PHILOSOPHY]: string;
        [FormFields.TREATMENT_APPROACH]: string;
    };
    specialtiesApproaches: {
        [FormFields.SPECIALTY_LIST]: Array<string>;
        [FormFields.CONDITION_TREATED_LIST]: Array<string>;
    },
    experience: Array<{
        [FormFields.ORGANIZATION_NAME]: string,
        [FormFields.TITLE]: string,
        [FormFields.IS_CURRENTLY_WORK]: boolean,
        [FormFields.START_DATE]?: Dayjs,
        [FormFields.END_DATE]?: Dayjs,
        [FormFields.EMPLOYMENT_TYPE]: string,
        [FormFields.HOSPITAL_LOCATION]: string,
        [FormFields.DESCRIPTION]: string,
        [FormFields.LOGO]: string,
    }>,
    education: Array<IEducation>,
    service: Array<IService>,
    contact: ProviderPractice,
    seoCity?: Array<string>,
    containSeoApp?: boolean,
    seoData?: Record<string, EditLocalSEOInfoServer>, //key is state
    pfKlarityServiceList: TKlarityServiceServerItemForPF[],
}

export interface IBasicInfoInput {
    [FormFields.FIRST_NAME]: string;
    [FormFields.LAST_NAME]: string;
    // [FormFields.ADDRESS]: string;
    [FormFields.CITY]: string;
    [FormFields.ZIP_CODE]: string;
    [FormFields.STATE]: string;
}

export interface IAboutMeInput {
    [FormFields.INTRO]: string;
    [FormFields.YEAR_EXP]: number;
    [FormFields.TITLE]: string;
    [FormFields.PHOTO]: string;
}

export interface IMyPhilosophyInput {
    [FormFields.TREATMENT_PHILOSOPHY]: string;
}

export interface ISpecialtiesApproachesInput {
    [FormFields.SPECIALITYINHEALTH]: string;
    [FormFields.SPECIALITYINFAMILY]: string;
    [FormFields.TREATMENT_APPROACH]: string;
}

export interface IExperienceInput {
    [FormFields.ORGANIZATION_NAME]: string,
    [FormFields.TITLE]: string,
    [FormFields.IS_CURRENTLY_WORK]: boolean,
    [FormFields.START_DATE]: string,
    [FormFields.END_DATE]: string,
    [FormFields.EMPLOYMENT_TYPE]: string,
    [FormFields.HOSPITAL_LOCATION]: string,
    [FormFields.DESCRIPTION]: string,
    [FormFields.LOGO]: string,
    [FormFields.ID]?: number,
}

export interface IEducationInput {
    [FormFields.ID]: number,
    [FormFields.SCHOOL_NAME]: string,
    [FormFields.MAJOR]: string,
    [FormFields.IS_PURSUING_DEGREE]: boolean,
    [FormFields.DEGREE]: string,
    [FormFields.SPECIALTIES]: string,
    [FormFields.CERTIFICATES]: string,
    [FormFields.START_DATE]: string,
    [FormFields.END_DATE]: string,
    [FormFields.SCHOOL_LOGO]: string,
}

export interface IEducationResponse {
    [FormFields.ID]: number,
    [FormFields.SCHOOL_NAME]: string,
    [FormFields.MAJOR]: string,
    [FormFields.IS_PURSUING_DEGREE]: boolean,
    [FormFields.DEGREE]: string,
    [FormFields.SPECIALTIES]: string,
    [FormFields.CERTIFICATES]: string,
    [FormFields.START_DATE]: string,
    [FormFields.END_DATE]: string,
    [FormFields.SCHOOL_LOGO]: string,
}

export interface IAddPraciceInputInput {
    [FormFields.PRACTICE_ADDRESS]: string;
    [FormFields.PRACTICE_STATE]: string;
    [FormFields.PRACTICE_CITY]: string;
    [FormFields.PRACTICE_ZIP]: string;
    [FormFields.PRACTICE_PHONE]: string;
}

export interface PracitceAddressForm {
    [FormFields.ADDRESS_PUBLIC_TYPE]: AddressPublicType;
    [FormFields.IS_DEFAULT]: boolean;
    [FormFields.AS_MAILING]: boolean;
    [FormFields.PRACTICE_ADDRESS]: string;
    [FormFields.PRACTICE_CITY]: string;
    [FormFields.PRACTICE_STATE]: string;
    [FormFields.PRACTICE_ZIP]: string;
    [FormFields.ID]?: number;
}

export interface ProvderAppointment {
    [FormFields.APPOINTMENT_LINK]: string;
    [FormFields.USE_INTEGRATION_URL]: boolean;
}

export enum EPhotoEditorSaveType {
    PROVIDER_PHOTO = 'providerPhoto',
    PRACTICE_LOGO = 'practiceLogo',
}
