import React, { useEffect, useMemo, useRef } from 'react';
import s from './s.module.less';
import { Button, message, Modal, Spin } from 'antd';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import useHomeConfig from 'hooks/useHomeConfig';
import { HomeConfig } from 'types/common';
import { homeConfigDelete } from 'api/operation';
import CreateAndEditHomeConfigModal from './components/CreateAndEditHomeConfigModal';

const Page = () => {
    const [modal, contextHolder] = Modal.useModal();
    const [item, setItem] = React.useState<HomeConfig>();
    const [itemList, loading, refresh] = useHomeConfig(true);
    const [isEdit, setIsEdit] = React.useState(false);
    const [openModal, setOpensModal] = React.useState(false);

    const deleteFunc = async (record: HomeConfig) => {
        modal.confirm({
            width: '600px',
            closable: true,
            title: `Do you confirm remove this config - ${record.title}?`,
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: 'Yes, confirm',
            cancelText: 'Cancel',
            onOk: async () => {
                const id = record?.id;
                try {
                    await homeConfigDelete(id!).finally(() => refresh());
                } catch (error) {
                    console.error(error);
                }
            },
        });
    };

    const addNew = () => {
        setIsEdit(false);
        setOpensModal(true);
        setItem(undefined);
    };

    const onEdit = (record: HomeConfig) => {
        setItem(record);
        setIsEdit(true);
        setOpensModal(true);
    };

    const onSuccess = () => {
        setOpensModal(false);
        refresh();
        message.success(isEdit ? 'Update success' : 'Add success');
    };

    return (
        <div className={s.wrap}>
            <div className={s.header}>
                {/* <h1>Home config</h1> */}
                <div className={s.desWrap}>
                    <div className={s.des}>Promotion carrousel</div>
                    <Button
                        type="primary"
                        onClick={addNew}
                    >
                        Add a card
                    </Button>
                </div>
            </div>
            <Spin spinning={loading}>
                <div className={s.content}>
                    {itemList?.map((i:HomeConfig) => {
                        const key = i.id;
                        return (
                            <div className={s.item} key={key}>
                                <div className={s.title}>{i.title}</div>
                                <div className={s.head}>{i.headLine}</div>
                                <div className={s.detail}>{i.content}</div>
                                <div className={s.action}>
                                    <Button
                                        onClick={() => onEdit(i)}
                                        className={s.btn}
                                        size="small"
                                        type="primary"
                                    >Edit
                                    </Button>
                                    <Button
                                        onClick={() => deleteFunc(i)}
                                        danger
                                        size="small"
                                    >Remove
                                    </Button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Spin>
            {
                openModal &&
                <CreateAndEditHomeConfigModal
                    isOps
                    item={item}
                    onCancel={() => setOpensModal(false)}
                    onSuccess={onSuccess}
                />
            }
            {contextHolder}
        </div>
    );
};

export default Page;
