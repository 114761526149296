export const downloadFileWithBlob = (csvData: string, fileName: string) => {
    try {
        const blob = new Blob([csvData], { type: 'text/csv' });
        const downloadUrl = window.URL.createObjectURL(blob);

        // 创建一个隐藏的 <a> 元素
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = downloadUrl;
        a.download = fileName;

        // 将 <a> 元素添加到文档中并触发点击事件以启动下载
        document.body.appendChild(a);
        a.click();

        // 清理
        window.URL.revokeObjectURL(downloadUrl);
        document.body.removeChild(a);
    } catch (e) {
        console.error(e);
    }
};
