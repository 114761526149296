import TinyStore from 'lib/tinyStore';
import { TReviewItem } from 'pages/dashboard/KlarityReviewsDashboradPage/types';

type ReviewDisputeModal = {
    currentReview?: TReviewItem;
    showViewModal?: boolean;
    showEditModal?: boolean;
    //loaded?: boolean, //cos after loaded, current plan may still null
};

const Store = TinyStore.createStore<ReviewDisputeModal>({
    nameSpace: 'klarity',
    key: 'reviewDispute',
    crossBundle: false,
    runTime: true,
    initValue: { currentReview: undefined, showViewModal: false, showEditModal: false },
});
export default Store;
