import ExportJsonExcel from 'js-export-excel';
import dayjs from './dayjs';

//export string excel table
export function exportExcel(props: {
    data: Record<string, string | number | object>[];
    excelName?: string; //default using path if not set
    sheetName?: string;
}) {
    const { data = [], excelName = '', sheetName = 'table' } = props;
    const option: any = {};
    const sheetData: Record<string, string>[] = [];
    const sheetHeader: string[] = [];
    const columnWidths: number[] = [];
    option.fileName = `klarity-${excelName}-${dayjs(new Date().getTime()).format('YYYY-MM-DD')}`;
    if (data && data.length > 0) {
        Object.keys(data[0]).forEach((key) => {
            columnWidths.push(20);
            sheetHeader.push(key);
        });
        for (let i = 0; i < data.length; i++) {
            const add: Record<string, string> = {};
            Object.keys(data[i]).forEach((key) => {
                add[key] = typeof data[i][key] === 'object' ? JSON.stringify(data[i][key]) : `${data[i][key]}`;
            });
            sheetData.push(add);
        }
    }
    option.datas = [
        {
            sheetData,
            sheetName,
            sheetHeader,
            columnWidths,
        },
    ];

    const toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
}
