import React, { useMemo } from 'react';
import { Button, Input, Modal, Space, TableProps, message } from 'antd';
import CommonTable from 'components/CommonTable';
import type { ColumnsType } from 'antd/es/table';
import s from './s.module.less';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import dayjs from 'dayjs';
import type { TAssessmentItem, IAssessmentInput } from 'types/operation';
import AssessmentUpdateModal from '../AssessmentUpdateModal';
import ExternalLinkIcon from 'assets/common/externalLinkIcon.svg';
import {
    getAssessmentList,
    changeAssessmentStatus,
} from 'api/operation/assessment';
import { EnumFields } from 'types/enumerationData';
import useGetSettingData from 'hooks/useGetSettingData';
import { ServerMeta } from 'types/common';
import { EPageStatus, EKlarityPageStatus } from 'types/helloKlarity';
import { isDevEnv, isTestEnv, isProductionEnv } from 'utils/common';

const Index = () => {
    const navigation = useNavigate();
    const [itemList, setItemList] = React.useState<TAssessmentItem[]>([]);
    const [total, setTotal] = React.useState<number>(0);
    const [loading, setLoading] = React.useState(false);
    const [modal, contextHolder] = Modal.useModal();
    const [openAssessmentModal, setOpenAssessmentModal] = React.useState(false);
    const [isOpenErrorModal, setIsOpenErrorModal] = React.useState(false);
    const [errorContent, setErrorContent] = React.useState<string[]>([]);
    const [itemStatus, setItemStatus] = React.useState<EPageStatus>(EPageStatus.ADD);
    const [assessmentItem, setAssessmentItem] =
      React.useState<TAssessmentItem>();
    const [searchOrderType, setOrderType] = React.useState<string>('DESC');
    const [searchOrderKey, setOrderKey] = React.useState<string>('UPDATE_AT');
    const pageSize = 10;

    const [page, setPage] = React.useState(1);
    const [pendingProviderNameFilter, setPendingProviderNameFilter] =
    React.useState('');
    const [pendingProviderIdFilter, setPendingProviderIdFilter] =
      React.useState('');
    const [nameFilter, setNameFilter] = React.useState<string>('');
    const [idFilter, setIdFilter] = React.useState<string>('');
    const [tagsFilter, setTagsFilter] = React.useState<string>('');
    const [statusFilter, setStatus] = React.useState<string>(
        '',
    );
    const [condition] =
      useGetSettingData(EnumFields.HELLO_KLARITY_CONDITION);
    const tagsOptions = useMemo(() => {
        return (
            (condition || [])
                .map((item: ServerMeta) => {
                    return {
                        text: item.content,
                        value: item.dictKey,
                    };
                })
                .sort((a: ServerMeta, b: ServerMeta) => {
                    const stringA = a.text.toLowerCase();
                    const stringB = b.text.toLowerCase();
                    if (stringA < stringB) {
                        return -1;
                    }
                    if (stringA > stringB) {
                        return 1;
                    }
                    return 0;
                }) || []
        );
    }, [condition]);

    const doFetch = async ({
        current,
        id,
        assessmentName,
        orderKey,
        orderType,
        tags,
        status,
    }: IAssessmentInput) => {
        setLoading(true);
        const reqParam: IAssessmentInput = {
            current: current || page,
            size: pageSize,
            id: id || idFilter,
            assessmentName: assessmentName || nameFilter,
            orderKey: orderKey || searchOrderKey,
            orderType: orderType || searchOrderType,
            tags: tags || tagsFilter || [],
            status: status || statusFilter || '',
        };
        const result = await getAssessmentList(reqParam);
        if (!result.error) {
            setItemList(result?.data?.data?.records);
            setTotal(result?.data?.data?.total);
        }
        setLoading(false);
    };

    const onTableChange: TableProps<TAssessmentItem>['onChange'] = (
        pagination,
        filters: any,
        sorter: any,
        action?: any,
    ) => {
        const pageInx = pagination?.current || 1;
        setPage(pageInx);
        const _tagsFilter = filters.conditionKeyTags || [];
        setTagsFilter(_tagsFilter);
        if (filters.status && filters.status.length === 1) {
            setStatus(filters.status[0] === 'ACTIVE' ? 'A' : 'D');
        } else {
            setStatus('');
        }
        if (action?.action === 'sort') {
            const orderType =
              sorter.order === 'ascend' ? 'ASC' : 'DESC';
            const orderKey =
              sorter.field === 'updateAt' ? 'UPDATE_AT' : sorter.field;
            setOrderType(orderType);
            setOrderKey(orderKey);
        }
    };

    const addNewAssessment = () => {
        setOpenAssessmentModal(true);
        setItemStatus(EPageStatus.ADD);
        setAssessmentItem(undefined);
    };

    const handleStatusChange = async (record: TAssessmentItem) => {
        const { data } = await changeAssessmentStatus({
            id: record.id || '',
            status:
            record.status === EKlarityPageStatus.ACTIVE
                ? EKlarityPageStatus.INACTIVATE
                : EKlarityPageStatus.ACTIVE,
        });
        if (Array.isArray(data?.data) && data?.data.length === 0) {
            message.success('updated successfully');
            doFetch({});
        } else {
            setAssessmentItem(record);
            setIsOpenErrorModal(true);
            setErrorContent(data?.data || []);
        }
    };

    const handleCancel = () => {
        setIsOpenErrorModal(false);
        setErrorContent([]);
    };

    const onSuccess = () => {
        setOpenAssessmentModal(false);
        setPage(1);
        doFetch({});
        message.success('Save successfully');
    };

    const handleDuplicate = (record: TAssessmentItem) => {
        setAssessmentItem(record);
        setItemStatus(EPageStatus.DUPLICATE);
        setOpenAssessmentModal(true);
    };

    const handleLink = (record: TAssessmentItem) => {
        if (isDevEnv()) {
            window.open(
                `https://dev.d2ysjydukc4efk.amplifyapp.com/preview/assessment?uuid=${record.uuid}`,
            );
        }
        if (isTestEnv()) {
            window.open(
                `https://test.d2ysjydukc4efk.amplifyapp.com/preview/assessment?uuid=${record.uuid}`,
            );
        }
        if (isProductionEnv()) {
            window.open(
                `https://www.helloklarity.com/preview/assessment?uuid=${record.uuid}`,
            );
        }
    };

    React.useEffect(() => {
        doFetch({});
    }, [page, idFilter, nameFilter, tagsFilter, searchOrderKey, searchOrderType]);

    const columns: ColumnsType<TAssessmentItem> = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: '80px',
            filterDropdown: ({ close }: any) => (
                <div style={{ padding: 16 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        placeholder="Search id"
                        value={pendingProviderIdFilter}
                        onChange={(e) => {
                            setPendingProviderIdFilter(e.target.value);
                        }}
                        onPressEnter={() => {
                            setIdFilter(pendingProviderIdFilter);
                            setPage(1);
                            close();
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                setIdFilter(pendingProviderIdFilter);
                                setPage(1);
                                close();
                            }}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => {
                                setPendingProviderIdFilter('');
                                setIdFilter('');
                                setPage(1);
                                close();
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
            ),
        },
        {
            title: 'ASSESSMENT NAME',
            dataIndex: 'assessmentName',
            key: 'assessmentName',
            width: '220px',
            filterDropdown: ({ close }: any) => (
                <div style={{ padding: 16 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        placeholder="Search name"
                        value={pendingProviderNameFilter}
                        onChange={(e) => {
                            setPendingProviderNameFilter(e.target.value);
                        }}
                        onPressEnter={() => {
                            setNameFilter(pendingProviderNameFilter);
                            setPage(1);
                            close();
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                setNameFilter(pendingProviderNameFilter);
                                setPage(1);
                                close();
                            }}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => {
                                setPendingProviderNameFilter('');
                                setNameFilter('');
                                setPage(1);
                                close();
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
            ),
        },
        {
            title: 'TAGS',
            dataIndex: 'conditionKeyTags',
            width: '300px',
            filterMultiple: false,
            filters: tagsOptions,
            render: (tags: string) => {
                return <div>{tags || '-'}</div>;
            },
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            width: '160px',
            filters: [
                {
                    text: 'Active',
                    value: 'ACTIVE',
                },
                {
                    text: 'Inactive',
                    value: 'INACTIVE',
                },
            ],
            render: (val: string) => {
                return (
                    <div className={s.cell}>
                        {val === 'A' && 'Active'}
                        {val === 'D' && 'Inactive'}
                    </div>
                );
            },
        },
        {
            title: 'LAST MODIFIED',
            dataIndex: 'updateAt',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            width: '150px',
            className: s.tableColumn,
            render: (time: string) => {
                return <div>{time ? dayjs(time).format('MM/DD/YYYY') : 'Nil'}</div>;
            },
        },
        {
            title: 'QUESTION FROM',
            dataIndex: 'questionsFormLink',
            width: '150px',
            className: s.tableColumn,
            render: (val: string) => {
                return val ? (
                    <a href={val} target="_blank" className={s.link} rel="noreferrer">
                        Form link
                        <img src={ExternalLinkIcon} />
                    </a>
                ) : (
                    '-'
                );
            },
        },
        {
            title: 'RESULTS',
            dataIndex: 'questionsFormResultLink',
            width: '150px',
            className: s.tableColumn,
            render: (val: string) => {
                return val ? (
                    <a href={val} target="_blank" className={s.link} rel="noreferrer">
                        Result link
                        <img src={ExternalLinkIcon} />
                    </a>
                ) : (
                    '-'
                );
            },
        },
        {
            title: '',
            width: '200px',
            fixed: 'right',
            render: (p: TAssessmentItem) => {
                return (
                    <div className={s.actionWrap}>
                        <Button
                            className={s.link}
                            onClick={() => handleStatusChange(p)}
                            type="text"
                        >
                            {p.status === EKlarityPageStatus.ACTIVE
                                ? 'De-activate'
                                : 'Activate'}
                        </Button>
                        <span
                            onClick={() => {
                                handleDuplicate(p);
                            }}
                            className={s.link}
                        >
                            Duplicate
                        </span>
                        {p.status === EKlarityPageStatus.ACTIVE ? (
                            <span
                                onClick={() => {
                                    handleLink(p);
                                }}
                                className={s.link}
                            >
                                Link
                                <img src={ExternalLinkIcon} />
                            </span>
                        ) : (
                            <span className={s.emptyLink}>-</span>
                        )}
                        <Button
                            className={s.link}
                            onClick={() => {
                                navigation(
                                    `${PATH.OPERATION}/${PATH.KLARITY_ASSESSMENT_DETAIL}?id=${p.id}&uuid=${p.uuid}`,
                                );
                            }}
                            type="text"
                        >
                            View
                        </Button>
                    </div>
                );
            },
        },
    ].filter(Boolean) as ColumnsType<TAssessmentItem>;

    return (
        <div className={s.wrap}>
            <div className={s.selectBar}>
                <h3 className={s.subtitle}>Assessment</h3>
                <Button onClick={addNewAssessment} icon={<PlusOutlined />}>
                    Add a new assessment
                </Button>
            </div>
            <CommonTable
                bordered
                rowKey="id"
                pagination={{
                    total,
                    current: page,
                    pageSize,
                    showSizeChanger: false,
                }}
                scroll={{ x: 'max-content' }}
                loading={loading}
                columns={columns}
                onChange={onTableChange}
                data={itemList || []}
            />
            {openAssessmentModal && (
                <AssessmentUpdateModal
                    item={assessmentItem}
                    itemStatus={itemStatus}
                    onCancel={() => setOpenAssessmentModal(false)}
                    onSuccess={onSuccess}
                />
            )}
            <Modal
                title=""
                open={isOpenErrorModal}
                destroyOnClose
                okText="Save"
                onCancel={handleCancel}
                closeIcon={<span />}
                footer={
                    <div>
                        <Button onClick={handleCancel}>Cancel</Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                navigation(
                                    `${PATH.OPERATION}/${PATH.KLARITY_ASSESSMENT_DETAIL}?id=${assessmentItem?.id}&uuid=${assessmentItem?.uuid}`,
                                );
                            }}
                        >
                            Edit
                        </Button>
                    </div>
                }
            >
                <div className={s.popupContent}>
                    <div className={s.popupContentInner}>
                        <h3 className={s.popupTitle}>
                            This test cannot be activated due to missing information
                        </h3>
                        <ul className={s.popupDetail}>
                            {errorContent.map((item: string, index: number) => {
                                return <li key={index}>{item}</li>;
                            })}
                        </ul>
                    </div>
                </div>
            </Modal>
            {contextHolder}
        </div>
    );
};

export default Index;
