import React from 'react';
import s from './s.module.less';
import { Button, Drawer, Input } from 'antd';
import { getTimeStr } from 'utils/calendar';
import { SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import HistoryAction from '../HistoryButton';
import { addOpsRecentSearch, getOpsRecentSearch } from 'utils/localstore';

type QuickItem = {
    label: string,
    value: string,
    type?: string,
};

type History = Record<string, string[]>;

const MOCK_QUICK:QuickItem[] = [
    {
        label: 'Generic prompt',
        value: 'Show me providers in TX with session fee below $200.',
        type: 'init',
    },
    {
        label: 'Therapist and counsellor',
        value: 'who are therapists or counsellors',
    },
    {
        label: 'Insurance',
        value: 'accepts _____ insurance',
    },
    {
        label: 'Drug seeking',
        value: 'prescribes controlled medications',
    },
    {
        label: 'Similar providers',
        value: 'Help me find providers who are similar to _____',
        type: 'init',
    },
    // {
    //     label: 'Generic prompt for specialty',
    //     value: 'Help in find providers in {state} who treats Specialty or {condition}',
    //     type: 'init',
    // },
];

const MOCK_DATA_HISTORY:History = {
    _1716778641048: [
        'Similar providers',
        'Insurance',
        'Drug seeking',
    ],
    _1716778645048: [
        'Help me find providers who are similar to',
    ],
};

type Props = {
    onSearch: (val:string)=>void,
    onConversationUpdateFromHistory: (id: string, name: string)=>void,
};

const Page = (prop:Props) => {
    const { onSearch, onConversationUpdateFromHistory } = prop;
    const [quickData, setQuickData] = React.useState<QuickItem[]>(MOCK_QUICK);
    const recentSearchData:string[] = getOpsRecentSearch();

    const [pendingSearchContent, setPendingSearchContent] = React.useState<string>('Show me providers in TX, the provider should has experiences with ADHD medications.');
    const [showHistory, setShowHistory] = React.useState(false);
    const [historyData, setHistoryData] = React.useState<History>(MOCK_DATA_HISTORY);

    const handleSearch = () => {
        if (pendingSearchContent) {
            addOpsRecentSearch(pendingSearchContent);
            setPendingSearchContent('');
            onSearch?.(pendingSearchContent);
        }
    };

    return (
        <div className={s.wrap}>
            <div className={s.nav}>
                <HistoryAction
                    onSearch={(val:string) => {
                        onSearch?.(val);
                    }}
                    onConversationUpdate={(id, name) => {
                        onConversationUpdateFromHistory(id, name);
                    }}
                />
            </div>
            <div className={s.content}>
                <h2>Provider Search</h2>
                <div className={s.inputWrap}>
                    <span className={s.search}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 9.29583 13.5892 10.4957 12.8907 11.4765L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L11.4765 12.8907C10.4957 13.5892 9.29583 14 8 14C4.68629 14 2 11.3137 2 8Z" fill="#9CA3AF" />
                        </svg>
                    </span>
                    <Input.TextArea
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey && !e.ctrlKey && !e.altKey && !e.metaKey) {
                                e.preventDefault(); // 阻止默认行为（可选）
                                handleSearch();
                            }
                        }}
                        onChange={(e) => {
                            setPendingSearchContent(e.target.value);
                        }}
                        draggable={false}
                        placeholder="Enter your prompt here..."
                        rows={7}
                        value={pendingSearchContent}
                    />
                    <Button
                        disabled={!pendingSearchContent}
                        className={s.confirm}
                        type="primary"
                        onClick={() => {
                            handleSearch();
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M11.5372 4.61719L16.9218 10.0018M16.9218 10.0018L11.5372 15.3864M16.9218 10.0018L3.07568 10.0018" stroke="white" strokeWidth="1.53846" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </Button>
                </div>
                <div className={s.quick}>
                    {
                        quickData?.map((item) => {
                            return (
                                <div
                                    onClick={() => {
                                        let val = item.value;
                                        if (item.type !== 'init') {
                                            if (pendingSearchContent) {
                                                val = `${pendingSearchContent}, ${val}`;
                                            }
                                        }
                                        setPendingSearchContent(val);
                                    }}
                                    className={s.quickItem}
                                    key={item.value}
                                >
                                    {item.label} +
                                </div>
                            );
                        })
                    }
                </div>
                <div className={s.recentSearch}>
                    <h4>⌛Recent searches</h4>
                    <div className={s.recentSearchData}>
                        {
                            recentSearchData?.map((val:string) => {
                                return (
                                    <div
                                        onClick={() => {
                                            setPendingSearchContent(val);
                                        }}
                                        className={s.recentItem}
                                        key={val}
                                    >
                                        {val}
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
            <Drawer
                title="History"
                onClose={() => {
                    setShowHistory(false);
                }}
                open={showHistory}
                className={s.drawer}
            >
                <div className={s.drawerWrap}>
                    <div className={s.searchWrap}>
                        <Input
                            placeholder="Search"
                            prefix={<SearchOutlined />}
                        />
                    </div>
                    <div className={s.historyWrap}>
                        {
                            Object.keys(historyData)?.map((val:string) => {
                                const time = val?.replace('_', '');
                                const historyItem:string[] = historyData[val];

                                return (
                                    <div
                                        className={s.historyItem}
                                        key={time}
                                    >
                                        <div className={s.historyTime}>
                                            {getTimeStr(dayjs(parseInt(time, 10)), 'MMM DD, YYYY')}
                                        </div>
                                        <div className={s.historyContentWrap}>
                                            {
                                                historyItem?.map((hisVal:string) => {
                                                    return (
                                                        <div
                                                            onClick={() => {
                                                                setShowHistory(false);
                                                                onSearch?.(hisVal);
                                                                setPendingSearchContent(hisVal);
                                                            }}
                                                            className={s.historyContentItem}
                                                            key={hisVal}
                                                        >
                                                            {hisVal}
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>

                </div>
            </Drawer>
        </div>
    );
};

export default Page;
