import TinyStore from 'lib/tinyStore';
import { ChannelHomeInfo, EProfileStatus } from 'types/channel';

type ProviderStatusModal = {
    data: ChannelHomeInfo;
    loading: boolean;
};

const Store = TinyStore.createStore<ProviderStatusModal>({
    nameSpace: 'klarity',
    key: 'providerStatus',
    crossBundle: false,
    runTime: true,
    initValue: { data: {
        profileStatus: EProfileStatus.REQUIRED,
        channelCount: 0,
        channelGuide: false,
    },
    loading: false },
});

export default Store;
