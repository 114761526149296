import React, { useCallback, useEffect, useState } from 'react';
import s from './s.module.less';
import { Button, Input, message } from 'antd';
import { updateOpsNotes, updateUniprofileNotes } from 'api/operation';

interface IProps {
    isEditing: boolean;
    showError: boolean;
    channelId: number;
    providerId: number;
    notes: string;
    onClick: (s:boolean) => void;
    refetch: () => void;
}

const Notes = ({
    channelId,
    providerId,
    isEditing,
    showError,
    notes,
    onClick,
    refetch,
}: IProps) => {
    const [notesValue, setNotesValue] = useState('');

    useEffect(() => {
        setNotesValue(notes);
    }, [notes]);

    const handleClick = useCallback((status: boolean) => {
        onClick(status);
    }, []);

    const handleSave = useCallback(async () => {
        const isUniprofile = `${channelId}` === '-1';
        const fn = isUniprofile ? updateUniprofileNotes : updateOpsNotes;
        const requestParam:any = {
            providerId,
            notes: notesValue,
        };
        if (!isUniprofile) {
            requestParam.channelId = channelId;
        }
        const res = await fn(requestParam);

        if (res?.error) {
            message.error(res.error);
        } else {
            message.success('notes saved successfully');
            onClick(false);
            refetch();
        }
    }, [channelId, notesValue, providerId]);

    const handleChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNotesValue(e.target.value);
    }, []);

    return (
        <div className={s.wrap}>
            {
                isEditing ?
                    <>
                        <div className={s.textarea}>
                            <Input.TextArea maxLength={1000} showCount style={{ height: '144px' }} value={notesValue} onChange={handleChange} />
                            { showError && <p>Please finish editing notes before changing tabs.</p>}
                        </div>
                        <div className={s.btns}>
                            <Button type="primary" onClick={handleSave}>Save changes</Button>
                            <Button type="primary" ghost onClick={() => handleClick(false)}>Cancel</Button>
                        </div>
                    </> :
                    <>
                        <p>{notes}</p>
                        <Button type="primary" ghost onClick={() => handleClick(true)}>Edit</Button>
                    </>
            }
        </div>
    );
};

export default Notes;
