import React, { useCallback, useEffect, useMemo } from 'react';
import s from './s.module.less';
import { Button, Checkbox, Form, Input, message, Modal, Radio, Space, Spin } from 'antd';
import ReviewItem from 'pages/dashboard/KlarityReviewsDashboradPage/components/WaitForReply/components/ReviewItem';
import { TReviewItem } from 'pages/dashboard/KlarityReviewsDashboradPage/types';
import commonS from 'styles/common.module.less';
import { EDISPUTE_STATUS } from 'types/common';
import { getDisputeDetail, submitDiputeDisission } from 'api/operation/reviews';
import { getPrivateFilePreview } from 'api/operation';
import dayjs from 'dayjs';
// import useSettings from 'hooks/useSettings';
// import { EnumFields } from 'types/enumerationData';

interface IProps {
    open?: boolean;
    reviewItem?: TReviewItem;
    providerId?: number;
    onCancel?: () => void;
    onSubmitSuccess?: () => void;
}

const DisputeDetailPopup = ({
    open,
    reviewItem,
    providerId,
    onCancel,
    onSubmitSuccess,
}: IProps) => {
    const [edit, setEdit] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [review, setReview] = React.useState<TReviewItem>();
    const [formInstance] = Form.useForm();
    const [pageLoading, setPageLoading] = React.useState(false);
    const [ensure, setEnsure] = React.useState(false);
    const [showEnsureWarning, setShowEnsureWarning] = React.useState(false);
    // const [setting] = useSettings();
    // const disputeReasonOptions = setting[EnumFields.REVIEW_DISPUTE_REASON] || [];

    useEffect(() => {
        formInstance.setFieldsValue({
            disputeStatus: EDISPUTE_STATUS.VALID,
        });
    }, [formInstance]);

    const fetchDetail = useCallback(async () => {
        if (reviewItem && providerId) {
            setLoading(true);
            try {
                const res = await getDisputeDetail(providerId, reviewItem.id);

                if (res && !res.error) {
                    setReview(res.data.data);
                    setEdit(res.data.data?.disputeStatus === EDISPUTE_STATUS.INVESTIGATION);
                } else {
                    message.error(res?.error);
                }
            } catch (e) {
                console.error(e);
            }
        }

        setLoading(false);
    }, [providerId, reviewItem]);

    useEffect(() => {
        fetchDetail();
    }, [fetchDetail]);

    const handleFormSubmit = useCallback(() => {
        formInstance.validateFields().then(async (values) => {
            if (!ensure) {
                setShowEnsureWarning(true);
            } else {
                const params = {
                    ...values,
                    reviewId: review?.reviewId,
                };

                setPageLoading(true);
                try {
                    const res = await submitDiputeDisission(params);

                    if (res && !res.error) {
                        formInstance.resetFields();
                        onSubmitSuccess?.();
                    } else {
                        message.error(res?.error);
                    }
                } catch (e) {
                    console.error(e);
                }
                setPageLoading(false);
            }
        }).catch((e) => {
            console.error(e);
        });
    }, [ensure, formInstance, onSubmitSuccess, review]);

    const handleEnsureChange = useCallback((e) => {
        setEnsure(e.target.checked);
        if (e.target.checked) {
            setShowEnsureWarning(false);
        }
    }, []);

    const handleLinkClick = useCallback(async (v?: string) => {
        if (v) {
            setPageLoading(true);
            try {
                const res = await getPrivateFilePreview(v);

                if (res && !res.error && res.data?.data?.singedUrl) {
                    window.open(res.data.data.singedUrl, '_blank');
                } else if (!res.data?.data?.singedUrl) {
                    message.error('there is no file to preview.');
                } else {
                    message.error(res?.error);
                }
            } catch (e) {
                console.error(e);
            }
            setPageLoading(false);
        }
    }, []);

    const handleCancel = useCallback(() => {
        formInstance.resetFields();
        onCancel?.();
    }, [formInstance, onCancel]);

    const popupButtonsRender = useMemo(() => {
        if (edit) {
            return [
                <Button key="back" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="back" type="primary" onClick={handleFormSubmit}>
                    Submit
                </Button>,
            ];
        } else {
            return [
                <Button key="back" type="primary" onClick={handleCancel}>
                    Close
                </Button>,
            ];
        }
    }, [edit, handleFormSubmit, handleCancel]);

    return (
        <Modal
            title="View dispute"
            className={commonS.modalFixHeightWrap}
            width={1100}
            destroyOnClose
            confirmLoading={pageLoading}
            open={open}
            onCancel={handleCancel}
            footer={popupButtonsRender}
        >
            {
                loading ? <Spin spinning /> : (
                    <Spin spinning={pageLoading}>
                        <div className={s.wrap}>
                            <div className={s.left}>
                                <div className={s.reviewItem}>
                                    <ReviewItem
                                        review={reviewItem}
                                        hideRightPart
                                        hideBorder
                                    />
                                </div>
                                <div className={s.info}>
                                    <div className={s.title}>Dispute reported</div>
                                    <div className={s.row}>
                                        <div className={s.item}>
                                            <div className={s.label}>Report date: </div>
                                            <div className={s.value}>{review?.disputeSubmitAt && dayjs(review.disputeSubmitAt).format('MMMM DD, YYYY')}</div>
                                        </div>
                                        <div className={s.item}>
                                            <div className={s.label}>Reason for dispute: </div>
                                            <div className={s.value}>{review?.disputeReasonType}</div>
                                        </div>
                                    </div>
                                    <div className={s.row}>
                                        <div className={s.item}>
                                            <div className={s.label}>Detail:</div>
                                            <div className={`${s.value} ${s.detail}`}>
                                                {review?.disputeReasonDetail}
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        review?.disputeSupportFile && Object.keys(review.disputeSupportFile).length > 0 && (
                                            <div className={s.row}>
                                                <div className={s.item}>
                                                    <div className={s.label}>Support documents:</div>
                                                    <div className={s.value}>
                                                        <div className={s.docList}>
                                                            {
                                                                Object.keys(review.disputeSupportFile).map((v, i) => (
                                                                    <div key={i} className={s.docLink} onClick={() => handleLinkClick(v)}>{review.disputeSupportFile?.[v]}</div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                </div>
                            </div>
                            <div className={s.right}>
                                <div className={s.rightInner}>

                                    <div className={s.title}>Dispute decision</div>
                                    {
                                        edit ? (
                                            <div className={s.form}>
                                                <Form
                                                    className={commonS.formStyle1}
                                                    form={formInstance}
                                                    id="disputeDetailForm"
                                                    layout="vertical"
                                                >
                                                    <Form.Item
                                                        label="Is this a valid dispute?"
                                                        name="disputeStatus"
                                                        validateFirst
                                                        rules={[
                                                            { required: true, message: 'Please make a choice' },
                                                        ]}
                                                    >
                                                        <Radio.Group>
                                                            <Space direction="vertical">
                                                                <Radio value={EDISPUTE_STATUS.VALID}>Yes, I agree with provider’s dispute.</Radio>
                                                                <Radio value={EDISPUTE_STATUS.INVALID}>No, I agree with patient’s review.</Radio>
                                                            </Space>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="Reason"
                                                        name="reason"
                                                        rules={[
                                                            { required: true, message: 'Please input the reason' },
                                                        ]}
                                                    >
                                                        <Input.TextArea showCount maxLength={600} style={{ height: '230px' }} />
                                                    </Form.Item>
                                                </Form>
                                                <div className={s.checkbox}>
                                                    <Checkbox onChange={handleEnsureChange}><span className={`${showEnsureWarning ? s.warning : ''}`}>By confirming this decision, I am finalizing the outcome of this dispute. This action cannot be undone.</span></Checkbox>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className={s.viewForm}>
                                                <div className={`${s.disputeStatus} ${review?.disputeStatus === EDISPUTE_STATUS.INVALID ? s.invalid : ''}`}>{`Dispute ${review?.disputeStatus?.toLowerCase()}`}</div>
                                                <div className={s.opsInfo}>{`Decision was made by ${reviewItem?.disputeDecisionByFirstName || ''} ${reviewItem?.disputeDecisionByLastName || ''} on ${dayjs(review?.disputeDecisionAt).format('MM/DD/YYYY')}`}</div>
                                                <div className={s.item}>
                                                    <div className={s.label}>Reason</div>
                                                    <div className={s.value}>{review?.disputeDecisionReason}</div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </Spin>
                )
            }

        </Modal>
    );
};

export default DisputeDetailPopup;
