import React, {
    useState,
    useEffect,
} from 'react';
import s from './s.module.less';
import { Button, Descriptions, Form, Input, Modal } from 'antd';
import type { TEngagementQuestion } from 'types/operation';
import commonS from 'styles/common.module.less';
import FileUpload from 'components/form/FileUpload';
import {
    engagementTipsAddOrUpdate,
    deleteEngagementTips,
} from 'api/operation/assessment';

type IProps = {
    index: number;
    item: TEngagementQuestion;
    questionCount: number;
    assessmentId: string | number;
    onSuccess: (obj: Partial<TEngagementQuestion>) => void;
};

const EngagementQuestionsItem: React.FC<IProps> = (props) => {
    const { item, index, questionCount, assessmentId, onSuccess } = props;
    const [isEdit, setIsEdit] = useState(false);
    const [form] = Form.useForm<TEngagementQuestion & { active: boolean }>();

    const handleEdit = () => {
        setIsEdit(true);
    };

    const handleCancel = () => {
        setIsEdit(false);
    };

    const handleSave = async () => {
        const formValues = await form.validateFields();
        const params = {
            id: item.id,
            seq: item.seq,
            title: formValues.title,
            type: 'EngagementQuestions',
            imgUrl: formValues.imgUrl,
            assessmentId,
        };
        const { data } = await engagementTipsAddOrUpdate(params);
        if (!data.error) {
            setIsEdit(false);
            onSuccess(params);
        }
    };

    const handleRemove = async () => {
        const { data } = await deleteEngagementTips({
            id: item.id,
        });
        if (!data.error) {
            onSuccess({});
        }
    };

    const renderDescription = () => {
        return (
            <Descriptions
                colon={false}
                title={`Engagement questions ${index + 1}`}
                layout="vertical"
                extra={
                    <>
                        <Button
                            type="text"
                            className={s.removeBtn}
                            onClick={() => {
                                Modal.confirm({
                                    className: s.removeModal,
                                    title: ` RemoveEngagement questions ${index + 1}`,
                                    content:
                        'Do you want to remove this RemoveEngagement questions ?',
                                    okText: 'Confirm',
                                    onOk: () => {
                                        handleRemove();
                                    },
                                });
                            }}
                        >
                            Remove
                        </Button>
                        <Button onClick={() => handleEdit()}>Edit</Button>
                    </>
                }
                column={1}
                size="small"
                contentStyle={{
                    marginBottom: '16px',
                }}
            >
                <Descriptions.Item label="Text">{item.title}</Descriptions.Item>
                <Descriptions.Item label="Image">
                    {item.imgUrl ? (
                        <img
                            src={item.imgUrl}
                            alt={item.title}
                            className={s.cardImg}
                        />
                    ) : (
                        '-'
                    )}
                </Descriptions.Item>
            </Descriptions>
        );
    };

    const renderForm = () => {
        return (
            <div>
                <div className={s.headerBar}>
                    <h4 className={s.subtitle}>
                        {`Engagement questions ${index + 1}`}
                    </h4>
                    <div className={s.buttonWrap}>
                        <Button onClick={() => handleCancel()}>Cancel</Button>
                        <Button
                            onClick={() => handleSave()}
                            type="primary"
                            className={s.ml8}
                        >
                            Save changes
                        </Button>
                    </div>
                </div>
                <p className={s.tips}>
                    <span>* </span>
                    Indicates required fields
                </p>
                <Form
                    form={form}
                    className={commonS.formStyle1}
                    initialValues={{ active: true }}
                    autoComplete="off"
                    layout="vertical"
                    scrollToFirstError
                >
                    <>
                        <Form.Item
                            className={s.rowItem}
                            label="Text"
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: 'Text is required',
                                },
                            ]}
                        >
                            <Input.TextArea maxLength={500} rows={5} showCount />
                        </Form.Item>
                        <Form.Item
                            className={s.rowItem}
                            name="imgUrl"
                            label={
                                <div className={s.formHeader}>
                                    <span>Image</span>
                                    <p className={s.descriptionTips}>
                                        Images taller than 100px will be automatically resized
                                        or cropped. Please keep the height within this limit for
                                        best results.
                                    </p>
                                </div>
                            }
                        >
                            <FileUpload
                                accept="image/png, image/jpeg, image/gif"
                                tips="PNG, JPG, GIF up to 10MB"
                            />
                        </Form.Item>
                    </>
                </Form>
            </div>
        );
    };

    useEffect(() => {
        if (item) {
            form.setFieldsValue({
                title: item.title,
                imgUrl: item.imgUrl,
            });
        } else {
            form.resetFields();
        }
    }, [item]);

    return (
        <div className={s.wrap}>
            <div className={s.content}>
                {isEdit ? renderForm() : renderDescription()}
            </div>
        </div>
    );
};

export default EngagementQuestionsItem;
