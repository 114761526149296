import React, { useEffect, useState, useMemo } from 'react';
import {
    Button,
    Descriptions,
    Spin,
    Select,
    Modal,
    message,
    Form,
    Input,
    Tooltip,
    Card,
} from 'antd';
import { TPageManagement, type TConditionSetting, type TObjectAction } from 'types/helloKlarity';
import EditCondition from '../EditCondition';
import useGetSpecialtyList from 'hooks/klarityServiceTemplateOps/useGetSpecialtyList';
import {
    exchangePriority,
    conditionSaveOrUpdateBatch,
} from 'api/operation/pageManagement';
import ConditionSelect from 'components/form/ConditionSelect';
import FileUpload from 'components/form/FileUpload';

import s from './s.module.less';
import cx from 'classnames';

type IProps = {
    isLoading: boolean;
    goLiveObj: TPageManagement;
    onSuccess: (obj: TObjectAction) => void;
    onPreview: (name: string) => void;
    onRefreshConditionList: () => void;
};

const ConditionManagement: React.FC<IProps> = ({
    goLiveObj,
    isLoading,
    onPreview,
    onSuccess,
    onRefreshConditionList,
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [item, setItem] = useState<TConditionSetting>();
    const [condition, setCondition] = useState<TConditionSetting[]>([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [currentSpecialty, setCurrentSpecialty] = useState<string>('');
    const [tempSpecialty, setTempSpecialty] = useState<string>('');
    const [listData, tableLoading, error, refetch] = useGetSpecialtyList();
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [form] = Form.useForm();
    // const { condition, isLoading, fetchData } = useKlarityCondition();

    const disableConditionList = useMemo(() => {
        return [];
    }, []);

    const handleEdit = (selectItem: TConditionSetting) => {
        setItem(selectItem);
        setIsOpen(true);
    };

    const handleSuccess = (obj: TObjectAction) => {
        setIsOpen(false);
        setItem(undefined);
        onSuccess(obj);
        // fetchData();
    };

    const changeActiveTab = (index: number) => {
        setActiveIndex(index);
    };

    const handleExchange = async ({
        fromSpecialty,
        toSpecialty,
    }: {
        fromSpecialty: string;
        toSpecialty: string;
    }) => {
        const { data } = await exchangePriority({
            fromSpecialty,
            toSpecialty,
        });
        if (data.data) {
            message.success('Exchange successfully');
            onRefreshConditionList();
        }
    };

    const handleChangeSpecialty = (val: string) => {
        const filterSpecialtyArr = goLiveObj.conditions.filter(
            (conditionItem) => conditionItem.serviceSpecialtyName === val,
        );
        const hadSettingSpecialty = filterSpecialtyArr.some(
            (conditionItem) => !!conditionItem.conditionName,
        );
        const filterSpecialtyPriority =
      filterSpecialtyArr.length > 0
          ? filterSpecialtyArr[0].serviceSpecialtyPriority
          : -1;
        // case 1: if there is a condition under a specialty and the serviceSpecialtyPriority > 4
        if (hadSettingSpecialty && filterSpecialtyPriority > 4) {
            handleExchange({
                fromSpecialty: currentSpecialty,
                toSpecialty: val,
            });
            return;
        }

        // case 2: if there is no condition under a specialty and then pup up the modal
        if (!hadSettingSpecialty) {
            setTempSpecialty(val);
            setIsShowModal(true);
            form.setFieldsValue({ items: filterSpecialtyArr });
            return;
        }

        // case 3: if there is a condition under a specialty and the serviceSpecialtyPriority <= 4, exchange
        if (hadSettingSpecialty && filterSpecialtyPriority <= 4) {
            Modal.confirm({
                className: s.removeModal,
                title: 'Exchange specialty',
                content: `Do you want to change ${currentSpecialty} to ${val} ?`,
                okText: 'Confirm',
                onOk: () => {
                    handleExchange({
                        fromSpecialty: currentSpecialty,
                        toSpecialty: val,
                    });
                },
            });
        }
    };

    const onCancelModal = () => {
        setIsShowModal(false);
    };

    const onSave = async () => {
        try {
            setSubmitting(true);
            const formValues = await form.validateFields();
            const params = formValues.items.map((formItem: TConditionSetting, index: number) => {
                return {
                    id: formItem.id,
                    type: 'HOME',
                    conditionName: formItem.conditionName,
                    subtext: formItem.subtext,
                    logo: formItem.logo,
                    serviceSpecialtyName: tempSpecialty,
                    priority: index + 1,
                };
            });
            const { data } = await conditionSaveOrUpdateBatch(params);
            setSubmitting(false);
            if (data.data) {
                const res = await exchangePriority({
                    fromSpecialty: currentSpecialty,
                    toSpecialty: tempSpecialty,
                });
                if (res.data?.data) {
                    message.success('Updated successfully');
                    onRefreshConditionList();
                    setIsShowModal(false);
                }
            }
        } catch (err) {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        changeActiveTab(0);
    }, []);

    useEffect(() => {
        const specialty =
      (goLiveObj.conditions || []).find(
          (conditionItem: TConditionSetting) =>
              conditionItem.serviceSpecialtyPriority === activeIndex + 1,
      )?.serviceSpecialtyName || '';
        setCurrentSpecialty(specialty);
    }, [activeIndex, goLiveObj.conditions]);

    useEffect(() => {
        const currentCondition = (goLiveObj.conditions || []).filter(
            (conditionItem) =>
                conditionItem.serviceSpecialtyName === currentSpecialty,
        );
        setCondition(currentCondition);
        // onPreview(currentCondition);
        onPreview(currentSpecialty);
    }, [currentSpecialty, goLiveObj.conditions]);

    return (
        <div className={s.wrap}>
            <Spin spinning={isLoading}>
                <div className={s.wrapper}>
                    <div className={s.specialtyWrap}>
                        {(new Array(4).fill(null) || []).map((_, index) => {
                            return (
                                <div
                                    className={cx(
                                        s.specialtyItem,
                                        activeIndex === index && s.current,
                                    )}
                                    key={index}
                                    onClick={() => changeActiveTab(index)}
                                >
                                    Specialty {index + 1}
                                </div>
                            );
                        })}
                    </div>
                    <div className={s.selectSpecialtyWrap}>
                        <Select
                            style={{ width: '150px' }}
                            value={currentSpecialty}
                            className={s.selectSpecialty}
                            onChange={(val) => handleChangeSpecialty(val)}
                        >
                            {listData.map((specialtyItem) => {
                                return (
                                    <Select.Option
                                        value={specialtyItem.specialtyName}
                                        key={specialtyItem.id}
                                    >
                                        {specialtyItem.specialtyName}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </div>
                    {condition
                        ?.filter(
                            (conditionItem: TConditionSetting) =>
                                conditionItem.serviceSpecialtyName === currentSpecialty,
                        )
                        .map((conditionItem: TConditionSetting, index) => (
                            <div className={s.panel} key={index}>
                                <Descriptions
                                    title={`Condition ${conditionItem.priority}`}
                                    layout="vertical"
                                    extra={
                                        <Button onClick={() => handleEdit(conditionItem)}>
                                            Edit
                                        </Button>
                                    }
                                    column={1}
                                    size="small"
                                    contentStyle={{
                                        marginBottom: '16px',
                                    }}
                                >
                                    <Descriptions.Item label="Condition name">
                                        {conditionItem.conditionName}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Subtext">
                                        {conditionItem.subtext}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Logo">
                                        {conditionItem.logo && (
                                            <img
                                                src={conditionItem.logo}
                                                alt={conditionItem.conditionName}
                                                className={s.conditionLogo}
                                            />
                                        )}
                                    </Descriptions.Item>
                                </Descriptions>
                            </div>
                        ))}
                </div>
            </Spin>
            <Modal
                title={`Add conditions for ${tempSpecialty}`}
                open={isShowModal}
                destroyOnClose
                okText="Save"
                onCancel={onCancelModal}
                width="500px"
                footer={
                    <div>
                        <Button onClick={onCancelModal}>Cancel</Button>
                        <Button loading={submitting} type="primary" onClick={onSave}>
                            Save changes
                        </Button>
                    </div>
                }
            >
                <div className={s.addWrap}>
                    <Form
                        form={form}
                        name="conditionForm"
                        autoComplete="off"
                        initialValues={{ items: [{}] }}
                        scrollToFirstError
                        layout="vertical"
                    >
                        <Form.List name="items">
                            {(fields, { add, remove }) => (
                                <div className={s.cardWrap}>
                                    {fields.map((field) => (
                                        <div key={field.key} className={s.card}>
                                            <h3 className={s.cardTitle}>
                                                {`Condition ${field.name + 1}`}
                                            </h3>
                                            <div className={s.cardContent}>
                                                <Form.Item
                                                    className={s.rowItem}
                                                    label="Condition name"
                                                    name={[field.name, 'conditionName']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Condition name is required',
                                                        },
                                                    ]}
                                                >
                                                    <ConditionSelect
                                                        isLabel
                                                        disableValues={disableConditionList}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    className={s.rowItem}
                                                    label="Subtext"
                                                    name={[field.name, 'subtext']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Subtext is required',
                                                        },
                                                    ]}
                                                >
                                                    <Input.TextArea
                                                        maxLength={200}
                                                        style={{ width: '100%' }}
                                                        rows={3}
                                                        showCount
                                                        placeholder="A brief description of the condition"
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    className={s.rowItem}
                                                    label={
                                                        <div className={s.formLabel}>
                                                            Logo
                                                            <Tooltip
                                                                overlayClassName={s.tipCard}
                                                                overlayInnerStyle={{ padding: '12px' }}
                                                                title={
                                                                    <div className={s.tipText}>
                                                                        Image should have a transparent background
                                                                        and be sized 118x118 pixels.
                                                                    </div>
                                                                }
                                                            >
                                                                <div className={s.questionMarkIcon} />
                                                            </Tooltip>
                                                        </div>
                                                    }
                                                    name={[field.name, 'logo']}
                                                    rules={[
                                                        { required: true, message: 'Image is required' },
                                                    ]}
                                                >
                                                    <FileUpload />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </Form.List>
                    </Form>
                </div>
            </Modal>
            {isOpen && (
                <EditCondition
                    serviceSpecialtyName={currentSpecialty}
                    serviceSpecialtyPriority={activeIndex + 1}
                    item={item}
                    list={condition}
                    onCancel={() => setIsOpen(false)}
                    onSuccess={handleSuccess}
                />
            )}
        </div>
    );
};

export default ConditionManagement;
