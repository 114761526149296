import React from 'react';
import dayjs from 'dayjs';
import s from './s.module.less';
import type { CheckboxOptionType } from 'antd/es/checkbox';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { SearchOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import { Button, Tag, Input, Space, Checkbox } from 'antd';
import { EProviderInfoStatus, ProviderListItem } from 'types/provider';
import CommonTable from 'components/CommonTable';
import { useRequest } from 'ahooks';
import { providerListFetch } from 'api/operation';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import { getTimeStr } from 'utils/calendar';
import { EProgressForProvider } from 'types/operation';

const CheckboxGroup = Checkbox.Group;

const allValue = 'All';

const Page = () => {
    const [page, setPage] = React.useState(1);
    const navigate = useNavigate();
    const [pendingNameFilter, setPendingNameFilter] = React.useState('');
    const [nameFilter, setNameFilter] = React.useState('');
    const allStatusValues = [allValue, EProviderInfoStatus.IN_PROGRESS, EProviderInfoStatus.FINISHED_SIGN_UP, EProviderInfoStatus.SUBMITTED_APPLICATION];
    const [statusFilterValues, setStatusFilterValues] = React.useState(allStatusValues);

    const allProgressValues = [allValue, EProgressForProvider.FOLLOW_UP_WITH_PROVIDER, EProgressForProvider.PENDING_REVIEW, EProgressForProvider.APPLICATION_APPROVED, EProgressForProvider.APPLICATION_ON_HOLD, EProgressForProvider.BP_ACCOUNT_ENTERED];
    const [progressFilterValues, setProgressFilterValues] = React.useState(allProgressValues);

    const { data, loading } = useRequest(providerListFetch, {
        defaultParams: [
            {
                page,
                size: 20,
            },
        ],
    });

    const listData:ProviderListItem[] = data?.data?.data || [];

    let list:ProviderListItem[] = listData.sort((a:ProviderListItem, b:ProviderListItem) => { return b.id - a.id; });

    if (nameFilter || !progressFilterValues.includes(allValue) || !statusFilterValues.includes(allValue)) {
        list = list.filter((item) => {
            const nameFit = !nameFilter || item.firstName?.toLowerCase().includes(nameFilter.toLowerCase()) || item.lastName?.toLowerCase().includes(nameFilter.toLowerCase()) || item.email?.toLowerCase().includes(nameFilter.toLowerCase());
            const statusFit = statusFilterValues.length === 0 || statusFilterValues.includes(allValue) || statusFilterValues.includes(item.status!);
            let progressStatus:string | undefined = item.status;
            if (item.status === EProviderInfoStatus.SUBMITTED_APPLICATION) {
                progressStatus = EProgressForProvider.PENDING_REVIEW;
            } else if (item.status === EProviderInfoStatus.FINISHED_SIGN_UP || item.status === EProviderInfoStatus.IN_PROGRESS) {
                progressStatus = EProgressForProvider.FOLLOW_UP_WITH_PROVIDER;
            }
            const progressFit = progressFilterValues.length === 0 || progressFilterValues.includes(allValue) || (progressStatus && progressFilterValues.includes(progressStatus));
            return nameFit && statusFit && progressFit;
        });
    }

    React.useEffect(() => {
        setPage(1);
    }, [nameFilter, statusFilterValues, progressFilterValues]);

    const columns: ColumnsType<ProviderListItem> = [
        {
            title: 'NAME',
            dataIndex: 'name',
            filterSearch: true,
            width: '280px',
            filterIcon: <SearchOutlined />,
            filterDropdown: () => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        value={pendingNameFilter}
                        onChange={(e) => {
                            setPendingNameFilter(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && pendingNameFilter) {
                                setNameFilter(pendingNameFilter);
                            }
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                setNameFilter(pendingNameFilter);
                            }}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => {
                                setPendingNameFilter('');
                                setNameFilter('');
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            render: (name:string, item:ProviderListItem) => {
                return (
                    <div className={s.nameWrap}>
                        <div className={s.nameRight}>
                            <div className={s.name}>{item.firstName} {item.lastName}</div>
                            <div className={s.email}>{item.email}</div>
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'SIGNUP DATE',
            dataIndex: 'createTime',
            render: (createTime) => {
                return (
                    <div>{createTime ? getTimeStr(dayjs(createTime), 'YYYY/MM/DD') : 'Nil'}</div>
                );
            },
        },
        {
            title: 'APPLICATION PROGRESS',
            dataIndex: 'status',
            width: '250px',
            filterIcon: <SearchOutlined />,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            filterDropdown: () => {
                const options:CheckboxOptionType[] = [
                    {
                        label: 'All',
                        value: allValue,
                    },
                    {
                        label: EProviderInfoStatus.IN_PROGRESS,
                        value: EProviderInfoStatus.IN_PROGRESS,
                    },
                    {
                        label: EProviderInfoStatus.FINISHED_SIGN_UP,
                        value: EProviderInfoStatus.FINISHED_SIGN_UP,
                    },
                    {
                        label: EProviderInfoStatus.SUBMITTED_APPLICATION,
                        value: EProviderInfoStatus.SUBMITTED_APPLICATION,
                    },
                ];
                const onFilterChange = (value:CheckboxValueType[]) => {
                    if (value.includes(allValue) && !statusFilterValues.includes(allValue)) {
                        setStatusFilterValues(allStatusValues);
                    } else if (statusFilterValues.includes(allValue) && !value.includes(allValue)) {
                        //uncheck all
                        setStatusFilterValues([]);
                    } else if (value.length === allStatusValues.length - 1 && !value.includes(allValue)) {
                        //uncheck all
                        setStatusFilterValues(allStatusValues);
                    } else if (value.length < allStatusValues.length && value.includes(allValue)) {
                        //uncheck all
                        setStatusFilterValues((value as string[]).filter((val) => val !== allValue));
                    } else {
                        setStatusFilterValues(value as string[]);
                    }
                };
                return (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <CheckboxGroup
                            className={s.statusFilter}
                            options={options}
                            value={statusFilterValues}
                            onChange={onFilterChange}
                        />
                    </div>
                );
            },
            render: (status) => {
                const isSubmitd = !!(status && [EProviderInfoStatus.SUBMITTED_APPLICATION, EProviderInfoStatus.APPLICATION_ON_HOLD, EProviderInfoStatus.BP_ACCOUNT_ENTERED, EProviderInfoStatus.APPLICATION_APPROVED].includes(status));
                if (isSubmitd) {
                    return <div>{EProviderInfoStatus.SUBMITTED_APPLICATION}</div>;
                } else {
                    return <div>{status || EProviderInfoStatus.FINISHED_SIGN_UP}</div>;
                }
            },
        },
        {
            title: 'OPS PROGRESS',
            dataIndex: 'status',
            width: '280px',
            filterIcon: <SearchOutlined />,
            filterDropdown: () => {
                const options:CheckboxOptionType[] = [
                    {
                        label: 'All',
                        value: allValue,
                    },
                    {
                        label: EProgressForProvider.FOLLOW_UP_WITH_PROVIDER,
                        value: EProgressForProvider.FOLLOW_UP_WITH_PROVIDER,
                    },
                    {
                        label: EProgressForProvider.PENDING_REVIEW,
                        value: EProgressForProvider.PENDING_REVIEW,
                    },
                    {
                        label: EProgressForProvider.APPLICATION_APPROVED,
                        value: EProgressForProvider.APPLICATION_APPROVED,
                    },
                    {
                        label: EProgressForProvider.APPLICATION_ON_HOLD,
                        value: EProgressForProvider.APPLICATION_ON_HOLD,
                    },
                    {
                        label: EProgressForProvider.BP_ACCOUNT_ENTERED,
                        value: EProgressForProvider.BP_ACCOUNT_ENTERED,
                    },
                ];
                const onFilterChange = (value:CheckboxValueType[]) => {
                    if (value.includes(allValue) && !progressFilterValues.includes(allValue)) {
                        setProgressFilterValues(allProgressValues);
                    } else if (progressFilterValues.includes(allValue) && !value.includes(allValue)) {
                        //uncheck all
                        setProgressFilterValues([]);
                    } else if (value.length === allProgressValues.length - 1 && !value.includes(allValue)) {
                        //uncheck all
                        setProgressFilterValues(allProgressValues);
                    } else if (value.length < allProgressValues.length && value.includes(allValue)) {
                        //uncheck all
                        setProgressFilterValues((value as string[]).filter((val) => val !== allValue));
                    } else {
                        setProgressFilterValues(value as string[]);
                    }
                };
                return (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <CheckboxGroup
                            className={s.statusFilter}
                            options={options}
                            value={progressFilterValues}
                            onChange={onFilterChange}
                        />
                    </div>
                );
            },
            render: (status) => {
                return (
                    <div className={s.tag}>
                        {(status === EProviderInfoStatus.FINISHED_SIGN_UP || !status) && <Tag color="purple">{EProgressForProvider.FOLLOW_UP_WITH_PROVIDER}</Tag>}
                        {status === EProviderInfoStatus.IN_PROGRESS && <Tag color="purple">{EProgressForProvider.FOLLOW_UP_WITH_PROVIDER}</Tag>}
                        {status === EProviderInfoStatus.SUBMITTED_APPLICATION && <Tag color="gold">{EProgressForProvider.PENDING_REVIEW}</Tag>}
                        {status === EProviderInfoStatus.APPLICATION_ON_HOLD && <Tag color="red">{EProgressForProvider.APPLICATION_ON_HOLD}</Tag>}
                        {status === EProviderInfoStatus.APPLICATION_APPROVED && <Tag color="blue">{EProgressForProvider.APPLICATION_APPROVED}</Tag>}
                        {status === EProviderInfoStatus.BP_ACCOUNT_ENTERED && <Tag color="green">{EProgressForProvider.BP_ACCOUNT_ENTERED}</Tag>}
                    </div>
                );
            },
        },
        {
            title: '',
            render: (item) => {
                return (
                    <div>
                        <Button
                            className={s.link}
                            onClick={() => {
                                navigate(`${PATH.OPERATION}/${PATH.PROVIDER_ONBOARD_DETAIL}/${item.id}`);
                            }}
                            type="text"
                        >View
                        </Button>
                    </div>
                );
            },
        },
    ];
    return (
        <div className={s.wrap}>
            <h2>Account applications</h2>
            <div className={s.tableWrap}>
                <CommonTable
                    bordered
                    rowKey="id"
                    loading={loading}
                    columns={columns}
                    data={list || []}
                />
            </div>
        </div>
    );
};

export default Page;
