import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import s from "./s.module.less";
import { Form, Input, InputNumber, message, Spin, Switch, Tabs } from "antd";
import PackageInfo from "./components/PackageInfo";
import IncludedItems from "./components/IncludedItems";
import Patients from "./components/Patients";
import Pricing from "./components/Pricing";
import useGetPackageDetail from "hooks/ProgramsOps/useGetPackageDetail";
import { IKlarityProgramPackage, IKlarityProgramPackageInput } from "types/programs";
import { saveNUpdatePackage } from "api/operation/programs";
import { frequency, patientGroup } from "constants/program";
import ProgramsStore from "store/Programs";
import commonS from 'styles/common.module.less';
import cloneDeep from 'lodash/cloneDeep';

interface IProps {
  packageItem?: IKlarityProgramPackage;
  setSaveLoading?: (v: boolean) => void;
  programId: number;
  onSaveSuccess: () => void;
}

const PackageDetail = ({
  packageItem,
  setSaveLoading,
  programId,
  onSaveSuccess,
}: IProps) => {
  const [form] = Form.useForm();
  const [detail, loading, refetchPackageDetail] = useGetPackageDetail(Number(packageItem?.id));
  const [getProgramsStore] = ProgramsStore.useStore();
  const tags = getProgramsStore('tags');
  const [includedItemsData, setIncludedItemsData] = useState<any>({});
  const differentFirstPriceIsChecked = Form.useWatch('differentFirstPrice', form);
  const watchedBillingFrequencyTypeValue = Form.useWatch('billingFrequencyType', form);
  const watchedBillingPeriodValue = Form.useWatch('billingPeriod', form);
  const watchedFirstPaymentPrice = Form.useWatch('firstPaymentPrice', form);
  const watchedPrice = Form.useWatch('price', form);
  const [tabActiveKey, setTabActiveKey] = useState<string>('1');

  // 用来保留后端返回的数据，用于比较是否是新增的session和medication，新增的话，需要去掉id。
  const [originalIncludedItemsData, setOriginalIncludedItemsData] = useState<any>({});

  const cannotCalculateTotalPrice = useMemo(() => watchedBillingPeriodValue === 1 && watchedBillingFrequencyTypeValue !== frequency.ONE_TIME, [watchedBillingPeriodValue, watchedBillingFrequencyTypeValue]);
  
  const requireFieldsTabIndex = useRef({
    packageTitle: '1',
    description: '1',
    // introductionVideoLink: '1',
    patientFacingTitle: '1',
    firstPaymentDuration: '1',
    patientFacingDesc: '1',
    whatIncludeInfo: '1',
    disclaimerPolicy: '1',
    billingFrequencyType: '3',
    billingPeriod: '3',
    price: '3',
    firstPaymentPrice: '3',
    providerEarning: '3',
  });

  const { klarityProgramPackageExternalInfo  } = detail || {};

  const handleRefetch = useCallback(() => {
    refetchPackageDetail(Number(packageItem?.id));
  }, [packageItem]);

  useEffect(() => {
    if (detail) {
      const { klarityProgramPackageExternalInfo } = detail
      const faq = klarityProgramPackageExternalInfo?.faq ? JSON.parse(klarityProgramPackageExternalInfo.faq) : [];
      const patientGroup = klarityProgramPackageExternalInfo?.patientGroup ? klarityProgramPackageExternalInfo.patientGroup.slice(1, -1).split(',').map(item => item.trim()) : [];
      const formData = {
        ...detail,
        ...klarityProgramPackageExternalInfo,
        patientGroup,
        faq,
        // 如果是one time，billingPeriod默认为1，且禁用differentFirstPrice checkbox
        billingPeriod: detail.billingFrequencyType === frequency.ONE_TIME ? 1 : detail.billingPeriod,
        differentFirstPrice: detail.billingFrequencyType === frequency.ONE_TIME ? false : detail.differentFirstPrice,
        firstPaymentPrice: detail.billingFrequencyType === frequency.ONE_TIME ? detail.price : detail.firstPaymentPrice,
        klarityProgramPackageTagItemList: detail.klarityProgramPackageTagItemList?.map(item => {
          const tag = (tags || []).find(tag => tag.id === item.tagId);

          return {
            ...item,
            tagName: tag?.tagName,
          }
        }),
        
      }
      form.setFieldsValue(formData);
    }
  }, [detail]);

  useEffect(() => {
    if (detail) {
      const { klarityProgramPackageSessionItemList = [], klarityProgramMedicationItemList = []  } = detail;

      const originalIncludedItemsData = {
        klarityProgramPackageSessionItemList,
        klarityProgramMedicationItemList
      }
      setIncludedItemsData(cloneDeep(originalIncludedItemsData))

      // 用来保留后端返回的数据，用于比较是否是新增的session和medication，新增的话，需要去掉id。
      setOriginalIncludedItemsData(originalIncludedItemsData)
    }
  }, [detail]);

  const items = [
      {
          key: '1',
          label: 'Package inforamtion',
          children: <PackageInfo />,
        },
        {
          key: '2',
          label: 'Included items',
          children: <IncludedItems originalIncludedItemsData={originalIncludedItemsData} packageId={detail?.id} programId={programId} refetch={handleRefetch} includedItemsData={includedItemsData} setIncludedItemsData={setIncludedItemsData}/>,
        },
        {
          key: '3',
          label: 'Pricing',
          children: <Pricing
              watchedBillingPeriodValue={watchedBillingPeriodValue}
              watchedBillingFrequencyTypeValue={watchedBillingFrequencyTypeValue}
              watchedFirstPaymentPrice={watchedFirstPaymentPrice}
              watchedPrice={watchedPrice}
              differentFirstPriceIsChecked={differentFirstPriceIsChecked}
            />,
          forceRender: true,
        },
        {
          key: '4',
          label: 'Patients',
          children: <Patients />,
          forceRender: true,
        },
  ]

  const handleSubmitForm = useCallback(async (values) => {
    setSaveLoading?.(true);

    const originalDetails = detail || {};

    const formFields = {
      patientFacingDesc: values.patientFacingDesc,
      whatIncludeInfo: values.whatIncludeInfo,
      disclaimerPolicy: values.disclaimerPolicy,
      faq: values.faq,
      patientGroup: values.patientGroup,
      isAllPatientGroup: (values.patientGroup && values.patientGroup.length === patientGroup.length),
    }

    const externalInfo = klarityProgramPackageExternalInfo ? {
      ...klarityProgramPackageExternalInfo,
      ...formFields,
    } : {
      programId,
      packageId: detail?.id,
      ...formFields,

    }
    
    const saveData: IKlarityProgramPackageInput = {
      ...originalDetails,
      programId,
      totalPrice: cannotCalculateTotalPrice ? 0 : values.totalPrice,
      packageTitle: values.packageTitle,
      description: values.description,
      introductionVideoLink: values.introductionVideoLink,
      billingFrequencyType: values.billingFrequencyType,
      billingPeriod: values.billingPeriod || 1,
      price: values.price,
      differentFirstPrice: !!(values.differentFirstPrice),
      isKlarityDisplay: !!values.isKlarityDisplay,
      patientFacingTitle: values.patientFacingTitle,
      firstPaymentDuration: values.firstPaymentDuration,
      providerEarning: values.providerEarning || 0,
      klarityProgramPackageExternalInfo: externalInfo,
      klarityProgramPackageTagItemList: values.klarityProgramPackageTagItemList ? 
        values.klarityProgramPackageTagItemList.filter((item: any) => !!item.tagId) .map((item: any) => ({...item, packageId: detail?.id, programId})) : 
        [],
      firstPaymentPrice: values.firstPaymentPrice || values.price,
      ...includedItemsData,
    }

    try {
      const res = await saveNUpdatePackage(saveData);

      if (res && !res.error) {
        onSaveSuccess();
      } else {
        message.error(res.error)
      }
    } catch(e) {
      console.error(e)
    }

    setSaveLoading?.(false);
  }, [detail, klarityProgramPackageExternalInfo, setSaveLoading, programId, includedItemsData, cannotCalculateTotalPrice]);

  // useEffect(() => {
  //   const totalPrice = price + providerEarning + (differentFirstPriceIsChecked ? firstPaymentPrice : 0);

  //   form.setFieldsValue({ totalPrice });
  // }, [differentFirstPriceIsChecked])

  const onValuesChange = useCallback((changedValues, allValues) => {
    const { price = 0, firstPaymentPrice, differentFirstPrice, billingFrequencyType, billingPeriod } = allValues;

    // mothly 最低只能是2
    const billingPeriodValue = allValues.billingFrequencyType === frequency.ONE_TIME ? 1 : allValues.billingPeriod;

    
    let checkedDifferentPrice = price;

    if (allValues.billingFrequencyType !== frequency.ONE_TIME && differentFirstPrice) {
      checkedDifferentPrice = firstPaymentPrice || price;
    }

    const totalPrice = (billingPeriodValue - 1) * price + checkedDifferentPrice

    form.setFieldsValue({
      totalPrice: isNaN(totalPrice) ? 0 : totalPrice,
      // 防止在主动修改firstPaymentPrice时，由于这个function里的逻辑，被动将firstPaymentPrice计算错误
      firstPaymentPrice: changedValues && Object.keys(changedValues)[0] !== 'firstPaymentPrice' ? checkedDifferentPrice : firstPaymentPrice,
      billingPeriod: billingPeriodValue,
      differentFirstPrice: differentFirstPrice && billingFrequencyType !== frequency.ONE_TIME,
    });
  }, []);

  const handleFormSubmitFailed = useCallback((e) => {
    const {errorFields} = e;
    const firstErrorField = errorFields[0];
    const tabKey = requireFieldsTabIndex.current[firstErrorField.name[0]];

    if (tabKey) {
      setTabActiveKey(tabKey);
    }
  }, []);

  const handleTabClick = useCallback((key: string) => {
    setTabActiveKey(key);
  }, []);

  return (
      <div className={s.wrap}>
        <Spin spinning={loading}>
          <div className={s.header}>{detail?.packageTitle || 'New Package'}</div>
          <Form
            name="packageDetailForm"
            className={commonS.formStyle1}
            form={form}
            layout="vertical"
            onFinish={handleSubmitForm}
            onFinishFailed={handleFormSubmitFailed}
            onValuesChange={onValuesChange}
          >
              <Tabs items={items} onTabClick={handleTabClick} activeKey={tabActiveKey} destroyInactiveTabPane={false}></Tabs>
          </Form>
        </Spin>
      </div>
  );
};

export default PackageDetail;
