import React, { useMemo, useRef } from "react";
import s from "./s.module.less";
import { Button, Checkbox, Col, Form, Input, InputNumber, Row, Select, Switch } from "antd";
import CheckGroupColumn from "components/CheckGroupColumn";
import { patientGroup } from "constants/program";
import ProgramStore from "store/Programs";

const Patients = () => {
    const [getProgramsStore] = ProgramStore.useStore();
    const tagsList = getProgramsStore('tags');

    const tagsOptions = useMemo(() => {
        return tagsList?.map((tag) => ({
            label: tag.tagName,
            value: tag.id
        }))
    },[tagsList]);

    return (
        // <Form>
        <div className={s.wrap}>
            <div className={s.block}>
                <div className={s.title}>Patients</div>
                <div className={s.formItem}>
                    <Form.Item
                        label="Select patient group"
                        name="patientGroup"
                        // rules={[{ required: true, message: 'Billing Frequency is required' }]}
                    >
                        <CheckGroupColumn showCheckAll options={patientGroup}/>
                    </Form.Item>
                </div>
                <div className={s.formItem}>
                    <div className={s.label}>Patients tag</div>
                    <div className={s.formListBox}>
                        <div className={s.listInner}>
                            <Form.List name="klarityProgramPackageTagItemList" initialValue={[{}]}>
                            {
                                (fields, {add, remove}) => {
                                    return (
                                        <>
                                            {
                                                fields.map(({ key, name, ...restField }, index) => (
                                                    <div key={index} className={s.advBlock}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, "id"]}
                                                            className={s.hidden}
                                                            // rules={[{ required: true, message: 'Title is required' }]}
                                                        >
                                                            <Input type="hidden"/>
                                                        </Form.Item>
                                                        {/* <Form.Item
                                                            {...restField}
                                                            name={[name, "tagId"]}
                                                            className={s.hidden}
                                                            // rules={[{ required: true, message: 'Title is required' }]}
                                                        >
                                                            <Input type="hidden"/>
                                                        </Form.Item> */}
                                                        <Form.Item
                                                            {...restField}
                                                            label="Tag name"
                                                            // name={[name, "tagName"]}
                                                            name={[name, "tagId"]}
                                                            // rules={[{ required: true, message: 'Title is required' }]}
                                                        >
                                                            {/* <Input showCount maxLength={100}/> */}
                                                            <Select options={tagsOptions}/>
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, "isTagAtBooking"]}
                                                            valuePropName="checked"
                                                        >
                                                            <Checkbox>Tag patients at booking</Checkbox>
                                                        </Form.Item>
                                                        <div className={s.formFooter}>
                                                            <Button onClick={() => remove(index)}>Remove</Button>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            <div className={s.add} onClick={() => add()}>Add {fields.length === 0 ? "a" : "another"} tag</div>
                                        </>
                                    )
                                }
                            }
                            </Form.List>
                        </div>
                    </div>
                </div>
                <div className={s.formItem}>
                    <div className={s.label}>Patients FAQ</div>
                    <div className={s.formListBox}>
                        <div className={s.listInner}>
                            <Form.List name="faq" initialValue={[{}]}>
                            {
                                (fields, {add, remove}) => {
                                    return (
                                        <>
                                            {
                                                fields.map(({ key, name, ...restField }, index) => (
                                                    <div key={index} className={s.advBlock}>
                                                        <Form.Item
                                                            // style={{ width: '120px' }}
                                                            label="Question"
                                                            name={[name, "q"]}
                                                            // rules={[{ required: true, message: 'Title is required' }]}
                                                        >
                                                            <Input showCount maxLength={200}/>
                                                            {/* <Select /> */}
                                                        </Form.Item>
                                                        <Form.Item
                                                            // style={{ width: '120px' }}
                                                            label="Answer"
                                                            name={[name, "a"]}
                                                            // rules={[{ required: true, message: 'Program title is required' }]}
                                                        >
                                                            <Input.TextArea showCount maxLength={1000} />
                                                        </Form.Item>
                                                        <div className={s.formFooter}>
                                                            <Button onClick={() => remove(index)}>Remove</Button>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            <div className={s.add} onClick={() => add()}>Add {fields.length === 0 ? "a" : "another"} question</div>
                                        </>
                                    )
                                }
                            }
                            </Form.List>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        // </Form>
    )
}

export default Patients;
