/* eslint-disable @typescript-eslint/no-use-before-define */
import { UniprofileFormData } from 'types/provider';
import { CheckLogicEnmu, ValueTypeEnum, checkedFields } from 'pages/UniprofileResume/constants';
import get from 'lodash/get';
import { CHANNEL_APPOINTMENT_STEP_SESSIONSTORAGE_KEY, SHOW_KLARITY_ADVERTISING_ICON } from 'constants/common';

type FieldItem = {
    text?: string;
    type?: ValueTypeEnum | CheckLogicEnmu;
    fieldName: string;
    fields: FieldItem[];
    anchorId?: string;
};

const checkIsRelationalType = (type: CheckLogicEnmu) => {
    return Object.values(CheckLogicEnmu).includes(type);
};

const getFieldValue = (data: Array<string> | Record<string, any>, fieldName:string, replaceKey: string | number = '0') => {
    return get(data, fieldName.replace(/\$_\$/g, replaceKey.toString()));
};

const getFinished = (data: Array<string> | Record<string, any>, fields: FieldItem[], index?: number) => {
    return fields.filter((field: FieldItem) => {
        const { fieldName } = field;
        if (typeof fieldName === 'string') {
            const value = getFieldValue(data, fieldName, index);
            return !(typeof value !== 'number' && !value);
        }
    });
};

// 获取Record类型
const getUnFinished = (data: Array<string> | Record<string, any>, fields: FieldItem[], index?: number) => {
    return fields.filter((field: FieldItem) => {
        const { fieldName } = field;
        if (typeof fieldName === 'string') {
            return !getFieldValue(data, fieldName, index);
        }
    });
};

const loopBasicTypeCheck = (checkFiled: FieldItem, data) => {
    const { type, fieldName, fields } = checkFiled;

    if (type === ValueTypeEnum.ARRAY) {
        const fieldData = getFieldValue(data, fieldName);
        if (fieldData && fields && fields.length > 0) {
            return fields.find((field: FieldItem) => {
                return loopCheck(field, data);
            });
        } else {
            return getFieldValue(data, fieldName)?.length > 0;
        }
    } else {
        const value = getFieldValue(data, fieldName);

        return !(typeof value !== 'number' && !value);
    }
};

const loopCheck = (checkFiled: FieldItem, data) => {
    const { type, fields } = checkFiled;

    if (type && checkIsRelationalType(type)) {
        if (type === CheckLogicEnmu.OR) {
            const r = fields.some((field) => {
                return loopCheck(field, data);
            });

            return r;
        } else if (type === CheckLogicEnmu.AND) {
            const r = fields.every((field: FieldItem) => {
                return loopCheck(field, data);
            });

            return r;
        }
    } else {
        return loopBasicTypeCheck(checkFiled, data);
    }
};

// 数组中获取无值的
const findUnFinished = (data: Record<string, any>, fields: FieldItem[], index?: number) => {
    return fields.find((field: FieldItem) => {
        const { fieldName } = field;
        if (typeof fieldName === 'string') {
            const value = getFieldValue(data, fieldName, index);

            return typeof value !== 'number' && !value;
        } else {
            // const { type, fields: dataFields = [] } = field;
            return !loopCheck(field, data);
        }
    });
};

// 不存在于checkedFields内的, 代表可为空的步骤，那么返回1，表示完成
export const getCheckFieldsResult = (uniprofileData: UniprofileFormData, fieldName: keyof UniprofileFormData) => {
    const checkfield = checkedFields[fieldName];

    if (checkfield) {
        const { mustBeEmptyWhenInit, isArray, fields, isRequired } = checkfield;
        const dataset = uniprofileData[fieldName];

        let total = fields.length;
        let finished = 0;
        let unfinished: (string | number)[] = [];
        if (mustBeEmptyWhenInit) {
            return {
                percent: 1,
                unfinished: [],
            };
        } else if (isArray) {
            if (dataset instanceof Array && dataset.length > 0) {
                total = fields.length * dataset.length;

                // finished = dataset.reduce((r, c, index: number) => {
                //     const count = getFinished(dataset, fields, index).length;

                //     return r + count;
                // }, 0);

                dataset.forEach((item, index: number) => {
                    const emptyField = findUnFinished(item, fields);

                    if (emptyField) {
                        unfinished.push(index);
                    }
                });

                finished = total - unfinished.length;
            } else if (!isRequired) {
                return {
                    percent: 1,
                    unfinished: [],
                };
            } else {
                return {
                    percent: 0,
                    unfinished: fields,
                };
            }
        } else {
            unfinished = getUnFinished(dataset, fields);
            finished = getFinished(dataset, fields).length;
        }

        if (total === 0) {
            return {
                percent: 0,
                unfinished: fields,
            };
        }

        return {
            percent: finished / total,
            unfinished,
        };
    } else {
        return {
            percent: 1,
            unfinished: [],
        };
    }
    // if (uniprofileData && fieldName) {
    //     const dataset = uniprofileData[fieldName];

    //     let percent = 0;
    //     let total = checkList.length;
    //     let finished = 0;

    //     if (dataset) {
    //         if (dataset instanceof Array && dataset.length > 0) {
    //             const data = dataset[0];

    //             total = Object.keys(data).length * dataset.length;
    //             finished = dataset.reduce((result:number, currentData) => {
    //                 const finishedFieldslength = Object.values(currentData).filter(((v) => {
    //                     // 目前只考虑一层数组，比如值是一个数组，该数组里又是一些object
    //                     if (v instanceof Array) {
    //                         return v.length > 0;
    //                     } else {
    //                         return !!v;
    //                     }
    //                 })).length;

    //                 return result + finishedFieldslength;
    //             }, 0);
    //         } else {
    //             total = Object.keys(dataset).length;
    //             finished = Object.values(dataset).filter(((v) => {
    //                 if (v instanceof Array) {
    //                     return v.length > 0;
    //                 } else {
    //                     return !!v;
    //                 }
    //             })).length;
    //         }

    //         percent = Math.round((finished / total) * 100);
    //     }

    //     return percent;
    // }
};

export const clearUniprofileStepStorage = () => {
    sessionStorage.removeItem(CHANNEL_APPOINTMENT_STEP_SESSIONSTORAGE_KEY);

    // 清理klarity plan 右下角的Icon
    localStorage.removeItem(SHOW_KLARITY_ADVERTISING_ICON);
};
