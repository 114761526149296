import React from 'react';
import s from './s.module.less';
import { ServerMeta } from 'types/common';

 type TInternalLink = {
     id?: number,
     link?: string,
     type?: string,
     linkTag?: string,
     linkName?: string,
     linkUrl?: string,
     targetItem?: ServerMeta,
 };

type Prop = {
    specialtyData?: TInternalLink[],
    servicesData?: TInternalLink[],
    stateData?: TInternalLink[],
    condition?: ServerMeta,
    specialty?: ServerMeta,
    isMobileMode?: boolean,
};

export default function InternalLink(props: Prop) {
    const { isMobileMode, specialtyData = [], servicesData = [], stateData = [], condition, specialty } = props;

    const hasSpe = specialtyData.length > 0;
    const hasService = servicesData.length > 0;
    const hasState = stateData.length > 0;
    return (
        <div className={isMobileMode ? `${s.previewPc} ${s.previewMobile}` : s.previewPc}>
            <div className={s.wrapIn}>
                <h2>Find a doctor or a treatment </h2>

                <div className={s.itemWrap}>
                    {
                        hasSpe &&
                        <div className={s.item}>
                            <div className={s.itemTitle}>
                                {specialty?.content} doctors
                            </div>
                            <div className={s.content}>
                                {
                                    specialtyData?.slice(0, 10).map((item, index) => {
                                        return (
                                            <div className={s.link} key={item.id}>
                                                Doctors for  {item.targetItem?.content} treatment
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    }
                    {
                        hasService &&
                        <div className={s.item}>
                            <div className={s.itemTitle}>Online healthcare services</div>
                            <div className={s.content}>
                                {
                                    servicesData?.slice(0, 10).map((item, index) => {
                                        return (
                                            <div
                                                onClick={() => {
                                                }}
                                                className={s.link}
                                                key={item.id}
                                            >
                                                Online {item.targetItem?.content}
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    }

                    {
                        hasState &&
                        <div className={s.item}>
                            <div className={s.itemTitle}>
                                Find {condition?.content} doctors in other states
                            </div>
                            <div className={s.content}>
                                {
                                    stateData?.slice(0, 10).map((item, index) => {
                                        return (
                                            <div className={s.link} key={item.id}>
                                                {condition?.content} doctors in {item.targetItem?.content}
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
