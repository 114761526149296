import React, { useEffect, useMemo, useRef } from 'react';
import s from './s.module.less';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import ProviderDisplayManagement from './components/ProviderDisplayManagement';
import PageManagement from './components/PageManagement';
import CarouselManagement from './components/CarouselManagement';
import TeamManagement from './components/TeamManagement';
import { getElementWithTargetRole, getUrlParam, insertUrlParam } from 'utils/common';
import AccessDeniedTabView from 'components/AccessDeniedTabView';
import AccessStore from 'store/Access/permission';
import { PermissionModuleKeys } from 'constants/access';

export enum EType {
    PROVIDER_DISPLAY_MANAGEMENT = 'providerDisplayManagement',
    PAGE_MANAGEMENT = 'pageManagement',
    CAROUSEL_MANAGEMENT = 'carouselManagement',
    TEAM_MANAGEMENT = 'teamManagement',
}

const Page = () => {
    const [getAccessStore] = AccessStore.useStore();
    const access = getAccessStore('data');

    const ProviderDisplayManagementComponent = useMemo(() => {
        if (!access) {
            return null;
        }

        return access?.['ACCOUNT APPLICATION'] || true ? <ProviderDisplayManagement /> : <AccessDeniedTabView />;
    }, [access]);

    const PageManagementComponent = useMemo(() => {
        if (!access) {
            return null;
        }

        return access?.['UNIPROFILE MANAGEMENT'] || true ? <PageManagement /> : <AccessDeniedTabView />;
    }, [access]);

    const CarouselManagementComponent = useMemo(() => {
        if (!access) {
            return null;
        }

        return access?.['ACCOUNT APPLICATION'] || true ? (
            <CarouselManagement />
        ) : (
            <AccessDeniedTabView />
        );
    }, [access]);

    const TeamManagementComponent = useMemo(() => {
        if (!access) {
            return null;
        }

        return access?.['ACCOUNT APPLICATION'] || true ? (
            <TeamManagement />
        ) : (
            <AccessDeniedTabView />
        );
    }, [access]);

    const items = [
        {
            key: EType.PROVIDER_DISPLAY_MANAGEMENT,
            label: <p className={s.tabTitle}>Provider display management</p>,
            children: getElementWithTargetRole({
                access,
                element: ProviderDisplayManagementComponent!,
                targetRoles: [PermissionModuleKeys.KIWI_HEALTH_PROVIDER],
            }),
            forceRender: true,
        },
        {
            key: EType.PAGE_MANAGEMENT,
            label: <p className={s.tabTitle}>Page management</p>,
            children: getElementWithTargetRole({
                access,
                element: PageManagementComponent!,
                targetRoles: [PermissionModuleKeys.KIWI_HEALTH_PAGE],
            }),
            forceRender: true,
        },
        {
            key: EType.CAROUSEL_MANAGEMENT,
            label: <p className={s.tabTitle}>Carousel management</p>,
            children: getElementWithTargetRole({
                access,
                element: CarouselManagementComponent!,
                targetRoles: [PermissionModuleKeys.KIWI_HEALTH_PAGE],
            }),
            forceRender: true,
        },
        {
            key: EType.TEAM_MANAGEMENT,
            label: <p className={s.tabTitle}>Team management</p>,
            children: getElementWithTargetRole({
                access,
                element: TeamManagementComponent!,
                targetRoles: [PermissionModuleKeys.KIWI_HEALTH_PAGE],
            }),
            forceRender: true,
        },
    ];
    const activeType = getUrlParam('type') as EType;
    const [type, setType] = React.useState(activeType || EType.PROVIDER_DISPLAY_MANAGEMENT);

    useEffect(() => {
        if (activeType) {
            setType(activeType);
        }
    }, [activeType]);

    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <h1>Kiwi Health.com management</h1>
            </div>
            <div className={s.tableWrap}>
                <Tabs
                    activeKey={type}
                    items={items}
                    onChange={(key) => {
                        setType(key as EType);
                        insertUrlParam('type', key);
                    }}
                />
            </div>

        </div>
    );
};

export default Page;
