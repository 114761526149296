import { CouponType } from './provider';

export type TFunStatus = {
    name: string;
    des: string;
    support: boolean;
    supportText?: string | string[];
    sign?: string;
};

export enum TSubscriptionType {
    FREE = 'Free',
    ESSENTIAL = 'Essential',
    GROWTH = 'Growth',
    GROUP_PRACTICE = 'Group Practice',
    KLARITY = 'Klarity',
    KLARITY_STARTER = 'Starter',
    KLARITY_PLUS = 'Plus',
    KLARITY_PRO = 'Pro',
}

export enum TSubscriptionSubType {
    MONTHLY_BILLING = 'Monthly billing',
    QUARTERLY_BILLING = 'Quarterly billing',
    YEARLY_BILLING = 'Yearly billing',
    SEMI_ANNUAL_BILLING = 'Semi-annual billing',
}

export type TSubscriptionItem = {
    name: string;
    highlight?: string;
    des: string; //get from backend
    priceTips?: string;
    monthlyPrice: number,
    quarterlyPrice: number,
    semiAnnualPrice?: number,
    yearlyPrice: number,
    monthlyPlanId?: number,
    quarterlyPlanId?: number,
    semiAnnualPlanId?: number,
    yearlyPlanId?: number,

    monthlyProductCode?: string,
    quarterlyProductCode?: string,
    semiAnnualProductCode?: string,
    yearlyProductCode?: string,

    contactOnly?: boolean;
    type: TSubscriptionType; //map with backend
    subType?: TSubscriptionSubType; //map with backend, fix on FE?
    isCurrent: boolean; //logic with backend
    funList: TFunStatus[];
    freeChannelLimit?: number | null;
    paidChannelLimit?: number | null;
    premiumChannelLimit?: number | null;
    featureList?: [];

    originalMonthlyPrice?: number; //klarity
    priceDesc?: string;//klarity
};

export type TSubscriptionItemFromServer = {
    id: number;
    type: TSubscriptionType;
    subType: TSubscriptionSubType;
    bizType: "klarity-plan" | "kiwi-plan";
    productCode?: string;
    description: string;
    price: number;
    priceId: string;
    createTime: string;
    createBy: string;
    updateTime: string;
    updateBy: string;
    freeChannelLimit: number | null;
    paidChannelLimit: number | null;
    premiumChannelLimit: number | null;
    status: string | null;
    originStatus?: EStripeStatus | null;
    payFailDate?: number;
    couponType?: CouponType;
};

export interface ISubscriptopnTalkSumbit {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    practiceName: string;
    number: number;
    specialties: string;
}

export enum EChannelPayType {
    PAID = 'paid',
    PREMIUM = 'premium',
    FREE = 'free',
}

export enum EStripeStatus {
    ACTIVE = 'active',
    TRIALING = 'trialing',
    PAST_DUE = 'past_due',
    UNPAID = 'unpaid',
    CANCELED = 'canceled',
    INCOMPLETE = 'incomplete',
    INCOMPLETE_EXPIRED = 'incomplete_expired',
    PAUSED = 'paused',
}

export type TOpsSubscriptionItem = {
    id: 2,
    type: string,
    subType: string,
    description: string,
    price: number,
    priceId: string,
    freeChannelLimit?: number,
    paidChannelLimit?: number,
    premiumChannelLimit?: number,
    surveyLimit: number,
    reviewLimit: number,
    contractId: number,
    createTime: string, //'2023-09-04T18:09:39.000+00:00',
    createBy?: null | string,
    updateTime?: null | string,
    updateBy?: null | string
};
