import { OPS_ADMIN, SUPER_ADMIN } from 'constants/access';

export const isSuperAdmin = (roles: string[] = []) => (roles || []).includes(SUPER_ADMIN);

export const isOpsAdmin = (roles: string[] = []) => (roles || []).includes(OPS_ADMIN);

export const transferRoleStringToNunber = (role: string) => {
    if (role === SUPER_ADMIN) {
        return 0;
    }

    if (role === OPS_ADMIN) {
        return 1;
    }

    return 2;
};
// 0 super 1 ops 2其他
export const checkUserRole = (roles: string[] = []) => {
    if (isSuperAdmin(roles)) {
        return 0;
    }

    if (isOpsAdmin(roles)) {
        return 1;
    }

    return 2;
};
