import React from 'react';
import s from './s.module.less';
import type { CheckboxOptionType } from 'antd/es/checkbox';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import { Button, Input, Space, Checkbox, message, Tag } from 'antd';
import CommonTable from 'components/CommonTable';
import { useNavigate } from 'react-router-dom';
import CreateAndEditPage from './components/CreateAndEditPage';
import { TPage } from 'types/kiwiHealth';
import useKiwiPage from 'hooks/useKiwiPage';
import { EStatus, EStatusAction } from 'types/common';
import ExternalLinkIcon from 'assets/common/externalLinkIcon.svg';
import useCalendarColors from 'hooks/useCalendarColors';
import { Color } from 'types/calendar';

const CheckboxGroup = Checkbox.Group;

const allValue = 'All';

const Page = () => {
    const [item, setItem] = React.useState<TPage>();
    const [itemList, loading, refresh] = useKiwiPage(true);
    const [isEdit, setIsEdit] = React.useState(false);
    const [openModal, setOpensModal] = React.useState(false);
    const [colors, colorLoading] = useCalendarColors({ useAdminApi: true });

    const [page, setPage] = React.useState(1);
    const navigate = useNavigate();
    const [pendingNameFilter, setPendingNameFilter] = React.useState('');
    const [nameFilter, setNameFilter] = React.useState('');

    const allStatusValues = [allValue, EStatus.Active, EStatus.Archived];
    const [statusFilterValues, setStatusFilterValues] = React.useState(allStatusValues);

    let list:TPage[] = itemList || [];

    if (nameFilter || !statusFilterValues.includes(allValue)) {
        list = list.filter((oj:TPage) => {
            const nameFit = !nameFilter || oj.name?.toLowerCase().includes(nameFilter.toLowerCase());
            const statusFit = statusFilterValues.length === 0 || statusFilterValues.includes(allValue) || (oj.status && statusFilterValues.includes(oj.status));
            return nameFit && statusFit;
        });
    }

    React.useEffect(() => {
        setPage(1);
    }, [nameFilter, statusFilterValues]);

    const addNew = () => {
        setIsEdit(false);
        setOpensModal(true);
        setItem(undefined);
    };

    const onEdit = (record: TPage) => {
        setItem(record);
        setIsEdit(true);
        setOpensModal(true);
    };

    const onSuccess = () => {
        setOpensModal(false);
        refresh();
        message.success(isEdit ? 'Update success' : 'Add success');
    };

    const columns: ColumnsType<TPage> = [
        {
            title: 'NAME',
            dataIndex: 'name',
            filterSearch: true,
            width: '30%',
            filterIcon: <SearchOutlined />,
            filterDropdown: () => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        value={pendingNameFilter}
                        onChange={(e) => {
                            setPendingNameFilter(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && pendingNameFilter) {
                                setNameFilter(pendingNameFilter);
                            }
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                setNameFilter(pendingNameFilter);
                            }}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => {
                                setPendingNameFilter('');
                                setNameFilter('');
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            render: (name:string, p:TPage) => {
                return (
                    <div
                        onClick={() => {
                            window.open(`https://www.kiwihealth.com${p.pagePath}`, '_blank');
                        }}
                        className={s.name}
                    >{p.name}<img src={ExternalLinkIcon} />
                    </div>
                );
            },
        },
        {
            title: 'TAG',
            dataIndex: 'name',
            filterSearch: true,
            width: '30%',
            filterIcon: <SearchOutlined />,
            filterDropdown: () => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        value={pendingNameFilter}
                        onChange={(e) => {
                            setPendingNameFilter(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && pendingNameFilter) {
                                setNameFilter(pendingNameFilter);
                            }
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                setNameFilter(pendingNameFilter);
                            }}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => {
                                setPendingNameFilter('');
                                setNameFilter('');
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            render: (name:string, p:TPage) => {
                const tagColor = colors?.find((c:Color) => c.dictKey === p.tagColor);
                let colorStr = '#006957';
                if (tagColor) {
                    colorStr = tagColor.content;
                }
                return (
                    <div className={s.tagWrap}>
                        <span
                            style={{ borderColor: colorStr, color: colorStr }}
                            className={s.tag}
                        >
                            <span
                                style={{ backgroundColor: colorStr }}
                                className={s.tagBg}
                            />
                            <span className={s.tagContent}>{p.name}</span>
                        </span>
                    </div>
                );
            },
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            width: '20%',
            filterDropdown: () => {
                const options:CheckboxOptionType[] = [
                    {
                        label: 'All',
                        value: allValue,
                    },
                    {
                        label: EStatus.Active,
                        value: EStatus.Active,
                    },
                    {
                        label: EStatus.Archived,
                        value: EStatus.Archived,
                    },
                ];
                const onFilterChange = (value:CheckboxValueType[]) => {
                    if (value.includes(allValue) && !statusFilterValues.includes(allValue)) {
                        setStatusFilterValues(allStatusValues);
                    } else if (statusFilterValues.includes(allValue) && !value.includes(allValue)) {
                        //uncheck all
                        setStatusFilterValues([]);
                    } else if (value.length === allStatusValues.length - 1 && !value.includes(allValue)) {
                        //uncheck all
                        setStatusFilterValues(allStatusValues);
                    } else if (value.length < allStatusValues.length && value.includes(allValue)) {
                        //uncheck all
                        setStatusFilterValues((value as string[]).filter((val) => val !== allValue));
                    } else {
                        setStatusFilterValues(value as string[]);
                    }
                };
                return (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <CheckboxGroup
                            className={s.statusFilter}
                            options={options}
                            value={statusFilterValues}
                            onChange={onFilterChange}
                        />
                    </div>
                );
            },
            render: (status) => {
                return (
                    <div>
                        {status}
                    </div>
                );
            },
        },
        {
            title: '',
            width: '20%',
            render: (p:TPage) => {
                return (
                    <div>
                        {/* <Button
                            style={{ minWidth: 120 }}
                            className={s.link}
                            onClick={() => {

                            }}
                            type="text"
                        >
                            {p.status === EStatus.Active ? EStatusAction.Archive : EStatusAction.Activate}
                        </Button> */}
                        <Button
                            className={s.link}
                            onClick={() => {
                                onEdit(p);
                            }}
                            type="text"
                        >
                            Edit
                        </Button>
                    </div>
                );
            },
        },
    ];
    return (
        <div className={s.wrap}>
            <h2>
                <span>Page management</span>
                <Button
                    onClick={addNew}
                    icon={<PlusOutlined />}
                >Add new page
                </Button>
            </h2>
            <div className={s.tableWrap}>
                <CommonTable
                    bordered
                    rowKey="id"
                    loading={loading}
                    columns={columns}
                    data={list || []}
                />
            </div>
            {
                openModal &&
                <CreateAndEditPage
                    isOps
                    item={item}
                    onCancel={() => setOpensModal(false)}
                    onSuccess={onSuccess}
                />
            }
        </div>
    );
};

export default Page;
