import React, { useCallback } from 'react';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import { Checkbox, Form, Input, Modal, Spin } from 'antd';
import ReviewItem from 'pages/dashboard/KlarityReviewsDashboradPage/components/WaitForReply/components/ReviewItem';
import { updateReviewtToPrivate } from 'api/operation/reviews';
import { IReviewItem } from 'types/operation';

interface IProps {
    open?: boolean;
    title?: string;
    reviewItem?: IReviewItem;
    onCancel?: () => void;
    onOk?: () => void;
}

const ReviewPrivateSettingPopup = ({
    open,
    title = 'Set the review to private',
    reviewItem,
    onCancel,
    onOk,
}: IProps) => {
    const [formInstance] = Form.useForm();
    const [loading, setLoading] = React.useState(false);
    const [ensure, setEnsure] = React.useState(false);
    const [showEnsureWarning, setShowEnsureWarning] = React.useState(false);

    const handleCancel = useCallback(() => {
        formInstance.resetFields();
        onCancel?.();
    }, [onCancel]);

    const handleEnsureChange = useCallback((e) => {
        setEnsure(e.target.checked);
        if (e.target.checked) {
            setShowEnsureWarning(false);
        }
    }, []);

    const handleSubmit = useCallback(() => {
        if (reviewItem) {
            formInstance.validateFields().then(async (values) => {
                if (!ensure) {
                    setShowEnsureWarning(true);
                } else {
                    setLoading(true);
                    try {
                        const params = {
                            reviewId: reviewItem?.id,
                            ...values,
                        };
                        const res = await updateReviewtToPrivate(params);

                        if (res && !res.error) {
                            onOk?.();
                        }
                    } catch (e) {
                        console.error(e);
                    }
                    setLoading(false);
                }
            }).catch((e) => {
                console.error(e);
            });
        }
    }, [ensure, formInstance, reviewItem]);

    return (
        <Modal
            width={780}
            open={open}
            title={title}
            className={commonS.modalFixHeightWrap}
            okText="Submit"
            confirmLoading={loading}
            onOk={handleSubmit}
            onCancel={handleCancel}
            destroyOnClose
        >
            <Spin spinning={loading}>
                <div className={s.wrap}>
                    <div className={s.reviewItem}>
                        <ReviewItem
                            review={reviewItem}
                            hideRightPart
                            hideBorder
                        />
                    </div>
                    <div className={s.form}>
                        <Form
                            className={commonS.formStyle1}
                            form={formInstance}
                            id="settingPrivateForm"
                            layout="vertical"
                        >
                            <Form.Item
                                label="Reason"
                                name="reason"
                                rules={[{ required: true, message: 'Details is required' }]}
                            >
                                <Input.TextArea style={{ height: '206px' }} maxLength={600} showCount />
                            </Form.Item>
                        </Form>
                        <div className={s.checkbox}>
                            <Checkbox onChange={handleEnsureChange}><span className={`${showEnsureWarning ? s.warning : ''}`}>By confirming this decision, I am finalizing the outcome of this dispute. This action cannot be undone.</span></Checkbox>
                        </div>
                    </div>
                </div>
            </Spin>
        </Modal>
    );
};

export default ReviewPrivateSettingPopup;
