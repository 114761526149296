import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import { Divider } from 'antd';
import { ExportOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Star from 'components/Star';
import useProviderFullInfo from 'hooks/useProviderFullInfo';
import { UniProfile, Provider } from 'types/provider';
import s from './s.module.less';
import PreviewWrapper from 'components/PreviewWrapper';

export interface FormInterface {
    email: string;
}

const Page = () => {
    const { id } = useParams();
    const uid = id ? parseInt(id, 10) : 0;
    const [params] = useSearchParams();
    const channelId = params.getAll('channelId')[0];
    const cid = channelId ? parseInt(channelId, 10) : 0;
    const [providerFullInfo] = useProviderFullInfo(uid);
    const uniProfile: UniProfile | undefined = providerFullInfo?.uniProfile;
    const basicInfo: Provider | undefined = providerFullInfo?.basicInfo;
    const mapRef = React.useRef({});

    const [pos, setPos] = React.useState({
        lat: 37.5334766,
        lng: -77.6879696,
    });
    const [mapInited, setMapInted] = React.useState(false);

    //@ts-ignore
    const handleApiLoaded = ({ map, maps }) => {
        mapRef.current = { map, maps };
        setMapInted(true);
    };

    React.useEffect(() => {
        if (!mapInited || !uniProfile || !basicInfo) {
            return;
        }

        const getPos = async () => {
            const address = `${uniProfile?.address}, ${uniProfile?.city}, ${uniProfile?.state}, ${uniProfile?.zip}`;
            //@ts-ignore
            const { maps } = mapRef.current;
            const geocoder = new maps.Geocoder();
            const geoResult = await geocoder.geocode({ address });
            if (geoResult?.results?.length) {
                const lat = geoResult?.results?.[0]?.geometry?.location?.lat();
                const lng = geoResult?.results?.[0]?.geometry?.location?.lng();
                setPos({ lat, lng });
            }
        };
        getPos();
    }, [mapInited, basicInfo, uniProfile]);

    const name = uniProfile?.providerPractice?.practiceName ? `From ${uniProfile?.providerPractice.practiceName} - ${uniProfile?.firstName} ${uniProfile?.lastName}` : `${uniProfile?.firstName} ${uniProfile?.lastName}`;

    return (
        <PreviewWrapper channelId={cid} logo="" name="Google">
            <div className={s.box}>
                <div className={s.description}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M3 0C3.55228 0 4 0.447715 4 1V2H5C5.55228 2 6 2.44772 6 3C6 3.55228 5.55228 4 5 4H4V5C4 5.55228 3.55228 6 3 6C2.44772 6 2 5.55228 2 5V4H1C0.447715 4 0 3.55228 0 3C0 2.44772 0.447715 2 1 2H2V1C2 0.447715 2.44772 0 3 0ZM3 10C3.55228 10 4 10.4477 4 11V12H5C5.55228 12 6 12.4477 6 13C6 13.5523 5.55228 14 5 14H4V15C4 15.5523 3.55228 16 3 16C2.44772 16 2 15.5523 2 15V14H1C0.447715 14 0 13.5523 0 13C0 12.4477 0.447715 12 1 12H2V11C2 10.4477 2.44772 10 3 10Z" fill="#FBBF24" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.99995 0C10.4537 0 10.8505 0.305483 10.9667 0.744107L12.1459 5.19893L15.4997 7.13381C15.8092 7.31241 15.9999 7.64262 15.9999 8C15.9999 8.35738 15.8092 8.6876 15.4997 8.86619L12.1459 10.8011L10.9667 15.2559C10.8505 15.6945 10.4537 16 9.99995 16C9.54622 16 9.14935 15.6945 9.03324 15.2559L7.85402 10.8011L4.50027 8.86618C4.19072 8.68759 4 8.35738 4 8C4 7.64262 4.19072 7.31241 4.50027 7.13382L7.85402 5.19893L9.03324 0.744107C9.14935 0.305483 9.54622 0 9.99995 0Z" fill="#FBBF24" />
                    </svg>
                    We create this preview based on your UniProfile.
                </div>
                <div className={s.content}>
                    <div className={s.line1}>
                        <img
                            width="300px"
                            height="300px"
                            src={uniProfile?.photoList?.[0]}
                            alt="Kiwi logo"
                        />
                        <div className={s.map}>
                            <div id="map-contact" style={{ width: '100%', height: '100%' }}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: 'AIzaSyCxm7e0xAhbiozO-PJ5CDs5xzD_UEdVuuc' }}
                                    center={pos}
                                    options={
                                        {
                                            gestureHandling: 'none',
                                            streetViewControl: true,
                                            mapTypeControl: false,
                                        }
                                    }
                                    defaultZoom={18}
                                    onGoogleApiLoaded={handleApiLoaded}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={s.from}>{name}</div>
                    <div className={s.links}>
                        <span>Website</span>
                        <span>Save</span>
                        <span>Call</span>
                    </div>
                    <div className={s.review}>
                        <span className={s.num}>5.0</span>
                        <Star width={80} gap={0.5} value={5} />
                        <span className={s.text}>1 Google review</span>
                    </div>
                    <Divider style={{ margin: '0 0 40px 0' }} />
                    <div className={s.openTime}>Hours: <span>Open</span> Closes 5 p.m</div>
                    <div className={s.phone}>Phone: <span>{`+${uniProfile?.tel?.slice(0, 4)}-${uniProfile?.tel?.slice(4, 7)}-${uniProfile?.tel?.slice(7)}`}</span></div>
                    <div className={s.suggest}>Suggest an edit</div>
                    <div className={s.questions}>
                        <div className={s.left}>
                            <div className={s.title}>Questions and answers</div>
                            <div className={s.link}>Be the first </div>
                        </div>
                        <div className={s.btn}>Ask a Question</div>
                    </div>
                    <div className={s.send}>
                        <div className={s.sendText}>
                            <ExportOutlined style={{ marginRight: '8px', width: '12px' }} />
                            Send to your phone
                        </div>
                        <div className={s.sendBtn}>Send</div>
                    </div>
                    <div className={s.reviewWrapper}>
                        <div className={s.reviewText}>
                            <div className={s.reviewTitle}>Reviews<ExclamationCircleOutlined style={{ marginLeft: '5px', width: '10px' }} /></div>
                            <div className={s.reviewNum}>1 Google review</div>
                        </div>
                        <div className={s.reviewBtns}>
                            <span>Write a review</span>
                            <span>Add a photo</span>
                        </div>
                    </div>
                    <div className={s.infoWrapper}>
                        <div className={s.infoTitle}>{uniProfile?.providerPractice?.practiceName ? `From ${uniProfile?.providerPractice.practiceName} - ` : ''}{uniProfile?.firstName} {uniProfile?.lastName}</div>
                        <div className={s.infoContent}>
                            Hi there! I&#39;m so glad you&#39;ve decided to schedule an online visit with me. I&#39;m here to help you with any mental struggles you may be facing. During our visit, we&#39;ll have a friendly chat where I&#39;ll ask you questions about your medical history and symptoms... <span>More</span>
                        </div>
                    </div>
                </div>
            </div>
        </PreviewWrapper>
    );
};

export default Page;
