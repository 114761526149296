import TinyStore from 'lib/tinyStore';
import { EnumFields, SettingDataType, SettingEnum } from 'types/enumerationData';
import { AllState } from 'constants/common';

const initialData: Record<EnumFields, Array<SettingEnum>> = {
    [EnumFields.LIST_CHANNEL_STATUS]: [],
    [EnumFields.DEGREE]: [],
    [EnumFields.COMPANY_TYPE]: [],
    [EnumFields.EMPLOYMENT_TYPE]: [],
    [EnumFields.TREATMENT_APPROACH]: [],
    [EnumFields.DURATION]: [],
    [EnumFields.CHANNEL]: [],
    [EnumFields.ORIENTATION]: [],
    [EnumFields.CHANNEL_AUDIT_STATUS]: [],
    [EnumFields.INSURANCE]: [],
    [EnumFields.COLOR]: [],
    [EnumFields.PROFILE_STATUS]: [],
    [EnumFields.PROFILE_AUDIT_STATUS]: [],
    [EnumFields.PROFILE_COMPLETE]: [],
    [EnumFields.CREDENTIAL_TYPE]: [],
    [EnumFields.GENDER]: [],
    [EnumFields.ETHNICITY]: [],
    [EnumFields.RELIGION]: [],
    [EnumFields.CHANNEL_PAY_PLAN_TYPE]: [],
    [EnumFields.BILLING_CYCLE]: [],
    [EnumFields.WITHDRAW_STATUS]: [],
    [EnumFields.WITHDRAW_REASON]: [],
    [EnumFields.CHANNEL_TAG]: [],
    [EnumFields.CHANNEL_FOCUS]: [],
    [EnumFields.CHANNEL_SORT_TYPE]: [],
    [EnumFields.STATE]: [
        ...AllState,
    ],
    [EnumFields.PATIENT_SURVEY_SEND_STATUS]: [],
    [EnumFields.PROVIDER_MAPPING_LIST_STATUS]: [],
    [EnumFields.PROVIDER_MAPPING_STATUS]: [],
    [EnumFields.PROVIDER_PATIENT_RATING]: [],
    [EnumFields.HELLO_KLARITY_CONDITION]: [],
    [EnumFields.HELLO_KLARITY_SPECIALTY]: [],
    [EnumFields.HELLO_KLARITY_SERVICE_PAGE]: [],
    [EnumFields.LICEN]: [],
    [EnumFields.HEATH]: [],
    [EnumFields.SCHED]: [],
    [EnumFields.STLIC]: [],
    [EnumFields.SIFAP]: [],
    [EnumFields.SIPMH]: [],
    [EnumFields.TIMEZONE]: [],
    [EnumFields.LIST_CARE]: [],
    [EnumFields.ADDRESS_PUBLIC_TYPE]: [],
    [EnumFields.PAYMENT_STATUS]: [],
};

type StoreType = {
    data: SettingDataType
};

const Store = TinyStore.createStore<StoreType>({
    nameSpace: 'EnumData',
    key: 'channelStatus',
    crossBundle: false,
    runTime: true,
    initValue: { data: initialData },
});

export default Store;
