// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.common-tab__content--R5s6v .ant-tabs-nav {
  margin-bottom: 24px!important;
}
.common-tab__content--R5s6v .ant-tabs-nav-list {
  width: 100%;
}
.common-tab__content--R5s6v .ant-tabs-tab {
  flex: 1;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/styles/common.tab.less"],"names":[],"mappings":"AAAA;EAGY,6BAAA;AADZ;AAFA;EAMY,WAAA;AADZ;AALA;EAUY,OAAA;EACA,uBAAA;AAFZ","sourcesContent":[".content {\n    :global {\n        .ant-tabs-nav {\n            margin-bottom: 24px!important;\n        }\n        .ant-tabs-nav-list {\n            width: 100%;\n        }\n\n        .ant-tabs-tab {\n            flex: 1;\n            justify-content: center;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `common-tab__content--R5s6v`
};
export default ___CSS_LOADER_EXPORT___;
