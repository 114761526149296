import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Spin, Tabs, Button, message } from 'antd';
import { Desktop } from 'assets/preview/Desktop';
import { Mobile } from 'assets/preview/Mobile';
import { useNavigate } from 'react-router-dom';
import IntroManagement, { IntroComponentRef } from '../components/Intro';
import QuestionManagement, {
    IQuestionManagement,
} from '../components/Questions';
import Result from '../components/Result';
import Email from '../components/Email';
import PreviewIntro from '../components/PreviewIntro';
import PreviewQuestion from '../components/PreviewQuestion';
import PreviewResult from '../components/PreviewResult';
import PreviewEmail from '../components/PreviewEmail';

import { PATH } from 'constants/path';
import s from './s.module.less';
import commonTabS from 'styles/common.tab.less';
import {
    getAssessmentList,
    addOrUpdateAssessment,
} from 'api/operation/assessment';
import dayjs from 'dayjs';
import { getHelloKlarityHost, getUrlParam } from 'utils/common';

import type {
    TAssessmentItem,
    TEngagementQuestion,
    TPreviewQuestion,
    TResult,
    TPreviewResult,
} from 'types/operation';

const Detail: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isMobileMode, setIsMobileMode] = useState(false);
    const [tabActiveKey, setTabActiveKey] = useState('1');
    const [previewItem, setPreviewItem] = useState<TAssessmentItem | undefined>(
        undefined,
    );

    const navigate = useNavigate();
    const id = getUrlParam('id');
    const uuid = getUrlParam('uuid');
    const [info, setInfo] = useState<TAssessmentItem>({} as TAssessmentItem);

    const [updateTime, setUpdateTime] = useState<string>('');
    const [goLiveObj, setGoLiveObj] = useState<TAssessmentItem>(
        {} as TAssessmentItem,
    );

    const host = getHelloKlarityHost();
    const introRef = useRef<IntroComponentRef>(null);
    const questionRef = useRef<IQuestionManagement>(null);
    const resultRef = useRef<IResult>(null);
    const [previewQuestion, setPreviewQuestion] = useState<TPreviewQuestion>(
        {} as TPreviewQuestion,
    );
    const [previewResultItem, setPreviewResultItem] = useState<TPreviewResult>({} as TPreviewResult);

    const [emailSelectedPreviewInfo, setEmailSelectedPreviewInfo] = useState();

    const handlePreview = (item: TAssessmentItem | undefined) => {
        setPreviewItem(item);
    };

    const getInfo = async () => {
        setIsLoading(true);
        try {
            const { data } = await getAssessmentList({
                assessmentName: '',
                tags: [''],
                id,
                orderKey: 'UPDATE_AT',
                orderType: 'DESC',
                size: 1,
                current: 1,
            });
            if (data?.data?.records?.length > 0) {
                const result = data.data.records[0];
                setInfo(result);
                const lastUpdateTime = `${dayjs(result.updateAt).format(
                    'MMMM D, YYYY',
                )} at ${dayjs(result.updateAt).format('h:mm A')}`;
                setUpdateTime(lastUpdateTime);
                console.log('result: ', result);
                setGoLiveObj(result);
            }
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    const handleTabClick = useCallback((key: string) => {
        setTabActiveKey(key);
    }, []);

    const handleExit = useCallback(() => {
        navigate(`${PATH.OPERATION}/${PATH.KLARITY_ASSESSMENT}`, {
            replace: true,
        });
    }, [navigate]);

    const handleGoLive = async () => {
        if (tabActiveKey === '1') {
            console.log(introRef.current?.validateAndSubmit());
        }
    };

    const handleAction = async (obj: Partial<TAssessmentItem>) => {
        setGoLiveObj({
            ...goLiveObj,
            ...obj,
        });
        const { data } = await addOrUpdateAssessment({
            ...goLiveObj,
            ...obj,
            uuid,
            id,
            fromType: 'google-form',
        });
        if (data.code === 200) {
            message.success('Information updated successfully');
        }
    };

    const handlePreviewQuestion = (item: TPreviewQuestion) => {
        setPreviewQuestion(item);
    };

    const handlePreviewResult = (item: TPreviewResult) => {
        setPreviewResultItem(item);
    };

    useEffect(() => {
        getInfo();
    }, []);

    const homeTabItems = [
        {
            key: '1',
            label: <span className={s.tabLabel}>Intro</span>,
            children: (
                <IntroManagement
                    isLoading={isLoading}
                    goLiveObj={goLiveObj}
                    onSuccess={(item) => handleAction(item)}
                    ref={introRef}
                    // onPreview={(item) => handlePreview(item)}
                />
            ),
        },
        {
            key: '2',
            label: <span className={s.tabLabel}>Questions</span>,
            children: (
                <QuestionManagement
                    isLoading={isLoading}
                    goLiveObj={goLiveObj}
                    onSuccess={() => getInfo()}
                    onPreview={(item: TPreviewQuestion) => handlePreviewQuestion(item)}
                    ref={questionRef}
                />
            ),
        },
        {
            key: '3',
            label: <span className={s.tabLabel}>Results</span>,
            children: (
                <Result
                    isLoading={isLoading}
                    goLiveObj={goLiveObj}
                    onSuccess={() => getInfo()}
                    onPreview={(item: TResult) => handlePreviewResult(item)}
                    ref={resultRef}
                />
            ),
        },
        {
            key: '4',
            label: <span className={s.tabLabel}>Email</span>,
            children: (
                <Email
                    isLoading={isLoading}
                    goLiveObj={goLiveObj}
                    info={info}
                    onSuccess={() => getInfo()}
                    setEmailSelectedPreviewInfo={setEmailSelectedPreviewInfo}
                />
            ),
        },
    ];

    const renderHeader = (
        <div className={s.titleWrap}>
            <h2 className={s.title}>{info.assessmentName}</h2>
            <h2 className={s.time}>Date last modified: {updateTime}</h2>
        </div>
    );

    const renderPreview = (
        <div className={s.preview}>
            <div className={s.modeWrap}>
                <span
                    onClick={() => {
                        setIsMobileMode(false);
                    }}
                    className={isMobileMode ? '' : s.active}
                >
                    <Desktop />
                </span>
                <span
                    onClick={() => {
                        setIsMobileMode(true);
                    }}
                    className={isMobileMode ? s.active : ''}
                >
                    <Mobile />
                </span>
            </div>
            <div className={s.previewContent}>
                {tabActiveKey === '1' && (
                    <PreviewIntro isMobileMode={isMobileMode} previewItem={goLiveObj} />
                )}
                {tabActiveKey === '2' && (
                    <PreviewQuestion
                        isMobileMode={isMobileMode}
                        previewItem={previewQuestion}
                    />
                )}
                {tabActiveKey === '3' && (
                    <PreviewResult
                        isMobileMode={isMobileMode}
                        previewItem={previewResultItem}
                    />
                )}
                {tabActiveKey === '4' && (
                    <PreviewEmail
                        previewData={emailSelectedPreviewInfo}
                        isMobileMode={isMobileMode}
                    />
                // <FaqPreview
                //     isMobileMode={isMobileMode}
                //     previewList={previewFAQ}
                //     state={targetState}
                //     condition={targetCondition}
                // />
                )}
            </div>
        </div>
    );

    const renderFooter = (
        <div className={s.footer}>
            <Button type="primary" ghost onClick={handleExit}>
                Exit
            </Button>
            {/* <Button type="primary" onClick={handleGoLive}>
                Go live
            </Button> */}
        </div>
    );

    return (
        <div className={s.wrap}>
            <Spin spinning={isLoading}>
                <div className={s.content}>
                    <div className={s.setting}>
                        {renderHeader}
                        <div className={commonTabS.content}>
                            <Tabs
                                defaultActiveKey="1"
                                activeKey={tabActiveKey}
                                items={homeTabItems as any}
                                onTabClick={handleTabClick}
                            />
                        </div>
                    </div>
                    {renderPreview}
                </div>
                {renderFooter}
            </Spin>
        </div>
    );
};

export default Detail;
