import TinyStore from 'lib/tinyStore';

type EditStatusModal = {
    data: boolean;
};

const Store = TinyStore.createStore<EditStatusModal>({
    nameSpace: 'klarity',
    key: 'practiceFrontFormEditTip',
    crossBundle: false,
    runTime: true,
    initValue: { data: false },
});

export default Store;
