import React, { useCallback, useMemo } from "react";
import s from './s.module.less';
import { Button, message, Spin } from "antd";
import {PlusOutlined} from '@ant-design/icons';
import CommonTable from 'components/CommonTable';
import type { ColumnsType } from 'antd/es/table';
import { useNavigate } from "react-router-dom";
import { PATH } from "constants/path";
import { IKlarityProgram, IKlarityProgramPackage } from "types/programs";
import useGetProgramsList from "hooks/ProgramsOps/useGetProgramsList";
import { activeProgramStatus } from "api/operation/programs";

const KlaritySelectPrograms = () => {
    const navigate = useNavigate();
    const [programsList, fetchLoading, refetchProgramsList] = useGetProgramsList();
    const [statusLoading, setStatusLoading] = React.useState<boolean>(false);

    const handleAddNewProgram = useCallback(() => {
        navigate(`${PATH.OPERATION}/${PATH.KLARITY_SELECT_PROGRAMS_DETAIL}/-1`);
    }, [])

    const handleEditProgram = useCallback((programId: number) => {
        navigate(`${PATH.OPERATION}/${PATH.KLARITY_SELECT_PROGRAMS_DETAIL}/${programId}`);
    }, [])

    const handleChangeProgramStatus = useCallback(async (programId:number) => {
        if (!programId || statusLoading) return;

        setStatusLoading(true);
        try {
            const res = await activeProgramStatus(Number(programId));

            if (res && !res.error) {
                refetchProgramsList();
            } else {
                message.error(res.error);
            }
        } catch (e) {
            console.error(e);
        }
        setStatusLoading(false);
    }, [statusLoading]);

    const columns = useMemo<ColumnsType<IKlarityProgram>>(() => (
        [
            {
                title: 'PROGRAM NAME',
                dataIndex: 'programName',
                width: '260px',
                fixed: 'left',
                className: s.tableColumn,
            },
            {
                title: 'SPECIALTY',
                dataIndex: 'specialtyName',
                width: '260px',
                className: s.tableColumn,
            },
            {
                title: 'INCLUDED PACKAGE',
                dataIndex: 'klarityProgramPackageList',
                width: '300px',
                className: s.tableColumn,
                render: (item: IKlarityProgramPackage[]) => {
                    return (
                        <div className={s.tableUl}>
                        <ul>
                            {
                                item?.map((packageItem:IKlarityProgramPackage) => {
                                    return (
                                        <li key={packageItem.id}>{packageItem.packageTitle}</li>
                                    )
                                })
                            }
                        </ul>
                        </div>
                    );
                },
            },
            {
                title: 'DESCRIPTION',
                dataIndex: 'description',
                className: s.tableColumn,
                width: '300px',
                render: (text:string) => {
                    return (
                        <div className={s.nameWrap} title={text}>
                            {text}
                        </div>
                    );
                },
            },
            {
                title: '',
                width: '120px',
                className: s.tableColumn,
                fixed: 'right',
                render: (item: IKlarityProgram) => {
                    const { programStatus } = item;
                    return programStatus === "A" ? (
                        <div
                            className={s.edit}
                            onClick={() => handleChangeProgramStatus(item.id)}
                        >Deactivate
                        </div>
                    ) : (
                        <div
                            className={s.edit}
                            onClick={() => handleChangeProgramStatus(item.id)}
                        >Activate
                        </div>
                    );
                },
            },
            {
                title: '',
                width: '80px',
                className: s.tableColumn,
                fixed: 'right',
                render: (item: IKlarityProgram) => {
                    return (
                        <div
                            className={s.edit}
                            onClick={() => handleEditProgram(item.id)}
                        >Edit
                        </div>
                    );
                },
            },
        ]
    ), []);

    return (
        <div className={s.wrap}>
            <Spin spinning={fetchLoading}>
                <div className={s.toolbar}>
                    <div className={s.title}></div>
                    <Button icon={<PlusOutlined/>} onClick={handleAddNewProgram}>Add a new Klarity select program</Button>
                </div>
                <CommonTable
                    rowKey={(record) => record.id}
                    bordered
                    loading={fetchLoading}
                    scroll={{ x: '1500' }}
                    columns={columns}
                    data={programsList || []}
                    pagination={false}
                />
            </Spin>
        </div>
    )
}

export default KlaritySelectPrograms;
