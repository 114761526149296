import React, { useState, useMemo } from 'react';
import { Modal, Form, Input, Button, Tooltip } from 'antd';
import commonS from 'styles/common.module.less';
import s from './s.module.less';
import { type TObjectAction, type TServiceSetting } from 'types/helloKlarity';
import KlarityServiceSelect from 'components/form/KlarityServiceSelect';
import FileUpload from 'components/form/FileUpload';
import { klarityServiceUpdate } from 'api/operation/pageManagement';

type Props = {
    currentIndex: number;
    item?: TServiceSetting;
    list: TServiceSetting[];
    onCancel?: () => void;
    onSuccess?: (obj: TObjectAction) => void;
};

const EditService = ({
    currentIndex,
    item,
    list,
    onCancel,
    onSuccess,
}: Props) => {
    const [submiting, setSubmiting] = useState(false);
    const [form] = Form.useForm<TServiceSetting & { active: boolean }>();
    const isEdit = true;
    const disableServiceList = useMemo(() => {
        return list.filter((listItem) => listItem.servicePage !== item?.servicePage).map((conditionItem) => {
            return conditionItem.servicePage;
        });
    }, [list, item]);

    const onSave = async () => {
        setSubmiting(true);
        try {
            const formValues = await form.validateFields();
            const id = item?.id;

            const params: TServiceSetting = {
                servicePage: formValues.servicePage,
                subtext: formValues.subtext,
                image: formValues.image,
                pageId: item?.pageId ?? 1,
                id: item?.id,
                priority: item?.priority ?? 1,
                type: 'HOME',
            };
            if (isEdit) {
                params.id = id;
            }
            const { data } = await klarityServiceUpdate(params);
            if (data.code === 200) {
                onSuccess?.({
                    type: 'SERVICE_EDIT',
                    service: params,
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            setSubmiting(false);
        }
    };

    React.useEffect(() => {
        if (item) {
            form.setFieldsValue({
                servicePage: item.servicePage,
                subtext: item.subtext,
                image: item.image,
            });
        } else {
            form.resetFields();
        }
    }, [item]);

    return (
        <Modal
            title={`Edit Service ${currentIndex + 1}`}
            closable
            className={commonS.modalFixHeightWrap}
            width="500px"
            destroyOnClose
            onCancel={onCancel}
            okText="Save"
            open
            footer={
                <div>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button loading={submiting} type="primary" onClick={onSave}>
                        Save changes
                    </Button>
                </div>
            }
        >
            <div className={s.wrap}>
                <Form
                    form={form}
                    className={commonS.formStyle1}
                    initialValues={{ active: true }}
                    autoComplete="off"
                    layout="vertical"
                    scrollToFirstError
                >
                    <>
                        <Form.Item
                            className={s.rowItem}
                            label="Service page"
                            name="servicePage"
                            rules={[
                                { required: true, message: 'Service page is required' },
                            ]}
                        >
                            <KlarityServiceSelect
                                isLabel
                                style={{ width: '100%' }}
                                disableValues={disableServiceList}
                            />
                        </Form.Item>
                        <Form.Item
                            className={s.rowItem}
                            label="Subtext"
                            name="subtext"
                            rules={[
                                { required: true, message: 'Description  is required' },
                            ]}
                        >
                            <Input maxLength={100} style={{ width: '100%' }} showCount />
                        </Form.Item>
                        <Form.Item
                            className={s.rowItem}
                            label={
                                <div className={s.formLabel}>
                                    Image
                                    <Tooltip
                                        overlayClassName={s.tipCard}
                                        overlayInnerStyle={{ padding: '12px' }}
                                        title={
                                            <div className={s.tipText}>
                                                Image should have a transparent background and be
                                                sized 278x128 pixels.
                                            </div>
                                        }
                                    >
                                        <div className={s.questionMarkIcon} />
                                    </Tooltip>
                                </div>
                            }
                            name={['image']}
                            rules={[{ required: true, message: 'Image is required' }]}
                        >
                            <FileUpload />
                        </Form.Item>
                    </>
                </Form>
            </div>
        </Modal>
    );
};

export default EditService;
