import React from 'react';
import s from './s.module.less';
import type { CheckboxOptionType } from 'antd/es/checkbox';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { SearchOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import { Button, Input, Space, Checkbox, message, Select } from 'antd';
import CommonTable from 'components/CommonTable';
import { useNavigate } from 'react-router-dom';
import CreateAndEditProviderPage from './components/CreateAndEditProviderPage';
import { ProviderPageInfo, TPage, TProviderPageDetail } from 'types/kiwiHealth';
import { EStatus } from 'types/common';
import { useRequest } from 'ahooks';
import { Color } from 'types/calendar';
import { getProviderPageInfoList } from 'api/operation';
import useCalendarColors from 'hooks/useCalendarColors';
import { NOT_SET } from 'constants/common';
import useKiwiPage from 'hooks/useKiwiPage';

const CheckboxGroup = Checkbox.Group;

const allValue = 'All';

const Page = () => {
    const [item, setItem] = React.useState<ProviderPageInfo>();
    const [isEdit, setIsEdit] = React.useState(false);
    const [openModal, setOpensModal] = React.useState(false);
    const [pageList, pageLoading] = useKiwiPage(true);
    const [colors, colorLoading] = useCalendarColors({ useAdminApi: true });

    const defaultPrams = {
        current: 1,
        size: 10000,
    };
    const { data, loading, run } = useRequest(getProviderPageInfoList, {
        defaultParams: [
            defaultPrams,
        ],
    });

    const itemList:ProviderPageInfo[] = data?.data?.data?.records || [];

    const [page, setPage] = React.useState(1);
    const navigate = useNavigate();
    const [pendingNameFilter, setPendingNameFilter] = React.useState('');
    const [nameFilter, setNameFilter] = React.useState('');

    const [pageFilterValues, setPageFilterValues] = React.useState<number[]>([]);

    let list:ProviderPageInfo[] = itemList || [];

    if (nameFilter || pageFilterValues.length > 0) {
        list = list.filter((oj:ProviderPageInfo) => {
            const nameFit = !nameFilter || oj.firstName?.toLowerCase().includes(nameFilter.toLowerCase()) || oj.lastName?.toLowerCase().includes(nameFilter.toLowerCase()) || oj.email?.toLowerCase().includes(nameFilter.toLowerCase());
            let pageFit = pageFilterValues.length === 0;
            if (!pageFit) {
                const pageInfoList = oj.pageDetailRspList || [];
                if (!pageInfoList || pageInfoList.length === 0) {
                    pageFit = false;
                } else {
                    pageInfoList.forEach((pd:TProviderPageDetail) => {
                        if (pageFilterValues.includes(pd.pageId)) {
                            pageFit = true;
                        }
                    });
                }
            }
            return nameFit && pageFit;
        });
    }

    React.useEffect(() => {
        setPage(1);
    }, [nameFilter, pageFilterValues]);

    const onEdit = (record: ProviderPageInfo) => {
        setItem(record);
        setIsEdit(true);
        setOpensModal(true);
    };

    const onSuccess = () => {
        run(defaultPrams);
        setOpensModal(false);
        message.success(isEdit ? 'Update success' : 'Add success');
    };

    const columns: ColumnsType<ProviderPageInfo> = [
        {
            title: 'NAME',
            dataIndex: 'name',
            filterSearch: true,
            width: '30%',
            filterIcon: <SearchOutlined />,
            filterDropdown: () => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        value={pendingNameFilter}
                        onChange={(e) => {
                            setPendingNameFilter(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && pendingNameFilter) {
                                setNameFilter(pendingNameFilter);
                            }
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                setNameFilter(pendingNameFilter);
                            }}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => {
                                setPendingNameFilter('');
                                setNameFilter('');
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            render: (name:string, pi:ProviderPageInfo) => {
                return (
                    <div className={s.nameWrap}>
                        <div className={s.nameRight}>
                            <div className={s.name}>{pi.firstName} {pi.lastName}</div>
                            <div className={s.email}>{pi.email}</div>
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'PAGES',
            dataIndex: 'pageDetailRspList',
            filterSearch: true,
            width: '20%',
            filterDropdown: () => {
                const options:CheckboxOptionType[] = [
                ];
                pageList?.forEach((p:TPage) => {
                    options.push({
                        label: p.name,
                        value: p.id!,
                    });
                });
                const onFilterChange = (value:number[]) => {
                    setPageFilterValues(value);
                };
                options.push({
                    label: '',
                    value: '',
                    disabled: true,
                });
                return (
                    <div style={{ padding: 16 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '500px' }}
                            placeholder="Please select"
                            onChange={onFilterChange}
                            options={options}
                        />
                    </div>
                );
            },
            render: (pageDetailRspList:TProviderPageDetail[]) => {
                if (!pageDetailRspList || pageDetailRspList.length === 0) {
                    return NOT_SET;
                }
                return (
                    <div className={s.tagWrap}>
                        {
                            pageDetailRspList.map((pd:TProviderPageDetail) => {
                                const tagColor = colors?.find((c:Color) => c.dictKey === pd.tagColor);
                                let colorStr = '#006957';
                                if (tagColor) {
                                    colorStr = tagColor.content;
                                }
                                return (
                                    <span
                                        style={{ borderColor: colorStr, color: colorStr }}
                                        className={s.tag}
                                    >
                                        <span
                                            style={{ backgroundColor: colorStr }}
                                            className={s.tagBg}
                                        />
                                        <span className={s.tagContent}>{pd.name}</span>
                                    </span>
                                );
                            })
                        }

                    </div>
                );
            },
        },
        {
            title: '',
            width: '20%',
            render: (p:ProviderPageInfo) => {
                const hasPage = p.pageDetailRspList && p.pageDetailRspList.length > 0;
                return (
                    <div>
                        <Button
                            className={s.link}
                            onClick={() => {
                                onEdit(p);
                            }}
                            type="text"
                        >
                            {hasPage ? 'Edit' : 'Assign pages'}
                        </Button>
                    </div>
                );
            },
        },
    ];
    return (
        <div className={s.wrap}>
            <div className={s.tableWrap}>
                <CommonTable
                    bordered
                    rowKey="id"
                    loading={loading}
                    columns={columns}
                    data={list || []}
                />
            </div>
            {
                openModal &&
                <CreateAndEditProviderPage
                    isOps
                    item={item}
                    onCancel={() => setOpensModal(false)}
                    onSuccess={onSuccess}
                />
            }
        </div>
    );
};

export default Page;
