import React from 'react';
import s from './s.module.less';
import { ServerMeta } from 'types/common';
import FrequencyQuestionSecion from 'components/FrequencyQuestionSecion';
import { TFaqInfo } from 'types/operation';

type IProps = {
    isMobileMode: boolean;
    previewList?: TFaqInfo[];
    state?:ServerMeta;
    condition?: ServerMeta;
};

const FaqPreview = (props: IProps) => {
    const { isMobileMode, previewList, state, condition } = props;

    return (
        <div className={isMobileMode ? s.previewMobile : s.previewPc}>
            <div className={s.wrapIn}>
                <h2>Frequently asked questions about {condition?.content} in {state?.content}</h2>
                <FrequencyQuestionSecion data={previewList || []} openAll />
            </div>
        </div>
    );
};

export default FaqPreview;
