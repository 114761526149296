import { Modal } from 'antd';
import React from 'react';
import s from './s.module.less';
import useGetSettingData from 'hooks/useGetSettingData';
import { EnumFields } from 'types/enumerationData';
import startCase from 'lodash/startCase';
import { urlSearchParamsReplacement } from 'utils/common';

interface IProps {
    show?: boolean;
    profileLinks?: string[];
    onOk: () => void;
    onCancel: () => void;
}

type TOption = {
    label: string;
    value: string;
};

const MulitProfileLinkModal = ({
    show,
    profileLinks = [],
    onOk,
    onCancel,
}: IProps) => {
    const [stateSettingOptions] = useGetSettingData(EnumFields.STATE, true) as (TOption[])[];
    return (
        <Modal
            title="Please select a profile link with a different state to view"
            open={show}
            onOk={onOk}
            onCancel={onCancel}
            cancelButtonProps={{ style: { display: 'none' } }}
        >
            <div className={s.wrap}>
                <div className={s.listBox}>
                    {
                        profileLinks?.map((link, i) => {
                            let state = 'General';
                            let newLink = link;
                            try {
                                const urlObj = new URL(link);

                                const { search } = urlObj;
                                const searchParams = new URLSearchParams(search);

                                const stateVal = searchParams.get('state') as string;

                                if (stateVal) {
                                    state = stateVal?.toLowerCase().replace('-', ' ');

                                    newLink = urlSearchParamsReplacement(link, 'state', state);
                                }
                            } catch (e) {
                                console.error(e);
                            }
                            return (
                                <div key={i} className={s.item}>
                                    <span className={s.label}>{startCase(state)}</span>
                                    <a title={newLink} className={s.link} href={newLink} target="_blank" rel="noreferrer">{newLink}</a>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </Modal>
    );
};

export default MulitProfileLinkModal;
