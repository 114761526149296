import React, { } from 'react';
import s from './s.module.less';
import TabContent from './components/TabContent';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { EKlarityPage } from 'types/helloKlarity';

const PageManagementPage = () => {
    const [key, setKey] = React.useState<string>('1');
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Condition page',
            children: <TabContent type={EKlarityPage.CONDITION} />,
        },
        {
            key: '2',
            label: 'State pages',
            children: <TabContent type={EKlarityPage.STATE} />,
        },
        {
            key: '3',
            label: 'Other pages',
            children: <TabContent type={EKlarityPage.HOME} />,
        },
    ];
    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <div className={s.title}>Hello Klarity.com page management</div>
            </div>
            <div className={s.content}>
                <Tabs
                    activeKey={key}
                    items={items}
                    onChange={(val) => {
                        setKey(val);
                    }}
                />
            </div>
        </div>
    );
};

export default PageManagementPage;
