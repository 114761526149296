import React, {
    useState,
    useEffect,
} from 'react';
import s from './s.module.less';
import { Button, message, Modal, Form, Input, Tooltip, Spin } from 'antd';
import type {
    TAssessmentItem,
    TEngagementQuestion,
    TPreviewQuestion,
    TQuestionItem,
} from 'types/operation';
import {
    getByAssessmentId,
    getCountByGoogleFormId,
    engagementTipsAddOrUpdate,
    getQuestionsByAssessmentUuid,
    engagementTipsAddOrUpdateBatch,
} from 'api/operation/assessment';
import cx from 'classnames';
import EngagementQuestionsItem from '../EngagementQuestionsItem';
import commonS from 'styles/common.module.less';
import FileUpload from 'components/form/FileUpload';
import { getUrlParam } from 'utils/common';

import type { DragEndEvent } from '@dnd-kit/core';
import {
    DndContext,
    PointerSensor,
    useSensor,
    useSensors,
    closestCenter,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    useSortable,
    rectSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const SortableItem = ({ id, title, isFixed, seq }: TQuestionItem) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        padding: '8px',
        margin: '4px 0',
        backgroundColor: '#f0f0f0',
        border: '1px solid #ccc',
        cursor: 'default',
    };

    const activeStyle = {
        transform: CSS.Transform.toString(transform),
        transition,
        padding: '8px',
        margin: '4px 0',
        backgroundColor: '#00816B',
        border: '1px solid #00816B',
        cursor: 'grab',
    };

    return (
        <div
            ref={setNodeRef}
            style={isFixed ? style : activeStyle}
            {...attributes}
            {...listeners}
            className={cx(s.moveItem, isFixed ? s.fixed : s.moved)}
        >
            <Tooltip
                title={title}
            >
                <div className={s.moveItemInner}>
                    {seq + 1}. {title}
                </div>
            </Tooltip>
        </div>
    );
};

type IProps = {
    item: TAssessmentItem;
    onSuccess: (obj: Partial<TAssessmentItem>) => void;
    onPreview: (item: Partial<TPreviewQuestion>) => void;
};

const EngagementQuestions: React.FC<IProps> = (props) => {
    const { item, onSuccess, onPreview } = props;
    const [list, setList] = useState<TEngagementQuestion[]>(
        [] as TEngagementQuestion[]);
    const [activePreviewIndex, setActivePreviewIndex] = useState<number>(0);
    const [questionCount, setQuestionCount] = useState<number>(0);
    const [engagementQuestionCount, setEngagementQuestionCount] =
      useState<number>(0);
    const [googleQuestionCount, setGoogleQuestionCount] = useState<number>(0);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [isShowSortModal, setIsShowSortModal] = useState<boolean>(false);
    const uuid = getUrlParam('uuid');
    const [questionList, setQuestionList] = useState<TQuestionItem[]>([]);

    const [submitting, setSubmitting] = useState(false);
    const [form] = Form.useForm<TEngagementQuestion & { active: boolean }>();
    const [isLoading, setIsLoading] = useState(false);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 5,
            },
        }),
    );

    const handleDragEnd = (event: any) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            const activeIndex = questionList.findIndex(
                (questionItem) => questionItem.id === active.id,
            );
            const overIndex = questionList.findIndex(
                (questionItem) => questionItem.id === over.id,
            );

            if (questionList[activeIndex].isFixed) {
                return;
            }

            setQuestionList((prevItems) =>
                arrayMove(prevItems, activeIndex, overIndex),
            );
        }
    };

    const handleAddQuestion = () => {
        form.setFieldsValue({
            imgUrl: '',
            title: '',
        });
        setIsShowModal(true);
    };

    const getList = async () => {
        try {
            setIsLoading(true);
            const { data } = await getByAssessmentId({
                assessmentId: item.id || '',
            });
            setList(data.data);
            setIsLoading(false);
            if (data?.data) {
                setEngagementQuestionCount(data?.data.length);
                onPreview({
                    ...data?.data?.[activePreviewIndex],
                    count: questionCount,
                    type: 'engagementQuestion',
                });
            }
        } catch (err) {
            setIsLoading(false);
        }
    };

    const handlePreviewItemClick = (index: number) => {
        setActivePreviewIndex(index);
    };

    const getGoogleCountList = async () => {
        const { data } = await getCountByGoogleFormId({
            questionsFormLinkId: item.questionsFormLinkId || '',
        });
        setGoogleQuestionCount(data.data);
    };

    const onCancel = () => {
        setIsShowModal(false);
    };

    const onSaveSortQuestion = async () => {
        const resultArr = questionList
            .map((questionItem, index) => {
                return {
                    assessmentId: item.id,
                    title: questionItem.title,
                    type: questionItem.type,
                    imgUrl: questionItem.imgUrl,
                    seq: index,
                    id: questionItem.id,
                };
            })
            .filter(
                (questionItem) => questionItem.type === 'EngagementQuestions',
            );

        const { data } = await engagementTipsAddOrUpdateBatch(resultArr);
        if (data.data) {
            message.success('updated successfully');
            setIsShowSortModal(false);
            getList();
        }
    };

    const handleSortQuestion = () => {
        setIsShowSortModal(true);
    };

    const onCancelSortModal = () => {
        setIsShowSortModal(false);
    };

    const getTotalQuestionsList = async () => {
        const { data } = await getQuestionsByAssessmentUuid({
            assessmentUuid: uuid || '',
        });
        setQuestionList(data?.data?.map((questionItem: TQuestionItem, index: number) => {
            return {
                ...questionItem,
                isFixed: questionItem.type !== 'EngagementQuestions',
                seq: index,
            };
        }));

        setQuestionCount(data?.data?.length);
    };

    const handleUpdateSuccess = () => {
        getList();
        getTotalQuestionsList();
        message.success('updated successfully');
    };

    const onSaveAddQuestion = async () => {
        const formValues = await form.validateFields();
        const params = {
            seq: questionCount,
            title: formValues.title,
            type: 'EngagementQuestions',
            imgUrl: formValues.imgUrl,
            assessmentId: item.id,
        };
        const { data } = await engagementTipsAddOrUpdate(params);
        if (!data.error) {
            setIsShowModal(false);
            handleUpdateSuccess();
        }
    };

    useEffect(() => {
        if (item.id) {
            getList();
            getGoogleCountList();
            getTotalQuestionsList();
        }
    }, [item]);

    useEffect(() => {
        onPreview({
            ...list[activePreviewIndex],
            count: questionCount,
            engagementQuestionCount,
            type: 'engagementQuestion',
        });
    }, [activePreviewIndex, questionCount, list, engagementQuestionCount]);

    return (
        <Spin spinning={isLoading}>
            <div className={s.wrap}>
                <div className={s.headerBar}>
                    <h4 className={s.subtitle}>Engagement questions</h4>
                    <div className={s.buttonWrap}>
                        {list.length > 0 && (
                            <Button onClick={() => handleSortQuestion()}>sort</Button>
                        )}
                        <Button
                            onClick={() => handleAddQuestion()}
                            type="primary"
                            className={s.ml8}
                        >
                            Add new questions
                        </Button>
                    </div>
                </div>
                <div className={s.previewList}>
                    {(list || []).map((listItem: TEngagementQuestion, index) => {
                        return (
                            <div
                                className={cx(
                                    s.previewItem,
                                    index === activePreviewIndex ? s.active : '',
                                )}
                                key={listItem.id}
                                onClick={() => handlePreviewItemClick(index)}
                            >
                                {listItem.title}
                            </div>
                        );
                    })}
                </div>
                <div className={s.contentWrap}>
                    {(list || []).map((listItem, index) => {
                        return (
                            <EngagementQuestionsItem
                                item={listItem}
                                index={index}
                                questionCount={questionCount}
                                assessmentId={item.id || ''}
                                onSuccess={() => handleUpdateSuccess()}
                            />
                        );
                    })}
                </div>
                <Modal
                    title="Add new questions"
                    open={isShowModal}
                    destroyOnClose
                    okText="Save"
                    onCancel={onCancel}
                    footer={
                        <div>
                            <Button onClick={onCancel}>Cancel</Button>
                            <Button
                                loading={submitting}
                                type="primary"
                                onClick={onSaveAddQuestion}
                            >
                                Save
                            </Button>
                        </div>
                    }
                >
                    <div className={s.wrap}>
                        <Form
                            form={form}
                            className={commonS.formStyle1}
                            initialValues={{ active: true }}
                            autoComplete="off"
                            layout="vertical"
                            scrollToFirstError
                        >
                            <>
                                <Form.Item
                                    className={s.rowItem}
                                    label="Text"
                                    name="title"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Text is required',
                                        },
                                    ]}
                                >
                                    <Input.TextArea maxLength={500} rows={5} showCount />
                                </Form.Item>
                                <Form.Item
                                    className={s.rowItem}
                                    name="imgUrl"
                                    label={
                                        <div className={s.formHeader}>
                                            <span>Image</span>
                                            <p className={s.descriptionTips}>
                                                Images taller than 100px will be automatically resized
                                                or cropped. Please keep the height within this limit
                                                for best results.
                                            </p>
                                        </div>
                                    }
                                >
                                    <FileUpload
                                        accept="image/png, image/jpeg, image/gif"
                                        tips="PNG, JPG, GIF up to 10MB"
                                    />
                                </Form.Item>
                            </>
                        </Form>
                    </div>
                </Modal>
                <Modal
                    title="Sort questions"
                    open={isShowSortModal}
                    destroyOnClose
                    okText="Save"
                    onCancel={onCancelSortModal}
                    footer={
                        <div>
                            <Button onClick={onCancelSortModal}>Cancel</Button>
                            <Button
                                loading={submitting}
                                type="primary"
                                onClick={onSaveSortQuestion}
                            >
                                Save
                            </Button>
                        </div>
                    }
                    width={1000}
                >
                    <div className={s.sortWrap}>
                        <DndContext
                            sensors={sensors}
                            collisionDetection={closestCenter}
                            onDragEnd={handleDragEnd}
                        >
                            <SortableContext
                                items={questionList?.map(
                                    (questionItem) => questionItem.id || '',
                                )}
                                strategy={rectSortingStrategy}
                            >
                                {questionList?.map((questionItem, index: number) => (
                                    <SortableItem
                                        seq={index}
                                        key={questionItem.id}
                                        id={questionItem.id}
                                        title={questionItem.title}
                                        isFixed={questionItem.isFixed}
                                    />
                                ))}
                            </SortableContext>
                        </DndContext>
                    </div>
                </Modal>
            </div>
        </Spin>
    );
};

export default EngagementQuestions;
