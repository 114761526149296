import { Rule } from 'rc-field-form/lib/interface';
import React from 'react';

import RegExps from 'constants/regexps';
import { CouponType } from 'types/provider';

/**
 * Compatible with antd v3.
 */
function create<T>(validatorFn: (value: T) => boolean, err: string): Rule {
    return {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        validator: async (_, value, callback) => {
            if (!validatorFn(value)) {
                throw new Error(err);
            }
        },
    };
}

function required(whitespace?: boolean, message?: string | React.ReactElement): Rule {
    return {
        message: message ?? 'This field is required',
        whitespace: whitespace ?? false,
        required: true,
    };
}

const requiredArray = (err: string = 'This field is required'): Rule => ({
    required: true,
    validator: (_, arr) => {
        return arr && arr.length > 0 ? Promise.resolve() : Promise.reject(err);
    },
});

const requiredNum = (message?: string | React.ReactElement): Rule => ({
    message: message ?? 'This field is required',
    required: true,
    type: 'number',
});

const validateName = (err: string) => create((name: string) => RegExps.name.test(name), err);

const validateIP = (err: string) => create((ip: string) => RegExps.ip.test(ip), err);

const validateNotEmpty = (err: string) => create((input: string) => !(!input || input.trim().length === 0), err);

const validateRelativeFilePath = (err: string) => create((path: string) => RegExps.relativeFilePath.test(path), err);

const validateLicenseNumber = (err: string) => create((number: string) => RegExps.licenseNumber.test(number), err);

const validateNpiNumber = (err: string) => create((number: string):boolean => {
    return !!(number && number.length === 10);
}, err);

const validateUSPhoneNumber = (err: string) => create((number: string):boolean => {
    const val = number?.replace(/\D/g, '');
    return val.length === 10;
}, err);

const validatePhoneNumber = (err: string) => create((number: string):boolean => {
    if (!number) {
        return true;
    }
    const regex = /^[0-9-]+$/;
    return regex.test(number);
}, err);

const validateEmail = (err: string) => create((path: string) => RegExps.email.test(path), err);

const validateSurveyWildcard = (err: string) => create((content: string) => {
    return !RegExps.surveyWildcard.test(content);
}, err);

const validatePhone = (err: string) => create((path: string) => RegExps.phone.test(path), err);

const validateHeadway = (err: string, couponType?: CouponType) => create((value: string) => {
    if (couponType !== CouponType.HEADWAY) {
        return true;
    }
    let url = value;
    if (value.indexOf('https:') === -1 && value.indexOf('http:') === -1) {
        url = `https://${url}`;
    }

    try {
        const urlObj = new URL(url);
        const { host } = urlObj;

        if (host.replace(/www\./g, '') === 'headway.co') {
            return true;
        }
    } catch (e) {
        console.error(e);
    }

    return false;
}, err);

const validateLink = (err: string) => create((link: string):boolean => {
    if (!link) {
        return true;
    }
    return /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)\??([^#\n\r]*)#?([^#\n\r]*)$/.test(link);
}, err);

const validateNonChinese = (err: string) => create((content: string): boolean => {
    return RegExps.nonChineseRegex.test(content);
}, err);

const FormValidationHelper = {
    create,

    required,
    requiredArray,
    requiredNum,
    validatePhoneNumber,
    validateIP,
    validateName,
    validateNotEmpty,
    validateRelativeFilePath,
    validateLicenseNumber,
    validateUSPhoneNumber,
    validateNpiNumber,
    validateEmail,
    validatePhone,
    validateLink,

    validateSurveyWildcard,
    validateHeadway,
    validateNonChinese,
};

export default FormValidationHelper;
