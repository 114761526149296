import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import { ArrowRightOutlined, CameraOutlined, CheckCircleOutlined, EditOutlined, UploadOutlined, StarOutlined, ExclamationCircleOutlined, TagOutlined, SearchOutlined } from '@ant-design/icons';
import { Input, Space, Divider, Button, Select } from 'antd';
import cx from 'classnames';
import ShareIcon from 'assets/channel/share.svg';
import CallIcon from 'assets/channel/call.svg';
import ChatIcon from 'assets/channel/chat.svg';
import EditIcon from 'assets/channel/edit.svg';
import YelpLogo from 'assets/channel/yelp.png';
import useProviderFullInfo from 'hooks/useProviderFullInfo';
import { UniProfile, Provider } from 'types/provider';
import s from './s.module.less';
import PreviewHeader from 'components/PreviewHeader';

const { Search } = Input;

export interface FormInterface {
    email: string;
}

const Page = () => {
    const { id } = useParams();
    const [params] = useSearchParams();
    const channelId = params.getAll('channelId')[0];
    const uid = id ? parseInt(id, 10) : 0;
    const cid = channelId ? parseInt(channelId, 10) : 0;
    const [providerFullInfo] = useProviderFullInfo(uid);
    const uniProfile: UniProfile | undefined = providerFullInfo?.uniProfile;
    const basicInfo: Provider | undefined = providerFullInfo?.basicInfo;
    const mapRef = React.useRef({});
    const [pos, setPos] = React.useState({
        lat: 37.5334766,
        lng: -77.6879696,
    });
    const [mapInited, setMapInted] = React.useState(false);
    //@ts-ignore

    React.useEffect(() => {
        if (!mapInited || !uniProfile || !basicInfo) {
            return;
        }

        const getPos = async () => {
            const address = `${uniProfile?.address}, ${uniProfile?.city}, ${uniProfile?.state}, ${uniProfile?.zip}`;
            //@ts-ignore
            const { maps } = mapRef.current;
            const geocoder = new maps.Geocoder();
            const geoResult = await geocoder.geocode({ address });
            if (geoResult?.results?.length) {
                const lat = geoResult?.results?.[0]?.geometry?.location?.lat();
                const lng = geoResult?.results?.[0]?.geometry?.location?.lng();
                setPos({ lat, lng });
            }
        };
        getPos();
    }, [mapInited, basicInfo, uniProfile]);

    const handleApiLoaded = ({ map, maps }) => {
        mapRef.current = { map, maps };
        setMapInted(true);
    };

    const name = `${uniProfile?.firstName} ${uniProfile?.lastName}, ${uniProfile?.providerPractice?.practiceName}`;

    return (
        <div className={s.wrap}>
            <PreviewHeader channelId={cid} logo={YelpLogo} name="Yelp" />
            <Divider style={{ margin: 0 }} />
            <div className={s.description}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M3 0C3.55228 0 4 0.447715 4 1V2H5C5.55228 2 6 2.44772 6 3C6 3.55228 5.55228 4 5 4H4V5C4 5.55228 3.55228 6 3 6C2.44772 6 2 5.55228 2 5V4H1C0.447715 4 0 3.55228 0 3C0 2.44772 0.447715 2 1 2H2V1C2 0.447715 2.44772 0 3 0ZM3 10C3.55228 10 4 10.4477 4 11V12H5C5.55228 12 6 12.4477 6 13C6 13.5523 5.55228 14 5 14H4V15C4 15.5523 3.55228 16 3 16C2.44772 16 2 15.5523 2 15V14H1C0.447715 14 0 13.5523 0 13C0 12.4477 0.447715 12 1 12H2V11C2 10.4477 2.44772 10 3 10Z" fill="#FBBF24" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M9.99995 0C10.4537 0 10.8505 0.305483 10.9667 0.744107L12.1459 5.19893L15.4997 7.13381C15.8092 7.31241 15.9999 7.64262 15.9999 8C15.9999 8.35738 15.8092 8.6876 15.4997 8.86619L12.1459 10.8011L10.9667 15.2559C10.8505 15.6945 10.4537 16 9.99995 16C9.54622 16 9.14935 15.6945 9.03324 15.2559L7.85402 10.8011L4.50027 8.86618C4.19072 8.68759 4 8.35738 4 8C4 7.64262 4.19072 7.31241 4.50027 7.13382L7.85402 5.19893L9.03324 0.744107C9.14935 0.305483 9.54622 0 9.99995 0Z" fill="#FBBF24" />
                </svg>
                We create this preview based on your UniProfile.
            </div>
            <div className={s.main}>
                <div className={s.searchHeader}>
                    <img src={YelpLogo} height={31} className={s.logo} />
                    <div className={s.left}>
                        <div className={s.searchSection}>
                            <div className={s.searchBar}>
                                <span className={s.name}>{name}</span>
                                <span className={s.splittor}>|</span>
                                <span className={s.address}>{`${uniProfile?.address}, ${uniProfile?.city}, ${uniProfile?.state}, ${uniProfile?.zip}`}</span>
                                <Button type="primary" danger icon={<SearchOutlined />} />
                            </div>
                        </div>
                        <div className={s.filterSection}>
                            <Select
                                defaultValue="Restaurants"
                                bordered={false}
                            />
                            <Select
                                defaultValue="Home Services"
                                bordered={false}
                            />
                            <Select
                                defaultValue="Auto Services"
                                bordered={false}
                            />
                            <Select
                                defaultValue="More"
                                bordered={false}
                            />
                        </div>
                    </div>

                </div>
                <Divider />
                <div className={s.content}>
                    <div className={s.profileSection}>
                        <div className={s.profileLeft}>
                            <div className={s.profileName}>{name}</div>
                            <div className={s.claimRow}>
                                <CheckCircleOutlined style={{ marginRight: 5 }} />
                                <span>claimed</span>
                                <span className={s.dot}>.</span>
                                <span className={s.claimDesc}>Family Nurse Practitioner & Psychiatric Nurse Practitioner</span>
                                <Button type="primary" disabled size="small">Edit</Button>
                            </div>
                            <div className={s.operationRow}>
                                <span className={s.open}>Open</span>
                                <span className={s.time}>9:00AM-5:00Pm</span>
                                <span className={s.desc}><ExclamationCircleOutlined /> Hours updated 2 weeks ago</span>
                                <Button type="primary" disabled size="small">See hours</Button>
                            </div>
                            <div className={s.buttonRow}>
                                <Button type="primary" icon={<StarOutlined />} danger>
                                    Write a review
                                </Button>
                                <Button icon={<CameraOutlined />}>Add photo</Button>
                                <Button icon={<UploadOutlined />}>Share</Button>
                                <Button icon={<TagOutlined />}>Save</Button>
                            </div>
                        </div>
                        <div className={s.profileRight}>
                            <div className={s.shareRow}><span>https://practicefront/max-rod...</span><img src={ShareIcon} /></div>
                            <Divider style={{ margin: '10px 0' }} />
                            <div className={s.callRow}><span>723-112-2211</span><img src={CallIcon} /></div>
                            <Divider style={{ margin: '10px 0' }} />
                            <div className={s.chatRow}><span>Message the Business</span><img src={ChatIcon} /></div>
                            <Button className={s.editBtn} icon={<EditOutlined />}>Suggest an edit</Button>
                        </div>
                    </div>
                    <Divider />
                    <div className={s.photoSection}>
                        <div className={s.photoSectionHeader}>
                            <div className={s.photoSectionName}>Photos & Video</div>
                            <div className={s.photoSectionMoreBtn}><span>See all 1 photos</span><ArrowRightOutlined style={{ marginLeft: '10px' }} /></div>
                        </div>
                        <div className={s.photoList}>
                            <img
                                width="155px"
                                height="155px"
                                className={s.photo}
                                src={uniProfile?.photoList?.[0]}
                            />
                            <div className={s.addMorePhoto}>
                                <CameraOutlined style={{ fontSize: '24px', marginBottom: 5 }} />
                                <span>Add photo</span>
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div className={s.servicesSection}>
                        <div className={s.servicesSectionHeader}>
                            <div className={s.servicesSectionName}>Services Offered</div>
                            <div className={s.servicesVerifyBy}><span>Verified by Business</span><CheckCircleOutlined style={{ fontSize: '14px', color: '#006F8A', marginLeft: 5 }} /></div>
                        </div>
                        <div className={s.services}>
                            <span>Anxiety</span>
                            <span>ADHD</span>
                            <span>Bipolar</span>
                            <span>Depression</span>
                            <span>Insomnia</span>
                            <span>Stress</span>
                            <span>Sleep problems</span>
                            <span>OCD</span>
                        </div>
                    </div>
                    <Divider />
                    <div className={s.aboutSection}>
                        <div className={s.aboutName}>
                            NearbyAbout the Business
                        </div>
                        <div className={s.aboutContent}>
                            Hi there! I'm so glad you've decided to schedule an online visit with me. I'm here to help you with any mental struggles you may be facing. During our visit, we'll have a friendly chat where I'll ask you questions about your medical history and symptoms, and you can ask me any questions you have...
                        </div>
                        <Button size="small">Read more</Button>
                    </div>
                    <Divider />
                    <div className={s.locationSection}>
                        <div className={s.locationSectionHeader}>
                            <div className={s.locationSectionName}>Location & Hours</div>
                            <div className={s.locationSectionEditBtn}><span>Suggest an edit</span><EditOutlined style={{ marginLeft: '10px' }} /></div>
                        </div>
                        <div className={s.locationContent}>
                            <div className={s.map}>
                                <div id="map-contact" style={{ width: '100%', height: '100%' }}>
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: 'AIzaSyCxm7e0xAhbiozO-PJ5CDs5xzD_UEdVuuc' }}
                                        center={pos}
                                        options={
                                            {
                                                gestureHandling: 'none',
                                                streetViewControl: true,
                                                mapTypeControl: false,
                                            }
                                        }
                                        defaultZoom={18}
                                        onGoogleApiLoaded={handleApiLoaded}
                                    />
                                </div>
                            </div>
                            <div className={s.operation}>
                                <p className={s.time}><span>Mon</span><span>9:00 AM - 3:00 PM</span></p>
                                <p className={s.time}><span>Tue</span><span>9:00 AM - 5:00 PM</span></p>
                                <p className={s.time}><span>Wed</span><span>9:00 AM - 3:00 PM</span></p>
                                <p className={s.time}><span>Thu</span><span>9:00 AM - 5:00 PM</span></p>
                                <p className={s.time}><span>Fri</span><span>9:00 AM - 3:00 PM</span></p>
                                <p className={s.time}><span>Sat</span><span>9:00 AM - 12:00 PM</span></p>
                                <p className={s.time}>opening by request</p>
                                <div className={s.editOperatioTime}>
                                    <EditOutlined style={{ marginRight: '10px' }} /> Edit business info
                                </div>
                            </div>
                            <div className={s.operationStatus}>
                                Open now
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div className={s.questionContent}>
                        <div className={s.questionHeader}>
                            <div className={s.questionName}>Frequently Asked Questions about {name}</div>
                        </div>
                        <div className={s.questionContent}>
                            <p>Frequently Asked Questions about {name}</p>
                            <p>Psychiatry Arlington heights - Amethyst Maxey accepts credit cards.</p>
                            <p>What days are {name} open?</p>
                            <p>Psychiatry Arlington heights - Amethyst Maxey is open Mon, Tue, Wed, Thu, Fri.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={s.footer}>
                <Button type="primary" ghost>Back</Button>
                <Button type="primary">List me on this channel</Button>
            </div>
        </div>

    );
};

export default Page;
