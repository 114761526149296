export enum ETaskType {
    GET_FIRST_PROSPECT = 'GET_FIRST_PROSPECT',
    ACQUIRE_PROSPECT = 'ACQUIRE_PROSPECT',
    MAINTAIN_AVG_RATING = 'MAINTAIN_AVG_RATING',
    EARN_5_STAR_REVIEWS = 'EARN_5_STAR_REVIEWS',
    LIST_ALL_RELEVANT_CHANNEL = 'LIST_ALL_RELEVANT_CHANNEL',
    TURN_ON_AVA_AUTO_REPLY = 'TURN_ON_AVA_AUTO_REPLY',
    UPLOAD_NEW_LICENSE = 'UPLOAD_NEW_LICENSE',
    GET_FIRST_APPOINTMENT = 'GET_FIRST_APPOINTMENT',
    ACQUIRE_PROSPECT_5 = 'ACQUIRE_PROSPECT_5',
    ACQUIRE_PROSPECT_25 = 'ACQUIRE_PROSPECT_25',
    ACQUIRE_PROSPECT_50 = 'ACQUIRE_PROSPECT_50',
    ACQUIRE_PROSPECT_100 = 'ACQUIRE_PROSPECT_100',
    ACQUIRE_PROSPECT_150 = 'ACQUIRE_PROSPECT_150',
}

export type TTaskItem = {
    'id': number;
    'parentTaskId': number;
    'taskCode': ETaskType;
    'taskName': string;
    'stageId': number;
    'description': string;
    'type': string;
    'taskCycle': null;
    'taskThreshold': number;
    'subTaskExecType': null;
    'kiwiUser': boolean;
    'klarityUser': boolean;
    'status': 'A' | 'D';
    'effectTime': null;
    'expireTime': null;
    'level': number;
    'priority': number;
    'createAt': string;
    'lastUpdateAt': string;
    'createBy': null;
    'lastUpdateBy': null;
    'completeThreshold': null;
    'completed': false
};

export type TTaskList = (TTaskItem & {
    childrenTaskList: TTaskItem[]
})[];

export type TTaskStage = {
    'id': number;
    'name': string;
    'stageCode': string;
    'priority': 1;
    'createAt': string;
    'lastUpdateAt': string;
    'createBy': null;
    'lastUpdateBy': null
};

export type TTaskData = {
    'taskStage': TTaskStage,
    'taskList': TTaskList
};

export type TProfileData = {
    googleProfileLink: string;
    klarityProfileLink: string;
    listedChannelCount: number;
    pfLink: string;
    notCompetitiveServicePer: null | number;
    waitReplyReviewCount: null | number;
};
