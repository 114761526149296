import React, { } from 'react';
import { Button, Modal, TableProps } from 'antd';
import CommonTable from 'components/CommonTable';
import type { ColumnsType } from 'antd/es/table';
import {
    getHelloKlairtyPageConfigList, klarityPageUpdate,
} from 'api/operation';
import s from './s.module.less';
import {
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import {
    EKlarityPage,
    EKlarityPageStatus,
    EKlarityPageStatusLabel,
    TKlarityPage,
} from 'types/helloKlarity';
import ExternalLinkIcon from 'assets/common/externalLinkIcon.svg';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import useSettings from 'hooks/useSettings';
import { EnumFields } from 'types/enumerationData';
import { getHelloKlarityHost, isProductionEnv } from 'utils/common';
import { PRE_RELEASE_ORIGIN } from 'constants/common';
import { getTimeStr } from 'utils/calendar';
import dayjs from 'utils/dayjs';
import { IGetHelloKlarityPageManagementResp } from 'types/operation';
import { Meta, ServerMeta } from 'types/common';

const onlyValue = 'HOME';

type Props = {
    type: EKlarityPage;
};

const TabContent = (props: Props) => {
    const { type } = props;
    const [itemList, setItemList] = React.useState<TKlarityPage[]>([]);
    const [total, setTotal] = React.useState<number>(0);
    const [loading, setLoading] = React.useState(false);
    const [modal, contextHolder] = Modal.useModal();
    const [setting] = useSettings();
    const conditionOption = setting[EnumFields.HELLO_KLARITY_CONDITION];
    const stateOption = setting[EnumFields.STATE];
    const host = getHelloKlarityHost();

    const [page, setPage] = React.useState(1);
    const [condition, setCondition] = React.useState<string[]>([]);
    const [state, setState] = React.useState<string[]>([]);
    const [order, setOrder] = React.useState<'DESC' | 'ASC'>();
    const [status, setStatus] = React.useState<('ACTIVE' | 'INACTIVE')[]>([]);

    const navigate = useNavigate();

    const handleLink = (p: TKlarityPage) => {
        const targetState = stateOption?.find((stateItem) => stateItem.value === p.state || stateItem.label === p.state);
        const targetCondition = conditionOption?.find((conditionItem) => conditionItem.value === p.name || conditionItem.label === p.name);
        let url = `${host}/condition/${targetCondition?.value}?state=${targetState?.label?.toLowerCase()?.replace(/ /g, '-')}`;
        if (type === EKlarityPage.HOME) {
            if (p.name === 'FAQS') {
                url = `${host}/faqs`;
            } else {
                url = `${host}`;
            }
        }
        if (type === EKlarityPage.STATE) {
            url = `${host}/providers/${targetState?.label?.toLowerCase()?.replace(/ /g, '-')}`;
        }
        window.open(
            url,
            '_blank',
        );
    };

    const doFetch = async (current:number, conditionFilter?:string[], stateFilter?:string[], statusFilter?:('ACTIVE' | 'INACTIVE')[], orderFilter?:'DESC' | 'ASC') => {
        setLoading(true);
        const reqParam: IGetHelloKlarityPageManagementResp = {
            current,
            size: 10,
            type,
        };
        if (conditionFilter && conditionFilter.length > 0) {
            reqParam.name = (conditionOption as Meta[])?.filter((item) => {
                return conditionFilter.includes(item.label) || conditionFilter.includes(item.value as string);
            }).map((item) => item.label as string);
        }
        if (stateFilter && stateFilter.length > 0) {
            reqParam.state = stateFilter;
        }
        if (orderFilter) {
            reqParam.orderType = orderFilter;
            reqParam.orderKey = 'LAST_UPDATE_AT';
        }
        if (statusFilter && statusFilter.length === 1) {
            reqParam.statusList = statusFilter;
        }
        const result = await getHelloKlairtyPageConfigList(reqParam);
        if (!result.error) {
            setItemList(result?.data?.data?.records);
            setTotal(result?.data?.data?.total);
        }
        setLoading(false);
    };

    const updateStatus = async (params: TKlarityPage) => {
        try {
            const { data } = await klarityPageUpdate(params, params.id!);
            if (data.code === 200) {
                doFetch(page, condition, state, status, order);
            }
        } catch (e) {
            console.log('e: ', e);
        }
    };

    const handleStatusChange = async (p: TKlarityPage) => {
        const params: TKlarityPage = {
            ...p,
            status:
            p.status === EKlarityPageStatus.ACTIVE
                ? EKlarityPageStatus.INACTIVATE
                : EKlarityPageStatus.ACTIVE,
        };
        if (p.status === EKlarityPageStatus.ACTIVE) {
            modal.confirm({
                width: '600px',
                closable: true,
                title: 'Inactivate the page?',
                icon: <ExclamationCircleOutlined />,
                content:
                'Inactivating this page will immediately remove all carousels from the HelloKlarity website, except for the first one. Are you sure you want to proceed?',
                okText: 'Inactivate',
                cancelText: 'Cancel',
                onOk: async () => {
                    updateStatus(params);
                },
            });
            return;
        }
        updateStatus(params);
    };

    const onTableChange:TableProps<TKlarityPage>['onChange'] = (pagination, filters:any, sorter:any) => {
        //filter change
        const pageInx = pagination?.current || 1;
        const conditionFilter = filters.name || [];
        const stateFilter = filters.state || [];
        const statusFilter = filters.status || [];
        const orderType = sorter.column?.key === 'lastUpdateAt' ? sorter.order : undefined;
        let orderVal:undefined | 'DESC' | 'ASC';

        if (orderType === 'ascend') {
            orderVal = 'ASC';
        }
        if (orderType === 'descend') {
            orderVal = 'DESC';
        }
        setPage(pageInx);
        setCondition(conditionFilter);
        setState(stateFilter);
        setOrder(orderVal); //'DESC''ASC'
        setStatus(statusFilter);

        doFetch(pageInx, conditionFilter, stateFilter, statusFilter, orderVal);
    };

    React.useEffect(() => {
        doFetch(page, condition, state, status, order);
    }, []);

    const columns: ColumnsType<TKlarityPage> = [
        type === EKlarityPage.CONDITION ?
            {
                title: 'CONDITION',
                dataIndex: 'name',
                filterSearch: true,
                filters: conditionOption?.map((conditionItem) => ({
                    text: conditionItem.label,
                    value: conditionItem.value as string,
                })) || [],
                render: (name: string) => {
                    return (
                        <div className={s.cell}>
                            {name}
                        </div>
                    );
                },
            } : null,
        (type === EKlarityPage.STATE || props.type === EKlarityPage.CONDITION) ?
            {
                title: 'STATE',
                dataIndex: 'state',
                filterSearch: true,
                filters: stateOption?.map((stateItem) => ({
                    text: stateItem.label,
                    value: stateItem.value as string,
                })) || [],
                render: (val: string) => {
                    const targetState = stateOption?.find((stateItem) => stateItem.value === val || stateItem.label === val);
                    return (
                        <div className={s.cell}>
                            {targetState?.label}
                        </div>
                    );
                },
            } : null,

        (type === EKlarityPage.HOME) ?
            {
                title: 'PAGE',
                dataIndex: 'name',
                render: (val: string) => {
                    return <div className={s.cell}>{val}</div>;
                },
            } : null,
        {
            title: 'STATUS',
            dataIndex: 'status',
            width: '160px',
            filters: type === EKlarityPage.HOME ? undefined : [
                {
                    text: 'Active',
                    value: 'ACTIVE',
                },
                {
                    text: 'Inactive',
                    value: 'INACTIVE',
                },
            ],
            render: (val: string) => {
                return (
                    <div className={s.cell}>
                        {val === 'A' && 'Active'}
                        {val === 'D' && 'Inactive'}
                    </div>
                );
            },
        },
        {
            title: 'LAST MODIFIED',
            sorter: type !== EKlarityPage.HOME,
            key: 'lastUpdateAt',
            dataIndex: 'lastUpdateAt',
            width: '15%',
            render: (p: string) => {
                return p ? getTimeStr(dayjs(p), 'MM/DD/YYYY') : '';
            },
        },
        {
            title: '',
            width: '300px',
            render: (p: TKlarityPage) => {
                const targetState = stateOption?.find((stateItem) => stateItem.value === p.state || stateItem.label === p.state);
                const targetCondition = conditionOption?.find((conditionItem) => conditionItem.value === p.name || conditionItem.label === p.name);
                return (
                    <div className={s.actionWrap}>
                        <Button
                            className={s.link}
                            onClick={() => handleStatusChange(p)}
                            type="text"
                            style={{ minWidth: 100 }}
                            disabled={p.type === onlyValue}
                        >
                            {p.status === EKlarityPageStatus.ACTIVE
                                ? 'De-activate'
                                : 'Activate'}
                        </Button>
                        <span
                            onClick={() => {
                                handleLink(p);
                            }}
                            className={s.link}
                        >
                            Link
                            <img src={ExternalLinkIcon} />
                        </span>
                        <Button
                            className={s.link}
                            onClick={() => {
                                let url = `${PATH.OPERATION}/${PATH.HELLO_KLARITY_PAGE_DETAIL}/${p.name}/${p.id}`;
                                if (type === EKlarityPage.STATE) {
                                    url = `${PATH.OPERATION}/${PATH.HELLO_KLARITY_PAGE_DETAIL}/${p.type}/${p.id}?state=${targetState?.value}`;
                                } else if (type === EKlarityPage.CONDITION) {
                                    url = `${PATH.OPERATION}/${PATH.HELLO_KLARITY_PAGE_DETAIL}/${p.type}/${p.id}?state=${targetState?.value}&&condition=${encodeURIComponent(targetCondition?.label!)}`;
                                }
                                navigate(
                                    url,
                                );
                            }}
                            type="text"
                        >
                            View
                        </Button>
                    </div>
                );
            },
        },
    ].filter(Boolean) as ColumnsType<TKlarityPage>;

    return (
        <div className={s.wrap}>
            <CommonTable
                bordered
                rowKey="id"
                pagination={{
                    total,
                    current: page,
                    pageSize: 10,
                    showSizeChanger: false,
                }}
                loading={loading}
                columns={columns}
                onChange={onTableChange}
                data={itemList || []}
            />
            {contextHolder}
        </div>
    );
};

export default TabContent;
