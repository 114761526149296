export type TPage = {
    id?: number;
    name: string;
    fullName: string;
    createBy?: number;
};

export enum EOrderType {
    DESC = 'DESC',
    ASC = 'ASC',
}

export interface IGetSalesContractPageDataInput {
    owner?: string;
    linkReceiver?: string;
    providerPayPlanTypeList?: string[],
    linkStatusList?: string[],
    orderKey?: string;
    orderType?: EOrderType;
    userId?: number;
    size?: number;
    current?: number;
    contractVersion?: string[];
}

export enum EPlanType {
    ESSENTIAL = 'Essential',
    HELLO_KLARITY = 'Klarity',
    GROWTH = 'Growth',
}

export enum EContractLinkStatus {
    LinkCreated = 'LS',
    LinkClicked = 'LC',
    PaymentReceived = 'PR',
    ContractSigned = 'CS',
    OnboardingScheduled = 'OCS',
    AccountCreated = 'DONE',
}

export enum EContractLinkStatusLabel {
    LS = 'Link created',
    LC = 'Link clicked',
    PR = 'Payment received',
    CS = 'Contract signed',
    OCS = 'Onboarding scheduled',
    DONE = 'Account created',
}
export interface IBPContactLinkItem {
    linkId: number;
    linkUuid: string;
    fullName: string;
    inviteEmail: string;
    phoneNumber: string;
    message: string;
    promotionCode: string;
    presentationEmbeddedLink: string;
    onboardingCallBookLink: string;
    currentStep: string;
    stripeCustomerId: string;
    firstPaidAt?: string;
    payPlanId: number;
    sentAt?: string
    firstClickAt: string;
    contractId?: string;
    contractSignAt?: string;
    contractSignDate?: string;
    contractSignName: string;
    contractSignUrl: string;
    stripePaymentEmail: string;
    signUpEmail: string;
    linkStatus: EContractLinkStatus;
    lastActionAt: string;
    providerId: number;
    providerCreateStatus: string;
    createBy: number;
    createAt: string;
    type: EPlanType;
    uniprofileOpsStatus?: string;
    uniprofileUpdateStatus?: string;
}

export type TContractPage = {
    records: IBPContactLinkItem[];
    current: number;
    size: number;
    total: number;
};

export interface IBPContactLinkDataResp {
    records?: IBPContactLinkItem[];
    total: number;
    size: number,
    current: number,
    orders: string[],
    optimizeCountSql: boolean,
    searchCount: boolean,
    countId?: number,
    maxLimit?: number,
    pages: number
}

export type TSalesInfo = {
    id: number;
    firstName: string;
    lastName: string;
    gmail: string;
    tel: string;
    bookingLinkUrl: string;
    title: string;
};
