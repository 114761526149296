import TinyStore from 'lib/tinyStore';
import { Provider } from 'types/provider';
import { getUserId } from 'utils/localstore';

// {
//     "id": 23,
//     "firstName": "Xiaocong",
//     "lastName": "Liu",
//     "email": "evanliu.it@gmail.com",
//     "tel": "+8613828394138",
//     "gmail": null,
//     "address": "Chaozhou , Guandong Province , China",
//     "zip": "521000",
//     "state": "CA",
//     "npi": "122",
//     "yearExp": 12,
//     "specialityInHealth": "Obsessive-Compulsive(OCD)",
//     "specialityInFamily": "Leg symptoms",
//     "licenseList": [],
//     "physicianData": []
//   }

//remove after login done

type ProviderModal = {
    uniprofileStatus: boolean;
    data: Partial<Provider>;
    loading: boolean;
};

const Store = TinyStore.createStore<ProviderModal>({
    nameSpace: 'klarity',
    key: 'provider',
    crossBundle: false,
    runTime: true,
    initValue: { data: { id: getUserId() }, uniprofileStatus: false, loading: false },
});

export default Store;
