import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Button, Tooltip } from 'antd';
import { kiwiTeamCreate, kiwiTeamUpdate } from 'api/airtable';
import type { TTeam } from 'types/kiwiHealth';
import FileUpload from 'components/form/FileUpload';
import commonS from 'styles/common.module.less';
import s from './s.module.less';

type Props = {
    item?: TTeam
    onCancel?: () => void
    onSuccess?: () => void
};

const { TextArea } = Input;

const CreateAndEditPage: React.FC<Props> = ({
    item,
    onCancel,
    onSuccess,
}) => {
    const [submitting, setSubmitting] = useState(false);
    const [form] = Form.useForm<TTeam & { active: boolean }>();
    const isEdit = !!item?.index;

    const onSave = async () => {
        setSubmitting(true);
        try {
            const formValues = await form.validateFields();
            const index = item?.index;
            const params: Partial<TTeam> = {
                name: formValues.name,
                position: formValues.position,
                description: formValues.description,
                image_url: formValues.image_url,
            };
            if (isEdit) {
                params.index = index;
            }
            const apiFunc = isEdit
                ? kiwiTeamUpdate(params)
                : kiwiTeamCreate(params);
            await apiFunc;
            onSuccess?.();
        } catch (error) {
            console.error(error);
        } finally {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        if (item) {
            form.setFieldsValue({
                name: item.name,
                position: item.position,
                description: item.description,
                image_url: item.image_url,
            });
        } else {
            form.resetFields();
        }
    }, [item]);

    return (
        <Modal
            title={isEdit ? 'Edit' : 'Add a new one'}
            closable
            className={commonS.modalFixHeightWrap}
            width="500px"
            destroyOnClose
            onCancel={onCancel}
            okText="Save"
            open
            footer={
                <div>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button loading={submitting} type="primary" onClick={onSave}>
                        Save
                    </Button>
                </div>
            }
        >
            <div className={s.wrap}>
                <Form
                    form={form}
                    className={commonS.formStyle1}
                    initialValues={{ active: true }}
                    autoComplete="off"
                    layout="vertical"
                    scrollToFirstError
                >
                    <>
                        <Form.Item
                            className={s.rowItem}
                            label={<div className={s.formLabel}>Name</div>}
                            name="name"
                            rules={[{ required: true, message: 'Name is required' }]}
                        >
                            <Input style={{ width: '100%' }} maxLength={30} />
                        </Form.Item>
                        <Form.Item
                            className={s.rowItem}
                            label={<div className={s.formLabel}>Job position</div>}
                            name="position"
                            rules={[
                                { required: true, message: 'Job position is required' },
                            ]}
                        >
                            <Input style={{ width: '100%' }} maxLength={30} />
                        </Form.Item>
                        <Form.Item
                            className={s.rowItem}
                            label="Description"
                            name="description"
                            rules={[{ required: true, message: 'Description is required' }]}
                        >
                            <TextArea style={{ width: '100%' }} rows={4} maxLength={300} />
                        </Form.Item>
                        <Form.Item
                            className={s.rowItem}
                            label="Image (240 * 240)"
                            name={['image_url']}
                            rules={[{ required: true, message: 'Image is required' }]}
                        >
                            <FileUpload />
                        </Form.Item>
                    </>
                </Form>
            </div>
        </Modal>
    );
};

export default CreateAndEditPage;
