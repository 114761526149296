import { Provider } from 'types/provider';
import { getProviderNameDisplay } from './provider';

export const handleProviderNameReplacement = ((value: string, providerData?: Provider) => {
    if (providerData && value) {
        const providerName = getProviderNameDisplay(providerData);

        return value.replace(/(\[Provider name\]|\[Provider's name\]|\[Provider‘s Name\])/ig, providerName);
    }

    return value;
});

export const handleCredentialReplacement = ((value: string, providerData?: Provider) => {
    if (providerData && value && providerData.credential) {
        return value.replace(/(\[Provider Credential\]|\[Credentials\])/ig, providerData.credential);
    }

    return value;
});
