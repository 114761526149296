// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/common/empty.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--TrY85 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 72px;
}
.s-module__wrap--TrY85 .s-module__title--o20On {
  color: var(--Primary-Black, #15191E);
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  /* 150% */
}
.s-module__wrap--TrY85 .s-module__desc--e8zMo {
  color: var(--White-gray, #6B7280);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}
.s-module__wrap--TrY85 .s-module__empty--TFlrK {
  width: 148px;
  height: 148px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) 0 0 no-repeat;
  background-size: 100% 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/operation/HelloKlarityPageManagement/components/CarouselsEmpty/s.module.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AACF;AAPA;EAQI,oCAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EAEF,SAAS;AACX;AAjBA;EAiBI,iCAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAGJ;AAzBA;EAyBI,YAAA;EACA,aAAA;EACA,iEAAA;EACA,0BAAA;AAGJ","sourcesContent":[".wrap {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  margin-top: 72px;\n  .title {\n    color: var(--Primary-Black, #15191E);\n    text-align: center;\n    font-family: Inter;\n    font-size: 20px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 30px; /* 150% */\n  }\n  .desc {\n    color: var(--White-gray, #6B7280);\n    font-family: Inter;\n    font-size: 18px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 30px;\n  }\n  .empty {\n    width: 148px;\n    height: 148px;\n    background: url(assets/common/empty.svg) 0 0 no-repeat;\n    background-size: 100% 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--TrY85`,
	"title": `s-module__title--o20On`,
	"desc": `s-module__desc--e8zMo`,
	"empty": `s-module__empty--TFlrK`
};
export default ___CSS_LOADER_EXPORT___;
