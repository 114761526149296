import TinyStore from 'lib/tinyStore';
import { HomeInfo } from 'types/provider';

type ProfileModal = {
    isSyncingEhr: boolean;
};

const Store = TinyStore.createStore<ProfileModal>({
    nameSpace: 'box_practice_profile_store',
    key: 'home',
    crossBundle: false,
    runTime: true,
    initValue: { isSyncingEhr: false },
});
export default Store;
