import TinyStore from 'lib/tinyStore';
import { EditModuleEnum } from 'types/practiceFront';

export const editStatusMap = {
    [EditModuleEnum.BASICINFO]: false,
    [EditModuleEnum.CONTACT]: false,
    [EditModuleEnum.EDUCATION]: false,
    [EditModuleEnum.INSURANCE]: false,
    [EditModuleEnum.MYPHILOSOPHY]: false,
    [EditModuleEnum.SEPCIALITIESAPPROACH]: false,
    [EditModuleEnum.WORKEXPERIENCE]: false,
    [EditModuleEnum.ABOUTME]: false,
    [EditModuleEnum.SERVICE]: false,
    [EditModuleEnum.PRACTICE_ADDRESS]: false,
    [EditModuleEnum.APPOINTMENT_LINK]: false,
};

type EditStatusModal = {
    data: Record<EditModuleEnum, boolean>;
};

const Store = TinyStore.createStore<EditStatusModal>({
    nameSpace: 'klarity',
    key: 'practiceFrontFormEditStatus',
    crossBundle: false,
    runTime: true,
    initValue: { data: editStatusMap },
});

export default Store;
