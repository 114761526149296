import React from 'react';
import { Modal, Form, message, Input } from 'antd';
import commonS from 'styles/common.module.less';
import { FormFieldEnum, FormInterface } from './types';
import s from './s.module.less';
import { REQUIRED_FIELD } from 'constants/common';
import { TContentInfo } from 'types/operation';
import { updateHelloKlairtyKlarityPageConditionContent } from 'api/operation';

type Props = {
    onCancel:()=>void
    item?:TContentInfo //for edit fun
    onSuccess?: ()=>void
    open?: boolean;
};

const EditContentModal = (props:Props) => {
    const { onCancel, item, onSuccess, open } = props;
    const [form] = Form.useForm<FormInterface>();
    const [,forceUpdate] = React.useState({});
    const [submiting, setSubmiting] = React.useState(false);

    const onOk = async () => {
        try {
            setSubmiting(true);
            const formValues = await form.validateFields();

            const data:TContentInfo = {
                ...item!,
                title: formValues[FormFieldEnum.TITLE],
                content: formValues[FormFieldEnum.CONTENT],
                status: 'A', //always active

            };

            const result = await updateHelloKlairtyKlarityPageConditionContent(data!);
            if (!result.error) {
                message.success('Update success');
                onSuccess?.();
            }
        } catch (e:any) {
            if (e.errorFields) {
                //form error
                return;
            }
            const msg = e?.toString?.() || 'data error';
            message.error(msg);
        } finally {
            setSubmiting(false);
        }
    };
    React.useEffect(() => {
        if (item) {
            form.setFieldsValue({
                [FormFieldEnum.TITLE]: item.title,
                [FormFieldEnum.CONTENT]: item.content,
            });
            forceUpdate({});
        }
    }, [item]);

    React.useEffect(() => {
        if (!open) {
            form.resetFields();
        }
    }, [open]);

    return (
        <Modal
            title={<div className={s.modalTitle}>Edit {item?.title}</div>}
            closable
            className={commonS.modalFixHeightWrap}
            width="550px"
            destroyOnClose
            onCancel={() => {
                onCancel?.();
            }}
            onOk={onOk}
            okText="Save change"
            open={open}
            okButtonProps={{
                loading: submiting,
            }}
        >
            <Form
                form={form}
                name="basic"
                className={commonS.formStyle1}
                initialValues={{ remember: true }}
                autoComplete="off"
                layout="vertical"
            >
                <div className={s.module}>
                    <div className={s.row}>
                        <div
                            className={s.rowItem}
                            style={{ width: '100%' }}
                        >
                            <Form.Item
                                label="Title"
                                name={[FormFieldEnum.TITLE]}
                                validateFirst
                                rules={[
                                    { required: true, message: REQUIRED_FIELD },
                                ]}
                            >
                                <Input maxLength={100} />
                            </Form.Item>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div
                            className={s.rowItem}
                            style={{ width: '100%' }}
                        >
                            <Form.Item
                                label="Content"
                                name={[FormFieldEnum.CONTENT]}
                                validateFirst
                                rules={[
                                    { required: true, message: REQUIRED_FIELD },
                                ]}
                            >
                                <Input.TextArea
                                    rows={7}
                                    maxLength={650}
                                    showCount
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal>
    );
};

export default EditContentModal;
