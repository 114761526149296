import { Popover, Spin } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import CommonTable from 'components/CommonTable';
import { ColumnsType } from 'antd/es/table';
import FilterSelect from '../../FilterSelect';
import s from './s.module.less';
import DatePicker from '../../DatePicker';
import ProfileViewDrawer from 'components/ProfileViewDrawer';
import { getProviderDetail, getProviderTable } from 'api/ai';
import { getAiUserId, isAiApiSuccess } from 'utils/common';
import Feedback from '../../Feedback';
import { TTableData, TTableProvider } from 'types/ai';

import { getProviderNameDisplay } from 'utils/provider';
import { ServerMeta, TPackage } from 'types/common';
import { EnumFields } from 'types/enumerationData';
import useGetSettingData from 'hooks/useGetSettingData';

type Props = {
    conversationId?: string;
    messageId?: string;
    onSearch?: (val: string) => void;
    showFeedback?: boolean;
    showFilter?: boolean;
    messageIdProviderMap: Record<string, TTableData>,
};

type PopProps = {
    allSupportStatesKeyLinkMap?: Record<string, TPackage[]>,
    allStates?: ServerMeta[],
    tagetStateKeys?: string[], //['CA', 'TX']
};

const PopContent = (props: PopProps) => {
    const { allSupportStatesKeyLinkMap, allStates, tagetStateKeys } = props;

    const [showOthers, setShowOthers] = React.useState(false);
    const [hasOthers, setHasOthers] = React.useState(false);

    const [defaultItems, setDefaultItems] = React.useState<Record<string, TPackage[]>>();
    const [othersItems, setOthersItems] = React.useState<Record<string, TPackage[]>>();

    React.useEffect(() => {
        if (allSupportStatesKeyLinkMap) {
            let _defaultItems: Record<string, TPackage[]> = {};
            const _othersItems: Record<string, TPackage[]> = {};
            Object.keys(allSupportStatesKeyLinkMap).forEach((st: string) => {
                if (tagetStateKeys?.includes(st)) {
                    _defaultItems[st] = allSupportStatesKeyLinkMap[st];
                } else {
                    _othersItems[st] = allSupportStatesKeyLinkMap[st];
                }
            });
            if (Object.keys(_defaultItems).length === 0) {
                _defaultItems = { ..._othersItems };
                setDefaultItems(_defaultItems);
                setHasOthers(false);
            } else if (Object.keys(_othersItems).length > 0) {
                setDefaultItems(_defaultItems);
                setOthersItems(_othersItems);
                setHasOthers(true);
            } else {
                setDefaultItems(_defaultItems);
                setHasOthers(false);
                setShowOthers(false);
            }
        }
    }, [allSupportStatesKeyLinkMap, tagetStateKeys]);

    return (
        <div className={s.toolWrap}>
            {
                defaultItems && Object.keys(defaultItems).map((st: string) => {
                    const targetState = (allStates as ServerMeta[])?.find((state: ServerMeta) => state.dictKey === st || state.content === st);
                    const links = defaultItems[st];
                    return (
                        <div className={s.pop} key={st}>
                            <div className={s.popTitle}>
                                {targetState?.content || st}
                            </div>
                            <ul className={s.popContent}>
                                {
                                    links.map((link: TPackage) => {
                                        return (
                                            <li className={s.popLink} key={link.url}>
                                                <span className={s.price}>[${link.price}]</span>
                                                <span>{link.name}</span>
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        </div>
                    );
                })
            }
            {
                showOthers && othersItems && Object.keys(othersItems).map((st: string) => {
                    const targetState = (allStates as ServerMeta[])?.find((state: ServerMeta) => state.dictKey === st || state.content === st);
                    const links = othersItems[st];
                    return (
                        <div className={s.pop} key={st}>
                            <div className={s.popTitle}>
                                {targetState?.content || st}
                            </div>
                            <ul className={s.popContent}>
                                {
                                    links.map((link: TPackage) => {
                                        return (
                                            <li className={s.popLink} key={link.url}>
                                                <span className={s.price}>[${link.price}]</span>
                                                <span>{link.name}</span>
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        </div>
                    );
                })
            }
            {
                hasOthers && !showOthers &&
                <div
                    className={s.showOther}
                    onClick={() => {
                        setShowOthers(true);
                    }}
                >
                    Show services in other states
                </div>
            }
        </div>
    );
};

const TableWithFilter = (props: Props) => {
    const [allStates] = useGetSettingData(EnumFields.STATE);
    const { conversationId = '', messageId = '', showFeedback, onSearch, showFilter, messageIdProviderMap } = props;
    const [sortByFilter, setSortByFilter] = React.useState<string | undefined>('recommended');

    const [providers, setProviders] = React.useState<TTableProvider[]>();
    const [targetStateKeys, setTargetStateKeys] = React.useState<string[]>();

    const sortByOption = [
        { id: 13264, type: 'SORT_BY', dictKey: 'recommended', content: 'Recommended', description: null },
        { id: 13266, type: 'SORT_BY', dictKey: 'lowest', content: 'Lowest price', description: null },
        { id: 13267, type: 'SORT_BY', dictKey: 'highest', content: 'Highest price', description: null },
    ];

    const [defaultDate, setDefaultDate] = React.useState<Dayjs>(dayjs());
    const [currentDate, setCurrentDate] = React.useState<Dayjs>(defaultDate);

    const [openDateFilter, setOpenDateFilter] = React.useState(false);

    const [currentShowProvider, setCurrentShowProvider] = React.useState<TTableProvider>();
    const [showProvider, setShowProvider] = React.useState(false);

    const [loading, setLoading] = React.useState(false);

    const hasData = providers && providers?.length > 0;

    const usedData: TTableProvider[] = [...(providers || [])];
    if (sortByFilter === 'lowest') {
        usedData.sort((a, b) => {
            return a.lowestPrice - b.lowestPrice;
        });
    }
    if (sortByFilter === 'highest') {
        usedData.sort((a, b) => {
            return b.lowestPrice - a.lowestPrice;
        });
    }

    React.useEffect(() => {
        const fetchData = async () => {
            if (!messageId) {
                return;
            }
            setProviders([]);
            setSortByFilter('recommended');
            setLoading(true);
            const result = await getProviderTable({
                inputs: {
                    conversation_id: conversationId!,
                    message_id: messageId!,
                    user_id: getAiUserId(),
                },
                user: getAiUserId(),
            });
            // const result = {
            //     data: {
            //         code: 200,
            //         message: 'Success',
            //         data: {
            //             task_id: '6b0d2e12-7638-41bd-9027-17006cd33e73',
            //             workflow_run_id: '702ac83e-84ad-47dd-91bc-76d71a0f3a79',
            //             data: {
            //                 id: '702ac83e-84ad-47dd-91bc-76d71a0f3a79',
            //                 workflow_id: '312ae26f-209d-4db3-aae1-facbe8de57f8',
            //                 status: 'succeeded',
            //                 outputs: {
            //                     provider_ids: [
            //                         '591',
            //                         '6552b750ea5b4b59224992c1',
            //                         '652d87df7b4996568e2b5188',
            //                         '51',
            //                     ],
            //                 },
            //                 error: null,
            //                 elapsed_time: 12.371721468865871,
            //                 total_tokens: 20127,
            //                 total_steps: 4,
            //                 created_at: 1717123422,
            //                 finished_at: 1717123434,
            //             },
            //         },
            //     },
            // };
            if (isAiApiSuccess(result)) {
                const _providerIds = result?.data?.data?.data?.outputs?.provider_ids;
                const _states = result?.data?.data?.data?.outputs?.states;
                const providerResult = await getProviderDetail(_providerIds);
                if (!providerResult.error) {
                    const providerResultData = providerResult?.data?.data;
                    messageIdProviderMap[messageId] = {
                        providers: providerResultData,
                        states: _states || [],
                    };
                    setProviders(providerResultData);
                    setTargetStateKeys(_states);
                }
            }
            setLoading(false);
        };
        if (conversationId && messageId) {
            //updated, can fetch table data
            if (messageIdProviderMap[messageId]) {
                setProviders(messageIdProviderMap[messageId].providers || []);
                setTargetStateKeys(messageIdProviderMap[messageId].states || []);
            } else {
                fetchData();
            }
        }
    }, [conversationId, messageId, messageIdProviderMap]);

    const providerColumns: ColumnsType<TTableProvider> = [
        {
            title: 'NAME',
            dataIndex: 'name',
            key: 'name',
            render: (name: string, provider: TTableProvider) => {
                return (
                    <div className={s.info}>
                        <div className={s.left}>
                            <img src={(provider.photos && provider.photos.length > 0) ? provider.photos[0] : ''} />
                        </div>
                        <div className={s.right}>
                            <div className={s.name}>
                                {getProviderNameDisplay(provider)}
                            </div>
                            <div className={s.credential}>
                                {provider.credential}
                            </div>
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'IN-PERSON',
            width: '120px',
            key: 'inPerson',
            dataIndex: 'inPerson',
            render: (val) => {
                return (
                    <div>
                        {val ? 'Yes' : 'No'}
                    </div>
                );
            },
        },
        {
            title: 'SERVICES',
            key: 'initialList',
            dataIndex: 'initialList',
            width: '150px',
            render: (initialList: TPackage[]) => {
                let length = 0;
                const allSupportStateKeys: string[] = [];
                initialList.forEach((p: TPackage) => {
                    const supportedStates = p.state || [];
                    supportedStates.forEach((st: string) => {
                        length += 1;
                        if (!allSupportStateKeys.includes(st)) {
                            allSupportStateKeys.push(st);
                        }
                    });
                });
                const allSupportStatesKeyLinkMap: Record<string, TPackage[]> = {};
                const showTips = length > 0;
                allSupportStateKeys.forEach((st: string) => {
                    allSupportStatesKeyLinkMap[st] = [];
                    initialList.forEach((p: TPackage) => {
                        const supportedStates = p.state || [];
                        if (supportedStates.includes(st) && p.url) {
                            allSupportStatesKeyLinkMap[st].push(p);
                        }
                    });
                });

                if (showTips) {
                    return (
                        <div className={s.colDurWrap}>
                            <Popover
                                content={(
                                    <PopContent
                                        allSupportStatesKeyLinkMap={allSupportStatesKeyLinkMap}
                                        allStates={allStates as ServerMeta[]}
                                        tagetStateKeys={targetStateKeys}
                                    />
                                )}
                            >
                                <span className={s.canHover}> {length} services</span>
                            </Popover>
                        </div>
                    );
                }
                return (
                    <div>
                        0 services
                    </div>
                );
            },
        },
        {
            title: 'INITIAL VISIT PRICE',
            width: '150px',
            key: 'lowestPrice',
            dataIndex: 'lowestPrice',
            render: (val: number) => {
                return (
                    <div>
                        Lowest: ${val}
                    </div>
                );
            },
        },
        {
            title: 'CONSULTATION',
            width: '120px',
            key: 'consultation',
            dataIndex: 'consultation',
            render: (consultationList: TPackage[]) => {
                let length = 0;
                const allSupportStateKeys: string[] = [];
                consultationList?.forEach((p: TPackage) => {
                    const supportedStates = p.state || [];
                    supportedStates.forEach((st: string) => {
                        length += 1;
                        if (!allSupportStateKeys.includes(st)) {
                            allSupportStateKeys.push(st);
                        }
                    });
                });
                const showTips = length > 0;
                const allSupportStatesKeyLinkMap: Record<string, TPackage[]> = {};
                allSupportStateKeys.forEach((st: string) => {
                    allSupportStatesKeyLinkMap[st] = [];
                    consultationList?.forEach((p: TPackage) => {
                        const supportedStates = p.state || [];
                        if (supportedStates.includes(st) && p.url) {
                            allSupportStatesKeyLinkMap[st].push(p);
                        }
                    });
                });

                if (showTips) {
                    return (
                        <div className={s.colDurWrap}>
                            <Popover
                                content={(
                                    <PopContent allSupportStatesKeyLinkMap={allSupportStatesKeyLinkMap} allStates={allStates as ServerMeta[]} tagetStateKeys={targetStateKeys} />
                                )}
                            >
                                <span className={s.canHover}> {length}</span>
                            </Popover>
                        </div>
                    );
                }
                return (
                    <div>
                        0
                    </div>
                );
            },
        },
        {
            title: '',
            width: '150px',
            render: (provider: TTableProvider) => {
                return (
                    <div
                        className={s.view}
                        onClick={() => {
                            setCurrentShowProvider(provider);
                            setShowProvider(true);
                        }}
                    >
                        View profile
                    </div>
                );
            },
        },
    ];

    if (!messageId) {
        return null;
    }

    if (loading) {
        return (
            <Spin spinning className={s.loading} />
        );
    }

    return (
        <div className={s.wrap}>
            {
                hasData &&
                <>
                    <div className={s.filter}>
                        {
                            showFilter &&
                            <div className={s.filterItem}>
                                <Popover
                                    content={
                                        <div style={{ width: '520px' }}>
                                            <DatePicker
                                                defaultValue={defaultDate}
                                                onChange={setCurrentDate}
                                                defaultState={targetStateKeys ? targetStateKeys[0] : undefined}
                                                startDate={dayjs()}
                                                endDate={dayjs().add(2, 'month')}
                                                onSearch={(val) => {
                                                    setOpenDateFilter(false);
                                                    onSearch?.(val);
                                                }}
                                                onCancel={() => {
                                                    setOpenDateFilter(false);
                                                }}
                                            />
                                        </div>
                                    }
                                    title={null}
                                    trigger="click"
                                    open={openDateFilter}
                                    placement="bottomRight"
                                    onOpenChange={(open) => {
                                        setOpenDateFilter(open);
                                    }}
                                >
                                    <div className={`${s.select} ${openDateFilter ? s.selected : ''}`}>
                                        <span>Availability</span>
                                        <span className={s.icon}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                                <path d="M15.0418 7.125L9.50016 12.6667L3.9585 7.125" stroke="#6B7280" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </span>
                                    </div>
                                </Popover>
                            </div>
                        }
                        <div className={s.filterItem}>
                            <FilterSelect
                                label="Sort by"
                                defaultValue={sortByFilter}
                                items={sortByOption || []}
                                onChange={(val: string) => {
                                    setSortByFilter(val);
                                }}
                            />
                        </div>
                    </div>

                    <div className={s.tableWrap}>
                        <CommonTable
                            bordered
                            rowKey="providerId"
                            columns={providerColumns}
                            data={usedData}
                            pagination={false}
                        />
                    </div>
                </>
            }
            {
                showFeedback &&
                <div className={s.feedbackWrap}>
                    <Feedback messageId={messageId} />
                </div>
            }

            <ProfileViewDrawer
                onClose={() => {
                    setShowProvider(false);
                }}
                providerId={currentShowProvider?.providerId}
                open={showProvider}
                isAirtableUser={currentShowProvider?.source === 'A'}
            />
        </div>
    );
};

export default TableWithFilter;
