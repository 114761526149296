import React, { useState } from 'react';
import { Modal, Form, Input, Button, message } from 'antd';
import commonS from 'styles/common.module.less';
import s from './s.module.less';
import { providerPageInfoUpdate } from 'api/operation';
import { ProviderPageInfo, TProviderPage, TProviderPageDetail } from 'types/kiwiHealth';
import KiwiChannelSelect from 'components/form/KiwiChannelSelect';
import { URL_LIMIT } from 'constants/common';

type Props = {
    isOps?: boolean
    item?: ProviderPageInfo
    onCancel?:()=>void
    onSuccess?: ()=>void
};

export type FormPageInfo = {
    list?:TProviderPage[]
};
const CreateAndEditProviderPage = ({ item, onCancel, onSuccess }:Props) => {
    const [submiting, setSubmiting] = useState(false);
    const [form] = Form.useForm<FormPageInfo>();
    const isEdit = !!item?.id;
    const [selectedPageIds, setSelectedPageIds] = useState<number[]>([]);
    const filedIdMap = React.useRef<Record<string, number>>({});

    const onSave = async () => {
        if (!item?.id) {
            message.error('Can not find provider id');
        }
        setSubmiting(true);
        try {
            const formValues = await form.validateFields();
            const itemArrary:TProviderPage[] = formValues.list || [];
            const params: TProviderPage[] = itemArrary?.map((pp:TProviderPage) => {
                return {
                    pageId: pp.pageId,
                    profileUrl: pp.profileUrl,
                };
            });
            const { data } = await providerPageInfoUpdate(params, item?.id!);
            if (data.code === 200) {
                onSuccess?.();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setSubmiting(false);
        }
    };

    React.useEffect(() => {
        if (item) {
            const newSelectedPageIds:number[] = [];
            const list: TProviderPage[] = item?.pageDetailRspList?.map((pageInfo: TProviderPageDetail, inx) => {
                newSelectedPageIds.push(pageInfo.pageId);
                filedIdMap.current[`${inx}`] = pageInfo.pageId;
                return {
                    pageId: pageInfo.pageId,
                    profileUrl: pageInfo.profileUrl,
                };
            }) || [{}];
            setSelectedPageIds(newSelectedPageIds);
            form.setFieldsValue({
                list,
            });
        } else {
            form.resetFields();
        }
    }, [item]);

    const hasPage = item?.pageDetailRspList && item?.pageDetailRspList.length > 0;

    return (
        <Modal
            title={hasPage ? 'Edit page' : 'Assign pages'}
            closable
            className={commonS.modalFixHeightWrap}
            width="500px"
            destroyOnClose
            onCancel={onCancel}
            okText="Save"
            open
            footer={
                <div>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button loading={submiting} type="primary" onClick={onSave}>Save</Button>
                </div>
            }
        >
            <div className={s.wrap}>
                <Form
                    form={form}
                    className={commonS.formStyle1}
                    initialValues={{ list: [{}] }}
                    autoComplete="off"
                    layout="vertical"
                    scrollToFirstError
                >
                    <Form.List name="list" initialValue={[{}]}>
                        {(fields, { add, remove }) => {
                            const { length } = fields;
                            return (
                                <div id="list">
                                    {fields.map((field) => {
                                        return (
                                            <Form.Item
                                                key={field.key}
                                                noStyle
                                            >
                                                <div className={s.modalWrap}>
                                                    <div className={s.header}>
                                                        <span>Page {field.name + 1}</span>
                                                        <span>
                                                            {
                                                                length !== 100 &&

                                                                <Button
                                                                    onClick={() => {
                                                                        const _key = `${field.name}`;
                                                                        const val = filedIdMap.current[_key];
                                                                        if (val && selectedPageIds.includes(val)) {
                                                                            delete filedIdMap.current[_key];
                                                                            const newSelectedPageIds = selectedPageIds.filter((id) => id !== val);
                                                                            setSelectedPageIds(newSelectedPageIds);
                                                                        }
                                                                        remove(field.name);
                                                                    }}
                                                                >Remove
                                                                </Button>
                                                            }
                                                        </span>
                                                    </div>
                                                    <div className={s.content}>
                                                        <div className={s.row}>
                                                            <Form.Item
                                                                style={{ width: '100%' }}
                                                                label="Assign a page"
                                                                name={[field.name, 'pageId']}
                                                                validateFirst
                                                                rules={[
                                                                    { required: true, message: 'Page is required' },
                                                                ]}
                                                            >
                                                                <KiwiChannelSelect
                                                                    disableValues={selectedPageIds}
                                                                    onChange={(id:number) => {
                                                                        const newSelectedPageIds:number[] = [];
                                                                        const _key = `${field.name}`;
                                                                        filedIdMap.current[_key] = id;
                                                                        Object.keys(filedIdMap.current).forEach((key) => {
                                                                            newSelectedPageIds.push(filedIdMap.current[key]);
                                                                        });
                                                                        setSelectedPageIds(newSelectedPageIds);
                                                                    }}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                        <div className={s.row}>
                                                            <Form.Item
                                                                style={{ width: '100%' }}
                                                                label="Profile link"
                                                                name={[field.name, 'profileUrl']}
                                                                validateFirst
                                                                rules={[
                                                                    { required: true, message: 'Profile link is required' },
                                                                ]}
                                                            >
                                                                <Input maxLength={URL_LIMIT} />
                                                            </Form.Item>
                                                        </div>
                                                    </div>

                                                </div>
                                            </Form.Item>);
                                    })}
                                    <div
                                        onClick={() => {
                                            add();
                                            const wrap = document.getElementById('list');
                                            setTimeout(() => {
                                                wrap?.parentElement?.parentElement?.scroll({
                                                    top: 1000000,
                                                    behavior: 'smooth',
                                                });
                                            }, 200);
                                        }}
                                        className={s.add}
                                    >
                                        + Add another page
                                    </div>
                                </div>);
                        }}
                    </Form.List>
                </Form>
            </div>
        </Modal>
    );
};

export default CreateAndEditProviderPage;
