import React, { useMemo, useRef } from 'react';
import {
    Button,
    Input,
    Modal,
    Space,
    TableProps,
    Select,
    Tooltip,
    DatePicker,
} from 'antd';
import CommonTable from 'components/CommonTable';
import type { ColumnsType } from 'antd/es/table';
import { getReviewList } from 'api/operation/reviews';
import s from './s.module.less';
import { SearchOutlined } from '@ant-design/icons';
import { IReviewInput, EOriderType, IReviewItem } from 'types/operation';
import dayjs from 'dayjs';
import useSettings from 'hooks/useSettings';
import { EnumFields } from 'types/enumerationData';
import OpenGoogleForm from '../OpenGoogleForm';
import { EDISPUTE_STATUS } from 'types/common';
import ReviewPrivateSettingPopup from '../ReviewPrivateSettingPopup';

type IProps = {
    type?: 'DETAIL';
    providerId?: number;
    providerSource?: string;
};

const Index: React.FC<IProps> = ({ type, providerId, providerSource }) => {
    const { RangePicker } = DatePicker;
    const isDetail = useMemo(() => type === 'DETAIL', [type]);
    const [open, setOpen] = React.useState(false);
    const [itemList, setItemList] = React.useState<IReviewItem[]>([]);
    const [total, setTotal] = React.useState<number>(0);
    const [loading, setLoading] = React.useState(false);
    const [modal, contextHolder] = Modal.useModal();
    const [setting] = useSettings();
    const negativeOptions = setting[EnumFields.PROVIDER_NEGATIVE_REVIEW_TAG];
    const positiveOptions = setting[EnumFields.PROVIDER_POSITIVE_REVIEW_TAG];
    const pageSize = 10;
    const [dateRangeFilter, setDateRangeFilter] = React.useState('-7');

    const [page, setPage] = React.useState(1);
    const [pendingNameFilter, setPendingNameFilter] = React.useState('');
    const [nameFilter, setnameFilter] = React.useState<string>('');
    const [ratingFilter, setRatingFilter] = React.useState<string[]>([]);
    const [tagsFilter, setTagsFilter] = React.useState<string>('');
    const [searchOrderType, setOrderType] = React.useState<
    EOriderType | undefined
    >(EOriderType.DESC);
    const [searchOrderKey, setOrderKey] = React.useState<string>('submitAt');
    const [pendingEmailFilter, setPendingEmailFilter] = React.useState('');
    const [emailFilter, setEmailFilter] = React.useState<string>('');
    const [publicFilter, setPublicFilter] = React.useState<string | boolean>('');
    const [replyFilter, setReplyFilter] = React.useState<string | boolean>('');
    const [customSelectedRange, setCustomSelectedRange] = React.useState<Date[] | null>(null);
    const [disputeStatusFilter, setDisputeStatusFilter] = React.useState<EDISPUTE_STATUS[]>();
    const [showPrivateSetting, setShowPrivateSetting] = React.useState(false);
    const [selectedReview, setSelectedReview] = React.useState<IReviewItem>();

    const DisputeStatusFilterOptions = useRef([
        {
            text: 'No dispute',
            value: EDISPUTE_STATUS.NORMAL,
        },
        {
            text: 'Under investigation',
            value: EDISPUTE_STATUS.INVESTIGATION,
        },
        {
            text: 'Dispute valid',
            value: EDISPUTE_STATUS.VALID,
        },
        {
            text: 'Dispute invalid',
            value: EDISPUTE_STATUS.INVALID,
        },
    ]);

    const tagsOptions = useMemo(() => {
        return negativeOptions?.concat(positiveOptions || []).map((item) => {
            return {
                text: item.label,
                value: item.value,
            };
        });
    }, [negativeOptions, positiveOptions]);

    const doFetch = async (
        current: number,
        name?: string,
        ratingFilterType?: string[],
        orderField?: string,
        orderType?: string,
        patientEmail?: string,
        publish?: string | boolean,
        needReply?: string | boolean,
        tag?: string,
        disputeStatusList?: EDISPUTE_STATUS[],
        beginDate?: Date,
        endDate?: Date,
    ) => {
        setLoading(true);
        const reqParam: IReviewInput = {
            current,
            size: pageSize,
            name,
            ratingFilterType: ratingFilterType?.[0],
            orderField,
            orderType,
            patientEmail,
            publish,
            needReply,
            tag,
            disputeStatusList,
            beginDate,
            endDate,
        };

        if (!reqParam.ratingFilterType) {
            delete reqParam.ratingFilterType;
        }

        if (isDetail) {
            reqParam.providerSource = providerSource;
            if (providerSource === 'E') {
                reqParam.bpProviderId = providerId;
            } else {
                reqParam.intakeqProviderId = providerId;
            }
        }

        if (!beginDate) {
            if (!!customSelectedRange && customSelectedRange.length > 0) {
                reqParam.beginDate = customSelectedRange?.[0];
                reqParam.endDate = customSelectedRange?.[1];
            } else {
                reqParam.beginDate = new Date(
                    new Date().getTime() + +dateRangeFilter * 24 * 60 * 60 * 1000,
                );
                reqParam.endDate = new Date();
            }
        }

        const result = await getReviewList(reqParam);
        if (!result.error) {
            setItemList(result?.data?.data?.list);
            setTotal(result?.data?.data?.totalRecords);
        }
        setLoading(false);
    };

    const onTableChange: TableProps<IReviewItem>['onChange'] = (
        pagination,
        filters: any,
        sorter: any,
        action: any,
    ) => {
        //filter change
        let pageInx = pagination?.current || 1;
        const _ratingFilter = filters.rating || [];
        const _publicFilter = filters.isPublic ? filters.isPublic?.[0] : '';
        const _tagsFilter = filters.tags?.[0] || '';
        const _needReplyFilter = filters.isNeedReplied ? filters.isNeedReplied?.[0] : '';
        let _searchOrderType = searchOrderType;
        let _orderKey = searchOrderKey;
        if (action?.action === 'sort') {
            const orderType =
        sorter.order === 'ascend' ? EOriderType.ASC : EOriderType.DESC;
            setOrderType(orderType);
            setOrderKey(sorter.field);
            _searchOrderType = orderType;
            _orderKey = sorter.field;
            pageInx = 1;
        }

        const _disputeStatus = filters.disputeStatus || [];

        setDisputeStatusFilter(_disputeStatus);

        setPage(pageInx);
        setRatingFilter(_ratingFilter);
        setPublicFilter(_publicFilter);
        setReplyFilter(_needReplyFilter);
        setTagsFilter(_tagsFilter);

        doFetch(
            pageInx,
            nameFilter,
            _ratingFilter,
            _orderKey,
            _searchOrderType,
            emailFilter,
            _publicFilter,
            _needReplyFilter,
            _tagsFilter,
            _disputeStatus,
        );
    };

    React.useEffect(() => {
        doFetch(page);
    }, []);

    const columns: ColumnsType<IReviewItem> = [
        {
            title: 'PROVIDER',
            dataIndex: 'firstName',
            key: 'firstName',
            width: '220px',
            hidden: !!isDetail,
            filterDropdown: ({ close }: any) => (
                <div style={{ padding: 16 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        placeholder="Search name"
                        value={pendingNameFilter}
                        onChange={(e) => {
                            setPendingNameFilter(e.target.value);
                        }}
                        onPressEnter={() => {
                            setnameFilter(pendingNameFilter);
                            setPage(1);
                            doFetch(
                                1,
                                pendingNameFilter,
                                ratingFilter,
                                searchOrderKey,
                                searchOrderType,
                                emailFilter,
                                publicFilter,
                                replyFilter,
                                tagsFilter,
                                disputeStatusFilter,
                            );
                            close();
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                setnameFilter(pendingNameFilter);
                                setPage(1);
                                doFetch(
                                    1,
                                    pendingNameFilter,
                                    ratingFilter,
                                    searchOrderKey,
                                    searchOrderType,
                                    emailFilter,
                                    publicFilter,
                                    replyFilter,
                                    tagsFilter,
                                    disputeStatusFilter,
                                );
                                close();
                            }}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => {
                                setPendingNameFilter('');
                                setnameFilter('');
                                setPage(1);
                                doFetch(
                                    1,
                                    '',
                                    ratingFilter,
                                    searchOrderKey,
                                    searchOrderType,
                                    emailFilter,
                                    publicFilter,
                                    replyFilter,
                                    tagsFilter,
                                    disputeStatusFilter,
                                );
                                close();
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
            ),
            render: (name: string, item: IReviewItem) => {
                return (
                    <div className={s.cell}>
                        <div className={s.name}>
                            {name} {item.lastName}
                        </div>
                        <div className={s.email}>{item.email}</div>
                    </div>
                );
            },
        },
        {
            title: 'RATING',
            dataIndex: 'rating',
            width: '120px',
            filterMultiple: false,
            filters: [
                {
                    text: 'All stars',
                    value: '',
                },
                {
                    text: '5 stars only',
                    value: 'ONLY_5',
                },
                {
                    text: '4 stars only',
                    value: 'ONLY_4',
                },
                {
                    text: '3 stars only',
                    value: 'ONLY_3',
                },
                {
                    text: '2 stars only',
                    value: 'ONLY_2',
                },
                {
                    text: '1 stars only',
                    value: 'ONLY_1',
                },
                {
                    text: 'Equal or above 3 stars',
                    value: 'GTE_3',
                },
                {
                    text: 'Less than 3 stars',
                    value: 'LESS_THEN_3',
                },
                {
                    text: 'No rating',
                    value: 'NONE',
                },
            ],
        },
        {
            title: 'TAGS',
            dataIndex: 'tags',
            width: '300px',
            filterMultiple: false,
            hidden: !isDetail,
            filters: tagsOptions,
            render: (tags: string) => {
                return <div>{tags || '-'}</div>;
            },
        },
        {
            title: 'REVIEW DETAIL',
            dataIndex: 'content',
            width: '360px',
            ellipsis: {
                showTitle: false,
            },
            render: (content: string) => (
                <Tooltip
                    placement="topLeft"
                    title={content}
                    overlayClassName={s.customTooltips}
                >
                    {content || '-'}
                </Tooltip>
            ),
        },
        {
            title: 'REVIEW DATE',
            dataIndex: 'submitAt',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            width: '150px',
            className: s.tableColumn,
            render: (time) => {
                return <div>{time ? dayjs(time).format('MM/DD/YYYY') : 'Nil'}</div>;
            },
        },
        {
            title: 'PATIENT EMAIL',
            dataIndex: 'patientEmail',
            key: 'patientEmail',
            width: '220px',
            hidden: !!isDetail,
            filterDropdown: ({ close }: any) => (
                <div style={{ padding: 16 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        placeholder="Search email"
                        value={pendingEmailFilter}
                        onChange={(e) => {
                            setPendingEmailFilter(e.target.value);
                        }}
                        onPressEnter={() => {
                            setEmailFilter(pendingEmailFilter);
                            setPage(1);
                            doFetch(
                                1,
                                pendingNameFilter,
                                ratingFilter,
                                searchOrderKey,
                                searchOrderType,
                                emailFilter,
                                publicFilter,
                                replyFilter,
                                tagsFilter,
                                disputeStatusFilter,
                            );
                            close();
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                setEmailFilter(pendingEmailFilter);
                                setPage(1);
                                doFetch(
                                    1,
                                    pendingNameFilter,
                                    ratingFilter,
                                    searchOrderKey,
                                    searchOrderType,
                                    emailFilter,
                                    publicFilter,
                                    replyFilter,
                                    tagsFilter,
                                    disputeStatusFilter,
                                );
                                close();
                            }}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => {
                                setPendingEmailFilter('');
                                setnameFilter('');
                                setPage(1);
                                doFetch(
                                    1,
                                    '',
                                    ratingFilter,
                                    searchOrderKey,
                                    searchOrderType,
                                    emailFilter,
                                    publicFilter,
                                    replyFilter,
                                    tagsFilter,
                                    disputeStatusFilter,
                                );
                                close();
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
            ),
            render: (name: string, item: IReviewItem) => {
                return (
                    <div className={s.cell}>
                        <div className={s.email}>{item.patientEmail}</div>
                    </div>
                );
            },
        },
        {
            title: 'PUBLIC',
            dataIndex: 'isPublic',
            width: '160px',
            filterMultiple: false,
            filters: [
                {
                    text: 'YES',
                    value: true,
                },
                {
                    text: 'NO',
                    value: false,
                },
            ],
            render: (val: number) => {
                return <div className={s.cell}>{val ? 'YES' : 'NO'}</div>;
            },
        },
        {
            title: 'REPLY',
            dataIndex: 'isNeedReplied',
            width: '120px',
            filterMultiple: false,
            filters: [
                {
                    text: 'Required',
                    value: true,
                },
                {
                    text: 'Not required',
                    value: false,
                },
            ],
            render: (val: string) => {
                return (
                    <div className={s.cell}>{val ? 'Required' : 'Not required'}</div>
                );
            },
        },
        {
            title: 'REPLIED ON',
            dataIndex: 'repliedAt',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            width: '150px',
            className: s.tableColumn,
            render: (time) => {
                return <div>{time ? dayjs(time).format('MM/DD/YYYY') : '-'}</div>;
            },
        },
        {
            title: 'REPLY DETAIL',
            dataIndex: 'replyContent',
            width: '360px',
            ellipsis: {
                showTitle: false,
            },
            render: (replyContent: string) => (
                <Tooltip
                    placement="topLeft"
                    title={replyContent}
                    overlayClassName={s.customTooltips}
                >
                    {replyContent || '-'}
                </Tooltip>
            ),
        },
        {
            title: 'DISPUTE',
            dataIndex: 'disputeStatus',
            width: '360px',
            filters: DisputeStatusFilterOptions.current,
            render: (status: string) => {
                return status === EDISPUTE_STATUS.NORMAL ? 'No dispute' : status;
            },
        },
        {
            title: 'ACTION',
            width: '150px',
            className: s.tableColumn,
            fixed: 'right',
            render: (row) => {
                const { isPublic } = row;
                return isPublic ? (
                    <div
                        className={s.action}
                        onClick={() => {
                            setSelectedReview(row);
                            setShowPrivateSetting(true);
                        }}
                    >Make private
                    </div>
                ) : '-';
            },
        },
    ].filter((item) => !item.hidden) as ColumnsType<IReviewItem>;

    const handleRangeChange = (dates: Date[]) => {
        setDateRangeFilter(
            `${dates?.[0] ? dayjs(dates?.[0]).format('MM/DD/YYYY') : ''} - ${dates?.[1] ? dayjs(dates?.[1]).format('MM/DD/YYYY') : ''}`,
        );
        setCustomSelectedRange(dates);
        doFetch(
            1,
            pendingNameFilter,
            ratingFilter,
            searchOrderKey,
            searchOrderType,
            emailFilter,
            publicFilter,
            replyFilter,
            tagsFilter,
            disputeStatusFilter,
            dates?.[0],
            dates?.[1],
        );
    };

    const handeRangeFocus = (val) => {
        setOpen(true);
    };

    return (
        <div className={s.wrap}>
            <ReviewPrivateSettingPopup
                open={showPrivateSetting}
                reviewItem={selectedReview}
                onCancel={() => setShowPrivateSetting(false)}
                onOk={() => {
                    doFetch(page);
                    setShowPrivateSetting(false);
                }}
            />
            <div className={s.selectBar}>
                <div className={s.selectWrap}>
                    <Select
                        value={dateRangeFilter}
                        style={{ width: 360 }}
                        open={open}
                        onDropdownVisibleChange={(visible) => setOpen(visible)}
                        onChange={(val) => {
                            setCustomSelectedRange(null);
                            setDateRangeFilter(val);
                            doFetch(
                                1,
                                pendingNameFilter,
                                ratingFilter,
                                searchOrderKey,
                                searchOrderType,
                                emailFilter,
                                publicFilter,
                                replyFilter,
                                tagsFilter,
                                disputeStatusFilter,
                                new Date(new Date().getTime() + +val * 24 * 60 * 60 * 1000),
                                new Date(),
                            );
                        }}
                        dropdownRender={(menu) => (
                            <div
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                            >
                                <div>{menu}</div>
                                <div className={s.customSelect}>
                                    <div
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                    >
                                        <RangePicker
                                            allowClear={false}
                                            onFocus={handeRangeFocus}
                                            onPanelChange={handeRangeFocus}
                                            onChange={handleRangeChange}
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        options={[
                            { value: '-1', label: 'Last 1 day' },
                            { value: '-3', label: 'Last 3 days' },
                            { value: '-7', label: 'Last 7 days' },
                            { value: '-14', label: 'Last 2 weeks' },
                            { value: '-30', label: 'Last month' },
                            { value: '-60', label: 'Last 2 months' },
                            { value: '-120', label: 'Last 4 months' },
                            { value: '-180', label: 'Last 6 months' },
                        ]}
                    />
                </div>

                {!isDetail && <OpenGoogleForm sheetType="ALL_REVIEW" />}
            </div>
            <CommonTable
                bordered
                rowKey="id"
                pagination={{
                    total,
                    current: page,
                    pageSize,
                    showSizeChanger: false,
                }}
                scroll={{ x: '1200' }}
                loading={loading}
                columns={columns}
                onChange={onTableChange}
                data={itemList || []}
            />
            {contextHolder}
        </div>
    );
};

export default Index;
