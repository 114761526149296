// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--l8F0J .s-module__header--ffGPZ {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  color: var(--gray-800);
  margin: 10px 0 16px;
}
.s-module__wrap--l8F0J .s-module__title--HImkL {
  color: #111827;
  font-size: 18px;
  font-weight: bold;
}
.s-module__wrap--l8F0J .s-module__colorItem--REdwR {
  display: inline-block;
  width: 56px;
  height: 22px;
  margin-top: 4px;
  border-radius: 4px;
}
.s-module__wrap--l8F0J .s-module__banner--yiTvO {
  width: 150px;
  height: auto;
}
.s-module__wrap--l8F0J .s-module__link--uxY6u {
  color: var(--primary-color);
}
.s-module__wrap--l8F0J .s-module__link--uxY6u:hover {
  color: var(--primary-color);
  cursor: pointer;
}
.s-module__wrap--l8F0J .s-module__carouselTitle--htUQg {
  color: var(--gray-500);
}
.s-module__wrap--l8F0J .s-module__carouselTitle--htUQg span {
  color: var(--primary-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/operation/KiwiHealthPage/components/TeamManagement/s.module.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;EACA,sBAAA;EACA,mBAAA;AAAJ;AARA;EAWI,cAAA;EACA,eAAA;EACA,iBAAA;AAAJ;AAbA;EAgBI,qBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;AAAJ;AApBA;EAuBI,YAAA;EACA,YAAA;AAAJ;AAxBA;EA4BI,2BAAA;AADJ;AA3BA;EA+BI,2BAAA;EACA,eAAA;AADJ;AA/BA;EAmCI,sBAAA;AADJ;AAlCA;EAqCM,2BAAA;AAAN","sourcesContent":[".wrap {\n  .header{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    font-size: 20px;\n    color: var(--gray-800);\n    margin:10px 0 16px;\n  }\n  .title {\n    color: #111827;\n    font-size: 18px;\n    font-weight: bold;\n  }\n  .colorItem{\n    display: inline-block;\n    width: 56px;\n    height: 22px;\n    margin-top: 4px;\n    border-radius: 4px;\n  }\n  .banner {\n    width: 150px;\n    height: auto;\n  }\n\n  .link{\n    color: var(--primary-color);\n  }\n  .link:hover{\n    color: var(--primary-color);\n    cursor: pointer;\n  }\n  .carouselTitle {\n    color: var(--gray-500);\n    span {\n      color: var(--primary-color);;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--l8F0J`,
	"header": `s-module__header--ffGPZ`,
	"title": `s-module__title--HImkL`,
	"colorItem": `s-module__colorItem--REdwR`,
	"banner": `s-module__banner--yiTvO`,
	"link": `s-module__link--uxY6u`,
	"carouselTitle": `s-module__carouselTitle--htUQg`
};
export default ___CSS_LOADER_EXPORT___;
