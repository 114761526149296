import { Button, Spin, Switch } from 'antd';
import React, { } from 'react';
import { ServerMeta } from 'types/common';
import s from './s.module.less';
import { TContentInfo } from 'types/operation';
import EditContentModal from './components/EditContentModal';
import { getHelloKlairtyKlarityPageConditionContent, updateHelloKlairtyKlarityPageConditionContentStatus } from 'api/operation';

type Props = {
    pageId?: string;
    state?: ServerMeta;
    condition?: ServerMeta;
    onPreview: (items:TContentInfo[], enabled: boolean) => void;
};

const ContentManagement = (props: Props) => {
    const { state, condition, pageId, onPreview } = props;
    const [data, setData] = React.useState<TContentInfo[]>([]);
    const [showEdit, setShowEdit] = React.useState(false);
    const [currentEdit, setCurrentEdit] = React.useState<TContentInfo>();
    const [loading, setLoading] = React.useState(false);

    const [enable, setEnable] = React.useState(false);

    React.useEffect(() => {
        if (onPreview) {
            onPreview(data, enable);
        }
    }, [data]);

    const fetchData = async (id:string) => {
        setLoading(true);
        const result = await getHelloKlairtyKlarityPageConditionContent(parseInt(`${id}`, 10));
        if (!result.error) {
            const _data = result.data?.data;
            if (_data && _data.length > 0) {
                const enabled = (_data[0] as TContentInfo).status === 'A';
                setEnable(enabled);
            }
            setData(_data);
        }
        setLoading(false);
    };

    React.useEffect(() => {
        if (pageId) {
            fetchData(pageId);
        }
    }, [pageId]);

    const handleSwich = async (checked:boolean) => {
        setLoading(true);
        const result = await updateHelloKlairtyKlarityPageConditionContentStatus(parseInt(`${pageId}`, 10), checked ? 'A' : 'D');
        setLoading(false);
        if (!result.error) {
            fetchData(pageId!);
            setEnable(checked);
        }
    };

    return (
        <div className={s.wrap}>
            <div className={s.title}>
                <span>
                    Content mgmt
                </span>
                {
                    pageId && <Switch loading={loading} onChange={handleSwich} checked={enable} />
                }
            </div>
            <Spin spinning={loading}>
                {
                    !enable && (
                        <div className={s.disabledWrap}>
                            <div className={s.disImg}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="148" height="147" viewBox="0 0 148 147" fill="none">
                                    <path d="M49.3983 108.956C47.2014 108.813 28.1443 109.391 25.9452 109.488C24.0195 109.572 23.7821 109.828 23.3683 111.673C21.6286 119.429 21.6572 119.857 23.3683 120.096C25.0794 120.335 37.3686 119.709 38.244 119.687C47.1507 119.462 48.3657 119.815 48.7633 118.38C51.4152 108.811 50.9551 109.057 49.3983 108.956V108.956ZM47.1889 117.044C47.1081 117.573 46.6715 117.352 46.4157 117.346C43.3919 117.274 40.374 117.482 36.8607 117.539C23.2389 117.76 23.7726 118.305 24.0349 117.265C25.4285 111.752 24.9008 111.471 25.7872 111.477C29.7525 111.499 44.7215 110.981 48.3333 110.859C49.3248 110.826 47.9055 112.343 47.1889 117.045V117.044Z" fill="#191919" />
                                    <path d="M122.648 56.3826C115.729 55.9122 98.7759 57.2823 98.615 56.1666C97.8586 50.9253 97.4816 46.7174 92.0815 45.5311C84.2597 43.8134 65.6995 44.2765 57.6982 44.5999C48.6445 44.9659 45.6082 45.5995 43.559 43.0931C42.8263 42.1979 41.5518 40.1825 37.3961 39.5445C35.3131 39.2241 32.5671 39.0418 29.6279 38.9683C29.5463 39.6291 29.303 40.2251 28.4871 40.3075C28.0667 40.3494 27.6845 40.1737 27.4126 39.8503C27.2626 39.6717 27.1244 39.3453 27.0113 38.9308C22.3036 38.9117 17.4555 39.1388 14.2546 39.5034C11.2066 39.8503 7.05454 40.7205 6.80096 43.7781C5.5228 59.1712 5.70287 86.9447 5.92558 100.617C6.27617 122.172 7.32649 129.128 15.0028 129.431C24.8489 129.821 38.819 129.398 77.4271 128.728C112.637 128.116 112.17 128.212 112.872 127.118C113.455 126.209 114.567 121.756 115.931 115.533L114.096 113.616C112.724 119.99 111.715 124.453 111.497 124.868C110.676 126.44 110.697 125.676 99.189 125.902L21.3371 127.441C8.42237 127.711 8.54879 130.496 8.76782 81.4175C8.94055 42.7829 6.8752 42.4522 15.0837 41.8987C19.6885 41.5886 38.3964 40.2759 40.9836 44.0964C42.6322 46.5322 44.996 47.5171 47.8147 47.6788C50.8804 47.8544 81.8334 45.4723 91.8838 47.9713C95.3663 48.8379 95.2435 51.5449 96.1049 56.1886C96.3056 57.2705 98.4283 56.2364 28.9744 58.0518C26.9164 58.1055 24.8327 59.8651 24.4829 61.9032C22.3131 74.5401 12.9213 116.268 12.4149 124.391C12.3826 124.913 13.3667 125.124 13.5343 124.634C14.5751 121.591 16.805 110.844 24.3263 73.8676C27.8153 56.7156 23.7074 60.3134 45.0709 60.0025C91.2517 59.3315 120.104 58.6354 121.543 58.6259C123.858 58.6104 125.045 60.345 124.738 62.1017C124.597 62.9043 123.646 67.6428 122.311 74.1866C122.846 75.112 123.331 76.0652 123.765 77.042C125.037 70.7269 126.141 65.3967 126.885 62.2906C127.608 59.2668 125.815 56.5995 122.649 56.3848L122.648 56.3834V56.3826Z" fill="#191919" />
                                    <path d="M93.6938 17.2379C101.658 17.0233 102.367 23.8728 94.1333 23.151C92.4987 23.0077 90.4238 22.3307 89.2551 20.996C88.4834 20.1154 88.8442 18.6704 89.9475 18.0501C90.6891 17.6326 92.2892 17.1431 93.6945 17.2387L93.6938 17.2379ZM96.0906 21.2826C95.9502 21.8412 96.256 21.9588 96.8006 21.781C97.2402 21.6376 99.4716 21.1952 98.2251 19.8913C97.8333 19.4819 97.3129 19.2886 96.8227 19.0416C96.1737 18.7131 96.2766 19.2503 96.0906 21.2826ZM91.6431 21.3319C91.71 20.4499 91.5895 19.6656 91.7784 18.8968C92.0378 17.8414 87.619 19.7737 91.6431 21.3319ZM93.4402 21.3429C93.4071 21.7545 93.5563 21.9103 93.9716 21.9353C94.428 21.9625 94.4413 21.7126 94.4383 21.3811C94.4089 18.6388 94.7537 18.4477 94.0642 18.3617C93.1859 18.2515 93.6159 19.1673 93.4402 21.3429Z" fill="#00816B" />
                                    <path d="M96.9439 9.8679C96.0751 10.1046 95.7267 10.7198 95.468 11.1909C95.2681 11.5555 95.1255 11.9237 94.8175 11.407C92.0951 6.84043 87.935 11.7635 92.8293 16.6424C93.5673 17.3781 94.4507 16.7078 93.729 15.8067C91.9848 13.6304 92.2119 11.5657 92.478 11.4379C93.016 11.1777 93.9186 12.3875 94.2082 12.9946C95.0093 14.6719 94.5933 15.853 95.5672 15.7802C96.1479 15.7369 96.2838 14.9607 96.1449 14.3587C96.0213 13.8601 95.8734 13.3678 95.7017 12.8836C96.0685 11.7657 96.6484 11.0946 97.3231 11.2468C97.7862 11.3519 97.7715 12.461 97.7097 12.9578C97.4562 15.0011 96.1817 16.4778 97.1747 16.8974C99.0129 17.6736 100.49 8.90137 96.9439 9.8679Z" fill="#00816B" />
                                    <path d="M62.3478 22.6621C62.0612 22.7584 61.8847 22.4717 61.7635 22.2733C59.0028 17.7464 58.9367 17.7148 59.3218 17.459C60.6066 16.6035 63.5253 22.2623 62.3478 22.6614V22.6621Z" fill="#00816B" />
                                    <path d="M36.5596 17.9279C34.4773 17.667 37.6481 15.2496 39.698 14.9357C40.5477 14.8056 40.9747 15.4149 40.2441 15.9721C39.1232 16.8269 37.8789 17.4509 36.5596 17.9279Z" fill="#00816B" />
                                    <path d="M55.6084 14.4484C57.2518 16.6189 50.7434 14.3786 50.983 13.4092C51.2432 12.3537 54.9814 13.6208 55.6084 14.4484Z" fill="#00816B" />
                                    <path d="M32.9479 19.9851C33.6403 19.3883 34.6259 19.7352 33.8858 20.8252C33.383 21.5653 31.7771 24.0864 30.8884 23.663C29.7345 23.1132 31.9792 20.8193 32.9479 19.9843V19.9851Z" fill="#00816B" />
                                    <path d="M63.0821 34.0809C63.1373 35.7677 60.6478 38.4586 59.8922 37.6067C59.682 37.3693 59.7342 37.0672 59.8966 36.8379C60.8573 35.4862 63.027 32.4242 63.0814 34.0809H63.0821Z" fill="#00816B" />
                                    <path d="M49.2613 22.1019C49.8419 22.4988 49.2642 23.6432 47.5965 26.4767C47.4693 26.6927 47.2893 26.8515 47.0298 26.739C45.8523 26.2282 48.2852 21.4353 49.2613 22.1027V22.1019Z" fill="#00816B" />
                                    <path d="M47.8173 13.9522C47.5071 14.1852 47.1183 14.1683 46.7604 14.2204C43.3265 14.7202 42.9215 14.7327 42.8509 14.2065C42.6723 12.86 49.9223 12.3638 47.8173 13.9514V13.9522Z" fill="#00816B" />
                                    <path d="M54.2404 16.822C54.236 17.0939 54.186 17.2924 54.0382 17.457C53.3018 18.2802 52.6535 19.1791 51.8465 19.9413C51.135 20.6146 50.3441 20.2147 50.8535 19.2431C51.8832 17.2762 54.1816 15.449 54.2404 16.8212V16.822Z" fill="#00816B" />
                                    <path d="M32.7814 50.8636C33.5341 50.4447 35.3084 54.3181 35.5002 55.1509C35.6354 55.7381 35.0879 55.963 34.7792 55.6154C33.4731 54.1469 32.167 51.2054 32.7814 50.8643V50.8636Z" fill="#00816B" />
                                    <path d="M28.0859 29.9076C28.1881 26.0393 31.0164 24.2555 29.894 27.7261C29.1142 30.1391 29.2289 31.016 28.5042 30.8778C27.9808 30.7778 28.1403 30.2185 28.0859 29.9076Z" fill="#00816B" />
                                    <path d="M71.5708 12.4085C71.0857 12.2144 70.9439 11.4772 71.7472 11.414C76.9407 11.0054 77.5141 14.7877 71.5708 12.4085Z" fill="#00816B" />
                                    <path d="M47.2057 34.6444C47.8459 33.9954 49.5239 36.2158 50.5896 37.1133C50.7822 37.275 50.8219 37.5161 50.7153 37.7483C50.1038 39.0875 46.3928 35.4691 47.2057 34.6444Z" fill="#00816B" />
                                    <path d="M54.8323 39.6769C54.2384 39.7357 52.273 39.4321 52.3171 38.8316C52.3921 37.821 55.1616 38.4891 56.8727 38.3208C57.5378 38.2554 57.6591 39.7247 54.8323 39.6769Z" fill="#00816B" />
                                    <path d="M28.8958 37.5925C28.8414 38.8119 28.9355 39.466 28.4085 39.5182C27.4089 39.6175 27.4677 36.2548 27.7176 34.8451C27.8022 34.3688 28.8142 33.1436 28.8958 37.5925Z" fill="#00816B" />
                                    <path d="M58.093 13.9868C57.447 14.0118 57.1522 13.6583 57.5793 13.1695C58.2393 12.4132 60.8743 11.9406 61.6747 12.006C62.3796 12.0634 62.4854 12.7484 61.9312 12.9321C60.6237 13.3665 59.247 13.5481 58.093 13.9861V13.9868Z" fill="#00816B" />
                                    <path d="M31.5493 47.9178C31.2023 50.8924 28.4836 44.3744 29.5486 43.9018C30.141 43.6394 31.3089 46.8815 31.5493 47.9178Z" fill="#00816B" />
                                    <path d="M46.6826 33.001C45.6256 33.198 45.3603 29.9676 45.9439 29.0599C46.4055 28.3426 47.0089 28.8497 47.017 29.3355C47.0251 29.8206 47.4925 32.8488 46.6826 33.001Z" fill="#00816B" />
                                    <path d="M78.6001 13.0078C80.3524 13.3356 82.5765 14.6586 82.3281 15.2378C82.0767 15.8221 79.4138 14.7167 78.1804 13.8883C77.8174 13.6443 77.8563 13.0541 78.6001 13.0078Z" fill="#00816B" />
                                    <path d="M63.4281 28.6654C63.4281 26.7838 63.2995 26.2539 63.828 26.1649C64.9385 25.9782 64.6298 31.3327 63.7816 31.2298C63.309 31.1725 63.4105 30.6278 63.3995 30.2545C63.398 30.1942 63.3678 28.6654 63.4281 28.6654Z" fill="#00816B" />
                                    <path d="M65.8944 12.3503C64.7817 12.3584 64.1591 12.473 64.0944 11.935C63.8997 10.3246 69.9869 11.3786 68.2788 12.0511C67.51 12.354 66.7 12.3444 65.8944 12.3503Z" fill="#00816B" />
                                    <path d="M84.1163 16.0745C84.5771 15.2917 87.8516 17.3953 87.4458 18.037C87.1261 18.5434 83.6554 16.8566 84.1163 16.0745Z" fill="#00816B" />
                                    <path d="M118.329 107.369C117.715 108.098 118.093 107.983 122.769 112.717C123.66 113.619 126.149 111.805 130.184 115.913C133.564 119.354 139.679 126.854 141.601 131.109C143.163 134.564 141.62 136.494 139.662 136.619C135.009 136.915 128.17 129.625 125.334 126.604C123.701 124.864 119.504 120.815 119.707 117.549C119.736 117.092 119.815 117.31 114.63 111.869C113.55 110.736 113.069 113.8 104.865 116.357C86.2759 122.148 67.2923 106.945 68.5903 87.9275C69.8406 69.6025 86.4685 58.3106 102.59 61.724C123.698 66.1928 131.543 91.6914 118.329 107.368L118.329 107.369ZM96.2418 111.221C108.517 111.366 118.14 101.765 118.434 90.1141C118.753 77.5125 108.935 67.5584 96.3542 67.6253C84.4391 67.6885 75.2641 76.9363 74.7952 88.5721C74.2837 101.252 84.5016 111.083 96.2418 111.222V111.221ZM136.053 128.538C135.652 126.785 128.007 115.22 124.092 115.866C123.417 115.977 123.224 116.625 124.044 116.809C127.293 117.541 133.212 126.856 136.053 128.538ZM121.471 113.7C121.153 113.284 120.314 112.418 117.383 109.184C116.921 108.674 116.883 109.09 115.674 110.174C115.133 110.66 115.316 110.502 119.926 114.964C120.036 115.071 120.131 115.226 120.323 115.192C120.422 115.175 121.479 113.852 121.471 113.7H121.471Z" fill="#00816B" />
                                    <path d="M98.6119 80.9219C97.5454 82.8366 80.0429 94.6495 82.5742 90.3637C83.8994 88.119 96.0129 80.7227 98.6119 80.9219Z" fill="#00816B" />
                                    <path d="M80.9138 86.0733C81.6936 84.1271 94.7854 76.2942 95.419 77.0784C95.8629 77.6289 78.9425 90.9927 80.9138 86.0733Z" fill="#00816B" />
                                </svg>
                            </div>
                            <div className={s.disText}>
                                AI-generated local module not enabled
                            </div>
                        </div>
                    )
                }
                {
                    enable && (
                        <div className={s.itemWrap}>
                            {
                                data?.map((item:TContentInfo) => {
                                    return (
                                        <div className={s.item} key={item.id}>
                                            <div className={s.header}>
                                                <div className={s.left}>
                                                    {item.title}
                                                </div>
                                                <div className={s.right}>
                                                    <Button
                                                        onClick={() => {
                                                            setCurrentEdit(item);
                                                            setShowEdit(true);
                                                        }}
                                                    >
                                                        Edit
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className={s.content}>
                                                <div className={s.label}>Content</div>
                                                <div>
                                                    {
                                                        item.content
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    )
                }
            </Spin>
            <EditContentModal
                onCancel={() => {
                    setShowEdit(false);
                    setCurrentEdit(undefined);
                }}
                onSuccess={() => {
                    setShowEdit(false);
                    fetchData(pageId!);
                }}
                open={showEdit}
                item={currentEdit}
            />
        </div>
    );
};

export default ContentManagement;
