// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--m9waL {
  background: var(--main-bg-color);
  min-height: 100vh;
  padding: 36px 48px;
}
.s-module__wrap--m9waL .s-module__title--gw5CA {
  margin-bottom: 24px;
  color: var(--gray-900);
  font-size: 30px;
  font-weight: 800;
  line-height: 36px;
}
.s-module__wrap--m9waL .s-module__tabTitle--zoNDD {
  text-align: center;
  min-width: 300px;
  color: var(--gray-500);
  font-weight: 500;
}
.s-module__wrap--m9waL :where(.css-dev-only-do-not-override-1uhj8f6).ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.s-module__wrap--m9waL :where(.css-dev-only-do-not-override-1uhj8f6).ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  border-bottom: 1px solid var(--gray-200);
}
.s-module__wrap--m9waL .ant-tabs-tab-active p {
  color: var(--primary-color);
}
.s-module__wrap--m9waL .ant-tabs-nav::before {
  width: 600px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/operation/HelloKlarityProviders/s.module.less"],"names":[],"mappings":"AAAA;EACI,gCAAA;EACA,iBAAA;EACA,kBAAA;AACJ;AAJA;EAMQ,mBAAA;EACA,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AACR;AAXA;EAcQ,kBAAA;EACA,gBAAA;EACA,sBAAA;EACA,gBAAA;AAAR;AAjBA;;EAqBY,wCAAA;AAAZ;AArBA;EAyBY,2BAAA;AADZ;AAxBA;EA6BU,YAAA;AAFV","sourcesContent":[".wrap {\n    background: var(--main-bg-color);\n    min-height: 100vh;\n    padding: 36px 48px;\n\n    .title{\n        margin-bottom: 24px;\n        color: var(--gray-900);\n        font-size: 30px;\n        font-weight: 800;\n        line-height: 36px;\n    }\n\n    .tabTitle{\n        text-align: center;\n        min-width: 300px;\n        color: var(--gray-500);\n        font-weight: 500;\n    }\n    :global{\n        :where(.css-dev-only-do-not-override-1uhj8f6).ant-tabs >.ant-tabs-nav .ant-tabs-nav-wrap, :where(.css-dev-only-do-not-override-1uhj8f6).ant-tabs >div>.ant-tabs-nav .ant-tabs-nav-wrap {\n            border-bottom: 1px solid var(--gray-200);\n        }\n        .ant-tabs-tab-active{\n          p{\n            color: var(--primary-color);\n          }\n        }\n        .ant-tabs-nav::before{\n          width: 600px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--m9waL`,
	"title": `s-module__title--gw5CA`,
	"tabTitle": `s-module__tabTitle--zoNDD`
};
export default ___CSS_LOADER_EXPORT___;
