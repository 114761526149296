import s from './s.module.less';
import React from 'react';
import {
    LoadingOutlined,
    CheckOutlined,
} from '@ant-design/icons';
import { ServerMeta } from 'types/common';
import { findTargetMetaFromListByKey } from 'utils/common';
import { Popover } from 'antd';

type Props = {
    items: ServerMeta[],
    label?: string,
    resetFlag?: Object,
    onChange?: (val: string, display: string | number) => void
    loading?: boolean;
    defaultValue?: string;
    defaultDisplay?: string;
    selectWidth?: string;
    theme?: 'default' | 'rectangle'
    placeholder?: string,
    displayKey?: 'dictKey' | 'content';
    valueKey?: 'dictKey' | 'content';
    hideAny?: boolean;
    supportKeyDown?: boolean,
    addAll?:boolean,
};

const FilterSelect = (props: Props) => {
    const { items, label, resetFlag, addAll, supportKeyDown, hideAny, onChange, selectWidth, loading, defaultValue, valueKey = 'dictKey', displayKey = 'content', theme = 'default', placeholder } = props;
    const [value, setValue] = React.useState<string | undefined>();
    const [display, setDisplay] = React.useState<string | undefined>();
    const [show, setShow] = React.useState(false);
    const ref = React.useRef<HTMLDivElement>(null);
    let claName = show ? `${s.wrap} ${s.wrapShow}` : `${s.wrap}`;
    if (theme === 'rectangle') {
        claName = `${claName} ${s.wrapRectangle}`;
    }

    React.useEffect(() => {
        setShow(false);
    }, [resetFlag]);

    React.useEffect(() => {
        setValue(defaultValue);
        const target = findTargetMetaFromListByKey(defaultValue, items);
        if (target) {
            setDisplay(target[displayKey]);
        } else if (addAll) {
            setDisplay('All');
        } else if (!hideAny) {
            setDisplay('Any');
        }
    }, [defaultValue, items, addAll]);

    React.useEffect(() => {
        if (!supportKeyDown || !show) {
            return;
        }

        const handleKeyDown = (e:KeyboardEvent) => {
            const letterKeyRegex = /^[a-zA-Z]$/;
            if (letterKeyRegex.test(e.key)) {
                const letter = e.key.toLowerCase();
                const childrens = ref.current?.childNodes;
                let target:HTMLBaseElement | undefined;
                childrens?.forEach((item) => {
                    if (item.childNodes[0]) {
                        const val = item.childNodes[0].textContent;
                        if (val?.toLowerCase()?.startsWith(letter) && !target) {
                            target = item as HTMLBaseElement;
                        }
                    }
                });
                if (target) {
                    ref.current?.scrollTo({
                        top: target.offsetTop - 12,
                        behavior: 'smooth',
                    });
                }
            }
        };
        if (document) {
            document.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            document?.removeEventListener('keydown', handleKeyDown);
        };
    }, [supportKeyDown, show]);

    const Content = () => {
        if (loading) {
            return (
                <div className={`${s.optionWrap} ${s.loading}`}>
                    <LoadingOutlined />
                </div>
            );
        }
        return (
            <div className={s.optionWrap} ref={ref} style={{ minWidth: selectWidth || '280px' }}>
                {
                    addAll &&
                    <div
                        onClick={() => {
                            setValue('all');
                            setDisplay('All');
                            onChange?.('all', 'All');
                            window.setTimeout(() => {
                                setShow(false);
                            }, 100);
                        }}
                        className={(!value || value === 'all') ? `${s.option} ${s.opionSelected}` : s.option}
                        key="all"
                    >
                        <span>All</span>
                        <span className={s.selected}><CheckOutlined /></span>
                    </div>
                }
                {
                    items?.map((item: ServerMeta) => {
                        const isSelected = item[valueKey] === value;
                        let displayContent: string | number = item.content;
                        if (displayKey && item[displayKey]) {
                            displayContent = item[displayKey];
                        }
                        return (
                            <div
                                onClick={() => {
                                    setValue(item[valueKey]);
                                    setDisplay(item[displayKey]);
                                    onChange?.(item[valueKey], displayContent);
                                    window.setTimeout(() => {
                                        setShow(false);
                                    }, 100);
                                }}
                                className={isSelected ? `${s.option} ${s.opionSelected}` : s.option}
                                key={item[valueKey]}
                            >
                                <span>{displayContent}</span>
                                <span className={s.selected}><CheckOutlined /></span>
                            </div>
                        );
                    })
                }
            </div>
        );
    };
    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
            }}
            className={claName}
        >

            <Popover
                open={show}
                content={(<Content />)}
                trigger="click"
                arrow={false}
                placement="bottomRight"
                onOpenChange={(open) => {
                    setShow(open);
                }}
            >
                <div
                    className={s.labelWrap}
                >
                    {label && <span className={s.labelDisplay}>{label} : </span>}
                    <span className={s.valueDisplay}>
                        {display && <span>{display}</span>}
                        {!display && placeholder && <span className={s.placeholder}>{placeholder}</span>}
                    </span>
                    <span className={s.switchIcon}>
                        {
                            theme === 'rectangle' &&
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M4.23431 6.01642C4.54673 5.704 5.05327 5.704 5.36569 6.01642L8 8.65074L10.6343 6.01642C10.9467 5.704 11.4533 5.704 11.7657 6.01642C12.0781 6.32884 12.0781 6.83537 11.7657 7.14779L8.56569 10.3478C8.25327 10.6602 7.74673 10.6602 7.43431 10.3478L4.23431 7.14779C3.9219 6.83537 3.9219 6.32884 4.23431 6.01642Z" fill="#9CA3AF" />
                            </svg>
                        }
                        {
                            theme === 'default' &&
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                <path d="M15.0418 7.125L9.50016 12.6667L3.9585 7.125" stroke="#6B7280" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        }
                    </span>
                </div>
            </Popover>
        </div>
    );
};

export default FilterSelect;
