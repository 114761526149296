// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/common/closeIcon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__info--vBZmk {
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  border: 1px solid var(--yellow-600, #D97706);
  background: var(--yellow-50, #FFFBEB);
  padding: 16px;
  margin-top: 16px;
}
.s-module__info--vBZmk .s-module__left--dWg7s {
  flex-direction: 0;
  margin-right: 16px;
}
.s-module__info--vBZmk .s-module__right--CAJjG {
  flex-grow: 1;
}
.s-module__info--vBZmk .s-module__right--CAJjG .s-module__title--pNy4X {
  position: relative;
  color: var(--yellow-800, #92400E);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
}
.s-module__info--vBZmk .s-module__right--CAJjG .s-module__content--Sdzc_ {
  color: var(--yellow-700, #B45309);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  min-height: 0;
  padding-bottom: 0;
  margin-top: 8px;
}
.s-module__info--vBZmk .s-module__closeIcon--rZnPh {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center;
  background-size: 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/pages/operation/HelloKlarityAssessment/components/WarningTips/s.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,4CAAA;EACA,qCAAA;EACA,aAAA;EACA,gBAAA;AACF;AARA;EAUI,iBAAA;EACA,kBAAA;AACJ;AAZA;EAeI,YAAA;AAAJ;AAfA;EAkBQ,kBAAA;EACF,iCAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AAAN;AAzBA;EA6BM,iCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,aAAA;EACA,iBAAA;EACA,eAAA;AADN;AAnCA;EAyCI,WAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,2EAAA;EACA,qBAAA;EACA,4BAAA;EACA,kBAAA;EACA,QAAA;EACA,UAAA;EACA,eAAA;AAHJ","sourcesContent":[".info {\n  display: flex;\n  flex-direction: row;\n  border-radius: 6px;\n  border: 1px solid var(--yellow-600, #D97706);\n  background: var(--yellow-50, #FFFBEB);\n  padding: 16px;\n  margin-top: 16px;\n\n  .left {\n    flex-direction: 0;\n    margin-right: 16px;\n  }\n\n  .right {\n    flex-grow: 1;\n\n    .title {\n        position: relative;\n      color: var(--yellow-800, #92400E);\n      font-family: Inter;\n      font-size: 14px;\n      font-style: normal;\n      font-weight: 500;\n      line-height: 20px;\n      margin-bottom: 8px;\n    }\n\n    .content {\n      color: var(--yellow-700, #B45309);\n      font-size: 14px;\n      font-style: normal;\n      font-weight: 400;\n      line-height: 20px;\n      min-height: 0;\n      padding-bottom: 0;\n      margin-top: 8px;\n    }\n  }\n\n  .closeIcon {\n    content: '';\n    display: block;\n    width: 20px;\n    height: 20px;\n    background: url('assets/common/closeIcon.svg') no-repeat center center;\n    background-size: 100%;\n    background-repeat: no-repeat;\n    position: absolute;\n    top: 0px;\n    right: 0px;\n    cursor: pointer;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info": `s-module__info--vBZmk`,
	"left": `s-module__left--dWg7s`,
	"right": `s-module__right--CAJjG`,
	"title": `s-module__title--pNy4X`,
	"content": `s-module__content--Sdzc_`,
	"closeIcon": `s-module__closeIcon--rZnPh`
};
export default ___CSS_LOADER_EXPORT___;
