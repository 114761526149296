import dayjs from 'dayjs';
import { UniprofileFormData } from 'types/provider';

export type EhrTableItem = {
    email:string,
    providerId: number,
    productCodes: string, //'ADD-ON-P008,ADD-ON-P007,ADD-ON-P005,ADD-ON-P004',
    assistantQty: number,
    physicianQty: number,
    paymentStatus: string, // 'Paid',
    pendingAddonQty: number,
    productNames: string, // 'E-Prescription+ EPCS (Non-prescriber role),Assistant seat,e-Prescription + EPCS (Prescriber role),Collaborating physician seat',
    firstName: string, //'zhengkai',
    lastName: string, //'zou',
    createAt: null | string,
    updateAt: null | string
};

export enum EAddOnProductCodeLabel {
    SELF_EPRESCRIPTION_EPCS = 'self e-prescription epcs',
    SELF_EPRESCRIPTION = 'self e-prescription',
    SELF_EFAX = 'self e-fax',
    COLLABORATING_PHYSICIAN_SEAT = 'Collaborating physician seat',
    COLLABORATING_PHYSICIAN_EPRESCRIPTION_EPCS = 'Collaborating physician e-prescription epcs',
    COLLABORATING_PHYSICIAN_EPRESCRIPTION = 'Collaborating physician e-prescription',
    ASSISTANT_SEAT = 'Assistant seat',
    ASSISTANT_EPRESCRIPTION_EPCS = 'Assistant e-prescription epcs',
    ASSISTANT_EPRESCRIPTION = 'Assistant e-prescription',
}

export enum EAddOnProductCode {
    SELF_EPRESCRIPTION_EPCS = 'ADD-ON-P001',
    SELF_EPRESCRIPTION = 'ADD-ON-P002',
    SELF_EFAX = 'ADD-ON-P003',
    COLLABORATING_PHYSICIAN_SEAT = 'ADD-ON-P004',
    COLLABORATING_PHYSICIAN_EPRESCRIPTION_EPCS = 'ADD-ON-P005',
    COLLABORATING_PHYSICIAN_EPRESCRIPTION = 'ADD-ON-P006',
    ASSISTANT_SEAT = 'ADD-ON-P007',
    ASSISTANT_EPRESCRIPTION_EPCS = 'ADD-ON-P008',
    ASSISTANT_EPRESCRIPTION = 'ADD-ON-P009',
}

export type EhrAddOnItem = {
    id: number;
    description: string;
    price: number;
    productCode: EAddOnProductCode;
    productType: 'ADD_ON';
    productName: string; // 'E-Prescription+ EPCS (Non-prescriber role)';
    priceId: null | string;
    freeTrialDay: number;
    billingPeriod: 'month';
    publishStatus: number;
    seq: number;
    paymentStatus?: null | 'Paid' | 'Failed';
    activeStatus?: null | 'Active' | 'Pending';
    inEhrAddonShoppingCart: boolean;
    inEhrAddonShoppingCartProductQty?: null | number;
    purchasedQty?: number;
    email?:string;
};

export type TAddOnService = {
    eFaxOption: string,
    efax?: string,
    doseSpotOption?: string, //"Y",
    doseSpotName?: string,
};

export type TShoppingCartItem = {
    ehrAddonShoppingCartId: number,
    freeTrialDay: number,
    price: number,
    productCode: EAddOnProductCode,
    productName:string,
    productQty: number,
    priceId: string;
};

export type TShoppingCart = {
    count: number,
    items?: TShoppingCartItem[],
};

export interface IEHROnboardingInfo {
    id: number;
    providerId: number;
    stepType: string;
    currentStep: string;
    endFlag: boolean;
    createAt: string;
    updateAt: string;
    createBy: string;
    updateBy: string;
}

export interface IOnboardingInput {
    stepTypeEnum: string;
    step: string;
    data: {
        id: number;
        practiceName: string;
        practicePhone: string;
        practiceLogo: string;
        taxIdType: string;
        taxId: string;
        w9Form: string;
        setUpLapOption: string;
    }
}

export enum TimeFormFieldEnum {
    TIME_ZONE = 'timezone',
    TIME = 'time',
}

export interface TimeFormInterface {
    [TimeFormFieldEnum.TIME_ZONE]: string;
    [TimeFormFieldEnum.TIME]: string;
}

export interface IEHRCheckoutCartItemInput {
    productCode: EAddOnProductCode;
    productQty: number;
}

export interface IEHRCheckoutInput {
    email?: string;
    paymentMethodId: string;
    couponId?: string;
    country?: string;
    postalCode?: string;
    newCustomerOption?: boolean;
    ehrAddonShoppingCartReqList: IEHRCheckoutCartItemInput[];
}

export type FormType = {
    addonService?: TAddOnService;
    birthDate?: dayjs.Dayjs;
    doseSpotOption: string;
    doseSpotName: string;
    eFaxOption: string;
    efax: string;
};

export interface IAddonPhysician {
    addOnList?: string[];
    address: string;
    birthdayDate: string;
    choice: number;
    deaNumber: string;
    effectiveDate: string;
    email: string;
    expDate?: string;
    id: number;
    licenseState: string;
    name: string;
    npi: string;
    physicianState: string;
    productAdded: boolean;
    productLimitAdded: boolean;
    productPurchased: boolean;
    providerId: number;
    specialities: string;
    state: string;
    tel: string;
    url?: string;
    yearExp?: string;
    zip: string;
}

export interface IAddon {
    activeAt?: string;
    activeBy: number;
    activeStatus: string;
    createAt: string;
    id: number;
    paidAt?: string;
    paymentSn?: string;
    priceId?: string;
    productCode?: string;
    productName?: string;
    productQty?: number;
    productType?: string;
    providerId: number;
    updateAt: string;
}

export interface IAssistant {
    addOnList?: string[];
    assistantEmail: string;
    assistantName: string;
    createAt: string;
    createBy: number;
    id: number;
    productAdded: boolean;
    productLimitAdded: boolean;
    productPurchased: boolean;
    providerId: number;
    updateAt: string;
    updateBy: number;
}

export interface IEFaxInfo {
    createAt: string;
    createBy: number;
    doseSpotName: string;
    doseSpotOption: string;
    eFax: string;
    eFaxOption: string;
    id: number;
    providerId: number;
    updateAt: string;
    updateBy: string;
    kiwiEFax?: string;
}

export interface IEHRProviderInfo extends UniprofileFormData {
    efaxInfo: IEFaxInfo,
    addonPhysicianList: IAddonPhysician[];
    addonList: IAddon[];
    assistantList: IAssistant[];
    hasEhrAccountPwd?: boolean;
}

export const ELAB: Record<string, string> = {
    1: 'Rupa Health',
    2: 'Other Platform',
    3: 'Not Need',
};
