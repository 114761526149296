import React, { useMemo, useRef } from 'react';
import {
    Button,
    Input,
    Modal,
    Space,
    TableProps,
    Select,
    Tooltip,
    DatePicker,
} from 'antd';
import CommonTable from 'components/CommonTable';
import type { ColumnsType } from 'antd/es/table';
import { getDisputeReviewsList } from 'api/operation/reviews';
import s from './s.module.less';
import { SearchOutlined } from '@ant-design/icons';
import { IReviewInput, EOriderType, IReviewItem } from 'types/operation';
import dayjs from 'dayjs';
import useSettings from 'hooks/useSettings';
import { EnumFields } from 'types/enumerationData';
import OpenGoogleForm from '../OpenGoogleForm';
import { EDISPUTE_STATUS } from 'types/common';
import DisputeDetailPopup from '../DisputeDetailPopup';
import { TReviewItem } from 'pages/dashboard/KlarityReviewsDashboradPage/types';

type IProps = {
    type?: 'DETAIL';
    providerId?: number;
    providerSource?: string;
};

const Index: React.FC<IProps> = ({ type, providerId, providerSource }) => {
    const { RangePicker } = DatePicker;
    const isDetail = useMemo(() => type === 'DETAIL', [type]);
    const [open, setOpen] = React.useState(false);
    const [itemList, setItemList] = React.useState<IReviewItem[]>([]);
    const [total, setTotal] = React.useState<number>(0);
    const [loading, setLoading] = React.useState(false);
    const [modal, contextHolder] = Modal.useModal();
    const [setting] = useSettings();
    const pageSize = 10;
    const [dateRangeFilter, setDateRangeFilter] = React.useState('-7');

    const [page, setPage] = React.useState(1);
    const [pendingNameFilter, setPendingNameFilter] = React.useState('');
    const [pendingDisitionNameFilter, setPendingDisitionNameFilter] = React.useState('');
    const [nameFilter, setnameFilter] = React.useState<string>('');
    const [disitionNameFilter, setDisitionNameFilter] = React.useState<string>('');
    const [ratingFilter, setRatingFilter] = React.useState<string[]>([]);
    const [searchOrderType, setOrderType] = React.useState<
    EOriderType | undefined
    >(EOriderType.DESC);
    const [searchOrderKey, setOrderKey] = React.useState<string>('submitAt');
    const disputeResonOptions = setting[EnumFields.REVIEW_DISPUTE_REASON];
    const [customSelectedRange, setCustomSelectedRange] = React.useState<Date[] | null>(null);
    const [showDetailPopup, setShowDetailPopup] = React.useState(false);
    const [selectedReview, setSelectedReview] = React.useState<TReviewItem>();
    const [disputeStatusFilter, setDisputeStatusFilter] = React.useState<EDISPUTE_STATUS[]>();
    const [disputeResonFilter, setDisputeResonFilter] = React.useState<string[]>();

    const disputeResonFilterOptions = useMemo(() => {
        return disputeResonOptions?.map((item) => {
            return {
                text: item.label,
                value: item.value,
            };
        });
    }, [disputeResonOptions]);

    const DisputeStatusFilterOptions = useRef([
        {
            text: 'Under investigation',
            value: EDISPUTE_STATUS.INVESTIGATION,
        },
        {
            text: 'Dispute valid',
            value: EDISPUTE_STATUS.VALID,
        },
        {
            text: 'Dispute invalid',
            value: EDISPUTE_STATUS.INVALID,
        },
    ]);

    const doFetch = async (
        current: number,
        name?: string,
        ratingFilterType?: string[],
        orderField?: string,
        orderType?: string,
        disputeDecisionBy?: string,
        disputeStatusList?: EDISPUTE_STATUS[],
        disputeReasonList?: string[],
        beginDate?: Date,
        endDate?: Date,
    ) => {
        setLoading(true);
        const reqParam: IReviewInput = {
            current,
            size: pageSize,
            name,
            ratingFilterType: ratingFilterType?.[0],
            orderField,
            orderType,
            disputeBeginDate: beginDate,
            disputeEndDate: endDate,
            disputeDecisionBy,
            disputeStatusList,
            disputeReasonList,
        };

        if (!reqParam.ratingFilterType) {
            delete reqParam.ratingFilterType;
        }

        if (!beginDate) {
            if (!!customSelectedRange && customSelectedRange.length > 0) {
                reqParam.disputeBeginDate = customSelectedRange?.[0];
                reqParam.disputeEndDate = customSelectedRange?.[1];
            } else {
                reqParam.disputeBeginDate = new Date(
                    new Date().getTime() + +dateRangeFilter * 24 * 60 * 60 * 1000,
                );
                reqParam.disputeEndDate = new Date();
            }
        }

        const result = await getDisputeReviewsList(reqParam);
        if (!result.error) {
            setItemList(result?.data?.data?.list);
            setTotal(result?.data?.data?.totalRecords);
        }
        setLoading(false);
    };

    const handleCloseDetailPopup = () => {
        setShowDetailPopup(false);
    };

    const onTableChange: TableProps<IReviewItem>['onChange'] = (
        pagination,
        filters: any,
        sorter: any,
        action: any,
    ) => {
        //filter change
        let pageInx = pagination?.current || 1;
        const _ratingFilter = filters.rating || [];
        let _searchOrderType = searchOrderType;
        let _orderKey = searchOrderKey;
        if (action?.action === 'sort') {
            const orderType =
        sorter.order === 'ascend' ? EOriderType.ASC : EOriderType.DESC;
            setOrderType(orderType);
            setOrderKey(sorter.field);
            _searchOrderType = orderType;
            _orderKey = sorter.field;
            pageInx = 1;
        }

        const _disputeStatus = filters.disputeStatus || [];
        const _disputeReaseon = filters.disputeReasonType || [];

        setDisputeStatusFilter(_disputeStatus);
        setDisputeResonFilter(_disputeReaseon);

        setPage(pageInx);
        setRatingFilter(_ratingFilter);

        doFetch(
            pageInx,
            nameFilter,
            _ratingFilter,
            _orderKey,
            _searchOrderType,
            disitionNameFilter,
            _disputeStatus,
            _disputeReaseon,
        );
    };

    React.useEffect(() => {
        doFetch(page);
    }, []);

    const columns: ColumnsType<IReviewItem> = [
        {
            title: 'PROVIDER',
            dataIndex: 'firstName',
            key: 'firstName',
            width: '220px',
            hidden: !!isDetail,
            filterDropdown: ({ close }: any) => (
                <div style={{ padding: 16 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        placeholder="Search name"
                        value={pendingNameFilter}
                        onChange={(e) => {
                            setPendingNameFilter(e.target.value);
                        }}
                        onPressEnter={() => {
                            setnameFilter(pendingNameFilter);
                            setPage(1);
                            doFetch(
                                1,
                                pendingNameFilter,
                                ratingFilter,
                                searchOrderKey,
                                searchOrderType,
                                disitionNameFilter,
                                disputeStatusFilter,
                                disputeResonFilter,
                            );
                            close();
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                setnameFilter(pendingNameFilter);
                                setPage(1);
                                doFetch(
                                    1,
                                    pendingNameFilter,
                                    ratingFilter,
                                    searchOrderKey,
                                    searchOrderType,
                                    disitionNameFilter,
                                    disputeStatusFilter,
                                    disputeResonFilter,
                                );
                                close();
                            }}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => {
                                setPendingNameFilter('');
                                setnameFilter('');
                                setPage(1);
                                doFetch(
                                    1,
                                    '',
                                    ratingFilter,
                                    searchOrderKey,
                                    searchOrderType,
                                    disitionNameFilter,
                                    disputeStatusFilter,
                                    disputeResonFilter,
                                );
                                close();
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
            ),
            render: (name: string, item: IReviewItem) => {
                return (
                    <div className={s.cell}>
                        <div className={s.name}>
                            {name} {item.lastName}
                        </div>
                        <div className={s.email}>{item.email}</div>
                    </div>
                );
            },
        },
        {
            title: 'RATING',
            dataIndex: 'rating',
            width: '120px',
            filterMultiple: false,
            filters: [
                {
                    text: 'All stars',
                    value: '',
                },
                {
                    text: '5 stars only',
                    value: 'ONLY_5',
                },
                {
                    text: '4 stars only',
                    value: 'ONLY_4',
                },
                {
                    text: '3 stars only',
                    value: 'ONLY_3',
                },
                {
                    text: '2 stars only',
                    value: 'ONLY_2',
                },
                {
                    text: '1 stars only',
                    value: 'ONLY_1',
                },
                {
                    text: 'Equal or above 3 stars',
                    value: 'GTE_3',
                },
                {
                    text: 'Less than 3 stars',
                    value: 'LESS_THEN_3',
                },
                {
                    text: 'No rating',
                    value: 'NONE',
                },
            ],
        },
        {
            title: 'REVIEW DETAIL',
            dataIndex: 'content',
            width: '360px',
            ellipsis: {
                showTitle: false,
            },
            render: (content: string) => (
                <Tooltip
                    placement="topLeft"
                    title={content}
                    overlayClassName={s.customTooltips}
                >
                    {content || '-'}
                </Tooltip>
            ),
        },
        {
            title: 'REVIEW DATE',
            dataIndex: 'submitAt',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            width: '150px',
            className: s.tableColumn,
            render: (time) => {
                return <div>{time ? dayjs(time).format('MM/DD/YYYY') : 'Nil'}</div>;
            },
        },
        {
            title: 'DISPUTE REASON',
            dataIndex: 'disputeReasonType',
            key: 'disputeReasonType',
            width: '220px',
            filters: disputeResonFilterOptions,
            // render: (name: string, item: IReviewItem) => {
            //     return (
            //         <div className={s.cell}>
            //             <div className={s.email}>{item.patientEmail}</div>
            //         </div>
            //     );
            // },
        },
        {
            title: 'DISPUTE STATUS',
            dataIndex: 'disputeStatus',
            width: '160px',
            filters: DisputeStatusFilterOptions.current,
            // render: (val: number) => {
            //     return <div className={s.cell}>{val ? 'YES' : 'NO'}</div>;
            // },
        },
        {
            title: 'DECISION MADE BY',
            width: '120px',
            filterDropdown: ({ close }: any) => (
                <div style={{ padding: 16 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        placeholder="Search name"
                        value={pendingDisitionNameFilter}
                        onChange={(e) => {
                            setPendingDisitionNameFilter(e.target.value);
                        }}
                        onPressEnter={() => {
                            setDisitionNameFilter(pendingDisitionNameFilter);
                            setPage(1);
                            doFetch(
                                1,
                                nameFilter,
                                ratingFilter,
                                searchOrderKey,
                                searchOrderType,
                                pendingDisitionNameFilter,
                                disputeStatusFilter,
                                disputeResonFilter,
                            );
                            close();
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                setDisitionNameFilter(pendingDisitionNameFilter);
                                setPage(1);
                                doFetch(
                                    1,
                                    nameFilter,
                                    ratingFilter,
                                    searchOrderKey,
                                    searchOrderType,
                                    pendingDisitionNameFilter,
                                    disputeStatusFilter,
                                    disputeResonFilter,
                                );
                                close();
                            }}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => {
                                setPendingDisitionNameFilter('');
                                setDisitionNameFilter('');
                                setPage(1);
                                doFetch(
                                    1,
                                    nameFilter,
                                    ratingFilter,
                                    searchOrderKey,
                                    searchOrderType,
                                    '',
                                    disputeStatusFilter,
                                    disputeResonFilter,
                                );
                                close();
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
            ),
            render: (val: string, row) => {
                const { disputeDecisionByFirstName, disputeDecisionByLastName } = row;
                let name = `${disputeDecisionByFirstName || ''} ${disputeDecisionByLastName || ''}`;
                if (!disputeDecisionByFirstName && !disputeDecisionByLastName) {
                    name = '-';
                }
                return (
                    <div className={s.cell}>{name}</div>
                );
            },
        },
        {
            title: 'ACTION',
            width: '150px',
            className: s.tableColumn,
            fixed: 'right',
            render: (row) => {
                return (
                    <div
                        className={s.action}
                        onClick={() => {
                            setSelectedReview(row);
                            setShowDetailPopup(true);
                        }}
                    >View
                    </div>
                );
            },
        },
    ].filter((item) => !item.hidden) as ColumnsType<IReviewItem>;

    const handleRangeChange = (dates: Date[]) => {
        setDateRangeFilter(
            `${dates?.[0] ? dayjs(dates?.[0]).format('MM/DD/YYYY') : ''} - ${dates?.[1] ? dayjs(dates?.[1]).format('MM/DD/YYYY') : ''}`,
        );
        setCustomSelectedRange(dates);
        doFetch(
            1,
            pendingNameFilter,
            ratingFilter,
            searchOrderKey,
            searchOrderType,
            pendingNameFilter,
            disputeStatusFilter,
            disputeResonFilter,
            dates?.[0],
            dates?.[1],
        );
    };

    const handeRangeFocus = (val) => {
        setOpen(true);
    };

    return (
        <div className={s.wrap}>
            <DisputeDetailPopup
                open={showDetailPopup}
                reviewItem={selectedReview}
                onCancel={handleCloseDetailPopup}
                onSubmitSuccess={() => {
                    doFetch(page);
                    handleCloseDetailPopup();
                }}
                providerId={selectedReview?.bpProviderId}
            />
            <div className={s.selectBar}>
                <div className={s.selectWrap}>
                    <Select
                        value={dateRangeFilter}
                        style={{ width: 360 }}
                        open={open}
                        onDropdownVisibleChange={(visible) => setOpen(visible)}
                        onChange={(val) => {
                            setDateRangeFilter(val);
                            setCustomSelectedRange(null);
                            doFetch(
                                1,
                                pendingNameFilter,
                                ratingFilter,
                                searchOrderKey,
                                searchOrderType,
                                pendingDisitionNameFilter,
                                disputeStatusFilter,
                                disputeResonFilter,
                                new Date(new Date().getTime() + +val * 24 * 60 * 60 * 1000),
                                new Date(),
                            );
                        }}
                        dropdownRender={(menu) => (
                            <div
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                            >
                                <div>{menu}</div>
                                <div className={s.customSelect}>
                                    <div
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                    >
                                        <RangePicker
                                            allowClear={false}
                                            onFocus={handeRangeFocus}
                                            onPanelChange={handeRangeFocus}
                                            onChange={handleRangeChange}
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        options={[
                            { value: '-1', label: 'Last 1 day' },
                            { value: '-3', label: 'Last 3 days' },
                            { value: '-7', label: 'Last 7 days' },
                            { value: '-14', label: 'Last 2 weeks' },
                            { value: '-30', label: 'Last month' },
                            { value: '-60', label: 'Last 2 months' },
                            { value: '-120', label: 'Last 4 months' },
                            { value: '-180', label: 'Last 6 months' },
                        ]}
                    />
                </div>

                {!isDetail && <OpenGoogleForm sheetType="REVIEW_DISPUTE" />}
            </div>
            <CommonTable
                bordered
                rowKey="id"
                pagination={{
                    total,
                    current: page,
                    pageSize,
                    showSizeChanger: false,
                }}
                scroll={{ x: '1200' }}
                loading={loading}
                columns={columns}
                onChange={onTableChange}
                data={itemList || []}
            />
            {contextHolder}
        </div>
    );
};

export default Index;
