import React, { useEffect } from 'react';
import s from './s.module.less';
import { Button, Form, Select } from 'antd';
import { IEmailListItem } from 'types/operation';

interface IProps {
    emailList?: IEmailListItem[];
    emailTemplateId?: number;
    onSave: (values: any, callback: () => void) => void;
}

const EmailTemplate = ({
    emailTemplateId,
    onSave,
    emailList = [],
}: IProps) => {
    const [formInstance] = Form.useForm();
    const [isEdit, setIsEdit] = React.useState(false);

    useEffect(() => {
        if (emailTemplateId) {
            formInstance.setFieldsValue({ emailTemplateId: Number(emailTemplateId) });
        }
    }, [emailTemplateId, formInstance]);

    const handleStartEdit = React.useCallback(() => {
        setIsEdit(true);
    }, []);

    const handleCancelEdit = React.useCallback(() => {
        setIsEdit(false);
    }, []);

    const handleSave = React.useCallback(() => {
        formInstance.validateFields().then((values) => {
            onSave(values, () => setIsEdit(false));
        }).catch((e) => {
            console.error(e);
        });
    }, [formInstance, onSave]);

    const ButtonRender = React.useMemo(() => {
        if (isEdit) {
            return (
                <>
                    <Button className={s.btn} onClick={handleCancelEdit}>Cancel</Button>
                    <Button htmlType="submit" form="pmform" className={s.saveBtn} onClick={handleSave}>Save</Button>
                </>
            );
        }

        return <Button className={s.btn} onClick={handleStartEdit}>Edit</Button>;
    }, [handleCancelEdit, handleSave, handleStartEdit, isEdit]);

    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <div className={s.title}>Email template</div>
                <div className={s.btns}>
                    {ButtonRender}
                </div>
            </div>
            <div className={s.body}>
                {
                    isEdit ? (
                        <Form
                            id="pmform"
                            form={formInstance}
                            layout="vertical"
                        >
                            <Form.Item
                                name="emailTemplateId"
                                label="Email template name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select email template name',
                                    },
                                ]}
                            >
                                <Select options={emailList.map((item) => ({ label: item.title, value: item.id }))} />
                            </Form.Item>
                        </Form>
                    ) : (
                        <div className={s.row}>
                            <div className={s.item}>
                                <div className={s.label}>Email template name</div>
                                <div className={s.value}>{emailList.find((e) => e.id === Number(emailTemplateId))?.title}</div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default EmailTemplate;
