// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--gS221 .s-module__header--a_snL {
  margin-bottom: 24px;
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
  color: var(--gray-900);
}
`, "",{"version":3,"sources":["webpack://./src/pages/operation/KlarityProgramDetailPage/components/PackageDetail/s.module.less"],"names":[],"mappings":"AAAA;EAEQ,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,sBAAA;AAAR","sourcesContent":[".wrap {\n    .header {\n        margin-bottom: 24px;\n        font-family: Inter;\n        font-size: 24px;\n        font-weight: 700;\n        line-height: 40px;\n        text-align: left;\n        color: var(--gray-900);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--gS221`,
	"header": `s-module__header--a_snL`
};
export default ___CSS_LOADER_EXPORT___;
