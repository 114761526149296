import React, { useState, useEffect, useCallback } from 'react';
import { Button, message, Tabs } from 'antd';
import CreateAndEditCarousels from '../CreateAndEditCarousels';
import CarouselsDetail from '../CarouselsDetail';
import {
    type TCarousel,
    type TStat,
    type TObjectAction,
    type TPageManagement,
    EPageStatus,
    ECarouselsStatus,
    ECarouselsStatusLabel,
} from 'types/helloKlarity';
import { RightOutlined } from '@ant-design/icons';
import s from './s.module.less';
import { getKlarityCarouselsListStat } from 'api/operation';
// import useKlarityCarousels from 'hooks/useKlarityCarousels';
import { useParams } from 'react-router-dom';

type IProps = {
    isLoading: boolean;
    pageName: string;
    goLiveObj: TPageManagement;
    onSuccess: (obj: TObjectAction) => void;
    onPreview: (previewItem: TCarousel | undefined) => void;
};

const CarouselsManagement: React.FC<IProps> = (props) => {
    const {
        pageName,
        goLiveObj,
        isLoading,
        onSuccess,
        onPreview,
    } = props;
    const [item, setItem] = useState<TCarousel>();
    const [itemStatus, setItemStatus] = useState<EPageStatus>(EPageStatus.ADD);
    const [itemIndex, setItemIndex] = useState<number>(0);
    const [currentStatus, setCurrentStatus] = useState<ECarouselsStatus>(
        ECarouselsStatus.ACTIVE,
    );
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [isShowDetail, setIsShowDetail] = useState<boolean>(false);
    const { id = '', type = 'HOME' } = useParams();
    const [statList, setStatList] = useState<TStat>({} as TStat);
    // const { carousels, isLoading, fetchData } = useKlarityCarousels({
    //     pageId: id,
    //     status: currentStatus,
    // });
    const [tabActiveKey, setTabActiveKey] = useState('ACTIVE');

    const handleTabClick = useCallback(
        (key: 'ACTIVE' | 'INACTIVATE') => {
            setCurrentStatus(ECarouselsStatus[key]);
            // fetchData({
            //     id,
            //     status: ECarouselsStatus[key],
            // });
            setTabActiveKey(key);
        },
        [goLiveObj.carousels],
    );

    const handleAdd = () => {
        setIsEdit(false);
        setIsOpen(true);
        setItem(undefined);
        setItemStatus(EPageStatus.ADD);
    };

    const handleEdit = (currentItem: TCarousel, index: number) => {
        setIsEdit(true);
        setIsOpen(true);
        setItem(currentItem);
        setItemIndex(index);
        setItemStatus(EPageStatus.EDIT);
    };

    const fetchStatData = useCallback(async () => {
        setStatList({
            A: (goLiveObj.carousels || []).filter(
                (carouselsItem: TCarousel) => carouselsItem.status === 'A',
            ).length,
            D: (goLiveObj.carousels || []).filter(
                (carouselsItem: TCarousel) => carouselsItem.status === 'D',
            ).length,
        });
        // const { data, error } = await getKlarityCarouselsListStat(id);
        // if (!error) {
        //     setStatList(data.data);
        // }
    }, [goLiveObj]);

    const handleSuccess = (carouselItem: TObjectAction) => {
        setIsOpen(false);
        onSuccess(carouselItem);
        // fetchStatData();
        // fetchData({
        //     id,
        //     status: currentStatus,
        // });
        // message.success('Changes published successfully');
    };

    const handleEnterDetail = (status: ECarouselsStatus) => {
        setCurrentStatus(status);
        setIsShowDetail(true);
        setTabActiveKey(ECarouselsStatusLabel[status].toLocaleUpperCase());
    };

    const handleBack = () => {
        onPreview(undefined);
        setIsShowDetail(false);
    };

    const handleDelete = (obj: TObjectAction) => {
        onSuccess(obj);
        // fetchStatData();
        // fetchData({
        //     id,
        //     status: currentStatus,
        // });
        // message.success('Carousel removed successfully');
    };

    const handleExchange = (obj: TObjectAction) => {
        onSuccess(obj);
    };

    useEffect(() => {
        fetchStatData();
    }, [fetchStatData]);

    // useEffect(() => {
    //     fetchData({
    //         id,
    //         status: currentStatus,
    //     });
    // }, [currentStatus]);

    const renderHomeCarousels = () => {
        return (
            <>
                {!isShowDetail && (
                    <>
                        <Button onClick={handleAdd} type="primary">
                            Add new carousel
                        </Button>
                        <ul className={s.list}>
                            <li
                                className={s.item}
                                onClick={() => handleEnterDetail(ECarouselsStatus.ACTIVE)}
                            >
                                Active carousels ({statList.A || 0})
                                <RightOutlined />
                            </li>
                            <li
                                className={s.item}
                                onClick={() =>
                                    handleEnterDetail(ECarouselsStatus.INACTIVATE)
                                }
                            >
                                Inactive carousels ({statList.D || 0})
                                <RightOutlined />
                            </li>
                        </ul>
                    </>
                )}
                {isShowDetail && currentStatus === ECarouselsStatus.ACTIVE && (
                    <CarouselsDetail
                        isShowBack
                        list={(goLiveObj.carousels || []).filter(
                            (carouselsItem: TCarousel) =>
                                carouselsItem.status === ECarouselsStatus.ACTIVE,
                        )}
                        isLoading={isLoading}
                        status={currentStatus}
                        pageName={pageName}
                        onBack={handleBack}
                        onAdd={handleAdd}
                        onDelete={handleDelete}
                        onEdit={handleEdit}
                        onSelect={onPreview}
                        onExchange={handleExchange}
                        onRefresh={() => {
                            // fetchData({
                            //     id,
                            //     status: currentStatus,
                            // });
                        }}
                    />
                )}
                {isShowDetail && currentStatus === ECarouselsStatus.INACTIVATE && (
                    <CarouselsDetail
                        isShowBack
                        list={(goLiveObj.carousels || []).filter(
                            (carouselsItem: TCarousel) =>
                                carouselsItem.status === ECarouselsStatus.INACTIVATE,
                        )}
                        isLoading={isLoading}
                        status={currentStatus}
                        pageName={pageName}
                        onBack={handleBack}
                        onAdd={handleAdd}
                        onDelete={handleDelete}
                        onEdit={handleEdit}
                        onSelect={onPreview}
                        onExchange={handleExchange}
                        onRefresh={() => {
                            // fetchData({
                            //     id,
                            //     status: currentStatus,
                            // });
                        }}
                    />
                )}
            </>
        );
    };

    const tabItems = [
        {
            key: 'ACTIVE',
            label: `Active carousels (${statList.A || 0})`,
            children: (
                <CarouselsDetail
                    isShowBack={false}
                    list={(goLiveObj.carousels || []).filter(
                        (carouselsItem: TCarousel) =>
                            carouselsItem.status === ECarouselsStatus.ACTIVE,
                    )}
                    isLoading={isLoading}
                    status={ECarouselsStatus.ACTIVE}
                    pageName={pageName}
                    onBack={handleBack}
                    onAdd={handleAdd}
                    onDelete={handleDelete}
                    onEdit={handleEdit}
                    onSelect={onPreview}
                    onExchange={handleExchange}
                    onRefresh={() => {
                        // fetchData({
                        //     id,
                        //     status: ECarouselsStatus.ACTIVE,
                        // });
                    }}
                />
            ),
        },
        {
            key: 'INACTIVATE',
            label: `Inactive carousels (${statList.D || 0})`,
            children: (
                <CarouselsDetail
                    isShowBack={false}
                    list={(goLiveObj.carousels || []).filter(
                        (carouselsItem: TCarousel) =>
                            carouselsItem.status === ECarouselsStatus.INACTIVATE,
                    )}
                    isLoading={isLoading}
                    status={ECarouselsStatus.INACTIVATE}
                    pageName={pageName}
                    onBack={handleBack}
                    onAdd={handleAdd}
                    onDelete={handleDelete}
                    onEdit={handleEdit}
                    onSelect={onPreview}
                    onExchange={handleExchange}
                    onRefresh={() => {
                        // fetchData({
                        //     id,
                        //     status: ECarouselsStatus.INACTIVATE,
                        // });
                    }}
                />
            ),
        },
    ];

    const renderOtherCarousels = () => {
        return (
            <div className={s.tabs}>
                <Tabs
                    defaultActiveKey="ACTIVE"
                    activeKey={tabActiveKey}
                    items={tabItems as any}
                    onTabClick={handleTabClick}
                />
            </div>
        );
    };

    return (
        <div className={s.wrap}>
            {type === 'HOME' && renderHomeCarousels()}
            {['CONDITION', 'STATE'].includes(type) && renderOtherCarousels()}
            {isOpen && (
                <CreateAndEditCarousels
                    tabActiveKey={tabActiveKey}
                    statList={statList}
                    item={item}
                    itemStatus={itemStatus}
                    itemIndex={itemIndex}
                    onCancel={() => setIsOpen(false)}
                    onSuccess={handleSuccess}
                />
            )}
        </div>
    );
};

export default CarouselsManagement;
