import TinyStore from 'lib/tinyStore';
import { UniprofileFormData } from 'types/provider';
import { initApplicationFormData } from 'store/ChannelApplicationProvider';

type DataModal = {
    loading?: boolean,
    data?: UniprofileFormData,
};

const Store = TinyStore.createStore<DataModal>({
    nameSpace: 'klarity',
    key: 'uniprofileResume',
    crossBundle: false,
    runTime: true,
    initValue: { data: { ...initApplicationFormData }, loading: false },
});
export default Store;
