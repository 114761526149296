import TinyStore from 'lib/tinyStore';
import { ICampaign } from 'types/campaign';

type CampaigneModal = {
    selectedCampaign?: ICampaign | null;
    forceRefetchCampaignList?: Record<any, any> | null;
};

const Store = TinyStore.createStore<CampaigneModal>({
    nameSpace: 'box_practice_campaign_store',
    key: 'campaignStore',
    crossBundle: false,
    runTime: true,
    initValue: { selectedCampaign: null },
});
export default Store;
