import React, { useEffect, useState, useMemo } from 'react';
import { ECarouselsStatus, TObjectAction, type TCarousel } from 'types/helloKlarity';
import { LeftOutlined } from '@ant-design/icons';
import { Button, Spin, Tabs, Tooltip } from 'antd';
import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import {
    arrayMove,
    horizontalListSortingStrategy,
    SortableContext,
    useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import s from './s.modules.less';
import CarouselsCard from '../CarouselsCard';
import CarouselsEmpty from '../CarouselsEmpty';
import { useParams } from 'react-router-dom';
import { klarityCarouselsExchange } from 'api/operation';
import cx from 'classnames';
import { MAX_CAROUSEL_LENGTH } from 'constants/carousels';

type IProps = {
    status: ECarouselsStatus;
    list: TCarousel[];
    isLoading: boolean;
    isShowBack: boolean;
    pageName: string;
    onBack: () => void;
    onAdd: () => void;
    onDelete: (item: TObjectAction) => void;
    onExchange: (item: TObjectAction) => void;
    onEdit: (item: TCarousel, index: number) => void;
    onSelect: (item: TCarousel | undefined) => void;
    onRefresh: () => void;
};
interface DraggableTabPaneProps extends React.HTMLAttributes<HTMLDivElement> {
    'data-node-key': string;
}

type TTabs = {
    key: string;
    label: React.ReactNode
};

const DraggableTabNode = ({ className, ...props }: DraggableTabPaneProps) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
        id: props['data-node-key'],
    });

    const style: React.CSSProperties = {
        ...props.style,
        transform: CSS.Transform.toString(transform && { ...transform, scaleX: 1 }),
        transition,
        cursor: 'grab',
        // border: '2px solid #00816B',
        // background: '#F3FAF8',
    };

    return React.cloneElement(
        props.children as React.ReactElement,
        {
            ref: setNodeRef,
            style,
            ...attributes,
            ...listeners,
        },
    );
};

const CarouselsDetail: React.FC<IProps> = (props) => {
    const {
        status,
        list,
        isLoading,
        isShowBack,
        pageName,
        onBack,
        onAdd,
        onDelete,
        onEdit,
        onSelect,
        onRefresh,
        onExchange,
    } = props;
    const [activeIndex, setActiveIndex] = useState<string>('');
    const [currentHandler, setCurrentHandler] = useState<string>('');
    const [draggableItems, setDraggableItems] = useState<TTabs[]>([] as TTabs[]);
    const [undraggableItems, setUndraggableItems] = useState<TTabs[]>(
        [] as TTabs[],
    );
    const [selectItem, setSelectItem] = useState<TCarousel>({} as TCarousel);
    const currentIndex = useMemo(() => {
        const index = list.findIndex((item) => item.id === selectItem.id);
        return index > -1 ? index : 0;
    }, [list, selectItem]);

    const sensor = useSensor(PointerSensor, {
        activationConstraint: { distance: 10 },
    });
    const { type } = useParams();

    const carouselWith = useMemo(() => {
        if (type === 'HOME') {
            return '504px';
        } else {
            return list.length <= 2 ? '236px' : '324px';
        }
    }, [list.length, type]);

    const handlePreview = (tabKey: string) => {
        if (list.length > 0) {
            const index = Number(tabKey.substring(4));
            const item = list.find((listItem) => listItem.id === index) || list[0];
            setActiveIndex(`tab-${item.id}`);
            setSelectItem(item);
            onSelect(item);
        } else {
            onSelect(undefined);
        }
    };

    const onDragStart = ({ active }: DragStartEvent) => {
        handlePreview(`${active.id}`);
    };

    const onDragEnd = async ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id) {
            setDraggableItems((prev) => {
                const currentId = prev.findIndex((i) => i.key === active.id);
                const overId = prev.findIndex((i) => i.key === over?.id);
                return arrayMove(prev, currentId, overId);
            });

            const fromId = `${active.id}`.substring(4);
            const toId = `${over?.id}`.substring(4);

            const { error } = await klarityCarouselsExchange(fromId, toId);
            if (!error) {
                onExchange({
                    type: 'CAROUSEL_EXCHANGE',
                    fromId,
                    toId,
                });
                onRefresh();
            }
        }
    };

    const handleDelete = (obj: TObjectAction) => {
        setCurrentHandler('DELETE');
        onDelete(obj);
    };

    const handleTabClick = (key: string) => {
        handlePreview(key);
    };

    const handlePrev = () => {
        if (currentIndex - 1 >= 0) {
            const item = list[currentIndex - 1];
            handlePreview(`tab-${item.id}`);
        }
    };

    const handleNext = () => {
        if (currentIndex + 1 < list.length) {
            const item = list[currentIndex + 1];
            handlePreview(`tab-${item.id}`);
        }
    };

    useEffect(() => {
        // handle refresh id when updating status
        if (list.length > 0) {
            const index = Number(activeIndex.substring(4));
            const item = list.find((listItem) => listItem.id === index);
            if (!item) {
                handlePreview(`tab-${list[0].id}`);
            } else {
                handlePreview(`tab-${item.id}`);
            }
        }
    }, [list]);

    useEffect(() => {
        if (list.length > 0) {
            if (!activeIndex || currentHandler === 'DELETE') {
                handlePreview(`tab-${list[0].id}`);
                setCurrentHandler('');
                return;
            }
            if (currentHandler === 'ADD') {
                handlePreview(`tab-${list[list.length - 1].id}`);
                setCurrentHandler('');
                return;
            }

            // edit change status situation
            handlePreview(`tab-${list[0].id}`);
            setCurrentHandler('');
        } else {
            onSelect(undefined);
        }
    }, [list.length]);

    useEffect(() => {
        if (list.length > 0) {
            const arr = list.map((item, index) => {
                return {
                    key: `tab-${item.id}`,
                    label: (
                        <div className={s.infoWrap}>
                            <div className={s.infoBox}>
                                <h4 className={s.carouselTitle}>Carousel {index + 1}</h4>
                                <p className={s.carouselText}>{item.title}</p>
                            </div>
                        </div>
                    ),
                };
            });

            /*
            * The first item cannot be dragged when type is CONDITION or STATE
            */

            if (type !== 'HOME') {
                const firstItem = arr.slice(0, 1);
                const resetOfArray = arr.slice(1);
                setUndraggableItems(firstItem);
                setDraggableItems(resetOfArray);
            } else {
                setDraggableItems(arr);
            }
        }
    }, [list]);

    const renderActiveCarousel = () => {
        return (
            <>
                <div className={s.sortList}>
                    {undraggableItems.map((item, index) => {
                        return (
                            <Tooltip
                                title="You cannot change the order of carousel 1"
                                placement="bottom"
                            >
                                <div
                                    key={index}
                                    className={`${s.sortItem} ${
                                        item.key === activeIndex ? s.active : ''
                                    } ${list.length <= 2 ? s.halfWidth : s.oneThirdWidth}`}
                                    onClick={() => {
                                        handlePreview(item.key);
                                    }}
                                >
                                    {item.label}
                                </div>
                            </Tooltip>
                        );
                    })}
                    <Tabs
                        style={{
                            width: carouselWith,
                        }}
                        items={draggableItems}
                        onTabClick={handleTabClick}
                        animated={false}
                        activeKey={activeIndex}
                        renderTabBar={(tabBarProps, DefaultTabBar) => (
                            <DndContext
                                sensors={[sensor]}
                                onDragEnd={onDragEnd}
                                onDragStart={onDragStart}
                            >
                                <SortableContext
                                    items={draggableItems.map((i) => i.key)}
                                    strategy={horizontalListSortingStrategy}
                                >
                                    <DefaultTabBar {...tabBarProps}>
                                        {(node) => (
                                            <DraggableTabNode {...node.props} key={node.key}>
                                                {node}
                                            </DraggableTabNode>
                                        )}
                                    </DefaultTabBar>
                                </SortableContext>
                            </DndContext>
                        )}
                    />
                </div>
                {list.length >= MAX_CAROUSEL_LENGTH && (
                    <div className={s.tips}>
                        A maximum of {MAX_CAROUSEL_LENGTH} carousels can be added per
                        page. Remove or inactivate existing carousels to add new ones.
                    </div>
                )}
            </>
        );
    };

    const renderDeactiveCarousel = () => {
        return (
            <div className={s.swiperHeader}>
                <div className={cx(s.swiperBtn, s.prev)} onClick={() => handlePrev()} />
                <div className={s.subTitle}>
                    Inactive carousel {currentIndex + 1} / {list.length || 0}
                </div>
                <div className={cx(s.swiperBtn, s.next)} onClick={() => handleNext()} />
            </div>
        );
    };

    const renderBack = (
        <div className={s.back} onClick={onBack}>
            <LeftOutlined />
            back
        </div>
    );

    return (
        <div className={s.wrap}>
            {isShowBack && renderBack}
            <div className={s.header}>
                <h4 className={s.title}>
                    {status === ECarouselsStatus.ACTIVE
                        ? 'Active carousels'
                        : 'Inactive carousels'}
                </h4>
                <Button
                    onClick={() => {
                        onAdd();
                        setCurrentHandler('ADD');
                    }}
                    type="primary"
                    disabled={status === ECarouselsStatus.ACTIVE && list.length >= 3}
                >
                    Add new carousel
                </Button>
            </div>
            <Spin spinning={isLoading}>
                <div className={s.carouselWrap}>
                    {list.length <= 0 && !isLoading && (
                        <CarouselsEmpty status={status} />
                    )}
                    {list.length > 0 && !isLoading && (
                        <div className={s.sortWrap}>
                            {status === ECarouselsStatus.ACTIVE && renderActiveCarousel()}
                            {status === ECarouselsStatus.INACTIVATE &&
                  renderDeactiveCarousel()}
                            <CarouselsCard
                                isShowRemove={
                                    (list.length > 1 &&
                      status === ECarouselsStatus.ACTIVE &&
                      (type === 'HOME' ||
                        (['CONDITION', 'STATE'].includes(type) &&
                          currentIndex !== 0))) ||
                    status === ECarouselsStatus.INACTIVATE
                                }
                                item={selectItem}
                                onDelete={handleDelete}
                                onEdit={onEdit}
                                index={currentIndex}
                                pageName={pageName}
                            />
                        </div>
                    )}
                </div>
            </Spin>
        </div>
    );
};

export default CarouselsDetail;
