import React, { useState } from 'react';
import s from './s.module.less';
import type { ColumnsType } from 'antd/es/table';
import { Button, message, Space, Modal } from 'antd';
import CommonTable from 'components/CommonTable';
import { useRequest } from 'ahooks';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { serviceTemplateList, serviceTemplateDelete } from 'api/operation';
import { useNavigate } from 'react-router-dom';
import { Color } from 'types/calendar';
import ServiceUpdateModal from 'components/ServiceUpdateModal';
import useCalendarColors from 'hooks/useCalendarColors';
import useIntakeqForm from 'hooks/useIntakeqForm';
import { IntakeqForm, Service } from 'types/common';

const Page = () => {
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const [modal, contextHolder] = Modal.useModal();
    const [serviceItem, setServiceItem] = useState<Service>();
    const [IntakeqForms] = useIntakeqForm({
        isOps: true,
    });
    const [colors] = useCalendarColors({
        useAdminApi: true,
    });
    const [openServicesModal, setOpenServicesModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const { data, loading, run } = useRequest(serviceTemplateList);

    const listData:Service[] = data?.data?.data || [];

    const getEventColor = (record: Service, color:Color[]):string => {
        const target = color?.find((i) => {
            return i.dictKey === record.colorId;
        });
        return target ? target.content : '#4499df';
    };

    const getIntakeName = (record: Service, name: IntakeqForm[]): string => {
        const target = name?.find((i) => {
            return i.id === record.sendForm;
        });
        return target ? target.name : '';
    };

    const deleteFunc = async (record: Service) => {
        modal.confirm({
            width: '600px',
            closable: true,
            title: `Do you confirm remove this service - ${record.name}?`,
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: 'Yes, confirm',
            cancelText: 'Cancel',
            onOk: async () => {
                const id = record?.id;
                try {
                    await serviceTemplateDelete(id!).finally(() => run());
                } catch (error) {
                    console.error(error);
                }
            },
        });
    };

    const addNewService = () => {
        setIsEdit(false);
        setOpenServicesModal(true);
        setServiceItem(undefined);
    };

    const editService = (record: Service) => {
        setServiceItem(record);
        setIsEdit(true);
        setOpenServicesModal(true);
    };

    const onSuccess = () => {
        setOpenServicesModal(false);
        run();
        message.success(isEdit ? 'Update success' : 'Add success');
    };

    const columns: ColumnsType<Service> = [
        {
            title: 'TYPE',
            dataIndex: 'type',
            key: 'type',
            align: 'left',
            width: '180px',
        },
        {
            title: 'NAME',
            dataIndex: 'name',
            key: 'name',
            align: 'left',
            width: '200px',
        },
        {
            title: 'DESCRIPTION',
            dataIndex: 'description',
            key: 'description',
            align: 'left',
            width: '250px',
        },
        {
            title: 'DURATION',
            dataIndex: 'duration',
            key: 'duration',
            align: 'center',
            width: '160px',
            render: (text) => (
                <span>{text} mins</span>
            ),
        },
        {
            title: 'PRICE',
            dataIndex: 'price',
            key: 'price',
            width: '180px',
            render: (price, item) => (
                <div>
                    <p>{price}</p>
                    {
                        item.enableSlidingScale &&
                        <p style={{ fontSize: 10, fontWeight: 'bold' }}>[{item.lowestPrice?.toFixed(2)} ~ {item.highestPrice?.toFixed(2)}]
                        </p>
                    }
                </div>
            ),
        },
        {
            title: 'COLOR',
            dataIndex: 'color',
            key: 'color',
            align: 'center',
            width: '160px',
            render: (_, record) => (
                <span className={s.colorItem} style={{ background: getEventColor(record, colors) }} />
            ),
        },
        {
            title: '',
            align: 'center',
            render: (_, record: Service) => (
                <Space size="small">
                    <Button className="text-indigo-600" type="text" onClick={() => editService(record)}>Edit</Button>
                    <Button className="text-indigo-600" type="text" onClick={() => deleteFunc(record)}>Remove</Button>
                </Space>
            ),
        },
        // {
        //     title: 'Intake form',
        //     dataIndex: 'sendForm',
        //     key: 'channels',
        //     align: 'center',
        //     width: '160px',
        //     render: (_, record) => (
        //         <span>{getIntakeName(record, IntakeqForms)}</span>
        //     ),
        // },
    ];
    return (
        <div className={s.wrap}>
            <div className={s.header}>
                {/* <h1>Services Template Management</h1> */}
                <div className={s.desWrap}>
                    <div className={s.des}>Initialize default services template for all provider</div>
                    <Button
                        onClick={addNewService}
                        icon={<PlusOutlined />}
                    >
                        Add a new service template
                    </Button>
                </div>
            </div>
            <div className={s.content}>
                <div className={s.tableWrap}>
                    <CommonTable
                        bordered
                        rowKey="id"
                        loading={loading}
                        columns={columns}
                        data={listData || []}
                    />
                </div>
            </div>
            {
                openServicesModal &&
                    <ServiceUpdateModal
                        isTemplate
                        isOps
                        isEdit={isEdit}
                        serviceItem={serviceItem}
                        onCancel={() => setOpenServicesModal(false)}
                        onSuccess={onSuccess}
                    />
            }
            {contextHolder}
        </div>
    );
};

export default Page;
