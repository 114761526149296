import { Button, Radio, RadioChangeEvent, Space } from 'antd';
import React, { useCallback, useState } from 'react';
import s from './s.module.less';

interface IProps {
    onClick: () => void;
}

const AddInformationCard = ({
    onClick,
}: IProps) => {
    const [value, setValue] = useState('0');

    const handleChange = useCallback((e: RadioChangeEvent) => {
        setValue(e.target.value);
    }, []);

    return (
        <div className={s.wrap}>
            <div className={s.title}>Have you added the provider&apos;s information to the Channel correctly?</div>
            <Space direction="vertical">
                <Radio.Group value={value} onChange={handleChange}>
                    <Space direction="vertical">
                        <Radio value="0">No, our team is still working on it.</Radio>
                        <Radio value="1">Yes, all information has been added to the channel and is awaiting channel approval. </Radio>
                    </Space>
                </Radio.Group>
                { value === '1' && <Button className={s.approveBtn} type="primary" ghost onClick={onClick}>Update status to Awaiting approval</Button> }
            </Space>
        </div>
    );
};

export default AddInformationCard;
