import { UNIPROFILE } from 'constants/common';
import { PATH } from 'constants/path';
import { setStartEditSurveyForm, setStartEditReview } from './localstore';

export const openCreateUniProfilePage = (uniproflePath: string, replace?:boolean) => {
    window.navigate(`${uniproflePath}?type=${UNIPROFILE}`, { replace: !!replace });
};

export const openConfigSurveyPage = (replace?:boolean) => {
    setStartEditSurveyForm('1');
    window.navigate(`${PATH.REPUTATION_CONFIG_PAGE}`, { replace: !!replace });
};

export const openConfigReviewInvitationPage = (replace?:boolean) => {
    setStartEditReview('1');
    window.navigate(`${PATH.REPUTATION_CONFIG_PAGE}`, { replace: !!replace });
};

export default {
    openCreateUniProfilePage,
    openConfigSurveyPage,
    openConfigReviewInvitationPage,
};
