export enum EventStatus {
    CONFIRMED = 'confirmed',
    ACCEPTED = 'accepted',
    DECLINED = 'declined',
    NEEDSACTION = 'needsAction',
}

export enum EntryPointType {
    VIDEO = 'video',
    PHONE = 'phone',
    MORE = 'more',
}

export enum ReminderOverrideMethod {
    EMAIL = 'email',
    POPUP = 'popup',
}

export type EventUser = {
    email: string;
};

export type EventAttendee = {
    email: string;
    displayName?:string;
    self?: true;
    organizer?:true;
    responseStatus?: EventStatus;
};

export type EventTime = {
    dateTime: string; //'2022-12-28T08:15:00+08:00',
    timeZone: string; //'America/Los_Angeles'
};

// {
//     "entryPointType": "video",
//     "uri": "https://us06web.zoom.us/j/83815658913?pwd=Ym5FUUIwa2x0Y0VtL000OW5jdDdXZz09",
//     "label": "us06web.zoom.us/j/83815658913?pwd=Ym5FUUIwa2x0Y0VtL000OW5jdDdXZz09",
//     "meetingCode": "83815658913",
//     "passcode": "620712"
//   },

export type EntryPoint = {
    entryPointType: EntryPointType;
    uri?: string;
    label?: string;
    meetingCode?: string;
    passcode: string;
};

export type ReminderOverride = {
    method: ReminderOverrideMethod;
    minutes: number;
};
export interface Event {
    kind?: string;
    etag?: string;
    location?: string;
    colorId?:string;
    description: string;
    id: string;
    status?: EventStatus;
    htmlLink?: string;
    created?: string; //'2022-11-25T19:55:49.000Z',
    updated?: string; //'2022-12-26T07:38:06.385Z',
    summary: string;
    recurrence?: string[];
    creator?: EventUser;
    organizer?: EventUser;
    start: EventTime;
    end: EventTime;
    recurringEventId?: string;
    originalStartTime?: EventTime;
    iCalUID?: string;
    sequence?: number;
    attendees?: EventAttendee[];
    extendedProperties?: {
        private: {
            everyoneDeclinedDismissed: string;
        };
        shared: {
            meetingId: string;
            zmMeetingNum: string;
            meetingParams: string;
        };
    };
    conferenceData?: {
        entryPoints: EntryPoint[];
        conferenceSolution: {
            key: {
                type: string;
            };
            name: string;
            iconUri: string; //'https://lh3.googleusercontent.com/pw/AM-JKLUkiyTEgH-6DiQP85RGtd_BORvAuFnS9katNMgwYQBJUTiDh12qtQxMJFWYH2Dj30hNsNUrr-kzKMl7jX-Qd0FR7JmVSx-Fhruf8xTPPI-wdsMYez6WJE7tz7KmqsORKBEnBTiILtMJXuMvphqKdB9X=s128-no'
        };
        conferenceId: string;
        notes: string;
        parameters: {
            addOnParameters: {
                parameters: {
                    scriptId: string;
                    realMeetingId: string;
                    creatorUserId: string;
                    meetingUuid: string;
                    meetingType: string;
                    originalEventId: string;
                };
            };
        };
    };
    guestsCanModify?: boolean;
    reminders?: {
        useDefault: boolean;
        overrides: ReminderOverride[];
    };
    eventType?: string;
    providerId?: number,
    channelId?: string,
    serviceId?: string,
    duration?: string,
    meetingLink?: string,
    patientName?: string,
    patientPhone?: string,
    sendForm?: string,
}

export interface EventWithTimeOverlap extends Event {
    timeOverlapKeys:string[]
}

export type GoogleColor = {
    id:string;
    background: string //'#ac725e',
    foreground: string //'#1d1d1d'
};

export type Color = {
    id: number,
    type: 'COLOR',
    dictKey: string,
    content: string,
};

// {
//     "start": "2023-01-05T09:00:00.000-07:00",
//     "end": "2023-01-05T17:00:00.000-07:00",
//     "providerId": null,
//     "eventId": "2kep3qjcqu11nfum4ffg0otug4_20230105T160000Z",
//     "channelId": null,
//     "serviceId": null,
//     "duration": null,
//     "meetingLink": null,
//     "patientName": null,
//     "patientPhone": null,
//     "sendForm": null,
//     "colorId": null,
//     "description": "A chance to hear more about Google's developer products.",
//     "email": [
//       "youming.tang@helloklarity.com",
//       "xiaocong.liu@helloklarity.com"
//     ],
//     "location": "800 Howard St., San Francisco, CA 94103",
//     "recurrence": null,
//     "summary": "Test Event -Xiaocong",
//     "timeZone": null
//   },
export type ServerEvent = {
    start: string,
    end: string,
    eventId: string,
    colorId?: string,
    description: string,
    email?: string[],
    location?: string,
    recurrence?: string;
    summary: string,
    timeZone: string,
    providerId?: number,
    channelId?: string,
    serviceId?: string,
    serviceName?: string,
    duration?: string,
    meetingLink?: string,
    patientName?: string,
    patientPhone?: string,
    sendForm?: string,
};
// [
//     {
//         label: 'Does not repeat',
//         value: '1',
//     },
//     {
//         label: 'Daily',
//         value: '2',
//     },
//     {
//         label: `Weekly on ${day.format('dddd')}`, //Weekly on Friday
//         value: '3',
//     },
//     {
//         label: `Monthly on the first ${day.format('dddd')}`, //Monthly on the first Friday
//         value: '4',
//     },
//     {
//         label: `Monthly on the ${day.format('DD')}`, //Monthly on the 6th
//         value: '5',
//     },
//     {
//         label: 'Every weekday (Monday to Friday)',
//         value: '6',
//     },
// ];
export enum RecurringType {
    NOT_REPEAT = 'NOT_REPEAT',
    DAILY = 'DAILY',
    WEEKLY = 'WEEKLY',
    MONTHLY_ON_FIRST_WEEKDAY = 'MONTHLY_ON_FIRST_WEEKDAY',
    MONTHLY_ON_DATE = 'MONTHLY_ON_DATE',
    WEEKDAY = 'WEEKDAY',
}
