// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__content--x9rOE {
  padding: 24px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid #D1D5DB;
  margin-top: 24px;
}
.s-module__content--x9rOE .ant-descriptions-row > th {
  padding-bottom: 4px;
}
.s-module__content--x9rOE .ant-descriptions-row > td {
  padding-bottom: 0;
}
.s-module__content--x9rOE .ant-descriptions-title {
  color: var(--gray-700, #374151);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 100% */
}
.s-module__headerBar--AcxAO {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.s-module__headerBar--AcxAO .s-module__subtitle--n2Xct {
  color: var(--gray-700, #374151);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 100% */
}
.s-module__headerBar--AcxAO .s-module__ml8--gLB0o {
  margin-left: 8px;
}
.s-module__tips--DWtu8 {
  color: var(--gray-700, #374151);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin: 16px 0;
}
.s-module__tips--DWtu8 span {
  color: var(--red-500, #EF4444);
}
`, "",{"version":3,"sources":["webpack://./src/pages/operation/HelloKlarityAssessment/components/QuestionFrom/s.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,sBAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;AACF;AAPA;EASM,mBAAA;AACN;AAVA;EAYM,iBAAA;AACN;AAbA;EAeQ,+BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACN,SAAS;AACX;AAGA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;AADJ;AAFA;EAKQ,+BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EAAN,SAAS;AACX;AAVA;EAYQ,gBAAA;AACR;AAGA;EACI,+BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;AADJ;AALA;EAQQ,8BAAA;AAAR","sourcesContent":[".content {\n  padding: 24px;\n  width: 100%;\n  box-sizing: border-box;\n  border-radius: 10px;\n  border: 1px solid #D1D5DB;\n  margin-top: 24px;\n  :global {\n    .ant-descriptions-row >th {\n      padding-bottom: 4px;\n    }\n    .ant-descriptions-row >td {\n      padding-bottom: 0;\n    }\n    .ant-descriptions-title {\n        color: var(--gray-700, #374151);\n        font-size: 20px;\n        font-style: normal;\n        font-weight: 500;\n        line-height: 24px; /* 100% */\n    }\n  }\n}\n\n.headerBar {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    .subtitle {\n        color: var(--gray-700, #374151);\n        font-size: 20px;\n        font-style: normal;\n        font-weight: 500;\n        line-height: 20px; /* 100% */\n    }\n    .ml8 {\n        margin-left: 8px;\n    }\n}\n\n.tips {\n    color: var(--gray-700, #374151);\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 20px;\n    margin: 16px 0;\n    span {\n        color: var(--red-500, #EF4444);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `s-module__content--x9rOE`,
	"headerBar": `s-module__headerBar--AcxAO`,
	"subtitle": `s-module__subtitle--n2Xct`,
	"ml8": `s-module__ml8--gLB0o`,
	"tips": `s-module__tips--DWtu8`
};
export default ___CSS_LOADER_EXPORT___;
