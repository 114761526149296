import React from 'react';
import { Modal, Form, message, Input, Button, Select } from 'antd';
import commonS from 'styles/common.module.less';
import { FormFieldEnum, FormInterface } from './types';
import s from './s.module.less';
import { REQUIRED_FIELD } from 'constants/common';
import { EInternalLinkType, TInternalLink } from 'types/operation';
import { ServerMeta } from 'types/common';
import { updateHelloKlairtyKlarityPageInternalLinkBatch } from 'api/operation';
import StateWithFeatureSelect from 'components/form/StateWithFeatureSelect';

type Props = {
    onCancel:()=>void
    item?:TInternalLink[] //for edit fun
    onSuccess?: ()=>void
    open?: boolean;
    title?: string;
    stateOptions?: ServerMeta[];
    targetCondition?: ServerMeta,
    pageId: number,
    targetState?: ServerMeta,
};

const EditFindInOtherStateModal = (props:Props) => {
    const { onCancel, item, onSuccess, targetState, pageId, targetCondition, stateOptions, open, title } = props;
    const [form] = Form.useForm<FormInterface>();
    const [,forceUpdate] = React.useState({});
    const [submiting, setSubmiting] = React.useState(false);

    const [usedDictKeyStr, setUsedDictKeyStr] = React.useState<string[]>([]);

    const onOk = async () => {
        try {
            setSubmiting(true);
            const formValues = await form.validateFields();
            const addData:TInternalLink[] = [];
            formValues[FormFieldEnum.LIST].forEach((formValue, inx) => {
                const add:TInternalLink = {
                    type: EInternalLinkType.FIND_DOCTOR,
                    linkTag: formValue.linkTag,
                    linkName: '',
                    linkUrl: '',
                    pageId: pageId!,
                    seq: inx,
                };
                addData.push(add);
            });
            const result = await updateHelloKlairtyKlarityPageInternalLinkBatch(addData, EInternalLinkType.FIND_DOCTOR, pageId!);
            if (!result.error) {
                message.success('Update success');
                onSuccess?.();
            }
        } catch (e:any) {
            if (e.errorFields) {
                //form error
                return;
            }
            const msg = e?.toString?.() || 'data error';
            message.error(msg);
        } finally {
            setSubmiting(false);
        }
    };
    React.useEffect(() => {
        if (!open) {
            setUsedDictKeyStr([]);
            form.resetFields();
        }
        if (item && open) {
            const _usedContentStr:string[] = [];
            form.setFieldsValue({
                [FormFieldEnum.LIST]: item?.map((i) => {
                    _usedContentStr.push(i.linkTag);
                    return {
                        id: i.id,
                        linkTag: i.linkTag,
                    };
                }),
            });
            setUsedDictKeyStr(_usedContentStr);
            forceUpdate({});
        }
    }, [item, open]);

    const hasOption = stateOptions && stateOptions?.length > 0;

    return (
        <Modal
            title={title || 'Edit'}
            closable
            className={commonS.modalFixHeightWrap}
            width="550px"
            destroyOnClose
            onCancel={() => {
                onCancel?.();
            }}
            onOk={onOk}
            okText="Save"
            open={open}
            okButtonProps={{
                loading: submiting,
            }}
        >
            {
                hasOption &&
                <Form
                    form={form}
                    name="basic"
                    className={commonS.formStyle1}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.List name="list">
                        {
                            (fields, { add, remove }) => {
                                return (
                                    <>
                                        {
                                            fields.map((field, index) => {
                                                const stateDictKey = form.getFieldValue(['list', field.name, 'linkTag']);
                                                const target = stateOptions?.find((sm:ServerMeta) => {
                                                    return sm.content === stateDictKey || sm.dictKey === stateDictKey;
                                                });
                                                const text = target ? `${targetCondition?.content} in ${target?.content}` : '';

                                                const stateList = stateOptions?.filter((sm:ServerMeta) => {
                                                    return !usedDictKeyStr.includes(sm.content) || sm.content === stateDictKey;
                                                });

                                                return (
                                                    <div className={s.module} key={field.name}>
                                                        <div className={s.rowHeader}>
                                                            <span>Link {index + 1}</span>
                                                            <Button
                                                                onClick={() =>
                                                                {
                                                                    const _newUsedCondition = [...usedDictKeyStr].filter((str) => str !== stateDictKey);
                                                                    setUsedDictKeyStr(_newUsedCondition);
                                                                    remove(index);
                                                                }
                                                                }
                                                            >
                                                                Remove
                                                            </Button>
                                                        </div>
                                                        <div className={s.content}>
                                                            <div className={s.row}>
                                                                <div
                                                                    className={s.rowItem}
                                                                    style={{ width: '100%' }}
                                                                >
                                                                    {/* <Form.Item
                                                                        label="State"
                                                                        name={[field.name, 'linkTag']}
                                                                        validateFirst
                                                                        rules={[
                                                                            { required: true, message: REQUIRED_FIELD },
                                                                        ]}
                                                                    >
                                                                        <Select
                                                                            showSearch
                                                                            onChange={(val) => {
                                                                                const _newUsedCondition = [...usedDictKeyStr].filter((str) => str !== stateDictKey);
                                                                                if (!usedDictKeyStr.includes(val)) {
                                                                                    _newUsedCondition.push(val);
                                                                                }
                                                                                setUsedDictKeyStr(_newUsedCondition);
                                                                            }}
                                                                        >
                                                                            {
                                                                                stateOptions?.map((sm:ServerMeta) => {
                                                                                    return (
                                                                                        <Select.Option
                                                                                            disabled={usedDictKeyStr.includes(sm.dictKey) && sm.dictKey !== stateDictKey}
                                                                                            key={sm.dictKey}
                                                                                            value={sm.dictKey}
                                                                                        >
                                                                                            {sm.content}
                                                                                        </Select.Option>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </Select>
                                                                    </Form.Item> */}
                                                                    <Form.Item
                                                                        label="State"
                                                                        name={[field.name, 'linkTag']}
                                                                        validateFirst
                                                                        rules={[
                                                                            { required: true, message: REQUIRED_FIELD },
                                                                        ]}
                                                                    >
                                                                        <StateWithFeatureSelect
                                                                            onChange={(val) => {
                                                                                const _newUsedCondition = [...usedDictKeyStr].filter((str) => str !== stateDictKey);
                                                                                if (!usedDictKeyStr.includes(val)) {
                                                                                    _newUsedCondition.push(val);
                                                                                }
                                                                                setUsedDictKeyStr(_newUsedCondition);
                                                                            }}
                                                                            hideValues={[targetState?.dictKey as string]}
                                                                            showSearch
                                                                            disableValues={usedDictKeyStr}
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>

                                                            <div className={s.row}>
                                                                <div
                                                                    className={s.rowItem}
                                                                    style={{ width: '100%' }}
                                                                >
                                                                    <div className={s.labelText}>Link name *</div>
                                                                    <Input value={text} disabled />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                        <div
                                            onClick={() => {
                                                add();
                                            }}
                                            className={s.addLinkBtn}
                                        >
                                            Add another link
                                        </div>
                                    </>
                                );
                            }
                        }
                    </Form.List>
                </Form>
            }
        </Modal>
    );
};

export default EditFindInOtherStateModal;
