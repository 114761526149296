import React, { useCallback, useMemo } from "react";
import s from './s.module.less';
import { Button, message, Popconfirm, Spin } from "antd";
import {PlusOutlined} from '@ant-design/icons';
import CommonTable from 'components/CommonTable';
import type { ColumnsType } from 'antd/es/table';
import { useNavigate } from "react-router-dom";
import { PATH } from "constants/path";
import FullScreenPopLayout from "components/FullScreenPopLayout";
import PackageDetail from "../PackageDetail";
import { IKlarityProgram, IKlarityProgramPackage, IKlarityProgramPackageExternalInfo } from "types/programs";
import { removePackageById, saveNUpdatePackage } from "api/operation/programs";
import { frequency } from "constants/program";

interface IProps {
    programData: IKlarityProgram;
    refetchProgramDetail: () => void;
    programId: number;
}

const IncludedPackages = ({
    programData,
    refetchProgramDetail,
    programId
}: IProps) => {
    const [tableLoading, setTableLoading] = React.useState<boolean>(false);
    const [showPackageDetailModal, setShowPackageDetailModal] = React.useState<boolean>(false);
    const [currentEditPackge, setCurrentEditPackage] = React.useState<IKlarityProgramPackage>();
    const [saveLoading, setSaveLoading] = React.useState<boolean>(false);
    const [removeLoading, setRemoveLoading] = React.useState<boolean>(false);

    const tableData = useMemo(() => programData?.klarityProgramPackageList || [], [programData]);

    const handleEdit = useCallback((item?: IKlarityProgramPackage) => {
        setShowPackageDetailModal(true);
        setCurrentEditPackage(item);
    }, []);

    const handleAddNewProgram = useCallback(() => {
        handleEdit();
    }, [handleEdit]);

    const handleDuplicatePackage = useCallback(async (item: IKlarityProgramPackage) => {
        setSaveLoading(true);
        try {
            const externalInfo = item.klarityProgramPackageExternalInfo || {} as IKlarityProgramPackageExternalInfo;
            const klarityProgramMedicationItemList = item.klarityProgramMedicationItemList || [];
            const klarityProgramPackageSessionItemList = item.klarityProgramPackageSessionItemList || [];
            const klarityProgramPackageTagItemList = item.klarityProgramPackageTagItemList || [];
            const res = await saveNUpdatePackage({
                ...item,
                klarityProgramPackageExternalInfo: {
                    disclaimerPolicy: externalInfo.disclaimerPolicy,
                    isAllPatientGroup: externalInfo.isAllPatientGroup,
                    isDeleted: externalInfo.isDeleted,
                    id: undefined,
                    packageId: undefined,
                    patientFacingDesc: externalInfo.patientFacingDesc,
                    programId: programId,
                    qtyPatientGroup: externalInfo.qtyPatientGroup,
                    whatIncludeInfo: externalInfo.whatIncludeInfo,
                    faq: externalInfo.faq ? JSON.parse(externalInfo.faq) : [],
                    patientGroup: externalInfo.patientGroup ? externalInfo.patientGroup.slice(1, -1).split(',').map(item => item.trim()) : []
                },
                packageTitle: `Copy of ${item.packageTitle}`,
                klarityProgramMedicationItemList: klarityProgramMedicationItemList.map((medicationItem) => ({
                    ...medicationItem,
                    id: undefined
                })),
                klarityProgramPackageSessionItemList: klarityProgramPackageSessionItemList.map((sessionItem) => ({
                    ...sessionItem,
                    id: undefined
                })),
                klarityProgramPackageTagItemList: klarityProgramPackageTagItemList.map((tagItem) => ({
                    ...tagItem,
                    id: undefined
                })),
                id: undefined
            })

            if (res && !res.error) {
                refetchProgramDetail();
            } else {
                message.error(res.error)
            }
        } catch(e) {
            console.error(e)
        }
        setSaveLoading(false);
    }, [refetchProgramDetail]);

    const handleRemovePackage = useCallback(async (id: number) => {
        if (id)  {
            setRemoveLoading(true);
            try {
                const res = await removePackageById(id);

                if (res && !res.error) {
                    refetchProgramDetail();
                } else {
                    message.error(res.error);
                }
            } catch(e) {
                console.error(e)
            }
            setRemoveLoading(false);
        }
    }, [])

    const columns = useMemo(() => (
        [
            {
                title: 'PACKAGE NAME',
                dataIndex: 'packageTitle',
                width: '260px',
                fixed: 'left',
                className: s.tableColumn,
            },
            {
                title: 'TOTAL PRICE',
                dataIndex: 'totalPrice',
                width: '260px',
                className: s.tableColumn,
                render: (value:number, row: IKlarityProgramPackage) => {
                    const {billingFrequencyType, firstPaymentPrice, price, billingPeriod} = row;
                    if (billingFrequencyType !== frequency.ONE_TIME && billingPeriod === 1) {
                        return `Payments: First payment $${firstPaymentPrice || 0} then $${price || 0} per month`;
                    }
                    return `$${value}`;
                }
            },
            {
                title: 'BILLING FREQUENCY',
                dataIndex: 'billingFrequencyType',
                width: '300px',
                className: s.tableColumn,
                render: (text:string) => {
                    return text;
                },
            },
            {
                title: 'FIRST PAYMENT',
                dataIndex: 'firstPaymentPrice',
                className: s.tableColumn,
                width: '300px',
                render: (text:string) => {
                    return `$${text}`;
                },
            },
            {
                title: 'ON KLARITY',
                dataIndex: 'isKlarityDisplay',
                className: s.tableColumn,
                width: '300px',
                render: (isOnKlarity:boolean) => {
                    return <div className={s.isOnKlarity}>{`${isOnKlarity}`}</div>
                },
            },
            {
                title: 'DESCRIPTION',
                dataIndex: 'description',
                className: s.tableColumn,
                width: '300px',
                render: (text:string) => {
                    return (
                        <div className={s.nameWrap} title={text}>
                            {text}
                        </div>
                    );
                },
            },
            {
                title: '',
                width: '120px',
                className: s.tableColumn,
                fixed: 'right',
                render: (item: IKlarityProgramPackage) => {
                    return (
                        <Popconfirm
                            title="Duplicate this package"
                            description="Are you sure to duplicate this package?"
                            onConfirm={() => handleDuplicatePackage(item)}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <div
                                className={s.edit}
                            >Duplicate
                            </div>
                        </Popconfirm>
                    );
                },
            },
            {
                title: '',
                width: '120px',
                className: s.tableColumn,
                fixed: 'right',
                render: (item) => {
                    return (
                        <Popconfirm
                            title="Delete the package"
                            description="Are you sure to delete this package?"
                            onConfirm={() => handleRemovePackage(item.id)}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <div
                                className={s.edit}
                            >Remove
                            </div>
                        </Popconfirm>
                    );
                },
            },
            {
                title: '',
                width: '80px',
                className: s.tableColumn,
                fixed: 'right',
                render: (item: IKlarityProgramPackage) => {
                    return (
                        <div
                            className={s.edit}
                            onClick={() => handleEdit(item)}
                        >Edit
                        </div>
                    );
                },
            },
        ]
    ), []);

    const handleSaveSuccess = useCallback(() => {
        refetchProgramDetail();
        setShowPackageDetailModal(false);
    }, []);

    return (
        <div className={s.wrap}>
            { 
                showPackageDetailModal && 
                <FullScreenPopLayout
                    wrapClass={s.fullScreenPopLayoutClass}
                    footerButton={
                        <div className={s.footerBtns}>
                            <Button
                                type="default"
                                onClick={() => {
                                    setShowPackageDetailModal(false);
                                    setCurrentEditPackage(undefined);
                                }}
                            >Exit
                            </Button>
                            <Button
                                type="primary"
                                htmlType="submit"
                                form="packageDetailForm"
                                loading={saveLoading}
                            >Save
                            </Button>
                        </div>
                    }
                >
                    {
                        <PackageDetail programId={programData.id} setSaveLoading={setSaveLoading} packageItem={currentEditPackge} onSaveSuccess={handleSaveSuccess} />
                    }
                </FullScreenPopLayout>
            }
            <Spin spinning={saveLoading}>
                <div className={s.toolbar}>
                    <div className={s.title}>Package management</div>
                    <Button icon={<PlusOutlined/>} onClick={handleAddNewProgram}>Add a new package</Button>
                </div>
                <CommonTable
                    rowKey={(record) => record.id}
                    bordered
                    loading={tableLoading}
                    scroll={{ x: '1500' }}
                    columns={columns}
                    data={tableData}
                />
            </Spin>
        </div>
    )
}

export default IncludedPackages;
