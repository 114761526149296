import TinyStore from 'lib/tinyStore';
import { UniprofileFormData } from 'types/provider';
// import { getUserId } from 'utils/localstore';

export const initApplicationFormData:UniprofileFormData = {
    profile: {
        id: -1,
        firstName: '',
        lastName: '',
        email: '',
        tel: '',
        gmail: '',
        address: '',
        zip: '',
        state: '',
        npi: '',
        yearExp: undefined,
        specialityInHealth: [],
        specialityInFamily: [],
        title: '',
        intro: '',
        primaryLanguage: '',
        foreignLanguage: [],
        inPersonChecked: false,
        officeAddress: '',
        officeCity: '',
        officeState: '',
        officeZip: '',
        payout: '',
        payment: '',
        showReview: false,
        allowAuth: false,
        photoList: [],
        additionalPhotoList: [],
        inPersonVisitList: [],
        telehealthVisitList: [],
        licenses: [],
        status: '',
        listStatus: '',
        auditStatus: '',
    },
    services: [],
    practice: {
        treatmentPhilosophy: '',
        treatmentApproach: '',
        practiceName: '',
        practiceAddress: '',
        practiceCity: '',
        practiceState: '',
        practiceZip: '',
        practiceEmail: '',
        practicePhone: '',
        practiceWebsite: '',
        companyType: '',
        practiceDesc: '',
        practiceLogo: '',
        practiceFrontUrl: '',
        conditionTreatedList: [],
        specialtyList: [],
        addressList: [],
        taxIdType: '',
        taxId: '',
    },
    experienceList: [
        // {
        //     organizationName: '',
        //     title: '',
        //     startDate: '',
        //     endDate: '',
        //     employmentType: '',
        //     description: '',
        //     experienceLogo: '',
        //     currently: false,
        //     hospitalLocation: '',
        // },
    ],
    educationList: [
        // {
        //     schoolName: '',
        //     major: '',
        //     degree: '',
        //     startDate: '',
        //     endDate: '',
        //     specialties: '',
        //     certificates: '',
        //     schoolLogo: '',
        //     currently: false,
        // },
    ],
    addtional: {
        payout: '',
        showReview: true,
        associationAdditionalList: [
            {
                additionalType: '',
                name: '',
                website: '',
                authors: '',
            },
        ],
        awardAdditionalList: [
            {
                additionalType: '',
                name: '',
                website: '',
                authors: '',
            },
        ],
        publicationAdditionalList: [
            {
                additionalType: '',
                name: '',
                website: '',
                authors: '',
            },
        ],
    },
    insurance: {
        insuranceList: [],
        otherInsuranceList: '',
        supportPayType: '',
    },
    biographic: {
        ethnicity: '',
        birthDate: '',
        gender: '',
        religion: '',
        usedName: '',
    },
    licensesInState: [],
};

type ProviderModal = {
    data: UniprofileFormData;
    loading: boolean;
};

const Store = TinyStore.createStore<ProviderModal>({
    nameSpace: 'klarity',
    key: 'channelApplicationProvider',
    crossBundle: false,
    runTime: true,
    initValue: { data: { ...initApplicationFormData }, loading: false },
});

export default Store;
