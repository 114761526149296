import dayjs from 'dayjs';

export enum FormFieldEnum {
    TITLE = 'title',
    CONTENT = 'content',
}

export interface FormInterface {
    [FormFieldEnum.TITLE]: string;
    [FormFieldEnum.CONTENT]: string;
}
