import TinyStore from 'lib/tinyStore';
import { ICCCItem } from 'types/ccc';

type CCCeModal = {
    // selectedCampaign?: ICampaign | null;
    cccList?: ICCCItem[];
    forceRefetchCCCList?: Record<any, any> | null;
};

const Store = TinyStore.createStore<CCCeModal>({
    nameSpace: 'box_practice_campaign_store',
    key: 'campaignStore',
    crossBundle: false,
    runTime: true,
    initValue: { cccList: [] },
});
export default Store;
