import React from 'react';
import s from './s.module.less';
import dayjs, { Dayjs } from 'dayjs';
import { Button, Calendar, Input } from 'antd';
import moment from 'moment';
import { getDayKey } from 'utils/common';
import StateSelect from 'components/form/StateSelect';
import { ServerMeta } from 'types/common';

moment.updateLocale('en', {
    weekdaysMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
});
type Prop = {
    wrapClass?: string;
    startDate?: Dayjs;
    endDate?: Dayjs;
    defaultValue?: Dayjs;
    onChange?: (value: Dayjs) => void;
    availableDates?: string[];
    defaultState?: string;
    onSearch?: (val:string)=>void;
    onCancel?: ()=>void;
};

export default function DatePicker(props: Prop) {
    const { wrapClass, onCancel, onSearch, startDate, endDate, onChange, availableDates, defaultValue, defaultState } = props;
    const [currentVal, setCurrentVal] = React.useState<Dayjs>(defaultValue || dayjs());
    const [selectedVals, setSelectedVals] = React.useState<Dayjs[]>([]);
    const [pendingState, setPendingState] = React.useState<string>(defaultState || 'CA');
    const [pendingSearchValue, setPendingSearchValue] = React.useState<string>('');
    const [hasChangeState, setHasChangeState] = React.useState(false);

    const addMonth = (month: number) => {
        onChange && onChange(currentVal.add(month, 'month'));
        setCurrentVal((prev) => prev.add(month, 'month'));
    };
    const firstDayOfMonth = currentVal.startOf('month');
    const lastDayOfMonth = currentVal.endOf('month');

    const canBack = startDate ? firstDayOfMonth.unix() > startDate?.unix() : true;
    const canNext = endDate ? lastDayOfMonth.unix() < endDate?.unix() : true;

    React.useEffect(() => {
        if (defaultState && !hasChangeState) {
            setPendingState(defaultState);
        }
    }, [defaultState, hasChangeState]);

    React.useEffect(() => {
        if (defaultValue) {
            //console.log('defaultValue', defaultValue);
            setCurrentVal(defaultValue);
        }
    }, [defaultValue]);
    //console.log('currentVal', currentVal);

    React.useEffect(() => {
        let text = selectedVals?.map((item) => {
            return item.format('MMM. DD');
        }).join(', ');
        if (text) {
            text = `Who has availability on ${text} on ${pendingState}`;
        } else {
            text = `Who has availability on ${pendingState}`;
        }
        setPendingSearchValue(text);
    }, [pendingState, selectedVals]);

    const headerRender = () => {
        return (
            <div className={s.header}>
                <div
                    onClick={() => {
                        if (!canBack) {
                            return;
                        }
                        addMonth(-1);
                    }}
                    className={`${s.left} ${canBack ? s.leftCan : ''}`}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M15 19L8 12L15 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                <div className={s.center}>
                    {currentVal?.format('MMMM YYYY')}
                </div>
                <div
                    onClick={() => {
                        if (!canNext) {
                            return;
                        }
                        addMonth(1);
                    }}
                    className={`${s.right} ${canNext ? s.rightCan : ''}`}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M9 5L16 12L9 19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </div>
        );
    };

    return (
        <div className={s.wrap}>
            <Calendar
                value={currentVal}
                headerRender={headerRender}
                dateFullCellRender={(current:Dayjs) => {
                    const isSelected = selectedVals?.find((item) => {
                        return getDayKey(item) === getDayKey(current);
                    });
                    return (
                        <span
                            onClick={(e) => {
                                if (getDayKey(current) === getDayKey(currentVal)) {
                                    const selected = selectedVals?.find((item) => {
                                        return getDayKey(item) === getDayKey(current);
                                    });
                                    if (selected) {
                                        //unselect
                                        setSelectedVals(selectedVals.filter((item) => {
                                            return getDayKey(item) !== getDayKey(current);
                                        }));
                                    } else {
                                        setSelectedVals([...selectedVals, current]);
                                    }
                                    e.stopPropagation();
                                }
                            }}
                            className={`${s.day} ${isSelected ? s.selected : ''}`}
                        >{current.format('DD')}
                        </span>
                    );
                }}
                //onPanelChange={onPanelChange}
                onChange={(value) => {
                    const isSelected = selectedVals?.find((item) => {
                        return getDayKey(item) === getDayKey(value);
                    });
                    if (isSelected) {
                        //unselect
                        setSelectedVals(selectedVals.filter((item) => {
                            return getDayKey(item) !== getDayKey(value);
                        }));
                    } else {
                        setSelectedVals([...selectedVals, value]);
                    }
                    setCurrentVal(value);
                    onChange && onChange(value);
                }
                }
                disabledDate={(current) => {
                    if (availableDates) {
                        if (!availableDates.includes(getDayKey(current))) {
                            return true;
                        }
                    }
                    if (startDate && endDate) {
                        if (getDayKey(current) === getDayKey(startDate) || getDayKey(current) === getDayKey(endDate)) {
                            return false;
                        }
                        return current <= startDate || current >= endDate;
                    }
                    return false;
                }}
            />
            <div className={s.label}>Patient’s location (For availability display)</div>
            <div className={s.selectWrap}>
                <StateSelect
                    style={{
                        width: '100%',
                    }}
                    value={pendingState}
                    onChange={(val) => {
                        setHasChangeState(true);
                        setPendingState(val);
                    }}
                />
            </div>
            <div className={s.inputWrap}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M8 4.5C5.79086 4.5 4 6.29086 4 8.5C4 10.7091 5.79086 12.5 8 12.5C10.2091 12.5 12 10.7091 12 8.5C12 6.29086 10.2091 4.5 8 4.5ZM2 8.5C2 5.18629 4.68629 2.5 8 2.5C11.3137 2.5 14 5.18629 14 8.5C14 9.79583 13.5892 10.9957 12.8907 11.9765L17.7071 16.7929C18.0976 17.1834 18.0976 17.8166 17.7071 18.2071C17.3166 18.5976 16.6834 18.5976 16.2929 18.2071L11.4765 13.3907C10.4957 14.0892 9.29583 14.5 8 14.5C4.68629 14.5 2 11.8137 2 8.5Z" fill="#9CA3AF" />
                </svg>
                <Input.TextArea
                    rows={1}
                    className={s.input}
                    onChange={(e) => {
                        setPendingSearchValue(e.target.value);
                    }}
                    value={pendingSearchValue}
                    autoSize={{ maxRows: 4 }}
                />
            </div>
            <div className={s.footer}>
                <span className={s.link}>Clear all</span>
                <div className={s.right}>
                    <Button
                        onClick={() => {
                            onCancel?.();
                            setSelectedVals([]);
                        }}
                        className={s.btn}
                    >Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            onSearch?.(pendingSearchValue);
                            setPendingSearchValue('');
                            setSelectedVals([]);
                        }}
                        className={s.btn}
                        type="primary"
                    >
                        Search
                    </Button>
                </div>
            </div>
        </div>
    );
}
