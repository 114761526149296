import dayjs from 'dayjs';

export enum FormFieldEnum {
    QUESTION = 'question',
    ANSWER = 'answer',
}

export interface FormInterface {
    [FormFieldEnum.ANSWER]: string;
    [FormFieldEnum.QUESTION]: string;
}
