// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--yDNmo {
  padding: 24px 0;
}
.s-module__wrap--yDNmo .s-module__listBox--mVmuZ .s-module__item--m0cm_ {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.s-module__wrap--yDNmo .s-module__listBox--mVmuZ .s-module__item--m0cm_ .s-module__label--UIaMr {
  width: 100px;
  margin-right: 8px;
  font-size: 16px;
}
.s-module__wrap--yDNmo .s-module__listBox--mVmuZ .s-module__item--m0cm_ .s-module__link--fedyB {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
`, "",{"version":3,"sources":["webpack://./src/pages/operation/HelloKlarityProviders/components/MulitProfileLinkModal/s.module.less"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;AAFA;EAKY,aAAA;EACA,mBAAA;EACA,mBAAA;AAAZ;AAPA;EAUgB,YAAA;EACA,iBAAA;EACA,eAAA;AAAhB;AAZA;EAgBgB,OAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;AADhB","sourcesContent":[".wrap {\n    padding: 24px 0;\n\n    .listBox {\n        .item {\n            display: flex;\n            align-items: center;\n            margin-bottom: 16px;\n\n            .label {\n                width: 100px;\n                margin-right: 8px;\n                font-size: 16px;\n            }\n\n            .link {\n                flex: 1;\n                overflow: hidden;\n                white-space: nowrap;\n                text-overflow: ellipsis;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--yDNmo`,
	"listBox": `s-module__listBox--mVmuZ`,
	"item": `s-module__item--m0cm_`,
	"label": `s-module__label--UIaMr`,
	"link": `s-module__link--fedyB`
};
export default ___CSS_LOADER_EXPORT___;
