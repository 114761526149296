import React, { useCallback, useEffect, useMemo } from "react";
import s from "./s.module.less";
import { Button, Form, Input, message, Select, Spin } from "antd";
import AutoLineBreakTextArea from "components/ApplicationFormComponents/Practice/component/AutoLineBreakTextArea/AutoLineBreakTextArea";
// import PhotoList from "components/PhotoList";
import Alert from "components/Alert";
import { IKlarityProgram, IKlarityProgramForm } from "types/programs";
import useGetSettingData from 'hooks/useGetSettingData';
import { EnumFields, SettingEnum } from 'types/enumerationData';
import { saveNUpdateProgram } from "api/operation/programs";
import { useNavigate } from "react-router-dom";
import { PATH } from "constants/path";
import useGetSpecialtyList from 'hooks/klarityServiceTemplateOps/useGetSpecialtyList';
import { IKlaritySpecialtyForm } from "types/serviceTemplate";

interface IProps {
    programData?: IKlarityProgram;
    refetchProgramDetail?: () => void;
}

const ProgramInfo = ({programData, refetchProgramDetail}: IProps) => {
    const [specialities, specialtiesLoading] = useGetSpecialtyList();
    const navigate = useNavigate();
    const [form] = Form.useForm<IKlarityProgramForm>();
    const [states] = useGetSettingData(EnumFields.STATE);
    const [loading, setLoading] = React.useState(false);

    const specialtyOptions = useMemo(() => {
        return (
            (specialities || [])
                .map((item: IKlaritySpecialtyForm) => {
                    return {
                        label: item.specialtyName,
                        value: item.id,
                    };
                })
        );
    }, [specialities]);

    const statesOptions = useMemo(() => {
        return (
            states ? states.map((stateItem: SettingEnum) => ({
                label: stateItem.content,
                value: stateItem.dictKey,
            })) : []
        )
    }, [states]);

    useEffect(() => {
        if (programData) {
            form.setFieldsValue({
                ...programData,
                excludeState: programData.excludeState.split(','),
                klarityProgramKeyAdvantageList: programData.klarityProgramKeyAdvantageList ? 
                    programData.klarityProgramKeyAdvantageList.map((e, index) => ({...e, priority: index+1})) :
                    [{
                        title: '',
                        content: '',
                        priority: 1,
                    }],
            });
        }
    }, [programData]);

    const handleFormSubmit = useCallback(() => {
        form.validateFields().then(async (values: IKlarityProgramForm) => {
            setLoading(true);
            const formData = {
                ...values,
                specialtyName: specialtyOptions.find(item => item.value === values.specialtyId)?.label || '',
                "excludeState": values.excludeState.join(','),
                klarityProgramKeyAdvantageList: values.klarityProgramKeyAdvantageList?.map((item, index) => ({
                    ...item,
                    priority: item.priority || index + 1,
                })),
            }

            if (programData?.id) {
                formData.id = programData.id;
            }
            
            try {
                const result = await saveNUpdateProgram(formData);
                if (result && !result.error) {  
                    navigate(`${PATH.OPERATION}/${PATH.SERVICES_TEMPLATE}?type=2&subType=3`);
                } else {
                    message.error(result.error)
                }
            } catch (e) {
                console.error(e);
            }
            
        }).catch((e) => {
            message.error('Please fill in the required fields');
        }).finally(() => {
            setLoading(false);
        });
    }, [form, programData, specialtyOptions, refetchProgramDetail]);

    return (
        <div className={s.wrap}>
            <Spin spinning={loading || specialtiesLoading}>
                <Form
                    form={form}
                    layout='vertical'
                >
                    <div className={s.toolbar}>
                        <Button type="primary" onClick={handleFormSubmit}>Save program</Button>
                    </div>
                    <div className={s.block}>
                        <div className={s.title}>Basic information</div>
                        <div className={s.formItem}>
                            <Form.Item
                                // style={{ width: '120px' }}
                                label="Select speciality"
                                name="specialtyId"
                                rules={[{ required: true, message: 'Speciality is required' }]}
                            >
                                <Select options={specialtyOptions}/>
                            </Form.Item>
                        </div>
                        <div className={s.formItem}>
                            <Form.Item
                                // style={{ width: '120px' }}
                                label="Program title"
                                name="programName"
                                rules={[{ required: true, message: 'Program title is required' }]}
                            >
                                <Input />
                            </Form.Item>
                        </div>
                        <div className={s.formItem}>
                            <Form.Item
                                // style={{ width: '120px' }}
                                label="Description"
                                name="description"
                                rules={[{ required: true, message: 'Description is required' }]}
                            >
                                <Input.TextArea showCount maxLength={350} />
                            </Form.Item>
                        </div>
                        <div className={s.formItem}>
                            <Form.Item
                                // style={{ width: '120px' }}
                                label="States excluded from service area"
                                name="excludeState"
                                rules={[{ required: true, message: 'States excluded from service area is required' }]}
                            >
                                <Select
                                    mode="tags"
                                    style={{ width: '100%' }}
                                    options={statesOptions}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className={s.block}>
                        <div className={s.title}>Detailed Information</div>
                        <div className={s.formItem}>
                            <Form.Item
                                // style={{ width: '120px' }}
                                label="Target patients"
                                name="detailInfo"
                            >
                                <AutoLineBreakTextArea showCount maxLength={250}/>
                            </Form.Item>
                        </div>
                        <div className={s.formItem}>
                            <div className={s.label}>Key advantages</div>
                            <div className={s.formListBox}>
                                <Form.List name="klarityProgramKeyAdvantageList" initialValue={[{}]}>
                                {
                                    (fields, {add, remove}) => {
                                        return (
                                            <>
                                                {
                                                    fields.map(({ key, name, ...restField }, index) => (
                                                        <div key={index} className={s.advBlock}>
                                                            <Form.Item
                                                                {...restField}
                                                                label="Title"
                                                                name={[name, 'priority']}
                                                                className={s.hidden}
                                                                // rules={[{ required: true, message: 'Title is required' }]}
                                                            >
                                                                <Input type="hidden"/>
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...restField}
                                                                label="Title"
                                                                name={[name, 'title']}
                                                                // rules={[{ required: true, message: 'Title is required' }]}
                                                            >
                                                                <Input showCount maxLength={100}/>
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...restField}
                                                                label="Content"
                                                                name={[name, 'content']}
                                                                // rules={[{ required: true, message: 'Program title is required' }]}
                                                            >
                                                                <Input.TextArea maxLength={200} showCount />
                                                            </Form.Item>
                                                            <div className={s.formFooter}>
                                                                <Button onClick={() => remove(index)}>Remove</Button>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                                <div className={s.add} onClick={() => add()}>Add another point</div>
                                            </>
                                        )
                                    }
                                }
                                </Form.List>
                            </div>
                        </div>
                    </div>
                    <div className={s.block}>
                        <div className={s.formItem}>
                            <div className={s.label}>Provider agreement</div>
                            <div className={s.alertBox}>
                                <Alert text="Please forward the agreement in PDF format directly to our product team to facilitate the e-signing setup process."></Alert>
                            </div>
                            {/* <Form.Item
                                name="Provider agreement"
                                valuePropName="data"
                            >
                                <PhotoList
                                    min={1}
                                    data={['']}
                                    enable
                                    responseType="string"
                                />
                            </Form.Item> */}
                        </div>
                    </div>
                    <div className={s.block}>
                        <div className={s.formItem}>
                            <Form.Item
                                label="Highlighted information to providers"
                                name="highlightInfo"
                            >
                                <AutoLineBreakTextArea />
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </Spin>
        </div>
    )
}

export default ProgramInfo;
