import React, { useCallback, useRef } from "react";
import s from "./s.module.less";
import { Form, Input, InputNumber, Checkbox, Select, Button, message } from "antd";
import commonS from 'styles/common.module.less';
import CheckboxNRadio from "components/CheckboxNRadio/CheckboxNRadio";
import { addKlarityServiceSession } from "api/operation/programs";

interface IProps {
    serviceTypeOptions: { label: string, value: any }[];
    setSessionSubmiting: (value: boolean) => void;
    onSubmitSuccess?: () => void;
    programId: number;
}

const SessionModal = ({
    setSessionSubmiting,
    onSubmitSuccess,
    serviceTypeOptions,
    programId,
}: IProps) => {
    const [form] = Form.useForm();

    const contactTypeOptions = useRef([
        {
            label: 'Tele-health',
            value: 'telehealth'
        },
        {
            label: 'In person',
            value: 'inperson'
        }
    ])

    const customContactTypeValidator = useCallback(async (rule, value: any[]) => {
        if (!value) {
          return Promise.reject(new Error('Contact type is required!'));
        }
        return Promise.resolve();
    }, []);

    const handleFinish = useCallback(async (values) => {
        setSessionSubmiting(true);

        // ... 需要后端改为批量
        values.serviceSession.forEach(async (value) => {
            try {
                const submitData = {
                    ...value,
                    teleHealth: value.contactType.includes('telehealth'),
                    inPerson: value.contactType.includes('inperson'),
                    programId,
                    serviceType: serviceTypeOptions.find(item => item.value === value.serviceTypeId)?.label
                }
                const res = await addKlarityServiceSession(submitData);
    
                if (res && !res.error) {
                    onSubmitSuccess?.();
                } else {
                    message.error(res.error)
                }
            } catch (e) {
                console.error(e)
            }
        });

        setSessionSubmiting(false);
    }, [onSubmitSuccess, programId, setSessionSubmiting, serviceTypeOptions]);

    const handleSubmitFailed = useCallback(() => {
        // console.info('error')
    }, []);

    return (
        <div className={s.wrap}>
            <Form
                name="sessionForm"
                className={commonS.formStyle1}
                layout="vertical"
                form={form}
                onFinish={handleFinish}
                onFinishFailed={handleSubmitFailed}
            >
                <Form.List name="serviceSession" initialValue={[{}]}>
                {
                    (fields, { add, remove }) => {
                        return (
                            <>
                                {
                                    fields.map(({ key, name, ...restField }, index) => {
                                        const label = `Service session ${index + 1}`;
                                        return (
                                            <div key={index} className={s.additionalBox}>
                                                <div className={s.additionalForm}>
                                                    <div className={s.formBody}>
                                                        <div className={s.row}>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    style={{width:'768px'}}
                                                                    label="Service session type"
                                                                    name={[name, 'serviceTypeId']}
                                                                    rules={[{ required: true, message: 'Service session type is required' }]}
                                                                >
                                                                    <Select options={serviceTypeOptions}/>
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    style={{width:'768px'}}
                                                                    label="Service session name"
                                                                    name={[name, 'serviceSessionName']}
                                                                    rules={[{ required: true, message: 'Service session name is required' }]}
                                                                >
                                                                    <Input showCount maxLength={150} />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="Contact type"
                                                                    name={[name, 'contactType']}
                                                                    rules={[{ validator: customContactTypeValidator }]}
                                                                >
                                                                    <CheckboxNRadio options={contactTypeOptions.current} />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="Duration"
                                                                    name={[name, 'duration']}
                                                                    rules={[{ required: true, message: 'Duration is required' }]}
                                                                >
                                                                    <InputNumber addonAfter="Mins" />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="Patient cost for the service"
                                                                    name={[name, 'patientCost']}
                                                                    rules={[{ required: true, message: 'Patient cost for the service is required' }]}
                                                                >
                                                                    <InputNumber addonBefore="$" addonAfter="USD" />
                                                                </Form.Item>
                                                            </div>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="Provider’s earning for this servcie"
                                                                    name={[name, 'providerEarning']}
                                                                >
                                                                    <InputNumber addonBefore="$" addonAfter="USD" />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        {/* <div className={s.row}>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="Quantity"
                                                                    name={[name, 'quantity']}
                                                                    rules={[{ required: true, message: 'Quantity is required' }]}
                                                                >
                                                                    <InputNumber min={0}/>
                                                                </Form.Item>
                                                            </div>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="Renewal"
                                                                    name={[name, 'isRenewal']}
                                                                    valuePropName="checked"
                                                                    rules={[{ required: true, message: 'Renewal is required' }]}
                                                                >
                                                                    <Checkbox>Renew</Checkbox>
                                                                </Form.Item>
                                                            </div>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="Bookable during purchase"
                                                                    name={[name, 'isBookable']}
                                                                    valuePropName="checked"
                                                                    rules={[{ required: true, message: 'Bookable is required' }]}
                                                                >
                                                                    <Checkbox>Bookable</Checkbox>
                                                                </Form.Item>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    <div className={s.formFooter}>
                                                        <Button onClick={() => remove(index)}>Remove</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                                <Form.Item>
                                    <div className={s.additionalTitle} onClick={() => add()}>+ Add another Service sessions</div>
                                </Form.Item>
                            </>
                        );
                    }
                }
                </Form.List>
            </Form>
        </div>
    )
}

export default SessionModal;
