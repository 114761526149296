import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Input } from 'antd';
import type { TAssessmentItem } from 'types/operation';
import {
    addOrUpdateAssessment,
    duplicateAssessment,
} from 'api/operation/assessment';
import {
    EPageStatus,
} from 'types/helloKlarity';
import ConditionSelect from 'components/form/ConditionSelect';
import commonS from 'styles/common.module.less';
import s from './s.module.less';

type IProps = {
    item?: TAssessmentItem;
    itemStatus: EPageStatus;
    onCancel: () => void;
    onSuccess: () => void;
};

const AssessmentModal: React.FC<IProps> = (props) => {
    const [submitting, setSubmitting] = useState(false);
    const [form] = Form.useForm<TAssessmentItem & { active: boolean }>();
    const { item, itemStatus, onCancel, onSuccess } = props;

    const titleMap = {
        [EPageStatus.ADD]: 'Add a new assessment',
        [EPageStatus.EDIT]: 'Assessment information',
        [EPageStatus.DUPLICATE]: 'Duplicate an assessment',
    };

    const onSave = async () => {
        setSubmitting(true);
        try {
            const formValues = await form.validateFields();
            const id = item?.id;

            const params: TAssessmentItem = {
                assessmentName: formValues.assessmentName,
                conditionKeyTags: (formValues.tags || []).join(','),
            };
            if (itemStatus === EPageStatus.EDIT) {
                params.id = id;
            }

            const handleDataFun =
              itemStatus === EPageStatus.DUPLICATE
                  ? duplicateAssessment({
                      id: item?.id || '',
                      ...params,
                  })
                  : addOrUpdateAssessment(params);
            const { data } = await handleDataFun;
            if (data.code === 200) {
                onSuccess?.();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        if (item) {
            if (EPageStatus.DUPLICATE === itemStatus) {
                form.setFieldsValue({
                    assessmentName: `Copy of ${item.assessmentName}`,
                    tags: item.conditionKeyTags?.split(','),
                });
            } else {
                form.setFieldsValue({
                    assessmentName: item.assessmentName,
                    tags: item.conditionKeyTags?.split(','),
                });
            }
        } else {
            form.resetFields();
        }
    }, [item]);
    return (
        <Modal
            title={titleMap[itemStatus]}
            open
            destroyOnClose
            okText="Save"
            onCancel={onCancel}
            footer={
                <div>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button loading={submitting} type="primary" onClick={onSave}>
                        Save
                    </Button>
                </div>
            }
        >
            <div className={s.wrap}>
                <Form
                    form={form}
                    className={commonS.formStyle1}
                    initialValues={{ active: true }}
                    autoComplete="off"
                    layout="vertical"
                    scrollToFirstError
                >
                    <>
                        <Form.Item
                            label="Assessment name"
                            name="assessmentName"
                            validateFirst
                            rules={[
                                { required: true, message: 'Assessment name is required' },
                            ]}
                        >
                            <Input
                                maxLength={70}
                                showCount
                                placeholder="Ex. ADHD self assessment"
                            />
                        </Form.Item>
                        <Form.Item
                            className={s.rowItem}
                            label="Tags"
                            name="tags"
                            rules={[
                                {
                                    required: true,
                                    message: 'Tags is required',
                                },
                            ]}
                        >
                            <ConditionSelect isLabel mode="multiple" />
                        </Form.Item>
                    </>
                </Form>
            </div>
        </Modal>
    );
};

export default AssessmentModal;
