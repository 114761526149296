import { Setting } from 'types/api';
import { EnumFields } from 'types/enumerationData';
import { Meta } from 'types/common';

// helloklarity provider mapping 里，虽然airtable和ehr的Content都是mapped，但需要都返回
const allowDuplicateContentTypes = [EnumFields.PROVIDER_MAPPING_STATUS];

export const transfer = (apiData:Setting[]):Partial<Record<EnumFields, Meta[]>> => {
    const addTypeContent:string[] = []; //server side have multiple data
    const ret:Partial<Record<EnumFields, Meta[]>> = {};
    for (let i = 0; i < apiData.length; i++) {
        const current = apiData[i];
        const key = `${current.type}-${current.content}`;
        if (addTypeContent.includes(key) && !allowDuplicateContentTypes.includes(current.type)) {
            continue;
        }
        addTypeContent.push(key);
        if (!ret[current.type]) {
            ret[current.type] = [{
                label: current.content,
                value: current.dictKey || current.content,
            }];
        } else {
            ret[current.type]!.push({
                label: current.content,
                value: current.dictKey || current.content,
            });
        }
    }
    return ret;
};
