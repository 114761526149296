import { useCallback, useEffect, useState } from 'react';
import { getUniprofileAuditSnapshot } from 'api/operation';
import { transferProviderToFormData } from 'utils/channel';
import { UniprofileFormData } from 'types/provider';

const useFetchAuditDetailSnapshotData = (providerId?:number):{ data?: UniprofileFormData, refetch:()=>void, loading: boolean, orgData:any } => {
    const [data, setData] = useState<UniprofileFormData>();
    const [orgData, setOrgData] = useState<any>(undefined);

    const [loading, setLoading] = useState(false);
    const fetchAuditDetailData = useCallback(async () => {
        if (!providerId) return;
        setLoading(true);
        const res = await getUniprofileAuditSnapshot(providerId);
        // const res = await getUniprofileAuditDetail({
        //     providerId,
        //     step: 'REVIEW',
        // });
        if (!res?.error) {
            setOrgData((res as any).data.data);
            const newData = transferProviderToFormData((res as any).data.data);
            setData(newData);
        } else {
            console.error(res);
        }
        setLoading(false);
    }, [providerId]);

    useEffect(() => {
        fetchAuditDetailData();
    }, [fetchAuditDetailData, providerId]);

    return { data, refetch: fetchAuditDetailData, loading, orgData };
};

export default useFetchAuditDetailSnapshotData;
