import React, {
    useState,
    useEffect,
} from 'react';
import s from './s.module.less';
import { Button, Descriptions, Form, Input, Select } from 'antd';
import type { TResult } from 'types/operation';
import commonS from 'styles/common.module.less';
import FileUpload from 'components/form/FileUpload';
import {
    assessmentResultLevelAddOrUpdate,
} from 'api/operation/assessment';

type IProps = {
    index: number;
    item: TResult;
    onSuccess: (obj: Partial<TResult>) => void;
};

const cardTitle = [
    'Low indication',
    'Moderate indication',
    'High indication',
];

const { Option } = Select;

const ResultsReviewItem: React.FC<IProps> = (props) => {
    const { item, index, onSuccess } = props;
    const [isEdit, setIsEdit] = useState(false);
    const [form] = Form.useForm<TResult & { active: boolean }>();

    const handleEdit = () => {
        setIsEdit(true);
    };

    const handleCancel = () => {
        setIsEdit(false);
    };

    const handleSave = async () => {
        const formValues = await form.validateFields();
        const params = {
            ...item,
            id: item.id,
            title: formValues.title,
            description: formValues.description,
            imgUrl: formValues.imgUrl,
            primaryCta: formValues.primaryCta,
            primaryCtaUrl: formValues.primaryCtaUrl || formValues.primaryCta,
            secondaryCta: formValues.secondaryCta || '',
            secondaryCtaUrl: formValues.secondaryCtaUrl || '',
        };
        const { data } = await assessmentResultLevelAddOrUpdate(params);
        if (!data.error) {
            setIsEdit(false);
            onSuccess(params);
        }
    };

    const renderDescription = () => {
        return (
            <Descriptions
                colon={false}
                title={`${cardTitle[(item.riskLevel || 1) - 1]}`}
                layout="vertical"
                extra={<Button onClick={() => handleEdit()}>Edit</Button>}
                column={1}
                size="small"
                contentStyle={{
                    marginBottom: '16px',
                }}
            >
                <Descriptions.Item label="Title">
                    {item.title || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Description">
                    {item.description || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Image">
                    {item.imgUrl ? (
                        <img src={item.imgUrl} alt={item.title} className={s.cardImg} />
                    ) : (
                        '-'
                    )}
                </Descriptions.Item>
                <Descriptions.Item label="Primary CTA">
                    {item.primaryCta || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Secondary CTA">
                    {item.secondaryCta || '-'}
                </Descriptions.Item>
            </Descriptions>
        );
    };

    const renderForm = () => {
        return (
            <div>
                <div className={s.headerBar}>
                    <h4 className={s.subtitle}>
                        {`${cardTitle[(item.riskLevel || 1) - 1]}`}
                    </h4>
                    <div className={s.buttonWrap}>
                        <Button onClick={() => handleCancel()}>Cancel</Button>
                        <Button
                            onClick={() => handleSave()}
                            type="primary"
                            className={s.ml8}
                        >
                            Save changes
                        </Button>
                    </div>
                </div>
                <p className={s.tips}>
                    <span>* </span>
                    Indicates required fields
                </p>
                <Form
                    form={form}
                    className={commonS.formStyle1}
                    initialValues={{ active: true }}
                    autoComplete="off"
                    layout="vertical"
                    scrollToFirstError
                >
                    <>
                        <Form.Item
                            className={s.rowItem}
                            label="Title"
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: 'Title is required',
                                },
                            ]}
                        >
                            <Input maxLength={40} showCount />
                        </Form.Item>
                        <Form.Item
                            className={s.rowItem}
                            label="Description"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Description is required',
                                },
                            ]}
                        >
                            <Input.TextArea maxLength={300} rows={5} showCount />
                        </Form.Item>
                        <Form.Item className={s.rowItem} name="imgUrl" label="Image">
                            <FileUpload
                                accept="image/png, image/jpeg, image/gif"
                                tips="PNG, JPG, GIF up to 10MB"
                            />
                        </Form.Item>
                        <Form.Item
                            className={s.rowItem}
                            label="Primary CTA"
                            name="primaryCta"
                            rules={[
                                {
                                    required: true,
                                    message: 'Primary CTA is required',
                                },
                            ]}
                        >
                            <Select>
                                <Option value="Find a provider">Find a Provider</Option>
                                <Option value="Collect email">Collect Email</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) => {
                                const type = getFieldValue('primaryCta');
                                if (type === 'Find a provider') {
                                    return (
                                        <Form.Item
                                            className={s.rowItem}
                                            label="Primary CTA URL"
                                            name="primaryCtaUrl"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Primary CTA URL is required',
                                                },
                                            ]}
                                        >
                                            <Input maxLength={512} showCount />
                                        </Form.Item>
                                    );
                                }
                            }}
                        </Form.Item>

                        <Form.Item
                            className={s.rowItem}
                            label="Secondary CTA"
                            name="secondaryCta"
                        >
                            <Select>
                                {/* <Option value="Find a provider">Find a Provider</Option> */}
                                <Option value="Collect email">Collect Email</Option>
                                <Option value="">None</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) => {
                                const type = getFieldValue('secondaryCta');
                                if (type === 'Find a provider') {
                                    return (
                                        <Form.Item
                                            className={s.rowItem}
                                            label="Secondary CTA URL"
                                            name="secondaryCtaUrl"
                                        >
                                            <Input maxLength={512} showCount />
                                        </Form.Item>
                                    );
                                }
                            }}
                        </Form.Item>
                    </>
                </Form>
            </div>
        );
    };

    useEffect(() => {
        if (item) {
            form.setFieldsValue({
                title: item.title,
                description: item.description,
                imgUrl: item.imgUrl,
                primaryCta: item.primaryCta,
                primaryCtaUrl: item.primaryCtaUrl,
                secondaryCta: item.secondaryCta,
                secondaryCtaUrl: item.secondaryCtaUrl,
            });
        } else {
            form.resetFields();
        }
    }, [item]);

    return (
        <div className={s.wrap}>
            <div className={s.content}>
                {isEdit ? renderForm() : renderDescription()}
            </div>
        </div>
    );
};

export default ResultsReviewItem;
