import React, { useCallback, useState } from 'react';
import s from './s.module.less';
import Profile from 'components/ApplicationFormComponents/Profile';
import Services from 'components/ApplicationFormComponents/Services';
import Practice from 'components/ApplicationFormComponents/Practice';
import HealthcareExperience from 'components/ApplicationFormComponents/HealthcareExperience';
import Education from 'components/ApplicationFormComponents/Education';
import AdditionalInformation from 'components/ApplicationFormComponents/AdditionalInformation';
import Insurance from 'components/ApplicationFormComponents/Insurance';
import EHRAccountNAddons from 'components/ApplicationFormComponents/EHRAccountNAddons';
import CollaboratePhysician from 'components/Addons/CollaboratePhysician';
import Assistant from 'components/Addons/Assistant';
import EFax from 'components/Addons/E-Fax';
import { useNavigate, useParams } from 'react-router-dom';
import useGetAddOnDetail from 'hooks/useGetAddOnDetail';
import { Spin } from 'antd';
import { getProviderNameDisplay } from 'utils/provider';
import { PATH } from 'constants/path';

const EHRAddonDetail = () => {
    const navigator = useNavigate();
    const { providerId } = useParams();
    const [providerData,,,refetch] = useGetAddOnDetail(providerId);
    const [collpased, setCollapsed] = useState(false);
    const onSubmit = useCallback(() => {}, []);
    const onShowMessage = useCallback(() => {}, []);
    const profile = providerData?.profile;
    const services = providerData?.services;
    const practice = providerData?.practice;
    const experienceList = providerData?.experienceList;
    const educationList = providerData?.educationList;
    const addtional = providerData?.addtional;
    const insurance = providerData?.insurance;
    const efaxInfo = providerData?.efaxInfo;
    const addonPhysicianList = providerData?.addonPhysicianList;
    const addonList = providerData?.addonList;
    const assistantList = providerData?.assistantList;
    const biographic = providerData?.biographic;

    const handleCollapse = useCallback(() => {
        setCollapsed(!collpased);
    }, [collpased]);

    const handleBack = useCallback(() => {
        navigator(`${PATH.OPERATION}/${PATH.EHR_ADD_ON_OPS}`);
    }, [navigator]);

    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <div className={s.nav} onClick={handleBack}>
                    <div className={s.backIcon} />
                    <div className={s.backText}>Practice enablement add-ons</div>
                </div>
                {
                    providerData && (
                        <div className={s.title}>
                            <div className={s.providerName}>{getProviderNameDisplay(profile)}</div>
                        </div>
                    )
                }
            </div>
            {
                providerData ? (
                    <>
                        <div className={s.block}>
                            <div className={s.collapeseHeader}>
                                <div className={s.title}>Kiwi EHR account and add-ons</div>
                                <div className={s.collapseIcon} onClick={handleCollapse} />
                            </div>
                            {
                                !collpased && (
                                    <>
                                        <EHRAccountNAddons
                                            addonPhysicianList={addonPhysicianList}
                                            addOns={addonList}
                                            providerId={providerId}
                                            data={practice}
                                            biographic={biographic}
                                            provider={profile}
                                            doesSpot={providerData?.efaxInfo?.doseSpotOption}
                                            doesSpotName={providerData?.efaxInfo?.doseSpotName}
                                            hasEhrAccountPwd={providerData?.hasEhrAccountPwd}
                                            refetch={refetch}
                                        />
                                        <CollaboratePhysician
                                            data={addonPhysicianList}
                                        />
                                        <Assistant data={assistantList} />
                                        <EFax data={efaxInfo} />
                                    </>
                                )
                            }
                        </div>
                        <div className={s.uniprofileBlock}>
                            <Profile isOps providerId={providerId} showEditButtons={false} editable={false} initFormValue={profile} onSubmit={onSubmit} />
                        </div>
                        <div className={s.uniprofileBlock}>
                            <Services isOps providerId={providerId} showEditButtons={false} initFormValue={services} refetch={refetch} onShowMessage={onShowMessage} />
                        </div>

                        <div className={s.uniprofileBlock}>
                            <Practice isOps showEditButtons={false} editable={false} initFormValue={practice} initPracticeName="" onSubmit={onSubmit} refetch={refetch} />
                        </div>
                        <div className={s.uniprofileBlock}>
                            <HealthcareExperience showEditButtons={false} editable={false} initFormValue={experienceList} onSubmit={onSubmit} />
                        </div>
                        <div className={s.uniprofileBlock}>
                            <Education showEditButtons={false} editable={false} initFormValue={educationList} onSubmit={onSubmit} />
                        </div>
                        <div className={s.uniprofileBlock}>
                            <AdditionalInformation showEditButtons={false} editable={false} initFormValue={addtional} onSubmit={onSubmit} />
                        </div>
                        <div className={s.uniprofileBlock}>
                            <Insurance isOps showEditButtons={false} editable={false} initFormValue={insurance} onSubmit={onSubmit} />
                        </div>
                    </>
                ) : <Spin spinning />
            }
        </div>
    );
};

export default EHRAddonDetail;
