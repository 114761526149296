import React from 'react';
import s from './s.module.less';

import SearchInit from './components/SearchInit';
import SearchResult from './components/SearchResult';

import SearchInitV2 from './components/SearchInit/test';
import { TTableData } from 'types/ai';
import { getUrlParam, insertUrlParam, removeUrlParam } from 'utils/common';
import { getAiSearchId, getAiSearchName, setAiSearchId, setAiSearchName } from 'utils/sessionStorage';

const Page = () => {
    const cid = getUrlParam('cid');
    const aiSearchId = getAiSearchId();
    const aiSearchName = getAiSearchName();
    //const canRecover = cid && aiSearchId && aiSearchName;
    const canRecover = false;
    const [mode, setMode] = React.useState(canRecover ? 1 : 0);
    const [currentConversationId, setCurrentConversationId] = React.useState<string | undefined>(canRecover ? cid : undefined); //'270a71b8-6b4d-406f-9641-657b143bd8ac'
    const [currentConversationName, setCurrentConversationName] = React.useState<string | undefined>(canRecover ? aiSearchName : undefined); // 'Show me providers in TX, the provider should has experiences with ADHD medications.'

    const [searchValue, setSearchValue] = React.useState<string>();
    const messageIdProviderMap = React.useRef<Record<string, TTableData>>({});

    const [pendingCurrentConversationId, setPendingCurrentConversationId] = React.useState<string | undefined>(); //
    const [pengdingCurrentConversationName, setPendingConversationName] = React.useState<string | undefined>(); //

    React.useEffect(() => {
        if (mode && pendingCurrentConversationId && pengdingCurrentConversationName) {
            setCurrentConversationId(pendingCurrentConversationId);
            setCurrentConversationName(pengdingCurrentConversationName);
            setMode(1);
            setPendingConversationName(undefined);
            setPendingCurrentConversationId(undefined);
        }
    }, [pendingCurrentConversationId, pengdingCurrentConversationName, mode]);

    React.useEffect(() => {
        if (mode === 0) {
            setAiSearchId('');
            removeUrlParam('cid');
        } else {
            setAiSearchId(currentConversationId || '');
            setAiSearchName(currentConversationName || '');
            insertUrlParam('cid', currentConversationId || '');
        }
    }, [mode, currentConversationId, currentConversationName]);

    return (
        <div className={s.wrap}>
            {mode === 0 &&
            <SearchInit
                onConversationUpdateFromHistory={(id, name) => {
                    if (id) {
                        setCurrentConversationId(id);
                        setCurrentConversationName(name);
                        setMode(1);
                    }
                }}
                onSearch={(val:string) => {
                    setSearchValue(val);
                    if (val) {
                        setCurrentConversationId(undefined);
                        setCurrentConversationName(undefined);
                        setMode(1);
                    }
                }}
            />}
            {mode === 1 &&
            <SearchResult
                messageIdProviderMap={messageIdProviderMap.current}
                onConversationUpdateFromHistory={(id, name) => {
                    if (id) {
                        setPendingCurrentConversationId(id);
                        setPendingConversationName(name);
                        //hide all
                        setMode(99);
                    }
                }}
                conversationId={currentConversationId}
                onNewSearch={() => {
                    setMode(0);
                }}
                searchValue={searchValue}
                conversationName={currentConversationName}
            />
            }
            {
                mode === 3 &&
                <SearchInitV2 />
            }
        </div>
    );
};

export default Page;
