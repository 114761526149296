import React from 'react';
import { Modal, Form, message, Input } from 'antd';
import commonS from 'styles/common.module.less';
import { FormFieldEnum, FormInterface } from './types';
import { Editor, Toolbar } from '@wangeditor/editor-for-react';
import { IDomEditor, IEditorConfig, IToolbarConfig, i18nChangeLanguage } from '@wangeditor/editor';
import s from './s.module.less';
import { REQUIRED_FIELD } from 'constants/common';
import { TFaqInfo } from 'types/operation';
import HtmlContentInput from 'components/form/HtmlContentInput';
import { updateHelloKlairtyKlarityPageFaq } from 'api/operation';

type Props = {
    onCancel:()=>void
    item?:TFaqInfo //for edit fun
    onSuccess?: ()=>void
    open?: boolean;
    index?: number;
    targetIndex?: number;
    pageId: number;
};

const EditContentModal = (props:Props) => {
    const { onCancel, item, onSuccess, open, index, targetIndex, pageId } = props;
    const [form] = Form.useForm<FormInterface>();
    const [,forceUpdate] = React.useState({});
    const [submiting, setSubmiting] = React.useState(false);
    const isEdit = !!item?.id;

    const [config, setConfig] = React.useState<Partial<IToolbarConfig>>(
        {
            toolbarKeys: [
                'undo',
                'redo',
                'headerSelect',
                {
                    key: 'group-justify',
                    title: '对齐',
                    iconSvg: '<svg viewBox="0 0 1024 1024"><path d="M768 793.6v102.4H51.2v-102.4h716.8z m204.8-230.4v102.4H51.2v-102.4h921.6z m-204.8-230.4v102.4H51.2v-102.4h716.8zM972.8 102.4v102.4H51.2V102.4h921.6z"></path></svg>',
                    menuKeys: [
                        'justifyLeft',
                        'justifyRight',
                        'justifyCenter',
                        'justifyJustify',
                    ],
                },
                'bold',
                'italic',
                'underline',
                'insertLink',
                'bulletedList',
                'numberedList',
            ],
        },
    );

    const onOk = async () => {
        try {
            setSubmiting(true);
            const formValues = await form.validateFields();
            let data:TFaqInfo | undefined;
            if (!isEdit) {
                data = {
                    pageId,
                    seq: targetIndex!,
                    answer: formValues[FormFieldEnum.ANSWER],
                    question: formValues[FormFieldEnum.QUESTION],
                };
            } else {
                data = {
                    ...item!,
                    answer: formValues[FormFieldEnum.ANSWER],
                    question: formValues[FormFieldEnum.QUESTION],
                };
            }

            const result = await updateHelloKlairtyKlarityPageFaq(data!);
            if (!result.error) {
                message.success('Update success');
                onSuccess?.();
            }
        } catch (e:any) {
            if (e.errorFields) {
                //form error
                return;
            }
            const msg = e?.toString?.() || 'data error';
            message.error(msg);
        } finally {
            setSubmiting(false);
        }
    };
    React.useEffect(() => {
        if (item) {
            form.setFieldsValue({
                [FormFieldEnum.ANSWER]: item.answer,
                [FormFieldEnum.QUESTION]: item.question,
            });
            forceUpdate({});
        } else {
            form.setFieldsValue({
                [FormFieldEnum.ANSWER]: '',
                [FormFieldEnum.QUESTION]: '',
            });
        }
    }, [item]);

    React.useEffect(() => {
        if (!open) {
            form.setFieldsValue({
                [FormFieldEnum.ANSWER]: '',
                [FormFieldEnum.QUESTION]: '',
            });
        }
    }, [open]);

    return (
        <Modal
            title={isEdit ? `Edit FAQ ${index ? index + 1 : '1'}` : 'Add new FAQ'}
            closable
            className={commonS.modalFixHeightWrap}
            width="650px"
            destroyOnClose
            onCancel={() => {
                onCancel?.();
            }}
            onOk={onOk}
            okText="Save change"
            open={open}
            okButtonProps={{
                loading: submiting,
            }}
        >
            <Form
                form={form}
                name="basic"
                className={commonS.formStyle1}
                initialValues={{ remember: true }}
                autoComplete="off"
                layout="vertical"
            >
                <div className={s.module}>
                    <div className={s.row}>
                        <div
                            className={s.rowItem}
                            style={{ width: '100%' }}
                        >
                            <Form.Item
                                label="Question"
                                name={[FormFieldEnum.QUESTION]}
                                validateFirst
                                rules={[
                                    { required: true, message: REQUIRED_FIELD },
                                ]}
                            >
                                <Input.TextArea
                                    rows={3}
                                    maxLength={200}
                                    showCount
                                />
                            </Form.Item>
                        </div>
                    </div>
                    {/* <div className={s.row}>
                        <div
                            className={s.rowItem}
                            style={{ width: '100%' }}
                        >
                            <Form.Item
                                label="Answer"
                                name={[FormFieldEnum.ANSWER]}
                                validateFirst
                                rules={[
                                    { required: true, message: REQUIRED_FIELD },
                                ]}
                            >
                                <Input.TextArea
                                    rows={7}
                                    maxLength={3000}
                                    showCount
                                />
                            </Form.Item>
                        </div>
                    </div> */}
                    <div className={s.row}>
                        <div
                            className={s.rowItem}
                            style={{ width: '100%' }}
                        >
                            <Form.Item
                                label="Answer"
                                name={[FormFieldEnum.ANSWER]}
                                validateFirst
                                rules={[
                                    { required: true, message: REQUIRED_FIELD },
                                    { max: 3000, message: 'Answer cannot be longer than 3000 characters' },
                                ]}
                            >
                                <HtmlContentInput
                                    isClosed={!open}
                                    header={null}
                                    footer={null}
                                    config={config}
                                    height="300px"
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal>
    );
};

export default EditContentModal;
