// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--cPMYV .s-module__block--fAT_T {
  background-color: var(--white);
  border-radius: 8px;
  margin-bottom: 32px;
  padding: 12px 24px 40px;
}
.s-module__wrap--cPMYV .s-module__block--fAT_T .s-module__title--YCRQ2 {
  margin-bottom: 24px;
  padding: 10px 0 6px;
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  color: var(--gray-800);
  border-bottom: 1px solid var(--gray-300);
}
.s-module__wrap--cPMYV .s-module__formItem--pTkR4.s-module__horizon--fSpxp {
  display: flex;
  align-items: center;
}
.s-module__wrap--cPMYV .s-module__formItem--pTkR4 .s-module__label--SnGVl {
  margin-bottom: 8px;
}
.s-module__wrap--cPMYV .s-module__formItem--pTkR4 .s-module__alertBox--k8M63 {
  margin-bottom: 8px;
}
.s-module__wrap--cPMYV .s-module__formListBox--lh4Rr {
  border-radius: 10px;
  background-color: #F9FAFB;
  padding: 32px 24px;
}
.s-module__wrap--cPMYV .s-module__formListBox--lh4Rr .s-module__add--vaJ2I {
  margin-top: 24px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--main-green);
  cursor: 2222;
}
`, "",{"version":3,"sources":["webpack://./src/pages/operation/KlarityProgramDetailPage/components/PackageDetail/components/Pricing/s.module.less"],"names":[],"mappings":"AAAA;EAEQ,8BAAA;EACA,kBAAA;EACA,mBAAA;EACA,uBAAA;AAAR;AALA;EAQY,mBAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,sBAAA;EACA,wCAAA;AAAZ;AAMQ;EACI,aAAA;EACA,mBAAA;AAJZ;AApBA;EA2BY,kBAAA;AAJZ;AAvBA;EA+BY,kBAAA;AALZ;AA1BA;EAoCQ,mBAAA;EACA,yBAAA;EACA,kBAAA;AAPR;AA/BA;EAyCY,gBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,wBAAA;EACA,YAAA;AAPZ","sourcesContent":[".wrap {\n    .block {\n        background-color: var(--white);\n        border-radius: 8px;\n        margin-bottom: 32px;\n        padding: 12px 24px 40px;\n\n        .title {\n            margin-bottom: 24px;\n            padding: 10px 0 6px;\n            font-family: Inter;\n            font-size: 20px;\n            font-weight: 500;\n            line-height: 28px;\n            text-align: left;\n            color: var(--gray-800);\n            border-bottom: 1px solid var(--gray-300);\n        }\n    }\n\n    .formItem {\n\n        &.horizon {\n            display: flex;\n            align-items: center;\n        }\n        .label {\n            margin-bottom: 8px;\n        }\n\n        .alertBox {\n            margin-bottom: 8px;\n        }\n    }\n\n    .formListBox {\n        border-radius: 10px;\n        background-color: #F9FAFB;\n        padding: 32px 24px;\n\n        .add {\n            margin-top: 24px;\n            font-family: Inter;\n            font-size: 14px;\n            font-weight: 500;\n            line-height: 20px;\n            text-align: left;\n            color: var(--main-green);\n            cursor: 2222;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--cPMYV`,
	"block": `s-module__block--fAT_T`,
	"title": `s-module__title--YCRQ2`,
	"formItem": `s-module__formItem--pTkR4`,
	"horizon": `s-module__horizon--fSpxp`,
	"label": `s-module__label--SnGVl`,
	"alertBox": `s-module__alertBox--k8M63`,
	"formListBox": `s-module__formListBox--lh4Rr`,
	"add": `s-module__add--vaJ2I`
};
export default ___CSS_LOADER_EXPORT___;
