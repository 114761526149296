import TinyStore from 'lib/tinyStore';

type DataModal = {
    loading?: boolean,
    data?: Record<string, {
        percent: number;
        unfinished: []
    }>,
};

const Store = TinyStore.createStore<DataModal>({
    nameSpace: 'klarity',
    key: 'uniprofileResumeStepPercentage',
    crossBundle: false,
    runTime: true,
    initValue: {
        data: {
            profile: {
                percent: 0,
                unfinished: [],
            },
            experienceList: {
                percent: 0,
                unfinished: [],
            },
            educationList: {
                percent: 0,
                unfinished: [],
            },
            recognition: {
                percent: 0,
                unfinished: [],
            },
            practice: {
                percent: 0,
                unfinished: [],
            },
        },
        loading: false,
    },
});
export default Store;
