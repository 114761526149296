import TinyStore from 'lib/tinyStore';

type DataUpdatedFlagModal = {
    patientFeedbackListUpdated?: undefined | {};
    patientListUpdated?: undefined | {}
    surveyDataUpdated?: undefined | {}
};

const Store = TinyStore.createStore<DataUpdatedFlagModal>({
    nameSpace: 'klarity',
    key: 'dataUpdatedFlag',
    crossBundle: false,
    runTime: true,
    initValue: { patientFeedbackListUpdated: undefined },
});
export default Store;
