export enum ServicesFormFieldEnum {
    IN_PERSON = 'inperson',
    ZIP_CODE = 'personZip',
    ADDRESS = 'personAddress',
    CITY = 'personCity',
    STATE = 'personState',
    DURATION = 'duration',
    INTAKE_FORM = 'intakeForm',
    NAME = 'name',
    PERSON_TYPE = 'personType',
    TELEHEALTH_TYPE = 'telehealthType',
    PATIENT_TYPE_IN_PERSON = 'patientTypeInPerson',
    PATIENT_TYPE_TELEHEALTH = 'patientTypeTelehealth',
    CONSULTATION_SESSION_INTAKE_FORM = 'consultationSessionIntakeForm',
    CONSULTATION_SESSION_DURATION = 'consultationSessionDuration',
    INITIAL_VISIT_DURATION = 'initialVisitDuration',
    INITIAL_VISIT_INTAKE_FORM = 'initialVisitIntakeForm',
    FOLLOW_UP_DURATION = 'followUpDuration',
    FOLLOW_UP_INTAKE_FORM = 'followUpIntakeForm',
    REFILL = 'refill',
    REFILL_DURATION = 'refillDuration',
    REFILL_INTAKE_FORM = 'refillIntakeForm',
    CHECKED = 'checked',
    DESCRIPTION = 'description',
    ID = 'id',
    IN_PERSON_CHECKED = 'inpersonChecked',
    TELEHEALTH_CHECKED = 'telehealthChecked',
    TYPE = 'type',
    COLOR_ID = 'colorId',
    PRICE = 'price',
    ADDRESS_ID = 'addressId',
    SEND_FORM = 'sendForm',
    ENABLE_SLIDING_SCALE = 'enableSlidingScale',
    LOWEST_PRICE = 'lowestPrice',
    HIGHEST_PRICE = 'highestPrice',
}

export interface ProviderChannelService {
    [ServicesFormFieldEnum.ID]: number;
    [ServicesFormFieldEnum.IN_PERSON]: boolean;
    [ServicesFormFieldEnum.ZIP_CODE]: string;
    [ServicesFormFieldEnum.ADDRESS]: string;
    [ServicesFormFieldEnum.CITY]: string;
    [ServicesFormFieldEnum.STATE]: string;
    [ServicesFormFieldEnum.DURATION]: number;
    [ServicesFormFieldEnum.INTAKE_FORM]: string;
    [ServicesFormFieldEnum.NAME]: string;
    [ServicesFormFieldEnum.PERSON_TYPE]: Array<string>;
    [ServicesFormFieldEnum.TELEHEALTH_TYPE]: Array<string>;
    [ServicesFormFieldEnum.CHECKED]: boolean;
    [ServicesFormFieldEnum.DESCRIPTION]: string;
    [ServicesFormFieldEnum.IN_PERSON_CHECKED]?: boolean;
    [ServicesFormFieldEnum.TELEHEALTH_CHECKED]: boolean;
    [ServicesFormFieldEnum.TYPE]: string;
    [ServicesFormFieldEnum.COLOR_ID]: string;
    [ServicesFormFieldEnum.PRICE]: number;
    [ServicesFormFieldEnum.ADDRESS_ID]: number;
    [ServicesFormFieldEnum.SEND_FORM]: string;
    [ServicesFormFieldEnum.ENABLE_SLIDING_SCALE]: boolean;
    [ServicesFormFieldEnum.LOWEST_PRICE]?: number;
    [ServicesFormFieldEnum.HIGHEST_PRICE]?: number;
}

export enum HealthcareExperienceFieldEnum {
    ORGANIZATION_NAME = 'organizationName',
    TITLE = 'title',
    IS_CURRENTLY_WORK = 'currently',
    START_DATE = 'startDate',
    END_DATE = 'endDate',
    EMPLOYMENT_TYPE = 'employmentType',
    HOSPITAL_LOCATION = 'hospitalLocation',
    DESCRIPTION = 'description',
    LOGO = 'experienceLogo',
}

export interface ProviderChannelHealthcareExperience {
    [HealthcareExperienceFieldEnum.ORGANIZATION_NAME]: string,
    [HealthcareExperienceFieldEnum.TITLE]: string,
    [HealthcareExperienceFieldEnum.IS_CURRENTLY_WORK]: boolean,
    [HealthcareExperienceFieldEnum.START_DATE]: string,
    [HealthcareExperienceFieldEnum.END_DATE]: string,
    [HealthcareExperienceFieldEnum.EMPLOYMENT_TYPE]: string,
    [HealthcareExperienceFieldEnum.HOSPITAL_LOCATION]: string,
    [HealthcareExperienceFieldEnum.DESCRIPTION]: string,
    [HealthcareExperienceFieldEnum.LOGO]: string,
}

export enum EducationFieldEnum {
    SCHOOL_NAME = 'schoolName',
    MAJOR = 'major',
    DEGREE = 'degree',
    SPECIALTIES = 'specialties',
    CERTIFICATES = 'certificates',
    IS_PURSUING_DEGREE = 'currently',
    START_DATE = 'startDate',
    END_DATE = 'endDate',
    LOGO = 'schoolLogo',

}

export interface ProviderChannelEducation {
    [EducationFieldEnum.SCHOOL_NAME]: string,
    [EducationFieldEnum.MAJOR]: string,
    [EducationFieldEnum.IS_PURSUING_DEGREE]: boolean,
    [EducationFieldEnum.DEGREE]: string,
    [EducationFieldEnum.SPECIALTIES]: string,
    certificates: string,
    [EducationFieldEnum.START_DATE]: string,
    endDate: string,
    [EducationFieldEnum.LOGO]: string,
}

export enum AdditionFieldEnum {
    ADDITION_TYPE = 'additionalType',
    AUTHORS = 'authors',
    ID = 'id',
    NAME = 'name',
    WEBSITE = 'website',
}

export interface AddtionalList {
    [AdditionFieldEnum.ADDITION_TYPE]: string;
    [AdditionFieldEnum.AUTHORS]: string;
    // [AdditionFieldEnum.ID]: number;
    [AdditionFieldEnum.NAME]: string;
    [AdditionFieldEnum.WEBSITE]: string;
}
export interface ProviderChannelAdditional {
    payout: string;
    showReview: boolean;
    associationAdditionalList: Array<AddtionalList>;
    awardAdditionalList: Array<AddtionalList>;
    publicationAdditionalList: Array<AddtionalList>;
}

export interface ProviderPractice {
    treatmentPhilosophy?: string;
    treatmentApproach?: string;
    practiceName?: string;
    practiceAddress?: string;
    practiceCity?: string;
    practiceState?: string;
    practiceZip?: string;
    practiceEmail?: string;
    practicePhone?: string;
    practiceWebsite?: string;
    companyType?: string;
    practiceDesc?: string;
    practiceLogo?: string;
    specialityInFamily?: string;
    specialityInHealth?: string;
    practiceFrontUrl: string;
}

// export interface ProviderExperience {
//     organizationName: string,
//     title: string,
//     startDate: string,
//     endDate: string,
//     employmentType: string,
//     hospitalLocation: string,
//     desc: string,
//     experienceLogo: string,
//     currently: string,
// }

// export interface ProviderEducation {
//     schoolName: string,
//     major: string,
//     degree: string,
//     startDate: string,
//     endDate: string,
//     specialties: string,
//     certificates: string,
//     schoolLogo: string,
//     currently: string,
// }

export interface ProviderAdditionalList {
    id?: string,
    additionalType?: string,
    name?: string,
    website?: string,
    authors?: string,
}

export interface Provider {
    id: number,
    firstName: string,
    lastName: string,
    email: string,
    tel: string,
    gmail: string,
    address: string,
    city: string,
    zip: string,
    state: string,
    npi: string,
    yearExp: string,
    specialityInHealth: Array<string>,
    specialityInFamily: Array<string>,
    title: string,
    intro: string,
    primaryLanguage: string,
    foreignLanguage: Array<string>,
    inPersonChecked: boolean,
    officeAddress: string,
    officeCity: string,
    officeState: string,
    officeZip: string,
    payout: string,
    payment: string,
    showReview: boolean,
    allowAuth: boolean,
    photoList: Array<string>,
    additionalPhotoList: Array<string>,
    // inPersonVisitList: Array<any>,
    // telehealthVisitList: Array<any>,
    // providerChannelServiceList: Array<ProviderChannelService>,
    // providerPractice: ProviderPractice,
    // providerExperienceList: Array<ProviderExperience>,
    // providerEducationList: Array<ProviderEducation>,
    // providerAdditionalList: Array<ProviderAdditionalList>,
    licenses: Array<string>
    status: string;
    listStatus: string;
    auditStatus: string;
    photo: string;
    website: string;
    middleName: string;
    credential: Array<string>;
    headLine: string;
    npiNotRequired: boolean;
}

export interface ISubmitProvider {
    id: number,
    firstName: string,
    lastName: string,
    email: string,
    tel: string,
    gmail: string,
    address: string,
    city: string,
    zip: string,
    state: string,
    npi: string,
    yearExp: string,
    specialityInHealth: Array<string>,
    specialityInFamily: Array<string>,
    title: string,
    intro: string,
    primaryLanguage: string,
    foreignLanguage: Array<string>,
    inPersonChecked: boolean,
    officeAddress: string,
    officeCity: string,
    officeState: string,
    officeZip: string,
    payout: string,
    payment: string,
    showReview: boolean,
    allowAuth: boolean,
    photoList: Array<string>,
    additionalPhotoList: Array<string>,
    // inPersonVisitList: Array<any>,
    // telehealthVisitList: Array<any>,
    // providerChannelServiceList: Array<ProviderChannelService>,
    // providerPractice: ProviderPractice,
    // providerExperienceList: Array<ProviderExperience>,
    // providerEducationList: Array<ProviderEducation>,
    // providerAdditionalList: Array<ProviderAdditionalList>,
    licenses: Array<string>
    status: string;
    listStatus: string;
    auditStatus: string;
    photo: string;
    website: string;
    middleName: string;
    credential: string;
    headLine: string;
}

export interface IReviews {
    anonymous: boolean;
    id: number;
    ratings: number;
    reviewerName: string;
    reviews: string;
    verified: boolean;
}

export interface IProfileFormSubmit extends ISubmitProvider {
    // providerChannelServiceList: Array<ProviderChannelService>;
    providerEducationList: Array<ProviderChannelEducation>;
    providerExperienceList: Array<ProviderChannelHealthcareExperience>;
    providerPractice: ProviderPractice;
    publicationAdditionalList: Array<ProviderAdditionalList>;
    providerChannelServiceIdList: Array<number>;
    insuranceList: Array<string>;
    otherInsuranceList: Array<string>;
    associationAdditionalList: Array<ProviderAdditionalList>;
    awardAdditionalList: Array<ProviderAdditionalList>;
}
