import { Input, message, Descriptions, Button, Form, Select } from 'antd';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import s from './s.module.less';
import { updateOpsChannelWebsite,
    getGoogleTitleList,
} from 'api/operation';
import commonS from 'styles/common.module.less';
import type { ProviderProfile } from 'types/provider';
import type { SelectProps } from 'antd';
import type { UpdateOpsChannelWebsiteInput } from 'types/operation';

interface IProps {
    channelId: number;
    providerId: number;
    profile: Partial<ProviderProfile>;
    refetch: () => void;
}

type TChannelExtraInfo = {
    website?: string;
    channelMappingAccount?: string;
    channelMappingPassword?: string;
    googleTitle?: string;
};

const GoLiveCard = ({
    channelId,
    providerId,
    profile,
    refetch,
}: IProps) => {
    const [edit, setEdit] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm<TChannelExtraInfo & { active: boolean }>();
    const [googleTitleList, setGoogleTitleList] = useState<
    SelectProps['options']
    >([]);

    const getList = useCallback(async () => {
        const { data } = await getGoogleTitleList();
        setGoogleTitleList(
            data.data.map((item) => {
                return {
                    value: item,
                    label: item,
                };
            }),
        );
    }, []);

    const handleChangeEditStatus = useCallback(() => {
        setEdit(!edit);
    }, [edit]);

    const handleSave = useCallback(async () => {
        const formValues = await form.validateFields();
        let params: UpdateOpsChannelWebsiteInput = {
            channelId,
            providerId,
            website: formValues.website || '',
        };
        if (profile.needAccount && profile.googleChannel) {
            params = {
                ...params,
                googleTitle: formValues.googleTitle,
            };
        }
        if (profile.needAccount && !profile.googleChannel) {
            params = {
                ...params,
                channelMappingAccount: formValues.channelMappingAccount,
                channelMappingPassword: formValues.channelMappingPassword,
            };
        }
        const res = await updateOpsChannelWebsite(params);

        if (!res?.error) {
            setEdit(false);
            refetch();
        } else {
            messageApi.error(res.error);
        }
    }, [channelId, messageApi, providerId, refetch, form]);

    const handleCancel = useCallback(() => {
        setEdit(false);
    }, []);

    useEffect(() => {
        form.setFieldsValue({
            website: profile.website,
            channelMappingAccount: profile.channelMappingAccount,
            channelMappingPassword: profile.channelMappingPassword,
            googleTitle: profile.googleTitle,
        });
        if (profile.googleChannel) {
            getList();
        }
    }, [profile]);

    return (
        <div className={s.wrap}>
            {contextHolder}
            <div className={s.title}>
                Have you added the provider&apos;s information to the Channel
                correctly?
            </div>
            <div className={s.modifyBox}>
                {edit ? (
                    <div className={s.formWrap}>
                        <Form
                            form={form}
                            className={commonS.formStyle1}
                            initialValues={{ active: true }}
                            autoComplete="off"
                            layout="vertical"
                            scrollToFirstError
                        >
                            <>
                                <Form.Item
                                    className={s.rowItem}
                                    label="Website"
                                    name="website"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Website is required',
                                        },
                                    ]}
                                >
                                    <Input maxLength={200} showCount />
                                </Form.Item>
                                {profile.needAccount && !profile.googleChannel && (
                                    <>
                                        <Form.Item
                                            className={s.rowItem}
                                            label="Account name"
                                            name="channelMappingAccount"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Account name is required',
                                                },
                                            ]}
                                        >
                                            <Input maxLength={200} showCount />
                                        </Form.Item>
                                        <Form.Item
                                            className={s.rowItem}
                                            label="Password"
                                            name="channelMappingPassword"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Password is required',
                                                },
                                            ]}
                                        >
                                            <Input maxLength={200} showCount />
                                        </Form.Item>
                                    </>
                                )}
                                {profile.needAccount && profile.googleChannel && (
                                    <Form.Item
                                        className={s.rowItem}
                                        label="Google title"
                                        name="googleTitle"
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Select a provider"
                                            options={googleTitleList}
                                            defaultActiveFirstOption={false}
                                        />
                                    </Form.Item>
                                )}
                            </>
                        </Form>
                        <div className={s.buttonWrap}>
                            <Button
                                type="default"
                                onClick={handleCancel}
                                className={s.cancelButton}
                            >
                                Cancel
                            </Button>
                            <Button type="primary" onClick={handleSave}>
                                Save
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className={s.displayWrap}>
                        <Descriptions
                            colon={false}
                            title=""
                            layout="horizontal"
                            extra={
                                <Button onClick={() => handleChangeEditStatus()}>Edit</Button>
                            }
                            column={3}
                            size="small"
                            contentStyle={{
                                marginBottom: '16px',
                            }}
                        >
                            <Descriptions.Item label="Website">
                                {profile.website}
                            </Descriptions.Item>
                            {profile.needAccount && profile.googleChannel && (
                                <Descriptions.Item label="Google title">
                                    {profile.googleTitle || '-'}
                                </Descriptions.Item>
                            )}
                            {profile.needAccount && !profile.googleChannel && (
                                <>
                                    <Descriptions.Item label="Account name">
                                        {profile.account}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Password">
                                        {profile.password}
                                    </Descriptions.Item>
                                </>
                            )}
                        </Descriptions>
                    </div>
                )}
            </div>
        </div>
    );
};

export default GoLiveCard;
