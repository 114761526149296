// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__checkbox--hoo9v {
  color: rgba(0, 0, 0, 0.85);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}
.s-module__warning--DDtud {
  color: var(--red-500);
}
`, "",{"version":3,"sources":["webpack://./src/pages/operation/HelloKlarityReviews/components/ReviewPrivateSettingPopup/s.module.less"],"names":[],"mappings":"AAAA;EACI,0BAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACF,aAAa;AACf;AAAA;EACI,qBAAA;AAEJ","sourcesContent":[".checkbox {\n    color: rgba(0, 0, 0, 0.85);\n    font-family: Inter;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 22px; /* 157.143% */\n}\n.warning {\n    color: var(--red-500);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox": `s-module__checkbox--hoo9v`,
	"warning": `s-module__warning--DDtud`
};
export default ___CSS_LOADER_EXPORT___;
