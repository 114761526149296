import React, { useState } from 'react';
import { Modal, Form, Input, Button, message, Switch } from 'antd';
import commonS from 'styles/common.module.less';
import s from './s.module.less';
import { kiwiPageCreate, kiwiPageUpdate } from 'api/operation';
import { TPage } from 'types/kiwiHealth';
import ColorSelect from 'components/form/ColorSelect';
import { EStatus } from 'types/common';

type Props = {
    isOps?: boolean
    item?: TPage
    onCancel?:()=>void
    onSuccess?: ()=>void
};

const CreateAndEditPage = ({ item, onCancel, onSuccess }:Props) => {
    const [submiting, setSubmiting] = useState(false);
    const [form] = Form.useForm<TPage & { active:boolean }>();
    const isEdit = !!item?.id;
    const [check, setCheck] = React.useState(true);

    const onSave = async () => {
        setSubmiting(true);
        try {
            const formValues = await form.validateFields();
            const id = item?.id;

            const params :TPage = {
                name: formValues.name,
                tagColor: formValues.tagColor,
                pagePath: formValues.pagePath,
                status: check ? EStatus.Active : EStatus.Archived,
            };
            if (isEdit) {
                params.id = id;
            }
            const apiFunc = isEdit ? kiwiPageUpdate(params, id!) : kiwiPageCreate(params);
            const { data } = await apiFunc;
            if (data.code === 200) {
                onSuccess?.();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setSubmiting(false);
        }
    };

    React.useEffect(() => {
        if (item) {
            form.setFieldsValue({
                name: item.name,
                tagColor: item.tagColor,
                pagePath: item.pagePath,
                active: item.status === 'Active',
            });
            setCheck(item.status === EStatus.Active);
        } else {
            form.resetFields();
        }
    }, [item]);

    return (
        <Modal
            title={isEdit ? 'Edit page' : 'Add a new page'}
            closable
            className={commonS.modalFixHeightWrap}
            width="500px"
            destroyOnClose
            onCancel={onCancel}
            okText="Save"
            open
            footer={
                <div>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button loading={submiting} type="primary" onClick={onSave}>Save</Button>
                </div>
            }
        >
            <div className={s.wrap}>
                <Form
                    form={form}
                    className={commonS.formStyle1}
                    initialValues={{ active: true }}
                    autoComplete="off"
                    layout="vertical"
                    scrollToFirstError
                >
                    <>
                        <Form.Item
                            className={s.rowItem}
                            label="Page name"
                            name="name"
                            rules={[{ required: true, message: 'Page name is required' }]}
                        >
                            <Input maxLength={40} style={{ width: '100%' }} />
                        </Form.Item>

                        <Form.Item
                            className={s.rowItem}
                            label="Web path"
                            name="pagePath"
                            rules={[{ required: true, message: 'Web path is required' }]}
                        >
                            <Input style={{ width: '100%' }} />
                        </Form.Item>

                        <Form.Item
                            className={s.rowItem}
                            label="Tag color"
                            name="tagColor"
                            rules={[{ required: true, message: 'Web path is required' }]}
                        >
                            <ColorSelect useAdminApi style={{ width: '150px' }} />
                        </Form.Item>
                        <div>
                            <Form.Item
                                className={s.rowItem}
                                label="Status"
                                name="active"
                            >
                                <Switch
                                    onChange={(e) => {
                                        setCheck(e);
                                    }}
                                    checked={check}
                                />
                            </Form.Item>
                            <span className={s.switchLabel}>{check ? EStatus.Active : EStatus.Archived}</span>
                        </div>
                    </>
                </Form>
            </div>
        </Modal>
    );
};

export default CreateAndEditPage;
