import React from 'react';
import s from './s.module.less';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import ProviderPerformance from './components/ProviderPerformance';
import AllReviews from './components/AllReviews';
import Complaints from './components/Complaints';
import { PermissionModuleKeys } from 'constants/access';
import AccessDenied from 'components/AccessDenied';
import AccessStore from 'store/Access/permission';
import DisputeReviews from './components/DisputeReviews';

const PageManagementPage = () => {
    const [key, setKey] = React.useState<string>('1');
    const [getAccessStore] = AccessStore.useStore();
    const access = getAccessStore('data');
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Provider performance',
            children: access?.[PermissionModuleKeys.PROVIDER_REVIEW_PERFORMANCE] ? (
                <ProviderPerformance />
            ) : (
                <AccessDenied />
            ),
        },
        {
            key: '2',
            label: 'All reviews',
            children: access?.[PermissionModuleKeys.PROVIDER_REVIEW_LIST] ? (
                <AllReviews />
            ) : (
                <AccessDenied />
            ),
        },
        {
            key: '3',
            label: 'Disputed reviews',
            children: access?.[PermissionModuleKeys.PROVIDER_REVIEW_DISPUTE] ? (
                <DisputeReviews />
            ) : (
                <AccessDenied />
            ),
        },
        {
            key: '4',
            label: 'Complaints',
            children: access?.[PermissionModuleKeys.PROVIDER_REVIEW_COMPLAINT] ? (
                <Complaints />
            ) : (
                <AccessDenied />
            ),
        },
    ];
    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <div className={s.title}>Reviews</div>
            </div>
            <div className={s.content}>
                <Tabs
                    activeKey={key}
                    items={items}
                    onChange={(val) => {
                        setKey(val);
                    }}
                />
            </div>
        </div>
    );
};

export default PageManagementPage;
