export const generateNewItem = (index: number, serviceTypeId: number, contextType: string, sequence: number) => {
    const item = {
        presetId: index + 1,
        serviceTypeId,
        type: contextType,
        sequence: sequence ? sequence + 1 : index + 1,
    };
    if (contextType === 'patientGroup') {
        item.patientGroup = '';
    } else if (contextType === 'limitation') {
        item.limitation = '';
    }

    return item;
};
