import { PATH } from 'constants/path';
import { isOpPortal } from './common';
import { getHasOnceSuccessLogin } from './localstore';

export const redirecToLogout = () => {
    if (window) {
        const { origin } = window.location;

        if (isOpPortal()) {
            window.location.href = `${origin}/${PATH.LOGIN}`;
        } else if (getHasOnceSuccessLogin()) {
            window.location.href = `${origin}/${PATH.PROVIDER_LOGIN}`;
        } else {
            window.location.href = `${origin}${PATH.SIGNUP}`;
        }
    }
};
