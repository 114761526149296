// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--sxyAF .ant-table-cell {
  border-right: 1px solid #f0f0f0 !important;
}
.s-module__wrap--sxyAF .ant-form-item-has-success {
  margin-bottom: 0;
}
.s-module__wrap--sxyAF .s-module__nameWrap--r8Q4h {
  margin: 16px 24px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 5;
  text-overflow: ellipsis;
  color: var(--gray-500);
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
.s-module__wrap--sxyAF .s-module__contactTypesUl--LWbUW {
  margin-left: 16px;
  list-style-type: disc;
}
.s-module__wrap--sxyAF .s-module__edit--SGI2V {
  color: var(--main-green);
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  cursor: pointer;
}
.s-module__modalFooter--iK_J3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/operation/ServiceTemplateManagement/components/KlarityServices/components/ServiceType/s.module.less"],"names":[],"mappings":"AAAA;EAGY,0CAAA;AADZ;AAFA;EAMY,gBAAA;AADZ;AALA;EAUQ,iBAAA;EACA,oBAAA;EACA,4BAAA;EACA,gBAAA;EACA,qBAAA;EACA,uBAAA;EACA,sBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AAFR;AAnBA;EA0BQ,iBAAA;EACA,qBAAA;AAJR;AAvBA;EA+BQ,wBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,eAAA;AALR;AAUA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;AARJ","sourcesContent":[".wrap {\n    :global {\n        .ant-table-cell {\n            border-right: 1px solid #f0f0f0 !important;\n        }\n        .ant-form-item-has-success {\n            margin-bottom: 0;\n        }\n    }\n    .nameWrap {\n        margin: 16px 24px;\n        display: -webkit-box;\n        -webkit-box-orient: vertical;\n        overflow: hidden;\n        -webkit-line-clamp: 5;\n        text-overflow: ellipsis;\n        color: var(--gray-500);\n        font-family: Inter;\n        font-size: 14px;\n        font-weight: 400;\n        line-height: 20px;\n        text-align: left;\n\n    }\n\n    .contactTypesUl {\n        margin-left: 16px;\n        list-style-type: disc;\n    }\n\n    .edit {\n        color: var(--main-green);\n        font-family: Inter;\n        font-size: 14px;\n        font-weight: 400;\n        line-height: 20px;\n        text-align: left;\n        cursor: pointer;\n    }\n}\n\n\n.modalFooter {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--sxyAF`,
	"nameWrap": `s-module__nameWrap--r8Q4h`,
	"contactTypesUl": `s-module__contactTypesUl--LWbUW`,
	"edit": `s-module__edit--SGI2V`,
	"modalFooter": `s-module__modalFooter--iK_J3`
};
export default ___CSS_LOADER_EXPORT___;
