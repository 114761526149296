import TinyStore from 'lib/tinyStore';
import { TChannel } from 'pages/dashboard/ChannelMainPage/components/ChannelMagementDelegationNotice/type';

type ChannelStoreModal = {
    collectedChannels: TChannel[];
};

const Store = TinyStore.createStore<ChannelStoreModal>({
    nameSpace: 'box_practice_channel_store',
    key: 'channelStore',
    crossBundle: false,
    runTime: true,
    initValue: { collectedChannels: [] },
});
export default Store;
