import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import s from './s.module.less';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import type { ColumnsType } from 'antd/es/table';
import { Button, Input, Checkbox, Space, Row, Col } from 'antd';
import CommonTable from 'components/CommonTable';
import { SearchOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { getUniprofileList } from 'api/operation';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import { ListToChannelTableRecord } from 'types/operation';
import debounce from 'lodash/debounce';
import useGetSettingData from 'hooks/useGetSettingData';
import { EnumFields } from 'types/enumerationData';
import { CheckboxChangeEvent, CheckboxOptionType } from 'antd/lib/checkbox';
import ProfileStatusTag from 'components/ProfileAuditStatusTag';

const Page = () => {
    const navigate = useNavigate();
    const [pendingNameFilter, setPendingNameFilter] = useState('');
    const [auditStatusOptions, setAuditStatusOptions] = useState([]);
    const [listingStatusOptions, setListingStatusOptions] = useState([]);

    // ops 状态
    const [aduitStatusIndeterminate, setAduitStatusIndeterminate] = useState(false);
    const [aduitCheckAll, setAduitCheckAll] = useState(false);
    const [aduitList, setAduitList] = useState<Array<CheckboxValueType>>([]);

    // 医生端状态
    const [listingStatusIndeterminate, setListingStatusIndeterminate] = useState(false);
    const [listingStatusCheckAll, setListingStatusCheckAll] = useState(false);
    const [listingStatusList, setListingStatusList] = useState<Array<CheckboxValueType>>([]);

    const [auditStatusData] = useGetSettingData(EnumFields.PROFILE_AUDIT_STATUS);
    const [listingStatusData] = useGetSettingData(EnumFields.PROFILE_STATUS);

    useEffect(() => {
        const auditStatus = auditStatusData?.map((item) => ({ label: item.content, value: item.dictKey }));
        const listingStatus = listingStatusData?.map((item) => ({ label: item.content, value: item.dictKey }));

        setAuditStatusOptions(auditStatus || []);
        setListingStatusOptions(listingStatus || []);
    }, [auditStatusData, listingStatusData]);

    const defaultPrams = useRef({
        nameCond: pendingNameFilter,
        auditStatusList: aduitList || [],
        uniprofileStatusList: listingStatusList || [],
        orderKey: 'submit_time',
        orderType: 'desc',
        current: 1,
        size: 10000,
    });

    const { data, loading, run } = useRequest(getUniprofileList, {
        defaultParams: [
            defaultPrams.current,
        ],
    });

    const listData:ListToChannelTableRecord[] = data?.data?.data.records || [];

    const handleTableChange = useCallback((paginationData?:any, filtersData?:any, sorter?:any, action?:any) => {
        const params = defaultPrams.current;

        if (action?.action === 'sort') {
            params.orderType = sorter.order === 'ascend' ? 'asc' : 'desc';
        }

        // paginationData存在，说明是tableChange，否则是别处调用
        if (!paginationData) {
            params.nameCond = filtersData.name;
            params.auditStatusList = filtersData.auditStatusList;
            params.uniprofileStatusList = filtersData.uniprofileStatusList;
        }
        run(params);
    }, [run]);

    const filterNameDebounce = useRef(debounce((params) => {
        handleTableChange(null, params);
    }, 500));

    const handleListingStatusFilterCheckAll = useCallback((e: CheckboxChangeEvent) => {
        const checkedListData = e.target.checked && listingStatusOptions ? listingStatusOptions.map((option: CheckboxOptionType) => option.value) : [];

        setListingStatusList(checkedListData);
        setListingStatusIndeterminate(false);
        setListingStatusCheckAll(e.target.checked);

        handleTableChange(null, {
            name: pendingNameFilter,
            auditStatusList: aduitList,
            uniprofileStatusList: checkedListData,
        });
    }, [aduitList, handleTableChange, listingStatusOptions, pendingNameFilter]);

    const handleAduitStatusFilterCheckAll = useCallback((e: CheckboxChangeEvent) => {
        const checkedListData = e.target.checked && auditStatusOptions ? auditStatusOptions.map((option: CheckboxOptionType) => option.value) : [];

        setAduitList(checkedListData);
        setAduitStatusIndeterminate(false);
        setAduitCheckAll(e.target.checked);

        handleTableChange(null, {
            name: pendingNameFilter,
            auditStatusList: checkedListData,
            uniprofileStatusList: listingStatusList,
        });
    }, [auditStatusOptions, handleTableChange, listingStatusList, pendingNameFilter]);

    const handleListingStatusFilterChange = useCallback((statusList: CheckboxValueType[]) => {
        setListingStatusList(statusList);
        setListingStatusIndeterminate(!!statusList.length && !!listingStatusOptions && statusList.length < listingStatusOptions.length);
        setListingStatusCheckAll(statusList.length === listingStatusOptions?.length);

        handleTableChange(null, {
            name: pendingNameFilter,
            auditStatusList: aduitList,
            uniprofileStatusList: statusList,
        });
    }, [aduitList, handleTableChange, listingStatusOptions, pendingNameFilter]);

    const handleAduitStatusFilterChange = useCallback((statusList: CheckboxValueType[]) => {
        setAduitList(statusList);
        setAduitStatusIndeterminate(!!statusList.length && !!auditStatusOptions && statusList.length < auditStatusOptions.length);
        setAduitCheckAll(statusList.length === auditStatusOptions?.length);

        handleTableChange(null, {
            name: pendingNameFilter,
            auditStatusList: statusList,
            uniprofileStatusList: listingStatusList,
        });
    }, [auditStatusOptions, handleTableChange, listingStatusList, pendingNameFilter]);

    const columns: ColumnsType<ListToChannelTableRecord> = useMemo(() => (
        [
            {
                title: 'NAME',
                dataIndex: 'name',
                filterSearch: true,
                className: s.tableColumn,
                filterIcon: <SearchOutlined />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Input
                            value={pendingNameFilter}
                            onChange={(e) => {
                                const filterName = e.target.value;
                                setPendingNameFilter(filterName);
                                filterNameDebounce.current({
                                    name: filterName,
                                    auditStatusList: aduitList,
                                    uniprofileStatusList: listingStatusList,
                                });
                            }}
                            style={{ marginBottom: 8, display: 'block' }}
                        />
                    </div>
                ),
                render: (name:string, { firstName, lastName, email }:ListToChannelTableRecord) => {
                    return (
                        <div className={s.nameWrap}>
                            <div className={s.nameRight}>
                                <div className={s.name}>{firstName} {lastName}</div>
                                <div className={s.email}>{email}</div>
                            </div>
                        </div>
                    );
                },
            },
            {
                title: 'PROVIDER ID',
                dataIndex: 'providerId',
                width: '100px',
                className: s.tableColumn,
            },
            {
                title: 'SIGNUP DATE',
                dataIndex: 'submitTime',
                sortDirections: ['ascend', 'descend', 'ascend'],
                sorter: true,
                width: '150px',
                className: s.tableColumn,
                render: (time) => {
                    return (
                        <div>{dayjs(time).format('MM/DD/YYYY')}</div>
                    );
                },
            },
            {
                title: 'UNIPROFILE ACTION',
                dataIndex: 'uniprofileStatusName',
                width: '250px',
                className: s.tableColumn,
                filterIcon: <SearchOutlined />,
                filterDropdown: () => {
                    return (
                        <div style={{ padding: 8, width: '800px' }} onKeyDown={(e) => e.stopPropagation()}>
                            <Space>
                                <Checkbox indeterminate={listingStatusIndeterminate} onChange={handleListingStatusFilterCheckAll} checked={listingStatusCheckAll}>
                                    Check all
                                </Checkbox>
                                <Checkbox.Group value={listingStatusList} onChange={handleListingStatusFilterChange}>
                                    <Row style={{ width: '650px' }}>
                                        {
                                            listingStatusOptions?.map((item, index) => {
                                                return (
                                                    <Col key={index} span={8}>
                                                        <Checkbox value={item.value}>{item.label}</Checkbox>
                                                    </Col>
                                                );
                                            })
                                        }
                                    </Row>
                                </Checkbox.Group>
                            </Space>
                        </div>
                    );
                },
            },
            {
                title: 'OPS PROGRESS',
                dataIndex: 'auditStatus',
                width: '280px',
                className: s.tableColumn,
                filterIcon: <SearchOutlined />,
                filterDropdown: () => {
                    return (
                        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                            <Space>
                                <Checkbox indeterminate={aduitStatusIndeterminate} onChange={handleAduitStatusFilterCheckAll} checked={aduitCheckAll}>
                                    Check all
                                </Checkbox>
                                <Checkbox.Group options={auditStatusOptions} value={aduitList} onChange={handleAduitStatusFilterChange} />
                            </Space>
                        </div>
                    );
                },
                render: (status) => status && <ProfileStatusTag status={status} />,
            },
            {
                title: '',
                width: '80px',
                className: s.tableColumn,
                render: (item) => {
                    return (
                        <div>
                            <Button
                                className={s.link}
                                onClick={() => {
                                    navigate(`${PATH.OPERATION}/${PATH.UNIPROFILE_DETAIL}/-1/${item.providerId}`);
                                }}
                                type="text"
                            >View
                            </Button>
                        </div>
                    );
                },
            },
        ]
    ), [aduitCheckAll, aduitList, aduitStatusIndeterminate, auditStatusOptions, filterNameDebounce, handleAduitStatusFilterChange, handleAduitStatusFilterCheckAll, handleListingStatusFilterChange, handleListingStatusFilterCheckAll, listingStatusCheckAll, listingStatusIndeterminate, listingStatusList, listingStatusOptions, navigate, pendingNameFilter]);

    return (
        <div className={s.wrap}>
            <h2>UniProfile applications</h2>
            <div className={s.tableWrap}>
                <CommonTable
                    rowKey={(record: ListToChannelTableRecord) => record.id}
                    bordered
                    loading={loading}
                    columns={columns}
                    data={listData || []}
                    onChange={handleTableChange}
                />
            </div>
        </div>
    );
};

export default Page;
