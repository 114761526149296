import TinyStore from 'lib/tinyStore';
import { HomeInfo } from 'types/provider';

type HomeModal = {
    reload: boolean;
    data?: HomeInfo
};

const Store = TinyStore.createStore<HomeModal>({
    nameSpace: 'box_practice_home_store',
    key: 'home',
    crossBundle: false,
    runTime: true,
    initValue: { reload: false },
});
export default Store;
