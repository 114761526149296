import React, {
    useState,
    useEffect,
} from 'react';
import s from './s.module.less';
import { message, Button, Descriptions, Form, Input } from 'antd';
import type { TAssessmentItem } from 'types/operation';
import commonS from 'styles/common.module.less';
import useLoadBasicData from 'hooks/useLoadBasicData';
import { addOrUpdateAssessment } from 'api/operation/assessment';

type IProps = {
    item: TAssessmentItem;
    onSuccess: (obj: Partial<TAssessmentItem>) => void;
};

const ResultAnalysis: React.FC<IProps> = ((props) => {
    const { item, onSuccess } = props;
    const [isEdit, setIsEdit] = useState(false);
    const [form] = Form.useForm<TAssessmentItem & { active: boolean }>();
    const [loadBaiscData] = useLoadBasicData();

    const handleEdit = () => {
        setIsEdit(true);
    };

    const handleCancel = () => {
        setIsEdit(false);
    };

    const handleSave = async () => {
        const formValues = await form.validateFields();
        const { data } = await addOrUpdateAssessment({
            ...item,
            analyzingPrompt: formValues.analyzingPrompt,
        });
        if (data.code === 200) {
            message.success('Information updated successfully');
        }
        onSuccess({
            analyzingPrompt: formValues.analyzingPrompt,
        });
        setIsEdit(false);
    };

    const renderDescription = () => {
        return (
            <Descriptions
                colon={false}
                title=""
                layout="vertical"
                column={1}
                size="small"
            >
                <Descriptions.Item label={
                    <>
                        Result analysis logic
                        <Button onClick={() => handleEdit()} className={s.btn}>Edit</Button>
                    </>
                }
                >
                    {item.analyzingPrompt || '-'}
                </Descriptions.Item>
            </Descriptions>
        );
    };

    const renderForm = () => {
        return (
            <div className={s.analysisForm}>
                <div className={s.buttonWrap}>
                    <Button onClick={() => handleCancel()}>Cancel</Button>
                    <Button
                        onClick={() => handleSave()}
                        type="primary"
                        className={s.ml8}
                    >
                        Save changes
                    </Button>
                </div>
                <Form
                    form={form}
                    className={commonS.formStyle1}
                    initialValues={{ active: true }}
                    autoComplete="off"
                    layout="vertical"
                    scrollToFirstError
                >
                    <Form.Item
                        label="Result analysis logic"
                        name="analyzingPrompt"
                        validateFirst
                        rules={[
                            {
                                required: true,
                                message: 'Result analysis logic is required',
                            },
                        ]}
                    >
                        <Input.TextArea maxLength={1000} rows={5} showCount />
                    </Form.Item>
                </Form>
            </div>
        );
    };

    useEffect(() => {
        if (item) {
            form.setFieldsValue({
                analyzingPrompt: item.analyzingPrompt,
            });
        } else {
            form.resetFields();
        }
    }, [item]);

    useEffect(() => {
        loadBaiscData();
    }, [loadBaiscData]);

    return (
        <div className={s.wrap}>
            <div className={s.content}>
                {isEdit ? renderForm() : renderDescription()}
            </div>
        </div>
    );
});

export default ResultAnalysis;
