import React, { useCallback } from "react";
import s from "./s.module.less";
import { Form, Input, InputNumber, Switch } from "antd";
import QuillRichText from "components/QuillRichText";
import { getPlainTextLength } from "utils/common";

const PackageInfo = () => {
    const customContactTypeValidator = useCallback((errorMessage: string, maxLength: number) => {
        return async (rule, value: string) => {
            if (maxLength && getPlainTextLength(value) > maxLength) {
                return Promise.reject(new Error(errorMessage));
              }
            return Promise.resolve();
        }
    }, []);
    return (
        <div className={s.wrap}>
            <div className={s.block}>
                <div className={s.title}>Package information</div>
                <div className={s.formItem}>
                    <Form.Item
                        label="Package title"
                        name="packageTitle"
                        rules={[{ required: true, message: 'Package title is required' }]}
                    >
                        <Input showCount maxLength={60} />
                    </Form.Item>
                </div>
                <div className={s.formItem}>
                    <Form.Item
                        label="Description"
                        name="description"
                        rules={[{ required: true, message: 'Description is required' }]}
                    >
                        <Input.TextArea showCount maxLength={600} />
                    </Form.Item>
                </div>
                <div className={s.formItem}>
                    <div className={`${s.label}`}>Introduction Video</div>
                    <div className={s.subLabel}>Add video link (URL) from YouTube, Loom, or Vimeo.</div>
                    <Form.Item
                        // label="Introduction Video"
                        name="introductionVideoLink"
                        // rules={[{ required: true, message: 'Introduction Video is required' }]}
                    >
                        <Input showCount maxLength={60} />
                    </Form.Item>
                </div>
            </div>
            <div className={s.block}>
                <div className={s.title}>Information on HelloKlarity</div>
                <div className={`${s.formItem} ${s.horizon}`}>
                    <Form.Item
                        name="isKlarityDisplay"
                        valuePropName="checked"
                    >
                        <Switch className={s.formSwitch} />
                    </Form.Item>
                    <div className={s.text}>Display on Hello klarity</div>
                </div>
                <div className={s.formItem}>
                    <Form.Item
                        label="Patient facing title"
                        name="patientFacingTitle"
                        rules={[{ required: true, message: 'Patient facing title is required' }]}
                    >
                        <Input showCount maxLength={600} />
                    </Form.Item>
                </div>
                <div className={s.formItem}>
                    <Form.Item
                        label="First appointment duration"
                        name="firstPaymentDuration"
                        rules={[{ required: true, message: 'First appointment duration is required' }]}
                    >
                        <InputNumber addonAfter="Mins" min={0}/>
                    </Form.Item>
                </div>
                <div className={s.formItem}>
                    <Form.Item
                        label="Patient facing description"
                        name="patientFacingDesc"
                        rules={[
                            { required: true, message: 'Patient facing description is required' },
                        ]}
                    >
                        <Input.TextArea showCount maxLength={600} />
                    </Form.Item>
                </div>
                <div className={s.formItem}>
                    <Form.Item
                        label="what's included"
                        name="whatIncludeInfo"
                        rules={[
                            { required: true, message: `what's included is required` },
                            {validator: customContactTypeValidator(`'what's included' should be less than 600 characters`, 600)}
                        ]}
                    >
                        <QuillRichText showCount maxLength={600}/>
                        {/* <Input.TextArea showCount maxLength={600} /> */}
                    </Form.Item>
                </div>
                <div className={s.formItem}>
                    <Form.Item
                        label="Disclaimer & policy"
                        name="disclaimerPolicy"
                        rules={[
                            { required: true, message: 'Disclaimer & policy is required' },
                            {validator: customContactTypeValidator(`'Disclaimer & policy' should be less than 600 characters`, 1024)}
                        ]}
                    >
                        <QuillRichText showCount maxLength={1024}/>
                        {/* <Input.TextArea showCount maxLength={600} /> */}
                    </Form.Item>
                </div>
            </div>
        </div>
    )
}

export default PackageInfo;
