import { ISales, ISalesLinkCheckout } from './provider';

export enum EBindType {
    CREATED = 'CREATED',
    NO_CREAT = 'NO_CREAT',
    CONFLICT = 'CONFLICT',
}

export interface IProvderAgreementSignInput {
    'linkUuid':string,
    'contractSignName':string,
    'contractSignDate':string,
    'contractSignAddress': string
}

export interface ISalesLinkFinnalyCompleteInput {
    'linkUuid': string,
    'email': string,
    'password'?: string,
    'bindType': EBindType,
}

export interface ICheckoutPaymentInput {
    linkUuid: string;
    email?: string;
    paymentMethodId?: string;
    couponId?: string;
    country: string;
    postalCode: string;
}

export interface IScheduleResponse {
    'linkId': number,
    'linkUuid': string,
    'fullName': string,
    'currentStep': string,
    'linkStatus': string,
    'onboardingCallBookLink'?: string,
    'sales': ISales,
    'checkOut'?: ISalesLinkCheckout,
    'agreement'?: string,
    'accountReview': {
        'emailList': string[],
        'accountStatus': EBindType,
        'loginEmail'?: string,
        verify?: boolean,
    }
}

export enum ECurrentStep {
    LS = 'LS',
    PR = 'PR',
    CS = 'CS',
    OCS = 'OCS',
    DONE = 'DONE',
}
