import React, { useMemo, useRef } from 'react';
import type { Identifier, XYCoord } from 'dnd-core';
import { useDrag, useDrop } from 'react-dnd';
import { MenuOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import s from './s.module.less';

export interface IProps {
    id?: number
    text: string;
    index: number;
    contextType: string;
    type: string;
    notDelete: boolean;
    isPlaceholder?: boolean;
    placeholder?: string
    onMoveItem: (dragIndex: number, hoverIndex: number) => void;
    onDelete: (index: number) => void;
    onAdd: () => void;
    onInputChange: (v: string, i: number) => void;
}

interface IDragItem {
    index: number
    id: string
    type: string
}

const ItemTypes = {
    ITEM: 'item',
};

const DragItem = ({
    id,
    index,
    text,
    notDelete,
    contextType,
    onMoveItem,
    type,
    onDelete,
    isPlaceholder,
    placeholder,
    onAdd,
    onInputChange,
}: IProps) => {
    const dragdropItemRef = useRef<HTMLDivElement>(null);
    const [{ handlerId }, drop] = useDrop<
    IDragItem,
    void,
    { handlerId: Identifier | null }
    >({
        accept: ItemTypes.ITEM,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: IDragItem, monitor) {
            if (!dragdropItemRef.current) {
                return;
            }

            if (contextType !== item.type) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = dragdropItemRef.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            onMoveItem(dragIndex, hoverIndex);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.ITEM,
        item: () => {
            return { id, index, type };
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0 : 1;
    drag(drop(dragdropItemRef));

    const iconRender = useMemo(() => {
        if (notDelete) {
            return null;
        } else if (isPlaceholder) {
            return <div className={`${s.icon} ${s.add}`} onClick={() => onAdd()} />;
        } else {
            return <div className={`${s.icon} ${s.delete}`} onClick={() => onDelete(index)} />;
        }
    }, [index, isPlaceholder, notDelete, onAdd, onDelete]);

    return (
        <div className={s.dragItem} style={{ opacity }} ref={dragdropItemRef} data-handler-id={handlerId}>
            <div className={s.dragIcon}>
                <MenuOutlined />
            </div>
            <Input value={text} maxLength={300} placeholder={placeholder} onChange={(e) => onInputChange(e.target.value, index)} />
            {iconRender}
        </div>
    );
};

export default DragItem;
