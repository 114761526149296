import React, { useCallback, useMemo, useRef } from "react";
import s from "./s.module.less";
import { Checkbox, Form, Input, InputNumber, Select, Switch } from "antd";
import { frequencyOptions,frequency } from "constants/program";

interface IProps {
    differentFirstPriceIsChecked: boolean;
    watchedBillingFrequencyTypeValue: string;
    watchedBillingPeriodValue: number;
    watchedFirstPaymentPrice: number;
    watchedPrice: number;
}

const Pricing = ({
    watchedBillingFrequencyTypeValue,
    differentFirstPriceIsChecked,
    watchedBillingPeriodValue,
    watchedFirstPaymentPrice,
    watchedPrice
}: IProps) => {
    const periodOptions = useMemo(() => [
        {
            label: 'Until Cancelled',
            value: 1,
            // disabled: watchedBillingFrequencyTypeValue === frequency.MONTHLY
        },
        {
            label: '2',
            value: 2
        },
        {
            label: '3',
            value: 3
        },
        {
            label: '4',
            value: 4
        },
        {
            label: '5',
            value: 5
        },
        {
            label: '6',
            value: 6
        },
    ], []);

    return (
        <div className={s.wrap}>
            <div className={s.block}>
                <div className={s.title}>Pricing</div>
                <div className={s.formItem}>
                    <Form.Item
                        style={{width: '270px'}}
                        label="Billing Frequency"
                        name="billingFrequencyType"
                        rules={[{ required: true, message: 'Billing Frequency is required' }]}
                    >
                        <Select options={frequencyOptions}/>
                    </Form.Item>
                </div>
                {
                    watchedBillingFrequencyTypeValue !== frequency.ONE_TIME && (
                        <div className={s.formItem}>
                            <Form.Item
                                style={{width: '270px'}}
                                label="Numbers of billing Period"
                                name="billingPeriod"
                                rules={[{ required: true, message: 'Numbers of billing Period is required' }]}
                            >
                                <Select disabled={watchedBillingFrequencyTypeValue === frequency.ONE_TIME} options={periodOptions}/>
                            </Form.Item>
                        </div>
                    )
                }
                <div className={s.formItem}>
                    <Form.Item
                        style={{width: '270px'}}
                        label="Price per payment"
                        name="price"
                        rules={[{ required: true, message: 'Price per payment is required' }]}
                    >
                        <InputNumber addonBefore="$"  addonAfter="USD" min={0}/>
                    </Form.Item>
                </div>
                <div className={s.formItem}>
                    <Form.Item
                        name="differentFirstPrice"
                        valuePropName="checked"
                    >
                        <Checkbox disabled={watchedBillingFrequencyTypeValue === frequency.ONE_TIME}>Enable different price for first payment</Checkbox>
                    </Form.Item>
                </div>
                <div className={s.formItem} style={{display: differentFirstPriceIsChecked ? 'block' : 'none'}}>
                    <Form.Item
                        style={{width: '270px'}}
                        label="First payment price"
                        name="firstPaymentPrice"
                        rules={[{ required: !!differentFirstPriceIsChecked, message: 'First payment price is required' }]}
                    >
                        <InputNumber addonBefore="$"  addonAfter="USD" min={0}/>
                    </Form.Item>
                </div>
                <div className={s.formItem}>
                    <Form.Item
                        style={{width: '270px'}}
                        label="Provider’s earning for this servcie"
                        name="providerEarning"
                        // rules={[{ required: true, message: 'Provider’s earning for this servcie is required' }]}
                    >
                        <InputNumber addonBefore="$"  addonAfter="USD" min={0}/>
                    </Form.Item>
                </div>
                <div className={s.formItem}>
                {
                    watchedBillingPeriodValue === 1 && watchedBillingFrequencyTypeValue !== frequency.ONE_TIME ? (
                        `Payments: First payment $${watchedFirstPaymentPrice || 0} then $${watchedPrice || 0} per month`
                    ) : (
                        <Form.Item
                            style={{width: '270px'}}
                            label="Total price"
                            name="totalPrice"
                        >
                            <InputNumber disabled addonBefore="$"  addonAfter="USD" min={0}/>
                        </Form.Item>
                    )
                }
                </div>
            </div>
        </div>
    )
}

export default Pricing;
