import { TInternalLink } from 'types/operation';

export enum FormFieldEnum {
    LIST = 'list',
}

export type FormTInternalLink = TInternalLink & {

};

export interface FormInterface {
    [FormFieldEnum.LIST]: FormTInternalLink[];
}
