import { SHOW_KLARITY_ADVERTISING_ICON } from 'constants/common';
import TinyStore from 'lib/tinyStore';
import { ServerMeta } from 'types/common';

type CommonModal = {
    authGoogleCalendarCallbackPath?: string;
    featuredState?: ServerMeta[],
    featuredCondition?: ServerMeta[],
    showAdvertisingIcon?: null | '1' | '2'; // null是从未弹出，首页需要做弹出逻辑。1 是弹出过被关了，在右下角显示icon，2是icon被关掉了。
};

const Store = TinyStore.createStore<CommonModal>({
    nameSpace: 'box_practice_common_store',
    key: 'common',
    crossBundle: false,
    runTime: true,
    initValue: { authGoogleCalendarCallbackPath: undefined, featuredState: [], featuredCondition: [], showAdvertisingIcon: localStorage.getItem(SHOW_KLARITY_ADVERTISING_ICON) as null | '1' | '2' },
});
export default Store;
