import React from 'react';
import s from './s.module.less';
import type { TCarousel } from 'types/helloKlarity';
import { useParams } from 'react-router-dom';

type IProps = {
    isMobileMode: boolean;
    condition?: string;
    state?: string;
    previewItem: TCarousel;
};

const CarouselsPreview = (props: IProps) => {
    const { previewItem, isMobileMode, condition, state } = props;
    const { type = 'HOME' } = useParams();
    const replaceCarouselLabel = (title: string) => {
        if (!title) {
            return;
        }
        if (type === 'CONDITION') {
            return title
                .replace(/({ConditionSelect})/gi, `<span>${condition}</span>`)
                .replace(/({StateSelect})/gi, `<span>${state}</span>`)
                .replace(/({Condition})/gi, `${condition}`)
                .replace(/({State})/gi, `${state}`);
        } else if (type === 'STATE') {
            return title
                .replace(/({StateSelect})/gi, `<span>${state}</span>`)
                .replace(/({State})/gi, `${state}`);
        } else {
            return title.replace(/({ConditionSelect})/gi, '<span>ADHD</span>')
                .replace(/({StateSelect})/gi, '<span>California</span>')
                .replace(/({Condition})/gi, 'ADHD')
                .replace(/({State})/gi, 'California');
        }
    };
    return (
        <div className={isMobileMode ? s.previewMobile : s.previewPc}>
            <div className={s.header} />

            <div className={s.content}>
                <div className={s.text}>
                    {!['HOME'].includes(type) && (
                        <div className={s.back}>back to home</div>
                    )}
                    <div
                        className={s.title}
                        dangerouslySetInnerHTML={{
                            __html: replaceCarouselLabel(previewItem.title),
                        }}
                    />
                    <div
                        className={s.des}
                        dangerouslySetInnerHTML={{
                            __html: replaceCarouselLabel(previewItem.description),
                        }}
                    />
                    {previewItem.actionName && (
                        <a
                            href={previewItem.actionLink}
                            target="_blank"
                            title={previewItem.actionName}
                            rel="noreferrer"
                            className={s.link}
                        >
                            {previewItem.actionName}
                        </a>
                    )}
                </div>
                <img
                    src={previewItem.image}
                    alt={previewItem.title}
                    className={s.img}
                />
            </div>
            {['HOME'].includes(type) && <div className={s.bottom} />}
        </div>
    );
};

export default CarouselsPreview;
