// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--PqaRb {
  margin-top: 24px;
}
.s-module__list--SgkFg .s-module__item--w1qXn {
  color: var(--Black, #15191E);
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 111.111% */
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/pages/operation/HelloKlarityPageManagement/components/CarouselsManagement/s.module.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAEA;EAEI,4BAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EADF,aAAa;EAEX,iBAAA;EACA,aAAA;EACA,8BAAA;EACA,eAAA;AAAJ","sourcesContent":[".wrap {\n  margin-top: 24px;\n}\n\n.list {\n  .item {\n    color: var(--Black, #15191E);\n    font-family: Inter;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 20px; /* 111.111% */\n    padding-top: 40px;\n    display: flex;\n    justify-content: space-between;\n    cursor: pointer;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--PqaRb`,
	"list": `s-module__list--SgkFg`,
	"item": `s-module__item--w1qXn`
};
export default ___CSS_LOADER_EXPORT___;
