import React from 'react';
import { type TCarousel, ECarouselsStatusLabel, TObjectAction } from 'types/helloKlarity';
import s from './s.module.less';
import { Button, Descriptions, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { klarityCarouselsDelete } from 'api/operation';

type IProps = {
    item: TCarousel;
    index: number;
    isShowRemove: boolean;
    pageName: string;
    onDelete: (obj: TObjectAction) => void;
    onEdit: (value: TCarousel, index: number) => void;
};

const CarouselCard: React.FC<IProps> = (props) => {
    const { item, index, isShowRemove, pageName, onDelete, onEdit } = props;
    const [modal, contextHolder] = Modal.useModal();

    const handleRemove = () => {
        modal.confirm({
            width: '600px',
            closable: true,
            title: `Remove this carousel from ${pageName}?`,
            icon: <ExclamationCircleOutlined />,
            content:
            'Please note that this carousel will be immediately removed from HelloKlarity website. Are you sure you want to proceed?',
            okText: 'Remove',
            cancelText: 'Cancel',
            onOk: async () => {
                try {
                    if (!item.id) {
                        return;
                    }
                    const { error } = await klarityCarouselsDelete(item.id);
                    if (!error) {
                        onDelete({
                            type: 'CAROUSEL_DELETE',
                            id: item.id,
                            params: item,
                        });
                    }
                } catch (error) {
                    console.error(error);
                }
            },
        });
    };

    if (!item) {
        return null;
    }

    return (
        <div className={s.wrap}>
            <div className={s.content}>
                <Descriptions
                    colon={false}
                    title={`Carousel ${index + 1}`}
                    layout="vertical"
                    extra={
                        <>
                            {isShowRemove && (
                                <Button
                                    onClick={handleRemove}
                                    type="link"
                                    className={s.remove}
                                >
                                    Remove
                                </Button>
                            )}
                            <Button onClick={() => onEdit(item, index)}>Edit</Button>
                        </>
                    }
                    column={1}
                    size="small"
                    contentStyle={{
                        marginBottom: '16px',
                    }}
                >
                    <Descriptions.Item label="Status">
                        {ECarouselsStatusLabel[item.status]}
                    </Descriptions.Item>
                    <Descriptions.Item label="Title">{item.title}</Descriptions.Item>
                    <Descriptions.Item label="Subtext">
                        {item.description}
                    </Descriptions.Item>
                    <Descriptions.Item label="Call-to-action name">
                        {item.actionName || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Call-to-action link">
                        {item.actionLink || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Image">
                        <img
                            src={item.image}
                            alt={item.title}
                            className={s.carouselImg}
                        />
                    </Descriptions.Item>
                </Descriptions>
            </div>
            {contextHolder}
        </div>
    );
};

export default CarouselCard;
