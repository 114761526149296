import React, { } from 'react';
import { Button, Input, Modal, Space, TableProps } from 'antd';
import CommonTable from 'components/CommonTable';
import type { ColumnsType } from 'antd/es/table';
import {
    getAddOnList,
} from 'api/operation';
import s from './s.module.less';
import { SearchOutlined } from '@ant-design/icons';
import { EOriderType, IGeAddOnResp } from 'types/operation';
import { Meta } from 'types/common';
import { EAddOnProductCode, EAddOnProductCodeLabel, EhrTableItem } from 'types/ehr';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';

type Props = {

};

const EhrAddOnLinkPage = (props: Props) => {
    const navigation = useNavigate();
    const [itemList, setItemList] = React.useState<EhrTableItem[]>([]);
    const [total, setTotal] = React.useState<number>(0);
    const [loading, setLoading] = React.useState(false);
    const [modal, contextHolder] = Modal.useModal();
    const pageSize = 10;

    const addOnProductCodeOption: Meta[] = [
        {
            label: EAddOnProductCodeLabel.SELF_EPRESCRIPTION_EPCS,
            value: EAddOnProductCode.SELF_EPRESCRIPTION_EPCS,
        },
        {
            label: EAddOnProductCodeLabel.SELF_EPRESCRIPTION,
            value: EAddOnProductCode.SELF_EPRESCRIPTION,
        },
        {
            label: EAddOnProductCodeLabel.SELF_EFAX,
            value: EAddOnProductCode.SELF_EFAX,
        },
        {
            label: EAddOnProductCodeLabel.COLLABORATING_PHYSICIAN_SEAT,
            value: EAddOnProductCode.COLLABORATING_PHYSICIAN_SEAT,
        },
        {
            label: EAddOnProductCodeLabel.COLLABORATING_PHYSICIAN_EPRESCRIPTION_EPCS,
            value: EAddOnProductCode.COLLABORATING_PHYSICIAN_EPRESCRIPTION_EPCS,
        },
        {
            label: EAddOnProductCodeLabel.COLLABORATING_PHYSICIAN_EPRESCRIPTION,
            value: EAddOnProductCode.COLLABORATING_PHYSICIAN_EPRESCRIPTION,
        },
        {
            label: EAddOnProductCodeLabel.ASSISTANT_SEAT,
            value: EAddOnProductCode.ASSISTANT_SEAT,
        },
        {
            label: EAddOnProductCodeLabel.ASSISTANT_EPRESCRIPTION_EPCS,
            value: EAddOnProductCode.ASSISTANT_EPRESCRIPTION_EPCS,
        },
        {
            label: EAddOnProductCodeLabel.ASSISTANT_EPRESCRIPTION,
            value: EAddOnProductCode.ASSISTANT_EPRESCRIPTION,
        },
    ];

    const [page, setPage] = React.useState(1);
    const [pendingProviderNameFilter, setPendingProviderNameFilter] = React.useState('');
    const [providerNameFilter, setProviderNameFilter] = React.useState<string>('');
    const [productCodeListFilter, setProductCodeListFilter] = React.useState<EAddOnProductCode[]>();
    const [paymentStatusListFilter, setPaymentStatusListFilter] = React.useState<('Paid' | 'Failed')[]>();

    const doFetch = async (current: number, providerName?: string, productCodeList?: string[], paymentStatusList?: ('Paid' | 'Failed')[], orderKey?: string, orderType?: string) => {
        setLoading(true);
        const reqParam: IGeAddOnResp = {
            current,
            size: pageSize,
        };
        if (providerName) {
            reqParam.providerName = providerName;
        }
        if (productCodeList && productCodeList.length > 0) {
            reqParam.productCodeList = productCodeList;
        }
        if (paymentStatusList && paymentStatusList.length === 1) {
            reqParam.paymentStatusList = paymentStatusList;
        }

        if (orderKey && orderType) {
            reqParam.orderKey = orderKey;
            reqParam.orderType = orderType;
        }
        const result = await getAddOnList(reqParam);
        if (!result.error) {
            setItemList(result?.data?.data?.records);
            setTotal(result?.data?.data?.total);
        }
        setLoading(false);
    };

    const onTableChange: TableProps<EhrTableItem>['onChange'] = (pagination, filters: any, sorter?: any, action?: any) => {
        //filter change
        const pageInx = pagination?.current || 1;
        const _productCodeListFilter = filters.productNames || [];
        const _paymentStatusListFilter = filters.paymentStatus || [];
        let orderType;
        let orderKey;
        if (action?.action === 'sort') {
            orderType = sorter.order === 'ascend' ? EOriderType.ASC : EOriderType.DESC;
            orderKey = sorter.field === 'pendingAddonQty' ? 'pending_addon_qty' : sorter.field;
        }

        setPage(pageInx);
        setProductCodeListFilter(_productCodeListFilter);
        setPaymentStatusListFilter(_paymentStatusListFilter);

        doFetch(pageInx, providerNameFilter, _productCodeListFilter, _paymentStatusListFilter, orderKey, orderType);
    };

    React.useEffect(() => {
        doFetch(page);
    }, []);

    const columns: ColumnsType<EhrTableItem> = [
        {
            title: 'NAME',
            dataIndex: 'firstName',
            key: 'firstName',
            width: '220px',
            filterDropdown: ({ close }: any) => (
                <div style={{ padding: 16 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        placeholder="Search name"
                        value={pendingProviderNameFilter}
                        onChange={
                            (e) => {
                                setPendingProviderNameFilter(e.target.value);
                            }
                        }
                        onPressEnter={() => {
                            setProviderNameFilter(pendingProviderNameFilter);
                            setPage(1);
                            doFetch(1, pendingProviderNameFilter, productCodeListFilter, paymentStatusListFilter);
                            close();
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                setProviderNameFilter(pendingProviderNameFilter);
                                setPage(1);
                                doFetch(1, pendingProviderNameFilter, productCodeListFilter, paymentStatusListFilter);
                                close();
                            }
                            }
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => {
                                setPendingProviderNameFilter('');
                                setProviderNameFilter('');
                                setPage(1);
                                doFetch(1, '', productCodeListFilter, paymentStatusListFilter);
                                close();
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
            ),
            render: (name: string, item: EhrTableItem) => {
                return (
                    <div className={s.cell}>
                        <div className={s.name}>
                            {name} {item.lastName}
                        </div>
                        <div className={s.email}>
                            {item.email}
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'ADD-ON',
            dataIndex: 'productNames',
            width: '360px',
            filterSearch: true,
            filters: addOnProductCodeOption?.map((stateItem) => ({
                text: stateItem.label,
                value: stateItem.value as string,
            })) || [],
            render: (val: string) => {
                const strArr = val?.split(',');
                return (
                    <div className={s.cell}>
                        {
                            strArr?.map((str: string) => {
                                return (
                                    <p key={str}>{str}</p>
                                );
                            })
                        }
                    </div>
                );
            },
        },
        {
            title: 'ASSISTANT',
            dataIndex: 'assistantQty',
            width: '160px',
            // filters: [
            //     {
            //         text: 'None',
            //         value: '0',
            //     },
            //     {
            //         text: '1',
            //         value: '1',
            //     },
            //     {
            //         text: '2',
            //         value: '2',
            //     },
            // ],
            render: (val: number) => {
                return (
                    <div className={s.cell}>
                        {val === 0 ? 'None' : val}
                    </div>
                );
            },
        },
        {
            title: 'COLLABORATOR',
            dataIndex: 'physicianQty',
            width: '160px',
            // filters: [
            //     {
            //         text: 'None',
            //         value: '0',
            //     },
            //     {
            //         text: '1',
            //         value: '1',
            //     },
            //     {
            //         text: '2',
            //         value: '2',
            //     },
            // ],
            render: (val: number) => {
                return (
                    <div className={s.cell}>
                        {val === 0 ? 'None' : val}
                    </div>
                );
            },
        },
        {
            title: 'PAYMENT',
            dataIndex: 'paymentStatus',
            width: '120px',
            filters: [
                {
                    text: 'Paid',
                    value: 'Paid',
                },
                {
                    text: 'Failed',
                    value: 'Failed',
                },
            ],
            render: (val: string) => {
                return (
                    <div className={s.cell}>
                        {val}
                    </div>
                );
            },
        },
        {
            title: 'PENDING ADD-ON',
            dataIndex: 'pendingAddonQty',
            width: '200px',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            // filters: [
            //     {
            //         text: 'None',
            //         value: '0',
            //     },
            //     {
            //         text: '1',
            //         value: '1',
            //     },
            //     {
            //         text: '2',
            //         value: '2',
            //     },
            // ],
            render: (val: number) => {
                return (
                    <div className={s.cell}>
                        {val}
                    </div>
                );
            },
        },
        {
            title: 'Action',
            width: '120px',
            fixed: 'right',
            render: (p: EhrTableItem) => {
                return (
                    <div className={s.actionWrap}>
                        <Button
                            className={s.link}
                            onClick={() => {
                                navigation(`${PATH.OPERATION}/${PATH.EHR_ADD_ON_DETAIL}/${p.providerId}`);
                            }}
                            type="text"
                        >
                            View
                        </Button>
                    </div>
                );
            },
        },
    ].filter(Boolean) as ColumnsType<EhrTableItem>;

    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <div className={s.title}>EHR Add-ons</div>
            </div>
            <CommonTable
                bordered
                rowKey="id"
                pagination={{
                    total,
                    current: page,
                    pageSize,
                    showSizeChanger: false,
                }}
                scroll={{ x: 'max-content' }}
                loading={loading}
                columns={columns}
                onChange={onTableChange}
                data={itemList || []}
            />
            {contextHolder}
        </div>
    );
};

export default EhrAddOnLinkPage;
