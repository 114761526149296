// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--Sfan0 {
  background: var(--main-bg-color);
  min-height: 100vh;
  padding: 36px 48px;
  color: var(--Primary-Black, #15191E);
}
.s-module__wrap--Sfan0 .s-module__title--A3aN9 {
  margin-bottom: 24px;
  color: var(--gray-900);
  font-size: 30px;
  font-weight: 800;
  line-height: 36px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/operation/HelloKlarityPageManagement/s.module.less"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACA,iBAAA;EACA,kBAAA;EACA,oCAAA;AACF;AALA;EAOI,mBAAA;EACA,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AACJ","sourcesContent":[".wrap {\n  background: var(--main-bg-color);\n  min-height: 100vh;\n  padding: 36px 48px;\n  color: var(--Primary-Black, #15191E);\n\n  .title {\n    margin-bottom: 24px;\n    color: var(--gray-900);\n    font-size: 30px;\n    font-weight: 800;\n    line-height: 36px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--Sfan0`,
	"title": `s-module__title--A3aN9`
};
export default ___CSS_LOADER_EXPORT___;
