import React from 'react';

export const renderChangUI = (key:string, data: any, enabled: boolean, transferFun?: (val:any)=> string | React.ReactNode) => {
    if (!enabled) {
        return null;
    }
    const keyNew = `${key}New`;
    if (data[keyNew]) {
        return (
            <div
                className="changed"
                style={{
                    color: '#1677ff',
                }}
            >
                {transferFun ? transferFun(data[keyNew]) : data[keyNew]}
            </div>
        );
    }
    return null;
};
