import React, { useEffect, useState } from 'react';
import s from './s.module.less';
import { TConditionSetting,
    TPageManagement,
} from 'types/helloKlarity';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import useGetSpecialtyList from 'hooks/klarityServiceTemplateOps/useGetSpecialtyList';

type IProps = {
    goLiveObj: TPageManagement;
    isMobileMode: boolean;
    previewName: string;
};

const ConditionPreview = (props: IProps) => {
    const { goLiveObj, isMobileMode, previewName } = props;
    const [tabActiveKey, setTabActiveKey] = useState('1');
    const [previewSpecialty, setPreviewSpecialty] = useState('1');
    // const [listData, tableLoading, error, refetch] = useGetSpecialtyList();

    const onChange = (key: string) => {
        console.log(key);
        setPreviewSpecialty(
            `${
                (goLiveObj.conditions || []).find(
                    (item) => `${item.serviceSpecialtyPriority}` === key,
                )?.serviceSpecialtyName
            }`,
        );
        setTabActiveKey(key);
    };

    const findSpecialtyName = (priority:number) => {
        return (goLiveObj.conditions || []).find(
            (conditionItem: TConditionSetting) =>
                conditionItem.serviceSpecialtyPriority === priority,
        )?.serviceSpecialtyName;
    };

    const tabItems: TabsProps['items'] = [
        {
            key: '1',
            label: findSpecialtyName(1),
            children: '',
        },
        {
            key: '2',
            label: findSpecialtyName(2),
            children: '',
        },
        {
            key: '3',
            label: findSpecialtyName(3),
            children: '',
        },
        {
            key: '4',
            label: findSpecialtyName(4),
            children: '',
        },
    ];

    useEffect(() => {
        setPreviewSpecialty(previewName);
        setTabActiveKey(`${(goLiveObj.conditions || []).find((item) => item.serviceSpecialtyName === previewName)?.serviceSpecialtyPriority}`);
    }, [previewName, goLiveObj.conditions]);
    return (
        <div className={isMobileMode ? s.previewMobile : s.previewPc}>
            <div className={s.content}>
                <div className={s.title}>
                    Top providers by most-searched specialties
                </div>
                <div className={s.changeBar}>
                    <Tabs
                        activeKey={tabActiveKey}
                        defaultActiveKey="1"
                        items={tabItems}
                        onChange={onChange}
                    />
                </div>
                <div className={s.providerList}>
                    {(goLiveObj.conditions || [])
                        .filter((item) => item.serviceSpecialtyName === previewSpecialty)
                        ?.map((item) => (
                            <div className={s.providerColumn} key={item.conditionName}>
                                <div className={s.info}>
                                    <div className={s.infoHeader}>
                                        <div className={s.logoBox}>
                                            <img src={item.logo} className={s.logo} alt="logo" />
                                        </div>
                                        <div className={s.name}>{item.conditionName}</div>
                                    </div>
                                    <div className={s.description}>{item.subtext}</div>
                                    <div className={s.link}>View all Providers</div>
                                </div>
                                <div className={s.cardWrap}>
                                    <div className={s.card} />
                                    <div className={s.card} />
                                    <div className={s.card} />
                                    <div className={s.shadow} />
                                    <div className={s.arrow} />
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default ConditionPreview;
