import TinyStore from 'lib/tinyStore';
import { IProviderStore } from 'types/practiceFront';

export const initApplicationFormData = {
    email: '',
    appointmentIntegrationUrl: '',
    useIntegrationUrl: false,
    appointmentNewFlag: false,
    theme: '',
    basicInfo: {
        id: -1,
        firstName: '',
        lastName: '',
        title: '',
        credential: [],
        photo: '',
        providerPractice: {
            treatmentPhilosophy: '',
            treatmentApproach: '',
            practiceName: '',
            practiceAddress: '',
            practiceCity: '',
            practiceState: '',
            practiceZip: '',
            practiceEmail: '',
            practicePhone: '',
            practiceWebsite: '',
            companyType: '',
            practiceDesc: '',
            practiceLogo: '',
            practiceFrontUrl: '',
            conditionTreatedList: [],
            specialtyList: [],
            addressList: [],
        },
    },
    insurance: {
        insuranceList: [],
        otherInsuranceList: [],
    },
    channels: [],
    aboutMe: {
        intro: '',
        yearExp: 0,
        headLine: '',
        photo: '',
    },
    myPhilosophy: {
        treatmentPhilosophy: '',
        treatmentApproach: '',
    },
    specialtiesApproaches: {
        specialtyList: [],
        conditionTreatedList: [],
    },
    experience: [],
    education: [],
    service: [],
    contact: {
        treatmentPhilosophy: '',
        treatmentApproach: '',
        practiceName: '',
        practiceAddress: '',
        practiceCity: '',
        practiceState: '',
        practiceZip: '',
        practiceEmail: '',
        practicePhone: '',
        practiceWebsite: '',
        companyType: '',
        practiceDesc: '',
        practiceLogo: '',
    },
};

type ProviderModal = {
    data: IProviderStore;
    loading: boolean;
};

const Store = TinyStore.createStore<ProviderModal>({
    nameSpace: 'klarity',
    key: 'practiceFrontForm',
    crossBundle: false,
    runTime: true,
    initValue: { data: { ...initApplicationFormData }, loading: false },
});

export default Store;
