import React, {
    useRef,
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';
import type {
    TAssessmentItem,
    TPreviewQuestion,
} from 'types/operation';
import QuestionFrom, { IQuestionFrom } from '../QuestionFrom';
import EngagementQuestions from '../EngagementQuestions';
import { Tabs } from 'antd';
import s from './s.module.less';
import commonTabS from 'styles/common.tab.less';

type IProps = {
    goLiveObj: TAssessmentItem;
    isLoading: boolean;
    onSuccess: (item: Partial<TAssessmentItem>) => void;
    onPreview: (item: TPreviewQuestion) => void;
};

export interface IQuestionManagement {
    validateAndSubmit: () => boolean;
}

const QuestionManagement = forwardRef<IQuestionManagement, IProps>((props, ref) => {
    const { goLiveObj, onSuccess, onPreview } = props;
    const QuestionFromRef = useRef<IQuestionFrom>(null);
    const [tabActiveKey, setTabActiveKey] = useState('1');
    const [previewEngagementQuestionItem, setPreviewEngagementQuestionItem] = useState({});
    const [previewQuestionFromItem, setPreviewQuestionFromItem] =
      useState<TPreviewQuestion>({} as TPreviewQuestion);

    const handlePreview = (item: TPreviewQuestion) => {
        if (item.type === 'questionFrom') {
            const params = {
              ...item,
              questionsPreviewLink: (item.questionsFormLink || '').replace(
                '/edit',
                '/viewform'
              ),
            };
            setPreviewQuestionFromItem(params);
            onPreview(params);

        } else if (item.type === 'engagementQuestion') {
            setPreviewEngagementQuestionItem(item);
            onPreview(item);
        }
    };

    const tabItems = [
        {
            key: '1',
            label: <span className={s.tabLabel}>Questions from</span>,
            children: (
                <QuestionFrom
                    item={goLiveObj}
                    onSuccess={onSuccess}
                    onPreview={handlePreview}
                />
            ),
        },
        {
            key: '2',
            label: <span className={s.tabLabel}>Engagement questions </span>,
            children: (
                <EngagementQuestions
                    item={goLiveObj}
                    onSuccess={onSuccess}
                    onPreview={handlePreview}
                />
            ),
        },
    ];

    const handleTabClick = (key: string) => {
        setTabActiveKey(key);
        if (key === '1') {
            onPreview(previewQuestionFromItem);
        } else {
            onPreview(previewEngagementQuestionItem);
        }
    };

    useImperativeHandle(ref, () => ({
        validateAndSubmit: () => {
            return (
                QuestionFromRef.current?.validateAndSubmit() || false
            );
        },
    }));
    return (
        <div className={commonTabS.content}>
            <Tabs
                defaultActiveKey="1"
                activeKey={tabActiveKey}
                items={tabItems as any}
                onTabClick={handleTabClick}
            />
        </div>
    );
});

export default QuestionManagement;
