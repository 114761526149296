let googleClient:{
    requestAccessToken:(params:unknown)=>void
};

const get = () => {
    return googleClient;
};

const init = (config:any) => {
    const { google } = window as any;
    googleClient = google.accounts.oauth2.initTokenClient(config);
};

export {
    get,
    init,
};
