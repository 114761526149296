// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--IreMO .ant-form-item-control-input-content button {
  background-color: var(--primary-color);
}
.s-module__wrap--IreMO .ant-form-item-control-input-content .ant-switch {
  background-color: rgba(0, 0, 0, 0.25);
}
.s-module__wrap--IreMO :where(.css-dev-only-do-not-override-1uhj8f6).ant-switch.ant-switch-checked {
  background-color: var(--primary-color);
}
.s-module__inputWrap--eopsN {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.s-module__inputWrap--eopsN .s-module__input--dyWfy {
  margin-right: 8px;
}
.s-module__switchWrap--uGoli {
  display: flex;
  align-items: center;
}
.s-module__switchWrap--uGoli .s-module__switch--AIicg {
  margin-right: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/operation/HelloKlarityProviders/components/AirTableTable/components/AddModal/s.module.less"],"names":[],"mappings":"AAAA;EAGY,sCAAA;AADZ;AAFA;EAMY,qCAAA;AADZ;AALA;EASY,sCAAA;AADZ;AASA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;AAPJ;AAIA;EAMQ,iBAAA;AAPR;AAWA;EACI,aAAA;EACA,mBAAA;AATJ;AAOA;EAKQ,iBAAA;AATR","sourcesContent":[".wrap {\n    :global {\n        .ant-form-item-control-input-content button {\n            background-color: var(--primary-color);\n        }\n        .ant-form-item-control-input-content .ant-switch {\n            background-color: rgba(0, 0, 0, 0.25);\n        }\n        :where(.css-dev-only-do-not-override-1uhj8f6).ant-switch.ant-switch-checked {\n            background-color: var(--primary-color);\n        }\n        // .ant-switch {\n        //     background-color: rgba(0, 0, 0, 0.25);\n        // }\n    }\n}\n\n.inputWrap {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n\n    .input {\n        margin-right: 8px;\n    }\n}\n\n.switchWrap {\n    display: flex;\n    align-items: center;\n\n    .switch {\n        margin-right: 8px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--IreMO`,
	"inputWrap": `s-module__inputWrap--eopsN`,
	"input": `s-module__input--dyWfy`,
	"switchWrap": `s-module__switchWrap--uGoli`,
	"switch": `s-module__switch--AIicg`
};
export default ___CSS_LOADER_EXPORT___;
