import TinyStore from 'lib/tinyStore';
import { IKlarityProgramTagResp } from 'types/programs';

type ReviewModal = {
    tags: IKlarityProgramTagResp[] | null;
};

const Store = TinyStore.createStore<ReviewModal>({
    nameSpace: 'klarity',
    key: 'programs',
    crossBundle: false,
    runTime: true,
    initValue: { tags: null },
});
export default Store;
