import React, { useCallback, useEffect, useState } from 'react';
import update from 'immutability-helper';
import s from './s.module.less';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DragItem from './DragItem';
import { IKlarityServiceTypeLimitation, IKlarityServiceTypePatientGroup } from 'types/common';
import { generateNewItem } from 'utils/serviceTemplate';
import lodashUpdate from 'lodash/update';
import { generateUUID } from 'utils/common';

type TValue = (IKlarityServiceTypeLimitation | IKlarityServiceTypePatientGroup)[];
interface IProps {
    contextType: string;
    placeholder?: string;
    value?: TValue
    onChange?: (v: TValue) => void;
}

const DragabelList = ({
    contextType,
    placeholder,
    value,
    onChange,
}:IProps) => {
    const [val, setVal] = useState<TValue>([]);

    useEffect(() => {
        if (value && value.length > 0) {
            setVal(value);
        }
    }, [value]);

    const resetSequence = useCallback((data: TValue) => {
        return data.map((e, i:number) => ({
            ...e,
            sequence: i + 1,
        }));
    }, []);

    const handleMoveItem = useCallback((dragIndex: number, hoverIndex: number) => {
        const newData = resetSequence(update(val, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, val[dragIndex]],
            ],
        }));
        setVal([...newData]);
        onChange?.([...newData]);
    }, [onChange, resetSequence, val]);

    const handleDeleteItem = useCallback((index: number) => {
        const newData = resetSequence(
            update(val, { $splice: [[index, 1]] }),
        );

        setVal(newData);
        onChange?.(newData);
    }, [onChange, resetSequence, val]);

    const handleAddItem = useCallback(() => {
        const newItem = generateNewItem(val.length, val[0]?.serviceTypeId, contextType);
        const newData = resetSequence([
            ...val
                .map((e) => ({ ...e, isPlaceholder: false, notDelete: e.notDelete || false }))
                .filter((e) => {
                    if (contextType === 'patientGroup') {
                        return !!e.patientGroup;
                    } else {
                        return !!e.limitation;
                    }
                }),
            {
                ...newItem,
                isPlaceholder: true,
                notDelete: false,
                key: generateUUID(),
            }]);

        setVal(newData);
        onChange?.(newData);
    }, [contextType, onChange, resetSequence, val]);

    const handleInputChange = useCallback((v: string, index:number) => {
        const field = contextType === 'patientGroup' ? 'patientGroup' : 'limitation';
        const newData = lodashUpdate(val, `[${index}].${field}`, () => v);

        setVal([...newData]);
        onChange?.([...newData]);
    }, [contextType, onChange, val]);

    return (
        <div className={s.wrap}>
            {/* <div className={s.title}>{label}</div> */}

            <DndProvider backend={HTML5Backend}>
                {
                    val.map((e, i:number) => {
                        const { id, type, limitation, patientGroup, notDelete, isPlaceholder, key } = e;
                        const cid = id || key;
                        const v = contextType === 'patientGroup' ? patientGroup : limitation;
                        return (
                            <DragItem
                                contextType={contextType}
                                type={type}
                                key={cid}
                                id={cid}
                                index={i}
                                text={v}
                                notDelete={notDelete}
                                isPlaceholder={isPlaceholder}
                                placeholder={placeholder}
                                onInputChange={handleInputChange}
                                onMoveItem={handleMoveItem}
                                onDelete={handleDeleteItem}
                                onAdd={handleAddItem}
                            />
                        );
                    })
                }
            </DndProvider>
        </div>
    );
};

export default DragabelList;
