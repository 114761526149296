import TinyStore from 'lib/tinyStore';

type AiChatModal = {
    isShowChatBox: boolean;
};

const Store = TinyStore.createStore<AiChatModal>({
    nameSpace: 'klarity',
    key: 'ai_chat',
    crossBundle: false,
    runTime: true,
    initValue: { isShowChatBox: true },
});

export default Store;
