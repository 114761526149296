import React, { useCallback, useEffect, useMemo } from "react";
import s from "./s.module.less";
import CommonTable from "components/CommonTable";
import { Button, Checkbox, Form, Input, InputNumber, Modal, Select, Spin } from "antd";
import commonS from "styles/common.module.less";
import SessionModal from "./SessionModal";
import MedicationModal from "./MedicationModal";
import { IklarityProgramMedicationItem, IKlarityProgramPackageSessionItem } from "types/programs";
import debounce from 'lodash/debounce'
import { CheckboxChangeEvent } from "antd/es/checkbox";
import useGetSessionList from "hooks/ProgramsOps/useGetSessionList";
import useGetMedicationList from "hooks/ProgramsOps/useGetMedicationList";
import { sourceType } from "constants/program";
import useGetServiceTypeList from 'hooks/klarityServiceTemplateOps/useGetServiceTypeList';

interface IProps {
    originalIncludedItemsData?: {
        klarityProgramPackageSessionItemList: IKlarityProgramPackageSessionItem[];
        klarityProgramMedicationItemList: IklarityProgramMedicationItem[];
    }
    includedItemsData?: {
        klarityProgramPackageSessionItemList: IKlarityProgramPackageSessionItem[];
        klarityProgramMedicationItemList: IklarityProgramMedicationItem[];
    };
    setIncludedItemsData?: (data: any) => void;
    refetch: () => void;
    programId: number;
    packageId: number;
}

const IncludedItems = ({
    originalIncludedItemsData,
    includedItemsData,
    setIncludedItemsData,
    refetch,
    programId,
    packageId,
}: IProps) => {
    const [servcieTypeList, serviceTypeListLoading] = useGetServiceTypeList();
    const { klarityProgramPackageSessionItemList, klarityProgramMedicationItemList } = includedItemsData || {};
    const [showSessionModal, setShowSessionModal] = React.useState<boolean>(false);
    const [showMedicationModal, setShowMedicationModal] = React.useState<boolean>(false);
    const [sessionSubmiting, setSessionSubmiting] = React.useState<boolean>(false);
    const [mediationSubmiting, setMediationSubmiting] = React.useState<boolean>(false);
    const [sessionList, sessionListLoading, refetchSessionList] = useGetSessionList();
    const [medicationList, medicationListLoading, refetchMedicationList] = useGetMedicationList();
    const [pendingSelectSessions, setPendingSelectSessions] = React.useState<number[]>([]);
    const [pendingSelectMedications, setPendingSelectMedications] = React.useState<number[]>([]);
    const [sessionSelectorValue, setSessionSelectorValue] = React.useState<number[]>([]);
    const [medicationSelectorValue, setMedicationSelectorValue] = React.useState<number[]>([]);

    const sessionOptions = useMemo(() => {
        return (
            sessionList?.filter((item) => item.programId === programId)?.map(item => {
                const findedItemFromTableData = klarityProgramPackageSessionItemList?.find((session) => String(session.serviceSessionId) === String(item.id));

                return {
                    ...item,
                    sessionName: item.serviceSessionName,
                    isBookable: false,
                    isDeleted: false,
                    isRenewal: false,
                    packageId,
                    programId,
                    quantity: 1,
                    serviceSessionId: item.id,
                    disabled: !!findedItemFromTableData,
                    label: item.serviceSessionName,
                    value: item.id,
                }
            }) || []
        )
    }, [sessionList, klarityProgramPackageSessionItemList, programId]);

    const medicationOptions = useMemo(() => {
        return (
            medicationList?.filter((item) => (item.sourceType === sourceType.PROGRAM_WL && String(item.sourceId) === String(programId)))?.map(item => {
                const findedItemFromTableData = klarityProgramMedicationItemList?.find((mediation) => mediation.medicationId === item.id);
                
                return ({
                    ...item,
                    isDeleted: false,
                    packageId,
                    programId,
                    // price: 0,
                    quantity: 1,
                    disabled: !!findedItemFromTableData,
                    medicationId: item.id,
                    label: item.medicationName,
                    value: item.id
                })
            }) || []
        )
    }, [medicationList, klarityProgramMedicationItemList, programId]);

    const serviceTypeOptions = useMemo(() => {
        return (
            servcieTypeList?.map(item => ({
                label: item.serviceType,
                value: item.id
            })) || []
        )
    }, [servcieTypeList]);

    useEffect(() => {
        if (klarityProgramPackageSessionItemList && klarityProgramPackageSessionItemList.length > 0) {
            const filteredSessionIds = klarityProgramPackageSessionItemList.filter((item) => !!(sessionOptions.find(option => {
                return option.value === item.serviceSessionId
            }))).map(item => item.serviceSessionId);
            setSessionSelectorValue(filteredSessionIds);
        } else {
            setSessionSelectorValue([]);
        }

        if (klarityProgramMedicationItemList && klarityProgramMedicationItemList.length > 0) {
            const filteredMedicationIds = klarityProgramMedicationItemList.filter((item) => !!(medicationOptions.find(option => option.value === item.medicationId))).map(item => item.medicationId);

            setMedicationSelectorValue(filteredMedicationIds);
        } else {
            setMedicationSelectorValue([]);
        }
    }, [klarityProgramPackageSessionItemList, klarityProgramMedicationItemList, sessionOptions, medicationOptions]);

    const handleRemoveSession = useCallback((item: IKlarityProgramPackageSessionItem) => {
        if (!includedItemsData) return;
        const newData = includedItemsData.klarityProgramPackageSessionItemList.filter(medication => medication.serviceSessionId !== item.serviceSessionId);
        setIncludedItemsData({
            ...includedItemsData,
            klarityProgramPackageSessionItemList: newData
        })
    }, [includedItemsData]);

    const handleRemoveMedication = useCallback((item: IklarityProgramMedicationItem) => {
        if (!includedItemsData) return;
        const newData = includedItemsData.klarityProgramMedicationItemList.filter(medication => medication.medicationId !== item.medicationId);
        setIncludedItemsData({
            ...includedItemsData,
            klarityProgramMedicationItemList: newData
        })
    }, [includedItemsData]);

    const debouncedUpdateTalbeDateByRowIndex = useCallback(debounce((fieldName: 'klarityProgramPackageSessionItemList'|'klarityProgramMedicationItemList', rowIndex: number, data: any) => {
        updateTalbeDateByRowIndex(fieldName, rowIndex, data);
    }, 300), [includedItemsData]);

    const updateTalbeDateByRowIndex = useCallback((fieldName: 'klarityProgramPackageSessionItemList'|'klarityProgramMedicationItemList', rowIndex: number, data: any) => {
        if (!includedItemsData) return;
        const newData = includedItemsData[fieldName];
        newData[rowIndex] = {
            ...newData[rowIndex],
            ...data
        };

        setIncludedItemsData({
            ...includedItemsData,
            [fieldName]: newData
        })
    }, [includedItemsData]);

    const sessionColumns = [
        {
            title: 'SERVICE SESSIONS',
            dataIndex: 'sessionName',
            // dataIndex: 'serviceSessionId',
            className: s.tableColumn,
            width: '250px',
            fixed: 'left',
            // render: (state: string) => getStateDisplayName(state, stateData),
        },
        {
            title: 'TYPE',
            dataIndex: 'serviceType',
            className: s.tableColumn,
            width: '120px',
            // render: (deaNumber: string) => deaNumber || '-',
        },
        {
            title: 'QUANTITY',
            dataIndex: 'quantity',
            className: s.tableColumn,
            width: '120px',
            render: (value: number, rowData: IKlarityProgramPackageSessionItem, rowIndex: number) => {
                return <InputNumber value={klarityProgramPackageSessionItemList?.[rowIndex]?.quantity} onChange={(value: number |null) => {
                    if (typeof value === 'number') {
                        debouncedUpdateTalbeDateByRowIndex('klarityProgramPackageSessionItemList', rowIndex, { quantity: value })
                    }
                }}/>;
            },
        },
        {
            title: 'RENEWAL',
            dataIndex: 'isRenewal',
            className: s.tableColumn,
            width: '120px',
            render: (v: boolean, rowData: IKlarityProgramPackageSessionItem, rowIndex: number) => {
                return <Checkbox checked={klarityProgramPackageSessionItemList?.[rowIndex]?.isRenewal} onChange={(e: CheckboxChangeEvent) => {
                    updateTalbeDateByRowIndex('klarityProgramPackageSessionItemList', rowIndex, { isRenewal: e.target.checked })
                }} />;
            }
        },
        {
            title: 'BOOKABLE DURING PURCHASE',
            dataIndex: 'isBookable',
            className: s.tableColumn,
            width: '120px',
            render: (v: boolean, rowData: IKlarityProgramPackageSessionItem, rowIndex: number) => {
                return <Checkbox checked={klarityProgramPackageSessionItemList?.[rowIndex]?.isBookable} onChange={(e: CheckboxChangeEvent) => {
                    updateTalbeDateByRowIndex('klarityProgramPackageSessionItemList', rowIndex, { isBookable: e.target.checked })
                }} />;
            }
        },
        {
            title: '',
            className: s.tableColumn,
            width: '180px',
            fixed: 'right',
            render: (item: IKlarityProgramPackageSessionItem) => {
                return (
                    <Button className={s.actionBtn} type="ghost" onClick={() => handleRemoveSession(item)}>Remove</Button>
                )
            }
        },
    ];

    const medicationColumns = [
        {
            title: 'MEDICATION NAME',
            // dataIndex: 'serviceSessions',
            dataIndex: 'medicationName',
            className: s.tableColumn,
            width: '250px',
            fixed: 'left',
            // render: (state: string) => getStateDisplayName(state, stateData),
        },
        // {
        //     title: 'TYPE',
        //     dataIndex: 'type',
        //     className: s.tableColumn,
        //     width: '120px',
        //     // render: (deaNumber: string) => deaNumber || '-',
        // },
        {
            title: 'QUANTITY',
            dataIndex: 'quantity',
            className: s.tableColumn,
            width: '120px',
            render: (value: number, rowData: IklarityProgramMedicationItem, rowIndex: number) => {
                return <InputNumber value={klarityProgramMedicationItemList?.[rowIndex]?.quantity} onChange={(value: number |null) => {
                    if (typeof value === 'number') {
                        debouncedUpdateTalbeDateByRowIndex('klarityProgramMedicationItemList', rowIndex, { quantity: value })
                    }
                }}/>;
            },
        },
        {
            title: 'BRAND',
            dataIndex: 'isBranded',
            className: s.tableColumn,
            width: '120px',
            render: (v: boolean) => {
                return v ? 'Branded' : 'Non-Brand';
            }
        },
        {
            title: 'DOSAGE',
            dataIndex: 'dosage',
            className: s.tableColumn,
            width: '120px',
            // render: (v: boolean) => {
            //     return <Checkbox checked={v} />;
            // }
        },
        {
            title: 'PRICE',
            dataIndex: 'price',
            className: s.tableColumn,
            width: '120px',
            render: (v: number) => {
                return `$${v}`;
            }
        },
        {
            title: 'PHARMACY',
            dataIndex: 'fulfillmentPharmacyName',
            className: s.tableColumn,
            width: '120px',
        },
        {
            title: '',
            className: s.tableColumn,
            width: '180px',
            fixed: 'right',
            render: (item: IklarityProgramMedicationItem) => {
                return (
                    <Button className={s.actionBtn} type="ghost" onClick={() => handleRemoveMedication(item)}>Remove</Button>
                )
            }
        },
        {
            title: 'NOTE',
            dataIndex: 'note',
            className: s.tableColumn,
            fixed: 'right',
            width: '330px',
        },
    ];

    const handleSessionFormSubmit = useCallback(() => {
        refetch();
        refetchSessionList();
        setShowSessionModal(false);
    }, []);

    const handleMedicationFormSubmit = useCallback(() => {
        refetch();
        refetchMedicationList();
        setShowMedicationModal(false);
    }, []);

    const handleSessionSelect = useCallback((sessionIds: number[]) => {
        setSessionSelectorValue(sessionIds);
        const newData = sessionOptions.filter(option => sessionIds.includes(option.value)).map((item) => {
            const itemInPrevData = includedItemsData?.klarityProgramPackageSessionItemList?.find((medication) => medication.serviceSessionId === item.value);
            return {
                ...item,
                ...itemInPrevData,
            }
        })
        .map((item) => {
            const isExistData = originalIncludedItemsData?.klarityProgramPackageSessionItemList?.find((session) => session.serviceSessionId === item.value);
            return {
                ...item,
                id: isExistData ? isExistData.id : undefined,
            }
        });

        setIncludedItemsData({
            ...includedItemsData,
            klarityProgramPackageSessionItemList: newData,
        })
    }, [sessionOptions,includedItemsData, originalIncludedItemsData]);

    const handleMedicationSelect = useCallback((medicationIds: number[]) => {
        setMedicationSelectorValue(medicationIds);
        const newData = medicationOptions.filter(option => medicationIds.includes(option.value)).map((item) => {
            const itemInPrevData = includedItemsData?.klarityProgramMedicationItemList?.find((medication) => medication.medicationId === item.value) || {};

            return {
                ...item,
                ...itemInPrevData,
            }
        })
        .map((item) => {
            const isExistData = originalIncludedItemsData?.klarityProgramMedicationItemList?.find((medication) => medication.medicationId === item.value);

            return {
                ...item,
                id: isExistData ? isExistData.id : undefined,
            }
        });

        setIncludedItemsData({
            ...includedItemsData,
            klarityProgramMedicationItemList: newData,
        })
    }, [medicationOptions,includedItemsData,originalIncludedItemsData]);
    
    return (
        <div className={s.wrap}>
            <Modal
                title="Manage service sessions"
                width={900}
                className={commonS.modalFixHeightWrap}
                open={showSessionModal}
                onCancel={() => setShowSessionModal(false)}
                zIndex={1011}
                destroyOnClose
                footer={
                    <div className={s.modalFooter}>
                        <Button onClick={() => setShowSessionModal(false)}>Cancel</Button>
                        <Button form="sessionForm" loading={sessionSubmiting} type="primary" htmlType="submit">Save</Button>
                    </div>
                }
            >
                <SessionModal programId={programId} serviceTypeOptions={serviceTypeOptions} setSessionSubmiting={setSessionSubmiting} onSubmitSuccess={handleSessionFormSubmit}/>
            </Modal>
            <Modal
                title="Manage medications"
                width={900}
                className={commonS.modalFixHeightWrap}
                open={showMedicationModal}
                onCancel={() => setShowMedicationModal(false)}
                zIndex={1011}
                destroyOnClose
                footer={
                    <div className={s.modalFooter}>
                        <Button onClick={() => setShowMedicationModal(false)}>Cancel</Button>
                        <Button form="medicationForm" loading={mediationSubmiting} type="primary" htmlType="submit">Save</Button>
                    </div>
                }
            >
                <MedicationModal packageId={packageId} programId={programId} setMedicationSubmiting={setMediationSubmiting} onSubmitSuccess={handleMedicationFormSubmit}/>
            </Modal>
            <Spin spinning={serviceTypeListLoading || sessionListLoading || medicationListLoading}>
                <div className={s.block}>
                    <div className={s.title}>Service sessions</div>
                    <div className={s.item}>
                        <Select
                            value={sessionSelectorValue}
                            placeholder="Search to select" className={s.searchSelctor} options={sessionOptions} mode="multiple" onChange={handleSessionSelect}></Select>
                        <Button onClick={() => setShowSessionModal(true)}>Manage service sessions</Button>
                    </div>
                    <div className={s.table}>
                        <CommonTable
                            bordered
                            rowKey="id"
                            columns={sessionColumns}
                            data={klarityProgramPackageSessionItemList || []}
                            pagination={false}
                        />
                    </div>
                </div>
                <div className={s.block}>
                    <div className={s.title}>Medication</div>
                    <div className={s.counts}>{klarityProgramMedicationItemList ? klarityProgramMedicationItemList.length : 0} medications</div>
                    <div className={s.item}>
                        <Select
                            value={medicationSelectorValue}
                            placeholder="Search to select" className={s.searchSelctor} options={medicationOptions} mode="multiple" onChange={handleMedicationSelect}></Select>
                        <Button onClick={() => setShowMedicationModal(true)}>Manage medications</Button>
                    </div>
                    <div className={s.table}>
                        <CommonTable
                            bordered
                            rowKey="id"
                            columns={medicationColumns}
                            data={klarityProgramMedicationItemList || []}
                            pagination={false}
                        />
                    </div>
                </div>
            </Spin>
        </div>
    );
};

export default IncludedItems;
