import React, { useEffect } from 'react';
import s from './s.module.less';
import { Button, Form, Input } from 'antd';

interface IProps {
    emailPrompt?: string;
    onSave: (values: any, callback: () => void) => void;
}

const Indication = ({
    emailPrompt,
    onSave,
}: IProps) => {
    const [formInstance] = Form.useForm();
    const [isEdit, setIsEdit] = React.useState(false);

    useEffect(() => {
        formInstance.setFieldsValue({
            emailPrompt,
        });
    }, [emailPrompt, formInstance]);

    const handleStartEdit = React.useCallback(() => {
        setIsEdit(true);
    }, []);

    const handleCancelEdit = React.useCallback(() => {
        setIsEdit(false);
    }, []);

    const handleSave = React.useCallback(() => {
        formInstance.validateFields().then((values) => {
            onSave(values, () => setIsEdit(false));
        }).catch((e) => {
            console.error(e);
        });
    }, [formInstance, onSave]);

    const ButtonRender = React.useMemo(() => {
        if (isEdit) {
            return (
                <>
                    <Button className={s.btn} onClick={handleCancelEdit}>Cancel</Button>
                    <Button htmlType="submit" form="pmform" className={s.saveBtn} onClick={handleSave}>Save</Button>
                </>
            );
        }

        return <Button className={s.btn} onClick={handleStartEdit}>Edit</Button>;
    }, [handleCancelEdit, handleSave, handleStartEdit, isEdit]);

    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <div className={s.title}>Email template</div>
                <div className={s.btns}>
                    {ButtonRender}
                </div>
            </div>
            <div className={s.body}>
                {
                    isEdit ? (
                        <Form
                            id="pmform"
                            form={formInstance}
                            layout="vertical"
                        >
                            <Form.Item
                                name="emailPrompt"
                                label="Prompt for email results"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input prompt',
                                    },
                                ]}
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Form>
                    ) : (
                        <div className={s.formWrap}>
                            <div className={s.row}>
                                <div className={s.item}>
                                    <div className={s.label}>Prompt for email results</div>
                                    <div className={s.value}>{emailPrompt}</div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default Indication;
