import { Button, Input, Spin } from 'antd';
import React from 'react';
import s from './s.module.less';
import HistoryAction from '../HistoryButton';
import {
    PlusOutlined,
    LoadingOutlined,
} from '@ant-design/icons';
import MarkdownRenderer from 'components/MarkdownRenderer';
import TableWithFilter from './TableWithFilter';
import { TChatMessage, THistoryMessage, TPromptMessage, TStreamingChatRep, TStreamingChatRes, TTableData, TTableProvider } from 'types/ai';
import { getSuggested, streamingChat, getConversations, getMessages } from 'api/ai';
import { getAiApiData, getAiUserId, insertUrlParam, isAiApiSuccess } from 'utils/common';
import { setAiSearchId, setAiSearchName } from 'utils/sessionStorage';

type Props = {
    searchValue?: string;
    onNewSearch?: () => void;
    conversationId?: string; //'' means new conversation
    onConversationUpdateFromHistory: (id: string, name: string) => void,
    messageIdProviderMap: Record<string, TTableData>,
    conversationName?: string,
};

const MOCK_ANSWER = `
Here are providers in Texas with experience in ADHD medications and session fees below $200:

1. **Michele Heyman, LPC**
   - 
2. **Shayna Labarge, MSN, FNP-BC, PMHNP-BC**
   - **Experience:** 10 years
   - **Specialty:** Mental Health
   - **Conditions Treated:** Anxiety, Depression, Insomnia, ADD/ADHD, Mood Disorders, ADHD
   - **Session Fee:** $149 for an Initial Online Visit
   - [Book a session with Shayna Labarge](https://care.helloklarity.com/booking?state=AZ&provider=61bce37a9557693990c1c322&service=f92200c2-ab5b-4330-9332-b53f7fbb9f5a)

3. **Lori Gonzales DNP, PMHNP-BC, ACNP-BC**
   - **Experience:** 12 years
   - **Specialty:** Mental Health
   - **Conditions Treated:** ADD/ADHD, Anxiety, Depression, Insomnia, ADHD
   - **Session Fee:** $149 for an Initial Online Visit
   - [Book a session with Lori Gonzales](https://care.helloklarity.com/booking?state=NM&provider=63c985acd10b49c47f63f499&service=c9bf7857-f790-4a26-bb6a-a267c276c200)

These providers offer online sessions and have experience with ADHD medications.
`;

const Page = (props: Props) => {
    const { searchValue, onNewSearch, conversationId, conversationName, messageIdProviderMap, onConversationUpdateFromHistory } = props;
    const [showProvider, setShowProvider] = React.useState(false);

    const [isStreaming, setIsStreaming] = React.useState(false);
    const [isWaitFirstStreamingReturn, setIsWaitFirstStreamingReturn] = React.useState(false);
    const [suggestion, setSuggestion] = React.useState<string[]>(['Find similar providers as...', 'Who are available on...']);

    const loadedTPrompt = React.useRef<TChatMessage[]>([]);
    const pendingTPromp = React.useRef<TChatMessage>({}); //add when new search start ? or get first message
    const scrollRef = React.useRef<HTMLDivElement>(null);
    const [, forceUpdate] = React.useState({});

    const [currentConversationId, setCurrentConversationId] = React.useState<string | undefined>(conversationId);
    const [currentConversationName, setCurrentConversationName] = React.useState<string | undefined>(conversationName);

    const [latestMessageId, setLatestMessageId] = React.useState<string>();
    const [latestAnswerStr, setLatestAnswerStr] = React.useState<string>('');
    const [latestSearchValue, setLatestSearchValue] = React.useState<string>('');
    const [currentSearchValue, setCurrentSearchValue] = React.useState<string>('');

    const [historyMessageList, setHistoryMessageList] = React.useState<THistoryMessage[]>();

    const autoScrollFlag = React.useRef({ auto: true });

    const [pendingSearchValue, setPendingSearchValue] = React.useState('');
    const [isLoadingInitMessage, setIsLoadingInitMessage] = React.useState(false);
    const [hasMore, setHasMore] = React.useState(false);

    const [chatTitle, setChatTitle] = React.useState<string | undefined>(searchValue);

    const isHistory = !!currentConversationName;
    const fetchMessagePageSize = 50;

    const scrollToBottom = () => {
        const item = scrollRef.current;
        if (item && autoScrollFlag.current.auto) {
            item.scrollTop = item?.scrollHeight;
        }
    };

    React.useEffect(() => {
        const timer = window.setInterval(scrollToBottom, 100);
        return () => {
            clearInterval(timer);
        };
    }, []);

    React.useEffect(() => {
        const handleScroll = () => {
            const scrollElement = scrollRef.current;
            if (scrollElement) {
                const { scrollTop, scrollHeight, clientHeight } = scrollElement;
                if (scrollHeight - scrollTop - clientHeight < 30) {
                    autoScrollFlag.current.auto = true;
                } else {
                    autoScrollFlag.current.auto = false;
                }
            }
        };
        const scrollElement = scrollRef.current;
        if (scrollElement) {
            scrollElement.addEventListener('scroll', handleScroll);
            return () => {
                scrollElement.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);

    const doQuery = async (val: string, covId?: string) => {
        if (val) {
            autoScrollFlag.current.auto = true;
            setPendingSearchValue('');
            // fetch data
            setIsWaitFirstStreamingReturn(true);
            setIsStreaming(true);
            // if (isHistory) {
            //     setLatestSearchValue(val);
            // }

            if (pendingTPromp.current.done || loadedTPrompt.current.length > 0) {
                //second question?
                if (!pendingTPromp.current.done) {
                    //for history
                    setLatestSearchValue(val);
                } else {
                    setCurrentSearchValue(val);
                }
            }

            const req: TStreamingChatRes = {
                inputs: {},
                query: val,
                user: getAiUserId(),
                response_mode: 'streaming',
                do_evaluation: false,
                conversation_id: '',
            };
            if (covId) {
                req.conversation_id = covId;
            }
            const response: any = await streamingChat(req);

            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');
            let buffer = '';
            let msgId = '';

            while (true) {
                // eslint-disable-next-line no-await-in-loop
                const { value, done } = await reader.read();
                if (done) {
                    pendingTPromp.current.done = true;
                    setIsWaitFirstStreamingReturn(false);
                    setIsStreaming(false);
                    setLatestMessageId(msgId);
                    setLatestSearchValue(val);
                    break;
                }

                buffer += decoder.decode(value, { stream: true });
                const lines = buffer.split('\n');

                buffer = lines.pop()!; // 保留最后一个不完整的行

                for (const line of lines) {
                    if (line.startsWith('data:')) {
                        try {
                            const message: TStreamingChatRep = JSON.parse(line.replace(/^data: /, ''));
                            if (message?.answer) {
                                const lastPendingTPromp = pendingTPromp.current;
                                if (lastPendingTPromp.done) {
                                    loadedTPrompt.current.push({ ...lastPendingTPromp });
                                    pendingTPromp.current = {
                                        query: val,
                                        answer: '',
                                    };
                                    setLatestAnswerStr('');
                                    setLatestMessageId('');
                                    //start to show streaming text
                                    setLatestSearchValue(val);
                                    setCurrentSearchValue('');
                                }

                                setIsWaitFirstStreamingReturn(false);
                            }
                            setLatestAnswerStr((prevAnswer) => {
                                const newAnswer = prevAnswer + (message?.answer || '');
                                return newAnswer;
                            });
                            setCurrentConversationId(message?.conversation_id);
                            msgId = message?.message_id;
                            if (!pendingTPromp.current.done) {
                                pendingTPromp.current.answer = `${pendingTPromp.current.answer}${message?.answer || ''}`;
                                pendingTPromp.current.messageId = msgId;
                            }
                        } catch (e) {
                            console.error(e);
                        }
                    }
                }
            }
        }
    };

    const handleSearch = () => {
        if (pendingSearchValue) {
            doQuery(pendingSearchValue, currentConversationId);
            setPendingSearchValue('');
        }
    };

    const fetchNewConversation = async (cid: string) => {
        //fetch coversation data
        setIsLoadingInitMessage(true);
        const result = await getMessages(getAiUserId(), cid, fetchMessagePageSize);

        if (isAiApiSuccess(result)) {
            const data: {
                has_more: boolean,
                limit: number,
                data: THistoryMessage[],
            } = getAiApiData(result);
            const msgList = data.data;
            setHasMore(data.has_more);
            loadedTPrompt.current = msgList?.map((item: THistoryMessage) => {
                const ret: TChatMessage = {
                    query: item.query,
                    answer: item.answer,
                    messageId: item.id,
                };
                return ret;
            });
            forceUpdate({});
        }
        setIsLoadingInitMessage(false);
    };

    React.useEffect(() => {
        //init handle
        if (searchValue && !currentConversationId) {
            doQuery(searchValue, currentConversationId);
            return;
        } else if (currentConversationId && currentConversationName) {
            loadedTPrompt.current = [];
            setChatTitle(currentConversationName);
            fetchNewConversation(currentConversationId);
        }
        if (currentConversationId) {
            insertUrlParam('cid', currentConversationId);
            setAiSearchId(currentConversationId);
            setAiSearchName(currentConversationName || searchValue || '');
        }
    }, [searchValue, currentConversationId, currentConversationName]);

    React.useEffect(() => {
        const fetchSuggestions = async () => {
            const result = await getSuggested(latestMessageId!, getAiUserId());
            if (!result.error) {
                setSuggestion(result.data?.data?.data);
            }
        };
        if (latestMessageId) {
            fetchSuggestions();
        }
    }, [latestMessageId]);

    return (
        <div className={s.wrap}>
            <div className={s.nav}>
                <div className={s.left}>
                    {chatTitle}
                </div>
                <div className={s.right}>
                    <Button
                        onClick={() => {
                            onNewSearch?.();
                        }}
                        className={s.btn}
                        type="primary"
                        icon={<PlusOutlined />}
                    >New search
                    </Button>
                    <HistoryAction
                        onConversationUpdate={onConversationUpdateFromHistory}
                        onSearch={() => {
                            //onSearch?.(val);
                        }}
                    />
                </div>
            </div>
            <div
                className={s.content}
                ref={scrollRef}
            >
                <div className={s.contentIn}>
                    {
                        loadedTPrompt.current.map((item: TChatMessage, inx: number) => {
                            const isFirst = inx === 0;
                            const isLast = inx === loadedTPrompt.current.length - 1;
                            let showFilter = false;
                            if (isLast && !pendingTPromp.current.query) {
                                showFilter = true;
                            }
                            return (
                                <div className={s.historyItemWrap} key={inx}>
                                    {
                                        !isFirst &&
                                        <div className={s.queryTextWrap}>
                                            <div className={s.queryText}>
                                                {item.query}
                                            </div>
                                        </div>
                                    }
                                    <div className={s.searchingText}>
                                        <MarkdownRenderer content={item.answer || ''} />
                                    </div>
                                    <div className={s.tableWrap}>
                                        <TableWithFilter
                                            messageIdProviderMap={messageIdProviderMap}
                                            onSearch={(val) => {
                                                doQuery(val, currentConversationId);
                                            }}
                                            showFilter={showFilter}
                                            conversationId={currentConversationId}
                                            messageId={item.messageId}
                                        />
                                    </div>
                                </div>
                            );
                        })
                    }
                    {
                        latestSearchValue && loadedTPrompt.current.length > 0 &&
                        <div className={s.queryTextWrap}>
                            <div className={s.queryText}>
                                {latestSearchValue}
                            </div>
                        </div>
                    }
                    <div className={s.searchingText}>
                        <MarkdownRenderer content={latestAnswerStr} />
                    </div>
                    <div className={s.tableWrap}>
                        <TableWithFilter
                            messageIdProviderMap={messageIdProviderMap}
                            onSearch={(val) => {
                                doQuery(val, currentConversationId);
                            }}
                            showFilter
                            showFeedback
                            conversationId={currentConversationId}
                            messageId={latestMessageId}
                        />
                    </div>
                    {
                        currentSearchValue && isStreaming &&
                        <div className={s.queryTextWrap}>
                            <div className={s.queryText}>
                                {currentSearchValue}
                            </div>
                        </div>
                    }
                    {
                        isWaitFirstStreamingReturn &&
                        <div className={s.loading}>
                            <Spin spinning />
                        </div>
                    }
                    {
                        isLoadingInitMessage &&
                        <div className={s.loading}>
                            <Spin spinning />
                        </div>
                    }
                </div>
            </div>
            <div className={s.footer}>
                <div className={s.suggestionWrap}>
                    {
                        suggestion?.map((sug) => {
                            return (
                                <div
                                    onClick={() => {
                                        setPendingSearchValue(sug);
                                    }}
                                    key={sug}
                                    className={s.suggestion}
                                >
                                    ↪ {sug}
                                </div>
                            );
                        })
                    }
                </div>
                <div className={s.inputWrap}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 9.29583 13.5892 10.4957 12.8907 11.4765L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L11.4765 12.8907C10.4957 13.5892 9.29583 14 8 14C4.68629 14 2 11.3137 2 8Z" fill="#9CA3AF" />
                    </svg>
                    <Input.TextArea
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey && !e.ctrlKey && !e.altKey && !e.metaKey) {
                                e.preventDefault(); // 阻止默认行为（可选）
                                handleSearch();
                            }
                        }}
                        className={s.input}
                        onChange={(e) => {
                            setPendingSearchValue(e.target.value);
                        }}
                        value={pendingSearchValue}
                        autoSize={{ maxRows: 4, minRows: 1 }}
                    />
                    <Button
                        loading={isStreaming || isWaitFirstStreamingReturn}
                        type="primary"
                        className={s.btn}
                        onClick={handleSearch}
                        disabled={!pendingSearchValue}
                    >
                        {
                            !isStreaming && !isWaitFirstStreamingReturn &&
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M11.5377 4.61719L16.9223 10.0018M16.9223 10.0018L11.5377 15.3864M16.9223 10.0018L3.07617 10.0018" stroke="white" strokeWidth="1.53846" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        }
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Page;
