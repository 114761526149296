import React, { useEffect, useMemo } from 'react';
import s from './s.module.less';
import type { TabsProps } from 'antd';
import OnboardingTable from './components/Onboarding';
import UniProfileTable from './components/UniProfile';
import AllProviderTable from './components/AllProvider';
import { getUrlParam } from 'utils/common';
import AccessDeniedTabView from 'components/AccessDeniedTabView';
import AccessStore from 'store/Access/permission';
import { PermissionModuleKeys } from 'constants/access';

export enum EType {
    ONBOARDING = 'onboarding',
    UNI_PROFILE = 'uniProfile',
}

const Page = () => {
    const [getAccessStore] = AccessStore.useStore();
    const access = getAccessStore('data');

    const accountApplicationComponent = useMemo(() => {
        if (!access) {
            return null;
        }

        return access?.[PermissionModuleKeys.ACCOUNT_APPLICATION] ? <OnboardingTable /> : <AccessDeniedTabView />;
    }, [access]);

    const uniprofileManagmentComponent = useMemo(() => {
        if (!access) {
            return null;
        }

        return access?.[PermissionModuleKeys.UNIPROFILE_MANAGEMENT] ? <UniProfileTable /> : <AccessDeniedTabView />;
    }, [access]);

    const appProviderManagmentComponent = useMemo(() => {
        if (!access) {
            return null;
        }

        return access?.[PermissionModuleKeys.UNIPROFILE_MANAGEMENT] ? <AllProviderTable /> : <AccessDeniedTabView />;
    }, [access]);

    const items = useMemo<TabsProps['items']>(() => [
        {
            key: EType.ONBOARDING,
            label: <p className={s.tabTitle}>Account Applications</p>,
            children: accountApplicationComponent,
            forceRender: true,
        },
        {
            key: EType.UNI_PROFILE,
            label: <p className={s.tabTitle}>UniProfile Management</p>,
            children: uniprofileManagmentComponent,
            forceRender: true,
        },
    ], [accountApplicationComponent, uniprofileManagmentComponent]);
    const activeType = getUrlParam('type') as EType;
    const [type, setType] = React.useState(activeType || EType.ONBOARDING);

    useEffect(() => {
        if (activeType) {
            setType(activeType);
        }
    }, [activeType]);

    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <h1>All providers</h1>
            </div>
            <div className={s.tableWrap}>
                {
                    appProviderManagmentComponent
                }
                {/* {
                    uniprofileManagmentComponent
                } */}
                {/* <Tabs
                    activeKey={type}
                    items={items}
                    onChange={(key) => {
                        setType(key as EType);
                        insertUrlParam('type', key);
                    }}
                /> */}
            </div>

        </div>
    );
};

export default Page;
