import React from 'react';
import s from './s.module.less';
import { TContentInfo } from 'types/operation';
import EmptyIcon from './empty.svg';

type IProps = {
    isMobileMode: boolean;
    previewList: TContentInfo[];
    previewContentEnabled: boolean;
};

const ContentPreview = (props: IProps) => {
    const { isMobileMode, previewList, previewContentEnabled } = props;

    return (
        <div className={isMobileMode ? s.previewMobile : s.previewPc}>
            {
                !previewContentEnabled &&
                <div className={s.disabledWrap}>
                    <div className={s.disText}>
                        No local module preview
                    </div>
                    <div className={s.disImg}>
                        <img src={EmptyIcon} />
                    </div>
                </div>
            }
            {
                previewContentEnabled &&
                <div className={s.infoWrap}>
                    {
                        previewList.map((item, index) => {
                            const className = index % 2 === 0 ? s.moduleLeftImg : s.moduleRightImg;
                            return (
                                <div className={className}>
                                    <div className={s.left}>
                                        <img
                                            src={item.imgUrl}
                                            alt={item.title}
                                            width={641}
                                            height={483}
                                            className={s.imgDisplay}
                                        />
                                    </div>
                                    <div className={s.right}>
                                        <div className={s.title}>
                                            {item.title}
                                        </div>
                                        <div className={s.content}>
                                            {item.content}
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            }

        </div>
    );
};

export default ContentPreview;
