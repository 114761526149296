import TinyStore from 'lib/tinyStore';
import { TKlarityProgramItem } from 'types/common';
import { HomeInfo } from 'types/provider';

type UniprofileStepDataModal = {
    speciality?: string[];
    currentSpeciality?: string;
};

const Store = TinyStore.createStore<UniprofileStepDataModal>({
    nameSpace: 'box_practice_uniprofile_step_store',
    key: 'uniprofileStep',
    crossBundle: false,
    runTime: true,
    initValue: {
        speciality: [],
        currentSpeciality: undefined,
    },
});
export default Store;
