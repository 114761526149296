import { Button, Form, Input, Modal, Switch, message } from 'antd';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import { addAirtableProvider, getAirtableDataByIntakeqProviderId } from 'api/operation';
import { EProviderListMappingStatusValue, ISaveAirtableProviderInput } from 'types/operation';
import useSettings from 'hooks/useSettings';
import { EnumFields } from 'types/enumerationData';

interface IProps {
    open?: boolean;
    onSuccess: () => void;
    onCancel: () => void;
}

interface IInputProps {
    onChange?: (e: ChangeEvent) => void;
    onSuccess?: (data: any) => void;
    onClear?: () => void;
}

const IntakeqIdInput = ({
    onChange,
    onSuccess,
    onClear,
}: IInputProps) => {
    const [loading, setLoading] = useState(false);
    const [id, setId] = useState('');

    useEffect(() => {
        if (!id) {
            onClear?.();
        }
    }, [id, onClear]);

    const handleFetch = useCallback(async () => {
        if (id) {
            setLoading(true);
            try {
                const res = await getAirtableDataByIntakeqProviderId(id);

                if (res && !res.error) {
                    onSuccess?.(res.data?.data);
                } else {
                    message.error(res.error);
                }
            } catch (e) {
                console.error(e);
            }
            setLoading(false);
        }
    }, [id, onSuccess]);

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setId(e.target.value);
        onChange?.(e);
    }, [onChange]);

    return (
        <div className={s.inputWrap}>
            <Input className={s.input} onChange={handleChange} />
            <Button type="primary" loading={loading} onClick={handleFetch}>Fetch data</Button>
        </div>
    );
};

interface ISwitchProps {
    value?: string;
    onChange?: (val: string) => void;
}

const IntakeqSwitch = ({
    value,
    onChange,
}: ISwitchProps) => {
    const [checked, setChecked] = useState(false);
    const [setting] = useSettings();
    const providerListingStatus = useMemo(() => setting[EnumFields.PROVIDER_MAPPING_LIST_STATUS] || [], [setting]);

    useEffect(() => {
        setChecked(value === EProviderListMappingStatusValue.LISTED);
    }, [value]);

    const handleChange = useCallback((ck: boolean) => {
        const val = ck ? EProviderListMappingStatusValue.LISTED : EProviderListMappingStatusValue.PENDING_REVIEW;

        onChange?.(val);
    }, [onChange]);

    const text = useMemo(() => providerListingStatus?.find((e) => e.value === value)?.label, [providerListingStatus, value]);

    return (
        <div className={s.switchWrap}>
            <Switch className={s.switch} checked={checked} onChange={handleChange} />
            <div className={s.status}>{text}</div>
        </div>
    );
};

interface IForm {
    providerNameSlug: string;
    listingStatus: EProviderListMappingStatusValue;
    airtableProviderTitle?: string;
    airtableProviderId?: string;
    intakeqProviderId?: string;
}

const AddModal = ({
    open,
    onSuccess,
    onCancel,
}: IProps) => {
    const defaultDetailValue = useRef({
        providerNameSlug: '',
        listingStatus: EProviderListMappingStatusValue.PENDING_REVIEW,
        airtableProviderTitle: '',
        intakeqProviderId: '',
        airtableProviderId: '',
    });
    const [formInstance] = Form.useForm<IForm>();
    const [detai, setDetail] = useState(defaultDetailValue.current);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (detai) {
            formInstance.setFieldsValue(detai);
        }
    }, [detai, formInstance]);

    const handleOk = useCallback(async () => {
        setLoading(true);
        try {
            const formValues = await formInstance.validateFields();

            if (formValues.airtableProviderId) {
                const { providerNameSlug, listingStatus, airtableProviderTitle, intakeqProviderId, airtableProviderId } = formValues;
                const pramas = {
                    intakeqProviderId,
                    providerNameSlug,
                    listingStatus,
                    airtableProviderId,
                    airtableProviderTitle,
                } as ISaveAirtableProviderInput;

                const res = await addAirtableProvider(pramas);

                if (res && !res.error) {
                    onSuccess?.();
                } else {
                    message.error(res.error);
                }
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    }, [formInstance, onSuccess]);

    const handleFetchSuccess = useCallback((data: any) => {
        setDetail({
            ...data,
            listingStatus: formInstance.getFieldValue('listingStatus'),
        });
    }, [formInstance]);

    const handleClear = useCallback(() => {
        setDetail(defaultDetailValue.current);
    }, []);

    const Details = useMemo(() => {
        return (
            <>
                <Form.Item
                    label="Title"
                    name="airtableProviderTitle"
                    rules={[
                        { required: true, message: 'Title is a required field' },
                    ]}
                >
                    <Input disabled />
                </Form.Item>
                <Form.Item
                    label="Slug"
                    name="providerNameSlug"
                    rules={[
                        { required: true, message: 'Slug is a required field' },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Airtable ID"
                    name="airtableProviderId"
                    rules={[
                        { required: true, message: 'Airtable ID is a required field' },
                    ]}
                >
                    <Input disabled />
                </Form.Item>
            </>
        );
    }, []);

    return (
        <Modal
            title="Add provider from Airtable"
            open={open}
            destroyOnClose
            okText="Add"
            onOk={handleOk}
            onCancel={onCancel}
            confirmLoading={loading}
            okButtonProps={{
                disabled: !(detai && detai.airtableProviderId),
            }}
        >
            <div className={s.wrap}>
                <Form
                    form={formInstance}
                    name="airtableAddition"
                    layout="vertical"
                    className={commonS.formStyle1}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Status"
                        name="listingStatus"
                        rules={[
                            { required: true, message: 'Status is a required field' },
                        ]}
                    >
                        <IntakeqSwitch />
                    </Form.Item>
                    <Form.Item
                        label="IntakeQ Provider ID"
                        name="intakeqProviderId"
                        rules={[
                            { required: true, message: 'IntakeQ Provider ID is a required field' },
                        ]}
                    >
                        <IntakeqIdInput onClear={handleClear} onSuccess={handleFetchSuccess} />
                    </Form.Item>
                    {
                        detai && detai.airtableProviderId && Details
                    }
                </Form>
            </div>
        </Modal>
    );
};

export default AddModal;
