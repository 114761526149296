import TinyStore from 'lib/tinyStore';
import { TSubscriptionItemFromServer } from 'types/subscription';

type SubscriptionModal = {
    loading?: boolean,
    currentPlan?: TSubscriptionItemFromServer,
    //loaded?: boolean, //cos after loaded, current plan may still null
};

const Store = TinyStore.createStore<SubscriptionModal>({
    nameSpace: 'klarity',
    key: 'subscription',
    crossBundle: false,
    runTime: true,
    initValue: { currentPlan: undefined, loading: false },
});
export default Store;
