import React from 'react';
import { Button, Input, Modal, Space, TableProps } from 'antd';
import CommonTable from 'components/CommonTable';
import type { ColumnsType } from 'antd/es/table';
import s from './s.module.less';
import { SearchOutlined } from '@ant-design/icons';
import { EOriderType } from 'types/operation';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import dayjs from 'dayjs';
import { getPerformanceList } from 'api/operation/reviews';
import type { TPerformanceItem, IPerformanceInput } from 'types/operation';
import OpenGoogleForm from '../OpenGoogleForm';

const Index = () => {
    const navigation = useNavigate();
    const [itemList, setItemList] = React.useState<TPerformanceItem[]>([]);
    const [total, setTotal] = React.useState<number>(0);
    const [loading, setLoading] = React.useState(false);
    const [modal, contextHolder] = Modal.useModal();
    const [searchOrderType, setOrderType] = React.useState<EOriderType | undefined>(
        EOriderType.DESC,
    );
    const [searchOrderKey, setOrderKey] =
      React.useState<string>('latestReviewAt');
    const pageSize = 10;

    const [page, setPage] = React.useState(1);
    const [pendingProviderNameFilter, setPendingProviderNameFilter] =
    React.useState('');
    const [nameFilter, setNameFilter] = React.useState<string>('');

    const doFetch = async ({
        current,
        name,
        orderKey,
        orderType,
    }: {
        current: number;
        name?: string;
        orderKey?: string;
        orderType?: string;
    }) => {
        setLoading(true);
        const reqParam: IPerformanceInput = {
            current,
            size: pageSize,
            name,
            orderField: orderKey,
            orderType,
        };
        const result = await getPerformanceList(reqParam);
        if (!result.error) {
            setItemList(result?.data?.data?.list);
            setTotal(result?.data?.data?.totalRecords);
        }
        setLoading(false);
    };

    const onTableChange: TableProps<TPerformanceItem>['onChange'] = (
        pagination,
        filters: any,
        sorter: any,
        action?: any,
    ) => {
        //filter change
        const pageInx = pagination?.current || 1;
        setPage(pageInx);
        if (action?.action === 'sort') {
            const orderKey = sorter.order === 'ascend' ? EOriderType.ASC : EOriderType.DESC;
            setOrderType(orderKey);
            setOrderKey(sorter.field);
            doFetch({
                current: pageInx,
                name: nameFilter,
                orderType: orderKey,
                orderKey: sorter.field,
            });
            return;
        }

        doFetch({
            current: pageInx,
            name: nameFilter,
            orderType: searchOrderType,
            orderKey: searchOrderKey,
        });
    };

    React.useEffect(() => {
        doFetch({
            current: 1,
        });
    }, []);

    const columns: ColumnsType<TPerformanceItem> = [
        {
            title: 'NAME',
            dataIndex: 'name',
            key: 'name',
            width: '220px',
            filterDropdown: ({ close }: any) => (
                <div style={{ padding: 16 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        placeholder="Search name"
                        value={pendingProviderNameFilter}
                        onChange={(e) => {
                            setPendingProviderNameFilter(e.target.value);
                        }}
                        onPressEnter={() => {
                            setNameFilter(pendingProviderNameFilter);
                            setPage(1);
                            doFetch({
                                current: 1,
                                name: pendingProviderNameFilter,
                            });
                            close();
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                setNameFilter(pendingProviderNameFilter);
                                setPage(1);
                                doFetch({
                                    current: 1,
                                    name: pendingProviderNameFilter,
                                });
                                close();
                            }}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => {
                                setPendingProviderNameFilter('');
                                setNameFilter('');
                                setPage(1);
                                doFetch({
                                    current: 1,
                                    name: '',
                                });
                                close();
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
            ),
            render: (name: string, item: TPerformanceItem) => {
                return (
                    <div className={s.cell}>
                        <div className={s.name}>
                            {item?.firstName} {item?.lastName}
                        </div>
                        <div className={s.email}>{item.email}</div>
                    </div>
                );
            },
        },
        {
            title: 'AVERAGE RATING',
            dataIndex: 'avgRating',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            width: '150px',
            className: s.tableColumn,
            render: (val: number) => {
                return <div className={s.cell}>{!val ? 'None' : val}</div>;
            },
        },
        {
            title: 'TOTAL REVIEWS',
            dataIndex: 'totalReviewCount',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            width: '150px',
            className: s.tableColumn,
            render: (val: number) => {
                return <div className={s.cell}>{val || 0}</div>;
            },
        },
        {
            title: 'LAST REVIEW DATE',
            dataIndex: 'latestReviewAt',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            width: '150px',
            className: s.tableColumn,
            render: (time) => {
                return <div>{time ? dayjs(time).format('MM/DD/YYYY') : 'Nil'}</div>;
            },
        },
        {
            title: '',
            width: '120px',
            fixed: 'right',
            render: (p: TPerformanceItem) => {
                return (
                    <div className={s.actionWrap}>
                        <Button
                            className={s.link}
                            onClick={() => {
                                navigation(
                                    `${PATH.OPERATION}/${PATH.KLARITY_REVIEWS_DETAIL}/${p.providerSource === 'E' ? p.bpProviderId : p.intakeqProviderId}/${p.providerSource}`,
                                );
                            }}
                            type="text"
                        >
                            View
                        </Button>
                    </div>
                );
            },
        },
    ].filter(Boolean) as ColumnsType<TPerformanceItem>;

    return (
        <div className={s.wrap}>
            <div className={s.selectBar}>
                <OpenGoogleForm sheetType="PERFORMANCE" />
            </div>
            <CommonTable
                bordered
                rowKey="id"
                pagination={{
                    total,
                    current: page,
                    pageSize,
                    showSizeChanger: false,
                }}
                scroll={{ x: 'max-content' }}
                loading={loading}
                columns={columns}
                onChange={onTableChange}
                data={itemList || []}
            />
            {contextHolder}
        </div>
    );
};

export default Index;
