import React, {
    useState,
    useImperativeHandle,
    forwardRef,
    useEffect,
} from 'react';
import s from './s.module.less';
import { Button, Descriptions, Form, Input, message } from 'antd';
import type { TAssessmentItem } from 'types/operation';
import WarningTips from '../WarningTips';
import commonS from 'styles/common.module.less';
import {
    updateQuestionsFrom,
} from 'api/operation/assessment';

type IProps = {
    item: TAssessmentItem;
    onSuccess: (obj: Partial<TAssessmentItem>) => void;
    onPreview: (obj: { type: string; questionsFormLink: string }) => void;
};

export interface IQuestionFrom {
    validateAndSubmit: () => boolean;
}

const QuestionFrom = forwardRef<IQuestionFrom, IProps>((props, ref) => {
    const { item, onSuccess, onPreview } = props;
    const [isEdit, setIsEdit] = useState(false);
    const [isTipsShow, setIsTipsShow] = useState(false);
    const [form] = Form.useForm<TAssessmentItem & { active: boolean }>();
    const [isLoading, setIsLoading] = useState(false);

    const handleEdit = () => {
        setIsEdit(true);
    };

    const handleCancel = () => {
        setIsEdit(false);
    };

    const handleSave = async () => {
        const formValues = await form.validateFields();
        try {
            const params = {
                id: item.id || '',
                questionsFormLink: formValues.questionsFormLink || '',
            };
            const { data } = await updateQuestionsFrom(params);

            if (!data.error) {
                setIsTipsShow(true);
                setIsEdit(false);
                onSuccess(params);
                onPreview({
                    ...params,
                    type: 'questionFrom',
                });
            }
        } catch (err) {

        }
    };

    const onClose = () => {
        setIsTipsShow(false);
    };

    const handleRefresh = async () => {
        try {
            const params = {
                id: item.id || '',
                questionsFormLink: item.questionsFormLink || '',
            };
            setIsLoading(true)
            const { data } = await updateQuestionsFrom(params);
            setIsLoading(false);
            if (!data.error) {
                message.success('Refresh successfully');
                onSuccess(params);
            }
        } catch (err) {
            setIsLoading(false);
        }
    };

    const renderDescription = () => {
        return (
          <Descriptions
            colon={false}
            title='Question form link'
            layout='vertical'
            extra={
              item.questionsFormLink ? (
                <Button onClick={() => handleRefresh()} loading={isLoading}>
                  Refresh the link
                </Button>
              ) : (
                <Button onClick={() => handleEdit()}>Edit</Button>
              )
            }
            column={1}
            size='small'
            contentStyle={{
              marginBottom: '16px',
            }}
          >
            <Descriptions.Item label='URL'>
              {item.questionsFormLink || '-'}
            </Descriptions.Item>
          </Descriptions>
        );
    };

    const renderForm = () => {
        return (
            <div>
                <div className={s.headerBar}>
                    <h4 className={s.subtitle}>Question form link</h4>
                    <div className={s.buttonWrap}>
                        <Button onClick={() => handleCancel()}>Cancel</Button>
                        <Button
                            onClick={() => handleSave()}
                            type="primary"
                            className={s.ml8}
                        >
                            Save changes
                        </Button>
                    </div>
                </div>
                <Form
                    form={form}
                    className={commonS.formStyle1}
                    initialValues={{ active: true }}
                    autoComplete="off"
                    layout="vertical"
                    scrollToFirstError
                >
                    <Form.Item
                        label="URL"
                        name="questionsFormLink"
                        validateFirst
                        rules={[
                            {
                                required: true,
                                message: 'URL is required',
                            },
                        ]}
                    >
                        <Input
                            maxLength={200}
                            placeholder="Ex. https://docs.google.com/forms/d/14BrtZc69_Vzjh2GSYf5VdPUldVyy9k2IbC6sWrGWhIs/edit"
                        />
                    </Form.Item>
                </Form>
            </div>
        );
    };

    useImperativeHandle(ref, () => ({
        validateAndSubmit: () => {
            return !isEdit;
        },
    }));

    useEffect(() => {
        if (item) {
            form.setFieldsValue({
                questionsFormLink: item.questionsFormLink,
            });
            onPreview({
                type: 'questionFrom',
                questionsFormLink: item.questionsFormLink || '',
            });
        } else {
            form.resetFields();
        }
    }, [item]);

    return (
        <div className={s.wrap}>
            <div className={s.content}>
                {isEdit ? renderForm() : renderDescription()}
                <WarningTips
                    title="Action required: review engagement questions"
                    description="The questionnaire link has been updated. Please double-check the placement of your engagement questions, as they may have shifted due to changes in the Google Form."
                    closable
                    isShow={isTipsShow}
                    onClose={onClose}
                />
            </div>
        </div>
    );
});

export default QuestionFrom;
