// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--yITsk {
  padding-top: 24px;
}
.s-module__wrap--yITsk .s-module__title--XA6qC {
  color: var(--gray-700, #374151);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 36px;
}
.s-module__wrap--yITsk .s-module__itemWrap--e79JB {
  border: 1px solid #D1D5DB;
  border-radius: 10px;
  padding: 24px;
  margin-bottom: 24px;
}
.s-module__wrap--yITsk .s-module__itemWrap--e79JB .s-module__header--VVuCh {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 24px;
}
.s-module__wrap--yITsk .s-module__itemWrap--e79JB .s-module__header--VVuCh .s-module__btn--_gOgg {
  background-color: #fff;
}
.s-module__wrap--yITsk .s-module__itemWrap--e79JB .s-module__item--HasdN {
  list-style: disc;
  list-style-position: inside;
  text-decoration: underline;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/pages/operation/HelloKlarityPageManagement/components/InternalLinkManagement/s.module.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AAFA;EAII,+BAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AACJ;AAXA;EAcI,yBAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;AAAJ;AAjBA;EAoBM,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;AAAN;AA3BA;EA6BQ,sBAAA;AACR;AA9BA;EAkCM,gBAAA;EACA,2BAAA;EACA,0BAAA;EACA,eAAA;AADN","sourcesContent":[".wrap {\n  padding-top: 24px;\n\n  .title {\n    color: var(--gray-700, #374151);\n    font-family: Inter;\n    font-size: 20px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 20px;\n    margin-bottom: 36px;\n  }\n\n  .itemWrap {\n    border: 1px solid #D1D5DB;\n    border-radius: 10px;\n    padding: 24px;\n    margin-bottom: 24px;\n\n    .header {\n      display: flex;\n      flex-direction: row;\n      align-items: center;\n      justify-content: space-between;\n      font-size: 20px;\n      line-height: 20px;\n      font-weight: 500;\n      margin-bottom: 24px;\n      .btn {\n        background-color: #fff;\n      }\n    }\n\n    .item {\n      list-style: disc;\n      list-style-position:inside;\n      text-decoration: underline;\n      cursor: pointer;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--yITsk`,
	"title": `s-module__title--XA6qC`,
	"itemWrap": `s-module__itemWrap--e79JB`,
	"header": `s-module__header--VVuCh`,
	"btn": `s-module__btn--_gOgg`,
	"item": `s-module__item--HasdN`
};
export default ___CSS_LOADER_EXPORT___;
