import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
} from 'react';
import s from './s.module.less';
import { message, Spin } from 'antd';
import type { TAssessmentItem, TResult } from 'types/operation';
import {
    getAssessmentResultLevel,
    assessmentEmailAddOrUpdate,
    updateAssementEmailById,
} from 'api/operation/assessment';
import cx from 'classnames';
import ResultsReviewItem from '../ResultsReviewItem';
import PromptManagement from './components/PromptManagement';
import EmailTemplate from './components/EmailTemplate';
import Indication from './components/Indication';
import useGetEmailListByType from 'hooks/useGetEmailListByType';
import useGetAssessmentEmailById from 'hooks/useGetAssessmentEmailById';

type IProps = {
    isLoading: boolean;
    goLiveObj: TAssessmentItem;
    info: TAssessmentItem;
    onSuccess: () => void;
    setEmailSelectedPreviewInfo: (previewData) => void;
};

const Email: React.FC<IProps> = (props) => {
    const { isLoading, onSuccess, info, setEmailSelectedPreviewInfo } = props;
    const [emailList, emailListLoading] = useGetEmailListByType('KLARITY_SELF_ASSESSMENT');

    const [indicationList, indicationListLoading, ,fetchIndicationList] = useGetAssessmentEmailById(info.id as number);

    const { emailPrompt, emailTemplateId } = info;

    const list = useRef(
        [
            {
                riskLevel: 1,
                type: 'low',
                text: 'Low indication',
            },
            {
                riskLevel: 2,
                type: 'moderate',
                text: 'Moderate indication',
            },
            {
                riskLevel: 3,
                type: 'high',
                text: 'High indication',
            },
        ],
    );
    const [activeIndex, setActiveIndex] = useState<number>(-1);

    const handlePreviewItemClick = (index: number) => {
        setActiveIndex(index);
        setEmailSelectedPreviewInfo(indicationList?.[index]);
    };

    // 保存prompt和template用
    const handleSaveEmailPromptOTemplate = useCallback(async (formValue: any, successCallback?: () =>void) => {
        try {
            const params = {
                id: info.id,
                assessmentName: info.assessmentName,
                conditionKeyTags: info.conditionKeyTags,
                ...formValue,
            };

            const { data } = await updateAssementEmailById(
                params,
            );

            if (!data.error) {
                message.success('Updated successfully');
                onSuccess();
                fetchIndicationList(info.id as number);
                successCallback?.();
            }
        } catch (err) {}
    }, [info]);

    const handleSave = useCallback(async (formValue: any, successCallback?: () =>void) => {
        try {
            const params = {
                emailPrompt: info.emailPrompt,
                emailTemplateId: info.emailTemplateId,
                assessmentId: info.id,
                ...formValue,
            };
            const { data } = await assessmentEmailAddOrUpdate(
                params,
            );

            if (!data.error) {
                message.success('Updated successfully');
                onSuccess();
                fetchIndicationList(info.id as number);
                successCallback?.();
            }
        } catch (err) {}
    }, [info]);

    return (
        <Spin spinning={isLoading || emailListLoading || indicationListLoading}>
            <div className={s.wrap}>
                <div className={s.headerBar}>
                    <h4 className={s.subtitle}>Email template review</h4>
                </div>
                <div className={s.tabList}>
                    {list.current.map((listItem, index) => {
                        const elId = `indication_${listItem.riskLevel}`;
                        return (
                            <a
                                href={`#${elId}`}
                                className={cx(
                                    s.tab,
                                    index === activeIndex ? s.active : '',
                                )}
                                key={index}
                                onClick={() => handlePreviewItemClick(index)}
                            >
                                {listItem.text}
                            </a>
                        );
                    })}
                </div>
                <div className={s.contentWrap}>
                    <PromptManagement onSave={handleSaveEmailPromptOTemplate} emailPrompt={emailPrompt} />
                    <EmailTemplate emailList={emailList} onSave={handleSaveEmailPromptOTemplate} emailTemplateId={emailTemplateId} />
                    {
                        indicationList.map((item) => {
                            return (

                                <a id={`indication_${item.riskLevel}`}>
                                    <Indication data={item} onSave={handleSave} />
                                </a>
                            );
                        })
                    }
                </div>
            </div>
        </Spin>
    );
};

export default Email;
