import React from 'react';
import s from './s.module.less';

type IProps = {
    title: string;
    description: string;
    closable: boolean;
    isShow: boolean;
    onClose: () => void;
};

const WarningTips: React.FC<IProps> = (props) => {
    const { title, description, closable, isShow, onClose } = props;
    if (!isShow) {
        return null;
    }
    return (
        <div className={s.info}>
            <div className={s.left}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.25608 3.0976C9.02069 1.7383 10.9778 1.7383 11.7424 3.0976L17.3227 13.0181C18.0726 14.3514 17.1092 15.9987 15.5795 15.9987H4.41893C2.88927 15.9987 1.92585 14.3514 2.67578 13.0181L8.25608 3.0976ZM10.9991 12.9988C10.9991 13.5511 10.5514 13.9988 9.99915 13.9988C9.44686 13.9988 8.99915 13.5511 8.99915 12.9988C8.99915 12.4465 9.44686 11.9988 9.99915 11.9988C10.5514 11.9988 10.9991 12.4465 10.9991 12.9988ZM9.99915 4.99878C9.44686 4.99878 8.99915 5.44649 8.99915 5.99878V8.99878C8.99915 9.55106 9.44686 9.99878 9.99915 9.99878C10.5514 9.99878 10.9991 9.55106 10.9991 8.99878V5.99878C10.9991 5.44649 10.5514 4.99878 9.99915 4.99878Z"
                        fill="#FBBF24"
                    />
                </svg>
            </div>
            <div className={s.right}>
                <div className={s.title}>
                    {title}
                    {closable && (
                        <div className={s.closeIcon} onClick={() => onClose()} />
                    )}
                </div>
                <div className={s.content}>{description}</div>
            </div>
        </div>
    );
};

export default WarningTips;
