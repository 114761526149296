import React, {
    useState,
    useImperativeHandle,
    forwardRef,
    useEffect,
} from 'react';
import s from './s.module.less';
import { Button, Descriptions, Form, Input } from 'antd';
import type { TAssessmentItem } from 'types/operation';
import ConditionSelect from 'components/form/ConditionSelect';
import commonS from 'styles/common.module.less';
import useLoadBasicData from 'hooks/useLoadBasicData';

type IProps = {
    item: TAssessmentItem;
    onSuccess: (obj: Partial<TAssessmentItem>) => void;
};

export interface IntroCardA {
    validateAndSubmit: () => boolean;
}

const IntroCard = forwardRef<IntroCardA, IProps>((props, ref) => {
    const { item, onSuccess } = props;
    const [isEdit, setIsEdit] = useState(false);
    const [form] = Form.useForm<TAssessmentItem & { active: boolean }>();
    const [loadBaiscData] = useLoadBasicData();

    const handleEdit = () => {
        setIsEdit(true);
    };

    const handleCancel = () => {
        setIsEdit(false);
    };

    const handleSave = async () => {
        const formValues = await form.validateFields();
        onSuccess({
            assessmentName: formValues.assessmentName,
            conditionKeyTags: (formValues.tags || []).join(','),
        });
        setIsEdit(false);
    };

    const renderDescription = () => {
        return (
            <Descriptions
                colon={false}
                title="Assessment information"
                layout="vertical"
                extra={<Button onClick={() => handleEdit()}>Edit</Button>}
                column={1}
                size="small"
                contentStyle={{
                    marginBottom: '16px',
                }}
            >
                <Descriptions.Item label="Assessment name">
                    {item.assessmentName || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Tags">
                    {item.conditionKeyTags || '-'}
                </Descriptions.Item>
            </Descriptions>
        );
    };

    const renderForm = () => {
        return (
            <div>
                <div className={s.headerBar}>
                    <h4 className={s.subtitle}>Assessment information</h4>
                    <div className={s.buttonWrap}>
                        <Button onClick={() => handleCancel()}>Cancel</Button>
                        <Button onClick={() => handleSave()} type="primary" className={s.ml8}>
                            Save changes
                        </Button>
                    </div>
                </div>
                <p className={s.tips}>
                    <span>* </span>
                    Indicates required fields
                </p>
                <Form
                    form={form}
                    className={commonS.formStyle1}
                    initialValues={{ active: true }}
                    autoComplete="off"
                    layout="vertical"
                    scrollToFirstError
                >
                    <>
                        <Form.Item
                            label="Assessment name"
                            name="assessmentName"
                            validateFirst
                            rules={[
                                {
                                    required: true,
                                    message: 'Assessment name is required',
                                },
                            ]}
                        >
                            <Input
                                maxLength={70}
                                showCount
                                placeholder="Ex. ADHD self assessment"
                            />
                        </Form.Item>
                        <Form.Item
                            className={s.rowItem}
                            label="Tags"
                            name="tags"
                            rules={[
                                {
                                    required: true,
                                    message: 'Tags is required',
                                },
                            ]}
                        >
                            <ConditionSelect isLabel mode="multiple" />
                        </Form.Item>
                    </>
                </Form>
            </div>
        );
    };

    useImperativeHandle(ref, () => ({
        validateAndSubmit: () => {
            return !isEdit;
        },
    }));

    useEffect(() => {
        if (item) {
            form.setFieldsValue({
                assessmentName: item.assessmentName,
                tags: item.conditionKeyTags?.split(','),
            });
        } else {
            form.resetFields();
        }
    }, [item]);

    useEffect(() => {
        loadBaiscData();
    }, [loadBaiscData]);

    return (
        <div className={s.wrap}>
            <div className={s.content}>
                {isEdit ? renderForm() : renderDescription()}
            </div>
        </div>
    );
});

export default IntroCard;
