import React, { useCallback, useEffect } from "react";
import s from "./s.module.less";
import { PATH } from "constants/path";
import { useNavigate, useParams } from "react-router-dom";
import { message, Spin, Switch, Tabs } from "antd";
import type { TabsProps } from 'antd';
import ProgramInfo from "./components/ProgramInfo";
import IncludedPackages from "./components/IncludedPackages";
import useGetProgramsDetail from "hooks/ProgramsOps/useGetProgramsDetail";
import { activeProgramStatus } from "api/operation/programs";
import useGetAllTags from "hooks/ProgramsOps/useGetAllTags";

const KlarityProgramDetailPage = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const programId = id === '-1' ? null : id;
    const [detail, loading, refetch] = useGetProgramsDetail(Number(programId));
    const [,tagsLoading] = useGetAllTags();
    const [statusLoading, setStatusLoading] = React.useState<boolean>(false);

    const [actived, setActived] = React.useState<boolean>(false);

    useEffect(() => {
        if (detail) {
            setActived(detail?.programStatus === 'A');
        }
    }, [detail]);

    const refetchProgramDetail = useCallback(() => {
        refetch(Number(programId));
    }, [programId]);

    const items: TabsProps['items'] = [
        {
          key: '1',
          label: 'Program information',
          children: <ProgramInfo programData={detail} refetchProgramDetail={refetchProgramDetail}/>,
        },
        {
          key: '2',
          label: 'Included Packages',
          disabled: !(detail?.id),
          children: <IncludedPackages programData={detail} refetchProgramDetail={refetchProgramDetail}/>,
        },
      ];

    const handleChangeProgramStatus = useCallback(async (e: boolean) => {
        if (!programId || statusLoading) return;

        setStatusLoading(true);
        try {
            const res = await activeProgramStatus(Number(programId));

            if (res && !res.error) {
                setActived(e);
                refetchProgramDetail();
            } else {
                message.error(res.error);
            }
        } catch (e) {
            console.error(e);
        }
        setStatusLoading(false);
    }, [programId, statusLoading,refetchProgramDetail]);

    return (
        <div className={s.wrap}>
            <div
                onClick={() => {
                    navigate(`${PATH.OPERATION}/${PATH.SERVICES_TEMPLATE}?type=2&subType=3`);
                }}
                className={s.nav}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.70711 16.7071C9.31659 17.0976 8.68342 17.0976 8.2929 16.7071L2.29289 10.7071C1.90237 10.3166 1.90237 9.68342 2.29289 9.29289L8.2929 3.29289C8.68342 2.90237 9.31659 2.90237 9.70712 3.29289C10.0976 3.68342 10.0976 4.31658 9.70712 4.70711L5.41422 9H17C17.5523 9 18 9.44772 18 10C18 10.5523 17.5523 11 17 11L5.41422 11L9.70711 15.2929C10.0976 15.6834 10.0976 16.3166 9.70711 16.7071Z"
                        fill="currentColor"
                    />
                </svg>
                Back to Klarity select programs
            </div>
            <Spin spinning={loading || tagsLoading}>
                <div className={s.header}>
                    <div className={s.title}>{detail?.programName || 'New program'}</div>
                    <div className={s.actionBox}>
                        <div className={s.switchBox}>
                            <Switch checked={actived} loading={statusLoading} onChange={handleChangeProgramStatus} />{detail?.programStatus === 'A' ? 'Active' : 'Inactive'}
                        </div>
                    </div>
                </div>
                <div className={s.body}>
                    <Tabs items={items}/>
                </div>
            </Spin>
        </div>
    )
}

export default KlarityProgramDetailPage;
