import { Button, Spin } from 'antd';
import React, { } from 'react';
import { ServerMeta } from 'types/common';
import s from './s.module.less';
import { EInternalLinkType, TInternalLink } from 'types/operation';
import EditSpecialtyModal from './components/EditSpecialtyModal';
import EditOnlineServiceModal from './components/EditOnlineServiceModal';
import EditFindInOtherStateModal from './components/EditFindInOtherStateModal';

import useKlaritySpecialtyConditionMap from 'hooks/useKlaritySpecialtyConditionMap';
import useGetSettingData from 'hooks/useGetSettingData';
import { EnumFields } from 'types/enumerationData';
import useKlaritySpecialtyServicePageMap from 'hooks/useKlaritySpecialtyServicePageMap';
import { getHelloKlairtyKlarityPageInternalLink } from 'api/operation';

type Props = {
    host: string;
    pageId: number;
    state?: ServerMeta;
    condition?: ServerMeta;
    specialty?: ServerMeta;
    onPreview: (specialtyData:TInternalLink[], onlineHealthcareServicesData:TInternalLink[], stateDoctorData:TInternalLink[]) => void;
};

const InternalLinkManagement = (props: Props) => {
    const { state, condition, onPreview, pageId, specialty, host } = props;

    const [allState] = useGetSettingData(EnumFields.STATE);
    const [allCondition] = useGetSettingData(EnumFields.HELLO_KLARITY_CONDITION);
    const [allServicePage] = useGetSettingData(EnumFields.HELLO_KLARITY_SERVICE_PAGE);

    const [specialtyData, setSpecialtyData] = React.useState<TInternalLink[]>([]);
    const [onlineHealthcareServicesData, setOnlineHealthcareServicesData] = React.useState<TInternalLink[]>([]);
    const [stateDoctorData, setStateDoctorData] = React.useState<TInternalLink[]>([]);

    const [showSpecialtyModal, setShowSpecialtyModal] = React.useState(false);
    const [showOnlineServiceModal, setShowOnlineServiceModal] = React.useState(false);
    const [showFindOtherStateModal, setShowFindOtherStateModal] = React.useState(false);

    const [currentEditType, setCurrentEditType] = React.useState<EInternalLinkType>();

    const [loading, setLoading] = React.useState(false);

    const [specialtyConditionMap, condtionKeySpecialtyMap] = useKlaritySpecialtyConditionMap();
    const [specialtyServicePageMap, servicePageKeySpecialtyMap] = useKlaritySpecialtyServicePageMap();

    const [allSpecialtyData] = useGetSettingData(EnumFields.HELLO_KLARITY_SPECIALTY);

    const targetSpecialtyKey = condtionKeySpecialtyMap?.[condition?.dictKey!];
    const targetSpecialtyData = (allSpecialtyData as ServerMeta[])?.find((s1:ServerMeta) => s1.dictKey === targetSpecialtyKey);

    const availableConditions = specialtyConditionMap?.[targetSpecialtyData?.dictKey!]?.filter((c:ServerMeta) => { return c.dictKey !== condition?.dictKey; });
    const [availableServicePages] = useGetSettingData(EnumFields.HELLO_KLARITY_SERVICE_PAGE);
    const availableStates = (allState as ServerMeta[])?.filter((c:ServerMeta) => { return c.dictKey !== state?.dictKey; });

    const fetchData = async (id:string) => {
        setLoading(true);
        const result = await getHelloKlairtyKlarityPageInternalLink(parseInt(`${id}`, 10));
        if (!result.error) {
            const _data:TInternalLink[] = result.data?.data || [];

            const _specialtyData:TInternalLink[] = [];
            const _onlineHealthcareServicesData:TInternalLink[] = [];
            const _stateDoctorData:TInternalLink[] = [];
            _data.forEach((item:TInternalLink) => {
                const tag = item.linkTag;
                const { type } = item;
                if (type === EInternalLinkType.SPECIALTY_DOCTOR) {
                    //condition
                    item.targetItem = (allCondition as ServerMeta[])?.find((c:ServerMeta) => c.dictKey === tag || c.content === tag);
                    _specialtyData.push(item);
                }
                if (type === EInternalLinkType.HEALTHY_CARE_SERVICE) {
                    //condition
                    item.targetItem = (allServicePage as ServerMeta[])?.find((c:ServerMeta) => c.dictKey === tag || c.content === tag);
                    _onlineHealthcareServicesData.push(item);
                }
                if (type === EInternalLinkType.FIND_DOCTOR) {
                    //condition
                    item.targetItem = (allState as ServerMeta[])?.find((c:ServerMeta) => c.dictKey === tag || c.content === tag);
                    _stateDoctorData.push(item);
                }
            });

            setSpecialtyData(_specialtyData);
            setOnlineHealthcareServicesData(_onlineHealthcareServicesData);
            setStateDoctorData(_stateDoctorData);

            onPreview?.(_specialtyData, _onlineHealthcareServicesData, _stateDoctorData);
        }
        setLoading(false);
    };

    React.useEffect(() => {
        if (pageId && allCondition && allCondition?.length > 0) {
            fetchData(`${pageId}`);
        }
    }, [pageId, allCondition?.length]);

    return (
        <div className={s.wrap}>
            <div className={s.title}>
                Internal links
            </div>
            <Spin spinning={loading}>
                <div className={s.itemWrap}>
                    <div className={s.header}>
                        <span className={s.subTitle}>{specialty?.content} doctors</span>
                        <Button
                            className={s.btn}
                            onClick={() => {
                                setShowSpecialtyModal(true);
                            }}
                        >
                            Edit
                        </Button>
                    </div>
                    <ul>
                        {
                            specialtyData?.map((item:TInternalLink) => {
                                return (
                                    <li
                                        onClick={() => {
                                            const url = `${host}/condition/${item?.targetItem?.dictKey?.toLowerCase()}?state=${state?.content?.toLowerCase()?.replace(/ /g, '-')}`;
                                            window.open(url, '_blank');
                                        }}
                                        className={s.item}
                                        key={item.id}
                                    >
                                        Doctors for {item?.targetItem?.content} treatment
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>

                <div className={s.itemWrap}>
                    <div className={s.header}>
                        <span className={s.subTitle}>Online healthcare services</span>
                        <Button
                            className={s.btn}
                            onClick={() => {
                                setShowOnlineServiceModal(true);
                            }}
                        >
                            Edit
                        </Button>
                    </div>
                    <ul>
                        {
                            onlineHealthcareServicesData?.map((item:TInternalLink) => {
                                return (
                                    <li
                                        onClick={() => {
                                            const url = `${host}${item?.targetItem?.dictKey}`;
                                            window.open(url, '_blank');
                                        }}
                                        className={s.item}
                                        key={item.id}
                                    >
                                        Online {item?.targetItem?.content}
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>

                <div className={s.itemWrap}>
                    <div className={s.header}>
                        <span className={s.subTitle}>Find {condition?.content} doctors in other states</span>
                        <Button
                            className={s.btn}
                            onClick={() => {
                                setShowFindOtherStateModal(true);
                            }}
                        >
                            Edit
                        </Button>
                    </div>
                    <ul>
                        {
                            stateDoctorData?.map((item:TInternalLink) => {
                                return (
                                    <li
                                        onClick={() => {
                                            const url = `${host}/condition/${condition?.dictKey?.toLowerCase()}?state=${item?.targetItem?.content?.toLowerCase()?.replace(/ /g, '-')}`;
                                            window.open(url, '_blank');
                                        }}
                                        className={s.item}
                                        key={item.id}
                                    >
                                        {condition?.content} doctors in {item?.targetItem?.content}
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
            </Spin>
            <EditSpecialtyModal
                pageId={pageId}
                onCancel={() => {
                    setShowSpecialtyModal(false);
                }}
                onSuccess={() => {
                    setShowSpecialtyModal(false);
                    fetchData(`${pageId}`);
                }}
                open={showSpecialtyModal}
                item={specialtyData}
                conditionOptions={availableConditions as ServerMeta[]}
                targetCondition={condition}
                title={`Edit ${targetSpecialtyData?.content} doctors`}
                targetSpecialty={targetSpecialtyData}
            />

            <EditOnlineServiceModal
                pageId={pageId}
                onCancel={() => {
                    setShowOnlineServiceModal(false);
                }}
                onSuccess={() => {
                    setShowOnlineServiceModal(false);
                    fetchData(`${pageId}`);
                }}
                open={showOnlineServiceModal}
                item={onlineHealthcareServicesData}
                servicePageOptions={availableServicePages as ServerMeta[]}
                title="Edit Online healthcare services"
                targetSpecialty={targetSpecialtyData}
            />

            <EditFindInOtherStateModal
                pageId={pageId}
                onCancel={() => {
                    setShowFindOtherStateModal(false);
                }}
                onSuccess={() => {
                    setShowFindOtherStateModal(false);
                    fetchData(`${pageId}`);
                }}
                open={showFindOtherStateModal}
                item={stateDoctorData}
                stateOptions={availableStates as ServerMeta[]}
                title={`Edit find ${condition?.content} doctors in other states`}
                targetCondition={condition}
                targetState={state}
            />
        </div>
    );
};

export default InternalLinkManagement;
