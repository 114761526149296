import React, { useState, useMemo } from 'react';
import { Modal, Form, Input, Button, Tooltip, Switch } from 'antd';
import commonS from 'styles/common.module.less';
import s from './s.module.less';
import { klarityCarouselsCreate, klarityCarouselsUpdate } from 'api/operation';
import {
    type TCarousel,
    type TStat,
    EPageStatus,
    ECarouselsStatus,
    ECarouselsStatusLabel,
    type TObjectAction,
} from 'types/helloKlarity';
import FileUpload from 'components/form/FileUpload';
import { useParams } from 'react-router-dom';
import { MAX_CAROUSEL_LENGTH } from 'constants/carousels';
import { URL_LIMIT } from 'constants/common';

type Props = {
    item?: TCarousel;
    itemStatus: EPageStatus;
    itemIndex: number;
    statList: TStat;
    tabActiveKey: string;
    onCancel?: () => void;
    onSuccess?: ({ type, params }: TObjectAction) => void;
};

const titleMap = {
    [EPageStatus.ADD]: 'Add a new carousel',
    [EPageStatus.EDIT]: 'Edit carousels',
    [EPageStatus.DUPLICATE]: 'Duplicate',
};

const { TextArea } = Input;

const CreateAndEditCarousels = ({ item, itemStatus, itemIndex, statList, tabActiveKey, onCancel, onSuccess }: Props) => {
    const [submiting, setSubmiting] = useState(false);
    const [form] = Form.useForm<TCarousel & { active: boolean }>();
    const isEdit = EPageStatus.EDIT === itemStatus;
    const [check, setCheck] = React.useState(false);
    const { id: pageId, type = 'HOME' } = useParams();

    const disabledEdit = useMemo(() => {
        return (
            ['CONDITION', 'STATE'].includes(type) &&
        isEdit &&
        item?.status === ECarouselsStatus.ACTIVE &&
        itemIndex === 0
        );
    }, [type, isEdit, itemIndex, item]);

    const disabledLastestActiveCarousel = useMemo(() => {
        return (
            isEdit &&
        item?.status === ECarouselsStatus.ACTIVE &&
        itemIndex === 0 &&
        statList.A === 1
        );
    }, [isEdit, itemIndex, item, statList]);

    const isExceedMax = useMemo(() => {
        return (
            !check &&
          statList.A >= MAX_CAROUSEL_LENGTH &&
          item?.status !== ECarouselsStatus.ACTIVE
        );
    }, [statList.A, check, item]);

    const onSave = async () => {
        setSubmiting(true);
        try {
            const formValues = await form.validateFields();
            const id = item?.id;
            let priority;
            if (item?.priority !== undefined) {
                priority = item?.priority;
            } else {
                priority = check ? (statList.A + 1) : (statList.D + 1);
            }
            const params: TCarousel = {
                status: check
                    ? ECarouselsStatus.ACTIVE
                    : ECarouselsStatus.INACTIVATE,
                title: formValues.title,
                description: formValues.description,
                actionName: formValues.actionName,
                actionLink:
                (formValues.actionLink || '').indexOf('https://') === -1 &&
                formValues.actionLink
                    ? `https://${formValues.actionLink}`
                    : formValues.actionLink,
                image: formValues.image,
                pageId,
                priority,
            };
            if (isEdit) {
                params.id = id;
            } else {
                params.id = null;
                params.priority = check ? statList.A : statList.D;
            }
            const apiFunc = isEdit
                ? klarityCarouselsUpdate(params, id!)
                : klarityCarouselsCreate(params);
            const { data } = await apiFunc;
            if (data.code === 200) {
                if (isEdit) {
                    onSuccess?.({
                        type: 'CAROUSEL_EDIT',
                        params,
                    });
                } else {
                    onSuccess?.({
                        type: 'CAROUSEL_ADD',
                        params,
                    });
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setSubmiting(false);
        }
    };

    React.useEffect(() => {
        if (item) {
            form.setFieldsValue({
                active: item.status === ECarouselsStatus.ACTIVE,
                title: item.title,
                description: item.description,
                actionName: item.actionName,
                actionLink: (item.actionLink || '').replace('https://', ''),
                image: item.image,
            });
            setCheck(item.status === ECarouselsStatus.ACTIVE);
        } else {
            form.resetFields();
            if (
                itemStatus === EPageStatus.ADD &&
              tabActiveKey === 'ACTIVE' &&
              !isExceedMax
            ) {
                setCheck(true);
            }
        }
    }, [item]);

    return (
        <Modal
            title={titleMap[itemStatus]}
            closable
            className={commonS.modalFixHeightWrap}
            width="500px"
            destroyOnClose
            onCancel={onCancel}
            okText="Save"
            open
            footer={
                <div>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button loading={submiting} type="primary" onClick={onSave}>
                        Save
                    </Button>
                </div>
            }
        >
            <div className={s.wrap}>
                <Form
                    form={form}
                    className={commonS.formStyle1}
                    initialValues={{ active: true }}
                    autoComplete="off"
                    layout="vertical"
                    scrollToFirstError
                    colon={false}
                >
                    <>
                        <Tooltip
                            title={
                                // eslint-disable-next-line no-nested-ternary
                                disabledEdit || disabledLastestActiveCarousel
                                    ? 'You cannot change the status of carousel 1'
                                    : isExceedMax
                                        ? `A maximum of ${MAX_CAROUSEL_LENGTH} carousels can be added per page. Remove or inactivate existing carousels to add new ones.`
                                        : ''
                            }
                        >
                            <Form.Item
                                className={s.rowItem}
                                label="Status"
                                name="active"
                                rules={[{ required: true }]}
                            >
                                <Switch
                                    onChange={(e) => {
                                        setCheck(e);
                                    }}
                                    checked={check}
                                    disabled={
                                        disabledEdit ||
                      isExceedMax ||
                      disabledLastestActiveCarousel
                                    }
                                />
                            </Form.Item>
                            <span className={s.switchLabel}>
                                {check ? ECarouselsStatusLabel.A : ECarouselsStatusLabel.D}
                            </span>
                        </Tooltip>

                        <Tooltip
                            title={
                                disabledEdit
                                    ? 'You cannot change the title of carousel 1'
                                    : ''
                            }
                        >
                            <Form.Item
                                className={s.rowItem}
                                label={
                                    <div className={s.formLabel}>
                                        Title
                                        <Tooltip
                                            overlayClassName={s.tipCard}
                                            overlayInnerStyle={{ padding: '12px' }}
                                            title={
                                                <div className={s.tipText}>
                                                    If you want to highlight the text, please wrap the
                                                    text with span tags. For examples, &quot;Build your
                                                    reputation and &lt;span&gt;earn good
                                                    reviews&lt;/span&gt; &quot;.
                                                </div>
                                            }
                                        >
                                            <div className={s.questionMarkIcon} />
                                        </Tooltip>
                                    </div>
                                }
                                name="title"
                                rules={[{ required: true, message: 'Title is required' }]}
                            >
                                <TextArea
                                    style={{ width: '100%' }}
                                    rows={1}
                                    maxLength={70}
                                    placeholder="Ex. All-in-one management platform for private practices"
                                    showCount
                                    disabled={disabledEdit}
                                />
                            </Form.Item>
                        </Tooltip>

                        <Form.Item
                            className={s.rowItem}
                            label="Subtext"
                            name="description"
                            rules={[{ required: true, message: 'Subtext is required' }]}
                        >
                            <TextArea
                                style={{ width: '100%' }}
                                rows={3}
                                maxLength={300}
                                showCount
                                placeholder="Ex. At Kiwi Health, we strongly believe in independent providers and practices. With cutting-edge technology and top-notch practice support, we unlock your full potential."
                            />
                        </Form.Item>
                        <Form.Item
                            className={s.rowItem}
                            label="Call-to-action name"
                            name="actionName"
                        >
                            <TextArea
                                style={{ width: '100%' }}
                                rows={1}
                                maxLength={20}
                                showCount
                                placeholder="Ex. Find my provider"
                            />
                        </Form.Item>
                        <Form.Item
                            className={s.rowItem}
                            label="Call-to-action link"
                            name="actionLink"
                            dependencies={['actionName']}
                            rules={[
                                {
                                    required: false,
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!!getFieldValue('actionName') && !value) {
                                            return Promise.reject(
                                                new Error('This is a required field'),
                                            );
                                        }
                                        if (
                                            !!getFieldValue('actionName') &&
                        !/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/.test(
                            value,
                        )
                                        ) {
                                            return Promise.reject(
                                                new Error('Please input valid URL'),
                                            );
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <Input
                                style={{ width: '100%' }}
                                maxLength={URL_LIMIT}
                                addonBefore="https://"
                            />
                        </Form.Item>
                        <Form.Item
                            className={s.rowItem}
                            label={
                                <div className={s.formLabel}>
                                    Image
                                    <Tooltip
                                        overlayClassName={s.tipCard}
                                        overlayInnerStyle={{ padding: '12px' }}
                                        title={
                                            <div className={s.tipText}>
                                                Image should have a transparent background and be
                                                sized 640x514 pixels.
                                            </div>
                                        }
                                    >
                                        <div className={s.questionMarkIcon} />
                                    </Tooltip>
                                </div>
                            }
                            name={['image']}
                            rules={[{ required: true, message: 'Image is required' }]}
                        >
                            <FileUpload
                                accept="image/png, image/jpeg, image/gif"
                                tips="PNG, JPG, GIF up to 10MB"
                            />
                        </Form.Item>
                    </>
                </Form>
            </div>
        </Modal>
    );
};

export default CreateAndEditCarousels;
