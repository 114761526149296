import React, { useCallback, useEffect, useMemo, useState } from 'react';
import s from './s.module.less';
import CommonTable from 'components/CommonTable';
import type { ColumnsType } from 'antd/es/table';
import { Button, Modal, Spin, message } from 'antd';
import Edit from './components/Edit';
import commonS from 'styles/common.module.less';
import useGetSpecialtyList from 'hooks/klarityServiceTemplateOps/useGetSpecialtyList';
import { KlarityService, KlarityServiceTypeValue } from 'types/common';
import { IKlaritySpecialtyForm } from 'types/serviceTemplate';
import { getKiwiSpecialtiesByKlaritySpecialty } from 'api/operation';
import type { DefaultOptionType } from 'rc-select/lib/Select';
import { EnumFields } from 'types/enumerationData';
import useSettings from 'hooks/useSettings';

interface IProps {
    reloadSpecialtiesTable?: object;
}

const Specialties = ({
    reloadSpecialtiesTable,
}: IProps) => {
    const [setting] = useSettings();
    const specialtiesOptions = setting[EnumFields.SPECIALTY_TYPE];
    const [listData, tableLoading, error, refetch] = useGetSpecialtyList();
    const [showEditModal, setShowEditModal] = useState(false);
    const [editRow, setEditRow] = useState<IKlaritySpecialtyForm>();
    const [saveLoading, setSaveLoading] = useState(false);
    const [loadingConditions, setLoadingConditions] = useState(false);
    const [conditions, setConditions] = useState<DefaultOptionType[]>([]);

    useEffect(() => {
        if (error) {
            message.error(error);
        }
    }, [error]);

    useEffect(() => {
        if (reloadSpecialtiesTable) {
            refetch();
        }
    }, [refetch, reloadSpecialtiesTable]);

    const saveSuccessfully = useCallback(() => {
        setShowEditModal(false);
        refetch();
    }, [refetch]);

    const findConditionsByKiwiSpecialties = useCallback((specialties: string[]) => {
        if (!specialtiesOptions) {
            return [];
        }
        const result = specialties.reduce((resp: string[], specialty: string) => {
            const specialtyIncluded = specialtiesOptions.filter((e) => e.value === specialty);

            const conditionString = specialtyIncluded.map((e) => e.label).join(',');

            return resp.concat(conditionString);
        }, []);

        const duplicationConditionArr = result.join(',').split(',');

        return Array.from(new Set(duplicationConditionArr));
    }, [specialtiesOptions]);

    const getConditions = useCallback(async (specialty: string) => {
        setLoadingConditions(true);

        try {
            const res = await getKiwiSpecialtiesByKlaritySpecialty(specialty);

            if (res && !res.error) {
                const respData = res.data.data || [];
                const conditionData = findConditionsByKiwiSpecialties(respData.map((e) => e.specialty));
                setConditions(conditionData.map((e: string) => ({
                    label: e,
                    value: e,
                })));
            } else {
                message.error(res?.error);
            }
        } catch (e) {
            console.error(e);
        }
        setLoadingConditions(false);
    }, [findConditionsByKiwiSpecialties]);

    const columns: ColumnsType<any> = useMemo(() => (
        [
            {
                title: 'SPECIALTY NAME',
                dataIndex: 'specialtyName',
                className: s.tableColumn,
                width: '250px',
                fixed: 'left',
                render: (text:string) => {
                    return (
                        <div className={s.name} title={text}>
                            {text}
                        </div>
                    );
                },
            },
            {
                title: 'DESCRIPTION',
                dataIndex: 'description',
                width: '260px',
                className: s.tableColumn,
                render: (text:string) => {
                    return (
                        <div className={s.nameWrap} title={text}>
                            {text}
                        </div>
                    );
                },
            },
            {
                title: 'COMMON CONDITIONS',
                dataIndex: 'commonCondition',
                width: '260px',
                className: s.tableColumn,
                render: (data:string[]) => {
                    return data ? (
                        <div className={s.nameWrap} title={data.join(',')}>
                            {data.join(',')}
                        </div>
                    ) : null;
                },
            },
            {
                title: 'SERVICE TYPE',
                dataIndex: 'serversTypes',
                width: '260px',
                className: s.tableColumn,
                render: (data:KlarityServiceTypeValue[], row: IKlaritySpecialtyForm) => {
                    const { serversTypeNames } = row;

                    return data ? (
                        <div className={s.columnList}>
                            {data.map((e, i) => {
                                const templateData = serversTypeNames.find((templateItem) => templateItem.id === e.serviceTypeId);

                                return (
                                    <div key={i} className={s.columnListItem}>{templateData?.displayName}</div>
                                );
                            })}
                        </div>
                    ) : null;
                },
            },
            {
                title: '',
                width: '80px',
                className: s.tableColumn,
                fixed: 'right',
                render: (item: IKlaritySpecialtyForm) => {
                    return (
                        <div
                            className={s.edit}
                            onClick={() => {
                                const { specialtyName } = item;
                                setEditRow(item);
                                getConditions(specialtyName);
                                setShowEditModal(true);
                            }}
                        >Edit
                        </div>
                    );
                },
            },
        ]
    ), [getConditions]);
    return (
        <div className={s.wrap}>
            <Modal
                title={`Edit specialty ${editRow?.specialtyName}`}
                className={commonS.modalFixHeightWrap}
                width={900}
                open={showEditModal}
                onCancel={() => {
                    setShowEditModal(false);
                }}
                footer={
                    <div className={s.modalFooter}>
                        <Button onClick={() => {
                            setShowEditModal(false);
                        }}
                        >Cancel
                        </Button>
                        <Button type="primary" htmlType="submit" form="specialtyForm" loading={saveLoading}>Save</Button>
                    </div>
                }
            >
                <Spin spinning={loadingConditions}>
                    <Edit row={editRow as IKlaritySpecialtyForm} setSaveLoading={setSaveLoading} onSaveSuccessfully={saveSuccessfully} conditions={conditions} />
                </Spin>
            </Modal>
            <CommonTable
                rowKey={(record: KlarityService) => record.id}
                bordered
                loading={tableLoading}
                scroll={{ x: '1500' }}
                columns={columns}
                data={listData}
            />
        </div>
    );
};

export default Specialties;
