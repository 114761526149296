export enum ETimeTag {
    START = 'start',
    END = 'end',
}

export interface ITime {
    value: string;
    tag: ETimeTag
}

export interface ITimePair {
    start: string;
    end: string;
}
