import React, {
    forwardRef,
} from 'react';
import type { TAssessmentItem, TResult, TPreviewResult } from 'types/operation';

import ResultLink from '../ResultLink';
import ResultPrompt from '../ResultPrompt';
import ResultsReview from '../ResultsReview';

type IProps = {
    isLoading: boolean;
    goLiveObj: TAssessmentItem;
    onPreview: (previewItem: TPreviewResult) => void;
    onSuccess: () => void;
};

export interface IResultFrom {
    validateAndSubmit: () => boolean;
}

const ResultFrom = forwardRef<IResultFrom, IProps>((props, ref) => {
    const { isLoading, goLiveObj, onPreview, onSuccess } = props;

    return (
        <>
            <ResultLink
                isLoading={isLoading}
                item={goLiveObj}
                onSuccess={onSuccess}
            />
            <ResultPrompt
                isLoading={isLoading}
                item={goLiveObj}
                onSuccess={onSuccess}
            />
            <ResultsReview
                item={goLiveObj}
                onSuccess={onSuccess}
                onPreview={onPreview}
            />
        </>
    );
});

export default ResultFrom;
