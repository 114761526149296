export const getKlarityPriceAreaFromRecommendPrice = (recommendPrice?: number): [number, number, number, number] | undefined => {
    if (recommendPrice === null || recommendPrice === undefined || typeof recommendPrice !== 'number') {
        return undefined;
    }
    const number1 = recommendPrice * 0.75;
    const number2 = recommendPrice * 0.95;
    const number3 = recommendPrice * 1.05;
    const number4 = recommendPrice * 1.25;
    return [number1, number2, number3, number4];
};

export const getKlarityPriceLevel = (price?: number, priceArea?:[number, number, number, number]): [number | null, number | null] => {
    if (price === null || price === undefined || !priceArea) {
        return [null, null];
    }
    let percent = 50;
    let level = 2;
    if (price < priceArea[0]) {
        percent = 20 * (price / priceArea[0]);
        level = 1;
    }
    if (price >= priceArea[0] && price <= priceArea[1]) {
        percent = 20 + (((price - priceArea[0]) / (priceArea[1] - priceArea[0])) * 20);
        level = 2;
    }

    if (price >= priceArea[2] && price <= priceArea[3]) {
        percent = 60 + (((price - priceArea[2]) / (priceArea[3] - priceArea[2])) * 20);
        level = 4;
    }
    if (price > priceArea[3]) {
        percent = 80 + (((price - priceArea[3]) / priceArea[3]) * 20);
        if (percent >= 100) {
            percent = 99;
        }
        level = 5;
    }

    if (price >= priceArea[1] && price <= priceArea[2]) {
        percent = 40 + (((price - priceArea[1]) / (priceArea[2] - priceArea[1])) * 20);
        level = 3;
    }
    if (Number.isNaN(percent)) {
        percent = 50;
    }
    return [level, percent];
};
