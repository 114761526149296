import React from 'react';
import s from './s.module.less';
import { useParams, useNavigate } from 'react-router-dom';
import commonS from 'styles/common.module.less';
import { Spin, Button, Tag, Input, message, Modal, Form } from 'antd';
import { RightOutlined, ExclamationCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { providerFetch, opUpdateproviderBasicInfo, providerUpdateGmail } from 'api/operation';
import { Provider, PreviewFormInterface, EProviderInfoStatus, ProviderBasicInfo } from 'types/provider';
import { EProgressForProvider } from 'types/operation';
import { transferProviderToFormData } from 'data/provider';
import PreviewForm from 'components/onboarding/Preview';
import OnHoldImg from 'assets/operation/sendFeedback.png';
import ApproveImg from 'assets/operation/approve.png';
import { PATH } from 'constants/path';
import { GOOGLE_ADMIN_URL } from 'constants/common';
import { getEditableStatus } from 'utils/common';

export interface FormInterface {
    email: string;
}

const Page = () => {
    const { id } = useParams();
    const uid = id ? parseInt(id, 10) : 0;
    const navigate = useNavigate();
    const [modal, contextHolder] = Modal.useModal();
    const [loading, setLoading] = React.useState(false); //loading basic info
    const [previewForm] = Form.useForm<PreviewFormInterface>();
    const [loaded, setLoaded] = React.useState(false);
    const [showOnHold, setShowOnHold] = React.useState(false);
    const [showApprove, setShowApprove] = React.useState(false);
    const [form] = Form.useForm<FormInterface>();
    const [editingBpAccount, setEditingBpAccount] = React.useState(false);
    const [provider, setProvider] = React.useState<Provider | undefined>(undefined);
    const fetchInfo = async () => {
        setLoading(true);
        const result = await providerFetch(uid!);
        if (!result.error) {
            try {
            // eslint-disable-next-line prefer-destructuring
                const data:Provider = result.data.data;
                const formData = transferProviderToFormData(data);
                if (data.gmail) {
                    form.setFieldValue('email', data.gmail);
                }

                previewForm.setFieldsValue({
                    ...formData.basic,
                    qualifications: formData.qualifications,
                });
                setLoaded(true);
                setProvider(data);
            } catch (e) {
                message.error('Data error');
                console.error(e);
            } finally {
                setLoading(false);
            }
        }
    };
    React.useEffect(() => {
        if (uid) {
            fetchInfo();
        }
    }, [uid]);
    const providerStatus = provider?.status;
    const showFollowUp = !providerStatus || [EProviderInfoStatus.FINISHED_SIGN_UP, EProviderInfoStatus.IN_PROGRESS].includes(providerStatus);

    const AddAccount = () => {
        return (
            <div className={s.addAccount}>
                <div className={s.module}>
                    <div className={s.addTitle}>Add Kiwi Health account for provider </div>
                    <div className={s.addTip}>Please add providers’ Kiwi Health gmail account here. If the account is not created, click Provider Kiwi Health account to create an account in Google Admin Console. </div>
                    <div className={s.subModule}>
                        <div className={s.inputWrap}>
                            <Form
                                form={form}
                                name="basic"
                                initialValues={{ remember: true }}
                                autoComplete="off"
                                layout="vertical"
                            >
                                <Form.Item
                                    label="Kiwi Health email"
                                    name="email"
                                    rules={[{ type: 'email', required: true, message: 'Please provide a vaild email address' }]}
                                >
                                    <Input style={{ width: '300px' }} />
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                    <div className={s.action}>
                        <Button
                            loading={false}
                            onClick={async () => {
                                try {
                                    const value = await form.validateFields();
                                    const { email } = value;
                                    setLoading(true);
                                    const result = await providerUpdateGmail(uid, email!);
                                    if (!result.error && provider) {
                                        const basicData:Partial<ProviderBasicInfo> = {
                                            npi: provider.npi,
                                            state: provider.state,
                                            yearExp: provider.yearExp,
                                            zip: provider.zip,
                                            address: provider.address,
                                            specialityInHealth: provider.specialityInHealth,
                                            specialityInFamily: provider.specialityInFamily,
                                            status: EProviderInfoStatus.BP_ACCOUNT_ENTERED,
                                        };
                                        const resultUpdate = await opUpdateproviderBasicInfo(basicData, uid!);
                                        if (!resultUpdate.error) {
                                            fetchInfo();
                                            message.success('Kiwi Health account has bind with provider');
                                        }
                                        setEditingBpAccount(false);
                                        setLoading(false);
                                    }
                                } catch (e:any) {
                                    if (e.errorFields) {
                                        return;
                                    }
                                    const msg = e?.toString?.() || 'data error';
                                    message.error(msg);
                                    setLoading(false);
                                }
                            }}
                            style={{ width: '90px' }}
                            className={commonS.lightBtn}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    const BpAccount = () => {
        return (
            <div className={s.module} style={{ marginTop: 24 }}>
                <h3><span>Kiwi Health Account</span>
                    <Button
                        size="small"
                        onClick={() => {
                            setEditingBpAccount(true);
                        }}
                    >
                        Edit
                    </Button>
                </h3>
                <div className={s.row}>
                    <div
                        style={{
                            width: '300px',
                        }}
                        className={s.displayItem}
                    >
                        <div className={s.label}>Email</div>
                        <div className={s.value}>{ provider?.gmail}</div>
                    </div>
                </div>
            </div>
        );
    };

    const FollowUp = () => {
        return (
            <div className={s.followUp}>
                <div className={s.module}>
                    <div className={s.info}>
                        <h3>Sign-up Information</h3>
                        <div className={s.row}>
                            <div
                                style={{
                                    width: '45%',
                                }}
                                className={s.displayItem}
                            >
                                <div className={s.label}>Email</div>
                                <div className={s.value}>{ provider?.email}</div>
                            </div>

                            <div
                                style={{
                                    width: '45%',
                                }}
                                className={s.displayItem}
                            >
                                <div className={s.label}>Personal phone number</div>
                                <div className={s.value}>{ provider?.tel}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={s.module}>
                    <div className={s.followDetail}>
                        <div className={s.imgWrap}>
                            <img src={OnHoldImg} />
                        </div>
                        <div className={s.title}>Send feedback to provider {provider?.firstName}, {provider?.lastName}</div>
                        <div className={s.subTitle}>Please connect with the provider and check if anything you can help to finish the account application.</div>
                        <Button
                            type="primary"
                            onClick={() => {
                                window.open(`mailto:${provider?.email}`);
                            }}
                        >
                            Open Gmail
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={s.wrap}>
            <Spin spinning={loading}>
                <div className={s.header}>
                    <div className={s.left}>
                        <div
                            onClick={() => {
                                navigate(`${PATH.OPERATION}/${PATH.PROVIDER}`);
                            }}
                            className={s.leftTop}
                        >
                            <span className={s.leftTopHome}><ArrowLeftOutlined /></span>
                            <span
                                className={s.link}
                            >Provider Onboard
                            </span>
                        </div>
                        <h1 className={s.leftBtm}>
                            <span className={s.title}>{loaded ? `${provider?.firstName} ${provider?.lastName}` : ''}</span>
                            {loaded &&
                            <span className={s.tag}>
                                {(!providerStatus || [EProviderInfoStatus.FINISHED_SIGN_UP, EProviderInfoStatus.IN_PROGRESS].includes(providerStatus)) && <Tag className={s.tag} color="purple">{EProgressForProvider.FOLLOW_UP_WITH_PROVIDER}</Tag>}
                                {providerStatus === EProviderInfoStatus.SUBMITTED_APPLICATION && <Tag color="gold">{EProgressForProvider.PENDING_REVIEW}</Tag>}
                                {providerStatus === EProviderInfoStatus.APPLICATION_ON_HOLD && <Tag color="red">{EProgressForProvider.APPLICATION_ON_HOLD}</Tag>}
                                {providerStatus === EProviderInfoStatus.APPLICATION_APPROVED && <Tag color="blue">{EProgressForProvider.APPLICATION_APPROVED}</Tag>}
                                {providerStatus === EProviderInfoStatus.BP_ACCOUNT_ENTERED && <Tag color="green">{EProgressForProvider.BP_ACCOUNT_ENTERED}</Tag>}
                            </span>
                            }

                        </h1>
                    </div>
                    <div className={s.right}>
                        {
                            EProviderInfoStatus.BP_ACCOUNT_ENTERED === providerStatus &&
                            <Button
                                type="primary"
                                onClick={() => {
                                    navigate(`${PATH.OPERATION}/${PATH.PROVIDER}`);
                                }}
                            >
                                Back to Providers
                            </Button>
                        }
                        {
                            providerStatus && [EProviderInfoStatus.APPLICATION_APPROVED].includes(providerStatus) &&
                            <Button
                                type="primary"
                                onClick={() => {
                                    window.open(GOOGLE_ADMIN_URL, '_blank');
                                }}
                            >
                                Provide Kiwi Health account
                            </Button>
                        }
                        {
                            providerStatus && [EProviderInfoStatus.SUBMITTED_APPLICATION, EProviderInfoStatus.APPLICATION_ON_HOLD].includes(providerStatus) &&
                            <>
                                <Button
                                    onClick={() => {
                                        modal.confirm({
                                            width: '600px',
                                            closable: true,
                                            title: 'Do you confirm to put the application on-hold?',
                                            icon: <ExclamationCircleOutlined />,
                                            content: '',
                                            okText: 'Yes, confirm',
                                            cancelText: 'No, return to the previous step',
                                            onOk: async () => {
                                                if (provider) {
                                                    const basicData:Partial<ProviderBasicInfo> = {
                                                        npi: provider.npi,
                                                        state: provider.state,
                                                        yearExp: provider.yearExp,
                                                        zip: provider.zip,
                                                        address: provider.address,
                                                        specialityInHealth: provider.specialityInHealth,
                                                        specialityInFamily: provider.specialityInFamily,
                                                        status: EProviderInfoStatus.APPLICATION_ON_HOLD,
                                                    };
                                                    const result = await opUpdateproviderBasicInfo(basicData, uid!);
                                                    if (!result.error) {
                                                        fetchInfo();
                                                        setShowOnHold(true);
                                                    }
                                                }
                                            },
                                        });
                                    }}
                                >Application on-hold
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        modal.confirm({
                                            width: '600px',
                                            closable: true,
                                            title: 'Do you confirm to approve the application?',
                                            icon: <ExclamationCircleOutlined />,
                                            content: '',
                                            okText: 'Yes, confirm',
                                            cancelText: 'No, return to the previous step',
                                            onOk: async () => {
                                                if (provider) {
                                                    const basicData:Partial<ProviderBasicInfo> = {
                                                        npi: provider.npi,
                                                        state: provider.state,
                                                        yearExp: provider.yearExp,
                                                        zip: provider.zip,
                                                        address: provider.address,
                                                        specialityInHealth: provider.specialityInHealth,
                                                        specialityInFamily: provider.specialityInFamily,
                                                        status: EProviderInfoStatus.APPLICATION_APPROVED,
                                                    };
                                                    const result = await opUpdateproviderBasicInfo(basicData, uid!);
                                                    if (!result.error) {
                                                        fetchInfo();
                                                        setShowApprove(true);
                                                    }
                                                }
                                            },
                                        });
                                    }}
                                    style={{
                                        marginLeft: '16px',
                                    }}
                                >Approve
                                </Button>
                            </>
                        }
                    </div>
                </div>
                {
                    (providerStatus === EProviderInfoStatus.APPLICATION_APPROVED || editingBpAccount) && <AddAccount />
                }
                {
                    (providerStatus === EProviderInfoStatus.BP_ACCOUNT_ENTERED && !editingBpAccount && <BpAccount />)
                }
                {
                    showFollowUp && loaded ?
                        <FollowUp /> :
                        <div className={s.detail}>
                            {loaded && <PreviewForm providerId={uid} isOperation editable={getEditableStatus(provider?.editable).allowEditOnBoarding} className={s.previewWrap} form={previewForm} hideTitle />}
                        </div>
                }

                <Modal
                    title="Application on-hold with feedback"
                    closable
                    className={commonS.modalFixHeightWrap}
                    width="660px"
                    destroyOnClose
                    onCancel={() => {
                        setShowOnHold(false);
                    }}
                    footer={
                        <div className={s.footerWrap}>
                            <Button onClick={() => {
                                setShowOnHold(false);
                                navigate(`${PATH.OPERATION}/${PATH.PROVIDER}`);
                            }}
                            >
                                Back to Providers
                            </Button>
                            <Button
                                type="primary"
                                onClick={() => {
                                    setShowOnHold(false);
                                    window.open(`mailto:${provider?.email}`);
                                }}
                            >
                                Open Gmail
                            </Button>
                        </div>
                    }
                    open={showOnHold}
                >
                    <div className={s.onHoldWrap}>
                        <div className={s.imgWrap}>
                            <img src={OnHoldImg} />
                        </div>
                        <div className={s.title}>Send feedback to provider {provider?.firstName}, {provider?.lastName}</div>
                        <div className={s.subTitle}>Please email the provider to explain application on-hold reasons in detail.</div>
                    </div>
                </Modal>
                <Modal
                    title="Application approve"
                    closable
                    className={commonS.modalFixHeightWrap}
                    width="660px"
                    destroyOnClose
                    footer={
                        <div className={s.footerWrap}>
                            <Button onClick={() => {
                                setShowApprove(false);
                                navigate(`${PATH.OPERATION}/${PATH.PROVIDER}`);
                            }}
                            >
                                Back to Providers
                            </Button>
                            <Button
                                type="primary"
                                onClick={() => {
                                    setShowApprove(false);
                                    window.open(GOOGLE_ADMIN_URL, '_blank');
                                }}
                            >
                                Open Google Admin Console
                            </Button>
                        </div>
                    }
                    onCancel={() => {
                        setShowApprove(false);
                    }}
                    open={showApprove}
                >
                    <div className={s.onHoldWrap}>
                        <div className={s.imgWrap}>
                            <img src={ApproveImg} />
                        </div>
                        <div className={s.title}>
                            Provider {provider?.firstName}, {provider?.lastName}’s application has been approved
                        </div>
                        <div className={s.subTitle}>Please create a Kiwi Health Gmail account for the provider. After creating the BP email account, please come back to this page and input the email address in the Ops Portal.</div>
                    </div>
                </Modal>
                {contextHolder}
            </Spin>
        </div>
    );
};

export default Page;
