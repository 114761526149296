import { EContactType, IKlarityServiceTypeLimitation, IKlarityServiceTypePatientGroup, KlarityServiceTypeTemplate, KlarityServiceTypeValue } from './common';

export enum EMandatoryOptions {
    MANDATORY = 'Mandatory',
    OPTIONAL = 'Optional',
}

export enum ENewPatientAccept {
    YES = 'Yes',
    NO = 'No',
}

export interface IKlarityServiceType extends Omit<KlarityServiceTypeTemplate, 'newPatient'> {
    switchTeleHealthInPerson: boolean;
    switchPatientGroupLimitation: boolean;
    isMandatory: EMandatoryOptions;
    newPatient: ENewPatientAccept;
    contactType: EContactType[];
}

export interface IFormKlarityServiceTypeValue extends Omit<KlarityServiceTypeValue, 'patientGroupIds' | 'limitationIds'> {
    commonCondition: string[];
    patientGroupIds: string[];
    limitationIds: string[];
    contactType: EContactType[];
}

export interface IKlaritySpecialtyForm {
    id: number,
    iconUrl: string,
    iconUri: string,
    specialtyName: string,
    description: string,
    commonCondition: string[],
    createAt: string,
    createBy: number,
    updateAt: string,
    updateBy: null,
    recommended: boolean,
    patientGroupIds?: string[],
    limitationIds?: string[],
    serversTypes: IFormKlarityServiceTypeValue[],
    serversTypeNames: KlarityServiceTypeTemplate[],
}

export interface IUpdateKlarityServiceInput {
    id: number,
    iconUri?: string,
    serviceType: string,
    description: string,
    mandatory: boolean,
    newPatient: boolean,
    teleHealth: boolean,
    inPerson: boolean,
    defaultDuration: number,
    minDuration: number,
    maxDuration: number,
    switchPatientGroup: boolean,
    switchTeleHealthInPerson: boolean,
    switchPatientGroupLimitation: boolean,
    qtyPatientGroup: number,
    switchLimitation: boolean,
    qtyLimitation: number,
    patientGroupList?: IKlarityServiceTypePatientGroup[],
    limitationList?: IKlarityServiceTypeLimitation[]
}

export interface IUpdateKlaritySpecialtyInput {
    'id': number,
    'iconUri'?: string,
    // "specialtyName": "Psychiatric & Mental Health",
    'description': string,
    'commonCondition': string,
    'createAt': string | null,
    'createBy': number | null,
    'updateAt': string | null,
    'updateBy': number | null,
    'serversTypes': KlarityServiceTypeValue[]
}
