import React from 'react';
import { Modal, Form, message, Input, Button, Select } from 'antd';
import commonS from 'styles/common.module.less';
import { FormFieldEnum, FormInterface } from './types';
import s from './s.module.less';
import { REQUIRED_FIELD } from 'constants/common';
import { EInternalLinkType, TInternalLink } from 'types/operation';
import { ServerMeta } from 'types/common';
import { updateHelloKlairtyKlarityPageInternalLinkBatch } from 'api/operation';
import KlarityConditionWithFeatureSelect from 'components/form/KlarityConditionWithFeatureSelect';
import useGetSettingData from 'hooks/useGetSettingData';
import { EnumFields } from 'types/enumerationData';

type Props = {
    onCancel: () => void
    item?: TInternalLink[] //for edit fun
    onSuccess?: () => void
    open?: boolean;
    title?: string;
    conditionOptions?: ServerMeta[];
    targetSpecialty?: ServerMeta,
    targetCondition?: ServerMeta,
    pageId: number,
};

const EditSpecialtyModal = (props: Props) => {
    const { onCancel, item, onSuccess, pageId, targetSpecialty, targetCondition, conditionOptions, open, title } = props;
    const [allItems] = useGetSettingData(EnumFields.HELLO_KLARITY_CONDITION);
    const [form] = Form.useForm<FormInterface>();
    const [, forceUpdate] = React.useState({});
    const [submiting, setSubmiting] = React.useState(false);

    const [usedDictKeyStr, setUsedDictKeyStr] = React.useState<string[]>([]);

    const onOk = async () => {
        try {
            setSubmiting(true);
            const formValues = await form.validateFields();
            const addData: TInternalLink[] = [];
            formValues[FormFieldEnum.LIST].forEach((formValue, inx) => {
                const add: TInternalLink = {
                    type: EInternalLinkType.SPECIALTY_DOCTOR,
                    linkTag: formValue.linkTag,
                    linkName: '',
                    linkUrl: '',
                    pageId: pageId!,
                    seq: inx,
                };
                addData.push(add);
            });
            const result = await updateHelloKlairtyKlarityPageInternalLinkBatch(addData, EInternalLinkType.SPECIALTY_DOCTOR, pageId!);
            if (!result.error) {
                message.success('Update success');
                onSuccess?.();
            }
        } catch (e: any) {
            if (e.errorFields) {
                //form error
                return;
            }
            const msg = e?.toString?.() || 'data error';
            message.error(msg);
        } finally {
            setSubmiting(false);
        }
    };
    React.useEffect(() => {
        if (!open) {
            setUsedDictKeyStr([]);
            form.resetFields();
        }
        if (item && open) {
            const _usedContentStr: string[] = [];
            form.setFieldsValue({
                [FormFieldEnum.LIST]: item?.map((i) => {
                    _usedContentStr.push(i.linkTag);
                    return {
                        id: i.id,
                        linkTag: i.linkTag,
                    };
                }),
            });
            setUsedDictKeyStr(_usedContentStr);
            forceUpdate({});
        }
    }, [item, open]);

    const hasOption = conditionOptions && conditionOptions?.length > 0;

    return (
        <Modal
            title={title || 'Edit'}
            closable
            className={commonS.modalFixHeightWrap}
            width="550px"
            destroyOnClose
            onCancel={() => {
                onCancel?.();
            }}
            onOk={onOk}
            okText="Save change"
            open={open}
            okButtonProps={{
                loading: submiting,
            }}
        >
            {
                !hasOption &&
                <div>No other conditions pages under this specialty in this state</div>
            }
            {
                hasOption &&
                <Form
                    form={form}
                    name="basic"
                    className={commonS.formStyle1}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.List name="list">
                        {
                            (fields, { add, remove }) => {
                                const canAdd = usedDictKeyStr.length < conditionOptions?.length;
                                return (
                                    <>
                                        {
                                            fields.map((field, index) => {
                                                const condtionDictKey = form.getFieldValue(['list', field.name, 'linkTag']);
                                                const target = conditionOptions?.find((sm: ServerMeta) => {
                                                    return sm.dictKey === condtionDictKey || sm.content === condtionDictKey;
                                                });

                                                const text = target ? `Doctors for ${target?.content || ''} treatment` : '';

                                                const condtionList = conditionOptions?.filter((sm: ServerMeta) => {
                                                    return !usedDictKeyStr.includes(sm.content) || sm.content === condtionDictKey;
                                                });
                                                const hideValues: string[] = [targetCondition?.dictKey as string];
                                                const allowValues: string[] = conditionOptions?.map((conItem) => conItem.dictKey);
                                                const allValues: string[] = (allItems as ServerMeta[])?.map((conItem) => conItem.dictKey);
                                                allValues.forEach((k: string) => {
                                                    if (!allowValues.includes(k)) {
                                                        hideValues.push(k);
                                                    }
                                                });

                                                return (
                                                    <div className={s.module} key={field.name}>
                                                        <div className={s.rowHeader}>
                                                            <span>Link {index + 1}</span>
                                                            <Button
                                                                onClick={() => {
                                                                    const _newUsedCondition = [...usedDictKeyStr].filter((str) => str !== condtionDictKey);
                                                                    setUsedDictKeyStr(_newUsedCondition);
                                                                    remove(index);
                                                                }
                                                                }
                                                            >
                                                                Remove
                                                            </Button>
                                                        </div>
                                                        <div className={s.content}>
                                                            <div className={s.row}>
                                                                <div
                                                                    className={s.rowItem}
                                                                    style={{ width: '100%' }}
                                                                >
                                                                    {/* <Form.Item
                                                                        label={`Condition page under ${targetSpecialty?.content?.toLowerCase()}`}
                                                                        name={[field.name, 'linkTag']}
                                                                        validateFirst
                                                                        rules={[
                                                                            { required: true, message: REQUIRED_FIELD },
                                                                        ]}
                                                                    >
                                                                        <Select
                                                                            showSearch
                                                                            onChange={(val) => {
                                                                                const _newUsedCondition = [...usedDictKeyStr].filter((str) => str !== condtionDictKey);
                                                                                if (!usedDictKeyStr.includes(val)) {
                                                                                    _newUsedCondition.push(val);
                                                                                }
                                                                                setUsedDictKeyStr(_newUsedCondition);
                                                                            }}
                                                                        >
                                                                            {
                                                                                condtionList?.map((sm:ServerMeta) => {
                                                                                    return (
                                                                                        <Select.Option
                                                                                            disabled={usedDictKeyStr.includes(sm.dictKey) && sm.dictKey !== condtionDictKey}
                                                                                            key={sm.dictKey}
                                                                                            value={sm.dictKey}
                                                                                        >
                                                                                            {sm.content}
                                                                                        </Select.Option>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </Select>
                                                                    </Form.Item> */}
                                                                    <Form.Item
                                                                        label={`Condition page under ${targetSpecialty?.content?.toLowerCase()}`}
                                                                        name={[field.name, 'linkTag']}
                                                                        validateFirst
                                                                        rules={[
                                                                            { required: true, message: REQUIRED_FIELD },
                                                                        ]}
                                                                    >
                                                                        <KlarityConditionWithFeatureSelect
                                                                            onChange={(val) => {
                                                                                const _newUsedCondition = [...usedDictKeyStr].filter((str) => str !== condtionDictKey);
                                                                                if (!usedDictKeyStr.includes(val)) {
                                                                                    _newUsedCondition.push(val);
                                                                                }
                                                                                setUsedDictKeyStr(_newUsedCondition);
                                                                            }}
                                                                            hideValues={hideValues}
                                                                            showSearch
                                                                            disableValues={usedDictKeyStr}
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                            <div className={s.row}>
                                                                <div
                                                                    className={s.rowItem}
                                                                    style={{ width: '100%' }}
                                                                >
                                                                    <div className={s.labelText}>Link name *</div>
                                                                    <Input value={text} disabled />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                        <div
                                            onClick={() => {
                                                add();
                                            }}
                                            className={s.addLinkBtn}
                                        >
                                            Add another link
                                        </div>
                                    </>
                                );
                            }
                        }
                    </Form.List>
                </Form>
            }
        </Modal>
    );
};

export default EditSpecialtyModal;
