import React from 'react';
import s from './s.module.less';
import cx from 'classnames';

type IProps = {
    isMobileMode: boolean
};

const CarouselsPreviewEmpty = (props: IProps) => {
    const { isMobileMode } = props;
    return (
        <div className={s.preview}>
            {isMobileMode ? (
                <div className={cx(s.previewMobile, s.center)}>
                    <div className={s.empty}>
                        <p className={s.emptyTips}>No carousel preview</p>
                    </div>
                </div>
            ) : (
                <div className={cx(s.previewPc, s.center)}>
                    <div className={s.empty}>
                        <p className={s.emptyTips}>No carousel preview</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CarouselsPreviewEmpty;
