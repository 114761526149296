import React, { useCallback, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import s from './s.module.less';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import type { ColumnsType } from 'antd/es/table';
import { Button, Input, Checkbox, Space } from 'antd';
import CommonTable from 'components/CommonTable';
import { useRequest } from 'ahooks';
import { getProviderChannelAuditList } from 'api/operation';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import { SearchOutlined } from '@ant-design/icons';
import { ListToChannelTableRecord } from 'types/operation';
import debounce from 'lodash/debounce';
import useGetSettingData from 'hooks/useGetSettingData';
import { EnumFields } from 'types/enumerationData';
import { CheckboxChangeEvent, CheckboxOptionType } from 'antd/lib/checkbox';
import ChannelAuditStatusTag from 'components/ChannelAuditStatusTag';

type Params = {
    nameCond?: string,
    auditStatusList?: CheckboxValueType[],
    completeStatusList?: CheckboxValueType[],
    orderKey?: string,
    orderType?: 'desc' | 'asc',
    current: number,
    size: number,
    archiveStatusList?: string[],
    assignee?:string,
};

const Page = () => {
    const navigate = useNavigate();
    const [pendingNameFilter, setPendingNameFilter] = useState('');
    const [pendingAssigneeFilter, setPendingAssigneeFilter] = useState('');

    // ops 状态
    const [aduitStatusIndeterminate, setAduitStatusIndeterminate] = useState(false);
    const [aduitCheckAll, setAduitCheckAll] = useState(false);
    const [aduitList, setAduitList] = useState<Array<CheckboxValueType>>([]);

    // 医生端状态
    const [listingStatusIndeterminate, setListingStatusIndeterminate] = useState(false);
    const [listingStatusCheckAll, setListingStatusCheckAll] = useState(false);
    const [listingStatusList, setListingStatusList] = useState<Array<CheckboxValueType>>([]);

    const [auditStatusData] = useGetSettingData(EnumFields.CHANNEL_AUDIT_STATUS);
    const [listingStatusData] = useGetSettingData(EnumFields.PROFILE_COMPLETE);

    const auditStatusOptions = auditStatusData?.map((item) => ({ label: item.content, value: item.dictKey }));
    const listingStatusOptions = listingStatusData?.map((item) => ({ label: item.content, value: item.dictKey }));

    const [current, setCurrent] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);

    const defaultPrams = useRef<Params>({
        nameCond: pendingNameFilter,
        auditStatusList: aduitList || [],
        completeStatusList: listingStatusList || [],
        orderKey: 'submit_time',
        orderType: 'desc',
        current: 1,
        size: 10,
        assignee: pendingAssigneeFilter,
    });

    const { data, loading, run } = useRequest(getProviderChannelAuditList, {
        defaultParams: [
            defaultPrams.current,
        ],
    });

    const listData:ListToChannelTableRecord[] = data?.data?.data?.records || [];
    const total = data?.data?.data?.total || 0;

    const handleTableChange = useCallback((paginationData?:any, filtersData?:any, sorter?:any, action?:any) => {
        const params = defaultPrams.current;
        if (paginationData) {
            params.current = paginationData.current;
            setCurrent(paginationData.current);
        } else {
            params.current = 1;
            setCurrent(1);
        }

        if (action?.action === 'sort') {
            params.orderType = sorter.order === 'ascend' ? 'asc' : 'desc';
            if (sorter.column.dataIndex === 'submitTime') {
                params.orderKey = 'submit_time';
            }
            if (sorter.column.dataIndex === 'listTime') {
                params.orderKey = 'list_time';
            }
        }

        // paginationData存在，说明是tableChange，否则是别处调用
        if (!paginationData) {
            params.nameCond = filtersData.name;
            params.auditStatusList = filtersData.auditStatusList;
            params.completeStatusList = filtersData.completeStatusList;
            params.assignee = filtersData.assignee;
        }
        run(params);
    }, [run]);

    const filterNameDebounce = useRef(debounce((params) => {
        handleTableChange(null, params);
    }, 500));

    const filterAssigneeDebounce = useRef(debounce((params) => {
        handleTableChange(null, params);
    }, 500));

    const handleListingStatusFilterCheckAll = useCallback((e: CheckboxChangeEvent) => {
        const checkedListData = e.target.checked && listingStatusOptions ? listingStatusOptions.map((option: CheckboxOptionType) => option.value) : [];

        setListingStatusList(checkedListData);
        setListingStatusIndeterminate(false);
        setListingStatusCheckAll(e.target.checked);

        handleTableChange(null, {
            name: pendingNameFilter,
            assignee: pendingAssigneeFilter,
            auditStatusList: aduitList,
            completeStatusList: checkedListData,
        });
    }, [aduitList, handleTableChange, listingStatusOptions, pendingNameFilter]);

    const handleAduitStatusFilterCheckAll = useCallback((e: CheckboxChangeEvent) => {
        const checkedListData = e.target.checked && auditStatusOptions ? auditStatusOptions.map((option: CheckboxOptionType) => option.value) : [];

        setAduitList(checkedListData);
        setAduitStatusIndeterminate(false);
        setAduitCheckAll(e.target.checked);

        handleTableChange(null, {
            name: pendingNameFilter,
            assignee: pendingAssigneeFilter,
            auditStatusList: checkedListData,
            completeStatusList: listingStatusList,
        });
    }, [auditStatusOptions, handleTableChange, listingStatusList, pendingNameFilter]);

    const handleListingStatusFilterChange = useCallback((statusList: CheckboxValueType[]) => {
        setListingStatusList(statusList);
        setListingStatusIndeterminate(!!statusList.length && !!listingStatusOptions && statusList.length < listingStatusOptions.length);
        setListingStatusCheckAll(statusList.length === listingStatusOptions?.length);

        handleTableChange(null, {
            name: pendingNameFilter,
            assignee: pendingAssigneeFilter,
            auditStatusList: aduitList,
            completeStatusList: statusList,
        });
    }, [aduitList, handleTableChange, listingStatusOptions, pendingNameFilter]);

    const handleAduitStatusFilterChange = useCallback((statusList: CheckboxValueType[]) => {
        setAduitList(statusList);
        setAduitStatusIndeterminate(!!statusList.length && !!auditStatusOptions && statusList.length < auditStatusOptions.length);
        setAduitCheckAll(statusList.length === auditStatusOptions?.length);

        handleTableChange(null, {
            name: pendingNameFilter,
            assignee: pendingAssigneeFilter,
            auditStatusList: statusList,
            completeStatusList: listingStatusList,
        });
    }, [auditStatusOptions, handleTableChange, listingStatusList, pendingNameFilter]);

    const columns: ColumnsType<ListToChannelTableRecord> = useMemo(() => (
        [
            {
                title: 'NAME',
                dataIndex: 'name',
                filterSearch: true,
                className: s.tableColumn,
                filterIcon: <SearchOutlined />,
                width: '250px',
                fixed: 'left',
                filterDropdown: () => (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Input
                            value={pendingNameFilter}
                            onChange={(e) => {
                                const filterName = e.target.value;
                                setPendingNameFilter(filterName);
                                filterNameDebounce.current({
                                    name: filterName,
                                    auditStatusList: aduitList,
                                    completeStatusList: listingStatusList,
                                });
                            }}
                            style={{ marginBottom: 8, display: 'block' }}
                        />
                    </div>
                ),
                render: (name:string, { photo, firstName, lastName, email }:ListToChannelTableRecord) => {
                    return (
                        <div className={s.nameWrap}>
                            <div className={s.logo} style={{ backgroundImage: `url(${photo})` }} />
                            <div className={s.nameRight}>
                                <div className={s.name}>{firstName} {lastName}</div>
                                <div className={s.email}>{email}</div>
                            </div>
                        </div>
                    );
                },
            },
            {
                title: 'CHANNEL',
                dataIndex: 'channelName',
                width: '180px',
                className: s.tableColumn,
            },
            {
                title: 'SUBMITTED ON',
                dataIndex: 'submitTime',
                sortDirections: ['ascend', 'descend', 'ascend'],
                sorter: true,
                width: '180px',
                className: s.tableColumn,
                render: (time) => {
                    return (
                        <div>{dayjs(time).format('MM/DD/YYYY')}</div>
                    );
                },
            },
            {
                title: 'LISTED ON',
                dataIndex: 'listTime',
                sortDirections: ['ascend', 'descend', 'ascend'],
                sorter: true,
                width: '180px',
                className: s.tableColumn,
                render: (time) => {
                    return (
                        <div>{time ? dayjs(time).format('MM/DD/YYYY') : 'Nil'}</div>
                    );
                },
            },
            {
                title: 'UNIPROFILE',
                dataIndex: 'profileCompleteStatus',
                width: '180px',
                className: s.tableColumn,
                filterIcon: <SearchOutlined />,
                filterDropdown: () => {
                    return (
                        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                            <Space>
                                <Checkbox indeterminate={listingStatusIndeterminate} onChange={handleListingStatusFilterCheckAll} checked={listingStatusCheckAll}>
                                    Check all
                                </Checkbox>
                                <Checkbox.Group options={listingStatusOptions} value={listingStatusList} onChange={handleListingStatusFilterChange} />
                            </Space>
                        </div>
                    );
                },
            },
            {
                title: 'OPS PROGRESS',
                dataIndex: 'status',
                width: '280px',
                className: s.tableColumn,
                filterIcon: <SearchOutlined />,
                filterDropdown: () => {
                    return (
                        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                            <Space>
                                <Checkbox indeterminate={aduitStatusIndeterminate} onChange={handleAduitStatusFilterCheckAll} checked={aduitCheckAll}>
                                    Check all
                                </Checkbox>
                                <Checkbox.Group options={auditStatusOptions} value={aduitList} onChange={handleAduitStatusFilterChange} />
                            </Space>
                        </div>
                    );
                },
                render: (status: string) => status && <ChannelAuditStatusTag status={status} />,
            },
            {
                title: 'ASSIGNEE',
                dataIndex: 'assignee',
                filterSearch: true,
                className: s.tableColumn,
                filterIcon: <SearchOutlined />,
                width: '180px',
                filterDropdown: () => (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Input
                            value={pendingAssigneeFilter}
                            onChange={(e) => {
                                const filterName = e.target.value;
                                setPendingAssigneeFilter(filterName);
                                filterAssigneeDebounce.current({
                                    assignee: filterName,
                                    name: pendingNameFilter,
                                    auditStatusList: aduitList,
                                    completeStatusList: listingStatusList,
                                });
                            }}
                            style={{ marginBottom: 8, display: 'block' }}
                        />
                    </div>
                ),
                render: (assignee) => {
                    return (
                        <div>{assignee || ''}</div>
                    );
                },
            },
            {
                title: '',
                width: '80px',
                className: s.tableColumn,
                fixed: 'right',
                render: (item) => {
                    const { channelName, channelId, providerId } = item;
                    const urlSearchParams = channelName ? `?channelName=${encodeURIComponent(channelName)}` : '';
                    const url = `${window.location.protocol}//${window.location.host}`;
                    const link = `${url}${PATH.OPERATION}/${PATH.LIST_TO_CHANNEL_DETAIL}/${channelId}/${providerId}${urlSearchParams}`;
                    return (
                        <div>
                            <Button
                                className={s.link}
                                onClick={() => {
                                    //navigate(`${url}/${PATH.OPERATION}/${PATH.LIST_TO_CHANNEL_DETAIL}/${channelId}/${providerId}${urlSearchParams}`);
                                    window.open(link, 'mozillaTab');
                                }}
                                type="text"
                            >
                                View
                            </Button>
                            {/* <a
                                target="_blank"
                                className={s.link}
                                href={link}
                                rel="noreferrer"
                            >
                                View
                            </a> */}
                        </div>
                    );
                },
            },
        ]
    ), [aduitCheckAll, aduitList, aduitStatusIndeterminate, auditStatusOptions, filterNameDebounce, handleAduitStatusFilterChange, handleAduitStatusFilterCheckAll, handleListingStatusFilterChange, handleListingStatusFilterCheckAll, listingStatusCheckAll, listingStatusIndeterminate, listingStatusList, listingStatusOptions, navigate, pendingNameFilter]);

    return (
        <div className={s.wrap}>
            <h2>Providers on Hello Klarity</h2>
            <div className={s.tableWrap}>
                <CommonTable
                    rowKey={(record: ListToChannelTableRecord) => record.id}
                    bordered
                    loading={loading}
                    pagination={{
                        current,
                        pageSize,
                        total,
                    }}
                    scroll={{ x: '1500' }}
                    columns={columns}
                    data={listData || []}
                    onChange={handleTableChange}
                />
            </div>
        </div>
    );
};

export default Page;
