import React, { useEffect, useState } from 'react';
import { Button, Space, message, Modal } from 'antd';
import CommonTable from 'components/CommonTable';
import type { ColumnsType } from 'antd/es/table';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { getCarouselList, kiwiCarouselDelete } from 'api/airtable';
import CreateAndEditPage from './components/CreateAndEditPage';
import type { TCarousel } from 'types/kiwiHealth';
import s from './s.module.less';

const CarouselManagementPage = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [carouselItem, setCarouselItem] = useState<TCarousel>();
    const [carouselData, setCarouselData] = useState<TCarousel[]>([]);
    const [modal, contextHolder] = Modal.useModal();

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const res = await getCarouselList();
            setCarouselData(res);
        } catch (e) {
            console.error('error: ', e);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDelete = async (record: TCarousel) => {
        modal.confirm({
            width: '600px',
            closable: true,
            title: `Do you confirm remove this carousel - ${record.title.replace(
                /<\/?span[^>]*>/g,
                '',
            )}?`,
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: 'Yes, confirm',
            cancelText: 'Cancel',
            onOk: async () => {
                const index = record?.index;
                try {
                    await kiwiCarouselDelete(index).finally(() => fetchData());
                } catch (error) {
                    console.error(error);
                }
            },
        });
    };

    const handleAdd = () => {
        setIsEdit(false);
        setIsOpen(true);
        setCarouselItem(undefined);
    };

    const handleEdit = (record: TCarousel) => {
        setCarouselItem(record);
        setIsEdit(true);
        setIsOpen(true);
    };

    const onSuccess = () => {
        setIsOpen(false);
        fetchData();
        message.success(isEdit ? 'Updated successfully' : 'Added successfully');
    };

    const columns: ColumnsType<TCarousel> = [
        {
            title: 'TITLE',
            dataIndex: 'title',
            key: 'title',
            align: 'left',
            width: '300px',
            render: (_, record: TCarousel) => (
                <p
                    dangerouslySetInnerHTML={{ __html: record.title }}
                    className={s.carouselTitle}
                />
            ),
        },
        {
            title: 'DESCRIPTION',
            dataIndex: 'description',
            key: 'description',
            align: 'left',
            width: '300px',
        },
        {
            title: 'IMAGE (640 * 514)',
            dataIndex: 'image_url',
            key: 'image_url',
            align: 'left',
            width: '350px',
            render: (_, record: TCarousel) => (
                <img className={s.banner} src={record.image_url} />
            ),
        },
        {
            title: '',
            align: 'center',
            render: (_, record: TCarousel) => (
                <Space size="small">
                    <Button
                        className={s.link}
                        type="text"
                        onClick={() => handleEdit(record)}
                    >
                        Edit
                    </Button>
                    <Button
                        className={s.link}
                        type="text"
                        onClick={() => handleDelete(record)}
                    >
                        Remove
                    </Button>
                </Space>
            ),
        },
    ];

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <div className={s.title}>Manage your carousel</div>
                <Button onClick={handleAdd} icon={<PlusOutlined />}>
                    Add a new one
                </Button>
            </div>
            <CommonTable
                bordered
                loading={isLoading}
                columns={columns}
                data={carouselData}
            />
            {isOpen && (
                <CreateAndEditPage
                    item={carouselItem}
                    onCancel={() => setIsOpen(false)}
                    onSuccess={onSuccess}
                />
            )}
            {contextHolder}
        </div>
    );
};

export default CarouselManagementPage;
