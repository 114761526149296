import React from 'react';
import s from './s.module.less';
import OpenGoogleForm from '../OpenGoogleForm';

const Index = () => {
    return (
        <div className={s.wrap}>
            <OpenGoogleForm
                sheetType="COMPLAINTS_PROVIDER"
                text="Check in Google form (Provider)"
            />
            <div className={s.mr20} />
            <OpenGoogleForm
                sheetType="COMPLAINTS_KLARITY"
                text="Check in Google form (Klarity)"
            />
        </div>
    );
};

export default Index;
