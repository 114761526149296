import { Provider, ProviderChannelService, ProviderPractice, ProviderChannelHealthcareExperience, ProviderChannelEducation, AddtionalList } from 'types/channelApplicationProvider';
import { License } from './common';

export interface IGetProviderFormDetailInput {
    allowAuth: boolean;
    channelId?: number | string;
    providerId?: number | string;
    step: string;
}

export interface IProviderReviewSubmitInput {
    allowAuth: boolean;
    channelId: number;
}

export interface SelectProviderChannelPhotoInput {
    photo: string,
}

export type IProvider = {
    assignee: string;
    notes: string;
    profile:Partial<Provider>,
    services: Array<ProviderChannelService>,
    practice: ProviderPractice,
    experienceList: Array<ProviderChannelHealthcareExperience>,
    educationList: Array<ProviderChannelEducation>,
    addtional: {
        payout: string;
        showReview: boolean;
        associationAdditionalList: Array<AddtionalList>;
        awardAdditionalList: Array<AddtionalList>;
        publicationAdditionalList: Array<AddtionalList>;
    },
    insurance: {
        insuranceList: Array<string>;
        otherInsuranceList: string;
        supportPayType: string;
    },
    licenseDtoList: License[]
};

export interface ISpecialtiesOption {
    label: string;
    value: string;
    children: Array<ISpecialtiesOption>
}

export type ISpecialtiesOptions = Array<ISpecialtiesOption>;

export interface IServiceAddressUpdateInput {
    id: number;
    addressId: number;
}

export enum PracticeAddressUpdateType {
    UPDATE = 'update',
    REMOVE = 'remove',
}
