import TinyStore from 'lib/tinyStore';
import { getGoogleToken, getGoogleTokenExpiredAt } from 'utils/localstore';

type GoogleModal = {
    clientReady: boolean;
    calendarReady: boolean;
    token?:string;
    expiredAt?:number;
};

const Store = TinyStore.createStore<GoogleModal>({
    nameSpace: 'klarity',
    key: 'google',
    crossBundle: false,
    runTime: true,
    initValue: { clientReady: false, calendarReady: false, token: getGoogleToken(), expiredAt: getGoogleTokenExpiredAt() },
});

export default Store;
