import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Button, Tooltip } from 'antd';
import { kiwiCarouselCreate, kiwiCarouselUpdate } from 'api/airtable';
import type { TCarousel } from 'types/kiwiHealth';
import FileUpload from 'components/form/FileUpload';
import commonS from 'styles/common.module.less';
import s from './s.module.less';

type Props = {
    item?: TCarousel
    onCancel?: () => void
    onSuccess?: () => void
};

const { TextArea } = Input;

const CreateAndEditPage: React.FC<Props> = ({
    item,
    onCancel,
    onSuccess,
}) => {
    const [submitting, setSubmitting] = useState(false);
    const [form] = Form.useForm<TCarousel & { active: boolean }>();
    const isEdit = !!item?.index;

    const onSave = async () => {
        setSubmitting(true);
        try {
            const formValues = await form.validateFields();
            const index = item?.index;
            const params: Partial<TCarousel> = {
                title: formValues.title,
                description: formValues.description,
                image_url: formValues.image_url,
            };
            if (isEdit) {
                params.index = index;
            }
            const apiFunc = isEdit
                ? kiwiCarouselUpdate(params)
                : kiwiCarouselCreate(params);
            await apiFunc;
            onSuccess?.();
        } catch (error) {
            console.error(error);
        } finally {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        if (item) {
            form.setFieldsValue({
                title: item.title,
                description: item.description,
                image_url: item.image_url,
            });
        } else {
            form.resetFields();
        }
    }, [item]);

    return (
        <Modal
            title={isEdit ? 'Edit' : 'Add a new one'}
            closable
            className={commonS.modalFixHeightWrap}
            width="500px"
            destroyOnClose
            onCancel={onCancel}
            okText="Save"
            open
            footer={
                <div>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button loading={submitting} type="primary" onClick={onSave}>
                        Save
                    </Button>
                </div>
            }
        >
            <div className={s.wrap}>
                <Form
                    form={form}
                    className={commonS.formStyle1}
                    initialValues={{ active: true }}
                    autoComplete="off"
                    layout="vertical"
                    scrollToFirstError
                >
                    <>
                        <Form.Item
                            className={s.rowItem}
                            label={
                                <div className={s.formLabel}>
                                    Title
                                    <Tooltip
                                        overlayClassName={s.tipCard}
                                        overlayInnerStyle={{ padding: '12px' }}
                                        title={
                                            <div className={s.tipText}>
                                                If you want to highlight the text, please wrap the
                                                text with span tags. For examples, &quot;Build your
                                                reputation and &lt;span&gt;earn good
                                                reviews&lt;/span&gt; &quot;.
                                            </div>
                                        }
                                    >
                                        <div className={s.questionMarkIcon} />
                                    </Tooltip>
                                </div>
                            }
                            name="title"
                            rules={[{ required: true, message: 'Title is required' }]}
                        >
                            <TextArea style={{ width: '100%' }} rows={4} maxLength={200} />
                        </Form.Item>
                        <Form.Item
                            className={s.rowItem}
                            label="Description"
                            name="description"
                            rules={[{ required: true, message: 'Description is required' }]}
                        >
                            <TextArea style={{ width: '100%' }} rows={4} maxLength={300} />
                        </Form.Item>
                        <Form.Item
                            className={s.rowItem}
                            label="Image (640 * 514)"
                            name={['image_url']}
                            rules={[{ required: true, message: 'Image is required' }]}
                        >
                            <FileUpload />
                        </Form.Item>
                    </>
                </Form>
            </div>
        </Modal>
    );
};

export default CreateAndEditPage;
