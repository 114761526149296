import React, { useRef, useState } from 'react';
import { Select, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const { Option } = Select;

const ProviderSearch = () => {
    const searchBarRef = useRef<any>(null);
    const [selectedState, setSelectedState] = useState('State');
    const [selectedCondition, setSelectedCondition] = useState('Specialty or condition');

    const handleSearch = () => {
        let searchText = searchBarRef?.current?.innerText;
        searchText = searchText.replace(/\n/g, ' ').trim();
        // console.log('Search Text: ', searchText);
        // console.log('Selected State: ', selectedState);
        // console.log('Selected Condition: ', selectedCondition);

        // 执行搜索逻辑
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Backspace') {
            const selection = window.getSelection()!;
            //console.log({ selection });
            if (selection && selection.rangeCount > 0) {
                const range = selection.getRangeAt(0);
                const { startContainer } = range;
                // 检查光标当前是否在 Select 组件后
                if (startContainer.nodeType === Node.TEXT_NODE && startContainer.nodeValue === '' && startContainer.previousSibling && startContainer.previousSibling.classList.contains('editable-select')) {
                    e.preventDefault();
                    startContainer.previousSibling.remove();
                }
            }
        }
    };

    return (
        <div className="provider-search">
            <h1>Provider search</h1>
            <div
                ref={searchBarRef}
                className="search-bar"
                contentEditable
                suppressContentEditableWarning
                onKeyDown={handleKeyDown}
            >
                <SearchOutlined className="search-icon" />
                <span className="editable-text">Help me find providers in </span>

                <Select
                    className="editable-select"
                    defaultValue="State"
                    bordered={false}
                    onChange={(value) => setSelectedState(value)}
                >
                    <Option value="State">State</Option>
                    <Option value="California">California</Option>
                    <Option value="New York">New York</Option>
                </Select>

                <span className="editable-text"> who treats </span>

                <Select
                    className="editable-select"
                    defaultValue="Specialty or condition"
                    bordered={false}
                    onChange={(value) => setSelectedCondition(value)}
                >
                    <Option value="Specialty or condition">Specialty or condition</Option>
                    <Option value="ADHD">ADHD</Option>
                    <Option value="Weight loss">Weight loss</Option>
                </Select>

                <span className="editable-text"> wh who tr who tr who tr who tro treats </span>

                <Button type="primary" icon={<SearchOutlined />} onClick={handleSearch} />
            </div>
        </div>
    );
};

export default ProviderSearch;
