import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import s from './s.module.less';
import { Button, Checkbox, Input, Space, Spin, message } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { SearchOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import CommonTable from 'components/CommonTable';
import { EOriderType, EProviderListMappingStatusValue, IBPMappingItem, IProviderMappingDataResp, EProviderMappingStatusValue } from 'types/operation';
import type { CheckboxOptionType, CheckboxValueType } from 'antd/es/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import dayjs from 'dayjs';
import AddModal from './components/AddModal';
import useGetProviderMappingData from 'hooks/helloklarityOps/useGetProviderMappingData';
import OpsProviderMappingListStatusTag from 'components/OpsProviderMappingListStatusTag';
import { getHelloKlairtyProfileLink } from 'api/operation';
import copy from 'copy-to-clipboard';
import useGetSettingData from 'hooks/useGetSettingData';
import { EnumFields } from 'types/enumerationData';
import AccessStore from 'store/Access/permission';
import { PermissionModuleKeys } from 'constants/access';
import { prereleaseOriginReplacement } from 'utils/helloklarity';
import { PRE_RELEASE_ORIGIN } from 'constants/common';
import MulitProfileLinkModal from '../MulitProfileLinkModal';
import { urlSearchParamsReplacement } from 'utils/common';

interface IProps {
    showMappingModal: () => void;
    showSlugModal: () => void;
    showTitleModal: () => void;
    hideMappingConfirmModal: () => void;
    showMappingConfirmModal: () => void;
    showPatientRatingModal: () => void;
    handleSetCurrentRow: (id: IBPMappingItem | null) => void;
    forceReload: Record<any, any>;
    handleListingStatusChange: (id: number, status: EProviderListMappingStatusValue) => void;
    findProviderByEmail: (email: string, type: number) => Promise<any>;
}

type TOption = {
    label: string;
    value: string;
};

const AirTableTable = ({
    showMappingModal,
    showSlugModal,
    showTitleModal,
    showPatientRatingModal,
    handleSetCurrentRow,
    handleListingStatusChange,
    forceReload,
    findProviderByEmail,
    hideMappingConfirmModal,
    showMappingConfirmModal,
}: IProps) => {
    const [getAccessStore] = AccessStore.useStore();
    const access = getAccessStore('data');
    const [stateSettingOptions] = useGetSettingData(EnumFields.STATE, true) as (TOption[])[];
    const [providerListingStatus] = useGetSettingData(EnumFields.PROVIDER_MAPPING_LIST_STATUS, true) as (CheckboxOptionType[])[];
    const [providerMappingStatusSettingData] = useGetSettingData(EnumFields.PROVIDER_MAPPING_STATUS, true) as (CheckboxOptionType[])[];
    const providerMappingStatus = providerMappingStatusSettingData?.filter((option) => option.value !== EProviderMappingStatusValue.EHR_MAPPED);
    const [patientRatingStatus] = useGetSettingData(EnumFields.PROVIDER_PATIENT_RATING, true) as (CheckboxOptionType[])[];
    const [pageLoading, setPageLoading] = useState(false);
    const [showProfileLinkModal, setShowProfileLinkModal] = useState(false);
    const [currentProfileLinks, setCurrentProfileLinks] = useState([]);

    const [showAdditionalModal, setShowAdditionalModal] = useState(false);

    // 先保留，防止后面改成需要控制edit权限
    const editPermission = true;
    const ratingPermission = useMemo(() => !!(access && access[PermissionModuleKeys.KLARITY_PROVIDER_AIRTABLE_RATING]), [access]);

    // string filter
    const [nameFilter, setNameFilter] = useState('');
    const [slugFilter, setSlugFilter] = useState('');
    const [airtableIdFilter, setAirtableIdFilter] = useState('');
    const [intakeqProviderIdFilter, setIntakeqProviderIdFilter] = useState('');
    const [uidFilter, setUidFilter] = useState('');

    // pagination
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    // order
    const [orderType, setOrderType] = useState<EOriderType | undefined>(EOriderType.DESC);

    // profile status filter
    const [listingStatusIndeterminate, setListingStatusIndeterminate] = useState(false);
    const [listingStatusCheckAll, setListingStatusCheckAll] = useState(false);
    const [listingStatusList, setListingStatusList] = useState<Array<CheckboxValueType>>([]);

    // mapping status filter
    const [mappingStatusIndeterminate, setMappingStatusIndeterminate] = useState(false);
    const [mappingStatusCheckAll, setMappingStatusCheckAll] = useState(false);
    const [mappingStatusList, setMappingStatusList] = useState<Array<CheckboxValueType>>([]);

    // patient rating filter
    const [patientRatingIndeterminate, setPatientRatingIndeterminate] = useState(false);
    const [patientRatingCheckAll, setPatientRatingCheckAll] = useState(false);
    const [patientRatingList, setPatientRatingList] = useState<Array<CheckboxValueType>>([]);

    const [data = {}, tableLoading, tableError, bpRefetch] = useGetProviderMappingData();

    const { records, total = 0 } = data as IProviderMappingDataResp;

    useEffect(() => {
        if (tableError) {
            message.error(tableError);
        }
    }, [tableError]);

    const handleTableChange = useCallback((paginationData?: TablePaginationConfig, filtersData?: any, sorter?: any, action?: any) => {
        setCurrent(paginationData?.current!);
        setPageSize(paginationData?.pageSize!);

        if (action?.action === 'sort') {
            const orderKey = sorter.order === 'ascend' ? EOriderType.ASC : EOriderType.DESC;

            setOrderType(orderKey);
        }
    }, []);

    const handleHideAdditionalModal = useCallback(() => {
        setShowAdditionalModal(false);
    }, []);

    const handleShowAdditionalModal = useCallback(() => {
        if (editPermission) {
            setShowAdditionalModal(true);
        }
    }, [editPermission]);

    const refetchTableData = useCallback(() => {
        const params = {
            current,
            // ehrProviderId,
            intakeqProviderId: intakeqProviderIdFilter,
            airtableProviderId: airtableIdFilter,
            listingStatus: listingStatusList as string[],
            mappingStatus: mappingStatusList as string[],
            name: nameFilter,
            orderKey: 'last_update_at',
            orderType,
            patientRating: patientRatingList as number[],
            providerNameSlug: slugFilter,
            size: pageSize,
            title: '',
            type: 2,
            uidPublic: uidFilter,
        };

        bpRefetch(params);
    }, [airtableIdFilter, bpRefetch, current, intakeqProviderIdFilter, listingStatusList, mappingStatusList, nameFilter, orderType, pageSize, patientRatingList, slugFilter, uidFilter]);

    useEffect(() => {
        refetchTableData();
    }, [forceReload, refetchTableData]);

    // profile status filter
    const handleListingStatusFilterCheckAll = useCallback((e: CheckboxChangeEvent) => {
        const checkedListData = e.target.checked && providerListingStatus ? providerListingStatus.map((option: CheckboxOptionType) => option.value) : [];

        setListingStatusList(checkedListData);
        setListingStatusIndeterminate(false);
        setListingStatusCheckAll(e.target.checked);
        setCurrent(1);
    }, [providerListingStatus]);

    const handleListingStatusFilter = useCallback((statusList: CheckboxValueType[]) => {
        setListingStatusList(statusList);
        setListingStatusIndeterminate(!!statusList.length && !!providerListingStatus && statusList.length < providerListingStatus.length);
        setListingStatusCheckAll(statusList.length === providerListingStatus?.length);
        setCurrent(1);
    }, [providerListingStatus]);

    // mapping status filter
    const handleMappingStatusFilterCheckAll = useCallback((e: CheckboxChangeEvent) => {
        const checkedListData = e.target.checked && providerMappingStatus ? providerMappingStatus.map((option: CheckboxOptionType) => option.value) : [];

        setMappingStatusList(checkedListData);
        setMappingStatusIndeterminate(false);
        setMappingStatusCheckAll(e.target.checked);

        setCurrent(1);
    }, [providerMappingStatus]);

    const handleMappingStatusFilter = useCallback((statusList: CheckboxValueType[]) => {
        setMappingStatusList(statusList);
        setMappingStatusIndeterminate(!!statusList.length && !!providerMappingStatus && statusList.length < providerMappingStatus.length);
        setMappingStatusCheckAll(statusList.length === providerMappingStatus?.length);

        setCurrent(1);
    }, [providerMappingStatus]);

    // patient rating filter
    const handlePatientRatingFilterCheckAll = useCallback((e: CheckboxChangeEvent) => {
        const checkedListData = e.target.checked && patientRatingStatus ? patientRatingStatus.map((option: CheckboxOptionType) => option.value) : [];

        setPatientRatingList(checkedListData);
        setPatientRatingIndeterminate(false);
        setPatientRatingCheckAll(e.target.checked);

        setCurrent(1);
    }, [patientRatingStatus]);

    const handlePatientRatingFilter = useCallback((statusList: CheckboxValueType[]) => {
        setPatientRatingList(statusList);
        setPatientRatingIndeterminate(!!statusList.length && !!patientRatingStatus && statusList.length < patientRatingStatus.length);
        setPatientRatingCheckAll(statusList.length === patientRatingStatus?.length);

        setCurrent(1);
    }, [patientRatingStatus]);

    const filterNameDebounce = useRef(debounce((setFn, value) => {
        setFn(value);
        setCurrent(1);
    }, 500));

    const filterUidDebounce = useRef(debounce((setFn, value) => {
        setFn(value);
        setCurrent(1);
    }, 500));

    const handleShowMappingModal = useCallback((row: IBPMappingItem) => {
        handleSetCurrentRow(row);
        showMappingModal();
    }, [handleSetCurrentRow, showMappingModal]);

    const handleShowSlugModal = useCallback((row: IBPMappingItem) => {
        if (editPermission) {
            handleSetCurrentRow(row);
            showSlugModal();
        }
    }, [editPermission, handleSetCurrentRow, showSlugModal]);

    const handleShowTitleModal = useCallback((row: IBPMappingItem) => {
        if (editPermission) {
            handleSetCurrentRow(row);
            showTitleModal();
        }
    }, [editPermission, handleSetCurrentRow, showTitleModal]);

    const handleShowPatientRatingModal = useCallback((row: IBPMappingItem) => {
        if (ratingPermission) {
            handleSetCurrentRow(row);
            showPatientRatingModal();
        }
    }, [ratingPermission, handleSetCurrentRow, showPatientRatingModal]);

    const handleReList = useCallback(async (item: IBPMappingItem) => {
        if (editPermission) {
            handleSetCurrentRow(item);
            try {
                const res = await findProviderByEmail(item.email, 1);

                if (res && !res.error) {
                    const providerRecords = res.data?.data?.records;

                    if (providerRecords && providerRecords.length > 0) {
                        const { mappingStatus } = providerRecords[0];

                        if (mappingStatus === EProviderMappingStatusValue.EHR_MAPPED) {
                            showMappingConfirmModal();

                            return;
                        }
                    }
                } else {
                    message.error(res?.error);
                }

                handleListingStatusChange(item.id, EProviderListMappingStatusValue.LISTED);
                hideMappingConfirmModal();
            } catch (e) {
                console.error(e);
            }
        }
    }, [editPermission, findProviderByEmail, handleListingStatusChange]);

    const handleGenerateProfileLink = useCallback(async (id: number) => {
        if (id) {
            setPageLoading(true);
            try {
                const res = await getHelloKlairtyProfileLink(id);

                if (res && !res.error) {
                    if (res.data?.data && res.data?.data.length > 1) {
                        const profileLinks = res.data?.data.map((link: string) => prereleaseOriginReplacement(link, PRE_RELEASE_ORIGIN));

                        setCurrentProfileLinks(profileLinks);
                        setShowProfileLinkModal(true);
                    } else if (res.data?.data?.[0]) {
                        let newUrl = prereleaseOriginReplacement(res.data?.data[0], PRE_RELEASE_ORIGIN);
                        const urlObj = new URL(newUrl);

                        const { search } = urlObj;
                        const searchParams = new URLSearchParams(search);

                        const stateVal = searchParams.get('state') as string;

                        const fullNameStateOption = stateSettingOptions?.find((item) => item.value === stateVal);

                        if (fullNameStateOption) {
                            const stateFullname = fullNameStateOption.label?.toLowerCase().replace(' ', '-');

                            newUrl = urlSearchParamsReplacement(newUrl, 'state', stateFullname);
                        }

                        window.open(newUrl, '_blank');
                    }
                } else {
                    message.error(res.error);
                }
            } catch (e) {
                console.error(e);
            }
            setPageLoading(false);
        }
    }, [stateSettingOptions]);

    const columns: ColumnsType<any> = useMemo(() => (
        [
            {
                title: 'TITLE',
                dataIndex: 'airtableProviderTitle',
                filterSearch: true,
                className: s.tableColumn,
                filterIcon: <SearchOutlined />,
                width: '280px',
                fixed: 'left',
                filterDropdown: () => (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Input
                            // value={nameFilter}
                            onChange={(e) => {
                                const filterName = e.target.value;

                                filterNameDebounce.current(setNameFilter, filterName);
                            }}
                            style={{ marginBottom: 8, display: 'block' }}
                        />
                    </div>
                ),
                render: (title: string, row: IBPMappingItem) => {
                    const { conflict } = row;
                    return (
                        <div className={s.nameWrap}>
                            {conflict && <div className={s.warning} onClick={() => handleShowMappingModal(row)} />}
                            <div className={s.name}>{title}</div>
                            {editPermission && <div className={s.editIcon} onClick={() => handleShowTitleModal(row)} />}
                        </div>
                    );
                },
            },
            {
                title: 'UID',
                dataIndex: 'uidPublic',
                filterSearch: true,
                className: s.tableColumn,
                filterIcon: <SearchOutlined />,
                width: '180px',
                fixed: 'left',
                filterDropdown: () => (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Input
                            // value={nameFilter}
                            onChange={(e) => {
                                const filterUid = e.target.value;
                                filterUidDebounce.current(setUidFilter, filterUid);
                            }}
                            style={{ marginBottom: 8, display: 'block' }}
                        />
                    </div>
                ),
                render: (uid: string) => {
                    return (
                        <div className={s.nameWrap}>
                            {uid}
                        </div>
                    );
                },
            },
            {
                title: 'STATUS',
                dataIndex: 'listingStatus',
                key: 'listingStatus',
                width: '200px',
                className: s.tableColumn,
                filterDropdown: () => {
                    return (
                        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                            <Space>
                                <Checkbox indeterminate={listingStatusIndeterminate} onChange={handleListingStatusFilterCheckAll} checked={listingStatusCheckAll}>
                                    Check all
                                </Checkbox>
                                <Checkbox.Group options={providerListingStatus} value={listingStatusList} onChange={handleListingStatusFilter} />
                            </Space>
                        </div>
                    );
                },
                render: (status) => status && <OpsProviderMappingListStatusTag status={status} />,
            },
            {
                title: 'DATA STATUS',
                dataIndex: 'mappingStatus',
                key: 'mappingStatus',
                width: '130px',
                className: s.tableColumn,
                filterDropdown: () => {
                    return (
                        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                            <Space>
                                <Checkbox indeterminate={mappingStatusIndeterminate} onChange={handleMappingStatusFilterCheckAll} checked={mappingStatusCheckAll}>
                                    Check all
                                </Checkbox>
                                <Checkbox.Group options={providerMappingStatus} value={mappingStatusList} onChange={handleMappingStatusFilter} />
                            </Space>
                        </div>
                    );
                },
                render: (status) => {
                    if (status === EProviderMappingStatusValue.AIR_TABLE_MAPPING) {
                        return 'Mapped';
                    }

                    return 'Unmapped';
                },
            },
            {
                title: 'PROFILE LINK',
                dataIndex: 'profileLink',
                key: 'bookingLink',
                width: '170px',
                className: s.tableColumn,
                render: (v, item: IBPMappingItem) => {
                    const { listingStatus } = item;

                    if (listingStatus !== EProviderListMappingStatusValue.PENDING_REVIEW && listingStatus !== EProviderListMappingStatusValue.DISQUALIFIED) {
                        return <a className={s.profileLink} onClick={() => handleGenerateProfileLink(item.id)}>Link</a>;
                    }

                    return null;
                },
            },
            {
                title: 'PATIENT RATING',
                dataIndex: 'patientRating',
                key: 'patientRating',
                width: '170px',
                className: s.tableColumn,
                filterDropdown: () => {
                    return (
                        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                            <Space>
                                <Checkbox indeterminate={patientRatingIndeterminate} onChange={handlePatientRatingFilterCheckAll} checked={patientRatingCheckAll}>
                                    Check all
                                </Checkbox>
                                <Checkbox.Group options={patientRatingStatus} value={patientRatingList} onChange={handlePatientRatingFilter} />
                            </Space>
                        </div>
                    );
                },
                render: (val: string, row: IBPMappingItem) => {
                    const label = patientRatingStatus?.find((e) => val.toString() === e.value.toString())?.label || '';

                    return (
                        <div className={s.slug}>{label}
                            {ratingPermission && <div className={s.editIcon} onClick={() => handleShowPatientRatingModal(row)} />}
                        </div>
                    );
                },
            },
            {
                title: 'INTAKEQ PROVIDER ID',
                dataIndex: 'intakeqProviderId',
                width: '180px',
                className: s.tableColumn,
                filterSearch: true,
                filterIcon: <SearchOutlined />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Input
                            onChange={(e) => {
                                const filterName = e.target.value;

                                filterNameDebounce.current(setIntakeqProviderIdFilter, filterName);
                            }}
                            style={{ marginBottom: 8, display: 'block' }}
                        />
                    </div>
                ),
                render: (text) => {
                    return (
                        <div className={s.slug}>{text}
                            <div
                                className={s.copyIcon}
                                onClick={() => {
                                    copy(text || '', { format: 'text/plain' });
                                    message.success('Copied to the clipboard');
                                }}
                            />
                        </div>
                    );
                },
            },
            {
                title: 'AIRTABBLE ID',
                dataIndex: 'airtableProviderId',
                width: '180px',
                className: s.tableColumn,
                filterSearch: true,
                filterIcon: <SearchOutlined />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Input
                            onChange={(e) => {
                                const filterName = e.target.value;

                                filterNameDebounce.current(setAirtableIdFilter, filterName);
                            }}
                            style={{ marginBottom: 8, display: 'block' }}
                        />
                    </div>
                ),
                render: (text) => {
                    return (
                        <div className={s.slug}>{text}</div>
                    );
                },
            },
            {
                title: 'SLUG',
                dataIndex: 'providerNameSlug',
                width: '180px',
                className: s.tableColumn,
                filterSearch: true,
                filterIcon: <SearchOutlined />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Input
                            onChange={(e) => {
                                const filterName = e.target.value;

                                filterNameDebounce.current(setSlugFilter, filterName);
                            }}
                            style={{ marginBottom: 8, display: 'block' }}
                        />
                    </div>
                ),
                render: (text, row) => {
                    return (
                        <div className={s.slug}>{text}
                            {editPermission && <div className={s.editIcon} onClick={() => handleShowSlugModal(row)} />}
                        </div>
                    );
                },
            },
            {
                title: 'LAST MODIFIED',
                dataIndex: 'lastUpdateAt',
                sortDirections: ['ascend', 'descend', 'ascend'],
                sorter: true,
                width: '150px',
                className: s.tableColumn,
                render: (time) => {
                    return (
                        <div>{time ? dayjs(time).format('MM/DD/YYYY') : 'Nil'}</div>
                    );
                },
            },
            editPermission ? {
                title: '',
                width: '100px',
                className: s.tableColumn,
                fixed: 'right',
                render: (item: IBPMappingItem) => {
                    if (item.listingStatus === EProviderListMappingStatusValue.PENDING_REVIEW) {
                        return (
                            <div>
                                <Button
                                    className={s.link}
                                    onClick={() => handleListingStatusChange(item.id, EProviderListMappingStatusValue.LISTED)}
                                    type="text"
                                >List
                                </Button>
                            </div>
                        );
                    }
                    if (item.listingStatus === EProviderListMappingStatusValue.UNLISTED) {
                        return (
                            <div>
                                <Button
                                    className={s.link}
                                    onClick={() => handleReList(item)}
                                    type="text"
                                >Re-list
                                </Button>
                            </div>
                        );
                    }

                    if (item.listingStatus === EProviderListMappingStatusValue.LISTED) {
                        return (
                            <div>
                                <Button
                                    className={s.link}
                                    onClick={() => handleListingStatusChange(item.id, EProviderListMappingStatusValue.UNLISTED)}
                                    type="text"
                                >Unlist
                                </Button>
                            </div>
                        );
                    }
                },
            } : {},
        ]
    ), [editPermission, handleGenerateProfileLink, handleListingStatusChange, handleListingStatusFilter, handleListingStatusFilterCheckAll, handleMappingStatusFilter, handleMappingStatusFilterCheckAll, handlePatientRatingFilter, handlePatientRatingFilterCheckAll, handleReList, handleShowMappingModal, handleShowPatientRatingModal, handleShowSlugModal, handleShowTitleModal, listingStatusCheckAll, listingStatusIndeterminate, listingStatusList, mappingStatusCheckAll, mappingStatusIndeterminate, mappingStatusList, patientRatingCheckAll, patientRatingIndeterminate, patientRatingList, patientRatingStatus, providerListingStatus, providerMappingStatus, ratingPermission]);

    return (
        <Spin spinning={pageLoading}>
            <div className={s.wrap}>
                <AddModal
                    open={showAdditionalModal}
                    onSuccess={() => {
                        refetchTableData();
                        handleHideAdditionalModal();
                    }}
                    onCancel={handleHideAdditionalModal}
                />
                <MulitProfileLinkModal
                    show={showProfileLinkModal}
                    onCancel={() => setShowProfileLinkModal(false)}
                    onOk={() => setShowProfileLinkModal(false)}
                    profileLinks={currentProfileLinks}
                />
                <div className={s.titleBar}>
                    <div className={s.title}>Airtable providers</div>
                    {editPermission && <Button type="primary" onClick={handleShowAdditionalModal}>Add provider from Airtable</Button>}
                </div>
                <CommonTable<any>
                    rowKey={(record: any) => record.id}
                    bordered
                    // loading={loading}
                    columns={columns}
                    data={records || []}
                    onChange={handleTableChange}
                    pagination={{
                        current,
                        pageSize,
                        total,
                    }}
                    loading={tableLoading}
                    scroll={{ x: '1200' }}
                // rowSelection={rowSelection}
                />
            </div>
        </Spin>
    );
};

export default AirTableTable;
