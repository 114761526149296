import { EContactType, KlarityService, ServerMeta, TKlarityProgramItem, TKlarityProgramItemDetail, TMedication, TProgramPlan } from "types/common"
import { TFaqInfo } from "types/operation"


export const mockProgramPlans: TProgramPlan[] = [
  {
    title: "Compounded Semaglutide Kickstart Plan",
    des: "Designed for patients who are new to GLP-1 and need to build tolerance towards the medication. Program includes new patient initial visit + 3-month medication for patient.",
    des2: "Suggested responsibilities (Provider can tailor based on clinical decision)",
    earnInit: 75,
    earnFollowUp: 188,
    earnAll: 263,
    res: [
      {
        label: 'Initial visit (30 mins)',
        content: 'Conduct a detailed health assessment, discuss weight loss goals, and review medical history. \nEvaluate whether patient is a good fit for Compounded Semaglutide.'
      },
      {
        label: 'First injection tutorial (10 mins)',
        content: 'Provide a call or video tutorial to guide the patient through their first injection, ensuring safety and confidence.'
      },
      {
        label: 'Ongoing support (20 mins/month)',
        points: [
          'Monthly follow-up visits to monitor progress and adjust treatment.',
          'Manage side effects and respond to unlimited patient messages within 72 hours.',
          'Ensure timely prescription orders to the partnered pharmacy for patient care.'
        ],
      }
    ]
  },
  {
    title: "Compounded Semaglutide Momentum Plan",
    des: "Designed for patients who are already on GLP-1 and need to build momentum in achieving their weight loss targets. Program includes new patient initial visit + 3-month medication for patient.",
    des2: "Suggested responsibilities (Provider can tailor based on clinical decision)",
    earnInit: 75,
    earnFollowUp: 105,
    earnAll: 180,
    res: [
      {
        label: 'Initial visit (30 mins)',
        content: 'Conduct a detailed health assessment, discuss weight loss goals, and review medical history. \nEvaluate whether patient is a good fit for Compounded Semaglutide.'
      },
      {
        label: 'Ongoing support (5 mins/month)',
        points: [
          'Continue to monitor the patient’s progress and adjust the treatment plan as needed.',
          'Manage side effects and respond to unlimited patient messages within 72 hours.',
          'Schedule follow-up sessions on EHR per patient request, charged separately.'
        ],
      },
      {
        label: 'Medication management (15 mins/every 3 months)',
        points: [
          'Manage prescription refills.',
          'Ensure timely delivery of medication.',
        ]
      }
    ]
  },
]

export const mockSelectedServiceData: KlarityService[] = [
  {
    "id": 5,
    "iconUrl": "https://cdn.helloklarity.net/ui/img/icon/infections.svg",
    "iconUri": "https://cdn.helloklarity.net/ui/img/icon/infections.svg",
    "specialtyName": "Infections",
    "description": "Fever,Persistent cough,Skin infections,Diarrhea,Sexually transmitted diseases (STD),Yeast Infection,Urinary tract infections,Respiratory infections,Pneumonia,Lyme disease,Urinary tract infections (UTI)",
    "patientDescription": "Focuses on the diagnosis, treatment, and management of infectious diseases; managing acute and chronic infections, providing vaccinations, and offering travel health advice.",
    "commonCondition": "Fever,Persistent cough,Skin infections,Diarrhea,Sexually transmitted diseases (STD),Yeast Infection,Urinary tract infections (UTI),Respiratory infections,Pneumonia,Lyme disease",
    "image": "https://saas-prod-us.s3.us-east-2.amazonaws.com/prod/6fb7893c-0a0d-4c9c-8f56-f2d12d1e434c.png",
    "createAt": "2024-05-11T17:39:11.000+00:00",
    "createBy": 0,
    "updateAt": "2024-09-03T15:37:32.000+00:00",
    "updateBy": 0,
    "serversTypes": [
      {
        "id": 21,
        "serviceTypeId": 1,
        "mandatory": false,
        "specId": 5,
        "switchType": true,
        "switchPatientGroupLimitation": true,
        "patientGroupIds": "1,2,3,4,5,6,7",
        "limitationIds": "1,2,3,4,5,6",
        "switchTeleHealthInPerson": false,
        "teleHealth": false,
        "inPerson": false,
        "duration": 101,
        "createAt": "2024-05-08T09:21:33.000+00:00",
        "createBy": 43,
        "updateAt": "2024-05-13T07:50:58.000+00:00",
        "updateBy": 43
      },
      {
        "id": 22,
        "serviceTypeId": 2,
        "mandatory": false,
        "specId": 5,
        "switchType": true,
        "switchPatientGroupLimitation": true,
        "patientGroupIds": "1,2,3,4,5,6,7",
        "limitationIds": "1,2,3,4,5,6",
        "switchTeleHealthInPerson": false,
        "teleHealth": false,
        "inPerson": false,
        "duration": 101,
        "createAt": "2024-05-08T09:21:33.000+00:00",
        "createBy": 43,
        "updateAt": "2024-05-13T07:50:58.000+00:00",
        "updateBy": 43
      },
      {
        "id": 23,
        "serviceTypeId": 3,
        "mandatory": false,
        "specId": 5,
        "switchType": true,
        "switchPatientGroupLimitation": true,
        "patientGroupIds": "1,2,3,4,5,6,7",
        "limitationIds": "1,2,3,4,5,6",
        "switchTeleHealthInPerson": false,
        "teleHealth": false,
        "inPerson": false,
        "duration": 101,
        "createAt": "2024-05-08T09:21:33.000+00:00",
        "createBy": 43,
        "updateAt": "2024-05-13T07:50:58.000+00:00",
        "updateBy": 43
      },
      {
        "id": 24,
        "serviceTypeId": 4,
        "mandatory": false,
        "specId": 5,
        "switchType": true,
        "switchPatientGroupLimitation": true,
        "patientGroupIds": "1,2,3,4,5,6,7",
        "limitationIds": "1,2,3,4,5,6",
        "switchTeleHealthInPerson": false,
        "teleHealth": false,
        "inPerson": false,
        "duration": 101,
        "createAt": "2024-05-08T09:21:33.000+00:00",
        "createBy": 43,
        "updateAt": "2024-05-13T07:50:58.000+00:00",
        "updateBy": 43
      },
      {
        "id": 25,
        "serviceTypeId": 5,
        "mandatory": false,
        "specId": 5,
        "switchType": true,
        "switchPatientGroupLimitation": true,
        "patientGroupIds": "1,2,3,4,5,6,7",
        "limitationIds": "1,2,3,4,5,6",
        "switchTeleHealthInPerson": false,
        "teleHealth": false,
        "inPerson": false,
        "duration": 101,
        "createAt": "2024-05-08T09:21:33.000+00:00",
        "createBy": 43,
        "updateAt": "2024-05-13T07:50:57.000+00:00",
        "updateBy": 43
      },
      {
        "id": 45,
        "serviceTypeId": 6,
        "mandatory": false,
        "specId": 5,
        "switchType": true,
        "switchPatientGroupLimitation": true,
        "patientGroupIds": "1,2,3,4,5,6,7",
        "limitationIds": "1,2,3,4,5,6",
        "switchTeleHealthInPerson": false,
        "teleHealth": false,
        "inPerson": false,
        "duration": 101,
        "createAt": "2024-05-08T09:21:33.000+00:00",
        "createBy": 43,
        "updateAt": "2024-05-13T07:43:16.000+00:00",
        "updateBy": 43
      }
    ],
    "serversTypeNames": [
      {
        "id": 1,
        "serviceType": "New patient initial visit",
        "displayName": "👋 New patient initial visit",
        "description": "An initial visit with a provider involves a medical history review, an assessment of your specific symptoms and concerns, and pop. An initial visit with a provider involves a medical history review, an assessment of your specific symptoms and concerns",
        "mandatory": true,
        "newPatient": false,
        "switchTeleHealthInPerson": true,
        "teleHealth": false,
        "inPerson": true,
        "defaultDuration": 30,
        "minDuration": 2,
        "maxDuration": 100,
        "switchPatientGroupLimitation": false,
        "qtyPatientGroup": 0,
        "qtyLimitation": 0,
        "createAt": "2024-05-08T14:30:06.000+00:00",
        "createBy": 0,
        "updateAt": "2024-09-24T15:07:52.000+00:00",
        "updateBy": 21,
        "klarityServiceTypePatientGroupList": [
          {
            "id": 2,
            "serviceTypeId": 1,
            "patientGroup": "Adolescents (13-17 years old)",
            "sequence": 1,
            "notDelete": false
          },
          {
            "id": 3,
            "serviceTypeId": 1,
            "patientGroup": "Adults (18-65 years old)",
            "sequence": 2,
            "notDelete": false
          },
          {
            "id": 1,
            "serviceTypeId": 1,
            "patientGroup": "Children (5-12 years old)",
            "sequence": 3,
            "notDelete": false
          },
          {
            "id": 5,
            "serviceTypeId": 1,
            "patientGroup": "Couples",
            "sequence": 4,
            "notDelete": false
          },
          {
            "id": 6,
            "serviceTypeId": 1,
            "patientGroup": "Family",
            "sequence": 5,
            "notDelete": false
          },
          {
            "id": 7,
            "serviceTypeId": 1,
            "patientGroup": "Group session",
            "sequence": 6,
            "notDelete": false
          },
          {
            "id": 4,
            "serviceTypeId": 1,
            "patientGroup": "Seniors (65+ years old)",
            "sequence": 7,
            "notDelete": false
          }
        ],
        "klarityServiceTypeLimitationList": [
          {
            "id": 1,
            "serviceTypeId": 1,
            "limitation": "Cannot see patients currently pregnant",
            "sequence": 1,
            "notDelete": false
          },
          {
            "id": 4,
            "serviceTypeId": 1,
            "limitation": "Cannot see patients with certain medical conditions: heart issues",
            "sequence": 2,
            "notDelete": false
          },
          {
            "id": 5,
            "serviceTypeId": 1,
            "limitation": "Patients in addiction recovery must provide a confirmed sobriety date.",
            "sequence": 3,
            "notDelete": false
          },
          {
            "id": 3,
            "serviceTypeId": 1,
            "limitation": "Unable to see patients with recent mental health hospitalizations",
            "sequence": 4,
            "notDelete": false
          },
          {
            "id": 6,
            "serviceTypeId": 1,
            "limitation": "Unable to treat patients diagnosed with eating disorders.",
            "sequence": 5,
            "notDelete": false
          },
          {
            "id": 2,
            "serviceTypeId": 1,
            "limitation": "Cannot treat patients with active suicidal behaviour or a history of suicide attempts or self-harm.",
            "sequence": 6,
            "notDelete": false
          }
        ]
      },
      {
        "id": 2,
        "serviceType": "Consultation",
        "displayName": "💬 Consultation",
        "description": "An opportunity for potential patients to discuss their concerns or needs with a provider",
        "mandatory": false,
        "newPatient": true,
        "switchTeleHealthInPerson": true,
        "teleHealth": true,
        "inPerson": true,
        "defaultDuration": 0,
        "minDuration": 0,
        "maxDuration": 1,
        "switchPatientGroupLimitation": true,
        "qtyPatientGroup": 10,
        "qtyLimitation": 7,
        "createAt": "2024-05-08T14:30:06.000+00:00",
        "createBy": 0,
        "updateAt": "2024-05-15T06:15:44.000+00:00",
        "updateBy": 17,
        "klarityServiceTypePatientGroupList": [
          {
            "id": 9,
            "serviceTypeId": 2,
            "patientGroup": "Adolescents (13-17 years old)",
            "sequence": 1,
            "notDelete": false
          },
          {
            "id": 10,
            "serviceTypeId": 2,
            "patientGroup": "Adults (18-65 years old)",
            "sequence": 2,
            "notDelete": false
          },
          {
            "id": 8,
            "serviceTypeId": 2,
            "patientGroup": "Children (5-12 years old)",
            "sequence": 3,
            "notDelete": false
          },
          {
            "id": 12,
            "serviceTypeId": 2,
            "patientGroup": "Couples",
            "sequence": 4,
            "notDelete": false
          },
          {
            "id": 13,
            "serviceTypeId": 2,
            "patientGroup": "Family",
            "sequence": 5,
            "notDelete": false
          },
          {
            "id": 14,
            "serviceTypeId": 2,
            "patientGroup": "Group session",
            "sequence": 6,
            "notDelete": false
          },
          {
            "id": 11,
            "serviceTypeId": 2,
            "patientGroup": "Seniors (65+ years old)",
            "sequence": 7,
            "notDelete": false
          },
          {
            "id": 42,
            "serviceTypeId": 2,
            "patientGroup": "555",
            "sequence": 8,
            "notDelete": false
          },
          {
            "id": 43,
            "serviceTypeId": 2,
            "patientGroup": "777",
            "sequence": 9,
            "notDelete": false
          }
        ],
        "klarityServiceTypeLimitationList": [
          {
            "id": 8,
            "serviceTypeId": 2,
            "limitation": "Cannot see patients currently pregnant",
            "sequence": 1,
            "notDelete": true
          },
          {
            "id": 11,
            "serviceTypeId": 2,
            "limitation": "Cannot see patients with certain medical conditions: heart issues",
            "sequence": 2,
            "notDelete": true
          },
          {
            "id": 9,
            "serviceTypeId": 2,
            "limitation": "Cannot treat patients with active suicidal behaviour or a history of suicide attempts or self-harm.",
            "sequence": 3,
            "notDelete": true
          },
          {
            "id": 12,
            "serviceTypeId": 2,
            "limitation": "Patients in addiction recovery must provide a confirmed sobriety date.",
            "sequence": 4,
            "notDelete": true
          },
          {
            "id": 10,
            "serviceTypeId": 2,
            "limitation": "Unable to see patients with recent mental health hospitalizations",
            "sequence": 5,
            "notDelete": true
          },
          {
            "id": 13,
            "serviceTypeId": 2,
            "limitation": "Unable to treat patients diagnosed with eating disorders.",
            "sequence": 6,
            "notDelete": true
          }
        ]
      },
      {
        "id": 3,
        "serviceType": "Established patient visit",
        "displayName": "🔄 Established patient visit",
        "description": "A follow-up visit with a provider involves checking the progress of the patient's treatment plan, assessing any new symptoms or ",
        "mandatory": false,
        "newPatient": true,
        "switchTeleHealthInPerson": true,
        "teleHealth": true,
        "inPerson": true,
        "defaultDuration": 45,
        "minDuration": 10,
        "maxDuration": 60,
        "switchPatientGroupLimitation": true,
        "qtyPatientGroup": 8,
        "qtyLimitation": 7,
        "createAt": "2024-05-08T14:30:06.000+00:00",
        "createBy": 0,
        "updateAt": "2024-05-13T06:22:52.000+00:00",
        "updateBy": 14,
        "klarityServiceTypePatientGroupList": [
          {
            "id": 16,
            "serviceTypeId": 3,
            "patientGroup": "Adolescents (13-17 years old)",
            "sequence": 0,
            "notDelete": false
          },
          {
            "id": 17,
            "serviceTypeId": 3,
            "patientGroup": "Adults (18-65 years old)",
            "sequence": 0,
            "notDelete": false
          },
          {
            "id": 15,
            "serviceTypeId": 3,
            "patientGroup": "Children (5-12 years old)",
            "sequence": 0,
            "notDelete": false
          },
          {
            "id": 19,
            "serviceTypeId": 3,
            "patientGroup": "Couples",
            "sequence": 0,
            "notDelete": false
          },
          {
            "id": 20,
            "serviceTypeId": 3,
            "patientGroup": "Family",
            "sequence": 0,
            "notDelete": false
          },
          {
            "id": 21,
            "serviceTypeId": 3,
            "patientGroup": "Group session",
            "sequence": 0,
            "notDelete": false
          },
          {
            "id": 18,
            "serviceTypeId": 3,
            "patientGroup": "Seniors (65+ years old)",
            "sequence": 0,
            "notDelete": false
          }
        ],
        "klarityServiceTypeLimitationList": [
          {
            "id": 14,
            "serviceTypeId": 3,
            "limitation": "Cannot see patients currently pregnant",
            "sequence": 0,
            "notDelete": true
          },
          {
            "id": 17,
            "serviceTypeId": 3,
            "limitation": "Cannot see patients with certain medical conditions: heart issues",
            "sequence": 0,
            "notDelete": true
          },
          {
            "id": 15,
            "serviceTypeId": 3,
            "limitation": "Cannot treat patients with active suicidal behaviour or a history of suicide attempts or self-harm.",
            "sequence": 0,
            "notDelete": true
          },
          {
            "id": 18,
            "serviceTypeId": 3,
            "limitation": "Patients in addiction recovery must provide a confirmed sobriety date.",
            "sequence": 0,
            "notDelete": true
          },
          {
            "id": 16,
            "serviceTypeId": 3,
            "limitation": "Unable to see patients with recent mental health hospitalizations",
            "sequence": 0,
            "notDelete": true
          },
          {
            "id": 19,
            "serviceTypeId": 3,
            "limitation": "Unable to treat patients diagnosed with eating disorders.",
            "sequence": 0,
            "notDelete": true
          }
        ]
      },
      {
        "id": 4,
        "serviceType": "Prescription service",
        "displayName": "📃 Prescription service ",
        "description": "Prescription Fulfillment enables providers to prescribe medications efficiently, ensuring patients receive the correct treatment promptly.",
        "mandatory": true,
        "newPatient": false,
        "switchTeleHealthInPerson": false,
        "teleHealth": false,
        "inPerson": false,
        "defaultDuration": 0,
        "minDuration": 0,
        "maxDuration": 0,
        "switchPatientGroupLimitation": true,
        "qtyPatientGroup": 8,
        "qtyLimitation": 7,
        "createAt": "2024-05-08T14:30:06.000+00:00",
        "createBy": 0,
        "updateAt": "2024-10-31T09:47:19.000+00:00",
        "updateBy": 43,
        "klarityServiceTypePatientGroupList": [
          {
            "id": 36,
            "serviceTypeId": 4,
            "patientGroup": "add group Test",
            "sequence": 1,
            "notDelete": false
          },
          {
            "id": 23,
            "serviceTypeId": 4,
            "patientGroup": "Adolescents (13-17 years old)",
            "sequence": 2,
            "notDelete": false
          },
          {
            "id": 24,
            "serviceTypeId": 4,
            "patientGroup": "Adults (18-65 years old)",
            "sequence": 3,
            "notDelete": false
          },
          {
            "id": 22,
            "serviceTypeId": 4,
            "patientGroup": "Children (5-12 years old)",
            "sequence": 4,
            "notDelete": false
          },
          {
            "id": 26,
            "serviceTypeId": 4,
            "patientGroup": "Couples",
            "sequence": 5,
            "notDelete": false
          },
          {
            "id": 27,
            "serviceTypeId": 4,
            "patientGroup": "Family",
            "sequence": 6,
            "notDelete": false
          },
          {
            "id": 28,
            "serviceTypeId": 4,
            "patientGroup": "Group session",
            "sequence": 7,
            "notDelete": false
          },
          {
            "id": 25,
            "serviceTypeId": 4,
            "patientGroup": "Seniors (65+ years old)",
            "sequence": 8,
            "notDelete": false
          }
        ],
        "klarityServiceTypeLimitationList": [
          {
            "id": 20,
            "serviceTypeId": 4,
            "limitation": "Cannot see patients currently pregnant",
            "sequence": 0,
            "notDelete": true
          },
          {
            "id": 23,
            "serviceTypeId": 4,
            "limitation": "Cannot see patients with certain medical conditions: heart issues",
            "sequence": 0,
            "notDelete": true
          },
          {
            "id": 21,
            "serviceTypeId": 4,
            "limitation": "Cannot treat patients with active suicidal behaviour or a history of suicide attempts or self-harm.",
            "sequence": 0,
            "notDelete": true
          },
          {
            "id": 24,
            "serviceTypeId": 4,
            "limitation": "Patients in addiction recovery must provide a confirmed sobriety date.",
            "sequence": 0,
            "notDelete": true
          },
          {
            "id": 22,
            "serviceTypeId": 4,
            "limitation": "Unable to see patients with recent mental health hospitalizations",
            "sequence": 0,
            "notDelete": true
          },
          {
            "id": 25,
            "serviceTypeId": 4,
            "limitation": "Unable to treat patients diagnosed with eating disorders.",
            "sequence": 0,
            "notDelete": true
          }
        ]
      },
      {
        "id": 5,
        "serviceType": "Other patient support",
        "displayName": "📝 Other patient support",
        "description": "Explore a variety of support services tailored to your needs, including help with documentation, lab test prescriptions, and spe",
        "mandatory": false,
        "newPatient": false,
        "switchTeleHealthInPerson": false,
        "teleHealth": false,
        "inPerson": false,
        "defaultDuration": 1,
        "minDuration": 1,
        "maxDuration": 1,
        "switchPatientGroupLimitation": true,
        "qtyPatientGroup": 10,
        "qtyLimitation": 10,
        "createAt": "2024-05-08T14:30:06.000+00:00",
        "createBy": 0,
        "updateAt": "2024-05-15T06:13:56.000+00:00",
        "updateBy": 17,
        "klarityServiceTypePatientGroupList": [
          {
            "id": 30,
            "serviceTypeId": 5,
            "patientGroup": "Adolescents (13-17 years old)",
            "sequence": 1,
            "notDelete": false
          },
          {
            "id": 31,
            "serviceTypeId": 5,
            "patientGroup": "Adults (18-65 years old)",
            "sequence": 2,
            "notDelete": false
          },
          {
            "id": 29,
            "serviceTypeId": 5,
            "patientGroup": "Children (5-12 years old)",
            "sequence": 3,
            "notDelete": false
          },
          {
            "id": 33,
            "serviceTypeId": 5,
            "patientGroup": "Couples",
            "sequence": 4,
            "notDelete": false
          },
          {
            "id": 34,
            "serviceTypeId": 5,
            "patientGroup": "Family",
            "sequence": 5,
            "notDelete": false
          },
          {
            "id": 35,
            "serviceTypeId": 5,
            "patientGroup": "Group session",
            "sequence": 6,
            "notDelete": false
          },
          {
            "id": 32,
            "serviceTypeId": 5,
            "patientGroup": "Seniors (65+ years old)",
            "sequence": 7,
            "notDelete": false
          },
          {
            "id": 38,
            "serviceTypeId": 5,
            "patientGroup": "123",
            "sequence": 8,
            "notDelete": false
          },
          {
            "id": 41,
            "serviceTypeId": 5,
            "patientGroup": "000",
            "sequence": 9,
            "notDelete": false
          }
        ],
        "klarityServiceTypeLimitationList": [
          {
            "id": 26,
            "serviceTypeId": 5,
            "limitation": "Cannot see patients currently pregnant",
            "sequence": 1,
            "notDelete": true
          },
          {
            "id": 29,
            "serviceTypeId": 5,
            "limitation": "Cannot see patients with certain medical conditions: heart issues",
            "sequence": 2,
            "notDelete": true
          },
          {
            "id": 27,
            "serviceTypeId": 5,
            "limitation": "Cannot treat patients with active suicidal behaviour or a history of suicide attempts or self-harm.",
            "sequence": 3,
            "notDelete": true
          },
          {
            "id": 30,
            "serviceTypeId": 5,
            "limitation": "Patients in addiction recovery must provide a confirmed sobriety date.",
            "sequence": 4,
            "notDelete": true
          },
          {
            "id": 28,
            "serviceTypeId": 5,
            "limitation": "Unable to see patients with recent mental health hospitalizations",
            "sequence": 5,
            "notDelete": true
          },
          {
            "id": 31,
            "serviceTypeId": 5,
            "limitation": "Unable to treat patients diagnosed with eating disorders.",
            "sequence": 6,
            "notDelete": true
          },
          {
            "id": 1731420167,
            "serviceTypeId": 5,
            "limitation": "q",
            "sequence": 7,
            "notDelete": false
          },
          {
            "id": 1731420168,
            "serviceTypeId": 5,
            "limitation": "i",
            "sequence": 8,
            "notDelete": false
          },
          {
            "id": 1731420169,
            "serviceTypeId": 5,
            "limitation": "e",
            "sequence": 9,
            "notDelete": false
          }
        ]
      },
      {
        "id": 6,
        "serviceType": "Medications",
        "displayName": "💉 Medications",
        "description": "A prescription service appointment with a provider typically involves a brief review of his/her existing patient's medical history, confirming the need for the prescription service, and issuing a new prescription. It may also involve evaluating any side effects or concerns related to the medication and ensuring that the treatment plan is still appropriate for the patient's needs.",
        "mandatory": false,
        "newPatient": false,
        "switchTeleHealthInPerson": false,
        "teleHealth": false,
        "inPerson": false,
        "defaultDuration": 1,
        "minDuration": 1,
        "maxDuration": 1,
        "switchPatientGroupLimitation": true,
        "qtyPatientGroup": 10,
        "qtyLimitation": 10,
        "createAt": "2024-05-08T14:30:06.000+00:00",
        "createBy": 0,
        "updateAt": "2024-05-15T06:13:56.000+00:00",
        "updateBy": 17,
        "klarityServiceTypePatientGroupList": [

        ],
        "klarityServiceTypeLimitationList": [

        ]
      }
    ],
    "recommended": true,
    "programId": 2,
    "programEnrolled": true,
    "have": false,
    "program": true
  },
  {
    "id": 3,
    "iconUrl": "https://cdn.helloklarity.net/ui/img/icon/weight-loss.svg",
    "iconUri": "https://cdn.helloklarity.net/ui/img/icon/weight-loss.svg",
    "specialtyName": "Weight Loss",
    "description": "Provides a structured approach to weight loss through the use of approved medications alongside lifestyle change. This template is designed for providers specializing in medical weight management, especially the prescription of GLP-1/GIP medications such as Ozempic, Wegovy, Mounjaro, Saxenda, Zepbound, and similar compound medications.",
    "patientDescription": "Provides a structured approach to weight loss through the use of approved medications alongside lifestyle change.",
    "commonCondition": "Obesity,Overweight,nutrition counseling,Weight loss consultation,Weight management,Diabetes,Lifestyle counseling",
    "image": "https://saas-prod-us.s3.us-east-2.amazonaws.com/prod/9fe113fb-b21b-40ab-b30e-0e9e62479ac9.png",
    "createAt": "2024-05-11T17:39:11.000+00:00",
    "createBy": 0,
    "updateAt": "2024-09-03T15:37:32.000+00:00",
    "updateBy": 0,
    "serversTypes": [
      {
        "id": 11,
        "serviceTypeId": 1,
        "mandatory": false,
        "specId": 3,
        "switchType": true,
        "switchPatientGroupLimitation": true,
        "patientGroupIds": "1,2,3,4,5,6,7",
        "limitationIds": "1,2,3,4,5,6",
        "switchTeleHealthInPerson": false,
        "teleHealth": false,
        "inPerson": false,
        "duration": 101,
        "createAt": "2024-05-08T09:21:33.000+00:00",
        "createBy": 43,
        "updateAt": "2024-05-13T07:50:58.000+00:00",
        "updateBy": 43
      },
      {
        "id": 12,
        "serviceTypeId": 2,
        "mandatory": false,
        "specId": 3,
        "switchType": true,
        "switchPatientGroupLimitation": true,
        "patientGroupIds": "1,2,3,4,5,6,7",
        "limitationIds": "1,2,3,4,5,6",
        "switchTeleHealthInPerson": false,
        "teleHealth": false,
        "inPerson": false,
        "duration": 101,
        "createAt": "2024-05-08T09:21:33.000+00:00",
        "createBy": 43,
        "updateAt": "2024-05-13T07:50:58.000+00:00",
        "updateBy": 43
      },
      {
        "id": 13,
        "serviceTypeId": 3,
        "mandatory": false,
        "specId": 3,
        "switchType": true,
        "switchPatientGroupLimitation": true,
        "patientGroupIds": "1,2,3,4,5,6,7",
        "limitationIds": "1,2,3,4,5,6",
        "switchTeleHealthInPerson": false,
        "teleHealth": false,
        "inPerson": false,
        "duration": 101,
        "createAt": "2024-05-08T09:21:33.000+00:00",
        "createBy": 43,
        "updateAt": "2024-05-13T07:50:57.000+00:00",
        "updateBy": 43
      },
      {
        "id": 14,
        "serviceTypeId": 4,
        "mandatory": false,
        "specId": 3,
        "switchType": true,
        "switchPatientGroupLimitation": true,
        "patientGroupIds": "1,2,3,4,5,6,7",
        "limitationIds": "1,2,3,4,5,6",
        "switchTeleHealthInPerson": false,
        "teleHealth": false,
        "inPerson": false,
        "duration": 101,
        "createAt": "2024-05-08T09:21:33.000+00:00",
        "createBy": 43,
        "updateAt": "2024-05-13T07:50:57.000+00:00",
        "updateBy": 43
      },
      {
        "id": 15,
        "serviceTypeId": 5,
        "mandatory": false,
        "specId": 3,
        "switchType": true,
        "switchPatientGroupLimitation": true,
        "patientGroupIds": "1,2,3,4,5,6,7",
        "limitationIds": "1,2,3,4,5,6",
        "switchTeleHealthInPerson": false,
        "teleHealth": false,
        "inPerson": false,
        "duration": 101,
        "createAt": "2024-05-08T09:21:33.000+00:00",
        "createBy": 43,
        "updateAt": "2024-05-13T07:50:59.000+00:00",
        "updateBy": 43
      },
      {
        "id": 43,
        "serviceTypeId": 6,
        "mandatory": false,
        "specId": 3,
        "switchType": true,
        "switchPatientGroupLimitation": true,
        "patientGroupIds": "1,2,3,4,5,6,7",
        "limitationIds": "1,2,3,4,5,6",
        "switchTeleHealthInPerson": false,
        "teleHealth": false,
        "inPerson": false,
        "duration": 101,
        "createAt": "2024-05-08T09:21:33.000+00:00",
        "createBy": 43,
        "updateAt": "2024-05-13T07:43:16.000+00:00",
        "updateBy": 43
      }
    ],
    "serversTypeNames": [
      {
        "id": 1,
        "serviceType": "New patient initial visit",
        "displayName": "👋 New patient initial visit",
        "description": "An initial visit with a provider involves a medical history review, an assessment of your specific symptoms and concerns, and pop. An initial visit with a provider involves a medical history review, an assessment of your specific symptoms and concerns",
        "mandatory": true,
        "newPatient": false,
        "switchTeleHealthInPerson": true,
        "teleHealth": false,
        "inPerson": true,
        "defaultDuration": 30,
        "minDuration": 2,
        "maxDuration": 100,
        "switchPatientGroupLimitation": false,
        "qtyPatientGroup": 0,
        "qtyLimitation": 0,
        "createAt": "2024-05-08T14:30:06.000+00:00",
        "createBy": 0,
        "updateAt": "2024-09-24T15:07:52.000+00:00",
        "updateBy": 21,
        "klarityServiceTypePatientGroupList": [
          {
            "id": 2,
            "serviceTypeId": 1,
            "patientGroup": "Adolescents (13-17 years old)",
            "sequence": 1,
            "notDelete": false
          },
          {
            "id": 3,
            "serviceTypeId": 1,
            "patientGroup": "Adults (18-65 years old)",
            "sequence": 2,
            "notDelete": false
          },
          {
            "id": 1,
            "serviceTypeId": 1,
            "patientGroup": "Children (5-12 years old)",
            "sequence": 3,
            "notDelete": false
          },
          {
            "id": 5,
            "serviceTypeId": 1,
            "patientGroup": "Couples",
            "sequence": 4,
            "notDelete": false
          },
          {
            "id": 6,
            "serviceTypeId": 1,
            "patientGroup": "Family",
            "sequence": 5,
            "notDelete": false
          },
          {
            "id": 7,
            "serviceTypeId": 1,
            "patientGroup": "Group session",
            "sequence": 6,
            "notDelete": false
          },
          {
            "id": 4,
            "serviceTypeId": 1,
            "patientGroup": "Seniors (65+ years old)",
            "sequence": 7,
            "notDelete": false
          }
        ],
        "klarityServiceTypeLimitationList": [
          {
            "id": 1,
            "serviceTypeId": 1,
            "limitation": "Cannot see patients currently pregnant",
            "sequence": 1,
            "notDelete": false
          },
          {
            "id": 4,
            "serviceTypeId": 1,
            "limitation": "Cannot see patients with certain medical conditions: heart issues",
            "sequence": 2,
            "notDelete": false
          },
          {
            "id": 5,
            "serviceTypeId": 1,
            "limitation": "Patients in addiction recovery must provide a confirmed sobriety date.",
            "sequence": 3,
            "notDelete": false
          },
          {
            "id": 3,
            "serviceTypeId": 1,
            "limitation": "Unable to see patients with recent mental health hospitalizations",
            "sequence": 4,
            "notDelete": false
          },
          {
            "id": 6,
            "serviceTypeId": 1,
            "limitation": "Unable to treat patients diagnosed with eating disorders.",
            "sequence": 5,
            "notDelete": false
          },
          {
            "id": 2,
            "serviceTypeId": 1,
            "limitation": "Cannot treat patients with active suicidal behaviour or a history of suicide attempts or self-harm.",
            "sequence": 6,
            "notDelete": false
          }
        ]
      },
      {
        "id": 2,
        "serviceType": "Consultation",
        "displayName": "💬 Consultation",
        "description": "An opportunity for potential patients to discuss their concerns or needs with a provider",
        "mandatory": false,
        "newPatient": true,
        "switchTeleHealthInPerson": true,
        "teleHealth": true,
        "inPerson": true,
        "defaultDuration": 0,
        "minDuration": 0,
        "maxDuration": 1,
        "switchPatientGroupLimitation": true,
        "qtyPatientGroup": 10,
        "qtyLimitation": 7,
        "createAt": "2024-05-08T14:30:06.000+00:00",
        "createBy": 0,
        "updateAt": "2024-05-15T06:15:44.000+00:00",
        "updateBy": 17,
        "klarityServiceTypePatientGroupList": [
          {
            "id": 9,
            "serviceTypeId": 2,
            "patientGroup": "Adolescents (13-17 years old)",
            "sequence": 1,
            "notDelete": false
          },
          {
            "id": 10,
            "serviceTypeId": 2,
            "patientGroup": "Adults (18-65 years old)",
            "sequence": 2,
            "notDelete": false
          },
          {
            "id": 8,
            "serviceTypeId": 2,
            "patientGroup": "Children (5-12 years old)",
            "sequence": 3,
            "notDelete": false
          },
          {
            "id": 12,
            "serviceTypeId": 2,
            "patientGroup": "Couples",
            "sequence": 4,
            "notDelete": false
          },
          {
            "id": 13,
            "serviceTypeId": 2,
            "patientGroup": "Family",
            "sequence": 5,
            "notDelete": false
          },
          {
            "id": 14,
            "serviceTypeId": 2,
            "patientGroup": "Group session",
            "sequence": 6,
            "notDelete": false
          },
          {
            "id": 11,
            "serviceTypeId": 2,
            "patientGroup": "Seniors (65+ years old)",
            "sequence": 7,
            "notDelete": false
          },
          {
            "id": 42,
            "serviceTypeId": 2,
            "patientGroup": "555",
            "sequence": 8,
            "notDelete": false
          },
          {
            "id": 43,
            "serviceTypeId": 2,
            "patientGroup": "777",
            "sequence": 9,
            "notDelete": false
          }
        ],
        "klarityServiceTypeLimitationList": [
          {
            "id": 8,
            "serviceTypeId": 2,
            "limitation": "Cannot see patients currently pregnant",
            "sequence": 1,
            "notDelete": true
          },
          {
            "id": 11,
            "serviceTypeId": 2,
            "limitation": "Cannot see patients with certain medical conditions: heart issues",
            "sequence": 2,
            "notDelete": true
          },
          {
            "id": 9,
            "serviceTypeId": 2,
            "limitation": "Cannot treat patients with active suicidal behaviour or a history of suicide attempts or self-harm.",
            "sequence": 3,
            "notDelete": true
          },
          {
            "id": 12,
            "serviceTypeId": 2,
            "limitation": "Patients in addiction recovery must provide a confirmed sobriety date.",
            "sequence": 4,
            "notDelete": true
          },
          {
            "id": 10,
            "serviceTypeId": 2,
            "limitation": "Unable to see patients with recent mental health hospitalizations",
            "sequence": 5,
            "notDelete": true
          },
          {
            "id": 13,
            "serviceTypeId": 2,
            "limitation": "Unable to treat patients diagnosed with eating disorders.",
            "sequence": 6,
            "notDelete": true
          }
        ]
      },
      {
        "id": 3,
        "serviceType": "Established patient visit",
        "displayName": "🔄 Established patient visit",
        "description": "A follow-up visit with a provider involves checking the progress of the patient's treatment plan, assessing any new symptoms or ",
        "mandatory": false,
        "newPatient": true,
        "switchTeleHealthInPerson": true,
        "teleHealth": true,
        "inPerson": true,
        "defaultDuration": 45,
        "minDuration": 10,
        "maxDuration": 60,
        "switchPatientGroupLimitation": true,
        "qtyPatientGroup": 8,
        "qtyLimitation": 7,
        "createAt": "2024-05-08T14:30:06.000+00:00",
        "createBy": 0,
        "updateAt": "2024-05-13T06:22:52.000+00:00",
        "updateBy": 14,
        "klarityServiceTypePatientGroupList": [
          {
            "id": 16,
            "serviceTypeId": 3,
            "patientGroup": "Adolescents (13-17 years old)",
            "sequence": 0,
            "notDelete": false
          },
          {
            "id": 17,
            "serviceTypeId": 3,
            "patientGroup": "Adults (18-65 years old)",
            "sequence": 0,
            "notDelete": false
          },
          {
            "id": 15,
            "serviceTypeId": 3,
            "patientGroup": "Children (5-12 years old)",
            "sequence": 0,
            "notDelete": false
          },
          {
            "id": 19,
            "serviceTypeId": 3,
            "patientGroup": "Couples",
            "sequence": 0,
            "notDelete": false
          },
          {
            "id": 20,
            "serviceTypeId": 3,
            "patientGroup": "Family",
            "sequence": 0,
            "notDelete": false
          },
          {
            "id": 21,
            "serviceTypeId": 3,
            "patientGroup": "Group session",
            "sequence": 0,
            "notDelete": false
          },
          {
            "id": 18,
            "serviceTypeId": 3,
            "patientGroup": "Seniors (65+ years old)",
            "sequence": 0,
            "notDelete": false
          }
        ],
        "klarityServiceTypeLimitationList": [
          {
            "id": 14,
            "serviceTypeId": 3,
            "limitation": "Cannot see patients currently pregnant",
            "sequence": 0,
            "notDelete": true
          },
          {
            "id": 17,
            "serviceTypeId": 3,
            "limitation": "Cannot see patients with certain medical conditions: heart issues",
            "sequence": 0,
            "notDelete": true
          },
          {
            "id": 15,
            "serviceTypeId": 3,
            "limitation": "Cannot treat patients with active suicidal behaviour or a history of suicide attempts or self-harm.",
            "sequence": 0,
            "notDelete": true
          },
          {
            "id": 18,
            "serviceTypeId": 3,
            "limitation": "Patients in addiction recovery must provide a confirmed sobriety date.",
            "sequence": 0,
            "notDelete": true
          },
          {
            "id": 16,
            "serviceTypeId": 3,
            "limitation": "Unable to see patients with recent mental health hospitalizations",
            "sequence": 0,
            "notDelete": true
          },
          {
            "id": 19,
            "serviceTypeId": 3,
            "limitation": "Unable to treat patients diagnosed with eating disorders.",
            "sequence": 0,
            "notDelete": true
          }
        ]
      },
      {
        "id": 4,
        "serviceType": "Prescription service",
        "displayName": "📃 Prescription service ",
        "description": "Prescription Fulfillment enables providers to prescribe medications efficiently, ensuring patients receive the correct treatment promptly.",
        "mandatory": true,
        "newPatient": false,
        "switchTeleHealthInPerson": false,
        "teleHealth": false,
        "inPerson": false,
        "defaultDuration": 0,
        "minDuration": 0,
        "maxDuration": 0,
        "switchPatientGroupLimitation": true,
        "qtyPatientGroup": 8,
        "qtyLimitation": 7,
        "createAt": "2024-05-08T14:30:06.000+00:00",
        "createBy": 0,
        "updateAt": "2024-10-31T09:47:19.000+00:00",
        "updateBy": 43,
        "klarityServiceTypePatientGroupList": [
          {
            "id": 36,
            "serviceTypeId": 4,
            "patientGroup": "add group Test",
            "sequence": 1,
            "notDelete": false
          },
          {
            "id": 23,
            "serviceTypeId": 4,
            "patientGroup": "Adolescents (13-17 years old)",
            "sequence": 2,
            "notDelete": false
          },
          {
            "id": 24,
            "serviceTypeId": 4,
            "patientGroup": "Adults (18-65 years old)",
            "sequence": 3,
            "notDelete": false
          },
          {
            "id": 22,
            "serviceTypeId": 4,
            "patientGroup": "Children (5-12 years old)",
            "sequence": 4,
            "notDelete": false
          },
          {
            "id": 26,
            "serviceTypeId": 4,
            "patientGroup": "Couples",
            "sequence": 5,
            "notDelete": false
          },
          {
            "id": 27,
            "serviceTypeId": 4,
            "patientGroup": "Family",
            "sequence": 6,
            "notDelete": false
          },
          {
            "id": 28,
            "serviceTypeId": 4,
            "patientGroup": "Group session",
            "sequence": 7,
            "notDelete": false
          },
          {
            "id": 25,
            "serviceTypeId": 4,
            "patientGroup": "Seniors (65+ years old)",
            "sequence": 8,
            "notDelete": false
          }
        ],
        "klarityServiceTypeLimitationList": [
          {
            "id": 20,
            "serviceTypeId": 4,
            "limitation": "Cannot see patients currently pregnant",
            "sequence": 0,
            "notDelete": true
          },
          {
            "id": 23,
            "serviceTypeId": 4,
            "limitation": "Cannot see patients with certain medical conditions: heart issues",
            "sequence": 0,
            "notDelete": true
          },
          {
            "id": 21,
            "serviceTypeId": 4,
            "limitation": "Cannot treat patients with active suicidal behaviour or a history of suicide attempts or self-harm.",
            "sequence": 0,
            "notDelete": true
          },
          {
            "id": 24,
            "serviceTypeId": 4,
            "limitation": "Patients in addiction recovery must provide a confirmed sobriety date.",
            "sequence": 0,
            "notDelete": true
          },
          {
            "id": 22,
            "serviceTypeId": 4,
            "limitation": "Unable to see patients with recent mental health hospitalizations",
            "sequence": 0,
            "notDelete": true
          },
          {
            "id": 25,
            "serviceTypeId": 4,
            "limitation": "Unable to treat patients diagnosed with eating disorders.",
            "sequence": 0,
            "notDelete": true
          }
        ]
      },
      {
        "id": 5,
        "serviceType": "Other patient support",
        "displayName": "📝 Other patient support",
        "description": "Explore a variety of support services tailored to your needs, including help with documentation, lab test prescriptions, and spe",
        "mandatory": false,
        "newPatient": false,
        "switchTeleHealthInPerson": false,
        "teleHealth": false,
        "inPerson": false,
        "defaultDuration": 1,
        "minDuration": 1,
        "maxDuration": 1,
        "switchPatientGroupLimitation": true,
        "qtyPatientGroup": 10,
        "qtyLimitation": 10,
        "createAt": "2024-05-08T14:30:06.000+00:00",
        "createBy": 0,
        "updateAt": "2024-05-15T06:13:56.000+00:00",
        "updateBy": 17,
        "klarityServiceTypePatientGroupList": [
          {
            "id": 30,
            "serviceTypeId": 5,
            "patientGroup": "Adolescents (13-17 years old)",
            "sequence": 1,
            "notDelete": false
          },
          {
            "id": 31,
            "serviceTypeId": 5,
            "patientGroup": "Adults (18-65 years old)",
            "sequence": 2,
            "notDelete": false
          },
          {
            "id": 29,
            "serviceTypeId": 5,
            "patientGroup": "Children (5-12 years old)",
            "sequence": 3,
            "notDelete": false
          },
          {
            "id": 33,
            "serviceTypeId": 5,
            "patientGroup": "Couples",
            "sequence": 4,
            "notDelete": false
          },
          {
            "id": 34,
            "serviceTypeId": 5,
            "patientGroup": "Family",
            "sequence": 5,
            "notDelete": false
          },
          {
            "id": 35,
            "serviceTypeId": 5,
            "patientGroup": "Group session",
            "sequence": 6,
            "notDelete": false
          },
          {
            "id": 32,
            "serviceTypeId": 5,
            "patientGroup": "Seniors (65+ years old)",
            "sequence": 7,
            "notDelete": false
          },
          {
            "id": 38,
            "serviceTypeId": 5,
            "patientGroup": "123",
            "sequence": 8,
            "notDelete": false
          },
          {
            "id": 41,
            "serviceTypeId": 5,
            "patientGroup": "000",
            "sequence": 9,
            "notDelete": false
          }
        ],
        "klarityServiceTypeLimitationList": [
          {
            "id": 26,
            "serviceTypeId": 5,
            "limitation": "Cannot see patients currently pregnant",
            "sequence": 1,
            "notDelete": true
          },
          {
            "id": 29,
            "serviceTypeId": 5,
            "limitation": "Cannot see patients with certain medical conditions: heart issues",
            "sequence": 2,
            "notDelete": true
          },
          {
            "id": 27,
            "serviceTypeId": 5,
            "limitation": "Cannot treat patients with active suicidal behaviour or a history of suicide attempts or self-harm.",
            "sequence": 3,
            "notDelete": true
          },
          {
            "id": 30,
            "serviceTypeId": 5,
            "limitation": "Patients in addiction recovery must provide a confirmed sobriety date.",
            "sequence": 4,
            "notDelete": true
          },
          {
            "id": 28,
            "serviceTypeId": 5,
            "limitation": "Unable to see patients with recent mental health hospitalizations",
            "sequence": 5,
            "notDelete": true
          },
          {
            "id": 31,
            "serviceTypeId": 5,
            "limitation": "Unable to treat patients diagnosed with eating disorders.",
            "sequence": 6,
            "notDelete": true
          },
          {
            "id": 1731420167,
            "serviceTypeId": 5,
            "limitation": "q",
            "sequence": 7,
            "notDelete": false
          },
          {
            "id": 1731420168,
            "serviceTypeId": 5,
            "limitation": "i",
            "sequence": 8,
            "notDelete": false
          },
          {
            "id": 1731420169,
            "serviceTypeId": 5,
            "limitation": "e",
            "sequence": 9,
            "notDelete": false
          }
        ]
      },
      {
        "id": 6,
        "serviceType": "Medications",
        "displayName": "💉 Medications",
        "description": "A prescription service appointment with a provider typically involves a brief review of his/her existing patient's medical history, confirming the need for the prescription service, and issuing a new prescription. It may also involve evaluating any side effects or concerns related to the medication and ensuring that the treatment plan is still appropriate for the patient's needs.",
        "mandatory": false,
        "newPatient": false,
        "switchTeleHealthInPerson": false,
        "teleHealth": false,
        "inPerson": false,
        "defaultDuration": 1,
        "minDuration": 1,
        "maxDuration": 1,
        "switchPatientGroupLimitation": true,
        "qtyPatientGroup": 10,
        "qtyLimitation": 10,
        "createAt": "2024-05-08T14:30:06.000+00:00",
        "createBy": 0,
        "updateAt": "2024-05-15T06:13:56.000+00:00",
        "updateBy": 17,
        "klarityServiceTypePatientGroupList": [

        ],
        "klarityServiceTypeLimitationList": [

        ]
      }
    ],
    "recommended": false,
    "programId": 1,
    "programEnrolled": false,
    "have": false,
    "program": true
  }
];

export const mockMedications: TMedication[] = [
  {
    id: 1,
    name: 'Medication name 1',
    pharmacyName: 'Pharmacy 1',
    brand: 'Brand 1',
    dosage: '0.5mg',
    price: 100,
  },
  {
    id: 2,
    name: 'Medication name 2',
    pharmacyName: 'Pharmacy 2',
    brand: 'Brand 2',
    dosage: '0.6mg',
    price: 120,
  }
]

export const mockServerIdStateMap: Record<string, ServerMeta[]> = { "1": [{ "id": 135, "type": "STATE", "dictKey": "CA", "content": "California", "description": null }, { "id": 163, "type": "STATE", "dictKey": "NY", "content": "New York", "description": null }], "2": [{ "id": 135, "type": "STATE", "dictKey": "CA", "content": "California", "description": null }, { "id": 163, "type": "STATE", "dictKey": "NY", "content": "New York", "description": null }], "3": [{ "id": 135, "type": "STATE", "dictKey": "CA", "content": "California", "description": null }, { "id": 163, "type": "STATE", "dictKey": "NY", "content": "New York", "description": null }], "4": [{ "id": 135, "type": "STATE", "dictKey": "CA", "content": "California", "description": null }, { "id": 163, "type": "STATE", "dictKey": "NY", "content": "New York", "description": null }], "5": [{ "id": 135, "type": "STATE", "dictKey": "CA", "content": "California", "description": null }, { "id": 163, "type": "STATE", "dictKey": "NY", "content": "New York", "description": null }], "6": [{ "id": 135, "type": "STATE", "dictKey": "CA", "content": "California", "description": null }, { "id": 163, "type": "STATE", "dictKey": "NY", "content": "New York", "description": null }], "7": [{ "id": 135, "type": "STATE", "dictKey": "CA", "content": "California", "description": null }, { "id": 163, "type": "STATE", "dictKey": "NY", "content": "New York", "description": null }], "8": [{ "id": 135, "type": "STATE", "dictKey": "CA", "content": "California", "description": null }, { "id": 163, "type": "STATE", "dictKey": "NY", "content": "New York", "description": null }] };




export const mockConfigProgram: TKlarityProgramItem[] = [
  {
    "id": 1,
    "programName": "Compounded Semaglutide Kickstart Plan",
    "specialtyName": "Weight loss",
    "specialtyId": 101,
    "description": "An advanced program focusing on heart and circulatory system health.",
    "excludeState": "CA",
    "detailInfo": "This program offers an in-depth approach to cardiology with hands-on experience and expert guidance.",
    "highlightInfo": "Hands-on labs, expert speakers, and the latest research insights.",
    "programStatus": "A",
    "isDeleted": false,
    "createAt": "2024-10-29T11:23:58",
    "createBy": 43,
    "updateAt": "2024-10-31T06:24:52",
    "updateBy": 43,
    "klarityProgramPackageList": null,
    "klarityProgramKeyAdvantageList": null,
    "states": 'CA',
    "enrolled": false,
    "displayStatus": 'A',
    'packages': [
      {
        "id": 1,
        "programId": 1,
        "packageTitle": "Sample Package-update-test",
        "description": "This is a sample health package description.",
        "introductionVideoLink": "https://example.com/video.mp4",
        "billingFrequencyType": "Monthly",
        "billingPeriod": 3,
        "price": 199.99,
        "differentFirstPrice": true,
        "firstPaymentPrice": 88.90,
        "providerEarning": 50.00,
        "totalPrice": 488.88,
        "packageStatus": "A",
        "isKlarityDisplay": true,
        "patientFacingTitle": "Sample Package for You",
        "firstPaymentDuration": 1,
        "priority": 2,
        "isDeleted": false,
        "createAt": "2024-10-29T15:01:56",
        "createBy": 43,
        "updateAt": "2024-10-29T15:02:03",
        "updateBy": 43,
        "klarityProgramPackageExternalInfo": null,
        "klarityProgramPackageSessionItemList": null,
        "klarityProgramMedicationItemList": null,
        "klarityProgramPackageTagItemList": null
      },
      {
        "id": 2,
        "programId": 1,
        "packageTitle": "Sample 2 Package-update-test",
        "description": "This is a sample health package description.",
        "introductionVideoLink": "https://example.com/video.mp4",
        "billingFrequencyType": "Monthly",
        "billingPeriod": 3,
        "price": 199.99,
        "differentFirstPrice": false,
        "firstPaymentPrice": 88.90,
        "providerEarning": 50.00,
        "totalPrice": 488.88,
        "packageStatus": "A",
        "isKlarityDisplay": true,
        "patientFacingTitle": "Sample Package for You",
        "firstPaymentDuration": 1,
        "priority": 2,
        "isDeleted": false,
        "createAt": "2024-10-29T15:01:56",
        "createBy": 43,
        "updateAt": "2024-10-29T15:02:03",
        "updateBy": 43,
        "klarityProgramPackageExternalInfo": null,
        "klarityProgramPackageSessionItemList": null,
        "klarityProgramMedicationItemList": null,
        "klarityProgramPackageTagItemList": null
      },
    ]
  },
  {
    "id": 2,
    "programName": "Mental Wellness Initiative",
    "specialtyName": "Infections",
    "specialtyId": 202,
    "description": "A program dedicated to promoting mental wellness through psychological research and therapy techniques.",
    "excludeState": "CA,NY",
    "detailInfo": "This program includes sessions on therapy methods, psychological assessments, and mental health awareness.",
    "highlightInfo": "Personalized therapy sessions, access to mental health resources, and expert-led workshops.",
    "programStatus": "A",
    "isDeleted": false,
    "createAt": "2024-10-29T11:26:58",
    "createBy": 43,
    "updateAt": "2024-10-31T06:50:51",
    "updateBy": 43,
    "klarityProgramPackageList": null,
    "klarityProgramKeyAdvantageList": null,
    "states": 'CA,NY',
    "enrolled": false,
    "displayStatus": 'A',
    'packages': [
      {
        "id": 1,
        "programId": 1,
        "packageTitle": "Sample Package-update-test",
        "description": "This is a sample health package description.",
        "introductionVideoLink": "https://example.com/video.mp4",
        "billingFrequencyType": "Monthly",
        "billingPeriod": 3,
        "price": 199.99,
        "differentFirstPrice": true,
        "firstPaymentPrice": 88.90,
        "providerEarning": 50.00,
        "totalPrice": 488.88,
        "packageStatus": "A",
        "isKlarityDisplay": true,
        "patientFacingTitle": "Sample Package for You",
        "firstPaymentDuration": 1,
        "priority": 2,
        "isDeleted": false,
        "createAt": "2024-10-29T15:01:56",
        "createBy": 43,
        "updateAt": "2024-10-29T15:02:03",
        "updateBy": 43,
        "klarityProgramPackageExternalInfo": null,
        "klarityProgramPackageSessionItemList": null,
        "klarityProgramMedicationItemList": null,
        "klarityProgramPackageTagItemList": null
      },
      {
        "id": 2,
        "programId": 1,
        "packageTitle": "Sample 2 Package-update-test",
        "description": "This is a sample health package description.",
        "introductionVideoLink": "https://example.com/video.mp4",
        "billingFrequencyType": "Monthly",
        "billingPeriod": 3,
        "price": 199.99,
        "differentFirstPrice": false,
        "firstPaymentPrice": 88.90,
        "providerEarning": 50.00,
        "totalPrice": 488.88,
        "packageStatus": "A",
        "isKlarityDisplay": true,
        "patientFacingTitle": "Sample Package for You",
        "firstPaymentDuration": 1,
        "priority": 2,
        "isDeleted": false,
        "createAt": "2024-10-29T15:01:56",
        "createBy": 43,
        "updateAt": "2024-10-29T15:02:03",
        "updateBy": 43,
        "klarityProgramPackageExternalInfo": null,
        "klarityProgramPackageSessionItemList": null,
        "klarityProgramMedicationItemList": null,
        "klarityProgramPackageTagItemList": null
      },
    ]
  }
]

export const mockConfigProgramDetail: TKlarityProgramItemDetail = {
  "id": 2,
  "programName": "Mental Wellness Initiative",
  "specialtyName": "Infections",
  "specialtyId": 202,
  "description": "A program dedicated to promoting mental wellness through psychological research and therapy techniques.",
  "excludeState": "AL,AK",
  "detailInfo": "This program includes sessions on therapy methods, psychological assessments, and mental health awareness.",
  "highlightInfo": "Personalized therapy sessions, access to mental health resources, and expert-led workshops.",
  "programStatus": "A",
  "isDeleted": false,
  "deletedAt": null,
  "createAt": "2024-10-29T11:26:58",
  "createBy": 43,
  "updateAt": "2024-10-31T08:13:18",
  "updateBy": 43,
  "contractUrl": "https://saas-dev-jp.s3.ap-northeast-1.amazonaws.com/dev/d5808646-7d0d-4192-a807-1960a675e539.html",
  "klarityProgramPackageList": [],
  "klarityProgramKeyAdvantageList": [
    {
      "id": 4,
      "programId": 2,
      "title": "Personalized Therapy",
      "content": "Individual therapy sessions tailored to participant needs.",
      "priority": 1,
      "isDeleted": false,
      "deletedAt": null,
      "createAt": "2024-10-29T11:26:59",
      "createBy": 43,
      "updateAt": "2024-10-29T11:26:58",
      "updateBy": 43
    },
    {
      "id": 5,
      "programId": 2,
      "title": "Resource Access",
      "content": "Gain access to extensive mental health resources and materials.",
      "priority": 2,
      "isDeleted": false,
      "deletedAt": null,
      "createAt": "2024-10-29T11:26:59",
      "createBy": 43,
      "updateAt": "2024-10-29T11:26:59",
      "updateBy": 43
    },
    {
      "id": 6,
      "programId": 2,
      "title": "Expert Workshops",
      "content": "Workshops led by top psychologists and mental health professionals.",
      "priority": 3,
      "isDeleted": false,
      "deletedAt": null,
      "createAt": "2024-10-29T11:26:59",
      "createBy": 43,
      "updateAt": "2024-10-29T11:26:59",
      "updateBy": 43
    }
  ]
}

export const mockFaq: TFaqInfo[] = [
  {
    id: 1,
    question: 'If I am diagnosed, can I get a record of my diagnosis?',
    answer: "Yes, after your first visit, your provider can share your visit note (with diagnosis, treatment plan if applicable, and provider's signature) with you via your secured patient portal. ",
    createAt: '2024-05-08T09:21:33.000+00:00',
    createBy: 43,
    updateAt: '2024-05-13T07:50:58.000+00:00',
    updateBy: 43,
    pageId: 1,
    seq: 1,
  },
  {
    id: 2,
    question: 'Question 2',
    answer: 'Answer 2',
    createAt: '2024-05-08T09:21:33.000+00:00',
    createBy: 43,
    updateAt: '2024-05-13T07:50:58.000+00:00',
    updateBy: 43,
    pageId: 1,
    seq: 2,
  },
  {
    id: 3,
    question: 'Question 3',
    answer: 'Answer 3',
    createAt: '2024-05-08T09:21:33.000+00:00',
    createBy: 43,
    updateAt: '2024-05-13T07:50:58.000+00:00',
    updateBy: 43,
    pageId: 1,
    seq: 3,
  },
  {
    id: 4,
    question: 'Question 4',
    answer: 'Answer 4',
    createAt: '2024-05-08T09:21:33.000+00:00',
    createBy: 43,
    updateAt: '2024-05-13T07:50:58.000+00:00',
    updateBy: 43,
    pageId: 1,
    seq: 4,
  },
]