import TinyStore from 'lib/tinyStore';

type GlobalNotificationStatus = {
    data: boolean
};

const GlobalNotificationStatusStore = TinyStore.createStore<GlobalNotificationStatus>({
    nameSpace: 'klarity',
    key: 'globalNotification',
    crossBundle: false,
    runTime: true,
    initValue: { data: false },
});

export default GlobalNotificationStatusStore;
