import React from 'react';
import s from './s.module.less';
import { ServerMeta } from 'types/common';
import { TServiceSetting } from 'types/helloKlarity';

type IProps = {
    isMobileMode: boolean;
    previewList?: TServiceSetting[];
    state?: ServerMeta;
    condition?: ServerMeta;
};

const ServicePreview = (props: IProps) => {
    const { isMobileMode, previewList, state, condition } = props;

    return (
        <div className={isMobileMode ? s.previewMobile : s.previewPc}>
            <div className={s.wrapIn}>
                <div className={s.wrapHeader}>
                    <h2 className={s.title}>Find care for your health needs</h2>
                    <div className={s.link}>View all services</div>
                </div>
                <div className={s.serviceList}>
                    {previewList?.map((item) => {
                        return (
                            <div className={s.serviceItem}>
                                <img
                                    src={item.image}
                                    alt={item.servicePage}
                                    className={s.serviceImg}
                                />
                                <p className={s.serviceName}>{item.subtext}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default ServicePreview;
