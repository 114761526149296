import React, { useCallback } from "react";
import s from "./s.module.less";
import { Form, Input, InputNumber, Checkbox, Select, Button, Radio, message } from "antd";
import commonS from 'styles/common.module.less';
import { addNUpdateKlarityMedication } from "api/operation/programs";
import { sourceType } from "constants/program";

interface IProps {
    setMedicationSubmiting: (value: boolean) => void;
    onSubmitSuccess?: () => void;
    programId: number;
    packageId: number;
}

const MedicationModal = ({
    setMedicationSubmiting,
    onSubmitSuccess,
    programId,
    packageId,
}: IProps) => {
    const [form] = Form.useForm();
    const brandOptions = [
        {
            label: 'Branded',
            value: 1
        },
        {
            label: 'Non-brand',
            value: 0
        }
    ]

    const handleFinish = useCallback(async (values) => {
        setMedicationSubmiting(true);

        values.medications.forEach(async (value) => {
            const submitData = {
                ...value,
                isBranded: value.brand === 1,
                sourceType: sourceType.PROGRAM_WL,
                sourceId: programId,
                medicationInfo: value.note, // ... 这里是不是和note重复了？
                isDeleted: false, // ... 新增的时候这个字段是不是多余的？
            }
            try {
                const res = await addNUpdateKlarityMedication(submitData);
    
                if (res && !res.error) {
                    onSubmitSuccess?.();
                } else {
                    message.error(res.error)
                }
            } catch (e) {
                console.error(e)
            }
        });

        setMedicationSubmiting(false);
    }, [programId]);

    const handleSubmitFailed = useCallback(() => {
        // console.info('error')
    }, []);

    return (
        <div className={s.wrap}>
            <Form
                name="medicationForm"
                className={commonS.formStyle1}
                layout="vertical"
                form={form}
                onFinish={handleFinish}
                onFinishFailed={handleSubmitFailed}
            >
                <Form.List name="medications" initialValue={[{}]}>
                {
                    (fields, { add, remove }) => {
                        return (
                            <>
                                {
                                    fields.map(({ key, name, ...restField }, index) => {
                                        const label = `Medication ${index + 1}`;
                                        return (
                                            <div key={index} className={s.additionalBox}>
                                                <div className={s.additionalForm}>
                                                    <div className={s.formBody}>
                                                        <div className={s.row}>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="Medication name"
                                                                    name={[name, 'medicationName']}
                                                                    rules={[{ required: true, message: 'Medication name is required' }]}
                                                                >
                                                                    <Input />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="Fulfillment pharmacy name"
                                                                    name={[name, 'fulfillmentPharmacyName']}
                                                                    rules={[{ required: true, message: 'Fulfillment pharmacy name is required' }]}
                                                                >
                                                                    <Input />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="Brand"
                                                                    name={[name, 'brand']}
                                                                    rules={[{ required: true, message: 'Brand is required' }]}
                                                                >
                                                                    <Radio.Group options={brandOptions} />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    style={{ width: '200px' }}
                                                                    {...restField}
                                                                    label="Dosage"
                                                                    name={[name, 'dosage']}
                                                                    rules={[{ required: true, message: 'Dosage is required' }]}
                                                                >
                                                                    <InputNumber addonAfter="mg" />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="Price"
                                                                    name={[name, 'price']}
                                                                    rules={[{ required: true, message: 'Price is required' }]}
                                                                >
                                                                    <InputNumber addonBefore="$" addonAfter="USD" />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="Provide detailed information about the medications to patients"
                                                                    name={[name, 'note']}
                                                                >
                                                                    <Input.TextArea />
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <div className={`${s.labelBox} ${s.verticalLabelBox}`}>
                                                                    <div className={s.label}>Cold storage</div>
                                                                    <div className={s.subLabel}>Does the mediation require a specific temperature range during storage and transportation to ensure efficacy and safety?</div>
                                                                </div>
                                                                <Form.Item
                                                                    {...restField}
                                                                    // label="Provide detailed information about the medications to patients"
                                                                    name={[name, 'isColdStorage']}
                                                                >
                                                                    <Radio.Group>
                                                                        <Radio value={1}>Yes</Radio>
                                                                        <Radio value={2}>No</Radio>
                                                                    </Radio.Group>
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                        <div className={s.row}>
                                                            <div
                                                                className={s.displayItem}
                                                            >
                                                                <Form.Item
                                                                    {...restField}
                                                                    label="Shipping restriction"
                                                                    name={[name, 'shippingRestriction']}
                                                                >
                                                                    <Input.TextArea placeholder="Please add shipping restrictions here, for example any states or zones prohibited."/>
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={s.formFooter}>
                                                        <Button onClick={() => remove(index)}>Remove</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                                <Form.Item>
                                    <div className={s.additionalTitle} onClick={() => add()}>+ Add another medication</div>
                                </Form.Item>
                            </>
                        );
                    }
                }
                </Form.List>
            </Form>
        </div>
    )
}

export default MedicationModal;
