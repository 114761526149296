import React from 'react';
import { Button } from 'antd';
import { getGoogleSheet } from 'api/operation/reviews';
import s from './s.module.less';

const ExportOutlined = () => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g id="External link">
            <g id="Icon">
                <path
                    d="M8.80039 2.40039C8.35856 2.40039 8.00039 2.75856 8.00039 3.20039C8.00039 3.64222 8.35856 4.00039 8.80039 4.00039H10.869L5.83471 9.03471C5.52229 9.34713 5.52229 9.85366 5.83471 10.1661C6.14712 10.4785 6.65366 10.4785 6.96608 10.1661L12.0004 5.13176V7.20039C12.0004 7.64222 12.3586 8.00039 12.8004 8.00039C13.2422 8.00039 13.6004 7.64222 13.6004 7.20039V3.20039C13.6004 2.75856 13.2422 2.40039 12.8004 2.40039H8.80039Z"
                    fill="#6B7280"
                />
                <path
                    d="M4.00039 4.00039C3.11674 4.00039 2.40039 4.71673 2.40039 5.60039V12.0004C2.40039 12.884 3.11673 13.6004 4.00039 13.6004H10.4004C11.284 13.6004 12.0004 12.884 12.0004 12.0004V9.60039C12.0004 9.15856 11.6422 8.80039 11.2004 8.80039C10.7586 8.80039 10.4004 9.15856 10.4004 9.60039V12.0004H4.00039V5.60039L6.40039 5.60039C6.84222 5.60039 7.20039 5.24222 7.20039 4.80039C7.20039 4.35856 6.84222 4.00039 6.40039 4.00039H4.00039Z"
                    fill="#6B7280"
                />
            </g>
        </g>
    </svg>
);

type IProps = {
    sheetType: string;
    text?: string
};

const Index: React.FC<IProps> = ({ sheetType, text }) => {
    const openLink = async () => {
        const { data } = await getGoogleSheet(sheetType);
        if (data?.data) {
            window.open(data?.data);
        } else {
            alert('url not found');
        }
    };
    return (
        <Button
            icon={<ExportOutlined />}
            className={s.button}
            onClick={() => openLink()}
        >
            {text || 'Open in Google sheet'}
        </Button>
    );
};

export default Index;
