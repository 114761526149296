import TinyStore from 'lib/tinyStore';

type GlobalEditStatusModal = {
    globalEditStatus: boolean
};

const GlobalFormsEditStatusStore = TinyStore.createStore<GlobalEditStatusModal>({
    nameSpace: 'klarity',
    key: 'channelApplicationFormsEditStatus',
    crossBundle: false,
    runTime: true,
    initValue: { globalEditStatus: false },
});

export default GlobalFormsEditStatusStore;
