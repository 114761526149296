// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__modalTitle--pgEDE {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.s-module__module--u1B0f {
  background: #fff;
  height: auto;
}
.s-module__module--u1B0f .s-module__row--ENKSc {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 16px;
}
.s-module__module--u1B0f .s-module__row--ENKSc .s-module__displayItem--cIrbs {
  margin-bottom: 12px;
}
.s-module__module--u1B0f .s-module__row--ENKSc .s-module__displayItem--cIrbs .s-module__label--peV_t {
  color: #6B7280;
  font-size: 14px;
  line-height: 20px;
}
.s-module__module--u1B0f .s-module__rowItem--nTenH {
  margin-right: 36px;
}
.s-module__module--u1B0f .s-module__colItem--MOZbo {
  display: flex;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/pages/operation/HelloKlarityPageManagement/components/ContentManagement/components/EditContentModal/s.module.less"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,uBAAA;EACA,4BAAA;EACA,qBAAA;EACA,gBAAA;AACF;AAEA;EACE,gBAAA;EACA,YAAA;AAAF;AAFA;EAKI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AAAJ;AARA;EAiBM,mBAAA;AANN;AAXA;EAYQ,cAAA;EACA,eAAA;EACA,iBAAA;AAER;AAhBA;EAsBI,kBAAA;AAHJ;AAnBA;EA0BI,aAAA;EACA,sBAAA;AAJJ","sourcesContent":[".modalTitle {\n  display: -webkit-box;\n  text-overflow: ellipsis;\n  -webkit-box-orient: vertical;\n  -webkit-line-clamp: 1;\n  overflow: hidden;\n}\n\n.module {\n  background: #fff;\n  height: auto;\n\n  .row {\n    display: flex;\n    flex-direction: row;\n    align-items: flex-start;\n    margin-top: 16px;\n\n    .displayItem {\n      .label {\n        color: #6B7280;\n        font-size: 14px;\n        line-height: 20px;\n      }\n\n      margin-bottom: 12px;\n    }\n  }\n\n  .rowItem {\n    margin-right: 36px;\n  }\n\n  .colItem {\n    display: flex;\n    flex-direction: column;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalTitle": `s-module__modalTitle--pgEDE`,
	"module": `s-module__module--u1B0f`,
	"row": `s-module__row--ENKSc`,
	"displayItem": `s-module__displayItem--cIrbs`,
	"label": `s-module__label--peV_t`,
	"rowItem": `s-module__rowItem--nTenH`,
	"colItem": `s-module__colItem--MOZbo`
};
export default ___CSS_LOADER_EXPORT___;
