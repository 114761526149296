// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__formWrap--kIg7v {
  max-width: 300px;
  margin: 0 auto;
}
.s-module__buttonWrap--xwv24 {
  display: flex;
  justify-content: center;
}
.s-module__wrap--sp8Ix .s-module__title--ppSvg {
  font-size: 16px;
  margin: 30px 0 20px;
  text-align: center;
}
.s-module__wrap--sp8Ix .s-module__line--kJ35W {
  width: 100%;
  height: 2px;
  background: #f3f3f3;
  margin: 20px 0;
}
.s-module__wrap--sp8Ix .s-module__listWrap--UWXij {
  width: 300px;
  margin: 0 auto;
}
.s-module__wrap--sp8Ix .s-module__rating--p4mCF {
  width: 40%;
  text-align: center;
  color: #666;
}
.s-module__wrap--sp8Ix .s-module__submitTime--fXJTo {
  flex: 1;
  text-align: center;
  color: #666;
}
.s-module__wrap--sp8Ix .s-module__header--KBCL2 {
  display: flex;
  align-content: center;
  font-weight: bold;
}
.s-module__wrap--sp8Ix .s-module__header--KBCL2 .s-module__rating--p4mCF,
.s-module__wrap--sp8Ix .s-module__header--KBCL2 .s-module__submitTime--fXJTo {
  color: #000;
}
.s-module__tips--_IAgd {
  color: red;
  font-weight: bold;
  margin: 20px 0;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/testing/Review/s.module.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,cAAA;AACJ;AAEA;EACI,aAAA;EACA,uBAAA;AAAJ;AAGA;EAEQ,eAAA;EACA,mBAAA;EACA,kBAAA;AAFR;AAFA;EAOQ,WAAA;EACA,WAAA;EACA,mBAAA;EACA,cAAA;AAFR;AARA;EAaQ,YAAA;EACA,cAAA;AAFR;AAZA;EAiBQ,UAAA;EACA,kBAAA;EACA,WAAA;AAFR;AAjBA;EAsBQ,OAAA;EACA,kBAAA;EACA,WAAA;AAFR;AAtBA;EA2BQ,aAAA;EACA,qBAAA;EACA,iBAAA;AAFR;AA3BA;;EA+BY,WAAA;AAAZ;AAKA;EACI,UAAA;EACA,iBAAA;EACA,cAAA;EACA,kBAAA;AAHJ","sourcesContent":[".formWrap {\n    max-width: 300px;\n    margin: 0 auto;\n}\n\n.buttonWrap {\n    display: flex;\n    justify-content: center;\n}\n\n.wrap {\n    .title {\n        font-size: 16px;\n        margin:30px 0 20px;\n        text-align: center;\n    }\n    .line {\n        width: 100%;\n        height: 2px;\n        background: #f3f3f3;\n        margin: 20px 0;\n    }\n    .listWrap {\n        width: 300px;\n        margin: 0 auto;\n    }\n    .rating {\n        width: 40%;\n        text-align: center;\n        color: #666;\n    }\n    .submitTime {\n        flex: 1;\n        text-align: center;\n        color: #666;\n    }\n    .header {\n        display: flex;\n        align-content: center;\n        font-weight: bold;\n        .rating, .submitTime {\n            color: #000;\n        }\n    }\n}\n\n.tips {\n    color: red;\n    font-weight: bold;\n    margin: 20px 0;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formWrap": `s-module__formWrap--kIg7v`,
	"buttonWrap": `s-module__buttonWrap--xwv24`,
	"wrap": `s-module__wrap--sp8Ix`,
	"title": `s-module__title--ppSvg`,
	"line": `s-module__line--kJ35W`,
	"listWrap": `s-module__listWrap--UWXij`,
	"rating": `s-module__rating--p4mCF`,
	"submitTime": `s-module__submitTime--fXJTo`,
	"header": `s-module__header--KBCL2`,
	"tips": `s-module__tips--_IAgd`
};
export default ___CSS_LOADER_EXPORT___;
