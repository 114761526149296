import React, { useState, useMemo } from 'react';
import { Modal, Form, Input, Button, Tooltip } from 'antd';
import commonS from 'styles/common.module.less';
import s from './s.module.less';
import { klarityConditionUpdate } from 'api/operation';
import { type TObjectAction, type TConditionSetting } from 'types/helloKlarity';
import ConditionSelect from 'components/form/ConditionSelect';
import FileUpload from 'components/form/FileUpload';

type Props = {
    serviceSpecialtyName?: string | string[];
    serviceSpecialtyPriority: number;
    item?: TConditionSetting;
    list: TConditionSetting[];
    onCancel?: () => void;
    onSuccess?: (obj: TObjectAction) => void;
};

const EditCondition = ({
    serviceSpecialtyName,
    serviceSpecialtyPriority,
    item,
    list,
    onCancel,
    onSuccess,
}: Props) => {
    const [submiting, setSubmiting] = useState(false);
    const [form] = Form.useForm<TConditionSetting & { active: boolean }>();
    const isEdit = true;
    const disableConditionList = useMemo(() => {
        return list.map((conditionItem) => {
            return conditionItem.conditionName;
        });
    }, [list]);

    const onSave = async () => {
        setSubmiting(true);
        try {
            const formValues = await form.validateFields();
            const id = item?.id;

            const params: TConditionSetting = {
                serviceSpecialtyName,
                serviceSpecialtyPriority,
                conditionName: formValues.conditionName,
                subtext: formValues.subtext,
                logo: formValues.logo,
                id: item?.id,
                priority: item?.priority ?? 1,
                type: 'HOME',
            };
            if (isEdit) {
                params.id = id;
            }
            const apiFunc = klarityConditionUpdate(params, id!);
            const { data } = await apiFunc;
            if (data.code === 200) {
                onSuccess?.({
                    type: 'CONDITION_EDIT',
                    condition: params,
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            setSubmiting(false);
        }
    };

    React.useEffect(() => {
        if (item) {
            form.setFieldsValue({
                conditionName: item.conditionName,
                subtext: item.subtext,
                logo: item.logo,
            });
        } else {
            form.resetFields();
        }
    }, [item]);

    return (
        <Modal
            title={`Edit ${item?.conditionName}`}
            closable
            className={commonS.modalFixHeightWrap}
            width="500px"
            destroyOnClose
            onCancel={onCancel}
            okText="Save"
            open
            footer={
                <div>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button loading={submiting} type="primary" onClick={onSave}>
                        Save
                    </Button>
                </div>
            }
        >
            <div className={s.wrap}>
                <Form
                    form={form}
                    className={commonS.formStyle1}
                    initialValues={{ active: true }}
                    autoComplete="off"
                    layout="vertical"
                    scrollToFirstError
                >
                    <>
                        <Form.Item
                            className={s.rowItem}
                            label="Condition name"
                            name="conditionName"
                            rules={[
                                { required: true, message: 'Condition name is required' },
                            ]}
                        >
                            <ConditionSelect
                                isLabel
                                style={{ width: '150px' }}
                                disableValues={disableConditionList}
                            />
                        </Form.Item>

                        <Form.Item
                            className={s.rowItem}
                            label="Subtext"
                            name="subtext"
                            rules={[
                                { required: true, message: 'Subtext is required' },
                            ]}
                        >
                            <Input.TextArea
                                maxLength={200}
                                style={{ width: '100%' }}
                                rows={3}
                                showCount
                            />
                        </Form.Item>
                        <Form.Item
                            className={s.rowItem}
                            label={
                                <div className={s.formLabel}>
                                    Logo
                                    <Tooltip
                                        overlayClassName={s.tipCard}
                                        overlayInnerStyle={{ padding: '12px' }}
                                        title={
                                            <div className={s.tipText}>
                                                Image should have a transparent background and be sized
                                                118x118 pixels.
                                            </div>
                                        }
                                    >
                                        <div className={s.questionMarkIcon} />
                                    </Tooltip>
                                </div>
                            }
                            name={['logo']}
                            rules={[{ required: true, message: 'Image is required' }]}
                        >
                            <FileUpload />
                        </Form.Item>
                    </>
                </Form>
            </div>
        </Modal>
    );
};

export default EditCondition;
