import TinyStore from 'lib/tinyStore';

const initData = [] as Array<string>;

type OpsRoleModal = {
    data: Array<string>;
};

const Store = TinyStore.createStore<OpsRoleModal>({
    nameSpace: 'klarity',
    key: 'access_role',
    crossBundle: false,
    runTime: true,
    initValue: { data: initData },
});

export default Store;
