import React, {
    useRef,
    forwardRef,
    useImperativeHandle,
} from 'react';
import type { TAssessmentItem } from 'types/operation';
import IntroCard, { IntroCardA } from '../IntroCard';
import TestOverview, { IntroCardB } from '../TestOverview';

type IProps = {
    goLiveObj: TAssessmentItem;
    isLoading: boolean;
    onSuccess: (item: Partial<TAssessmentItem>) => void;
};

export interface IntroComponentRef {
    validateAndSubmit: () => boolean;
}

const IntroModule = forwardRef<IntroComponentRef, IProps>((props, ref) => {
    const { goLiveObj, onSuccess } = props;
    const introCardRef = useRef<IntroCardA>(null);
    const testOverviewRef = useRef<IntroCardB>(null);

    useImperativeHandle(ref, () => ({
        validateAndSubmit: () => {
            return introCardRef.current?.validateAndSubmit() && testOverviewRef.current?.validateAndSubmit() || false;
        },
    }));
    return (
        <div>
            <IntroCard item={goLiveObj} onSuccess={onSuccess} ref={introCardRef} />
            <TestOverview
                item={goLiveObj}
                onSuccess={onSuccess}
                ref={testOverviewRef}
            />
        </div>
    );
});

export default IntroModule;
