import { Channel, Service } from './common';
import { TSubscriptionType } from './subscription';

export enum EChannelStatus {
    ALIVE = 'alive',
    UPCOMING = 'upcoming',
    INTEREST = 'interest',
}

export enum ChannelPayPlanTypeEnum {
    FREE = 'free',
    PAID = 'paid',
    PREMIUM = 'premium',
}

export type ChannelBasicInformationFormInterface = {
    name: string;
    logo: string;
    rectangleLogo: string;
    website: string;
    intro: string;
    payPlanType: string;
    avgMonthGainPatient: number;
    kiwiPrice?: number;
    originalPrice?: number;
    billingCycle?: string;
    subscriptionPriceId?: string;
    freeTrialDays?: number;
    reviewAvailable?: boolean;
    reviewRanking?: number;
    klarityPlanLevel?: number[];
};

export type ChannelDetailedInformationFormInterface = {
    name: string;
    channelDesc: string;
    economics: string;
    caseStudy: string;
    news: string;
    tagList: string[]
    systemEmail: string;
};

export type KlarityChannelDetailedInformationFormInterface = {
    name: string;
    klarityChannelDesc: string;
    klarityEconomics: string;
    klarityCaseStudy: string;
    klarityNews: string;
    klarityTagList: string[];
    klaritySystemEmail: string;
};

export enum EProfileStatus {
    REJECTED = 'rejected',
    UPDATE_IN_PROGRESS = 'update-in-progress',
    UNDER_REVIEW = 'under-review',
    UPDATE_SUBMITTED = 'update-submitted',
    APPROVED = 'approved',
    REQUIRED = 'required',
}

export type ChannelHomeInfo = {
    channelCount: number,
    channelGuide: boolean,
    profileStatus: EProfileStatus,
    lastUpdateTime: string,
};

export interface IRecommendChannel extends ChannelBasicInformationFormInterface {
    id: number;
    serviceConfig: string | null;
    channelType: ChannelPayPlanTypeEnum;
    payplantype: ChannelPayPlanTypeEnum | null;
}
export interface ISubmitRecommandChannelInput {
    allowAuth?: boolean;
    channelIdList: Array<number>;
    multServiceList?: Array<Partial<Service>>;
    providerId?: string;
    listingType?: 'MANAGED'
}

export enum EPracticeFrontPreviewPage {
    HOME = 'home',
    CONTACT = 'contact',
    SERVICES = 'services',
    BOOKING = 'booking',
    DIRECT_BOOKING = 'direct_booking',
}

export enum EChannelWithdrawalsStatus {
    Pending = 'Pending',
    Removed = 'Removed',
}

export interface MyChannelsData {
    allList?: Channel[],
    freeList?: Channel[],
    premiumList?: Channel[],
    remainChannelList?: Channel[],
    standardList?: Channel[],
}

export const klarityPlanLevel = [TSubscriptionType.KLARITY_STARTER, TSubscriptionType.KLARITY_PLUS, TSubscriptionType.KLARITY_PRO];

export interface IUpdateAccessInfo {
    providerId: number;
    channelName: string,
    channelId: number,
    account: string,
    password: string,
}
