// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--MjzB1 {
  padding: 22px 28px;
  background-color: #EEF2FF;
  border-radius: 8px;
  font-family: 'Inter';
  font-style: normal;
}
.s-module__wrap--MjzB1 .s-module__title--UxdZ5 {
  margin-bottom: 22px;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: var(--gray-800);
}
.s-module__wrap--MjzB1 .s-module__selectBox--wA1fq {
  margin-bottom: 46px;
}
.s-module__wrap--MjzB1 .s-module__formList--AyuZT {
  display: flex;
  align-items: center;
}
.s-module__wrap--MjzB1 .s-module__formList--AyuZT .s-module__label--ZFa5r {
  color: var(--gray-700, #374151);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  flex-shrink: 0;
  white-space: nowrap;
  margin-right: 6px;
}
.s-module__wrap--MjzB1 .s-module__formList--AyuZT input {
  margin-right: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/operation/ListToChannelDetailPage/components/WaitApproveCard/s.module.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,yBAAA;EACA,kBAAA;EACA,oBAAA;EACA,kBAAA;AACJ;AANA;EAQQ,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AACR;AAbA;EAgBQ,mBAAA;AAAR;AAhBA;EAoBQ,aAAA;EACA,mBAAA;AADR;AApBA;EAuBY,+BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EAAV,aAAa;EACH,cAAA;EACA,mBAAA;EACA,iBAAA;AACZ;AA/BA;EAiCY,kBAAA;AACZ","sourcesContent":[".wrap {\n    padding: 22px 28px;\n    background-color: #EEF2FF;\n    border-radius: 8px;\n    font-family: 'Inter';\n    font-style: normal;\n\n    .title {\n        margin-bottom: 22px;\n        font-weight: 500;\n        font-size: 16px;\n        line-height: 28px;\n        color: var(--gray-800);\n    }\n\n    .selectBox {\n        margin-bottom: 46px;\n    }\n\n    .formList {\n        display: flex;\n        align-items: center;\n        .label {\n            color: var(--gray-700, #374151);\n            font-size: 14px;\n            font-style: normal;\n            font-weight: 500;\n            line-height: 20px; /* 142.857% */\n            flex-shrink: 0;\n            white-space: nowrap;\n            margin-right: 6px;\n        }\n        input {\n            margin-right: 16px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--MjzB1`,
	"title": `s-module__title--UxdZ5`,
	"selectBox": `s-module__selectBox--wA1fq`,
	"formList": `s-module__formList--AyuZT`,
	"label": `s-module__label--ZFa5r`
};
export default ___CSS_LOADER_EXPORT___;
