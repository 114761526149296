import TinyStore from 'lib/tinyStore';

type AlertModal = {
    info: {
        show: boolean;
        message: string | React.ReactNode;
        type: 'success' | 'error' | 'warning' | 'info';
    }
};

const Store = TinyStore.createStore<AlertModal>({
    nameSpace: 'klarity',
    key: 'globalAlert',
    crossBundle: false,
    runTime: true,
    initValue: { info: { show:false, message: '', type: 'success'} },
});
export default Store;
