import TinyStore from 'lib/tinyStore';
import { EPracticeFrontPreviewPage } from 'types/channel';
import { UrlString, LocalSEOInfo } from 'types/common';

type PracticeFrontPreview = {
    currentPage: EPracticeFrontPreviewPage | UrlString;
    isMobile: boolean;
    flag?: Object;
    previewLocalSEO?: LocalSEOInfo[],
    showLocalSEO?: boolean, //only when try mode
    previewContactForm?: boolean,
    serviceStateKey?: string,
    serviceStateTypeKey?: "1" | "2",
    iframeRefreshFlag?: {},
};

const Store = TinyStore.createStore<PracticeFrontPreview>({
    nameSpace: 'klarity',
    key: 'practice_front_preview',
    crossBundle: false,
    runTime: true,
    initValue: { currentPage: EPracticeFrontPreviewPage.HOME, isMobile: false, showLocalSEO: false, previewContactForm: false, serviceStateKey: undefined },
});

export default Store;
