import React from 'react';
import s from './s.module.less';
import PageManagement from './components/PageManagement';
import { PermissionModuleKeys } from 'constants/access';
import AccessDenied from 'components/AccessDenied';
import AccessStore from 'store/Access/permission';

const AssessmentPage = () => {
    const [getAccessStore] = AccessStore.useStore();
    const access = getAccessStore('data');
    return (
        <div className={s.wrap}>
            <div className={s.content}>
                {access?.[PermissionModuleKeys.KLARITY_ASSESSMENT] ? (
                    <PageManagement />
                ) : (
                    <AccessDenied />
                )}
            </div>
        </div>
    );
};

export default AssessmentPage;
