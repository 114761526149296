// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--zWjbw {
  background: var(--main-bg-color);
}
.s-module__wrap--zWjbw h2 {
  color: #111827;
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;
  margin-top: 12px;
}
.s-module__wrap--zWjbw .s-module__filterLabel--NHVsI {
  color: var(--gray-500);
  margin-top: 16px;
  margin-bottom: 12px;
  font-weight: 500;
}
.s-module__wrap--zWjbw .s-module__avatar--yd1pD {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
}
.s-module__wrap--zWjbw .s-module__nameWrap--CllRX {
  display: flex;
  flex-direction: row;
}
.s-module__wrap--zWjbw .s-module__nameWrap--CllRX .s-module__name--IrYuR {
  color: var(--gray-900);
  font-size: 14px;
  line-height: 20px;
}
.s-module__wrap--zWjbw .s-module__nameWrap--CllRX .s-module__email--Zk5I5 {
  color: var(--gray-500);
  font-size: 14px;
  line-height: 20px;
}
.s-module__wrap--zWjbw .s-module__tableWrap--aePpw {
  margin-top: 16px;
}
.s-module__wrap--zWjbw a {
  color: var(--primary-color);
}
.s-module__wrap--zWjbw a:hover {
  text-decoration: underline;
}
.s-module__wrap--zWjbw .s-module__link--jaG08 {
  color: var(--primary-color);
}
.s-module__wrap--zWjbw .s-module__link--jaG08:hover {
  color: var(--primary-color);
  cursor: pointer;
}
.s-module__tag--smblR .ant-tag {
  border-radius: 11px;
  border-color: none !important;
}
.s-module__statusFilter--dn1t4 {
  padding: 12px !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/operation/ProviderPage/components/Onboarding/s.module.less"],"names":[],"mappings":"AAAA;EACE,gCAAA;AACF;AAFA;EAGI,cAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,gBAAA;AAEJ;AATA;EAUI,sBAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;AAEJ;AAfA;EAiBI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;AACJ;AArBA;EAuBI,aAAA;EACA,mBAAA;AACJ;AAzBA;EA0BM,sBAAA;EACA,eAAA;EACA,iBAAA;AAEN;AA9BA;EA+BM,sBAAA;EACA,eAAA;EACA,iBAAA;AAEN;AAnCA;EAqCI,gBAAA;AACJ;AAtCA;EAyCI,2BAAA;AAAJ;AAzCA;EA4CI,0BAAA;AAAJ;AA5CA;EA+CI,2BAAA;AAAJ;AA/CA;EAkDI,2BAAA;EACA,eAAA;AAAJ;AAGA;EAGM,mBAAA;EACA,6BAAA;AAHN;AAQA;EACE,wBAAA;AANF","sourcesContent":[".wrap {\n  background: var(--main-bg-color);\n  h2{\n    color: #111827;\n    font-size: 18px;\n    line-height: 20px;\n    font-weight: bold;\n    margin-top: 12px;\n  }\n  .filterLabel{\n    color: var(--gray-500);\n    margin-top: 16px;\n    margin-bottom: 12px;\n    font-weight: 500;\n  }\n\n  .avatar{\n    width: 40px;\n    height: 40px;\n    border-radius: 50%;\n    margin-right: 12px;\n  }\n  .nameWrap{\n    display: flex;\n    flex-direction: row;\n    .name{\n      color: var(--gray-900);\n      font-size: 14px;\n      line-height: 20px;\n    }\n    .email{\n      color: var(--gray-500);\n      font-size: 14px;\n      line-height: 20px;\n    }\n  }\n  .tableWrap{\n    margin-top: 16px;\n  }\n  \n  a{\n    color: var(--primary-color);\n  }\n  a:hover{\n    text-decoration: underline;\n  }\n  .link{\n    color: var(--primary-color);\n  }\n  .link:hover{\n    color: var(--primary-color);\n    cursor: pointer;\n  }\n}\n.tag{\n  :global{\n    .ant-tag{\n      border-radius: 11px;\n      border-color: none !important;\n    }\n  }\n}\n\n.statusFilter{\n  padding: 12px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--zWjbw`,
	"filterLabel": `s-module__filterLabel--NHVsI`,
	"avatar": `s-module__avatar--yd1pD`,
	"nameWrap": `s-module__nameWrap--CllRX`,
	"name": `s-module__name--IrYuR`,
	"email": `s-module__email--Zk5I5`,
	"tableWrap": `s-module__tableWrap--aePpw`,
	"link": `s-module__link--jaG08`,
	"tag": `s-module__tag--smblR`,
	"statusFilter": `s-module__statusFilter--dn1t4`
};
export default ___CSS_LOADER_EXPORT___;
