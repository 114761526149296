import React, { useCallback, useState } from 'react';
import {
    Dropdown,
    MenuProps,
    Space,
    Spin,
    Tabs,
} from 'antd';
import { ETimeType, TOverviewData } from 'pages/dashboard/KlarityReviewsDashboradPage/types';
import { DownOutlined } from '@ant-design/icons';
import Down from 'pages/dashboard/KlarityReviewsDashboradPage/images/down.svg';
import Up from 'pages/dashboard/KlarityReviewsDashboradPage/images/up.svg';
import { getReviewSummary } from 'api/operation/reviews';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH } from 'constants/path';
import AllReviews from '../components/AllReviews';
import s from './s.module.less';

const Detail: React.FC = () => {
    const [timeFilter, setTimeFilter] = React.useState<ETimeType>(
        ETimeType.THIS_WEEK,
    );
    const [loadingOverview, setLoadingOverview] = useState<boolean>(false);
    const [overviewData, setOverviewData] = useState<TOverviewData>();
    const { providerId, providerSource } = useParams();
    const navigator = useNavigate();
    const timeFilterItems: MenuProps['items'] = [
        {
            label: (
                <span
                    className={s.dropItem}
                    onClick={() => {
                        setTimeFilter(ETimeType.THIS_WEEK);
                    }}
                >
                    This week
                </span>
            ),
            key: ETimeType.THIS_WEEK,
        },
        {
            label: (
                <span
                    className={s.dropItem}
                    onClick={() => {
                        setTimeFilter(ETimeType.LAST_30_DAYS);
                    }}
                >
                    Last 30 days
                </span>
            ),
            key: ETimeType.LAST_30_DAYS,
        },
        {
            label: (
                <span
                    className={s.dropItem}
                    onClick={() => {
                        setTimeFilter(ETimeType.LAST_90_DAYS);
                    }}
                >
                    Last 90 days
                </span>
            ),
            key: ETimeType.LAST_90_DAYS,
        },
    ];

    let timeFilterLabel = '';
    if (timeFilter === ETimeType.THIS_WEEK) {
        timeFilterLabel = 'This week';
    }
    if (timeFilter === ETimeType.LAST_30_DAYS) {
        timeFilterLabel = 'Last 30 days';
    }
    if (timeFilter === ETimeType.LAST_90_DAYS) {
        timeFilterLabel = 'Last 90 days';
    }

    const timeFilterChange: MenuProps['onClick'] = ({ key }) => {
        setTimeFilter(key as ETimeType);
    };

    const renderRisingArrow = (val?: number) => {
        if (val === undefined || val === null) {
            return <span />;
        }

        if (val >= 0) {
            return (
                <div className={s.up}>
                    <img src={Up} alt="" />
                    <span className={s.rate}>{val}%</span>
                </div>
            );
        }

        if (val < 0) {
            return (
                <div className={s.down}>
                    <img src={Down} alt="" />
                    <span className={s.rate}>{val}%</span>
                </div>
            );
        }
    };

    const hasRating = !!(
        overviewData?.averageSurveyRating || overviewData?.preAverageSurveyRating
    );
    const hasFeedback = !!(
        overviewData?.feedbackReceivedCount ||
      overviewData?.preFeedbackReceivedCount
    );
    const hasReply = !!(
        overviewData?.feedbackRepliedCount ||
      overviewData?.preFeedbackRepliedCount
    );

    const fetchOverviewData = async (v: string) => {
        setLoadingOverview(true);
        try {
            const res = await getReviewSummary({
                summaryRange: v,
                providerId,
                providerSource: providerSource === 'E' ? 'BP_PROVIDER' : 'INTAKEQ_PROVIDER',
            });
            if (res && !res.error && res.data?.data) {
                setOverviewData(res.data.data);
            }
        } catch (e) {
            console.error(e);
        }
        setLoadingOverview(false);
    };

    const handleBack = useCallback(() => {
        navigator(`${PATH.OPERATION}/${PATH.KLARITY_REVIEWS}`);
    }, [navigator]);

    React.useEffect(() => {
        if (timeFilter) {
            fetchOverviewData(timeFilter);
        }
    }, [timeFilter]);

    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <div className={s.nav} onClick={handleBack}>
                    <div className={s.backIcon} />
                    <div className={s.backText}>Back to Reviews</div>
                </div>
                <div className={s.title}>
                    <div className={s.providerName}>{overviewData?.providerName}</div>
                </div>
            </div>
            <div className={s.filter}>
                <div className={s.timeFilter}>
                    <Dropdown
                        overlayStyle={{
                            width: '160px',
                        }}
                        menu={{ items: timeFilterItems, onClick: timeFilterChange }}
                        trigger={['click']}
                    >
                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                                <span className={s.filterLabel}>{timeFilterLabel}</span>
                                <DownOutlined />
                            </Space>
                        </a>
                    </Dropdown>
                </div>
            </div>
            <Spin spinning={loadingOverview}>
                <div className={s.rowWrap}>
                    <div className={s.rowIn}>
                        <div className={s.item}>
                            <div className={s.top}>
                                <div className={s.label}>Average survey rating</div>
                            </div>
                            {hasRating && overviewData && (
                                <div className={s.bottom}>
                                    <div className={s.left}>
                                        <span className={s.val}>
                                            {overviewData?.averageSurveyRating}
                                        </span>{' '}
                                        <span className={s.valDes}>
                                            out of {overviewData?.preAverageSurveyRating}
                                        </span>
                                    </div>
                                    <div className={s.right}>
                                        {renderRisingArrow(overviewData?.averageSurveyRatingRate)}
                                    </div>
                                </div>
                            )}
                            {!hasRating && overviewData && (
                                <div className={s.bottom}>No rating yet</div>
                            )}
                        </div>
                        <div className={s.item}>
                            <div className={s.top}>
                                <div className={s.label}>Feedback</div>
                            </div>
                            {hasFeedback && overviewData && (
                                <div className={s.bottom}>
                                    <div className={s.left}>
                                        <span className={s.val}>
                                            {overviewData?.feedbackReceivedCount}
                                        </span>{' '}
                                        <span className={s.valDes}>
                                            out of {overviewData?.feedbackReceivedCount}
                                        </span>
                                    </div>
                                    <div className={s.right}>
                                        {renderRisingArrow(overviewData?.feedbackReceivedRate)}
                                    </div>
                                </div>
                            )}
                            {!hasFeedback && overviewData && (
                                <div className={s.bottom}>No feedbacks yet</div>
                            )}
                        </div>
                        {(hasFeedback || hasReply) && (
                            <div className={s.item}>
                                <div className={s.top}>
                                    <div className={s.label}>Feedback replied</div>
                                </div>
                                {hasReply && overviewData && (
                                    <div className={s.bottom}>
                                        <div className={s.left}>
                                            <span className={s.val}>
                                                {overviewData?.feedbackRepliedCount}
                                            </span>{' '}
                                            <span className={s.valDes}>
                                                out of {overviewData?.preFeedbackRepliedCount}
                                            </span>
                                        </div>
                                        <div className={s.right}>
                                            {renderRisingArrow(overviewData?.feedbackRepliedRate)}
                                        </div>
                                    </div>
                                )}
                                {!hasReply && overviewData && (
                                    <div className={s.bottom}>No feedbacks replied</div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </Spin>
            <AllReviews
                type="DETAIL"
                providerId={providerId}
                providerSource={providerSource}
            />
        </div>
    );
};

export default Detail;
