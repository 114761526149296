import React, { useCallback, useEffect, useMemo, useState } from 'react';
import s from './s.module.less';
import { Tabs } from 'antd';
import Specialties from './components/Specialties';
import ServiceType from './components/ServiceType';
import KlaritySelectPrograms from './components/KlaritySelectPrograms';
import { getUrlParam } from 'utils/common';

const KlarityServices = () => {
    const type = getUrlParam('subType');
    const [tabActiveKey, setTabActiveKey] = React.useState<string>(type || '1');
    const [reloadSpecialtiesTable, setReloadSpecialtiesTable] = useState<object>();
    useEffect(() => {
        setTabActiveKey(type || '1');
    }, [type]);
    const handleTabClick = useCallback((key: string) => {
        setTabActiveKey(key);
    }, []);

    const items = useMemo(() => {
        return [
            {
                key: '1',
                label: 'Specialties',
                children: (
                    <div className={s.tabContent}>
                        <Specialties reloadSpecialtiesTable={reloadSpecialtiesTable} />
                    </div>
                ),
            },
            {
                key: '2',
                label: 'Service type',
                children: (
                    <div className={s.tabContent}>
                        <ServiceType setReloadSpecialtiesTable={setReloadSpecialtiesTable} />
                    </div>
                ),
            },
            {
                key: '3',
                label: 'Klarity select programs',
                children: (
                    <div className={s.tabContent}>
                        <KlaritySelectPrograms />
                    </div>
                ),
            },
        ];
    }, [reloadSpecialtiesTable]);
    return (
        <div className={s.wrap}>
            <Tabs
                type="card"
                items={items}
                activeKey={tabActiveKey}
                onTabClick={handleTabClick}
            />
        </div>
    );
};

export default KlarityServices;
