import { PermissionModuleKeys } from 'constants/access';
import TinyStore from 'lib/tinyStore';

const initData: null | Record<PermissionModuleKeys, boolean> = null;

type OpsAccessModal = {
    data: Record<PermissionModuleKeys, boolean> | null;
};

const Store = TinyStore.createStore<OpsAccessModal>({
    nameSpace: 'klarity',
    key: 'access_permission',
    crossBundle: false,
    runTime: true,
    initValue: { data: initData },
});

export default Store;
