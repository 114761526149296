import React, { useRef } from 'react';
import { createReview } from 'api/testing/review';
import { List, message, DatePicker, Button, Form, Rate, Spin } from 'antd';
import commonS from 'styles/common.module.less';
import dayjs from 'dayjs';
import useGetKlarityReviewList from 'hooks/useGetKlarityReviewList';
import {
    EOpenType,
    ESortType,
} from 'pages/dashboard/KlarityReviewsDashboradPage/types';
import s from './s.module.less';

type TFormInput = {
    rating: number;
    submitAt: string;
};

const Index: React.FC = () => {
    const [form] = Form.useForm<TFormInput & { active: boolean }>();
    const defautlReviewListParams = useRef({
        sortType: ESortType.MOST_RECENT,
        publicOrPrivate: EOpenType.BOTH_PUBLIC_AND_PRIVATE,
        size: 10,
        current: 1,
    });
    const [list, totalData, loadingReviewList, _, fetchData] = useGetKlarityReviewList(
        defautlReviewListParams.current,
    );

    const handleSave = async () => {
        const formValues = await form.validateFields();
        const { data } = await createReview({
            rating: formValues.rating,
            submitAt: dayjs(formValues.submitAt)
                .set('hour', 0)
                .set('minute', 0)
                .set('second', 2)
                .set('millisecond', 16)
                .utc()
                .toISOString(),
        });
        message.success('Added successfully');
        form.resetFields();
    };

    return (
        <div className={s.wrap}>
            <div className={s.tips}>
                This is for review and testing purposes only and does not affect
                online data
            </div>
            <div className={s.title}>Add a review</div>
            <div className={s.formWrap}>
                <Form
                    form={form}
                    className={commonS.formStyle1}
                    initialValues={{ active: true }}
                    autoComplete="off"
                    layout="vertical"
                    scrollToFirstError
                >
                    <Form.Item
                        className={s.rowItem}
                        label="Rating"
                        name="rating"
                        rules={[
                            {
                                required: true,
                                message: 'Rating is required',
                            },
                        ]}
                    >
                        <Rate />
                    </Form.Item>
                    <Form.Item
                        className={s.rowItem}
                        label="SubmitAt"
                        name="submitAt"
                        rules={[
                            {
                                required: true,
                                message: 'SubmitAt is required',
                            },
                        ]}
                    >
                        <DatePicker style={{ width: '300px' }} />
                    </Form.Item>
                </Form>
                <div className={s.buttonWrap}>
                    <Button type="primary" onClick={handleSave}>
                        Save
                    </Button>
                </div>
            </div>

            <div className={s.line} />

            <div className={s.title}>Review List</div>
            <Spin spinning={loadingReviewList}>
                <div className={s.listWrap}>
                    <div className={s.list}>
                        <List
                            header={
                                <div className={s.header}>
                                    <div className={s.rating}>RATING</div>
                                    <div className={s.submitTime}>CREATE AT</div>
                                </div>
                            }
                            pagination={{
                                showTotal: (total) => `Total ${total} items`,
                                onChange: (page) => {
                                    fetchData({
                                        sortType: ESortType.MOST_RECENT,
                                        publicOrPrivate: EOpenType.BOTH_PUBLIC_AND_PRIVATE,
                                        size: 10,
                                        current: page,
                                    });
                                },
                                total: totalData,
                                pageSize: 10,
                            }}
                        >
                            {list?.map((item) => {
                                return (
                                    <List.Item key={item.submitAt}>
                                        <div className={s.rating}>{item.rating}</div>
                                        <div className={s.submitTime}>
                                            {dayjs(item.submitAt).format('MM/DD/YYYY')}
                                        </div>
                                    </List.Item>
                                );
                            })}
                        </List>
                    </div>
                </div>
            </Spin>
        </div>
    );
};

export default Index;
