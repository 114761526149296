import TinyStore from 'lib/tinyStore';

type ReviewModal = {
    klarityReviewWaitToReplyNumber: number;
};

const Store = TinyStore.createStore<ReviewModal>({
    nameSpace: 'klarity',
    key: 'review',
    crossBundle: false,
    runTime: true,
    initValue: { klarityReviewWaitToReplyNumber: 0 },
});
export default Store;
