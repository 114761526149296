import React from 'react';
import s from './s.module.less';
import type { TResult, TPreviewResult } from 'types/operation';
import cx from 'classnames';
import Logo from 'assets/logo/blueLogo.png';

type IProps = {
    isMobileMode: boolean;
    previewData?: any;
};

const PreviewResult: React.FC<IProps> = (props) => {
    const { isMobileMode, previewData } = props;

    return (
        <div className={isMobileMode ? s.previewMobile : s.previewPc}>
            <div
                className={cx(
                    s.wrap,
                    // s[`result${RESULT_HASH[previewItem.riskLevel || 1]}`],
                )}
            >
                <div className={s.content}>
                    <div className={s.header}>
                        <img src={Logo} alt="klarity-logo" />
                    </div>
                    <div className={s.inner}>
                        <img className={s.banner} src={previewData ? previewData.bannerImageUrl : 'https://www.helloklarity.com/_next/image?url=https%3A%2F%2Fcontent.helloklarity.com%2Fwp-content%2Fuploads%2F2024%2F08%2FOzempic-babies.jpg&w=3840&q=75'} alt="banner" />
                        <div className={s.emailContent}>
                            <div className={s.textContent}>
                                <p className={s.patienName}>Hello [Patient Name],</p>
                                <p className={s.p}>{'Thank you for taking the time to complete our mental health self-assessment. Based on your responses, we believe it\'s important to seek professional help as soon as possible.'}</p>
                                <p className={s.p}>We understand that this might feel overwhelming, but please know that you are not alone. Many people find relief and support through therapy or medication. The first step is reaching out to a qualified professional who can assess your specific needs and recommend the best course of action.</p>
                                <p>{'To help you get started, we\'ve partnered with Klarity to offer free consultations with licensed therapists specializing in Anxiety. You can easily book an appointment online through our platform.'}</p>
                            </div>
                            <div className={s.btnWrap}>
                                <div className={s.ctaButton}>{previewData ? previewData.ctaText : ''}</div>
                            </div>
                            <div className={s.footer}>
                                <p className={s.p}>We encourage you to prioritize your mental health and seek help immediately. Remember, taking care of yourself is an act of strength and courage.</p>
                                <p className={s.regards}>Warm regards,</p>
                                <p className={s.p}>The Klarity team</p>
                            </div>
                        </div>
                    </div>
                    <div className={s.desc}>This is a system-generated email; please do not reply directly to it. This email is for the intended recipient only. Kindly delete if received in error. Anticipate an automated follow-up for additional input.</div>
                    <div className={s.companyInfo}>
                        <div className={s.companyName}>Klarity</div>
                        <div className={s.rights}>370 Convention Way, Redwood City, CA 94063 United States</div>
                        <div className={s.links}>
                            <div className={s.link}>Privacy policy</div>
                            <div className={s.link}>Terms & conditions</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PreviewResult;
