import React, {
    forwardRef,
} from 'react';
import s from './s.module.less';
import { Descriptions } from 'antd';
import type { TAssessmentItem } from 'types/operation';

type IProps = {
    isLoading: boolean;
    item: TAssessmentItem;
    onSuccess: (obj: Partial<TAssessmentItem>) => void;
};

export interface IResultLink {
    validateAndSubmit: () => boolean;
}

const ResultLink = forwardRef<IResultLink, IProps>((props, ref) => {
    const { isLoading, item, onSuccess } = props;

    const renderDescription = () => {
        return (
            <Descriptions
                colon={false}
                title="Question results link"
                layout="vertical"
                column={1}
                size="small"
                contentStyle={{
                    marginBottom: '16px',
                }}
            >
                <Descriptions.Item label="URL">
                    {item.questionsFormResultLink || '-'}
                </Descriptions.Item>
            </Descriptions>
        );
    };

    return (
        <div className={s.wrap}>
            <div className={s.content}>
                {renderDescription()}
            </div>
        </div>
    );
});

export default ResultLink;
