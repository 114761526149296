// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--zXWeq {
  display: inline-block;
}
.s-module__wrap--zXWeq .s-module__btn--maTKR {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  font-size: 12px;
  font-weight: 500;
}
.s-module__wrap--zXWeq .s-module__btn--maTKR svg {
  margin-right: 8px;
}
.s-module__loading--kD3az {
  min-height: 56px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.s-module__drawerWrap--UqvMG .s-module__historyWrap--oqfSC {
  min-height: 300px;
}
.s-module__drawerWrap--UqvMG .s-module__historyItem--WUvkJ {
  margin-top: 24px;
  cursor: pointer;
}
.s-module__drawerWrap--UqvMG .s-module__historyTime--RowlP {
  color: var(--gray-500, var(--Placeholder, #6B7280));
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.s-module__drawerWrap--UqvMG .s-module__historyContentItem--gW1EI {
  color: var(--Primary-Black, #15191E);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding-bottom: 16px;
  padding-top: 16px;
  border-bottom: 1px solid #D6D6D6;
}
.s-module__drawerWrap--UqvMG .s-module__historyContentItem--gW1EI:hover {
  color: var(--primary-color);
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/pages/operation/ProviderSearchPage/components/HistoryButton/s.module.less"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;AAFA;EAII,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,sBAAA;EACA,eAAA;EACA,gBAAA;AACJ;AAVA;EAYM,iBAAA;AACN;AAIA;EACE,gBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAFF;AAKA;EASI,iBAAA;AAXJ;AAEA;EAaI,gBAAA;EACA,eAAA;AAZJ;AAFA;EAoBI,mDAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAfJ;AATA;EA4BI,oCAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,oBAAA;EACA,iBAAA;EACA,gCAAA;AAhBJ;AApBA;EAwCI,2BAAA;EACA,eAAA;AAjBJ","sourcesContent":[".wrap {\n  display: inline-block;\n\n  .btn {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    background-color: #fff;\n    font-size: 12px;\n    font-weight: 500;\n\n    svg {\n      margin-right: 8px;\n    }\n  }\n}\n\n.loading{\n  min-height: 56px;\n  text-align: center;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.drawerWrap {\n\n  // :global {\n  //   .ant-input-group-addon {\n  //     background: none;\n  //     border-right: 0;\n  //   }\n  // }\n  .historyWrap {\n    min-height: 300px;\n  }\n\n  .historyItem {\n    margin-top: 24px;\n    cursor: pointer;\n  }\n\n\n\n  .historyTime {\n    color: var(--gray-500, var(--Placeholder, #6B7280));\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 20px;\n  }\n\n  .historyContentItem {\n    color: var(--Primary-Black, #15191E);\n    font-family: Inter;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 20px;\n    padding-bottom: 16px;\n    padding-top: 16px;\n    border-bottom: 1px solid #D6D6D6;\n  }\n\n  .historyContentItem:hover {\n    color: var(--primary-color);\n    cursor: pointer;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--zXWeq`,
	"btn": `s-module__btn--maTKR`,
	"loading": `s-module__loading--kD3az`,
	"drawerWrap": `s-module__drawerWrap--UqvMG`,
	"historyWrap": `s-module__historyWrap--oqfSC`,
	"historyItem": `s-module__historyItem--WUvkJ`,
	"historyTime": `s-module__historyTime--RowlP`,
	"historyContentItem": `s-module__historyContentItem--gW1EI`
};
export default ___CSS_LOADER_EXPORT___;
