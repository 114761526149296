import { useCallback, useEffect, useState } from 'react';
import { getChannelAuditDetail, getUniprofileAuditDetail } from 'api/operation';
import { transferProviderToFormData } from 'utils/channel';
import { initApplicationFormData } from 'store/ChannelApplicationProvider';
import { UniprofileFormData } from 'types/provider';

interface IParams {
    channelId: number;
    providerId?: number;
}

const useFetchAuditDetailData = ({ channelId, providerId }: IParams):{ data: UniprofileFormData, refetch:()=>void, loading: boolean, orgData?:any } => {
    const [data, setData] = useState(initApplicationFormData);
    const [orgData, setOrgData] = useState<any>(undefined);

    const [loading, setLoading] = useState(false);
    const fetchAuditDetailData = useCallback(async () => {
        setLoading(true);
        let res = {
            error: '',
        };
        // 不修改detial页面的路由，所以给unprofile设定channelid为-1
        if (channelId !== -1) {
            res = await getChannelAuditDetail({
                channelId,
                providerId,
                step: 'REVIEW',
            });
        } else {
            res = await getUniprofileAuditDetail({
                providerId: providerId!,
                step: 'REVIEW',
            });
        }
        if (!res?.error) {
            setOrgData((res as any).data.data);
            setData(transferProviderToFormData((res as any).data.data)!);
        } else {
            console.error(res);
        }
        setLoading(false);
    }, [channelId, providerId]);

    useEffect(() => {
        if (!providerId) return;
        fetchAuditDetailData();
    }, [fetchAuditDetailData, providerId]);

    return { data, refetch: fetchAuditDetailData, loading, orgData };
};

export default useFetchAuditDetailData;
