import React from 'react';
import s from './s.module.less';
import type { TAssessmentItem } from 'types/operation';

type IProps = {
    isMobileMode: boolean;
    previewItem: TAssessmentItem;
};

const PreviewIntro: React.FC<IProps> = (props) => {
    const { isMobileMode, previewItem } = props;
    console.log('previewItem: ', previewItem);
    return (
        <div className={isMobileMode ? s.previewMobile : s.previewPc}>
            <div className={s.content}>
                <div className={s.decoration}>
                    <i />
                    <i />
                    <i />
                    <i />
                </div>
                <div className={s.contentInner}>
                    {previewItem.imgUrl && (
                        <img
                            src={previewItem.imgUrl}
                            className={s.img}
                            alt={previewItem.title}
                        />
                    )}
                    <h3 className={s.title}>{previewItem.title || '-'}</h3>
                    {previewItem.description && (
                        <p className={s.description}>{previewItem.description}</p>
                    )}

                    <div className={s.info}>
                        <p className={s.finishMinute}>
                            About {previewItem.finishMinute || '-'} mins
                        </p>
                        <p className={s.questionNumber}>
                            {previewItem.questionNumber || '-'} questions
                        </p>
                        <p className={s.participantsNumber}>
                            over {previewItem.participantsNumber || '-'} participants
                        </p>
                    </div>
                    <div className={s.cta}>Start now</div>
                    <p className={s.tips}>
                        * This is not a substitute for a professional diagnosis.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PreviewIntro;
