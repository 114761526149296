// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--z9fBk :where(.css-dev-only-do-not-override-1uhj8f6).ant-tabs .ant-tabs-tab-btn {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}
.s-module__wrap--z9fBk .ant-tabs-nav {
  margin-bottom: 0;
}
.s-module__wrap--z9fBk .ant-table-wrapper .ant-table-thead > tr > th,
.s-module__wrap--z9fBk .ant-table-wrapper .ant-table-thead > tr > td {
  background-color: var(--gray-50);
}
.s-module__wrap--z9fBk .s-module__tabContent--s8k97 {
  border: 1px solid #f0f0f0;
  border-top: none;
  padding: 16px 12px;
  background-color: var(--white);
}
`, "",{"version":3,"sources":["webpack://./src/pages/operation/ServiceTemplateManagement/components/KlarityServices/s.module.less"],"names":[],"mappings":"AAAA;EAGY,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AADZ;AANA;EAWY,gBAAA;AAFZ;AATA;;EAeY,gCAAA;AAFZ;AAbA;EAoBQ,yBAAA;EACA,gBAAA;EACA,kBAAA;EACA,8BAAA;AAJR","sourcesContent":[".wrap {\n    :global {\n        :where(.css-dev-only-do-not-override-1uhj8f6).ant-tabs .ant-tabs-tab-btn {\n            font-family: Roboto;\n            font-size: 14px;\n            font-weight: 400;\n            line-height: 22px;\n            text-align: left;\n        }\n\n        .ant-tabs-nav {\n            margin-bottom: 0;\n        }\n\n        .ant-table-wrapper .ant-table-thead >tr>th, .ant-table-wrapper .ant-table-thead >tr>td {\n            background-color: var(--gray-50);\n        }\n    }\n\n    .tabContent {\n        border: 1px solid #f0f0f0;\n        border-top: none;\n        padding: 16px 12px;\n        background-color: var(--white);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--z9fBk`,
	"tabContent": `s-module__tabContent--s8k97`
};
export default ___CSS_LOADER_EXPORT___;
